import { ElementType, FC, memo, useEffect, useMemo, useRef, useState } from 'react';

import { VariableSizeList } from 'react-window';
import { Clusterer, Map as YandexMap, Placemark, YMaps, ZoomControl } from 'react-yandex-maps';
import styled, { css } from 'styled-components';

import { activePlacemarkPath, defaultPlacemarkPath } from 'resources/other-27';
import { clustererPath } from 'resources/other-42';
import { MapIcon } from 'resources/placeholders';
import { Heading, Text } from 'UI';

const Container = styled.div(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    align-items: center;
    width: 100%;
    height: 656px;
    border: 1px solid ${colors.divider};

    // for correct view in Firefox
    & [class$='ground-pane'] {
      filter: grayscale(65%);
    }
  `,
);

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  margin-left: auto;
  overflow-y: auto;
`;

const PlaceholderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  min-width: 430px;
  margin-left: auto;
`;

interface IMapPlaceholder {
  title: string;
  text: string;
  icon: ElementType;
}

const MapPlaceholder: FC<IMapPlaceholder> = memo(
  ({ title, text, icon: PlaceholderIcon }): JSX.Element => {
    return (
      <PlaceholderContainer>
        <PlaceholderIcon />
        <Heading as='h3' my={2}>
          {title}
        </Heading>
        <Text color='text.secondary'>{text}</Text>
      </PlaceholderContainer>
    );
  },
);

interface IMapProps {
  selectedLocationId?: string;
  placeholderTitle?: string;
  placeholderIcon?: ElementType;
  itemSize?: number | ((location: any) => number);
  locations: any;
  locationCard: any;
}

const Map: FC<IMapProps> = memo(
  ({
    selectedLocationId = '',
    placeholderTitle = 'Нет данных по вашему запросу',
    placeholderIcon = MapIcon,
    itemSize,
    locations,
    locationCard: LocationCard,
  }) => {
    const variableListRef = useRef<VariableSizeList>(null);
    const mapInstanceRef = useRef<any>(null);
    const ymapsInstanceRef = useRef<any>(null);
    const [activeLocationId, setActiveLocationId] =
      useState(selectedLocationId);
    const [displayedLocations, setDisplayedLocations] = useState(
      locations ?? [],
    );

    useEffect(() => setDisplayedLocations(locations), [locations]);

    useEffect(() => {
      if (selectedLocationId) {
        const locationIndex = displayedLocations.findIndex(
          ({ id }) => id === selectedLocationId,
        );

        variableListRef.current?.scrollToItem(locationIndex, 'smart');
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [{ clustererLayour }, setClustererLayout] = useState({
      clustererLayour: null,
    });

    const defaultMapBounds = useMemo(() => {
      const { minLatitude, maxLatitude, minLongitude, maxLongitude } = locations
        .filter(item => item.location)
        .reduce(
          (acc, location) => {
            const {
              location: {
                coordinates: { latitude, longitude },
              },
            } = location;

            if (
              acc.minLatitude === null &&
              acc.maxLatitude === null &&
              acc.minLongitude === null &&
              acc.maxLongitude === null
            ) {
              return {
                minLatitude: latitude,
                maxLatitude: latitude,
                minLongitude: longitude,
                maxLongitude: longitude,
              };
            }

            const tmpMinLatitude =
              latitude < acc.minLatitude ? latitude : acc.minLatitude;
            const tmpMaxLatitude =
              latitude > acc.maxLatitude ? latitude : acc.maxLatitude;
            const tmpMinLongitude =
              longitude < acc.minLongitude ? longitude : acc.minLongitude;
            const tmpMaxLongitude =
              longitude > acc.maxLongitude ? longitude : acc.maxLongitude;

            return {
              minLatitude: tmpMinLatitude,
              maxLatitude: tmpMaxLatitude,
              minLongitude: tmpMinLongitude,
              maxLongitude: tmpMaxLongitude,
            };
          },
          {
            minLatitude: null,
            maxLatitude: null,
            minLongitude: null,
            maxLongitude: null,
          },
        );

      return [
        [minLatitude, minLongitude],
        [maxLatitude, maxLongitude],
      ];
    }, [locations]);

    const handleInstanceMap = (mapInstance): void => {
      if (!mapInstanceRef?.current) {
        mapInstanceRef.current = mapInstance;
      }
    };

    const handleLoadMap = (ymapsInstance): void => {
      ymapsInstanceRef.current = ymapsInstance;

      setClustererLayout({
        clustererLayour: ymapsInstance.templateLayoutFactory.createClass(
          '<div style="color: #FFFFFF; font-weight: 600;">$[properties.geoObjects.length]</div>',
        ),
      });
    };

    const handlePlacemarkClick = ({ originalEvent: { target } }): void => {
      const { locationId } = target.properties._data;

      const locationIndex = displayedLocations.findIndex(
        ({ id }) => id === locationId,
      );

      variableListRef.current?.scrollToItem(locationIndex, 'smart');
      setActiveLocationId(locationId);
    };

    const handleCardClick = ({ id, location }): void => {
      if (location) {
        setActiveLocationId(id);
        mapInstanceRef.current.setCenter([
          location.coordinates.latitude,
          location.coordinates.longitude,
        ]);
      }
    };

    const handleBoundsChange = (): void => {
      const currentBounds = mapInstanceRef.current.getBounds();

      const filteredLocations = locations
        .filter(item => item.location)
        .filter(({ location }) => {
          return ymapsInstanceRef.current.util.bounds.containsPoint(
            currentBounds,
            [location.coordinates.latitude, location.coordinates.longitude],
          );
        });

      setDisplayedLocations(filteredLocations);
    };

    const defaultMapState = useMemo(() => {
      const filteredLocations = locations.filter(item => item.location);

      if (selectedLocationId) {
        const selectedLocation = locations.find(
          ({ id }) => id === selectedLocationId,
        );

        return {
          center: [
            selectedLocation?.location?.coordinates?.latitude,
            selectedLocation?.location?.coordinates?.longitude,
          ],
          zoom: 14,
        };
      }

      if (filteredLocations.length === 1) {
        return {
          center: [
            filteredLocations[0].location.coordinates?.latitude,
            filteredLocations[0].location.coordinates?.longitude,
          ],
          zoom: 14,
        };
      }

      return { bounds: defaultMapBounds };
    }, [locations, selectedLocationId, defaultMapBounds]);

    useEffect(() => {
      const mapInstance = mapInstanceRef.current;
      const ymapsInstance = ymapsInstanceRef.current;

      const bounds = ymapsInstance?.util?.bounds.getCenterAndZoom(
        defaultMapBounds,
        mapInstanceRef.current?.container?.getMapSize(),
      );

      // eslint-disable-next-line no-unused-expressions
      mapInstance?.setCenter(
        bounds.center,
        bounds.zoom >= 15 ? 14 : bounds.zoom - 1,
      );
    }, [defaultMapBounds]);

    return (
      <YMaps query={{ apikey: process.env.YANDEX_MAPS_API_KEY }}>
        <Container>
          <YandexMap
            defaultState={defaultMapState}
            height='100%'
            instanceRef={handleInstanceMap}
            modules={['templateLayoutFactory', 'util.bounds']}
            options={{
              // world map (all continents)
              // @ts-ignore
              restrictMapArea: [
                [84.96206, -170.337975],
                [-80.437067, -170.337976],
              ],
              minZoom: 2,
              suppressMapOpenBlock: true,
              autoFitToViewport: 'always',
            }}
            width='100%'
            onBoundsChange={handleBoundsChange}
            onLoad={handleLoadMap}
          >
            <ZoomControl />
            <Clusterer
              options={{
                clusterIcons: [
                  {
                    href: clustererPath,
                    size: [42, 42],
                    offset: [-21, -21],
                  },
                ],
                clusterIconContentLayout: clustererLayour,
              }}
            >
              {locations.map(location => {
                if (!location.location) return null;

                return (
                  <Placemark
                    key={location.id}
                    geometry={[
                      location.location.coordinates.latitude,
                      location.location.coordinates.longitude,
                    ]}
                    options={{
                      iconLayout: 'default#image',
                      iconImageHref:
                        location.id === activeLocationId
                          ? activePlacemarkPath
                          : defaultPlacemarkPath,
                    }}
                    properties={{ locationId: location.id }}
                    onClick={handlePlacemarkClick}
                  />
                );
              })}
            </Clusterer>
          </YandexMap>
          {displayedLocations.length === 0 ? (
            <MapPlaceholder
              icon={placeholderIcon}
              text='Попробуйте изменить масштаб.'
              title={placeholderTitle}
            />
          ) : (
            <CardsWrapper>
              <VariableSizeList
                ref={variableListRef}
                height={656}
                itemCount={displayedLocations.length}
                itemKey={index => displayedLocations[index].id}
                // @ts-ignore
                itemSize={index => {
                  return typeof itemSize === 'function'
                    ? itemSize(locations[index])
                    : itemSize;
                }}
                style={{ overflowX: 'hidden' }}
                width={430}
              >
                {({ style, index }) => {
                  const location = displayedLocations[index];

                  return (
                    <LocationCard
                      active={location.id === activeLocationId}
                      handleClick={() => handleCardClick(location)}
                      location={location}
                      style={style}
                    />
                  );
                }}
              </VariableSizeList>
            </CardsWrapper>
          )}
        </Container>
      </YMaps>
    );
  },
);

export { Map };
