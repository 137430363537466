import { ChangeEventHandler, CSSProperties, forwardRef, useRef } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { FormLabel } from '../FormLabel';

const Wrapper = styled.label<{ disabled?: boolean } & SpaceProps>(
  ({ disabled }) => css`
    position: relative;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    cursor: ${disabled ? 'default' : 'pointer'};
    margin: 0;
    overflow: hidden;

    ${styledSpace}
  `,
);

const Controller = styled.input(
  ({ theme: { space } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    // 1px container border
    width: ${space[2] + 1}px;
    height: ${space[2] + 1}px;
    margin: 0;
    opacity: 0;
  `,
);

const Container = styled.div<{ indeterminate?: boolean }>(
  ({ theme: { colors, space, borderRadius }, indeterminate }) => css`
    position: relative;
    display: inline-block;
    width: ${space[2]}px;
    min-width: ${space[2]}px;
    height: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    transition: 0.1s ease-in-out border-color;
    margin-right: ${space[1]}px;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      display: none;
      width: ${space[2] / 5}px;
      height: ${space[2] / 3}px;
      border-width: 0 2px 2px 0;
      border-style: solid;
      border-color: ${colors.white};
      transform: translate(-50%, -60%) rotate(45deg);
    }

    ${Wrapper}:hover & {
      border-color: ${colors.text.secondary};
    }

    ${Controller}:checked ~ & {
      background: ${colors.text.primary};
      border-color: ${colors.text.primary};

      &::after {
        display: block;
      }
    }

    ${Controller}:disabled ~ & {
      background: #edeef1;
      border-color: ${colors.divider};
      cursor: default;
    }

    ${Controller}[disabled][checked] ~ & {
      background: ${colors.text.disabled};
      border: none;
    }

    ${indeterminate &&
    css`
      background: ${colors.text.primary};
      border-color: ${colors.text.primary};

      &::after {
        display: block;
        width: ${space[2] / 2}px;
        height: 0;
        background: ${colors.white};
        transform: translate(-50%, -50%);
      }
    `}
  `,
);

interface ICheckboxProps {
  style?: CSSProperties;
  className?: string;
  /**
   * Disabled status
   */
  disabled?: boolean;
  required?: boolean;
  /**
   * Partial selection
   */
  indeterminate?: boolean;
  tip?: string;
  /**
   * Colors from styled-system
   */
  labelColor?: string;
  fontWeight?: number;
  fontSize?: number | string;
  /**
   * Checkbox label
   */
  label?: string;
  /**
   * Value of checkbox
   */
  value?: boolean;
  /**
   * Name of checkbox
   */
  name?: string;
  /**
   * Checkbox switch
   */
  checked?: boolean;
  /**
   * Function for check
   */
  onChange: ChangeEventHandler<HTMLInputElement> | ((value: any) => void);
}

const Checkbox = forwardRef<HTMLLabelElement, ICheckboxProps & SpaceProps>(
  (
    {
      style,
      className,
      disabled,
      required,
      indeterminate,
      tip,
      labelColor,
      label,
      value,
      name,
      fontWeight = 500,
      fontSize = 3,
      checked,
      onChange,
      ...other
    },
    ref,
  ) => {
    // condition for checkbox array in Formik 2+
    const isBooleanValue = typeof value === 'boolean';
    const idRef = useRef(uuid());
    const isChecked = isBooleanValue ? value : checked;

    return (
      <Wrapper
        ref={ref}
        className={className}
        disabled={disabled}
        style={style}
        {...other}
      >
        <Controller
          checked={isChecked}
          disabled={disabled}
          id={idRef.current}
          name={name}
          role='checkbox'
          type='checkbox'
          value={`${value}`}
          onChange={onChange}
        />
        <Container indeterminate={indeterminate} />
        {label && (
          <FormLabel
            color={labelColor}
            disabled={disabled}
            fontSize={fontSize}
            fontWeight={fontWeight}
            htmlFor={idRef.current}
            mb='0px'
            required={required}
            tip={tip}
          >
            {label}
          </FormLabel>
        )}
      </Wrapper>
    );
  },
);

export { Checkbox, ICheckboxProps };
