import { useCallback, useRef, FC, KeyboardEvent } from 'react';

import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import styled, { css, useTheme } from 'styled-components';

import { fetchYandexSuggest } from 'common/api/external';
import { IYandexSuggest } from 'common/types/external';
import { IOption } from 'common/types/form.types';
import { getSuggestText } from 'common/utils/external/yandex-api';
import { RequestReturnType } from 'common/utils/request';
import { IReformField } from 'reform';
import { Container, FormLabel } from 'UI';
import { Options, useAsyncOptions } from 'UI/Inputs';

const Input = styled.input(
  ({ theme: { colors } }) => css`
    height: 100%;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

interface IInputWithDropdownProps extends IReformField {
  field: IReformField;
  label: string;
  placeholder: string;
  types: string;
}

const InputWithDropdown: FC<IInputWithDropdownProps> = ({
  form: { setFieldValue },
  field: { name, value },
  label,
  placeholder,
  types,
}) => {
  const theme = useTheme();

  const containerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const [asyncState, dispatch] = useAsyncOptions();

  const fetchOptions = useCallback(
    (props: IOption): RequestReturnType => {
      return fetchYandexSuggest(props.name, types);
    },
    [types],
  );

  const stateReducer = useCallback(
    (
      state: UseComboboxState<any>,
      { type, changes }: UseComboboxStateChangeOptions<IYandexSuggest>,
    ): Partial<UseComboboxState<any>> => {
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (changes.selectedItem) {
            setFieldValue(name, getSuggestText(changes.selectedItem));
          }
          return changes;
        case useCombobox.stateChangeTypes.InputChange:
          setFieldValue(name, changes.inputValue);
          return { ...changes, isOpen: !!changes.inputValue };
        case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
        case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
          return { ...changes, isOpen: !!changes.inputValue };
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
          return state;
        default:
          return changes;
      }
    },
    [name, setFieldValue],
  );

  const downshift = useCombobox({
    inputValue: value,
    initialInputValue: value,
    items: asyncState.items,
    stateReducer,
  });

  const { highlightedIndex, getComboboxProps, getInputProps, closeMenu } =
    downshift;

  const handleKeyPress = ({ key }: KeyboardEvent<HTMLInputElement>): void => {
    if (key === 'Escape' || (key === 'Enter' && highlightedIndex < 0)) {
      closeMenu();
    }
  };

  return (
    <Container column width='100%'>
      <FormLabel>{label}</FormLabel>
      <Container
        {...getComboboxProps({ ref: containerRef })}
        alignItems='center'
        border={`1px solid ${theme.colors.divider}`}
        borderRadius={theme.borderRadius}
        height='34px'
        px={2}
        width='100%'
      >
        <Container
          {...getInputProps({
            ref: inputRef,
            placeholder,
            onKeyDown: handleKeyPress,
          })}
          as={Input}
          px='0px'
          width='100%'
        />
        <Options
          anchorEl={containerRef.current}
          dispatch={dispatch}
          downshift={downshift}
          filteredItems={asyncState.items}
          hasPlaceholder={false}
          itemToKey={item => item.subtitle.text}
          itemToString={getSuggestText}
          options={fetchOptions}
          state={asyncState}
        />
      </Container>
    </Container>
  );
};

export { InputWithDropdown };
