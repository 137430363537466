import { useState, useEffect, useCallback, memo, FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { NOT_FOUND_PLACEHOLDER } from 'common/constants/notFoundPlaceholder.const';
import { useRequest } from 'common/hooks';
import {
  IProduct,
  ProductCategory,
  ProductLifeState,
} from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { getProductPagePath } from 'common/utils/products';
import { FillMediumIcon } from 'resources/icons/18';
import {
  Text,
  Avatar,
  Button,
  Heading,
  Spinner,
  Container,
  SearchInput,
  PaginationButton,
} from 'UI';

import { CardWrapper } from './styled';
import { ProductionStateBadge } from '../../../../products/pages/product/_shared/badges/ProductionStateBadge';

const MainInfo = styled.div(
  ({ theme: { space } }) => css`
    padding-left: ${space[2]}px;
    max-width: calc(90% - 100px);
  `,
);

const Title = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: none;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    font-weight: 600;
    transition: opacity 0.3s ease;

    ${CardWrapper}:hover & {
      display: inline-block;
      margin-left: ${space[0]}px;
      opacity: 1;
    }
  `,
);

const ITEMS_PER_PAGE = 5;

const Questionnaire: FC<{ questionnaire: IProduct }> = ({ questionnaire }) => {
  const url = getProductPagePath(questionnaire);

  return (
    <CardWrapper>
      <Container alignItems='center' pr={2}>
        <Avatar
          as={Link}
          size='l'
          to={url}
          url={getImageURL(questionnaire.previewImage)}
        />
        <MainInfo>
          <ProductionStateBadge
            product={questionnaire}
            size='s'
            style={{ height: '16px' }}
          />
          <Heading as='h5' fontWeight={500} my={0}>
            <Text truncate as={Link} target='_blank' to={url}>
              {questionnaire.name}
            </Text>
          </Heading>
          <Text color='text.secondary'>Тип товара: {questionnaire.type}</Text>
        </MainInfo>
        <Button
          as={Link}
          color='secondary'
          ml='auto'
          size='m'
          target='_blank'
          to={`${url}?filling=true`}
          variant='text'
        >
          <FillMediumIcon />
          <Title>Заполнить</Title>
        </Button>
      </Container>
    </CardWrapper>
  );
};

interface ICompanyQuestionnaireProps {
  companyId: string;
}

const CompanyQuestionnaire: FC<ICompanyQuestionnaireProps> = memo(
  ({ companyId }) => {
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [questionnaires, setQuestionnaires] = useState<IProduct[]>([]);

    useEffect(() => {
      setQuestionnaires([]);
      setPage(1);
    }, [searchValue]);

    const { isLoading, data: totalItems = 0 } = useRequest(
      fetchProducts,
      [
        {
          page,
          company: companyId,
          category: ProductCategory.questionnaire,
          lifeState: ProductLifeState.public,
          itemsPerPage: ITEMS_PER_PAGE,
          search: searchValue,
        },
      ],
      {
        responseToData: data => data.totalItems,
        onSuccess: ({ member }: { member: IProduct[]; totalItems: number }) => {
          setQuestionnaires(prev => [...prev, ...member]);
        },
      },
    );

    const handleSearch = (value = ''): void => {
      setSearchValue(value);
    };

    const handleNextPage = useCallback(
      (): void => setPage(prev => prev + 1),
      [setPage],
    );

    const isPaginationVisible =
      questionnaires.length < totalItems && questionnaires.length !== 0;

    if (!isLoading && totalItems === 0 && !searchValue) return null;

    return (
      <>
        <SearchInput
          isBordered
          iconVariant='gray'
          initialValue={searchValue}
          onChange={handleSearch}
        />
        {questionnaires.map(questionnaire => (
          <Questionnaire key={questionnaire.id} questionnaire={questionnaire} />
        ))}
        {!isLoading && questionnaires.length === 0
          ? NOT_FOUND_PLACEHOLDER
          : null}
        <Spinner delay={0} height='300px' loading={isLoading}>
          {isPaginationVisible && (
            <PaginationButton onClick={handleNextPage}>
              Показано {questionnaires.length} из {totalItems}. Показать еще
            </PaginationButton>
          )}
        </Spinner>
      </>
    );
  },
);

export { CompanyQuestionnaire };
