import { createElement, FC, Fragment, ReactElement, useState } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { DocumentsSections } from 'common/types/media.types';
import {
  IPackage,
  KindOfOfPackageEnum,
} from 'common/types/product.types/accompany-information.types';
import { ImagesFormTemplate } from 'components/media';
import { DocumentsMultiplyForm } from 'components/media/documents';
import { Reform, useField, useReform, useWatch } from 'reform';
import { CloseMediumIcon } from 'resources/icons/18';
import { Modal, Notice } from 'UI';
import { StyledIconButton } from 'UI/Modal/templates/styled';

import { ProductsCreatePackagesModalForm } from './Form';
import { ProductsCreatePackagesModalSidebar } from './Sidebar';
import {
  PACKAGE_CREATE_SECTIONS,
  PACKAGE_TYPE_SIZES,
} from '../../../../../../features/products/constants';

const Step = styled.div<{ isOpen: boolean } & SpaceProps>(
  ({ isOpen }) => css`
    width: 100%;
    min-height: 640px;
    max-height: 640px;
    display: ${isOpen ? 'flex' : 'none'};
    overflow-y: scroll;

    ${isOpen &&
    css`
      flex-direction: column;
    `}

    ${styledSpace};
  `,
);

const getInitialValue = (
  isPrimaryPackage: boolean,
): Omit<IPackage, 'packageType' | 'size' | 'id' | 'uin' | '_editable'> => {
  return {
    kindOfPackage: KindOfOfPackageEnum.consumer,
    quantity: {
      value: '',
      isApproximate: false,
      unit: isPrimaryPackage ? '' : 'quantity',
    },
    grossWeight: {
      value: '',
      unit: '',
    },
    packingAids: [],
    media: {
      images: [],
      documents: [],
    },
    vendorCode: {
      code: { code: '', value: '' },
      value: '',
    },
    recyclingCode: null,
    canBeReused: false,
  };
};

interface IMediaProps {
  limit: number;
}

const Images: FC<IMediaProps> = ({ limit }) => {
  const { value: images, setValue: handleImagesChange } = useField({
    name: 'media.images',
  });

  return (
    <ImagesFormTemplate
      disableDescription
      disableGallery
      handleChange={handleImagesChange}
      images={images}
      imagesCount={4}
      limit={limit}
    />
  );
};

interface IInnerProductCreatePackagesModal {
  isEditable: boolean;
  isPrimaryPackage: boolean;
  index: number;
  error: any;
  productPackage: IPackage;
  onSubmit: any;
  handleModalClose: any;
}

const InnerProductCreatePackagesModal: FC<IInnerProductCreatePackagesModal> = ({
  isEditable = true,
  isPrimaryPackage = false,
  index,
  error,
  productPackage,
  onSubmit,
  handleModalClose,
}) => {
  const { getData } = useReform();
  const [activeSection, setActiveSection] = useState(
    PACKAGE_CREATE_SECTIONS.MAIN_INFO,
  );

  const isDisabled = useWatch({
    selector: values => {
      const hasThirdDimension =
        values.packageType?.sizeParameters?.type ===
        PACKAGE_TYPE_SIZES.THREE_DIMENSIONS;

      return (
        !values.packageType ||
        !values.quantity.value ||
        (isPrimaryPackage && !values.quantity.unit) ||
        !values.grossWeight.value ||
        !values.grossWeight.unit ||
        !values.size.value.a ||
        !values.size.value.b ||
        (hasThirdDimension && !values.size.value.c) ||
        !values.size.unit ||
        (values.vendorCode?.code && !values.vendorCode.value) ||
        values.media.documents.some(file => file instanceof File) ||
        values.media.images.some(file => file instanceof File)
      );
    },
  });

  const handleSelectSection = ({ currentTarget: { dataset } }): void => {
    setActiveSection(dataset.section);
  };

  const handleSubmit = (): void => {
    const data = getData();

    onSubmit(data, index);
    handleModalClose();
  };

  return (
    <Modal
      disabled={isDisabled}
      hasSubmitOnEnter={false}
      width={906}
      onSubmit={handleSubmit}
    >
      <Fragment>
        <div style={{ display: 'flex' }}>
          <Step
            isOpen={activeSection === PACKAGE_CREATE_SECTIONS.MAIN_INFO}
            px={3}
            py={4}
          >
            <ProductsCreatePackagesModalForm
              error={error}
              isEditable={isEditable}
              isPrimaryPackage={isPrimaryPackage}
            />
          </Step>
          <Step
            isOpen={activeSection === PACKAGE_CREATE_SECTIONS.IMAGES}
            px={3}
            py={4}
          >
            <Images limit={8} />
            <Notice mb='0px' mt={3} pr='0px' type={Notice.TYPES.DISABLED}>
              Допустимые форматы файла — JPG, PNG, BMP. <br />
              Допустимый размер файла: 1 KB – 50 MB. Минимальное разрешение
              файла — 50х50 px.
            </Notice>
          </Step>
          <Step
            isOpen={activeSection === PACKAGE_CREATE_SECTIONS.DOCUMENTS}
            px={3}
            py={4}
          >
            <DocumentsMultiplyForm
              onlyContent
              fileSection={DocumentsSections.productDocuments}
              flexGrow={1}
              forbidden={{ edit: true }}
              limit={5}
              name='media.documents'
            />
          </Step>
          <ProductsCreatePackagesModalSidebar
            activeSection={activeSection}
            handleSubmit={handleSubmit}
            isDisabled={isDisabled}
            isEdit={!!productPackage}
            isPrimaryPackage={isPrimaryPackage}
            onSelectSection={handleSelectSection}
          />
        </div>
        <StyledIconButton onClick={handleModalClose}>
          <CloseMediumIcon />
        </StyledIconButton>
      </Fragment>
    </Modal>
  );
};

function ProductCreatePackagesModal(props): ReactElement {
  const { productPackage, isPrimaryPackage = false } = props;

  return (
    <Reform initialValues={productPackage || getInitialValue(isPrimaryPackage)}>
      {createElement(InnerProductCreatePackagesModal, props)}
    </Reform>
  );
}

const ADD_PACKAGE = 'ADD_PACKAGE';

export { ProductCreatePackagesModal, ADD_PACKAGE };
