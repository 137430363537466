import { FC, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import { flexbox, FlexboxProps } from 'styled-system';
import { BufferGeometry } from 'three';

import { ALL, IMAGE_FILTERS } from 'common/constants/products.const';
import { Nullable } from 'common/types/common.types';
import {
  IDocumentFile,
  IImage,
  MediaFieldsTypes,
} from 'common/types/media.types';
import { ImagesGallery } from 'components/products/ImagesGallery';

import { ImagesFilterButtons, ListImages, MainImage } from './components';
import { ImagesWithTypes, Media } from './types';
import { get3DModelImage } from '../../3d-model/utils';

const PreviewGalleryContainer = styled.div<FlexboxProps>(
  ({ theme: { space } }) => css`
    position: sticky;
    top: ${space[3]}px;
    flex-basis: 324px;
    height: 100%;
    min-width: 0;
    margin-right: ${space[3]}px;

    ${flexbox}
  `,
);

const injectTypeToImages = (
  type: string,
  images: IImage[],
): ImagesWithTypes => {
  return images?.map(image => ({ type, image }));
};

const getImagesByFilterWithTypes = (
  filter: string,
  media: Media,
  isStandard = false,
): ImagesWithTypes => {
  if (isStandard) {
    return injectTypeToImages(filter, media[filter]);
  }
  if (filter === MediaFieldsTypes.model3D) {
    return injectTypeToImages(filter, [media[filter]?.file]);
  }

  return injectTypeToImages(filter, media[filter]?.items);
};

const getAllImagesWithTypes = (
  previewImage: Nullable<IImage> | undefined,
  media: Media,
  isStandard: boolean,
): ImagesWithTypes => {
  return IMAGE_FILTERS.filter(keyFilter => {
    if (isStandard) {
      return media[keyFilter]?.length > 0;
    }
    if (keyFilter === MediaFieldsTypes.model3D) {
      return !!media[keyFilter]?.file;
    }

    return media[keyFilter]?.items?.length > 0;
  }).reduce(
    (images, keyFilter) => [
      ...images,
      ...getImagesByFilterWithTypes(keyFilter, media, isStandard),
    ],
    previewImage ? [{ type: ALL, image: previewImage }] : [],
  );
};

const INITIAL_STATE: {
  activeImageIndex: number;
  filter: string;
} = {
  activeImageIndex: 0,
  filter: ALL,
};

interface IImagesPageProps extends FlexboxProps {
  isStandard?: boolean;
  withFilters?: boolean;
  companyName?: string;
  previewImage?: Nullable<IImage>;
  media: Media;
}

const ImagesPage: FC<IImagesPageProps> = ({
  isStandard = false,
  withFilters = true,
  companyName,
  previewImage,
  media,
  ...other
}) => {
  const [{ activeImageIndex, filter }, setState] = useState(INITIAL_STATE);
  const [modelGeometry, setModelGeometry] =
    useState<Nullable<BufferGeometry>>(null);
  const [isOpenGallery, setOpenGallery] = useState(false);

  const allImagesWithTypes = getAllImagesWithTypes(
    previewImage,
    media,
    isStandard,
  );

  const filteredImagesWithTypes =
    getImagesByFilterWithTypes(filter, media) || allImagesWithTypes;

  const allImages = allImagesWithTypes
    .filter(item => item.type !== MediaFieldsTypes.model3D)
    .map(item => item.image);

  const isShowImageList = allImagesWithTypes.length > 1;
  const selectedImageWithType = filteredImagesWithTypes[activeImageIndex] ?? {};
  const model3D = allImagesWithTypes.find(
    image => image.type === MediaFieldsTypes.model3D,
  )?.image;

  useEffect(() => {
    if (model3D) {
      const getModelGeometry = async (): Promise<void> => {
        const { modelGeometry: geometry } = await get3DModelImage(model3D);
        setModelGeometry(geometry);
      };

      getModelGeometry();
    }
  }, [model3D]);

  const handlePrevImage = (): void => {
    const newActiveImageIndex =
      activeImageIndex === 0
        ? filteredImagesWithTypes.length - 1
        : activeImageIndex - 1;

    setState({
      filter,
      activeImageIndex: newActiveImageIndex,
    });
  };

  const handleNextImage = (): void => {
    const newActiveImageIndex =
      activeImageIndex === filteredImagesWithTypes.length - 1
        ? 0
        : activeImageIndex + 1;

    setState({
      filter,
      activeImageIndex: newActiveImageIndex,
    });
  };

  return (
    <PreviewGalleryContainer {...other}>
      <MainImage
        handleNextImage={handleNextImage}
        handlePrevImage={handlePrevImage}
        isShowArrows={filteredImagesWithTypes.length < 2}
        selectedImageWithType={selectedImageWithType}
        onOpenGallery={() => setOpenGallery(true)}
      />
      {isShowImageList && (
        <>
          {withFilters && (
            <ImagesFilterButtons
              handleSelectedFilter={({ currentTarget: { dataset } }) =>
                setState({ filter: dataset.filter!, activeImageIndex: 0 })
              }
              media={media}
              previewImage={previewImage}
              selectedFilter={filter}
            />
          )}
          <ListImages
            filteredImagesWithTypes={filteredImagesWithTypes}
            handleSelectedImage={selectedImageIndex =>
              setState({ filter, activeImageIndex: selectedImageIndex })
            }
            selectedImageIndex={activeImageIndex}
          />
        </>
      )}
      {isOpenGallery &&
        ReactDOM.createPortal(
          <ImagesGallery
            companyName={companyName}
            images={allImages as IImage[]}
            isInit3DModelMode={
              selectedImageWithType.type === MediaFieldsTypes.model3D
            }
            model3D={
              model3D
                ? { ...(model3D as IDocumentFile), modelGeometry }
                : undefined
            }
            selectedImageIndex={allImages.indexOf(selectedImageWithType.image)}
            onRequestClose={() => setOpenGallery(false)}
          />,
          document.body,
        )}
    </PreviewGalleryContainer>
  );
};

export { ImagesPage };
