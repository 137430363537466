import {
  Dispatch,
  FC,
  Fragment,
  memo,
  SetStateAction,
  useCallback,
  useRef,
} from 'react';

import styled, { css } from 'styled-components';

import { useQueryParams } from 'common/hooks';
import { ICommonSearchProductForm } from 'common/types/common-search';
import { ISearchParams, IUrlForm } from 'common/types/search.types';
import {
  IRenderFooterParams,
  IRenderFormSelectorParams,
  SearchByParams,
  useSearchParams,
} from 'entities/search-by-params';
import { SystemSmallIcon } from 'resources/icons/12';
import { Delete1pxIcon } from 'resources/icons/1px-12';
import { RollupIcon } from 'resources/other';
import { Button, Tooltip } from 'UI';

import {
  Footer,
  FooterIconButton,
  PropertiesBar,
  PropertiesButton,
  TabPanel,
  ResetButton,
} from './styled';
import { ProductFormTabs } from './Tabs';

const OpenedParamsPanel = styled.div(
  ({ theme: { colors, borderRadius, shadows } }) => css`
    display: flex;
    flex-direction: column;
    width: 100%;
    max-height: 576px;
    background: ${colors.white};
    border-radius: 0 ${borderRadius}px ${borderRadius}px ${borderRadius}px;
    box-shadow: ${shadows[2]};
    position: absolute;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 1;
  `,
);

interface IProductParamsProps {
  isLoadingProductForms: boolean;
  isOpenFormsPanel: boolean;
  indexDeletedForm?: number;
  searchParameters: IUrlForm[];
  formTypesMap?: string;
  productForms: ICommonSearchProductForm[];
  value: string;
  toggleFormsPanel: VoidFunction;
  setIndexDeletedForm?: Dispatch<SetStateAction<number | undefined>>;
  onSearch: (params: ISearchParams) => void;
}

const ProductParams: FC<IProductParamsProps> = memo(
  ({
    isLoadingProductForms,
    isOpenFormsPanel,
    searchParameters,
    formTypesMap,
    value,
    toggleFormsPanel,
    onSearch,
    ...searchProps
  }) => {
    const [{ analogId }] = useQueryParams();
    const tabListRef = useRef<HTMLDivElement>(null);
    const { activeFormIndex, setActiveFormIndex, onResetProductForms } =
      useSearchParams(value, onSearch);

    const handleSelectForm = useCallback(
      (
        index: number,
        setIndexDeletedForm: Dispatch<SetStateAction<number | undefined>>,
      ): void => {
        setActiveFormIndex(index);

        setTimeout(() => {
          const activeTab = tabListRef.current?.querySelector(
            `button:nth-of-type(${index + 1})`,
          );

          if (activeTab) {
            activeTab.scrollIntoView({ block: 'center', behavior: 'smooth' });
            setIndexDeletedForm?.(undefined);
          }
        }, 100);
      },
      [setActiveFormIndex],
    );

    const renderFormSelector = useCallback(
      ({
        filledForms,
        indexDeletedForm,
        setIndexDeletedForm,
        forms,
      }: IRenderFormSelectorParams) => (
        <ProductFormTabs
          activeFormIndex={activeFormIndex}
          analogId={analogId}
          filledFormsIdsMap={filledForms}
          indexDeletedForm={indexDeletedForm}
          productForms={forms}
          tabListRef={tabListRef}
          onSelectForm={index => handleSelectForm(index, setIndexDeletedForm)}
        />
      ),
      [activeFormIndex, analogId, handleSelectForm],
    );

    const renderFooter = useCallback(
      ({
        searchText,
        filledFormsCount,
        onSubmit,
        onReset,
      }: IRenderFooterParams) => (
        <Footer>
          <FooterIconButton title='Свернуть' onClick={toggleFormsPanel}>
            <RollupIcon size={24} />
          </FooterIconButton>
          <Button
            color='secondary'
            ml='auto'
            size='l'
            variant='outlined'
            onClick={onReset}
          >
            Сбросить все
          </Button>
          <Button
            disabled={!filledFormsCount}
            ml={1}
            mr={4}
            size='l'
            type='submit'
            onClick={() => {
              onSubmit();
              toggleFormsPanel();
            }}
          >
            {searchText}
          </Button>
        </Footer>
      ),
      [toggleFormsPanel],
    );

    const hasFilledProperties = searchParameters.length > 0;

    return (
      <Fragment>
        {isOpenFormsPanel ? (
          <OpenedParamsPanel>
            <SearchByParams
              activeFormIndex={activeFormIndex}
              analogId={analogId}
              formTypesMap={formTypesMap}
              headerComponent={TabPanel}
              isLoading={isLoadingProductForms}
              isOpenFormsPanel={isOpenFormsPanel}
              renderFooter={renderFooter}
              renderFormSelector={renderFormSelector}
              searchParameters={searchParameters}
              value={value}
              onSearch={onSearch}
              {...searchProps}
            />
          </OpenedParamsPanel>
        ) : (
          <PropertiesBar active={hasFilledProperties}>
            <PropertiesButton
              active={hasFilledProperties}
              onClick={toggleFormsPanel}
            >
              <SystemSmallIcon mr={1} />
              Фильтры по характеристикам
            </PropertiesButton>
            {hasFilledProperties && (
              <Tooltip placement='top' title='Сбросить'>
                <ResetButton onClick={onResetProductForms}>
                  Применено фильтров: {searchParameters.length}{' '}
                  <Delete1pxIcon />
                </ResetButton>
              </Tooltip>
            )}
          </PropertiesBar>
        )}
      </Fragment>
    );
  },
);

export { ProductParams };
