import { CSSProperties, forwardRef, ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  background,
  BackgroundProps,
  BorderProps,
  BorderRadiusProps,
  borders as styledBorders,
  color,
  ColorProps,
  display,
  DisplayProps,
  flexbox,
  FlexboxProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  layout,
  LayoutProps,
  position,
  PositionProps,
  shadow as styledShadow,
  ShadowProps,
  space as styledSpace,
  SpaceProps,
  styleFn,
  textAlign,
  TextAlignProps,
  typography,
  TypographyProps,
} from 'styled-system';

import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';

import { ICollapse, useCollapse } from '../CollapseCard';
import { Heading } from '../Heading';
import { IconButton } from '../IconButton';

const gapStyle = (): styleFn => {
  return ({ theme: { space }, gap }) => {
    return (
      (typeof gap === 'string' || typeof gap === 'number') &&
      css`
        gap: ${typeof gap === 'number' ? `${space[gap]}px` : gap};
      `
    );
  };
};

/**
 * Универсальная обертка
 * Покрывает множество незначительных кейсов, где необходим конетейнер с небольшим кол-вом стилей
 */
const Container = styled.div<
  {
    column?: boolean;
    borders?: boolean;
    shadow?: boolean;
    gap?: number | string;
    radius?: boolean | string;
  } & SpaceProps &
    ColorProps &
    BorderProps &
    FlexboxProps &
    LayoutProps &
    DisplayProps &
    PositionProps &
    TextAlignProps &
    TypographyProps &
    BackgroundProps
>(
  ({
    theme: { colors, shadows, borderRadius },
    column,
    borders,
    shadow,
    radius,
  }) => css`
    display: flex;

    ${column &&
    css`
      flex-direction: column;
    `}

    ${borders &&
    css`
      border: 1px solid ${colors.divider};
    `}

    ${shadow &&
    css`
      box-shadow: ${shadows[0]};
    `}

    ${radius &&
    `border-radius: ${typeof radius === 'boolean' ? borderRadius : radius}px;`}

    ${styledSpace};
    ${color};
    ${styledBorders};
    ${layout};
    ${display}
    ${flexbox};
    ${position};
    ${background};
    ${textAlign};
    ${typography};
    ${gapStyle}
  `,
);

/**
 * Container - задающий белый фон и отбивку в виде разделителя слева
 */
const LevelContainer = styled(Container)(
  ({ theme: { colors } }) => css`
    flex-direction: column;
    margin-left: 17px;
    padding-left: 17px;
    border-left: 1px solid ${colors.divider};
    background: ${colors.white};
  `,
);

/**
 * Container - задающий белый фон и бордер
 */
const FrameContainer = styled(Container)(
  ({ theme: { colors, space, borderRadius } }) => css`
    flex-direction: column;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    border-top: none;
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
  `,
);

/**
 * Container - задающий серый фон
 */
const HighlightContainer = styled(Container)<{ center?: boolean }>(
  ({ theme: { colors, space, borderRadius }, center }) => css`
    flex-direction: column;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.highlight[0]};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

    ${center &&
    css`
      justify-content: center;
    `}
  `,
);

const Relative = styled.div<SpaceProps & LayoutProps>(
  () => css`
    position: relative;

    ${styledSpace};
    ${layout}
  `,
);

const BoxInner = styled.div<SpaceProps & DisplayProps>`
  width: 100%;
  min-width: 800px;
  max-width: 1280px;
  margin: 0 auto;

  ${styledSpace};
  ${display}
`;

const FillingWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 0;
`;

const Box = Object.assign(BoxInner, { FillingWrapper });

/**
 * Отображает прямоугольный белый лист с отступами p={4}
 * */
const Paper = styled.article<
  { center?: boolean } & SpaceProps &
    ShadowProps &
    BorderRadiusProps &
    LayoutProps
>(
  ({ theme: { colors, space, shadows }, center }) => css`
    display: block;
    padding: ${space[4]}px;
    background: ${colors.white};
    color: inherit;
    border-radius: 8px;
    box-shadow: ${shadows[0]};

    ${center &&
    css`
      display: flex;
      flex-direction: column;
      align-items: center;
    `}

    ${styledSpace}
    ${styledBorders}
    ${styledShadow}
    ${layout};
  `,
);

const RightActionWrapper = styled.div`
  display: flex;
`;

const LeftHalf = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 0;
    margin-right: ${space[2]}px;
  `,
);

const RightHalf = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const HeaderButton = styled.button`
  display: flex;
  align-items: center;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: left;
`;

const headerSizeStyle =
  (): styleFn =>
  ({ theme: { space }, size, collapse, isOpen, collapseHeight }) => {
    if (size === 'l') {
      return css`
        height: ${space[5]}px;

        ${collapse &&
        css`
          height: ${isOpen ? space[7] : collapseHeight ?? space[5]}px;
        `};
      `;
    }

    return css`
      height: ${space[4]}px;

      ${collapse &&
      css`
        height: ${isOpen ? space[4] : collapseHeight ?? 56}px;
      `};
    `;
  };

type SizesEnum = 's' | 'l';

const HeadingWrapper = styled.div<
  {
    size: SizesEnum;
    collapse?: boolean;
    isOpen?: boolean;
    collapseHeight?: number;
  } & ColorProps &
    SpaceProps &
    FontWeightProps &
    FontSizeProps
>`
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;

  ${color};
  ${styledSpace};
  ${fontWeight};
  ${fontSize}
  ${headerSizeStyle};
`;

interface ICollapseHeader {
  collapse?: boolean;
  isOpen?: boolean;
  collapseHeight?: number;
  color?: string;
  size?: SizesEnum;
  title: ReactNode;
  actions?: ReactNode;
  rightActions?: ReactNode;
  onToggleCollapse?: (boolean) => void;
}

function CollapseHeader({
  collapse,
  isOpen,
  size = 's',
  collapseHeight,
  title,
  actions,
  rightActions,
  onToggleCollapse,
  ...other
}: ICollapseHeader &
  SpaceProps &
  FontSizeProps &
  FontWeightProps): ReactElement {
  return (
    <HeadingWrapper
      aria-label='title'
      collapse={collapse}
      collapseHeight={collapseHeight}
      isOpen={isOpen}
      size={size}
      style={{ marginBottom: collapse && !isOpen ? 0 : undefined }}
      {...other}
    >
      <LeftHalf>
        {collapse ? (
          <HeaderButton onClick={onToggleCollapse}>{title}</HeaderButton>
        ) : (
          <Heading as='h2'>{title}</Heading>
        )}
        {isOpen && actions}
      </LeftHalf>
      <RightHalf>
        {/* Если включен режим collapse, то отображать rightActions только, когда в состоянии isOpen (развернут), если не режим collapse, отображать всегда rightAction */}
        {rightActions && (!collapse || (collapse && isOpen)) && (
          <RightActionWrapper>{rightActions}</RightActionWrapper>
        )}
        {collapse && (
          <IconButton
            as='div'
            ml={0}
            title={isOpen ? 'Свернуть' : 'Развернуть'}
            onClick={onToggleCollapse}
          >
            {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </IconButton>
        )}
      </RightHalf>
    </HeadingWrapper>
  );
}

interface IPanel {
  id?: string;
  className?: string;
  style?: CSSProperties;
  /*
   * Включает режим сворачивания/разворачивания + появляется toggle IconButton
   * Высота заголовка в свернутом состоянии 46px, в развернутом 64px.
   * Высота по умолчанию: (collapse = false) - 46px
   * */
  collapse?: boolean;
  /*
   * Задает высоту CollapseHeader в свернутом состоянии
   * */
  collapseHeight?: number;
  /*
   * Задает контент в заголовок. Отображается всегда.
   * Стили по умолчанию: fontSize = 14px, fontWeight = 600
   * */
  title?: ReactNode;
  children: ReactNode;
  /*
   * Задает контент, идущий после title.
   * Отличие от title в том, что отображается только тогда,
   * когда включен режим сворачивания/разворачивания (collapse) и карточка в развернутом состоянии (isOpen = true)
   * */
  actions?: ReactNode;
  /*
   * Задает контент в крайнее правое положение заголовка.
   * Если collapse = true - отображается, когда карточка в развернутом состоянии (isOpen = true)
   * Если collapse = false - всегда отображается
   * */
  rightActions?: ReactNode;
  /*
   * Задает размер заголовка (по умолчанию равен 'l')
   * */
  size?: SizesEnum;
}

/*
 * Является оберткой Paper, добавляет заголовок и возможность режима сворачивания/разворачивания карточки
 * */
const Panel = forwardRef<
  HTMLDivElement,
  IPanel & ICollapse & SpaceProps & BorderProps & ShadowProps & LayoutProps
>(
  (
    {
      className,
      style,
      id,
      collapse,
      initialOpen = true,
      collapseHeight,
      size = 'l',
      open,
      title,
      children,
      actions,
      rightActions,
      onRequestToggle,
      ...other
    },
    ref,
  ) => {
    const { isOpen, handleToggle } = useCollapse({
      open,
      initialOpen,
      onRequestToggle,
    });

    return (
      <Paper
        ref={ref}
        className={className}
        id={id}
        style={{
          paddingTop: collapse ? '0' : undefined,
          paddingBottom: collapse && !isOpen ? '0' : undefined,
          ...style,
        }}
        {...other}
      >
        <CollapseHeader
          actions={actions}
          collapse={collapse}
          collapseHeight={collapseHeight}
          fontSize={5}
          isOpen={isOpen}
          rightActions={rightActions}
          size={size}
          title={title}
          onToggleCollapse={handleToggle}
        />
        {collapse ? (
          <div
            style={{
              display: isOpen ? 'block' : 'none',
            }}
          >
            {children}
          </div>
        ) : (
          children
        )}
      </Paper>
    );
  },
);

export {
  Container,
  FrameContainer,
  HighlightContainer,
  LevelContainer,
  Relative,
  Box,
  Paper,
  Panel,
  CollapseHeader,
  gapStyle,
};
