import { Fragment, ReactElement } from 'react';

import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchEmployees } from 'common/api/employees.api';
import { INITIAL_DATA_REQUEST_V1 } from 'common/constants/request.const';
import { useGlobalLoader, useQueryParams, useRequest } from 'common/hooks';
import { ModalController, useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { OPERATIONS, Permission, usePermission } from 'entities/permission';
import { CenteredPage, DefaultPage } from 'layouts/Pages';
import { EmployeesIcon, MatchesIcon } from 'resources/placeholders';
import { COMPANY, INFORMATION } from 'routes';
import { Button, ButtonOptions, Confirm, Container, ContextMenuItem, Pagination, Placeholder, SearchInput } from 'UI';

import { EmployeeCard, EmployeesInviteModal, EmployeesSideMenu, InvitationCard } from './components';
import { SupportSections, useSupport } from '../../support';
import {
  createEmployeeInvitationsResend,
  createEmployeeRestore,
  deleteEmployee,
  deleteEmployeeInvitations,
  fetchEmployeeInvitations,
} from '../api';
import { INVITATION_FILTERS } from '../constants';
import { IEmployeeInvitation } from '../types';

const EmployeesWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: ${space[2]}px;
    overflow-y: auto;
  `,
);

const REMOVE_EMPLOYEE = 'REMOVE_EMPLOYEE';
const INVITE_EMPLOYEE = 'INVITE_EMPLOYEE';
const HEADING = 'Сотрудники';
const TIP =
  'Сотрудник компании — физическое лицо, зарегистрированное в системе и прикрепленное к компании. Сотрудники имеют разные уровни доступа к внутренней информации компании и действиям с ней.';

function AddEmployeeButton({ buttonSize, handleModalOpen }): ReactElement {
  const handleInviteEmployeeModalOpen = (): void => {
    handleModalOpen(INVITE_EMPLOYEE);
  };

  return (
    <Permission operation={OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE}>
      <ButtonOptions buttonText='Добавить сотрудника' size={buttonSize}>
        <ContextMenuItem
          as={Link}
          text='По email'
          to={COMPANY.EMPLOYEES_CREATE}
        />
        <ContextMenuItem
          text='По ссылке'
          onClick={handleInviteEmployeeModalOpen}
        />
      </ButtonOptions>
    </Permission>
  );
}

function fetchEmployeesAndInvitations(): Promise<[any, any]> {
  return Promise.all([
    fetchEmployees({ page: 1, itemsPerPage: 20 }),
    fetchEmployeeInvitations({ page: 1, itemsPerPage: 1 }),
  ]);
}

function useEmptyEmployeesAndInvites(): {
  isLoadingAll: boolean;
  isEmpty: boolean;
} {
  const { isLoading, data: isEmpty = true } = useRequest(
    fetchEmployeesAndInvitations,
    [],
    {
      responseToData: ([
        { totalItems: employeeTotalItems },
        { totalItems: invitationTotalItems },
      ]) => employeeTotalItems === 1 && invitationTotalItems === 0,
    },
  );

  return { isLoadingAll: isLoading, isEmpty };
}

interface EmployeesProps {
  isLoading: boolean;
  isTrashed?: '0' | '1';
  employees: IEmployeeInvitation[];
  updateDataEmployees: () => void;
}

function Employees({
  isLoading,
  isTrashed,
  employees,
  updateDataEmployees,
}: EmployeesProps): ReactElement | null {
  const notify = useNotify();
  const [, querySet] = useQueryParams();
  const hasInviteEmployee = usePermission(OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE);
  const { handleModalOpen, handleModalClose } = useModalContext();
  const isEmptyEmployees = !isLoading && employees.length === 0;

  const handleDelete = (id: string): void => {
    handleModalOpen(REMOVE_EMPLOYEE, {
      size: 'small',
      kind: 'delete',
      title: 'Удалить сотрудника?',
      text: 'Удаленный сотрудник потеряет доступ к работе с компанией, ее товарами и партнерами. Пользователь получит статус физического лица, свободно зарегистрированного в системе.',
      onConfirm: () => {
        deleteEmployee(id).then(() => {
          updateDataEmployees();
          handleModalClose();
          notify.success('Сотрудник удален.');
        });
      },
    });
  };

  const handleRecovery = (id: string): void => {
    createEmployeeRestore(id).then(() => {
      notify.success('Сотрудник восстановлен');
      updateDataEmployees();
    });
  };

  // нет удаленных сотрудников
  if (isTrashed === '1' && isEmptyEmployees) {
    return (
      <Placeholder
        hideInfo
        icon={EmployeesIcon}
        text='У компании нет удаленных сотрудников'
      />
    );
  }

  // нет подтвержденных сотрудников
  if (isEmptyEmployees) {
    if (hasInviteEmployee) {
      return (
        <Placeholder
          hideInfo
          icon={EmployeesIcon}
          text={
            <Fragment>
              У компании пока нет подтвержденных сотрудников.
              <br />
              Проверьте{' '}
              <Button
                as={Link}
                fontSize={5}
                to={querySet(() => ({ state: INVITATION_FILTERS.ALL }))}
                variant='string'
              >
                приглашения
              </Button>{' '}
              или добавьте нового сотрудника.
            </Fragment>
          }
          title='Добавьте сотрудников'
        />
      );
    }

    return (
      <Placeholder
        hideInfo
        icon={EmployeesIcon}
        text='У компании пока нет подтвержденных сотрудников'
      />
    );
  }

  return (
    <Fragment>
      {employees.map(employee => (
        <EmployeeCard
          key={employee.id}
          employee={employee}
          updateData={updateDataEmployees}
          onDelete={handleDelete}
          onRecovery={handleRecovery}
        />
      ))}
    </Fragment>
  );
}

const enum InvitationFilters {
  all = 'all',
  pending = 'pending',
  canceled = 'canceled',
  expired = 'expired',
}

interface InvitesProps {
  isLoading: boolean;
  invites: IEmployeeInvitation[];
  filterState: InvitationFilters;
  updateDataInvitations: () => void;
}

function Invites({
  isLoading,
  filterState,
  invites,
  updateDataInvitations,
}: InvitesProps): ReactElement | null {
  const notify = useNotify();
  const hasInviteEmployee = usePermission(OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE);
  const isEmptyInvitations = !isLoading && invites.length === 0;

  const handleDelete = (id: string): void => {
    deleteEmployeeInvitations(id).then(() => {
      notify.success('Сотрудник удален');
      updateDataInvitations();
    });
  };

  const handleInvite = (id: string): void => {
    createEmployeeInvitationsResend(id).then(() => {
      notify.success('Приглашение сотруднику выслано');
      updateDataInvitations();
    });
  };

  // нет всех, либо подтвержденных приглашений
  if (
    isEmptyInvitations &&
    (filterState === INVITATION_FILTERS.ALL ||
      filterState === INVITATION_FILTERS.PENDING)
  ) {
    if (hasInviteEmployee) {
      return (
        <Placeholder
          hideInfo
          icon={EmployeesIcon}
          text={
            <Fragment>
              У компании нет необработанных приглашений.
              <br />
              Чтобы отправить приглашение, добавьте нового сотрудника.
            </Fragment>
          }
          title='Добавьте сотрудников'
        />
      );
    }

    return (
      <Placeholder
        hideInfo
        icon={EmployeesIcon}
        text='У компании нет необработанных приглашений'
      />
    );
  }

  // нет отклоненных или просроченных приглашений
  if (
    isEmptyInvitations &&
    (filterState === INVITATION_FILTERS.CANCELED ||
      filterState === INVITATION_FILTERS.EXPIRED)
  ) {
    return (
      <Placeholder
        hideInfo
        icon={EmployeesIcon}
        text='У компании нет необработанных приглашений'
      />
    );
  }

  return (
    <Fragment>
      {invites.map(invite => (
        <InvitationCard
          key={invite.id}
          invitation={invite}
          updateData={updateDataInvitations}
          onDelete={handleDelete}
          onInvite={handleInvite}
        />
      ))}
    </Fragment>
  );
}

function EmployeesBody({
  isLoading,
  isTrashed,
  filterState,
  invites,
  employees,
  updateDataInvitations,
  updateDataEmployees,
}: InvitesProps & EmployeesProps): ReactElement | null {
  if (filterState) {
    return (
      <Invites
        filterState={filterState}
        invites={invites}
        isLoading={isLoading}
        updateDataInvitations={updateDataInvitations}
      />
    );
  }

  return (
    <Employees
      employees={employees}
      isLoading={isLoading}
      isTrashed={isTrashed}
      updateDataEmployees={updateDataEmployees}
    />
  );
}

function EmployeesList(): ReactElement | null {
  const history = useHistory();
  const [
    {
      isTrashed,
      state: filterState,
      searchValue = '',
      page = 1,
      itemsPerPage = 20,
    },
    querySet,
  ] = useQueryParams({ page: Number, itemsPerPage: Number });
  const { isLoadingAll, isEmpty } = useEmptyEmployeesAndInvites();
  const { handleModalOpen } = useModalContext();

  const {
    isLoading: isLoadingEmployees,
    data: employees = INITIAL_DATA_REQUEST_V1,
    updateData: updateDataEmployees,
  } = useRequest(!filterState && fetchEmployees, [
    {
      page,
      itemsPerPage,
      orderBy: 'familyName',
      orderDirection: 'ASC',
      isTrashed: isTrashed ? Number(isTrashed) : undefined,
      search: searchValue,
    },
  ]);

  const {
    isLoading: isLoadingInvitations,
    data: invitations = INITIAL_DATA_REQUEST_V1,
    updateData: updateDataInvitations,
  } = useRequest(filterState && fetchEmployeeInvitations, [
    {
      page,
      itemsPerPage,
      orderBy: 'updatedAt',
      orderDirection: 'DESC',
      state: filterState !== INVITATION_FILTERS.ALL ? filterState : undefined,
      search: searchValue,
    },
  ]);

  useSupport({
    sectionKey: SupportSections.employees,
  });

  const handleSearch = (value: string = ''): void => {
    history.push(
      querySet(params => ({
        ...params,
        page: 1,
        searchValue: value,
      })),
    );
  };

  const isLoading: boolean =
    isLoadingAll || isLoadingEmployees || isLoadingInvitations;
  const totalItems: number = filterState
    ? invitations.totalItems
    : employees.totalItems;
  const hasMatches = totalItems !== 0;
  const isShowSearch = isLoading || searchValue !== '' || hasMatches;
  const isEmptySearchResult = searchValue !== '' && !hasMatches;

  useGlobalLoader(isLoading);
  if (isLoading && isEmpty) {
    return null;
  }

  if (isEmpty) {
    return (
      <DefaultPage
        heading={{
          heading: HEADING,
          tip: TIP,
        }}
      >
        <Placeholder
          buttons={
            <AddEmployeeButton
              buttonSize='l'
              handleModalOpen={handleModalOpen}
            />
          }
          icon={EmployeesIcon}
          section={HEADING}
          text={[
            'Сотрудники получат доступ к данным вашей компании',
            <br key='1' />,
            'и смогут работать в системе от ее лица.',
          ]}
          title='Добавьте сотрудников'
          to={INFORMATION.GUIDES.ADD_EMPLOYEE}
        />
        <ModalController
          component={EmployeesInviteModal}
          type={INVITE_EMPLOYEE}
        />
      </DefaultPage>
    );
  }

  return (
    <Fragment>
      <CenteredPage
        bg='background'
        heading={{
          heading: HEADING,
          tip: TIP,
          controls: (
            <>
              {isShowSearch && (
                <SearchInput
                  isBordered
                  iconVariant='gray'
                  initialValue={searchValue}
                  mb='0px'
                  mr={1}
                  placeholder='Фамилия, Имя, Отчество, Email, должность'
                  width={400}
                  onChange={handleSearch}
                />
              )}
              <AddEmployeeButton
                buttonSize='xs'
                handleModalOpen={handleModalOpen}
              />
            </>
          ),
        }}
        p='0px'
        subMenu={<EmployeesSideMenu />}
      >
        <Container height='100%' overflow='auto'>
          <EmployeesWrapper>
            {isEmptySearchResult ? (
              <Placeholder
                hideInfo
                icon={MatchesIcon}
                text='Уточните поисковый запрос и пропробуйте еще раз'
                title='Совпадений не найдено'
              />
            ) : (
              <EmployeesBody
                employees={employees.member}
                filterState={filterState}
                invites={invitations.member}
                isLoading={isLoading}
                isTrashed={isTrashed}
                updateDataEmployees={updateDataEmployees}
                updateDataInvitations={updateDataInvitations}
              />
            )}
            <Pagination mt={2} pl={-18} totalItems={totalItems} width={762} />
          </EmployeesWrapper>
        </Container>
      </CenteredPage>
      <ModalController component={Confirm} type={REMOVE_EMPLOYEE} />
      <ModalController
        component={EmployeesInviteModal}
        type={INVITE_EMPLOYEE}
      />
    </Fragment>
  );
}

export { EmployeesList };
