import { Fragment, useState, memo, FC } from 'react';

import { useHistory } from 'react-router-dom';

import { findArticles } from 'common/api/company-management';
import {
  ARTICLE_PUBLIC_STATUS,
  ARTICLE_PUBLICATION_VISIBILITY,
} from 'common/constants/company-management';
import { SECONDARY_ITEMS_PER_PAGE } from 'common/constants/pagination.const';
import { INITIAL_DATA_REQUEST_V2 } from 'common/constants/request.const';
import { useQueryParams, useRequest } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { IQuerySorts, IQueryPaginator } from 'common/types/request.types';
import { OrderDirectionType } from 'common/types/sorts.types';
import { CenteredPage } from 'layouts/Pages';
import { SortDownIcon, SortUpIcon } from 'resources/icons/18';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { MatchesIcon } from 'resources/placeholders';
import {
  Button,
  Spinner,
  Tooltip,
  Container,
  IconButton,
  Pagination,
  ContextMenu,
  Placeholder,
  SearchInput,
  ContextMenuItem,
} from 'UI';

import { SideMenu, ArticleCard, CardsContainer } from './components';

const enum SortedBy {
  title = 'title',
  publishedAt = 'publishedAt',
  updatedAt = 'updatedAt',
}

const SORTED_BY_TEXT = {
  [SortedBy.title]: 'По названию',
  [SortedBy.publishedAt]: 'По дате публикации',
  [SortedBy.updatedAt]: 'По дате обновления',
};

const SubMenu = <SideMenu />;
const CONTEXT_OFFSET: [number, number] = [0, 10];

interface ISearchParams extends IQueryPaginator, IQuerySorts {
  type?: string;
  companyId?: string[];
  searchValue?: string;
  status?: string;
  visibility?: string;
  tag: string[];
  category?: string;
  partnersOnly?: boolean;
  publishedAtAfter: string;
  publishedAtBefore: string;
}

const DictionariesArticles: FC = memo(() => {
  const history = useHistory();

  const [queryParams, querySet] = useQueryParams({
    page: Number,
    itemsPerPage: Number,
  });

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);

  const {
    partnersOnly,
    type,
    companyId,
    searchValue = '',
    tag,
    category,
    publishedAtAfter,
    publishedAtBefore,
    page = 1,
    itemsPerPage = SECONDARY_ITEMS_PER_PAGE[0],
    orderBy = `${SortedBy.updatedAt}`,
    orderDirection = 'DESC',
  }: ISearchParams = queryParams;

  const { isLoading, data: { items, count } = INITIAL_DATA_REQUEST_V2 } =
    useRequest(findArticles, [
      {
        partnersOnly,
        types: type,
        companies: companyId ?? undefined,
        search: searchValue || undefined,
        statuses: ARTICLE_PUBLIC_STATUS,
        visibilities: ARTICLE_PUBLICATION_VISIBILITY,
        tags: tag,
        category,
        publishedAtAfter,
        publishedAtBefore,
        page,
        itemsPerPage,
        orderBy,
        orderDirection,
      },
    ]);

  const handleSearch = (value = ''): void => {
    history.push(
      querySet(params => ({ ...params, page: 1, searchValue: value })),
    );
  };

  const isDisabledFilter = count === 0;
  const isAsc = orderDirection === 'ASC';

  const handleClick = ({ currentTarget }): void => setAnchorEl(currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const handleSort = (
    key: string = orderBy,
    direction: OrderDirectionType = orderDirection,
  ): void => {
    history.push(
      querySet(params => ({
        ...params,
        page: 1,
        orderBy: key,
        orderDirection: direction,
      })),
    );
  };

  return (
    <Fragment>
      <CenteredPage
        bg='white'
        heading={{
          heading: 'Справочник публикаций',
          backLink: history.goBack,
          controls: (
            <Container alignItems='center' justifyContent='center'>
              <IconButton
                disabled={isDisabledFilter}
                mr={0}
                size={28}
                onClick={() => handleSort(orderBy, isAsc ? 'DESC' : 'ASC')}
              >
                <Tooltip
                  arrow
                  hint
                  title={
                    isAsc
                      ? 'Сортировать по убыванию'
                      : 'Сортировать по возрастанию'
                  }
                >
                  {orderDirection === 'ASC' ? <SortDownIcon /> : <SortUpIcon />}
                </Tooltip>
              </IconButton>
              <Button
                color='secondary'
                fontSize={3}
                mr={1}
                variant='string'
                onClick={anchorEl ? handleClose : handleClick}
              >
                {SORTED_BY_TEXT[orderBy]}
                {anchorEl ? (
                  <ArrowUp1pxIcon ml={0} />
                ) : (
                  <ArrowDown1pxIcon ml={0} />
                )}
              </Button>
              <ContextMenu
                anchorEl={anchorEl}
                offset={CONTEXT_OFFSET}
                open={Boolean(anchorEl)}
                placement='bottom-end'
                width={220}
                onRequestClose={handleClose}
              >
                <ContextMenuItem
                  px={1}
                  text={SORTED_BY_TEXT.title}
                  onClick={() => handleSort(SortedBy.title)}
                />
                <ContextMenuItem
                  px={1}
                  text={SORTED_BY_TEXT.updatedAt}
                  onClick={() => handleSort(SortedBy.updatedAt)}
                />
                <ContextMenuItem
                  px={1}
                  text={SORTED_BY_TEXT.publishedAt}
                  onClick={() => handleSort(SortedBy.publishedAt)}
                />
              </ContextMenu>
              <SearchInput
                isBordered
                delay={700}
                initialValue={searchValue}
                mb='0px'
                ml={1}
                placeholder='Название или тег'
                width='300px'
                onChange={handleSearch}
              />
            </Container>
          ),
        }}
        height={!isLoading && count > 0 ? 'max-content' : 'inherit'}
        maxWidth='1122px'
        minWidth='800px'
        subMenu={SubMenu}
      >
        <Spinner delay={400} height='100%' loading={isLoading}>
          {count === 0 ? (
            <Placeholder
              hideInfo
              icon={MatchesIcon}
              text={
                <Fragment>
                  Совпадений не найдено.
                  <br />
                  Измените запрос и попробуйте снова.
                </Fragment>
              }
            />
          ) : (
            <CardsContainer>
              {items.map(article => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </CardsContainer>
          )}
          <Pagination
            itemsPerPageOptions={SECONDARY_ITEMS_PER_PAGE}
            mt={2}
            totalItems={count}
          />
        </Spinner>
      </CenteredPage>
    </Fragment>
  );
});

export { DictionariesArticles };
