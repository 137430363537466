import { Fragment, useState, useEffect, FC } from 'react';

import { useSelector } from 'react-redux';
import styled, { css, useTheme } from 'styled-components';

import {
  setCompanyProductsLifeState,
  fetchProductManagementSearchProducts,
} from 'common/api/products.api';
import { EMPTY_ARRAY } from 'common/constants/emptyDataStructures.const';
import { POINT_KEYS } from 'common/constants/onboarding.const';
import {
  HINTS,
  MARK_AS_DRAFT,
  PRODUCT_COLUMN_KEYS,
  PRODUCT_ACCESS_STATUSES,
} from 'common/constants/products.const';
import { TABLE_COLUMN_KEYS } from 'common/constants/tableColumns.const';
import { useHint, useRequest, useSelect } from 'common/hooks';
import {
  IProduct,
  ProductionStates,
  ProductLifeState,
} from 'common/types/product.types';
import {
  PointsOfSaleModal,
  LINK_TO_POINT_OF_SALES,
} from 'components/points-of-sale';
import { PRODUCT_COLUMNS } from 'components/products/columns';
import {
  AddToExportModal,
  ShareProductModal,
  ProductsRemoveModal,
  SendToInternalModal,
  SendToModerationModal,
  ProductPublicationModal,
  ProductInCompositionModal,
  RemoveFromCollectionModal,
  ADD_TO_EXPORT,
  SHARE_PRODUCT,
  REMOVE_PRODUCT,
  PUBLISH_PRODUCT,
  SEND_TO_INTERNAL,
  SEND_TO_MODERATION,
  PRODUCT_IN_COMPOSITION,
  REMOVE_FROM_COLLECTION,
} from 'components/products/modals';
import { TABLE_COLUMNS } from 'components/tableColumns';
import { EducationPoint } from 'entities/education';
import { getCompanyId, hasAccessRole } from 'entities/identity';
import { ModalController, useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { OPERATIONS, Permission, usePermission } from 'entities/permission';
import { useAppSelector } from 'store';
import {
  Confirm,
  Container,
  DataTable,
  Pagination,
  PLACEHOLDER_TYPES,
  IDataTableColumn,
} from 'UI';
import { colors } from 'UI/theme';

import { COMPANY_PRODUCTS_COLUMNS } from './components/columns';
import { ProductVersionsList } from './components/ProductVersionsList';
import { ProductsControls } from '../../../products/_shared/controls/Controls';
import { ProductsTableHeader } from '../../../products/_shared/TableHeader';
import {
  ProductGroupSelectModal,
  SELECT_PRODUCT_GROUP,
} from '../../../products/product-groups/modal/Select';
import { fetchConsolidatedProductVersion } from '../../api';
import { PRODUCT_VERSIONS, SEARCH_BY_PROPERTIES_MODAL } from '../../constants';
import { SearchByPropertiesModal } from '../modal';

/**
 * У конструктора есть возможность поменять режим отображения таблицы, появляется кнопка, поэтому меняется паддинг
 */
const StyledProductsTable = styled(DataTable)<{ isConstructor: boolean }>(
  ({ theme: { space }, isConstructor }) => css`
    th {
      padding: ${isConstructor ? space[0] : space[1]}px;
    }
  `,
);

const INITIAL_DATA = { totalItems: 0, member: EMPTY_ARRAY };

/**
 * Т.к. этот компонент у нас переиспользуется, то теперь он отвечает за отображение данных с двух запросов:
 * 1. Обычный список товаров - (post: '/product-management/search-products')
 * 2. Список версий (а точнее агрегирующих версий с полем versionCount) - для этого используется новый запрос
 * (get: '/product-management/consolidated-product-versions')
 * Отображение зависит от наличия квери-параметра groupByVersions, соответственно, от этого зависит, какие данные
 * попадут в итоговые data, isLoading, updateProduct
 */
const ProductsCompanyTable: FC<any> = ({
  kind,
  productionStates,
  createdAtBefore,
  createdAtAfter,
  updatedAtBefore,
  updatedAtAfter,
  columnsCondition: outerColumnsCondition = {},
  withoutCollection,
  groupByVersions,
  withTask,
  withoutTask,
  taskStatuses,
  updatedBy,
  createdBy,
  brand,
  searchValue,
  categories,
  collection,
  lifeState,
  searchParameters,
  page,
  itemsPerPage,
  orderBy,
  orderDirection,
  querySet,
  onSort,
  onSearchDisabled,
  onSetProductsCount,
  manufacturers,
}) => {
  const notify = useNotify();
  const theme = useTheme();

  const { handleModalOpen, handleModalClose } = useModalContext();
  const companyId = useSelector(getCompanyId);

  const [expandedRows, setExpandedRows] = useState<Record<string, string>>({});
  const [errorIds, setErrorsIds] = useState(new Set());

  const isAllLifeStates =
    Array.isArray(lifeState) &&
    Object.keys(ProductLifeState).length === lifeState.length;

  const isConstructor = useAppSelector(state => {
    return (
      hasAccessRole(state, 'chiefConstructor') ||
      hasAccessRole(state, 'juniorConstructor')
    );
  });

  const {
    isLoading,
    data = INITIAL_DATA,
    updateData,
  } = useRequest(
    groupByVersions
      ? fetchConsolidatedProductVersion
      : fetchProductManagementSearchProducts,
    [
      {
        search: searchValue,
        lifeStates: isAllLifeStates ? lifeState : [lifeState],
        productionStates,
        categories,
        accessStatus: PRODUCT_ACCESS_STATUSES.OWN,
        brand,
        kind,
        searchParameters,
        withoutCollection,
        collection,
        withTask,
        withoutTask,
        taskStatuses,
        createdBy,
        createdAtBefore,
        createdAtAfter,
        updatedBy,
        updatedAtBefore,
        updatedAtAfter,
        page,
        itemsPerPage,
        orderBy,
        orderDirection,
        ...(manufacturers && { manufacturer: { ids: manufacturers } }),
      },
    ],
    { onSuccess: ({ totalItems }) => onSetProductsCount(totalItems) },
  );

  const { isSelectedAll, selected, bindItem, handleSelectAll } = useSelect({
    options: data.member,
    revalidate: true,
    shouldReset: { page, brand, collection, lifeState },
  });

  const {
    isViewed: isViewedEditExpiresHint,
    handleToggleViewed: handleToggleFullProductEditExpiredViewed,
  } = useHint(HINTS.FULL_PRODUCT_EDIT_EXPIRES);
  const { isViewed: isViewedMakeInternalHint } = useHint(
    HINTS.MAKE_PRODUCT_INTERNAL,
  );

  const hasCreateCompanyProductAccess = usePermission(
    OPERATIONS.PRODUCTS_OWN.CREATE_COMPANY_PRODUCT,
  );
  const hasUpdatePointOfSaleAccess = usePermission(
    OPERATIONS.POINTS_OF_SALES.UPDATE_POINT_OF_SALE,
  );

  useEffect(() => {
    onSearchDisabled(data.member.length);
  }, [data.member.length, onSearchDisabled]);

  const updatePage = (products: IProduct[]): void => {
    const totalPages = Math.ceil(data.totalItems / itemsPerPage);
    if (
      page > 1 &&
      totalPages === page &&
      data.member.length === products.length
    ) {
      querySet(params => ({ ...params, page: page > 0 ? page - 1 : 0 }));
    } else {
      updateData();
    }
  };

  const handleDelete = (products: IProduct[]): void => {
    setCompanyProductsLifeState(products, ProductLifeState.trashed).then(() => {
      updatePage(products);
      handleSelectAll(false);
      notify.success(products.length === 1 ? 'Товар удален' : 'Товары удалены');
    });
  };

  const handleInternal = (products: IProduct[]): void => {
    setCompanyProductsLifeState(products, ProductLifeState.internalUsage)
      .then(() => {
        updatePage(products);
        handleSelectAll(false);
        const notifyText =
          Array.isArray(products) && products.length > 1 ? (
            <Fragment>
              Товары назначены внутренними. Вы можете увидеть их в разделе{' '}
              <strong>Внутренние</strong>.
            </Fragment>
          ) : (
            <Fragment>
              Товар назначен внутренним. Вы можете увидеть его в разделе{' '}
              <strong>Внутренние</strong>.
            </Fragment>
          );

        notify.success(notifyText);
      })
      .catch(() => {
        notify.error(
          'В выделенных товарах обнаружены ошибки. Внесите изменения и попробуйте снова.',
        );
      });
  };

  const handlePublish = (products: IProduct[]): void => {
    setCompanyProductsLifeState(products, ProductLifeState.public)
      .then(() => {
        updatePage(products);
        handleSelectAll(false);

        const notifyText =
          Array.isArray(products) && products.length > 1
            ? 'Товары опубликованы'
            : 'Товар опубликован';

        notify.success(notifyText);
      })
      .catch(() => {
        const notifyText =
          Array.isArray(products) && products.length > 1
            ? 'В выделенных товарах обнаружены ошибки. Внесите изменения и попробуйте снова.'
            : 'В товаре обнаружены ошибки. Внесите изменения и попробуйте снова.';

        notify.error(notifyText);
      });
  };

  const handleMarkAsDraft = (products: IProduct[]): void => {
    setCompanyProductsLifeState(products, ProductLifeState.draft).then(
      () => {
        updatePage(products);
        handleSelectAll(false);

        const notifyText =
          Array.isArray(products) && products.length > 1
            ? 'Товары перемещены в черновики'
            : 'Товар перемещен в черновики';

        notify.success(notifyText);
      },
      error => {
        notify.error(error?.response.data['hydra:description']);
      },
    );
  };

  const handleModerate = (products: IProduct[]): void => {
    setCompanyProductsLifeState(products, ProductLifeState.moderation).then(
      () => {
        updatePage(products);
        handleSelectAll(false);

        const notifyText =
          Array.isArray(products) && products.length > 1
            ? `Товары отправлены на модерацию: ${products.length} шт`
            : 'Товар отправлен на модерацию';

        notify.success(notifyText);
      },
      ({ response }) => {
        const errors = response.data?.violations ?? [];

        if (errors.length > 0) {
          const productIds = new Set();

          errors.forEach(({ propertyPath }) => {
            const productId = propertyPath.match(/\[[a-z0-9-]*\]/)?.[0];
            if (productId) productIds.add(productId.slice(1, -1));
          });

          setErrorsIds(productIds);

          notify.error('Минимальный набор обязательных полей не заполнен');
        }
      },
    );
  };

  const handleInternalConfirm = (products: IProduct[]): void => {
    if (!isViewedMakeInternalHint) {
      handleModalOpen(SEND_TO_INTERNAL, {
        onConfirm: () => {
          handleInternal(products);
          handleModalClose();
        },
      });
    } else {
      handleInternal(products);
    }
  };

  const handlePublishConfirm = (products: IProduct[]): void => {
    const validProductionState = ({ productionState: state }): boolean => {
      return (
        state === ProductionStates.inProduction ||
        state === ProductionStates.discontinued
      );
    };

    const isValidProductionState = Array.isArray(products)
      ? products.some(validProductionState)
      : validProductionState(products);

    if (isValidProductionState && !isViewedEditExpiresHint) {
      handleModalOpen(PUBLISH_PRODUCT, {
        products,
        onConfirm: ({ isViewedHintCheck }) => {
          if (isViewedHintCheck) handleToggleFullProductEditExpiredViewed();

          handlePublish(products);
          handleModalClose();
        },
      });
    } else {
      handlePublish(products);
    }
  };

  const handleExpandRow = (index: number, type?: string): void => {
    const isExpanded = expandedRows[index];

    if (isExpanded) {
      setExpandedRows(({ [index]: deletedRow, ...rest }) => rest);
    } else {
      setExpandedRows(prev => ({ ...prev, [index]: type }));
    }
  };

  const columnsCondition = {
    [TABLE_COLUMN_KEYS.EXPANDED_BUTTON]: !!groupByVersions,
    [TABLE_COLUMN_KEYS.CHECKBOX]:
      (hasCreateCompanyProductAccess || hasUpdatePointOfSaleAccess) &&
      lifeState !== ProductLifeState.blocked &&
      lifeState !== ProductLifeState.moderation &&
      !isAllLifeStates,
    [PRODUCT_COLUMN_KEYS.LIFE_STATE]: isAllLifeStates,
    [PRODUCT_COLUMN_KEYS.TASK]: lifeState === ProductLifeState.draft,
    [PRODUCT_COLUMN_KEYS.MODERATION_SENT_AT]:
      lifeState === ProductLifeState.moderation,
    [PRODUCT_COLUMN_KEYS.MODERATION_COMPLETED_AT]:
      lifeState === ProductLifeState.moderationCompleted,
    [TABLE_COLUMN_KEYS.UPDATED_AT]:
      lifeState === ProductLifeState.draft ||
      lifeState === ProductLifeState.public ||
      lifeState === ProductLifeState.internalUsage ||
      isAllLifeStates,
    [TABLE_COLUMN_KEYS.REJECTED_AT]: lifeState === ProductLifeState.rejected,
    [TABLE_COLUMN_KEYS.BLOCKED_AT]: lifeState === ProductLifeState.blocked,
    [TABLE_COLUMN_KEYS.TRASHED_AT]: lifeState === ProductLifeState.trashed,
    [TABLE_COLUMN_KEYS.CONTROLS]:
      hasCreateCompanyProductAccess || hasUpdatePointOfSaleAccess,
    ...outerColumnsCondition,
  };

  const columnFilter = ({ id }: IDataTableColumn): boolean => {
    const isShowColumns = columnsCondition[id!];
    return typeof isShowColumns === 'boolean' ? isShowColumns : true;
  };

  const columns: IDataTableColumn[] = [
    COMPANY_PRODUCTS_COLUMNS.expandedButton({
      expandedRows,
      onExpandRow: handleExpandRow,
    }),
    TABLE_COLUMNS.checkbox({
      disabled: !data.member.length,
      isSelectedAll,
      bindItem,
      handleSelectAll,
    }),
    PRODUCT_COLUMNS.previewImage(),
    PRODUCT_COLUMNS.name(),
    PRODUCT_COLUMNS.mainProperties,
    PRODUCT_COLUMNS.version,
    PRODUCT_COLUMNS.category,
    PRODUCT_COLUMNS.lifeState(),
    PRODUCT_COLUMNS.type(),
    PRODUCT_COLUMNS.companyName(),
    PRODUCT_COLUMNS.taskStatus,
    PRODUCT_COLUMNS.tags(),
    COMPANY_PRODUCTS_COLUMNS.author,
    PRODUCT_COLUMNS.sentToModerationAt,
    PRODUCT_COLUMNS.moderationCompletedAt,
    TABLE_COLUMNS.updatedAt,
    TABLE_COLUMNS.rejectedAt,
    TABLE_COLUMNS.blockedAt,
    TABLE_COLUMNS.trashedAt,
    {
      key: 'controls',
      sortable: false,
      truncate: false,
      align: 'right',
      colSpan: 1,
      header: { minWidth: 85 },
      render: (_, { versionCount, ...product }, index) => {
        /**
         * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
         * если > 1, значит, минимум одна версия была создана, тогда не отображаем кнопку контекстного меню
         * (но она отображается уже для версий - "вложенных" товаров в таблице)
         */
        const isAggregatingProduct = versionCount > 1;

        if (isAggregatingProduct) return null;

        const updateDataControls = (): void => {
          handleExpandRow(index);
          updateData();
        };

        return (
          <ProductsControls
            companyId={companyId}
            product={product}
            updateData={updateDataControls}
            onDelete={handleDelete}
            onInternal={handleInternalConfirm}
            onMarkAsDraft={handleMarkAsDraft}
            onModerate={handleModerate}
            onPublish={handlePublishConfirm}
            onRequestModal={handleModalOpen}
          />
        );
      },
    } as IDataTableColumn,
    COMPANY_PRODUCTS_COLUMNS.viewSettings({
      onSetExpandedRows: setExpandedRows,
    }),
  ].filter(columnFilter);

  const expandedTypes = {
    [PRODUCT_VERSIONS]: item => (
      <ProductVersionsList columns={columns} root={item} />
    ),
  };

  return (
    <Container column flexGrow={1} height='100%' width='100%'>
      {!isAllLifeStates && (
        <Permission
          operation={[
            OPERATIONS.POINTS_OF_SALES.UPDATE_POINT_OF_SALE,
            OPERATIONS.PRODUCTS_OWN.CREATE_COMPANY_PRODUCT,
          ]}
        >
          <EducationPoint section={POINT_KEYS.TABLE_HEADER}>
            <ProductsTableHeader
              isTableInner
              hasSelected={selected.length > 0}
              lifeState={lifeState}
              productGroupId={collection}
              products={selected}
              updateData={updateData}
              onDelete={handleDelete}
              onInternal={() => handleInternalConfirm(selected)}
              onMarkAsDraft={() => handleMarkAsDraft(selected)}
              onModerate={() => handleModerate(selected)}
              onPublish={() => handlePublishConfirm(selected)}
              onSelectAll={handleSelectAll}
            />
          </EducationPoint>
        </Permission>
      )}
      <EducationPoint
        section={[
          {
            section: POINT_KEYS.TABLE_PRODUCT_CONTEXT_MENU,
            style: { right: 0 },
            condition: data.member.length > 0,
          },
          {
            section: POINT_KEYS.TABLE_PRODUCT_ROW,
            condition: data.member.length > 0,
          },
        ]}
      >
        <StyledProductsTable
          isInnerTable
          columns={columns}
          data={data.member}
          expandedRows={expandedRows}
          expandedTypes={expandedTypes}
          expandedUnionCells={false}
          isConstructor={isConstructor}
          loading={isLoading}
          orderBy={orderBy}
          orderDirection={orderDirection}
          placeholder={{
            type: searchValue
              ? PLACEHOLDER_TYPES.MATCHES
              : PLACEHOLDER_TYPES.PRODUCTS,
          }}
          rowStyle={(item: IProduct, index: number) => {
            const { isSelected } = bindItem(item);
            const isExpanded = expandedRows[index];

            if (isExpanded) {
              return {
                borderLeft: `${theme.space[0]}px solid ${colors.text.disabled}`,
              };
            }

            return {
              ...(isSelected && { backgroundColor: 'highlight.0' }),
              error: errorIds.has(item.id),
              height: '70px',
            };
          }}
          onSort={onSort}
        />
      </EducationPoint>
      <Pagination totalItems={data.totalItems} />
      <ModalController
        component={PointsOfSaleModal}
        type={LINK_TO_POINT_OF_SALES}
      />
      <ModalController component={ProductsRemoveModal} type={REMOVE_PRODUCT} />
      <ModalController component={ShareProductModal} type={SHARE_PRODUCT} />
      <ModalController
        component={ProductGroupSelectModal}
        type={SELECT_PRODUCT_GROUP}
      />
      <ModalController component={Confirm} type={MARK_AS_DRAFT} />
      <ModalController
        component={ProductPublicationModal}
        type={PUBLISH_PRODUCT}
      />
      <ModalController
        component={SendToModerationModal}
        type={SEND_TO_MODERATION}
      />
      <ModalController
        component={SendToInternalModal}
        type={SEND_TO_INTERNAL}
      />
      <ModalController component={AddToExportModal} type={ADD_TO_EXPORT} />
      <ModalController
        component={RemoveFromCollectionModal}
        type={REMOVE_FROM_COLLECTION}
      />
      <ModalController
        component={SearchByPropertiesModal}
        type={SEARCH_BY_PROPERTIES_MODAL}
      />
      <ModalController
        component={ProductInCompositionModal}
        type={PRODUCT_IN_COMPOSITION}
      />
    </Container>
  );
};

export { ProductsCompanyTable };
