import { Fragment } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { DEFAULT_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { POINT_KEYS } from 'common/constants/onboarding.const';
import {
  LIFE_STATE_COLORS,
  LIFE_STATE_NAMES,
  PRODUCT_CATEGORY_TEXT,
  PRODUCT_COLUMN_KEYS,
  PRODUCTION_STATE_NAMES,
} from 'common/constants/products.const';
import { TABLE_COLUMN_KEYS } from 'common/constants/tableColumns.const';
import { Nullable } from 'common/types/common.types';
import { ProductionStates } from 'common/types/product.types';
import { getImageURL, getTablesDate, getTextByNumber } from 'common/utils';
import { getProductFullName } from 'common/utils/products/fullName';
import { getProductPagePath } from 'common/utils/products/pagePath';
import { EducationPoint } from 'entities/education';
import { COMPANY, getPath, PRODUCTS } from 'routes';
import {
  Avatar,
  Badge,
  Container,
  IDataTableColumn,
  Tags,
  Text,
  Tooltip,
} from 'UI';

import { MainProperties } from './properties';
import { TaskStatusBadge, UserAvatar } from './TaskStatusBadge';

const OwnProductBadge = styled.div(
  ({ theme: { colors } }) => css`
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${colors.primary.main};
    min-width: 6px;
    margin-left: 4px;
  `,
);

/**
 * Мапа, в которой лежат колонки для таблицы, относящиеся к сущности "Товары".
 *
 * P.s.: также есть мапа TABLE_COLUMNS, которая содержит "общие" колонки,
 * например, checkbox, createdAt, updatedAt и т.д., которые могут использоваться и в
 * других таблицах проекта. {@link TABLE_COLUMNS}
 */
const PRODUCT_COLUMNS = {
  previewImage: ({
    noLink = false,
    target = '_self',
    key = 'previewImage',
  }: {
    noLink?: boolean;
    target?: '_blank' | '_self' | '_parent' | '_top';
    key?: string;
  } = {}): IDataTableColumn => ({
    id: PRODUCT_COLUMN_KEYS.PREVIEW_IMAGE,
    key,
    header: { text: 'Фото', fixedWidth: 62 },
    sortable: false,
    truncate: false,
    render: (_, item) => {
      const product =
        (item?.reference?.id && item?.reference.id) ||
        item?.product || // для других, где приходит product
        item; // для моих и доступных товаров

      const { id, previewImage, versionCount } = product;

      /**
       * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
       * если > 1, значит, минимум одна версия была создана, тогда не даем возможность по клику на аватарку перейти на ВС
       * (у агрегирующей карточки такой возможности нет)
       */
      const isAggregatingProduct = versionCount > 1;

      return (
        <Avatar
          as={!id || noLink || isAggregatingProduct ? 'div' : Link}
          size='s'
          target={target}
          to={getProductPagePath(product)}
          url={getImageURL(previewImage)}
        />
      );
    },
  }),
  mainProperties: {
    id: PRODUCT_COLUMN_KEYS.MAIN_PROPERTIES,
    key: 'mainProperties',
    header: { fixedWidth: 50 },
    sortable: false,
    truncate: false,
    align: 'center',
    render: (_, product) => <MainProperties product={product} />,
  } as IDataTableColumn,
  name: ({ target = '_self', sortable = true } = {}): IDataTableColumn => ({
    id: TABLE_COLUMN_KEYS.NAME,
    key: 'name',
    header: { text: 'Название', minWidth: 140 },
    sortable,
    render: (_, item) => {
      const { id, name, designation, category, type, versionCount } =
        item?.reference?.id || // для состава
        item.product || // для других, где приходит product
        item; // для моих и доступных товаров
      const productName = getProductFullName({ name, designation, category });

      /**
       * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
       * если > 1, значит, минимум одна версия была создана, тогда отображаем просто название товара (без ссылки)
       */
      const isAggregatingProduct = versionCount > 1;

      if (isAggregatingProduct) {
        return productName;
      }

      // TODO refactor productColumn.name() api
      const route = category
        ? getProductPagePath({ id, category, type })
        : getPath(PRODUCTS.SPECIFICATION_PAGE, { id });

      return (
        <Fragment>
          <Text as={Link} color='text.primary' target={target} to={route}>
            {productName || <Text color='text.disabled'>Не указан</Text>}
          </Text>
          {item?.slot && (
            <Text color='text.secondary' fontSize={2}>
              Слот
            </Text>
          )}
        </Fragment>
      );
    },
  }),
  version: {
    id: PRODUCT_COLUMN_KEYS.VERSION,
    key: 'version',
    header: 'Версия',
    sortable: false,
    render: (version, { versionCount }) => {
      /**
       * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
       * если > 1, значит, минимум одна версия была создана, тогда отображаем соответствующий бедж с кол-вом версий
       */
      const isAggregatingProduct = versionCount > 1;
      if (isAggregatingProduct) {
        const text = getTextByNumber(versionCount, [
          'версия',
          'версии',
          'версий',
        ]);

        return (
          <Badge label={`${versionCount} ${text}`} type={Badge.TYPES.DEFAULT} />
        );
      }

      /**
       * isRoot - костыльный флаг, который в будущем должен убраться
       * см {@link ProductVersionsList}
       */
      const { parent, number, isRoot = false } = version ?? {};

      /**
       * Если parent !== null, значит товар является версией (и кроме parent есть еще поле number)
       */
      if (parent) {
        return (
          <Badge
            label={`Версия ${number}`}
            title={{
              arrow: true,
              maxWidth: 385,
              title: (
                <Container column p={1}>
                  <Text
                    color='text.secondary'
                    fontSize={2}
                    fontWeight={600}
                    mb={0}
                  >
                    Предыдущая версия изделия
                  </Text>
                  <Link to={getPath(PRODUCTS.PRODUCT_PAGE, { id: parent.id })}>
                    {getProductFullName(parent)}
                  </Link>
                </Container>
              ),
            }}
            type={Badge.TYPES.DEFAULT}
          />
        );
      }

      /**
       * Если isRoot, то товар - корневой
       */
      if (isRoot) {
        return (
          <Badge
            label='Версия 1'
            title={{
              maxWidth: 208,
              title: 'Первая версия данного изделия',
            }}
            type={Badge.TYPES.DEFAULT}
          />
        );
      }

      /**
       * Иначе товар вообще не является версией (или корнем), тогда ничего не отображаем
       */
      return null;
    },
  } as IDataTableColumn,
  category: {
    id: PRODUCT_COLUMN_KEYS.CATEGORY,
    key: 'category',
    header: 'Категория',
    render: category => PRODUCT_CATEGORY_TEXT[category],
  } as IDataTableColumn,
  lifeState: ({ isBadge = false }: { isBadge?: boolean } = {}) => {
    return {
      id: PRODUCT_COLUMN_KEYS.LIFE_STATE,
      key: 'lifeState',
      sortable: false,
      header: 'Состояние товара',
      render: (lifeState, { versionCount }) => {
        /**
         * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
         * если > 1, значит, минимум одна версия была создана, тогда не отображаем состояние товара
         */
        const isAggregatingProduct = versionCount > 1;
        if (isAggregatingProduct) return null;

        if (isBadge) {
          return (
            <Badge
              dot={LIFE_STATE_COLORS[lifeState]}
              label={LIFE_STATE_NAMES[lifeState]}
              ml='auto'
              style={{
                background: 'transparent',
              }}
              type={Badge.TYPES.DEFAULT}
            />
          );
        }

        return LIFE_STATE_NAMES[lifeState];
      },
    } as IDataTableColumn;
  },
  type: ({
    sortable = true,
    fixedWidth,
  }: {
    sortable?: boolean;
    fixedWidth?: number | undefined;
  } = {}) => {
    return {
      id: PRODUCT_COLUMN_KEYS.TYPE,
      key: 'type',
      sortable,
      header: { text: 'Тип товара', fixedWidth },
      render: type => type || <Text color='text.disabled'>Не указано</Text>,
    } as IDataTableColumn;
  },
  productionState: ({
    sortable = true,
    fixedWidth,
  }: {
    sortable?: boolean;
    fixedWidth?: number | undefined;
  } = {}) => {
    return {
      id: PRODUCT_COLUMN_KEYS.PRODUCTION_STATE,
      key: 'productionState',
      header: { text: 'Статус производства', fixedWidth },
      sortable,
      render: productionState =>
        productionState === ProductionStates.cancelled
          ? 'Анонс отменен'
          : PRODUCTION_STATE_NAMES[productionState] || (
              <Text color='text.disabled'>Не указано</Text>
            ),
    } as IDataTableColumn;
  },
  companyName: ({
    companyId = null,
    sortable = true,
  }: {
    companyId?: Nullable<string>;
    sortable?: boolean;
  } = {}): IDataTableColumn => ({
    id: PRODUCT_COLUMN_KEYS.MANUFACTURER_NAME,
    key: 'manufacturer.name',
    header: 'Производитель',
    sortable,
    render: (_, { company, manufacturer }) => {
      const isOwnProduct = company && companyId === company.id;

      if (manufacturer) {
        if (manufacturer.company) {
          return (
            <Text
              as={Link}
              color='text.secondary'
              to={getPath(COMPANY.CARD, { id: manufacturer.company.id })}
            >
              {manufacturer.company.name}
            </Text>
          );
        }
        return (
          <Text truncate color='text.primary' fontSize={3}>
            {manufacturer.name}
          </Text>
        );
      }

      return (
        <Container alignItems='center' justifyContent='space-between'>
          <Text
            truncate
            as={Link}
            color='text.secondary'
            to={getPath(COMPANY.CARD, { id: company.id })}
          >
            {company.name}
          </Text>
          {isOwnProduct && (
            <Tooltip title='Мой товар'>
              <OwnProductBadge />
            </Tooltip>
          )}
        </Container>
      );
    },
  }),
  taskStatus: {
    id: PRODUCT_COLUMN_KEYS.TASK,
    key: 'task',
    header: {
      text: (
        <EducationPoint section={POINT_KEYS.TABLE_COLUMN_TASK_PRODUCT}>
          <div>Статус задачи</div>
        </EducationPoint>
      ),
      fixedWidth: 120,
    },
    sortable: false,
    render: task => (
      <TaskStatusBadge
        hasTooltip
        dueDate={task?.dueDate}
        executor={task?.executor}
        status={task?.status}
      />
    ),
  } as IDataTableColumn & { render: (task: any) => JSX.Element },
  tags: ({ minWidth = 100 }: { minWidth?: number | undefined } = {}) => {
    return {
      id: PRODUCT_COLUMN_KEYS.TAGS,
      key: 'tags',
      header: { text: 'Теги', minWidth },
      truncate: false,
      sortable: false,
      render: tags => <Tags limit={2} tags={tags} />,
    } as IDataTableColumn;
  },
  sentToModerationAt: {
    id: PRODUCT_COLUMN_KEYS.MODERATION_SENT_AT,
    key: 'moderation.sentAt',
    header: { text: 'Отправлен', fixedWidth: 90 },
    render: date => getTablesDate(date, false, DEFAULT_DATE_PATTERN),
  } as IDataTableColumn,
  moderationCompletedAt: {
    id: PRODUCT_COLUMN_KEYS.MODERATION_COMPLETED_AT,
    key: 'moderation.completedAt',
    header: { text: 'Изменен', fixedWidth: 90 },
    render: date => getTablesDate(date, false, DEFAULT_DATE_PATTERN),
  } as IDataTableColumn,
  // todo возможно перенести в будущий раздел Task
  verifier: {
    id: PRODUCT_COLUMN_KEYS.VERIFIER,
    key: 'verifier',
    header: 'Проверяющий',
    sortable: false,
    truncate: false,
    render: createdBy => <UserAvatar user={createdBy} />,
  } as IDataTableColumn,
  authorshipCompany: {
    id: PRODUCT_COLUMN_KEYS.AUTHORSHIP_COMPANY,
    key: 'authorshipCompany',
    header: 'Компания',
    sortable: false,
    render: authorshipCompany => (
      <Text
        as={Link}
        color='text.primary'
        to={getPath(COMPANY.CARD, { id: authorshipCompany.id })}
      >
        {authorshipCompany.name}
      </Text>
    ),
  } as IDataTableColumn,
};

export { PRODUCT_COLUMNS };
