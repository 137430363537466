import styled, { css } from 'styled-components';
import { space as styledSpace, width } from 'styled-system';

export const PointOfSaleLink = styled.a(
  ({ theme: { colors, fontSizes } }) => css`
    display: flex;
    align-items: center;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[3]}px;

    &:hover {
      color: ${colors.text.primary};

      & svg {
        color: ${colors.text.primary};
      }
    }
  `,
);

export const WrapperCard = styled.div(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: flex;
    margin-bottom: ${space[2]}px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
);

export const NameWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: ${space[1]}px;
  `,
);

export const ContentWrapper = styled.div`
  display: flex;
  max-width: 628px;
  width: 50%;
  min-width: 0;
  flex-grow: 1;

  ${width};
  ${styledSpace}
`;

export const InfoWrapper = styled.div(
  ({ theme: { space } }) => css`
    width: 100%;
    flex-grow: 1;
    min-width: 0;
    padding-right: ${space[2]}px;
  `,
);

export const ProductCountBlock = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 82px;
    min-width: 82px;
    height: 100%;
    padding-left: ${space[2]}px;
    border-left: 1px solid ${colors.divider};
    font-weight: 500;
  `,
);
