import {
  isSameDay,
  startOfMonth,
  endOfMonth,
  startOfWeek,
  endOfWeek,
  eachDayOfInterval,
} from 'date-fns';
import styled, { css } from 'styled-components';

import { isEqualsDate } from 'common/utils';

import { Tile, SquareGrid } from './styled';
import { IViewDate } from './types';

const Weekday = styled.div(
  ({ theme: { colors, fontSizes } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${colors.text.primary};
    font-size: ${fontSizes[2]}px;
    font-weight: 500;
    text-align: center;
    text-transform: uppercase;
  `,
);

const WEEKDAYS = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

function DaysView({
  shouldDisableDate,
  selectedDate,
  value,
  onClick,
}: IViewDate): JSX.Element {
  const today = new Date();
  const days = eachDayOfInterval({
    start: startOfWeek(startOfMonth(selectedDate), { weekStartsOn: 1 }),
    end: endOfWeek(endOfMonth(selectedDate)),
  });

  return (
    <SquareGrid columns={7}>
      {WEEKDAYS.map(item => (
        <Weekday key={item}>{item}</Weekday>
      ))}
      {days.map(date => {
        return (
          <Tile
            key={date.getTime()}
            active={isEqualsDate(date, value)}
            disabled={shouldDisableDate(date)}
            secondary={date.getMonth() !== selectedDate.getMonth()}
            today={isSameDay(today, date)}
            onClick={() => onClick(date)}
          >
            {date.getDate()}
          </Tile>
        );
      })}
    </SquareGrid>
  );
}

export { DaysView };
