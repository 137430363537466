import { FC, Fragment, memo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { POINTS_OF_SALE_TYPES_NAME } from 'common/constants/points-of-sale.const';
import { ICompany } from 'common/types/company.types';
import {
  IPointsOfSale,
  PointsOfSaleType,
} from 'common/types/point-of-sale.types';
import { getImageURL, getValidUrl } from 'common/utils';
import { getCompanyId } from 'entities/identity';
import { EmailIcon, PhoneIcon, TimeIcon } from 'resources/icons/18';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { ExternalLinkIcon } from 'resources/other-12';
import { COMPANY, getPath } from 'routes';
import { useToggle } from 'tools/hooks';
import { Avatar, Badge, Button, Container, Text, Tooltip } from 'UI';

import { CompanyTitle } from '../../../company';
import { ContactItem } from '../../../Map/_shared/styled';
import {
  GeneratedBySystemStatus,
  OfficialRetailerStatus,
} from '../../PointOfSalesStatuses';
import {
  InfoWrapper,
  PointOfSaleLink,
  ProductCountBlock,
  WrapperCard,
} from '../../styled';

const ContentWrapper = styled.div<{ width?: string }>(
  ({ width }) => css`
    display: flex;
    max-width: 628px;
    width: ${width};
    min-width: 0;
    flex-grow: 1;

    ${styledSpace}
  `,
);

const MultipleCard = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    padding: ${space[2]}px 0 ${space[2]}px 0;
    border-top: 1px solid ${colors.divider};

    &:last-child {
      padding-bottom: 0;
    }
  `,
);

interface IPointOfSaleInfoProps {
  point: IPointsOfSale;
  isStandard: boolean;
}

const PointOfSaleInfo: FC<IPointOfSaleInfoProps> = memo(
  ({ point, isStandard }) => {
    const {
      isGeneratedBySystem,
      id,
      name,
      location,
      openingHours,
      telephones,
      email,
      url,
      ownershipCompany,
    } = point;

    const companyId = ownershipCompany?.id;

    const mainPhone =
      telephones && telephones?.length > 0
        ? telephones?.map(item => (item.isMain ? item.value : null))
        : null;
    const userCompanyId = useSelector(getCompanyId);
    const isMyPointOfSale = userCompanyId === ownershipCompany?.id;

    return (
      <Fragment>
        <ContentWrapper width='50%'>
          <InfoWrapper>
            <Badge
              fontSize='8px'
              fontWeight={600}
              label={point?.type ? POINTS_OF_SALE_TYPES_NAME[point.type] : null}
              letterSpacing='0.5px'
              mb={1}
              textTransform='uppercase'
            />
            <Container
              alignItems='center'
              display='inline-flex'
              mb={0}
              width='100%'
            >
              <Text
                truncate
                as={Link}
                color='text.primary'
                fontSize={4}
                fontWeight={500}
                mr={1}
                to={getPath(COMPANY.POINTS_OF_SALE_PAGE, { id })}
              >
                {name}
              </Text>
              {isGeneratedBySystem && isMyPointOfSale && (
                <GeneratedBySystemStatus />
              )}
            </Container>
            {point.type !== PointsOfSaleType.onlineStore && location && (
              <ContactItem
                primaryColor={false}
                text={location.address}
                truncate={false}
              />
            )}
            {point.type === PointsOfSaleType.onlineStore && (
              <PointOfSaleLink
                color='text.secondary'
                href={getValidUrl(url)}
                rel='noreferrer noopener'
                target='_blank'
              >
                Перейти на сайт
                <ExternalLinkIcon ml={1} />
              </PointOfSaleLink>
            )}
          </InfoWrapper>
        </ContentWrapper>
        <ContentWrapper width='59%'>
          <InfoWrapper>
            <ContactItem
              icon={TimeIcon}
              placeholder='Не указано'
              text={openingHours}
              tooltip='Время работы'
            />
            <ContactItem icon={PhoneIcon} text={mainPhone} tooltip='Телефон' />
            <ContactItem
              icon={EmailIcon}
              mb='0px'
              placeholder='Не указан'
              text={email}
              tooltip='Email'
            />
          </InfoWrapper>
          <ProductCountBlock>
            {isStandard ? (
              <Avatar
                cover
                rounded
                as={Link}
                borderColor='colors.white'
                size='xxs'
                to={getPath(COMPANY.CARD, { id: companyId })}
                url={getImageURL(ownershipCompany?.logo, { size: 'l' })}
              />
            ) : (
              <Text color='text.secondary' fontSize={2} textAlign='center'>
                Наличие не указано
              </Text>
            )}
          </ProductCountBlock>
        </ContentWrapper>
      </Fragment>
    );
  },
);

interface IGroupPointOfSaleCardProps {
  points: IPointsOfSale[];
  isStandard: boolean;
}
const GroupPointOfSaleCard: FC<IGroupPointOfSaleCardProps> = memo(
  ({ points, isStandard }) => {
    const [openState, handleStateToggle] = useToggle(false);
    const { name, id, logo, isOfficial } = points[0]
      .ownershipCompany as ICompany;

    return (
      <WrapperCard>
        <Container column width='100%'>
          <Container>
            <Avatar
              as={Link}
              letter={name}
              mr={2}
              size='l'
              to={getPath(COMPANY.CARD, { id })}
              url={getImageURL(logo)}
            />
            <Container column height='64px' justifyContent='center'>
              <Container alignItems='center' display='inline-flex'>
                <Tooltip arrow hint title={name}>
                  <CompanyTitle
                    truncate
                    as={Link}
                    to={getPath(COMPANY.CARD, { id })}
                  >
                    {name}
                  </CompanyTitle>
                </Tooltip>
                {isOfficial && <OfficialRetailerStatus companyId={id} ml={1} />}
              </Container>
              <Container display='inline-flex' mt={0}>
                <Text color='text.secondary' mr={0}>
                  Мест продаж:{' '}
                </Text>
                <Text fontWeight={600}>{points.length}</Text>
              </Container>
            </Container>
            <Button
              color='secondary'
              ml='auto'
              mt={3}
              variant='text'
              onClick={() => handleStateToggle(!openState)}
            >
              <Container alignItems='center' display='inline-flex'>
                <Text mr={1}>{openState ? 'Скрыть' : 'Показать'}</Text>
                {openState ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </Container>
            </Button>
          </Container>
          {openState && (
            <Container column maxWidth='1180px' ml='80px' mt='16px'>
              {points.map(point => {
                return (
                  <MultipleCard key={point.id}>
                    <PointOfSaleInfo isStandard={isStandard} point={point} />
                  </MultipleCard>
                );
              })}
            </Container>
          )}
        </Container>
      </WrapperCard>
    );
  },
);

interface IPointOfSaleCardProps {
  points: IPointsOfSale[][];
  isStandard: boolean;
}
const PointsOfSaleCard: FC<IPointOfSaleCardProps> = memo(
  ({ isStandard, points }) => {
    return (
      <Fragment>
        {points.map((item, index) => (
          <GroupPointOfSaleCard
            key={index}
            isStandard={isStandard}
            points={item}
          />
        ))}
      </Fragment>
    );
  },
);

export { PointsOfSaleCard };
