import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { NotifyCard } from './Card';

const HEIGHT_NOTIFY = 54;

const NotifyContainer = styled.div(
  ({ theme: { space, zIndex } }) => css`
    position: fixed;
    bottom: ${space[2]}px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    padding-left: 110px;
    z-index: ${zIndex.notification};
  `,
);

function NotifySystem(): JSX.Element {
  const notifies = useSelector(state => (state as any).notifyReducer);
  let numberPos = -1;
  let currentPos = 0;

  return (
    <NotifyContainer>
      {notifies.map((notify, index) => {
        if (notify.hiding) {
          currentPos = index;
        } else {
          numberPos += 1;
          currentPos = numberPos;
        }

        return (
          <NotifyCard
            key={notify.id}
            bottomIndent={currentPos * HEIGHT_NOTIFY}
            notify={notify}
          />
        );
      })}
    </NotifyContainer>
  );
}

export { NotifySystem };
