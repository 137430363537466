import {
  FC,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  fetchMarkNotificationsAsRead,
  fetchNewAndViewedNotifications,
  LIMIT_NOTIFICATIONS,
  markAllNotificationsAsViewed,
} from 'common/api/notifications.api';
import { useRequest } from 'common/hooks';
import { NOTIFICATIONS } from 'routes';
import { Spinner, Text, Dropdown, Button } from 'UI';

import { NotificationCard } from './card';
import { NotificationBadge } from './styled';
import { isExistNotification } from './utils';

const Arrow = styled.div<{ offsetX: number }>(
  ({ theme: { space, colors }, offsetX }) => css`
    position: absolute;
    top: -${space[0]}px;
    right: ${offsetX}px;

    &::before {
      content: '';
      display: block;
      width: ${space[1]}px;
      height: ${space[1]}px;
      background-color: ${colors.white};
      transform: rotate(144deg) skewX(20deg);
    }
  `,
);

const StyledDropdown = styled(Dropdown)(
  ({ theme: { shadows, zIndex } }) => css`
    width: 488px;
    z-index: ${zIndex.header + 1};
    box-shadow: ${shadows[2]};
  `,
);

const Header = styled.div(
  ({ theme: { colors, fontSizes, space } }) => css`
    display: flex;
    height: 46px;
    text-align: center;
    align-items: center;
    font-size: ${fontSizes[5]}px;
    font-weight: 500;
    padding: ${space[2]}px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

const NotificationsWrapper = styled.div`
  position: relative;
  height: 456px;
  overflow-y: auto;
`;

const ViewAllButton = styled.button.attrs(() => ({
  type: 'button',
  role: 'button',
}))(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 43px;
    border-top: 1px solid ${colors.divider};
  `,
);

const NewNotificationsButton = styled.button.attrs(() => ({
  type: 'button',
  role: 'button',
}))(
  ({
    theme: { space, colors, fontSizes },
    show,
  }: {
    theme: any;
    show: boolean;
  }) => css`
    visibility: hidden;
    position: absolute;
    top: 58px;
    left: 50%;
    transform: translateX(-50%);
    width: 184px;
    height: ${space[5]}px;
    font-size: ${fontSizes[5]}px;
    color: ${colors.white};
    background: ${colors.text.primary};
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    z-index: 1;

    ${show &&
    css`
      opacity: 1;
      visibility: visible;
    `}

    & > ${Arrow}::before {
      background: ${colors.text.primary};
    }
  `,
);

const LoadingCard = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    height: ${space[4]}px;
    background: ${colors.background};
    border-bottom: 1px solid ${colors.divider};
  `,
);

interface NotificationsPopupProps {
  newNotificationsCount: number;
  anchorEl: HTMLElement | null;
  onUpdateNotificationsBadgeCount: (count: number) => void;
  onRequestClose: () => void;
}

const NotificationsPopup: FC<NotificationsPopupProps> = memo(
  ({
    newNotificationsCount,
    anchorEl,
    onUpdateNotificationsBadgeCount,
    onRequestClose,
  }) => {
    const history = useHistory();
    const scrollRef = useRef<HTMLDivElement | null>(null);
    const [
      { hasNewNotifications, notificationsCount, restNotificationsCount },
      setState,
    ] = useState({
      hasNewNotifications: false,
      notificationsCount: newNotificationsCount,
      restNotificationsCount: Math.max(
        newNotificationsCount - LIMIT_NOTIFICATIONS,
        0,
      ),
    });

    const {
      isLoading,
      data = { notifications: [], unreadNotificationsCount: 0 },
      updateData,
    } = useRequest(fetchNewAndViewedNotifications, [], {
      onSuccess: ({ unreadNotificationsCount }) => {
        onUpdateNotificationsBadgeCount(unreadNotificationsCount);
      },
    });

    useEffect(() => {
      if (!isLoading) {
        if (data.unreadNotificationsCount > newNotificationsCount) {
          onUpdateNotificationsBadgeCount(data.unreadNotificationsCount);
        }
        setState(prev => ({
          ...prev,
          notificationsCount: data.unreadNotificationsCount,
          restNotificationsCount: Math.max(
            data.unreadNotificationsCount - LIMIT_NOTIFICATIONS,
            0,
          ),
        }));
      }
    }, [
      data.unreadNotificationsCount,
      isLoading,
      newNotificationsCount,
      onUpdateNotificationsBadgeCount,
    ]);

    useEffect(() => {
      if (newNotificationsCount > notificationsCount) {
        setState({
          hasNewNotifications: true,
          notificationsCount: newNotificationsCount,
          restNotificationsCount: Math.max(
            newNotificationsCount - LIMIT_NOTIFICATIONS,
            0,
          ),
        });
      }
    }, [newNotificationsCount, notificationsCount]);

    const handleViewNewNotifications = useCallback(() => {
      if (scrollRef.current) {
        scrollRef.current.scrollTo({ top: 0, behavior: 'smooth' });
      }
      updateData();
      setState(prevState => ({
        ...prevState,
        hasNewNotifications: false,
      }));
    }, [updateData]);

    const handleRequestClose = useCallback(
      (isLink = false) => {
        fetchMarkNotificationsAsRead(data.notifications).then(() => {
          if (isLink) history.push(NOTIFICATIONS);
          onRequestClose();
          onUpdateNotificationsBadgeCount(restNotificationsCount);
        });
      },
      [
        data,
        history,
        onRequestClose,
        restNotificationsCount,
        onUpdateNotificationsBadgeCount,
      ],
    );

    const handleMarkAllAsViewed = useCallback(() => {
      markAllNotificationsAsViewed(data.notifications[0].id).then(() => {
        updateData();
      });
    }, [data, updateData]);

    const hasUnreadNotifications = useMemo(() => {
      return data.notifications.some(item => item?.isViewed === false);
    }, [data]);

    return (
      <StyledDropdown
        anchorEl={anchorEl}
        open={!!anchorEl}
        placement='bottom-end'
        onRequestClose={() => handleRequestClose()}
      >
        <Arrow offsetX={26} />
        <Header>
          <Text>Последние уведомления</Text>
          <Button
            disabled={!hasUnreadNotifications}
            ml='auto'
            variant='text'
            onClick={handleMarkAllAsViewed}
          >
            Отметить все как прочитанные
          </Button>
        </Header>
        <NewNotificationsButton
          show={hasNewNotifications}
          onClick={handleViewNewNotifications}
        >
          <Arrow offsetX={88} />
          Новые уведомления
        </NewNotificationsButton>
        <NotificationsWrapper ref={scrollRef}>
          <Spinner
            height='100%'
            loading={isLoading && data.notifications.length === 0}
          >
            {isLoading && (
              <LoadingCard>
                <Spinner delay={0} height='100%' />
              </LoadingCard>
            )}
            {data.notifications.map(event => {
              if (!isExistNotification(event)) return null;

              return (
                <NotificationCard
                  key={event.id}
                  isPopupViewMode
                  notification={event}
                />
              );
            })}
          </Spinner>
        </NotificationsWrapper>
        <ViewAllButton data-link onClick={() => handleRequestClose(true)}>
          <Text color='text.secondary' fontWeight={500} mr={0}>
            Показать все
          </Text>
          {restNotificationsCount > 0 && (
            <NotificationBadge>{restNotificationsCount}</NotificationBadge>
          )}
        </ViewAllButton>
      </StyledDropdown>
    );
  },
);

export { NotificationsPopup };
