import {
  Fragment,
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
  cloneElement,
  ReactNode,
  CSSProperties,
  MouseEvent as MouseEventReact,
} from 'react';

import { createPopper, Instance, Placement } from '@popperjs/core';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  fontSize,
  FontSizeProps,
  SpaceProps,
} from 'styled-system';

import { getBrowser, BROWSERS } from 'common/utils/getBrowser';
import { useForkRef, useDidUpdate } from 'tools/hooks';

const Container = styled.div(
  ({ theme: { space, zIndex } }) => css`
    z-index: ${zIndex.tooltip};
    padding: ${space[1]}px;

    &[data-popper-placement^='right'] > [data-popper-arrow] {
      left: ${space[0]}px;
      &::before {
        transform: rotate(54deg) skewX(20deg);
      }
    }

    &[data-popper-placement^='top'] > [data-popper-arrow] {
      bottom: ${space[0]}px;
      &::before {
        transform: rotate(-36deg) skewX(20deg);
      }
    }

    &[data-popper-placement^='left'] > [data-popper-arrow] {
      right: ${space[0]}px;
      &::before {
        transform: rotate(234deg) skewX(20deg);
      }
    }

    &[data-popper-placement^='bottom'] > [data-popper-arrow] {
      top: ${space[0]}px;
      &::before {
        transform: rotate(144deg) skewX(20deg);
      }
    }
  `,
);

const Paper = styled.div<{ isPointer: boolean } & SpaceProps>(
  ({
    theme: { colors, fontSizes, space, borderRadius, shadows },
    isPointer,
  }) => css`
    padding: ${space[1]}px;
    border-radius: ${borderRadius}px;
    background: ${colors.white};
    font-size: ${fontSizes[2]}px;
    font-weight: 500;
    box-shadow: ${shadows[1]};
    text-align: left;
    word-wrap: break-word;

    ${isPointer &&
    css`
      font-size: ${fontSizes[2]}px;
      padding: ${space[0]}px;
      max-width: 320px;
    `}

    ${styledSpace};
    ${fontSize}
  `,
);

const Arrow = styled.div.attrs(() => ({
  'data-popper-arrow': '',
}))(
  ({ theme: { colors } }) => css`
    position: absolute;

    &::before {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      background-color: ${colors.white};
    }
  `,
);

// TODO: Посмотреть что с children

interface ITooltipProps {
  arrow?: boolean;
  hint?: boolean;
  pointer?: boolean;
  truncate?: boolean;
  style?: CSSProperties;
  maxWidth?: number | string;
  minWidth?: number | string;
  zIndex?: number;
  placement?: Placement;
  title?: string | null | ReactNode;
  paperCloseDelay?: number;
  offset?: [number, number];
  children: any;
}

const Tooltip = forwardRef<
  ReactNode,
  ITooltipProps & FontSizeProps & SpaceProps
>(
  (
    {
      arrow = false,
      hint = false,
      pointer = false,
      truncate,
      style,
      maxWidth = 500,
      minWidth,
      zIndex,
      placement = hint ? 'top' : 'right',
      title,
      paperCloseDelay,
      offset = [0, 0],
      children,
      ...other
    },
    tooltipRef,
  ) => {
    const paperRef = useRef<HTMLDivElement>(null);
    const popperRef = useRef<Instance>();
    const childRef = useRef<HTMLElement>();
    const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
    const timeoutClosePaperRef = useRef<ReturnType<typeof setTimeout>>();
    const ref = useForkRef(children.ref, childRef, tooltipRef);

    const [isOpen, setOpenState] = useState(false);

    const handleOpen = useCallback(
      (event: MouseEvent) => {
        if (children.props.disabled) return;

        if (children.onMouseEnter) children.onMouseEnter(event);

        clearTimeout(timeoutRef.current);
        timeoutRef.current = setTimeout(
          () => {
            const childEl = childRef.current;

            if (
              !childEl ||
              (truncate && childEl.clientWidth >= childEl.scrollWidth)
            ) {
              return;
            }

            // disable automatic batching
            ReactDOM.flushSync(() => setOpenState(true));

            popperRef.current = createPopper(childEl, paperRef.current!, {
              placement,
              modifiers: [
                { name: 'offset', options: { offset } },
                {
                  name: 'flip',
                  options: {
                    behavior: hint
                      ? ['top', 'bottom']
                      : ['right', 'top', 'left', 'bottom'],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: { boundariesElement: 'window' },
                },
              ],
            });
          },
          hint ? 400 : 100,
        );
      },
      [hint, truncate, placement, offset, children],
    );

    const handleClose = useCallback(
      (event: MouseEvent | MouseEventReact) => {
        if (
          !truncate &&
          event.relatedTarget instanceof Node &&
          paperRef.current?.contains(event.relatedTarget)
        ) {
          return;
        }

        if (children.onMouseLeave) children.onMouseLeave(event);

        clearTimeout(timeoutRef.current);

        const closePaper = (): void => {
          setOpenState(false);

          if (popperRef.current) popperRef.current.destroy();
        };

        if (paperCloseDelay && paperCloseDelay > 0) {
          timeoutClosePaperRef.current = setTimeout(() => {
            closePaper();
          }, paperCloseDelay);
        } else closePaper();
      },
      [truncate, paperCloseDelay, children],
    );

    const handlePaperMouseEnter = useCallback(() => {
      clearTimeout(timeoutClosePaperRef.current);
    }, []);

    // for situation, when tooltip open and element has been disabled
    useEffect(() => {
      if (children.props.disabled && isOpen) {
        clearTimeout(timeoutRef.current);
        setOpenState(false);

        if (popperRef.current) popperRef.current.destroy();
      }
    }, [isOpen, children.props.disabled]);

    // https://github.com/facebook/react/issues/10396
    useEffect(() => {
      const childEl = childRef.current;
      if (!childEl) return;

      childEl.addEventListener('mouseenter', handleOpen);
      childEl.addEventListener('mouseleave', handleClose);

      // eslint-disable-next-line consistent-return
      return () => {
        childEl.removeEventListener('mouseenter', handleOpen);
        childEl.removeEventListener('mouseleave', handleClose);
      };
    }, [handleOpen, handleClose]);

    useEffect(() => {
      const handleForceClose = (): void => setOpenState(false);

      document.addEventListener('visibilitychange', handleForceClose);
      document.addEventListener('scroll', handleForceClose);

      return () => {
        document.removeEventListener('visibilitychange', handleForceClose);
        document.removeEventListener('scroll', handleForceClose);
      };
    }, []);

    useDidUpdate(() => {
      popperRef.current?.update();
    }, [title]);

    if (!title) return children;
    if (truncate && getBrowser() === BROWSERS.SAFARI) return children;

    const defaultAriaLabel =
      !truncate && typeof title === 'string' ? title : undefined;

    return (
      <Fragment>
        {cloneElement(children, {
          ref,
          'aria-label': children.props['aria-label'] ?? defaultAriaLabel,
        })}
        {isOpen &&
          ReactDOM.createPortal(
            <Container
              ref={paperRef}
              role='tooltip'
              style={{ zIndex }}
              onMouseEnter={handlePaperMouseEnter}
              onMouseLeave={handleClose}
            >
              {arrow && <Arrow />}
              <Paper
                isPointer={pointer}
                style={{ maxWidth, minWidth, ...style }}
                {...other}
              >
                {title}
              </Paper>
            </Container>,
            document.body,
          )}
      </Fragment>
    );
  },
);

export { Tooltip };
