import styled, { css } from 'styled-components';

export const InfoContainer = styled.div(
  ({ theme: { colors, fontSizes, space } }) => css`
    margin: ${space[2]}px 0;
    font-size: ${fontSizes[5]}px;
    color: ${colors.text.secondary};
    text-align: center;
  `,
);

export const ButtonsWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    position: fixed;
    display: inline-flex;
    justify-content: flex-end;
    margin: 0 -${space[4]}px 0;
    width: 100%;
    padding: ${space[2]}px ${space[4]}px;
    border-top: 1px solid ${colors.white};
    border-radius: 0 0 ${space[1]}px ${space[1]}px;
    background: ${colors.background};
  `,
);
