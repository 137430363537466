import { FC, useEffect, useRef } from 'react';

import { NumericFormat } from 'react-number-format';

import { fetchMeasurementUnits } from 'common/api/dictionaries.api';
import { useUnit } from 'common/hooks';
import { PhysicalQuantities } from 'common/types/dictionaries.types';
import { useField } from 'reform';
import { TextInput, Select, UnionContainer } from 'UI';
import { FetchOptionsType } from 'UI/Inputs';

import { FieldWrapper } from '../../fields/FieldWrapper';

const fetchOptions: FetchOptionsType = ({ page, name }) => {
  return fetchMeasurementUnits({
    name,
    page,
    physicalQuantity: [
      PhysicalQuantities.time,
      PhysicalQuantities.engineResource,
      PhysicalQuantities.durationOfWork,
    ],
  });
};

/**
 * "Трудозатраты" в общих сведениях
 */
const LaborEffortField: FC<{ name: string }> = ({ name }) => {
  const isFirstInsertValue = useRef<boolean>(false);
  const { unitToString } = useUnit();

  const {
    value,
    error: valueError,
    setValue,
  } = useField({
    name: `${name}.value`,
  });

  const {
    value: unit,
    setValue: setUnit,
    error: unitError,
  } = useField({ name: `${name}.unit` });

  const hasValueWithoutUnit = !!value && !unit;
  const handleValueChange = ({ value: newValue }): void => setValue(newValue);

  useEffect(() => {
    if (hasValueWithoutUnit && !isFirstInsertValue.current) {
      isFirstInsertValue.current = true;
      setUnit('man-hour');
    }
  }, [hasValueWithoutUnit, setUnit]);

  return (
    <FieldWrapper
      showErrorDescription
      error={valueError || unitError}
      label='Трудозатраты'
      mb='12px'
      width='100%'
    >
      <UnionContainer>
        <UnionContainer.Row>
          <NumericFormat
            allowLeadingZeros={false}
            allowNegative={false}
            customInput={TextInput}
            error={valueError}
            name={`${name}.value`}
            placeholder='Введите значение'
            value={value}
            onValueChange={handleValueChange}
          />
          <Select
            error={unitError}
            itemToString={unitToString}
            options={fetchOptions}
            placeholder='Ед. изм.'
            value={unit}
            width={140}
            onChange={setUnit}
          />
        </UnionContainer.Row>
      </UnionContainer>
    </FieldWrapper>
  );
};

export { LaborEffortField };
