import styled, { css } from 'styled-components';

import { AddMediumIcon } from 'resources/icons/18';
import { DescriptionIcon } from 'resources/other';

export const StyledDescriptionIcon = styled(DescriptionIcon)(
  ({ theme: { space, colors, borderRadius } }) => css`
    position: absolute;
    top: ${space[0]}px;
    right: ${space[0]}px;
    width: 18px;
    height: 18px;
    padding: ${space[0]}px;
    border-radius: ${borderRadius}px;
    background: ${colors.blackout};

    & > svg {
      color: ${colors.white};
    }
  `,
);

export interface IBaseImageBackground {
  isDragging?: boolean;
  url?: string;
}

export const BaseImageBackground = styled.div<IBaseImageBackground>(
  ({ theme: { colors, borderRadius }, isDragging = false, url = '' }) => css`
    height: 100%;
    background-image: url('${url}');
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${borderRadius}px;

    ${isDragging &&
    css`
      background: ${colors.background};
    `}
  `,
);

export const AddPhotoBetweenWrapper = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    align-self: stretch;
    min-width: ${space[1]}px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
  `,
);

export const DashedLine = styled.div(
  ({ theme: { colors } }) => css`
    align-self: stretch;
    width: 1px;
    border: 1px dashed ${colors.text.secondary};
  `,
);

export const OpenMenuIcon = styled(AddMediumIcon)(
  ({ theme: { space, borderRadius, colors } }) => css`
    position: absolute;
    align-items: center;
    z-index: 1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: ${space[2]}px;
    height: ${space[2]}px;
    padding: 2px;
    background: ${colors.white};
    border-radius: ${borderRadius}px;
    border: 1px solid ${colors.text.secondary};
  `,
);
