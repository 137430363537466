import {
  CSSProperties,
  Fragment,
  ReactNode,
  useMemo,
  useRef,
  useState,
  isValidElement,
  forwardRef,
} from 'react';

import { Placement } from '@popperjs/core';
import {
  useCombobox,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import {
  ArrowDown1pxIcon,
  ArrowUp1pxIcon,
  Delete1pxIcon,
} from 'resources/icons/1px-12';
import { useForkRef } from 'tools/hooks';
import { STUB_FUNC } from 'tools/utils';

import { BaseInput } from './BaseInput';
import {
  Options,
  useAsyncOptions,
  filterByInputValue,
  InputContainer,
} from './components';
import {
  FetchOptionsType,
  ItemToStringType,
  ItemToKeyType,
  DisableItemType,
  ItemToStringAndOptionsType,
  OnStateChange,
  RenderButtonType,
} from './types';
import { VerticalDivider } from '../Divider';
import { IconButtonWrapper } from '../IconButton';
import { Tooltip } from '../Tooltip';

const Container = styled(InputContainer)<{ width?: number | string }>(
  ({ width }) => css`
    align-items: stretch;

    ${width &&
    css`
      width: ${width}px;
      min-width: ${width}px;
    `}

    ${space};
  `,
);

function stateReducer<T>(
  state: UseComboboxState<T>,
  actionAndChanges: UseComboboxStateChangeOptions<T>,
): Partial<UseComboboxState<T>> {
  // @ts-ignore
  const { type, changes, props } = actionAndChanges;
  const { inputValue, selectedItem } = changes;

  switch (type) {
    case useCombobox.stateChangeTypes.InputChange:
      return {
        ...changes,
        ...(inputValue === '' && { selectedItem: null }),
      };
    case useCombobox.stateChangeTypes.InputBlur: {
      if (inputValue !== '' && !!selectedItem) {
        return { ...changes, inputValue: props.itemToString(selectedItem) };
      }

      if (!selectedItem) return { ...changes, inputValue: '' };

      return changes;
    }
    default:
      return changes;
  }
}

interface ISelectProps<T = any> {
  className?: string;
  style?: CSSProperties;
  canClear?: boolean;
  disabled?: boolean;
  allowContentSearch?: boolean;
  disablePortal?: boolean;
  disableSearch?: boolean;
  disableItem?: DisableItemType<T>;
  name?: string;
  labelId?: string;
  inputId?: string;
  placeholder?: string;
  width?: number | string;
  error?: boolean;
  value: T;
  customItem?: T;
  options: T[] | FetchOptionsType;
  fetchSearchOptions?: FetchOptionsType;
  itemToKey?: ItemToKeyType<T>;
  itemToString?: ItemToStringAndOptionsType<T>;
  dropdownPlacement?: Placement;
  rightAddon?: ReactNode;
  dropdownAddon?: ReactNode;
  dropdownPlaceholder?: ReactNode;
  heightItem?: number;
  onChange?: (selectedItem: T) => void;
  onStateChange?: OnStateChange<T>;
  renderButton?: RenderButtonType<T>;
}

const Select = forwardRef<HTMLDivElement, ISelectProps & SpaceProps>(
  (
    {
      className,
      style,
      canClear,
      disabled = false,
      allowContentSearch,
      disablePortal,
      disableSearch,
      disableItem,
      labelId,
      inputId,
      placeholder = 'Выберите значение',
      error,
      value,
      customItem,
      options = [],
      fetchSearchOptions,
      itemToKey = STUB_FUNC.ITEM,
      itemToString = STUB_FUNC.ITEM as () => string,
      rightAddon,
      dropdownAddon,
      dropdownPlaceholder,
      dropdownPlacement,
      heightItem,
      width,
      onChange,
      onStateChange,
      renderButton = STUB_FUNC.NULL,
      ...other
    },
    ref,
  ) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [asyncState, dispatch] = useAsyncOptions();
    const [inputValue, setInputValue] = useState('');

    const isAsyncOptions = typeof options === 'function';
    const isSearchAvailable =
      (fetchSearchOptions || isAsyncOptions || allowContentSearch) &&
      !disableSearch;

    const isSearchMode =
      (fetchSearchOptions && (!!value || inputValue.length > 0)) ||
      isAsyncOptions;

    const isAvailableToClear = Boolean(value) && (canClear || isSearchMode);

    const unionRef = useForkRef(containerRef, ref);

    const items = useMemo(() => {
      // eslint-disable-next-line no-nested-ternary
      const mainItems = isSearchMode
        ? asyncState.items
        : customItem || allowContentSearch
        ? filterByInputValue(
            options,
            inputValue,
            itemToString as ItemToStringType,
          )
        : options;

      const filterCustomItem =
        customItem &&
        (itemToString as ItemToStringType)(customItem)
          .toLowerCase()
          .includes(inputValue.toLowerCase())
          ? customItem
          : null;

      return filterCustomItem ? [filterCustomItem, ...mainItems] : mainItems;
    }, [
      allowContentSearch,
      asyncState.items,
      customItem,
      inputValue,
      isSearchMode,
      itemToString,
      options,
    ]);

    const downshift = useCombobox({
      labelId,
      inputId,
      items,
      stateReducer,
      initialHighlightedIndex: 0,
      selectedItem: value,
      itemToString: item => (item ? (itemToString(item) as string) : ''),
      onInputValueChange: ({ inputValue: newInputValue }) => {
        setInputValue(newInputValue ?? '');
      },
      onSelectedItemChange: ({ selectedItem }) => {
        if (typeof onChange === 'function') onChange(selectedItem);
      },
      onStateChange,
    });

    const {
      isOpen,
      reset,
      toggleMenu,
      getInputProps,
      getComboboxProps,
      getToggleButtonProps,
    } = downshift;

    return (
      <Container
        className={className}
        style={style}
        {...getComboboxProps({
          ref: unionRef,
          disabled,
          ...other,
        })}
        error={error}
        width={width}
      >
        <Tooltip hint truncate placement='bottom' title={downshift.inputValue}>
          <BaseInput
            width='100%'
            // в случае, если options являются react нодами, а не текстом, вместо input выводится div
            {...(isValidElement(downshift.inputValue)
              ? {
                  as: 'div',
                  onClick: toggleMenu,
                  children: downshift.inputValue,
                }
              : getInputProps({
                  readOnly: !isSearchAvailable && !customItem,
                  disabled,
                  placeholder,
                  onClick() {
                    if (downshift.inputValue === '' || !isSearchAvailable) {
                      toggleMenu();
                    }
                  },
                }))}
          />
        </Tooltip>
        {rightAddon}
        <VerticalDivider height='initial' my='2px' />
        {renderButton({
          inputValue: downshift.inputValue,
          selectedItem: value,
        }) ?? (
          <Fragment>
            {isAvailableToClear ? (
              <IconButtonWrapper
                disabled={disabled}
                size={32}
                title='Очистить'
                onClick={reset}
              >
                <Delete1pxIcon />
              </IconButtonWrapper>
            ) : (
              <IconButtonWrapper
                aria-label='toggle menu'
                disabled={disabled}
                size={32}
                {...getToggleButtonProps()}
              >
                {isOpen ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </IconButtonWrapper>
            )}
          </Fragment>
        )}
        <Options
          anchorEl={containerRef.current}
          disableItem={disableItem}
          disablePortal={disablePortal}
          dispatch={dispatch}
          downshift={downshift}
          dropdownAddon={dropdownAddon}
          dropdownPlacement={dropdownPlacement}
          fetchSearchOptions={fetchSearchOptions}
          filteredItems={items}
          heightItem={heightItem}
          itemToKey={itemToKey}
          itemToString={itemToString}
          options={options}
          placeholder={dropdownPlaceholder}
          state={asyncState}
        />
      </Container>
    );
  },
);

export { Select };
