import { FC, Fragment, memo } from 'react';

import styled, { css } from 'styled-components';

import { useMenuScroll } from 'common/hooks';
import { SIDE_MENU_TEXT_PROPS } from 'components/products/side-menu/constants';
import {
  ava_manPath,
  ava_womanPath,
  galleryPath,
  promotionPath,
} from 'resources/advertising';
import { EmailIcon, PhoneIcon, ViewMapIcon } from 'resources/icons/18';
import { CompanyIcon } from 'resources/icons/24';
import { EmployeesIcon } from 'resources/other-24';
import { INFORMATION } from 'routes';
import { Img } from 'tools/libs';
import { Badge, SideMenuItem, Slider } from 'UI';

import { AdvertisingSlider } from './components';
import { ManualsArticlePage } from './components/ArticlePage';
import {
  BRANDING_CARDS,
  FILLER_TEXT,
  HEADING_IDS,
  MANUFACTURERS_CARDS,
  PLACEMENT_CARDS,
  POINTS_OF_SALE_CARDS,
  SIDE_MENU_POINTS,
} from './constants';

const FRAME_WIDTH = 860;

const SupportContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[2]}px;
    width: 100%;
    min-height: 90px;
    margin-top: ${space[3]}px;
    padding: ${space[2]}px ${space[4]}px;
    border-radius: 8px;
    background: ${colors.background};
  `,
);

const CardContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: 16px;
    margin-top: ${space[5]}px;
  `,
);

const Card = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 392px;
    height: 173px;
    margin-bottom: ${space[3]}px;
    padding: ${space[7]}px ${space[4]}px ${space[5]}px;
    border-radius: 16px;
    background: ${colors.background};
  `,
);

const TextContainer = styled.div`
  flex-basis: 50%;
`;

const ContactsContainer = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-basis: 50%;
`;

const ContactsContainerItem = styled.div`
  display: flex;
  align-items: flex-end;
`;

const List = styled.div(
  ({ theme: { space } }) => css`
    margin-bottom: ${space[3]}px;
    padding-left: ${space[2]}px;
  `,
);

const ListItem = styled.div`
  display: flex;
  align-items: flex-end;
  margin: 20px 0;
`;

const HeaderWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: ${space[3]}px;
    h2 {
      margin-bottom: 0;
    }
  `,
);

const StyledCardImage = styled(Img)(
  ({ theme: { shadows, space } }) => css`
    position: absolute;
    top: -${space[4]}px;
    left: ${space[4]}px;
    border-radius: 24px;
    box-shadow: ${shadows[2]};
  `,
);

const StyledSliderImage = styled.img`
  margin: 0 !important;
`;

const StyledPhoneNumber = styled.a(
  ({ theme: { colors } }) => css`
    border-bottom: 1px dashed ${colors.text.disabled};
    color: ${colors.text.primary};
  `,
);

interface ISectionHeader {
  title: string;
}

const SectionHeader: FC<ISectionHeader> = ({ title }) => {
  return (
    <HeaderWrapper>
      <h2>{title}</h2>
      <Badge label='Скоро в системе' size='s' type={Badge.TYPES.PRIMARY} />
    </HeaderWrapper>
  );
};

const AdvertisingPage: FC = memo(() => {
  const [activeMenuItem, handleScrollTo] = useMenuScroll({
    defaultActiveItem: HEADING_IDS.BANNERS,
    data: Object.values(HEADING_IDS),
  });

  return (
    <ManualsArticlePage
      heading={{
        heading: 'Реклама в istock.info',
        backLink: INFORMATION.PAGE,
      }}
      sidemenu={
        <Fragment>
          {SIDE_MENU_POINTS.map(({ text, id }) => (
            <SideMenuItem
              {...SIDE_MENU_TEXT_PROPS}
              key={id}
              active={activeMenuItem === id}
              as='button'
              text={text}
              onClick={() => handleScrollTo(id)}
            />
          ))}
        </Fragment>
      }
    >
      <div id='introduction'>
        <p>
          istock.info объединяет профессионалов, которые работают в
          промышленности. От руководителей до исполнителей: инженеры,
          проектировщики, конструкторы, специалисты по снабжению, менеджеры по
          продажам, производственный персонал и многие другие специалисты – все
          используют istock.info для поиска товаров и поставщиков, для
          выполнения ежедневных рабочих задач.
        </p>
        <SupportContainer>
          <TextContainer>
            Для получения более подробной информации, пожалуйста, свяжитесь с
            нами
          </TextContainer>
          <ContactsContainer>
            <ContactsContainerItem>
              <PhoneIcon color='text.primary' mr={1} />
              <StyledPhoneNumber href='tel:+79817950035'>
                +7 (981) 795-00-35
              </StyledPhoneNumber>
            </ContactsContainerItem>
            <ContactsContainerItem>
              <EmailIcon color='text.primary' mr={1} />
              <a href='mailto:support@istock.info'>support@istock.info</a>
            </ContactsContainerItem>
          </ContactsContainer>
        </SupportContainer>
      </div>
      <div id={HEADING_IDS.BANNERS}>
        <h2>Баннерная реклама</h2>
        Использование баннерной рекламы поможет:
        <CardContainer>
          <Card>
            <StyledCardImage alt='' src={ava_manPath} />
            Повысить узнаваемости вашей компании и продуктов среди целевой
            аудитории
          </Card>
          <Card>
            <StyledCardImage alt='' src={ava_womanPath} />
            Информировать потенциальных потребителей о специальных предложениях
          </Card>
        </CardContainer>
        <p>
          Размещение баннерной рекламы эффективно, так как пользователи
          istock.info – это специалисты, заинтересованные в поиске и выборе
          изделий.
        </p>
        Для повышения конверсии аудиторию показа можно сегментировать по
        следующим критериям:
        <List>
          <ListItem>
            <ViewMapIcon color='primary.main' mr={2} size={24} />
            Геопозиция
          </ListItem>
          <ListItem>
            <EmployeesIcon color='primary.main' mr={2} />
            Должность пользователя
          </ListItem>
          <ListItem>
            <CompanyIcon color='primary.main' mr={2} />
            Сфера деятельности компании пользователя
          </ListItem>
        </List>
        <p>
          Баннеры размещаются в фиксированном месте на срок от одного месяца.
          Стоимость формируется в зависимости от места и срока размещения.
        </p>
        <p>
          Доступно <strong>4 варианта</strong> размещения баннеров.
        </p>
        <AdvertisingSlider
          frameWidth={FRAME_WIDTH}
          mb={-1}
          mt='12px'
          tabs={PLACEMENT_CARDS.map(({ tabHeading }) => tabHeading)}
        >
          {PLACEMENT_CARDS.map(({ heading, text, image }, index) => (
            <Slider.Frame key={index}>
              <h3>{heading}</h3>
              <p>{text}</p>
              <StyledSliderImage alt='' src={image} title='' />
            </Slider.Frame>
          ))}
        </AdvertisingSlider>
      </div>
      <div id={HEADING_IDS.POINTS_OF_SALE}>
        <SectionHeader title='ТОП Мест продаж на странице изделия' />
        {FILLER_TEXT[HEADING_IDS.POINTS_OF_SALE].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <AdvertisingSlider
          frameWidth={FRAME_WIDTH}
          mb={3}
          mt='12px'
          tabs={POINTS_OF_SALE_CARDS.map(({ tabHeading }) => tabHeading)}
        >
          {POINTS_OF_SALE_CARDS.map(({ image }, index) => (
            <Slider.Frame key={index}>
              <StyledSliderImage alt='' src={image} title='' />
            </Slider.Frame>
          ))}
        </AdvertisingSlider>
      </div>
      <div id={HEADING_IDS.MANUFACTURERS}>
        <SectionHeader title='ТОП Производителей на странице товара по стандарту' />
        {FILLER_TEXT[HEADING_IDS.MANUFACTURERS].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <AdvertisingSlider
          frameWidth={FRAME_WIDTH}
          mb={3}
          mt='12px'
          tabs={MANUFACTURERS_CARDS.map(({ tabHeading }) => tabHeading)}
        >
          {MANUFACTURERS_CARDS.map(({ image }, index) => (
            <Slider.Frame key={index}>
              <StyledSliderImage alt='' src={image} title='' />
            </Slider.Frame>
          ))}
        </AdvertisingSlider>
      </div>
      <div id={HEADING_IDS.GALLERY}>
        <SectionHeader title='Галерея товаров на странице ваших мест продаж' />
        {FILLER_TEXT[HEADING_IDS.GALLERY].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <Img alt='' src={galleryPath} />
      </div>
      <div id={HEADING_IDS.PROMOTION}>
        <SectionHeader title='Продвижение товаров на странице ваших мест продаж' />
        {FILLER_TEXT[HEADING_IDS.PROMOTION].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <Img alt='' src={promotionPath} />
      </div>
      <div id={HEADING_IDS.BRANDING}>
        <SectionHeader title='Брендирование страниц' />
        {FILLER_TEXT[HEADING_IDS.BRANDING].map((line, index) => (
          <p key={index}>{line}</p>
        ))}
        <AdvertisingSlider
          frameWidth={FRAME_WIDTH}
          mb={-1}
          mt='12px'
          tabs={BRANDING_CARDS.map(({ tabHeading }) => tabHeading)}
        >
          {BRANDING_CARDS.map(({ image, heading, text }, index) => (
            <Slider.Frame key={index}>
              <h3>{heading}</h3>
              <p>{text}</p>
              <StyledSliderImage alt='' src={image} title='' />
            </Slider.Frame>
          ))}
        </AdvertisingSlider>
      </div>
      <div>
        <p>
          Наша служба поддержки рекламодателей поможет вам определиться с
          выбором рекламного места и сроком рекламной кампании, в зависимости от
          тех целей, которые вы преследуете. В конце каждого периода вы будете
          получать аналитику и отчет о результатах рекламной кампании, где будут
          отражены достижения поставленных целей.
        </p>
        <SupportContainer>
          <TextContainer>
            Свяжитесь с нами для планирования вашей первой рекламной кампании в
            istock.info
          </TextContainer>
          <ContactsContainer>
            <ContactsContainerItem>
              <PhoneIcon color='text.primary' mr={1} />
              <StyledPhoneNumber href='tel:+79817950035'>
                +7 (981) 795-00-35
              </StyledPhoneNumber>
            </ContactsContainerItem>
            <ContactsContainerItem>
              <EmailIcon color='text.primary' mr={1} />
              <a href='mailto:support@istock.info'>support@istock.info</a>
            </ContactsContainerItem>
          </ContactsContainer>
        </SupportContainer>
      </div>
    </ManualsArticlePage>
  );
});

export { AdvertisingPage };
