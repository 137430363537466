import { IRecyclingCode } from 'common/types/dictionaries.types';

export const itemToRecyclingCodesString = (
  { codeNumber, code, extraRu: codeRu, name }: IRecyclingCode,
  isFull = true,
): string => {
  const codeNumberString = codeNumber
    ? `${codeNumber}${isFull || !code ? ' ' : '-'}`
    : '';
  const codeString = code ? `${code} ` : '';
  const codeRuString = codeRu ? `(${codeRu}) ` : '';
  const dash = codeNumber || code || codeRu ? '- ' : '';

  return `${codeNumberString}${codeString}${codeRuString}${
    isFull ? `${dash}${name}` : ''
  }`;
};
