import { Fragment, useState, memo, FC } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { hasIdentity } from 'entities/identity';
import { ModalController, useModalContext } from 'entities/modals';
import { GoPageIcon } from 'resources/starting';
import { IDENTITY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import { Container, Text, Button } from 'UI';
import { TablistTab } from 'UI/TabList';

import { TABS, MODAL_FORM, CARDS } from './constants';
import { ReactComponent as ProductImgIcon } from './images/product-img.svg';
import { ProductModal } from './Modal';
import impulseTheme from '../../../../theme/theme';
import { COLOR, CONTAINER_PROPS, TEXT_PROPS } from '../constants';

const CardTextLink = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    margin-top: auto;
    margin-left: auto;
    color: ${colors.text.disabled};

    &:hover {
      color: ${impulseTheme.colors.primary.main};
    }
  `,
);

const StyledTab = styled(TablistTab)(
  ({ theme: { colors } }) => css`
    flex: 1;
    color: ${colors.text.primary};

    &[aria-selected='true'] {
      color: ${impulseTheme.colors.primary.main};
      border-color: ${impulseTheme.colors.primary.main};
    }
  `,
);

export const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 20px;
  width: 100%;
  margin-right: 20px;
  line-height: 1.4;

  & > div {
    background: ${COLOR};
  }
`;

const StyledButton = styled(Button)<{ textColor?: string }>(
  ({ theme: { fontSizes }, textColor }) => css`
    height: 46px;
    font-size: ${fontSizes[7]}px;
    font-weight: 500;
    border-radius: 8px;

    ${textColor
      ? `color: ${textColor}`
      : css`
          background: ${impulseTheme.colors.primary.main};

          &:hover {
            background: ${impulseTheme.colors.primary.dark};
          }
        `}
  `,
);

interface ICardLinkProps {
  hasAuth?: boolean;
  external?: boolean;
  text: string;
  to: string;
}

const CardLink: FC<ICardLinkProps> = ({ hasAuth, external, text, to }) => {
  const isAuthed = useAppSelector(hasIdentity);

  const needAuth = hasAuth && !isAuthed;
  const linkTo = needAuth ? IDENTITY.AUTHENTICATION : to;
  const linkProps =
    external && !needAuth
      ? { as: 'a', href: linkTo }
      : { as: Link, to: linkTo };

  return (
    // @ts-ignore
    <CardTextLink {...linkProps}>
      <Text {...TEXT_PROPS['18']} mr={1}>
        {text}
      </Text>
      <GoPageIcon color='inherit' />
    </CardTextLink>
  );
};

const Products: FC = memo(() => {
  const theme = useTheme();

  const { handleModalOpen } = useModalContext();

  const [activeTab, setActiveTab] = useState(TABS[0].key);

  const handleClick = (title: string): void => {
    handleModalOpen(MODAL_FORM, { title });
  };

  return (
    <Fragment>
      <Container {...CONTAINER_PROPS} column mb='131px'>
        <Container
          borderBottom={`1px solid ${theme.colors.divider}`}
          height='73px'
          justifyContent='space-between'
          mb={4}
        >
          {TABS.map(({ key, text }) => (
            <StyledTab
              {...TEXT_PROPS['24']}
              key={key}
              aria-selected={activeTab === key}
              fontWeight={500}
              onClick={() => setActiveTab(key)}
            >
              {text}
            </StyledTab>
          ))}
        </Container>
        <Container>
          <CardsWrapper>
            {CARDS[activeTab].map(({ title, text, link }, index) => (
              <Container
                key={index}
                column
                borderRadius='8px'
                padding={`${theme.space[5]}px ${theme.space[5]}px ${theme.space[4]}px`}
              >
                <Text {...TEXT_PROPS['24']} mb='20px'>
                  {title}
                </Text>
                <Text {...TEXT_PROPS['16']} fontWeight={400} mb='20px'>
                  {text}
                </Text>
                <CardLink {...link} />
              </Container>
            ))}
          </CardsWrapper>
          <Container
            column
            maxWidth='279px'
            minWidth='279px'
            padding='46px 0px 34px 26px'
          >
            <ProductImgIcon mb={4} mt={5} />
            <Text {...TEXT_PROPS['24']} mb='20px'>
              Есть вопросы?
            </Text>
            <Text {...TEXT_PROPS['16']} fontWeight={400} mb={4}>
              Расскажем о возможностях
              <br />
              системы и сценариях применения
              <br />
              на вашем предприятии
            </Text>
            <StyledButton
              mb='20px'
              onClick={() => handleClick('Заявка на демонстрацию')}
            >
              Заявка на демонстрацию
            </StyledButton>
            <StyledButton
              color='secondary'
              textColor={theme.colors.text.primary}
              variant='outlined'
              onClick={() => handleClick('Задать вопрос')}
            >
              Задать вопрос
            </StyledButton>
          </Container>
        </Container>
      </Container>
      <ModalController component={ProductModal} type={MODAL_FORM} />
    </Fragment>
  );
});

export { Products };
