import { FC, memo } from 'react';

import styled from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { INITIAL_DATA_REQUEST_V1 } from 'common/constants/request.const';
import { useRequest } from 'common/hooks';
import { IProduct } from 'common/types/product.types';
import { DataTable, IDataTableColumn, ProgressIndicator } from 'UI';
import { colors } from 'UI/theme';

interface IProductVersionsListProps {
  root: IProduct;
  columns: IDataTableColumn[];
}

const IndicatorContainer = styled.div`
  position: relative;
  top: -2px;
`;

const ProductVersionsList: FC<IProductVersionsListProps> = memo(
  ({ root, columns }): JSX.Element => {
    const { isLoading, data = INITIAL_DATA_REQUEST_V1 } = useRequest(
      fetchProducts,
      [
        {
          root: root.id,
          orderBy: 'updatedAt',
          orderDirection: 'DESC',
          itemsPerPage: 60,
        },
      ],
      {
        responseToData: ({ member, totalItems }) => ({
          totalItems,
          member: member.map(product => {
            const { version } = product;
            if (!version.parent) {
              /**
               * Временный костыль пока не будет нового решения на беке
               */
              return {
                ...product,
                version: {
                  ...version,
                  isRoot: true,
                },
              };
            }

            return product;
          }),
        }),
      },
    );

    /**
     * Т.к. вложенные элементы у нас появляются с задержкой, в таблицу добавляется лоадер,
     * отображающийся как будто это нижний бордер агрегирующей карточки: для этого добавляется
     * еще один ряд, а все ячейки схлопываются в одну широкую
     */

    if (isLoading) {
      return (
        <tr>
          <td colSpan={columns.length + 1}>
            <IndicatorContainer>
              <ProgressIndicator isLoading />
            </IndicatorContainer>
          </td>
        </tr>
      );
    }

    return data.member.map((version, index) => {
      return (
        <DataTable.Row
          key={version.id}
          borderLeft={`4px solid ${colors.text.disabled}`}
        >
          <DataTable.Columns columns={columns} index={index} item={version} />
        </DataTable.Row>
      );
    });
  },
);

export { ProductVersionsList };
