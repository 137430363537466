import {
  CSSProperties,
  ElementType,
  FC,
  Fragment,
  isValidElement,
  ReactNode,
  useRef,
} from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { useHint } from 'common/hooks';
import { PrevMediumIcon } from 'resources/icons/18';
import { Help1pxIcon } from 'resources/icons/1px-18';

import { Badge } from '../Badge/Badge';
import { Container } from '../Blocks';
import { VerticalDivider } from '../Divider/Divider';
import { Heading, headingAppearances } from '../Heading';
import { HintWindow } from '../HintWindow';
import { IconButton } from '../IconButton';
import { Tip } from '../Tip';
import { Tooltip } from '../Tooltip';

const HeaderContainer = styled.div<{ border?: boolean } & SpaceProps>(
  ({ theme: { space, colors }, border }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 50px;
    padding: ${space[1]}px ${space[0]}px ${space[1]}px 0;
    background-color: white;

    ${border && `border-bottom: 1px solid ${colors.divider}`};

    ${styledSpace};
  `,
);

const StyledHeading = styled(Heading)(
  ({ as, theme: { space } }) => css`
    max-width: 561px;
    padding-left: ${space[2]}px;
    margin-right: ${space[0]}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    ${headingAppearances[as]}
  `,
);

const IconButtonWrapper = styled(IconButton)(
  ({ theme: { space, colors } }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    width: ${space[5]}px;
    height: ${space[5]}px;

    &:hover {
      background: ${colors.white};

      & svg {
        color: ${colors.text.primary};
      }
    }

    ${styledSpace};
  `,
);

type BackLinkType = string | VoidFunction;

type GetBackLinkReturnPropsType =
  | {
      as: ElementType;
      to: string;
    }
  | { onClick: VoidFunction };

const getBackLinkProps = (
  backLink: BackLinkType,
): GetBackLinkReturnPropsType => {
  const isBackLinkURL = typeof backLink === 'string';
  return isBackLinkURL ? { as: Link, to: backLink } : { onClick: backLink };
};

interface IBackLink {
  className?: string;
  backLink: BackLinkType;
  divider?: boolean;
  title?: string;
}

const BackLink: FC<IBackLink & SpaceProps> = ({
  className,
  backLink,
  divider,
  title,
  ...other
}) => {
  return (
    <Container alignItems='center' className={className}>
      <IconButton
        title={title ?? 'Назад'}
        {...getBackLinkProps(backLink)}
        {...other}
      >
        <PrevMediumIcon />
      </IconButton>
      {divider && <VerticalDivider height={34} ml={0} />}
    </Container>
  );
};

const TITLE_BACK = {
  placement: 'right',
  title: 'Назад',
};

interface IBackLinkControlProps {
  backLink: BackLinkType;
}

const BackLinkControl: FC<IBackLinkControlProps & SpaceProps> = ({
  backLink,
  ...other
}) => {
  return (
    <IconButtonWrapper
      title={TITLE_BACK}
      {...getBackLinkProps(backLink)}
      {...other}
    >
      <PrevMediumIcon size={14} />
    </IconButtonWrapper>
  );
};

type PageHeaderType = {
  className?: string;
  style?: CSSProperties;
  backLink?: false | BackLinkType;
  heading?: ReactNode;
  badge?: false | string;
  tip?: string | JSX.Element;
  hintWindow?: { key: string; title?: string };
  controls?: ReactNode;
  headingAppearance?: ElementType;
  noBorder?: boolean;
  /**
   * Добавляет компонент справа от заголовка (heading)
   */
  rightHeadingAddon?: ReactNode;
} & SpaceProps;

const PageHeader: FC<PageHeaderType & SpaceProps> = ({
  className,
  style,
  backLink,
  heading = '',
  badge,
  tip = '',
  hintWindow = { key: '', title: '' },
  controls,
  headingAppearance = 'h2',
  noBorder,
  rightHeadingAddon,
  ...other
}) => {
  const tipIconRef = useRef<HTMLElement>(null);
  const { isViewed, handleToggleViewed } = useHint(hintWindow.key);

  return (
    <HeaderContainer
      border={!noBorder}
      className={className}
      style={style}
      {...other}
    >
      <Container alignItems='center'>
        {backLink && (
          <BackLink backLink={backLink} divider={!!heading} ml={2} />
        )}
        {isValidElement(heading) ? (
          heading
        ) : (
          <Fragment>
            <Tooltip truncate placement='bottom' title={heading}>
              <StyledHeading as={headingAppearance}>{heading}</StyledHeading>
            </Tooltip>
            {tip &&
              (isViewed ? (
                <Tip tip={tip} />
              ) : (
                <Fragment>
                  <Help1pxIcon ref={tipIconRef} />
                  <HintWindow
                    open
                    anchorEl={tipIconRef.current}
                    offset={[-30, 0]}
                    placement='right-start'
                    title={
                      hintWindow.title ||
                      (typeof heading === 'string' ? heading : '')
                    }
                    onClose={handleToggleViewed}
                  >
                    {tip}
                  </HintWindow>
                </Fragment>
              ))}
            {badge && (
              <Badge
                fontSize={2}
                fontWeight={600}
                label={badge}
                letterSpacing={0.5}
                ml={tip ? 1 : undefined}
                px={1}
                size='m'
                type={Badge.TYPES.SECONDARY}
              />
            )}
            {rightHeadingAddon}
          </Fragment>
        )}
      </Container>
      {controls && (
        <Container alignItems='center' flex='0 0 auto' px={0}>
          {controls}
        </Container>
      )}
    </HeaderContainer>
  );
};

// Пока оставил для
// PageHeader.propTypes = {
//   heading: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
//   headingAppearance: PropTypes.string,
//   controls: PropTypes.node,
//   tip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
//   backLink: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
// };

export { PageHeader, PageHeaderType, BackLink, BackLinkControl, StyledHeading };
