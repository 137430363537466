import { Fragment, useState, useEffect, memo, useRef, FC } from 'react';

import { throttle } from 'lodash';
import styled, { css } from 'styled-components';

import { hasIdentity } from 'entities/identity';
import { BUTTON_BORDER_RADIUS_STYLE } from 'nomenclature/starting/components/constants';
import { ListIcon, CloseLargeIcon } from 'resources/icons/24';
import { getPath, DICTIONARIES, PROFILE, IDENTITY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import { Container, Button, Text, Divider } from 'UI';

import { Catalogs } from './Catalogs';
import { INFORMATION } from '../../../../routes';
import impulseTheme from '../../../../theme/theme';
import { CONTAINER_PROPS, GAP } from '../constants';
import { SearchInput } from '../SearchInput';
import { ImpulseButton } from '../styled';

const MenuButton = styled(Button).attrs({ as: Link, variant: 'string' })(
  ({ theme: { colors } }) => css`
    font-size: 18px;
    color: ${colors.text.primary};

    &:hover {
      color: ${impulseTheme.colors.primary.dark};
    }
  `,
);

const HEADER_TOP_POSITION = 80;
const CUSTOM_BORDER_RADIUS = { borderRadius: '8px' };
const DIVIDER_WIDTH = '1550px';

interface IHeaderProps {
  openCatalogsMenu: boolean;
  containerElement: HTMLDivElement | undefined;
  toggleCatalogsMenu: VoidFunction;
}

const Header: FC<IHeaderProps> = memo(
  ({ openCatalogsMenu, containerElement, toggleCatalogsMenu }) => {
    const isAuthed = useAppSelector(hasIdentity);
    const headerRef = useRef<HTMLDivElement>(null);

    const [isHeaderTop, setIsSearchHeaderTop] = useState(false);

    useEffect(() => {
      const handleScroll = throttle((): void => {
        setIsSearchHeaderTop(
          Number(headerRef.current?.offsetTop) >= HEADER_TOP_POSITION,
        );
      }, 100);

      containerElement?.addEventListener('scroll', handleScroll);

      return () => {
        containerElement?.removeEventListener('scroll', handleScroll);
      };
    }, [containerElement]);

    const buttonLink = getPath(
      isAuthed ? PROFILE.PAGE : IDENTITY.AUTHENTICATION,
    );

    return (
      <Fragment>
        <Container
          {...CONTAINER_PROPS}
          alignItems='center'
          gap={4}
          minHeight='78px'
          py={2}
        >
          <MenuButton to={getPath(DICTIONARIES.COMPANIES)}>Компании</MenuButton>
          <MenuButton to={INFORMATION.GUIDES.TRADING_PLATFORM}>ЭТП</MenuButton>
          {!isHeaderTop && (
            <Button
              as={Link}
              color='secondary'
              fontSize='18px'
              fontWeight={500}
              ml='auto'
              size='l'
              style={BUTTON_BORDER_RADIUS_STYLE}
              to={buttonLink}
            >
              <Text color='text.primary'>
                {isAuthed ? 'Личный кабинет' : 'Вход и регистрация'}
              </Text>
            </Button>
          )}
        </Container>
        <Divider mx='auto' my='0px' px={2} width={DIVIDER_WIDTH} />
        <Container
          ref={headerRef}
          bg='white'
          minHeight='96px'
          position='sticky'
          top='-1px'
          zIndex={100}
        >
          <Container alignItems='center' gap={GAP} {...CONTAINER_PROPS}>
            <Text fontSize='30px'>Импульс</Text>
            <ImpulseButton
              fontSize='18px'
              fontWeight={500}
              height='54px'
              minWidth='238px'
              size='l'
              style={CUSTOM_BORDER_RADIUS}
              onClick={toggleCatalogsMenu}
            >
              Каталог продукции
              {openCatalogsMenu ? (
                <CloseLargeIcon color='white' ml={1} />
              ) : (
                <ListIcon color='white' ml={1} />
              )}
            </ImpulseButton>
            <SearchInput />
            {isHeaderTop && (
              <Button
                as={Link}
                color='secondary'
                fontSize='18px'
                fontWeight={500}
                height='52px'
                size='l'
                style={BUTTON_BORDER_RADIUS_STYLE}
                to={buttonLink}
              >
                <Text color='text.primary'>
                  {isAuthed ? 'Личный кабинет' : 'Вход и регистрация'}
                </Text>
              </Button>
            )}
          </Container>
          {openCatalogsMenu && <Catalogs isHeaderTop={isHeaderTop} />}
        </Container>
      </Fragment>
    );
  },
);

export { Header };
