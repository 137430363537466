import {
  CSSProperties,
  ElementType,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import styled, { css, CSSProp } from 'styled-components';
import {
  color as styledColor,
  ColorProps,
  fontSize,
  FontSizeProps,
  layout,
  LayoutProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { Nullable } from 'common/types/common.types';
import { Delete1pxIcon } from 'resources/icons/1px-12';
import { TriangleIcon } from 'resources/other';

import { Text } from '../Text';
import { colors as themeColors } from '../theme';

type SizeTag = 's' | 'm' | 'l';
type VariantTag = 'filled' | 'outlined';
type ColorTag = 'primary' | 'secondary' | 'error' | 'info';
type StyleStates = 'default' | 'hover' | 'disabled';
type ThemeTag = Record<
  VariantTag,
  Partial<Record<ColorTag, Record<StyleStates, CSSProp>>>
>;

const sizeStyle = (props): CSSProp => {
  const {
    size,
    theme: { space, fontSizes },
  } = props;

  if (size === 's') {
    return css`
      height: ${space[2]}px;
      padding: 0 2px;
      font-size: ${fontSizes[2]}px;
    `;
  }

  if (size === 'm') {
    return css`
      height: 18px;
      padding: 0 ${space[0]}px;
      font-size: ${fontSizes[3]}px;
    `;
  }

  return css`
    height: ${space[4]}px;
    padding: 0 ${space[1]}px;
    font-size: ${fontSizes[5]}px;
  `;
};

const themeTagByVariant: ThemeTag = {
  filled: {
    primary: {
      default: css`
        color: ${themeColors.white};
        background: ${themeColors.primary.main};

        & svg {
          color: ${themeColors.white};
        }
      `,
      hover: css``,
      disabled: css``,
    },
    secondary: {
      default: css`
        color: ${themeColors.text.primary};
        background: ${themeColors.highlight[0]};

        & svg {
          color: ${themeColors.highlight[0]};
        }
      `,
      hover: css`
        background: ${themeColors.highlight[2]};
        color: ${themeColors.text.primary};

        & svg {
          color: ${themeColors.highlight[2]};
        }
      `,
      disabled: css`
        cursor: default;
        background: ${themeColors.highlight[0]};
        color: ${themeColors.text.disabled};
      `,
    },
  },
  outlined: {
    info: {
      default: css`
        color: ${themeColors.text.primary};
        background: ${themeColors.white};
        border: 1px solid ${themeColors.divider};
      `,
      hover: css``,
      disabled: css``,
    },
  },
};

const Arrow = styled(TriangleIcon)(
  ({ theme: { space, colors } }) => css`
    position: absolute;
    right: -${space[0]}px;
    color: ${colors.highlight[0]};
  `,
);

const variantStyle = ({
  isRemove = false,
  variant = 'filled',
  colorTheme = 'secondary',
}): Nullable<CSSProp> => {
  const tagByVariant = themeTagByVariant[variant][colorTheme];

  return css`
    ${tagByVariant.default};

    &[href]:hover,
    &[type='button']:hover {
      ${tagByVariant.hover};
      cursor: pointer;
    }

    &:disabled,
    &[disabled] {
      ${tagByVariant.disabled};
      pointer-events: none;
    }

    ${isRemove &&
    css`
      & svg {
        color: ${colorTheme === 'secondary'
          ? themeColors.text.secondary
          : tagByVariant.color};
      }
    `}
  `;
};

interface IStyledTag {
  arrow?: boolean;
  size?: SizeTag;
  variant?: VariantTag;
  bg?: string;
  colorTheme?: ColorTag;
}

const StyledTag = styled.div<
  IStyledTag & SpaceProps & FontSizeProps & LayoutProps
>(
  ({ theme: { space, borderRadius }, arrow }) => css`
    position: relative;
    display: flex;
    align-items: center;
    border-radius: ${borderRadius}px;
    min-width: 0;
    transition: background 200ms ease-in;

    &:not:last-child {
      margin-right: ${space[0]}px;
    }

    & svg {
      transition: color 200ms ease-in;
    }

    ${arrow &&
    css`
      margin-right: ${space[0]}px;
      padding: 0 ${space[0]}px;
      border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
    `}

    ${variantStyle};
    ${sizeStyle};
    ${styledSpace};
    ${fontSize};
    ${styledColor};
    ${layout}
  `,
);

interface ITagProps {
  style?: CSSProperties;
  className?: string;
  disabled?: boolean;
  id?: string;
  as?: ElementType;
  to?: string;
  type?: 'submit' | 'reset' | 'button';
  value: ReactNode;
  target?: '_blank' | '_self';
  itemToIcon?: JSX.Element;
  onRemove?: (e: MouseEventHandler<HTMLDivElement>, id?: string) => void;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

const Tag = forwardRef<
  HTMLDivElement,
  ITagProps & IStyledTag & SpaceProps & FontSizeProps & LayoutProps & ColorProps
>(
  (
    {
      style,
      className,
      arrow,
      disabled,
      id,
      as,
      to,
      type,
      value,
      target,
      itemToIcon,
      size = arrow ? 'm' : 's',
      variant = 'filled',
      colorTheme = 'secondary',
      onClick,
      onRemove,
      ...other
    },
    ref,
  ): JSX.Element => {
    return (
      <StyledTag
        ref={ref}
        arrow={arrow}
        as={as}
        className={className}
        colorTheme={colorTheme}
        disabled={disabled}
        isRemove={!!onRemove}
        role='link'
        size={size}
        style={style}
        target={target}
        to={to}
        type={type}
        variant={variant}
        onClick={onClick}
        {...other}
      >
        {itemToIcon}
        <Text truncate>{value}</Text>
        {onRemove && (
          <Delete1pxIcon
            as='button'
            ml={0}
            mr='2px'
            size={12}
            onClick={e => onRemove(e, id)}
          />
        )}
        {arrow && <Arrow />}
      </StyledTag>
    );
  },
);

export { Tag, StyledTag, ITagProps };
