import { memo, FC } from 'react';

import styled, { useTheme } from 'styled-components';

import { getFormattedNumber } from 'common/utils';
import { CATALOGS } from 'routes';
import { Img, Link } from 'tools/libs';
import { Container, Text } from 'UI';

import {
  CATALOG_INFO,
  LINK_STYLE_PROPS,
  CHINA_STANDARD_LINK,
  RIGHT_COLUMN_STYLE_PROPS,
} from './constants';
import { ReactComponent as CategoryIcon } from './images/category.svg';
import flagPath from './images/flag.png';
import impulseTheme from '../../../../theme/theme';
import { COLOR, CONTAINER_PROPS, GAP, TEXT_PROPS } from '../constants';

const CategoryLink = styled(Link)`
  &:hover {
    color: initial;
  }
`;

const CatalogLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 240px;
  width: 240px;
  color: ${impulseTheme.colors.primary.main};
  background: ${impulseTheme.colors.highlight[1]};
  border-radius: 8px;

  &:hover {
    color: ${impulseTheme.colors.primary.dark};
  }
`;

const CatalogItem: FC<{ index: number }> = ({ index }) => {
  const { title, count, imagePath, link } = CATALOG_INFO[index];

  return (
    <Container
      {...LINK_STYLE_PROPS}
      key={title}
      as={CategoryLink}
      borderRadius='8px'
      p={3}
      to={link}
      width='100%'
    >
      <Container
        column
        alignItems='flex-start'
        height='100%'
        justifyContent='space-between'
      >
        <Text {...TEXT_PROPS[index === 0 ? '36' : '24']}>{title}</Text>
        <Text {...TEXT_PROPS[index === 0 ? '48' : '36']}>
          {getFormattedNumber(count)}
        </Text>
      </Container>
      <Container ml='auto' mt='auto'>
        <Img alt={title} src={imagePath} />
      </Container>
    </Container>
  );
};

const Categories: FC = memo(() => {
  const theme = useTheme();

  return (
    <Container background={COLOR}>
      <Container {...CONTAINER_PROPS} column pb='100px' pt='76px'>
        <Container column alignContent='space-between'>
          <Container>
            <Text {...TEXT_PROPS['48']} mb='64px'>
              Популярные категории
            </Text>
          </Container>
          <Container gap={GAP}>
            <Container
              column
              as={CategoryLink}
              bg={theme.colors.white}
              borderRadius='8px'
              color='text.primary'
              height='440px'
              p={3}
              to={CHINA_STANDARD_LINK}
              width='240px'
            >
              <Img
                alt='Флаг Дагестана'
                height='184px'
                src={flagPath}
                width='184px'
              />
              <Text {...TEXT_PROPS['24']} my={3}>
                Производители и поставщики Дагестана
              </Text>
              <Text {...TEXT_PROPS['36']}>640</Text>
            </Container>
            <Container column gap={GAP} width='740px'>
              <Container
                bg={theme.colors.white}
                borderRadius='8px'
                height='440px'
              >
                <CatalogItem index={0} />
              </Container>
              <Container gap={GAP} width='740px'>
                <CatalogLink to={CATALOGS.ROOT}>
                  <CategoryIcon />
                  <Text {...TEXT_PROPS['20']}>Все каталоги</Text>
                </CatalogLink>
                <Container
                  {...RIGHT_COLUMN_STYLE_PROPS}
                  bg={theme.colors.white}
                  width='500px'
                >
                  <CatalogItem index={1} />
                </Container>
              </Container>
            </Container>
            <Container column gap={GAP} width='500px'>
              <Container
                {...RIGHT_COLUMN_STYLE_PROPS}
                bg={theme.colors.highlight[0]}
                fontSize='20px'
                fontWeight={400}
                p='48px'
              >
                Добавьте вашу продукцию в отраслевые каталоги, которые ежедневно
                просматривают тысячи пользователей
              </Container>
              <Container {...RIGHT_COLUMN_STYLE_PROPS} bg={theme.colors.white}>
                <CatalogItem index={2} />
              </Container>
              <Container {...RIGHT_COLUMN_STYLE_PROPS} bg={theme.colors.white}>
                <CatalogItem index={3} />
              </Container>
            </Container>
          </Container>
        </Container>
      </Container>
    </Container>
  );
});

export { Categories };
