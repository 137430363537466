import { FC, memo } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { POINTS_OF_SALE_TYPES_NAME } from 'common/constants/points-of-sale.const';
import { IPointOfSaleMapCard } from 'common/types/point-of-sale.types';
import { getImageURL } from 'common/utils';
import {
  EmailIcon,
  LinkOutsideIcon,
  PhoneIcon,
  TimeIcon,
} from 'resources/icons/18';
import { COMPANY, getPath } from 'routes';
import { Avatar, Badge, Container, Text, Tooltip } from 'UI';

import { ContactItem, MapCardContainer } from '../../../Map/_shared/styled';
import {
  GeneratedBySystemStatus,
  OfficialRetailerStatus,
} from '../../PointOfSalesStatuses';

const ExternalLinkIcon = styled(LinkOutsideIcon)(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: none;
    font-size: 0;
    height: 34px;
    width: 34px;
    padding: ${space[1]}px;
    border-radius: ${borderRadius}px;

    &:hover {
      background: ${colors.highlight[1]};
    }

    ${MapCardContainer}:hover & {
      display: block;
    }
  `,
);

const PointsOfSaleMapCard: FC<IPointOfSaleMapCard> = memo(
  ({
    style,
    active,
    location: {
      id,
      name,
      openingHours,
      telephones,
      email,
      location,
      type,
      ownershipCompany,
      isGeneratedBySystem,
    },
    handleClick,
  }) => {
    const mainPhone =
      telephones && telephones?.length > 0
        ? telephones?.map(item => (item.isMain ? item.value : null))
        : null;

    return (
      <MapCardContainer active={active} style={style} onClick={handleClick}>
        <Badge
          fontSize='8px'
          fontWeight={500}
          label={type ? POINTS_OF_SALE_TYPES_NAME[type] : null}
          letterSpacing='0.5px'
          mb={0}
          textTransform='uppercase'
        />
        <Container alignItems='center' display='inline-flex' mb={0} width='90%'>
          <Text truncate fontSize={4} fontWeight={500}>
            {name}
          </Text>
          {ownershipCompany?.isOfficial && (
            <OfficialRetailerStatus companyId={ownershipCompany?.id} ml={1} />
          )}
          {isGeneratedBySystem && <GeneratedBySystemStatus ml={1} />}
        </Container>
        {location && (
          <ContactItem primaryColor={false} text={location.address} />
        )}
        <div style={{ position: 'absolute', top: '25px', right: '25px' }}>
          <Tooltip arrow hint title='К месту продаж'>
            <Link to={getPath(COMPANY.POINTS_OF_SALE_PAGE, { id })}>
              <ExternalLinkIcon color='primary.main' size={18} />
            </Link>
          </Tooltip>
        </div>
        <ContactItem
          icon={TimeIcon}
          mb={0}
          style={{ width: '90%' }}
          text={openingHours}
          tooltip='Время работы'
        />
        <ContactItem
          icon={PhoneIcon}
          mb={0}
          text={mainPhone}
          tooltip='Телефон'
        />
        <ContactItem icon={EmailIcon} text={email} tooltip='Email' />
        <Text color='text.secondary' fontSize={12} mt='auto'>
          Наличие не указано
        </Text>
      </MapCardContainer>
    );
  },
);

const PointsOfSaleStandardMapCard: FC<IPointOfSaleMapCard> = memo(
  ({
    style,
    active,
    location: {
      id,
      name,
      openingHours,
      telephones,
      email,
      location,
      type,
      ownershipCompany,
    },
    handleClick,
  }) => {
    const mainPhone = telephones?.map(item => {
      return item.isMain ? item.value : null;
    });

    return (
      <MapCardContainer active={active} style={style} onClick={handleClick}>
        <Badge
          fontSize={0}
          fontWeight={500}
          label={type ? POINTS_OF_SALE_TYPES_NAME[type] : null}
          letterSpacing='0.5px'
          mb={0}
          textTransform='uppercase'
        />
        <Container column mb={0} minHeight='32px'>
          <Text
            truncate
            fontSize={4}
            fontWeight={500}
            mb={0}
            mr='auto'
            pr={1}
            style={{ width: '90%' }}
          >
            {name}
          </Text>
          {location && (
            <ContactItem primaryColor={false} text={location.address} />
          )}
          <div style={{ position: 'absolute', right: '-35px' }}>
            <Tooltip arrow hint title='К месту продаж'>
              <Link to={getPath(COMPANY.POINTS_OF_SALE_PAGE, { id })}>
                <ExternalLinkIcon color='primary.main' size={18} />
              </Link>
            </Tooltip>
          </div>
        </Container>
        <ContactItem
          icon={TimeIcon}
          mb={0}
          text={openingHours}
          tooltip='Время работы'
        />
        <ContactItem
          icon={PhoneIcon}
          mb={0}
          text={telephones ? mainPhone : null}
          tooltip='Телефон'
        />
        <ContactItem icon={EmailIcon} text={email} tooltip='Email' />
        <Avatar
          cover
          rounded
          as={Link}
          borderColor='colors.white'
          size='xxs'
          to={getPath(COMPANY.PAGE, {
            id: ownershipCompany?.id,
          })}
          url={getImageURL(ownershipCompany?.logo, { size: 'l' })}
        />
      </MapCardContainer>
    );
  },
);

export { PointsOfSaleMapCard, PointsOfSaleStandardMapCard };
