import { FC } from 'react';

import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { placeholder403Path, placeholder404Path, placeholder500Path } from 'resources/placeholders';
import { Button, Heading, Text } from 'UI';

import { DefaultPage } from '../layouts/Pages';

const Wrapper = styled.div<{ full?: boolean }>(
  ({ theme: { colors }, full }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    ${full &&
    css`
      position: fixed;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      min-height: 100vh;
      background: ${colors.white};
    `}
  `,
);

const Image = styled.img(
  ({ theme: { space } }) => css`
    width: 600px;
    height: 300px;
    margin-bottom: ${space[6]}px;
  `,
);

interface ICommonProps {
  full?: boolean;
  src: string;
  title: string;
  description: string;
  buttonText: string;
  action: VoidFunction;
}

const Common: FC<ICommonProps> = ({
  full,
  src,
  title,
  description,
  buttonText,
  action,
}) => {
  return (
    <DefaultPage>
      <Wrapper full={full}>
        <Image src={src} />
        <Heading as='h2'>{title}</Heading>
        <Text color='text.secondary' fontSize={5} mt={2} textAlign='center'>
          {description}
          <br />
          Попробуйте{' '}
          <Button fontSize={5} variant='string' onClick={action}>
            {buttonText}
          </Button>{' '}
          или начните с <Link to='/'>главной страницы.</Link>
        </Text>
      </Wrapper>
    </DefaultPage>
  );
};

const Forbidden: FC = () => {
  const history = useHistory();

  return (
    <Common
      action={() => history?.goBack()}
      buttonText='вернуться назад'
      description='Вы зашли под нужным аккаунтом? И проверьте, есть ли у вас необходимые права.'
      src={placeholder403Path}
      title='Вы пытались сделать что-то, что мы не можем подтвердить.'
    />
  );
};

const NotFound: FC<{ full?: boolean }> = ({ full }) => {
  const history = useHistory();

  return (
    <Common
      action={() => history?.goBack()}
      buttonText='вернуться назад'
      description='Возможно, страница была удалена или ее адрес изменен.'
      full={full}
      src={placeholder404Path}
      title='К сожалению, мы не смогли найти эту страницу.'
    />
  );
};

const InternalServerError: FC = () => {
  return (
    <Common
      full
      action={() => window.location.reload()}
      buttonText='обновить страницу'
      description='Но мы уже в курсе об этом.'
      src={placeholder500Path}
      title='Что-то пошло не так...'
    />
  );
};

export { Forbidden, NotFound, InternalServerError };
