import { MouseEventHandler, useState } from 'react';

import { FCWithBlankChildren, Nullable } from 'common/types/common.types';
import { ClosePanelLeftIcon, OpenPanelIcon } from 'resources/icons/24';
import { useAppSelector } from 'store';
import { Container } from 'UI';

import { MainMenuGroup } from './Group';
import { MainMenuItem } from './Item';
import { MainMenuContext } from '../../context';
import { useLayoutActions } from '../../hooks';
import { getLayoutStates } from '../../store';
import { ButtonCollapse, MenuContainer, MenuWrapper } from '../styled';

const MainMenu: FCWithBlankChildren & {
  Group: typeof MainMenuGroup;
  Item: typeof MainMenuItem;
} = ({ children }) => {
  const [openSection, setOpenSection] = useState<Nullable<string>>(null);
  const { collapseMainMenu } = useAppSelector(getLayoutStates);
  const { toggleCollapseMainMenu } = useLayoutActions();

  return (
    <MenuContainer
      collapse={collapseMainMenu}
      collapseWidth={50}
      isBordered={false}
      width={244}
    >
      <MenuWrapper collapse={collapseMainMenu} pb={2}>
        <MainMenuContext.Provider value={{ openSection, setOpenSection }}>
          {children}
        </MainMenuContext.Provider>
      </MenuWrapper>
      <Container
        alignItems='center'
        as='footer'
        height='52px'
        minHeight='52px'
        mt='auto'
        width='100%'
      >
        <ButtonCollapse
          onClick={
            toggleCollapseMainMenu as unknown as MouseEventHandler<HTMLButtonElement>
          }
        >
          {collapseMainMenu ? (
            <Container justifyContent='center' width='100%'>
              <OpenPanelIcon />
            </Container>
          ) : (
            <ClosePanelLeftIcon />
          )}
        </ButtonCollapse>
      </Container>
    </MenuContainer>
  );
};

MainMenu.Group = MainMenuGroup;
MainMenu.Item = MainMenuItem;

export { MainMenu };
