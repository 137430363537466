import { FC, memo } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { ILocation } from 'common/types/company.types';
import { ContactItem, TelephonesItem } from 'components/Map/_shared/styled';
import { EmailIcon, TimeIcon, ViewMapIcon } from 'resources/icons/18';
import { Text } from 'UI';

const Wrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: grid;
    grid-template-columns: calc(50% - 14px) calc(50% - 14px);
    grid-column-gap: 28px;
    border-bottom: 1px solid ${colors.divider};
    margin-bottom: ${space[3]}px;
    padding-bottom: ${space[3]}px;

    &:last-of-type {
      border-bottom: none;
      margin-bottom: 0;
      padding-bottom: 0;
    }
  `,
);

interface IContactProps {
  isMain?: boolean;
  name: string;
  location?: Nullable<ILocation>;
  openingHours?: string;
  email?: string;
  telephones?: [] | any;
}

interface ICompanyContactCardProps {
  contact: IContactProps;
}

const CompanyContactCard: FC<ICompanyContactCardProps> = memo(({ contact }) => {
  const { isMain, name, location, openingHours, email, telephones } = contact;

  return (
    <Wrapper>
      <div>
        <Text as='p' fontSize={4} fontWeight={500} mb={0} wordBreak='break-all'>
          {name}
        </Text>
        {isMain && (
          <Text as='p' color='text.secondary' fontSize={2}>
            Основная контактная информация
          </Text>
        )}
      </div>
      <div>
        <ContactItem
          icon={ViewMapIcon}
          mb={location?.placement ? 0 : 1}
          text={location?.address}
          tooltip='Адрес'
        />
        {location?.placement && (
          <Text
            as='p'
            color='text.secondary'
            fontSize={2}
            mb={1}
            ml='26px'
            wordBreak='break-all'
          >
            {location.placement}
          </Text>
        )}
        <ContactItem
          icon={TimeIcon}
          placeholder='Не указано'
          text={openingHours}
          tooltip='Время работы'
        />
        <ContactItem
          icon={EmailIcon}
          placeholder='Не указан'
          text={email}
          tooltip='Email'
        />
        <TelephonesItem telephones={telephones} width='300px' />
      </div>
    </Wrapper>
  );
});

export { CompanyContactCard };
