import { useState, FC, ReactElement } from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { findCompaniesByIds } from 'common/api/company-management';
import { fetchEmployee, fetchEmployees } from 'common/api/employees.api';
import { fetchPartners } from 'common/api/partners.api';
import {
  PRODUCT_CATEGORY_TEXT,
  KIND_PRODUCT_OPTIONS_BY_TEXT,
} from 'common/constants/products.const';
import {
  TASK_STATUSES,
  TASK_STATUSES_NAMES,
} from 'common/constants/task.const';
import { useQueryParams, useRequest } from 'common/hooks';
import { ICompany } from 'common/types/company.types';
import { PartnershipStatuses } from 'common/types/partnership';
import { ProductCategory } from 'common/types/product.types';
import { formatDate, getFullName } from 'common/utils';
import { EducationPoint } from 'entities/education';
import { useArrayField, useField } from 'reform';
import { CloseMediumIcon } from 'resources/icons/18';
import { itemTo } from 'tools/utils';
import {
  Select,
  Spinner,
  Relative,
  IconButton,
  MultiSelect,
  ToggleButton,
  SideMenuGroup,
  DateInputRange,
} from 'UI';

import { TOOLTIP_POINT_KEYS } from '../../constants';

const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    gap: ${space[0]}px;
    flex-wrap: wrap;
    margin-top: ${space[1]}px;
  `,
);

const enum ProductTaskExistence {
  withTask = 'withTask',
  withoutTask = 'withoutTask',
}

const PRODUCT_TASK_EXISTENCE_TEXT = {
  [ProductTaskExistence.withTask]: 'С задачей',
  [ProductTaskExistence.withoutTask]: 'Без задачи',
};

const ENTITIES_BY_FILTERS = [
  ProductCategory.product,
  ProductCategory.questionnaire,
];

const PRODUCT_KIND_OPTIONS = Object.keys(KIND_PRODUCT_OPTIONS_BY_TEXT);

const kindToString = (kind: string): string => {
  return KIND_PRODUCT_OPTIONS_BY_TEXT[kind] ?? '';
};

/*
 * Вид продукции
 * Одиночный выбор: Деталь, Сборочная единица, Материал или сырье, Стандартное изделие...
 * ...Комплект, Комплекс, Прочее
 * */
function ProductKindFilter(): ReactElement {
  const history = useHistory();
  const [{ kind }, querySet] = useQueryParams();

  const handleChangeKind = (kindSelected: string): void => {
    const querySetString = querySet(queryProps => ({
      ...queryProps,
      kind: kindSelected,
      page: 1,
    }));

    history.push(querySetString);
  };

  return (
    <Relative>
      <EducationPoint
        section={TOOLTIP_POINT_KEYS.SUB_MENU_KIND_FILTER}
        style={{ top: 0 }}
      >
        <SideMenuGroup isFixedGroup secondary mb={1} title='Вид продукции'>
          <SideMenuGroup.ChildWrapper>
            <Select
              canClear
              itemToString={kindToString}
              options={PRODUCT_KIND_OPTIONS}
              placeholder='Выберите значение'
              value={kind}
              onChange={handleChangeKind}
            />
          </SideMenuGroup.ChildWrapper>
        </SideMenuGroup>
      </EducationPoint>
    </Relative>
  );
}

/*
 * Категория товара
 * Множественный выбор: Изделие и Опросной лист
 * */
function ProductCategoryFilter({ name }): ReactElement {
  const { fields, push, remove } = useArrayField({ name });

  const handleChangeProductCategory = ({ currentTarget }): void => {
    const { checked, value } = currentTarget;

    if (checked) {
      remove(fields.indexOf(value));
    } else {
      push(value);
    }
  };

  return (
    <SideMenuGroup
      isFixedGroup
      fontWeight={600}
      indent={false}
      mt={1}
      title='Категория товара'
    >
      <Wrapper>
        {ENTITIES_BY_FILTERS.map(item => (
          <ToggleButton.Tab
            key={item}
            secondary
            uncheck
            checked={fields.includes(item)}
            label={PRODUCT_CATEGORY_TEXT[item]}
            mb='0px'
            mr='0px'
            value={item}
            onClick={handleChangeProductCategory}
          />
        ))}
      </Wrapper>
    </SideMenuGroup>
  );
}

/*
 * Любая дата (Дата производства, Дата создания, Дата изменения и т.д.)
 * Одиночный выбор (до / после / до и после)
 * */
function ProductDateRangeFilter({ name, title }): ReactElement {
  const { value, setValue } = useField({ name });

  const [
    { nextLeftDisabled, prevRightDisabled },
    setDateDisabledFromDataPicker,
  ] = useState<{
    nextLeftDisabled: undefined | Date;
    prevRightDisabled: undefined | Date;
  }>({
    nextLeftDisabled: (value.before && new Date(value.before)) || undefined,
    prevRightDisabled: (value.after && new Date(value.after)) || undefined,
  });

  const handleChange = (fieldName: string) => (date: Date | '') => {
    if (fieldName === 'after') {
      setDateDisabledFromDataPicker(prev => ({
        ...prev,
        prevRightDisabled: date || undefined,
      }));
    } else {
      setDateDisabledFromDataPicker(prev => ({
        ...prev,
        nextLeftDisabled: date || undefined,
      }));
    }

    const formatNewDate = date ? formatDate(date, false, 'yyyy-MM-dd') : '';

    setValue(prev => ({ ...prev, [fieldName]: formatNewDate }));
  };

  return (
    <SideMenuGroup
      isFixedGroup
      indent={false}
      mt={2}
      rightAction={
        (!!value.after || !!value.before) && (
          <IconButton
            size={28}
            onClick={() => {
              setValue({ after: '', before: '' });
            }}
          >
            <CloseMediumIcon size={12} />
          </IconButton>
        )
      }
      title={title}
    >
      <DateInputRange
        canClear
        leftValue={value.after}
        mt='5px'
        nextLeftDisabled={nextLeftDisabled}
        prevRightDisabled={prevRightDisabled}
        rightValue={value.before}
        onChangeLeft={handleChange('after')}
        onChangeRight={handleChange('before')}
      />
    </SideMenuGroup>
  );
}

const employeeToString = (item): string => getFullName(item) || '';
const fetchAllEmployees = (data): Promise<any> => {
  return Promise.all(data.map(id => fetchEmployee(id)));
};

/*
 * Сотрудник компании (Автор товара, Последний редактор товара и т.д.)
 * Множественный выбор: один/несколько сотрудников
 * */
function ProductEmployeesFilter({ name, title }): ReactElement {
  const { value, setValue } = useField({ name });

  const handleChange = (selectedEmployees): void => {
    setValue(selectedEmployees);
  };

  const { isLoading } = useRequest(
    value.every(item => typeof item === 'string') && fetchAllEmployees,
    [value],
    {
      onSuccess: employees => {
        setValue(employees);
      },
    },
  );

  return (
    <SideMenuGroup
      isFixedGroup
      indent={false}
      mt={2}
      rightAction={
        !!value.length && (
          <IconButton
            size={28}
            onClick={() => {
              setValue([]);
            }}
          >
            <CloseMediumIcon size={12} />
          </IconButton>
        )
      }
      title={title}
    >
      <Spinner loading={isLoading} size='xs'>
        <MultiSelect
          itemToKey={itemTo('id')}
          itemToString={employeeToString}
          options={fetchEmployees}
          placeholder='ФИО сотрудника'
          style={{ marginTop: '5px' }}
          value={value}
          onChange={handleChange}
        />
      </Spinner>
    </SideMenuGroup>
  );
}

const partnerToString = (item): string => {
  return item.partnerCompany?.name ?? item.name;
};

const fetchPartnersOptions = ({ page }): Promise<any> => {
  return fetchPartners({
    page,
    partnershipStatus: PartnershipStatuses.confirmed,
    hasAvailableProducts: 1,
  });
};

/*
 * Партнеры компании
 * Одиночный выбор
 * */
const ProductPartnersFilter: FC<{ name: string }> = ({ name }) => {
  const { value, setValue } = useField({ name });

  const handleChange = (selectedPartner): void => {
    setValue(selectedPartner);
  };

  const { isLoading }: { isLoading: boolean } = useRequest(
    !!value && typeof value === 'string' && findCompaniesByIds,
    [{ minify: true, ids: [value] }],
    {
      onSuccess: (response: { items: Record<string, ICompany> }) => {
        setValue(response.items[value]);
      },
    },
  );

  return (
    <SideMenuGroup isFixedGroup indent={false} mt={2} title='Партнер'>
      <Spinner delay={400} loading={isLoading} size='xs'>
        <Select
          canClear
          disableSearch
          dropdownPlacement='bottom'
          itemToKey={itemTo('id')}
          itemToString={partnerToString}
          options={fetchPartnersOptions}
          placeholder='Выберите партнера'
          value={value}
          onChange={handleChange}
        />
      </Spinner>
    </SideMenuGroup>
  );
};

/*
 * Наличие задачи
 * Одиночный выбор: есть / нет
 * */
function ProductTaskExistenceFilter({ name }): ReactElement {
  const { value, setValue } = useField({ name });

  const getCurrentPick = (): string => {
    if (value.withTask) return ProductTaskExistence.withTask;
    if (value.withoutTask) return ProductTaskExistence.withoutTask;
    return '';
  };

  const handleChangeTaskExistence = ({ currentTarget }): void => {
    const { checked, value: tabValue } = currentTarget;

    if (checked) {
      setValue({ withTask: '', withoutTask: '' });
    } else {
      setValue(
        tabValue === ProductTaskExistence.withTask
          ? { withTask: 'true', withoutTask: '' }
          : { withoutTask: 'true', withTask: '' },
      );
    }
  };

  return (
    <SideMenuGroup isFixedGroup indent={false} mt={1} title='Наличие задачи'>
      <Wrapper>
        {Object.keys(PRODUCT_TASK_EXISTENCE_TEXT).map(item => (
          <ToggleButton.Tab
            key={item}
            secondary
            uncheck
            checked={getCurrentPick() === item}
            label={PRODUCT_TASK_EXISTENCE_TEXT[item]}
            mb='0px'
            mr='0px'
            value={item}
            onClick={handleChangeTaskExistence}
          />
        ))}
      </Wrapper>
    </SideMenuGroup>
  );
}

/*
 * Статус задачи
 * Множественный выбор: Нужно сделать, В работе, На проверке, Готово
 * */
function ProductTaskStatusFilter({ name }): ReactElement {
  const { fields, push, remove } = useArrayField({ name });

  const handleChangeTaskStatus = ({ currentTarget }): void => {
    const { checked, value } = currentTarget;

    if (checked) {
      remove(fields.indexOf(value));
    } else {
      push(value);
    }
  };

  return (
    <SideMenuGroup isFixedGroup indent={false} mt={1} title='Статус задачи'>
      <Wrapper>
        {Object.values(TASK_STATUSES).map(item => (
          <ToggleButton.Tab
            key={item}
            secondary
            uncheck
            checked={fields.includes(item)}
            label={TASK_STATUSES_NAMES[item]}
            mb='0px'
            mr='0px'
            value={item}
            onClick={handleChangeTaskStatus}
          />
        ))}
      </Wrapper>
    </SideMenuGroup>
  );
}

export {
  ProductKindFilter,
  ProductCategoryFilter,
  ProductDateRangeFilter,
  ProductEmployeesFilter,
  ProductPartnersFilter,
  ProductTaskExistenceFilter,
  ProductTaskStatusFilter,
};
