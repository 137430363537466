import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

/**
 * Прямоугольная кнопка на всю ширину родительского пространства.
 *
 * Обычно подписывается в виде "Показано 3 из 20. Показать еще..."
 */
const PaginationButton = styled.button<SpaceProps>(
  ({ theme: { space, colors, borderRadius, fontSizes } }) => css`
    width: 100%;
    height: ${space[3]}px;
    min-height: ${space[3]}px;
    background: ${colors.highlight[0]};
    color: ${colors.text.secondary};
    border-radius: ${borderRadius}px;
    font-size: ${fontSizes[2]}px;
    font-weight: 500;

    &:hover {
      background: ${colors.divider};
    }

    ${styledSpace};
  `,
);

export { PaginationButton };
