import { memo, FC } from 'react';

import { Field, useReform } from 'reform';
import { AddSmallIcon } from 'resources/icons/12';
import { TrashIcon } from 'resources/icons/18';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { useToggle } from 'tools/hooks';
import {
  Text,
  Button,
  Tooltip,
  Container,
  IconButton,
  TextInputField,
} from 'UI';

const FIELD_NAME = 'location.placement';
const TEXT = 'Уточните этаж, номер офиса и другую информацию об адресе';

const LocationPlacementField: FC<{ isInitialOpen: boolean }> = memo(
  ({ isInitialOpen }) => {
    const { setFieldValue } = useReform();

    const [open, toggleOpen] = useToggle(isInitialOpen);

    return open ? (
      <Container alignItems='center'>
        <Field
          component={TextInputField}
          label='Комментарий к адресу'
          mb='11px'
          mt={3}
          name={FIELD_NAME}
          placeholder={TEXT}
          transform={(value: string) => value.trim()}
        />
        <IconButton
          ml={0}
          mt='24px'
          title='Удалить'
          onClick={() => {
            toggleOpen();
            setFieldValue('location.placement', '');
          }}
        >
          <TrashIcon />
        </IconButton>
      </Container>
    ) : (
      <Container alignItems='center' mb={1} mt={2} pb={2}>
        <Button color='secondary' variant='string' onClick={toggleOpen}>
          <IconButton mr={0} size={20}>
            <AddSmallIcon />
          </IconButton>
          <Text fontSize={2} fontWeight={600} pr={1}>
            Добавить комментарий к адресу
          </Text>
          <Tooltip maxWidth={205} title={TEXT}>
            <Help1pxIcon ml={0} />
          </Tooltip>
        </Button>
      </Container>
    );
  },
);

export { LocationPlacementField };
