import styled, { css } from 'styled-components';
import {
  layout,
  background,
  LayoutProps,
  BackgroundProps,
} from 'styled-system';

import { Link } from 'tools/libs';
import { Badge } from 'UI';

export const DictionaryStatus = styled(Badge)`
  position: absolute;
  top: 24px;
  right: 24px;
`;

export const DictionaryAvatarContainer = styled.div`
  display: flex;
  min-height: 160px;
  align-items: center;
`;

export const DictionaryManufacturersContainer = styled.div(
  ({ theme: { colors } }) => css`
    display: none;
    flex-direction: column;
    min-height: 160px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

export const DictionaryManufacturerItem = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: ${space[1]}px;
    color: ${colors.text.primary};

    &:hover {
      color: ${colors.text.primary};
    }
  `,
);

export const DictionaryNoManufacturerButton = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: none;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    padding: 0 9px;
    height: ${space[3]}px;
    border-radius: 8px;
    font-size: ${fontSizes[0]}px;
    font-weight: 700;
    color: ${colors.text.disabled};
    background: ${colors.background};
    text-transform: uppercase;
  `,
);

export const DictionaryCardsWrapper = styled.div<{
  hasManufacturers?: boolean;
}>(
  ({ theme: { space, shadows }, hasManufacturers }) => css`
    display: flex;
    position: relative;
    flex-direction: column;
    min-height: 320px;
    max-height: 320px;
    height: auto;
    padding: ${space[2]}px;
    border-radius: 8px;
    gap: ${space[2]}px;

    &:hover {
      box-shadow: ${shadows[2]};

      ${hasManufacturers &&
      css` {
        ${DictionaryAvatarContainer},
        ${DictionaryStatus} {
          display: none;
        }

        ${DictionaryManufacturersContainer} {
          display: flex;
        }
      `}

      ${DictionaryNoManufacturerButton} {
        display: flex;
      }
    }
  `,
);

export const DictionaryAdaptiveCardsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    padding: 10px;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: ${space[2]}px;
    width: 100%;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
  `,
);

export const DictionaryGridCardsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
    grid-template-rows: repeat(auto-fill, 185px);
    grid-gap: ${space[1]}px;
    margin-top: ${space[3]}px;
  `,
);

export const DictionaryCard = styled(Link)<
  { isShadow?: boolean } & LayoutProps & BackgroundProps
>(
  ({ theme: { colors, shadows, space }, isShadow = false }) => css`
    display: flex;
    justify-content: space-between;
    height: 185px;
    background: ${colors.background};
    border-radius: 8px;

    ${
      isShadow &&
      css`
        padding: ${space[2]}px;

        &:hover {
          box-shadow: ${shadows[1]};
      `
    }
  }

  ${background};
  ${layout}
  `,
);

export const DictionaryCardName = styled.div(
  ({ theme: { colors, fontSizes } }) => css`
    display: -webkit-box;
    font-size: ${fontSizes[4]}px;
    color: ${colors.text.primary};
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  `,
);
