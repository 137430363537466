import { FC, useMemo, useState } from 'react';

import styled, { css } from 'styled-components';
import { BufferGeometry } from 'three';

import { IDocumentFile, IImage } from 'common/types/media.types';
import { GalleryOverlay } from 'components/gallery';
import {
  ListPreview,
  MainPreview,
  SlickList,
} from 'components/gallery/components';
import { Model3DStage } from 'components/media/3d-model';
import { Button } from 'UI';

const StyledButtonOutline = styled(Button).attrs(() => ({
  color: 'secondary',
  variant: 'outlined',
}))(
  ({ theme: { colors, fontSizes }, active }) => css`
    border-radius: 18px;
    background: ${active ? colors.highlight[2] : 'transparent'};
    color: ${active ? colors.text.primary : colors.white};
    font-size: ${fontSizes[3]}px;

    &:hover {
      color: ${active ? colors.text.primary : colors.white};
      border-color: ${colors.divider};
    }

    ${active &&
    css`
      border: none;
    `}
  `,
);

interface IImagesGalleryProps {
  companyName?: string;
  selectedImageIndex: number;
  images: IImage[];
  model3D?: IDocumentFile & { modelGeometry: BufferGeometry };
  isInit3DModelMode?: boolean;
  onRequestClose: VoidFunction;
}

const ImagesGallery: FC<IImagesGalleryProps> = ({
  companyName,
  selectedImageIndex,
  images,
  model3D,
  isInit3DModelMode,
  onRequestClose,
}) => {
  const [selectedIndex, setSelectedIndex] =
    useState<number>(selectedImageIndex);
  const [isInitStage, setInitStage] = useState<boolean>(false);
  const [is3DModelMode, set3DModelMode] = useState<boolean>(
    !!isInit3DModelMode,
  );

  const { modelGeometry, ...modelItem } = model3D ?? {};

  const topAddon = useMemo(() => {
    if (model3D && images.length > 0) {
      return (
        <>
          {!!images.length && (
            <StyledButtonOutline
              active={!is3DModelMode}
              ml={7}
              onClick={() => {
                set3DModelMode(false);
                setSelectedIndex(0);
              }}
            >
              Все фото
            </StyledButtonOutline>
          )}
          <StyledButtonOutline
            active={is3DModelMode}
            ml={images.length ? 0 : 7}
            onClick={() => set3DModelMode(true)}
          >
            3D-модель
          </StyledButtonOutline>
        </>
      );
    }

    return undefined;
  }, [images.length, is3DModelMode, model3D]);

  return (
    <GalleryOverlay topAddon={topAddon} onRequestClose={onRequestClose}>
      {is3DModelMode ? (
        <MainPreview
          companyName={companyName}
          items={[modelItem as IDocumentFile]}
        >
          <Model3DStage modelGeometry={modelGeometry!} />
        </MainPreview>
      ) : (
        <>
          <MainPreview
            companyName={companyName}
            isInitStage={isInitStage}
            items={images}
            selectedItemIndex={selectedIndex}
            onSelect={setSelectedIndex}
          >
            <SlickList
              images={images}
              isInitStage={isInitStage}
              selectedImageIndex={selectedIndex}
              onInitStage={setInitStage}
            />
          </MainPreview>
          <ListPreview
            images={images}
            selectedImageIndex={selectedIndex}
            onSelect={setSelectedIndex}
          />
        </>
      )}
    </GalleryOverlay>
  );
};

export { ImagesGallery };
