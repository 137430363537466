import styled, { css } from 'styled-components';

import { useHint } from 'common/hooks';
import { FCWithBlankChildren } from 'common/types/common.types';
import { checkLocalStorage } from 'common/utils/localStorage';
import { LINK_POLITIKA_KONFIDENTSIALNOSTI } from 'entities/identity/constants';
import { InfoLargeIcon } from 'resources/icons/24';
import { Button } from 'UI';

import { useMainMenu } from '../hooks';

const COOKIES_HINT_KEY = 'cookiesHintKey';

const LayoutContainer = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    height: 100vh;
    background: ${colors.background};
  `,
);

const Container = styled.div(
  ({ theme: { colors, zIndex } }) => css`
    position: fixed;
    z-index: ${zIndex.tooltip};
    bottom: 0;
    width: 100%;
    border-top: 1px solid ${colors.divider};
    background: ${colors.white};
  `,
);

const InfoNoticeCookies = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${space[7]}px;
    min-width: 691px;
  `,
);

const Link = styled.a(
  ({ theme: { colors } }) => css`
    color: ${colors.text.primary};
    text-decoration: underline;
  `,
);

const Layout: FCWithBlankChildren = ({ children }) => {
  const { isViewed, handleToggleViewed } = useHint(COOKIES_HINT_KEY);

  useMainMenu();

  return (
    <LayoutContainer>
      {children}
      <Container>
        {!isViewed && (
          <InfoNoticeCookies>
            <InfoLargeIcon mr={1} />
            <div>
              istock.info{' '}
              <Link
                href={LINK_POLITIKA_KONFIDENTSIALNOSTI}
                rel='noreferrer'
                target='_blank'
              >
                использует cookie
              </Link>{' '}
              для персонализации сервисов и удобства пользователей. <br />
              Вы можете запретить сохранение cookie в настройках своего
              браузера.
            </div>
            {checkLocalStorage() && (
              <Button ml={2} onClick={handleToggleViewed}>
                Согласен
              </Button>
            )}
          </InfoNoticeCookies>
        )}
      </Container>
    </LayoutContainer>
  );
};

export { Layout };
