import { useEffect, useCallback, useContext, FC } from 'react';

import styled, { css } from 'styled-components';

import { useField } from 'reform';
import { replaceItem } from 'tools/utils';

import {
  Input,
  RowContext,
  RowWrapper,
  CellWrapper,
  containerStyle,
  PropertiesTableRowControl,
} from './properties-table';
import { transformValue } from './utils';
import { validateByType } from '../utils';

const DisabledCellWrapper = styled(CellWrapper)(
  ({ theme: { space, colors } }) => css`
    ${containerStyle};

    line-height: 31px;
    padding: 0 ${space[2]}px;
    color: ${colors.text.disabled};
    background-color: ${colors.highlight[0]};
  `,
);

const isRowValid = (row, headers): any => {
  return row.every((value, index) => {
    const { type } = headers[index];

    return validateByType(type, value, true);
  });
};

const PropertiesRow: FC<any> = ({ index: rowIndex, style }) => {
  const {
    fieldName,
    disabled,
    rowsCount,
    headers,
    pushRow,
    removeRow,
    handleValidRows,
  } = useContext(RowContext);

  const { value: row, setValue: setRowValues } = useField({
    name: `${fieldName}.rows.${rowIndex}`,
    transform: value => {
      return value.map(item => (typeof item === 'string' ? item.trim() : item));
    },
  });

  const setRowValue = useCallback(
    (value, index) => {
      setRowValues(prevRow => replaceItem(prevRow, value, index));
    },
    [setRowValues],
  );

  useEffect(() => {
    handleValidRows(isRowValid(row, headers), rowIndex);
  }, [handleValidRows, headers, row, rowIndex]);

  return (
    <RowWrapper style={style}>
      <PropertiesTableRowControl
        disabled={disabled}
        rowIndex={rowIndex}
        rowsCount={rowsCount}
        onPushRow={pushRow}
        onRemoveRow={removeRow}
      />
      {headers.map(({ id, type, width }, index) => {
        const currentValue = row[index];
        const tabIndex = rowIndex + 1 + rowsCount * index;

        if (disabled.all) {
          return (
            <DisabledCellWrapper key={id} width={width}>
              {currentValue}
            </DisabledCellWrapper>
          );
        }

        return (
          <CellWrapper key={id} width={width}>
            <Input
              disabled={disabled.all}
              error={!validateByType(type, currentValue)}
              placeholder='Введите значение'
              tabIndex={tabIndex}
              value={currentValue}
              onChange={({ target }) => {
                setRowValue(
                  transformValue({ nextType: type, value: target.value }),
                  index,
                );
              }}
              onKeyUp={({ keyCode, target }: any) => {
                if (
                  keyCode === 13 &&
                  !(
                    target.value.endsWith('; ') ||
                    target.value.endsWith(';') ||
                    target.value.trim() === ''
                  )
                ) {
                  setRowValue(
                    transformValue({
                      nextType: type,
                      value: `${target.value}; `,
                    }),
                    index,
                  );
                }
              }}
            />
          </CellWrapper>
        );
      })}
    </RowWrapper>
  );
};

export { PropertiesRow, isRowValid };
