import styled, { css } from 'styled-components';

export const CollapsePanel = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    position: relative;
    display: flex;
    align-items: center;
    height: 34px;
    color: ${colors.text.secondary};
    background: ${colors.highlight[1]};
    border: ${borderRadius}px;
  `,
);
