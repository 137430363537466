import { FC, memo } from 'react';

import styled, { css, CSSObject } from 'styled-components';

import { StandardIcon } from 'resources/other-16';
import { IImage, ImagesSections } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { NextMediumIcon, PrevMediumIcon } from 'resources/icons/18';
import { Avatar } from 'UI';

const positionAbsoluteStyle = {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
} as CSSObject;

const ArrowWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: none;
    justify-content: center;
    align-items: center;

    ${positionAbsoluteStyle};

    &::before {
      content: '';
      background: ${colors.text.secondary};
      opacity: 70%;

      ${positionAbsoluteStyle};
    }
  `,
);

const ImageContainer = styled.div`
  position: relative;
  cursor: pointer;

  &:hover ${ArrowWrapper} {
    display: flex;
  }
`;

const StyledStandardIcon = styled(StandardIcon)(
  ({ theme: { space } }) => css`
    position: absolute;
    top: ${space[0]}px;
    right: ${space[0]}px;
  `,
);

interface IImageComponentProps {
  isAddedImage: boolean;
  image: IImage;
  onClick: (isAdded: boolean, image: IImage) => void;
}

const ImageComponent: FC<IImageComponentProps> = memo(
  ({ isAddedImage, image, onClick }) => {
    return (
      <ImageContainer
        key={image.id}
        onClick={() => onClick(isAddedImage, image)}
      >
        <div style={{ opacity: isAddedImage ? '50%' : '100%' }}>
          <Avatar size='s' url={getImageURL(image)} />
        </div>
        {image.section === ImagesSections.standardImages && (
          <StyledStandardIcon />
        )}
        <ArrowWrapper>
          {isAddedImage ? (
            <NextMediumIcon color='white' />
          ) : (
            <PrevMediumIcon color='white' />
          )}
        </ArrowWrapper>
      </ImageContainer>
    );
  },
);

export { ImageComponent };
