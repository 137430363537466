import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  typography,
  TypographyProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

const TitleWrapper = styled.div<
  TypographyProps & ColorProps & { uppercase?: boolean }
>(
  ({ theme: { space, colors }, uppercase }) => css`
    padding: ${space[1]}px;
    margin-bottom: ${space[1]}px;
    color: ${colors.text.secondary};
    background: ${colors.background};
    font-weight: 500;
    text-transform: ${uppercase ? 'uppercase' : 'none'};

    ${typography}
    ${color}
  `,
);
const ControlsGuestWrapper = styled.div<{ width: number } & SpaceProps>(
  ({ theme: { fontSizes, space, colors }, width }) => css`
    width: ${width}px;
    font-size: ${fontSizes[2]}px;
    padding: ${space[1]}px;
    color: ${colors.text.secondary};
    background: ${colors.background};

    ${styledSpace};
  `,
);

export { ControlsGuestWrapper, TitleWrapper };
