import { forwardRef, ReactElement, useMemo } from 'react';

import { addDays } from 'date-fns';
import styled, { css } from 'styled-components';

import { PRODUCTION_STATE_DATE_NAMES } from 'common/constants/products.const';
import { ProductionStates } from 'common/types/product.types';
import { formatISOWithoutTime } from 'common/utils';
import { getProductionStateText } from 'common/utils/products';
import { FieldWrapper } from 'components/fields';
import { getAccessRoles } from 'entities/identity';
import { useField } from 'reform';
import { TrashIcon } from 'resources/icons/18';
import { useAppSelector } from 'store';
import { IconButton, Select, DateInput, Container } from 'UI';

const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: ${space[2]}px;
    flex-grow: 1;
  `,
);

const STATUS_OPTIONS = [
  ProductionStates.inProduction,
  ProductionStates.announced,
  ProductionStates.discontinued,
];

const PARTIAL_STATUS_OPTIONS = [
  ProductionStates.inProduction,
  ProductionStates.discontinued,
];

const getProductionErrorText = (error: any): string | undefined => {
  if (typeof error === 'string') {
    return error;
  }

  return undefined;
};

interface IProductionStateFieldProps {
  tip?: JSX.Element;
  options?: string[];
  remove?: boolean | ((name: string) => void);
}

const ProductionStateField = forwardRef<
  HTMLDivElement,
  IProductionStateFieldProps
>(({ tip, options = STATUS_OPTIONS, remove }, ref): ReactElement => {
  const {
    form: {
      initialValues: {
        _isPartialEditing: isPartialEditing,
        productionDate: initialProductionDate,
      },
    },
    value: productionState,
    error: productionStateError,
    setValue: setProductionState,
  } = useField({
    name: 'productionState',
  });

  const {
    ref: productionDateRef,
    value: productionDate,
    setValue: setProductionDate,
    error,
  } = useField({
    name: 'productionDate',
    transform: date => (date ? formatISOWithoutTime(date) : null),
  });

  const hasRemove = Boolean(remove);
  const { moderator } = useAppSelector(getAccessRoles);

  const [disabledPrevDates, disabledNextDates] = useMemo(() => {
    let prevDate;
    let nextDate;

    // for prevDate conditions
    if (productionState === ProductionStates.announced) {
      prevDate = new Date();
    } else if (
      isPartialEditing &&
      productionState === ProductionStates.inProduction
    ) {
      prevDate = addDays(new Date(initialProductionDate), -1);
    }

    // for nextDate conditions
    if (isPartialEditing && productionState === ProductionStates.discontinued) {
      nextDate = new Date();
    } else if (
      !isPartialEditing &&
      (productionState === ProductionStates.inProduction ||
        productionState === ProductionStates.discontinued)
    ) {
      nextDate = addDays(new Date(), 1);
    }

    if (prevDate instanceof Date) {
      prevDate.setHours(0, 0, 0, 0);
    }

    if (nextDate instanceof Date) {
      nextDate.setHours(0, 0, 0, 0);
    }

    return [prevDate, nextDate];
  }, [initialProductionDate, isPartialEditing, productionState]);

  const handleRemove = (): void => {
    setProductionState('');
    setProductionDate(null);

    if (typeof remove === 'function') {
      remove('productionState');
    }
  };

  const handleChangeProductionState = (value): void => {
    setProductionState(value ?? '');
    setProductionDate(null);
  };

  return (
    <Container ref={ref} justifyContent='space-between'>
      <Wrapper>
        <FieldWrapper
          injectedProps
          showErrorDescription
          disabled={moderator}
          error={getProductionErrorText(productionStateError)}
          label='Статус производства'
          tip={tip}
        >
          <Select
            canClear
            itemToString={getProductionStateText}
            options={isPartialEditing ? PARTIAL_STATUS_OPTIONS : options}
            value={productionState}
            onChange={handleChangeProductionState}
          />
        </FieldWrapper>
        {productionState && (
          <DateInput
            ref={productionDateRef}
            canClear
            error={error}
            label={PRODUCTION_STATE_DATE_NAMES[productionState]}
            nextDisabledDate={disabledNextDates}
            prevDisabledDate={disabledPrevDates}
            readonly={moderator}
            value={productionDate}
            onChange={setProductionDate}
          />
        )}
      </Wrapper>
      {hasRemove && (
        <IconButton ml={0} mt='25px' size={34} onClick={handleRemove}>
          <TrashIcon />
        </IconButton>
      )}
    </Container>
  );
});

export { ProductionStateField };
