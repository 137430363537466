import { FC, useState } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CURRENCY_ISO_SHORT_TEXT } from 'common/constants/currency.const';
import { useUnit } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { BackendTypeEnum } from 'common/types/request.types';
import { IWorkBackend } from 'common/types/works.types';
import { getFormattedNumber, getFileURL, getImageURL } from 'common/utils';
import { ImageSideBarAddon } from 'components/ImageSideBarAddon';
import { BaseImageBackground } from 'components/media/styled';
import { hasIdentity } from 'entities/identity';
import { COMPANY, getPath } from 'routes';
import {
  Drawer,
  Text,
  DescriptionList,
  Container,
  FileLoaded,
  Avatar,
} from 'UI';
import {
  DrawerContentWrapper,
  DrawerGroupBlock,
  DrawerTextBlock,
} from 'UI/Drawer/components';

const SIDEBAR_WIDTH = 700;

const WORK_SIDE_PANEL_MODAL = 'workSidePanelModal';

const ImagesWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(5, 100px);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: ${space[2]}px;
  `,
);

const ImageBackground = styled(BaseImageBackground)`
  background-size: contain;
  cursor: pointer;
`;

interface IWorkSidePanelModalProps {
  work: IWorkBackend<BackendTypeEnum.response>;
  workObjectName: string;
  handleModalClose: VoidFunction;
}

const WorkSidePanelModal: FC<IWorkSidePanelModalProps> & { TYPE: string } = ({
  work,
  workObjectName,
  handleModalClose,
}) => {
  const {
    name,
    category,
    speciality,
    price,
    priceComment,
    laborEffort,
    description,
    media,
  } = work;
  const { unitToString } = useUnit();
  const isAuthed = useSelector(hasIdentity);
  const [selectedImage, setSelectedImage] = useState<Nullable<IImage>>(null);

  const { images, documents } = media ?? {};
  const { textDescription, regulations } = description ?? {};

  return (
    <Drawer
      handleModalClose={handleModalClose}
      leftAddon={
        selectedImage && (
          <ImageSideBarAddon
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            sidebarWidth={SIDEBAR_WIDTH}
          />
        )
      }
      width={SIDEBAR_WIDTH}
    >
      <Drawer.Header>
        <Container column fontWeight={500} width='100%'>
          <Text truncate fontSize={7} mb={1}>
            {name}
          </Text>
          <Text truncate color='text.secondary'>
            {workObjectName}
          </Text>
        </Container>
      </Drawer.Header>
      <DrawerContentWrapper fontWeight={500}>
        <DrawerGroupBlock title='Общие сведения'>
          <DescriptionList dotted>
            <DescriptionList.Property label='Категория' mb={2}>
              {category}
            </DescriptionList.Property>
            {speciality && (
              <DescriptionList.Property label='Исполнитель' mb={2}>
                {speciality}
              </DescriptionList.Property>
            )}
            {laborEffort && (
              <DescriptionList.Property
                label={`Трудозатраты, ${unitToString(laborEffort.unit)}`}
                mb={2}
              >
                {laborEffort.value}
              </DescriptionList.Property>
            )}
            <DescriptionList.Property
              label={
                price?.currency
                  ? `Цена, ${CURRENCY_ISO_SHORT_TEXT[price.currency]}`
                  : 'Цена'
              }
              mb={2}
            >
              {getFormattedNumber(price?.amount)}
            </DescriptionList.Property>
            {priceComment && (
              <DescriptionList.Property label='Комментарий к цене' mb={2}>
                {priceComment}
              </DescriptionList.Property>
            )}
          </DescriptionList>
        </DrawerGroupBlock>
        {regulations && (
          <DrawerGroupBlock title='Регламенты'>
            <Container column gap={2}>
              {regulations.items.map(item => (
                <Container key={item.id} alignItems='center'>
                  <Avatar mr={2} size='xs' url={getFileURL(item.image)} />
                  <Text
                    truncate
                    as={Link}
                    color='text.primary'
                    target='_blank'
                    to={getPath(COMPANY.ARTICLE_PAGE, { id: item.id })}
                  >
                    {item.title}
                  </Text>
                </Container>
              ))}
            </Container>
          </DrawerGroupBlock>
        )}
        {textDescription && (
          <DrawerGroupBlock title='Текстовое описание'>
            <DrawerTextBlock>{textDescription}</DrawerTextBlock>
          </DrawerGroupBlock>
        )}
        {!!images?.items.length && (
          <DrawerGroupBlock title='Фото'>
            <ImagesWrapper>
              {images!.items.map(image => (
                <ImageBackground
                  key={image.id}
                  url={getImageURL(image, { size: 'l' })}
                  onClick={() => {
                    setSelectedImage(image);
                  }}
                />
              ))}
            </ImagesWrapper>
          </DrawerGroupBlock>
        )}
        {!!documents?.items.length && (
          <DrawerGroupBlock title='Документы'>
            {documents!.items.map(document => (
              <FileLoaded
                key={document.id}
                hovered
                download={isAuthed}
                file={document}
                mb={1}
              />
            ))}
          </DrawerGroupBlock>
        )}
      </DrawerContentWrapper>
    </Drawer>
  );
};

WorkSidePanelModal.TYPE = WORK_SIDE_PANEL_MODAL;

export { WorkSidePanelModal };
