import { useState } from 'react';

import styled, { css } from 'styled-components';

import { useRequest } from 'common/hooks';
import { copyToClipboard } from 'common/utils';
import { CopyIcon } from 'resources/icons/18';
import { LinkIcon } from 'resources/modal';
import { IDENTITY } from 'routes';
import { IconButton, Modal, Text } from 'UI';
import { InputContainer } from 'UI/Inputs/components';

import { createCompanyInvitations } from '../../api';

const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: right;
    height: 15px;
    margin-top: ${space[0]}px;
    margin-bottom: 9px;
  `,
);

function EmployeesInviteModal(): JSX.Element {
  const [isCopySuccess, setCopySuccess] = useState(false);

  const { isLoading, data } = useRequest(createCompanyInvitations, []);

  const inviteLink = `${window.origin}${IDENTITY.REGISTRATION_EMPLOYEE_BY_GENERAL_LINK}?invitationToken=${data?.invitationToken}`;

  const handleCopy = (): void => {
    copyToClipboard(inviteLink).then(() => {
      setCopySuccess(true);
    });
  };

  return (
    <Modal>
      <Modal.Default
        hideButtons
        icon={<LinkIcon />}
        title='Пригласительная ссылка'
      >
        <InputContainer disabled>
          <Text truncate color='text.primary' px={2}>
            {isLoading ? 'Генерация ссылки...' : inviteLink}
          </Text>
          <IconButton
            disabled={isLoading}
            ml='auto'
            mr='2px'
            size={28}
            title='Скопировать'
            variant='outline'
            onClick={handleCopy}
          >
            <CopyIcon />
          </IconButton>
        </InputContainer>
        <Wrapper>
          {isCopySuccess && (
            <Text color='primary.dark' fontSize={1}>
              Ссылка скопирована
            </Text>
          )}
        </Wrapper>
        <Text fontSize={3}>
          Скопируйте ссылку и разошлите своим сотрудникам. После регистрации
          сотрудники автоматически будут прикреплены
          <br /> к компании и получат уровень доступа «Только просмотр».
        </Text>
        <Text fontSize={3} fontWeight={600} mb={4} mt={2}>
          Пригласительная ссылка действительна 5 дней.
        </Text>
      </Modal.Default>
    </Modal>
  );
}

export { EmployeesInviteModal };
