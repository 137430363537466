import { ReactElement } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { DEFAULT_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { TASK_STATUSES_TEXT } from 'common/constants/task.const';
import { TaskStatuses } from 'common/types/product.types';
import { getFullName, getImageURL, formatDate } from 'common/utils';
import { BillingIcon01Icon } from 'resources/icons/24';
import { Badge, Avatar, Text } from 'UI';

const IconWrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${space[4]}px;
    height: ${space[4]}px;
    border-radius: 50%;
    border: 1px solid ${colors.divider};
  `,
);

const AboutTemplateContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[1]}px;

    ${styledSpace}
  `,
);

function AboutTemplate({
  roundIcon,
  title,
  description,
  ...other
}): ReactElement {
  return (
    <AboutTemplateContainer {...other}>
      {roundIcon}
      <div style={{ minWidth: 0 }}>
        {title}
        {description}
      </div>
    </AboutTemplateContainer>
  );
}

function UserAvatar({
  style,
  user,
  description,
  avatarSize = 'xxs',
  ...other
}: {
  style?: any;
  user?: any;
  description?: string;
  avatarSize?: string;
} & SpaceProps): ReactElement | null {
  const userName = user ? getFullName(user) : null;

  return (
    <AboutTemplate
      description={
        description && <Text color='text.secondary'>{description}</Text>
      }
      roundIcon={
        <Avatar
          rounded
          letter={userName}
          size={avatarSize}
          url={getImageURL(user?.avatarImage)}
        />
      }
      style={style}
      title={
        userName ? (
          <Text truncate>{userName}</Text>
        ) : (
          <Text truncate color='text.disabled'>
            Не назначен
          </Text>
        )
      }
      {...other}
    />
  );
}

function DueDate({ date }): ReactElement {
  return (
    <AboutTemplate
      description={<Text color='text.secondary'>Срок исполнения</Text>}
      roundIcon={
        <IconWrapper>
          <BillingIcon01Icon color='text.secondary' />
        </IconWrapper>
      }
      title={
        formatDate(date, false, DEFAULT_DATE_PATTERN) || (
          <Text color='text.disabled'>Не назначен</Text>
        )
      }
    />
  );
}

function TaskStatusBadge({
  hasTooltip,
  status,
  executor,
  dueDate,
}: {
  hasTooltip?: boolean;
  status: string | undefined;
  executor?: object;
  dueDate?: string;
}): ReactElement | null {
  if (!status) {
    return <Text color='text.disabled'>Не указан</Text>;
  }

  const { text, color } = TASK_STATUSES_TEXT[status] ?? {};

  const title = hasTooltip
    ? {
        arrow: true,
        placement: 'bottom',
        title: (
          <div style={{ width: '195px' }}>
            <UserAvatar
              avatarSize='xs'
              description='Исполнитель'
              mb={1}
              user={executor}
            />
            <DueDate date={dueDate} />
          </div>
        ),
      }
    : null;

  return (
    <Badge
      bg='transparent'
      color={status === TaskStatuses.done && 'success.main'}
      dot={color}
      label={text}
      title={title}
      type={Badge.TYPES.DEFAULT}
    />
  );
}

export { TaskStatusBadge, UserAvatar };
