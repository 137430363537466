import styled, { css } from 'styled-components';
import { color as styledColor, ColorProps } from 'styled-system';

import { OptionWrapper } from 'UI/Inputs';

export const Limit = styled.div<{ isDisabled?: boolean }>(
  ({ theme: { fontSizes, space, colors }, isDisabled }) => css`
    margin-top: ${space[1]}px;
    margin-right: ${space[1]}px;
    width: 20px;
    align-self: flex-start;
    font-size: ${fontSizes[2]}px;

    ${isDisabled &&
    css`
      color: ${colors.text.disabled};
    `}
  `,
);

export const ColorPreview = styled.div<ColorProps>(
  ({ theme: { colors, space } }) => css`
    width: ${space[4]}px;
    min-width: ${space[4]}px;
    height: ${space[4]}px;
    border: 1px solid ${colors.background};

    ${styledColor};
  `,
);

export const ColorPreviewMini = styled(ColorPreview)(
  ({ theme: { space, borderRadius } }) => css`
    width: 14px;
    min-width: 14px;
    height: 14px;
    margin-right: ${space[0]}px;
    border-radius: ${borderRadius}px;
  `,
);

export const ValuesWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    padding: ${space[0]}px 0 ${space[0]}px ${space[2]}px;
  `,
);

export const GroupHeader = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    height: ${space[4]}px;
    margin-bottom: 1px;
    padding: 0 ${space[2]}px;
    color: ${colors.text.secondary};
    background: ${colors.highlight[0]};
    font-size: ${fontSizes[3]}px;
    cursor: pointer;
    user-select: none;
  `,
);

export const StyledOptionWrapper = styled(OptionWrapper)(
  ({ theme: { colors }, disabled }) => css`
    cursor: ${!disabled ? 'pointer' : 'default'};

    ${!disabled &&
    `&:hover {
          background: ${colors.highlight[0]};
      }
    `};
  `,
);
