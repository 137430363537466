import { memo, FC } from 'react';

import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { ICompany } from 'common/types/company.types';
import { getFormattedNumber, getImageURL } from 'common/utils';
import { CompanyTitle } from 'components/company';
import { getPath, COMPANY } from 'routes';
import { itemTo } from 'tools/utils';
import { Container, Text, Tags, Tag, Avatar, Tooltip } from 'UI';

import {
  CardWrapper,
  StyledStatus,
  BodyContainer,
  InfoItemTitle,
} from './styled';

const TAGS = [
  { statName: 'productsCount', text: 'Товары', bg: 'highlight.gray' },
  {
    statName: 'questionnairesCount',
    text: 'Заказное производство',
    bg: 'success.light',
  },
  { statName: 'manufacturedNTDsCount', text: 'НТД', bg: 'highlight.1' },
  { statName: 'pointOfSalesCount', text: 'Места продаж', bg: 'warning.light' },
  {
    statName: 'servicesCount',
    text: 'Сервисы',
    // TODO поправить цвет или вынести в тему
    bg: 'rgba(255, 208, 201, 0.5)',
  },
];

const StatTag: FC<{ text: string; bg: string; count: number }> = memo(
  ({ text, bg, count }) => {
    if (!count || count === 0) return null;

    return (
      <Tag
        arrow
        bg={bg}
        mb={0}
        mr={0}
        value={
          <Container>
            <Text fontWeight={600} mr={0}>
              {text}
            </Text>
            <Text>{getFormattedNumber(count)}</Text>
          </Container>
        }
      />
    );
  },
);

const CompanyCard: FC<{ company: ICompany }> = memo(
  ({
    company: {
      isPartner,
      id,
      name,
      logo,
      stats,
      legalAccount,
      activityAreas,
      mainDepartment,
    },
  }) => (
    <CardWrapper>
      <BodyContainer>
        <Container alignItems='flex-start' display='flex' mb={2}>
          <Avatar
            as={Link}
            letter={name}
            mr={2}
            size='l'
            to={getPath(COMPANY.CARD, { id })}
            url={getImageURL(logo)}
          />
          <div>
            <Tooltip arrow hint title={name}>
              <CompanyTitle
                truncate
                as={Link}
                mb={1}
                to={getPath(COMPANY.CARD, { id })}
              >
                {name}
              </CompanyTitle>
            </Tooltip>
            <Text color='text.secondary' fontSize={2}>
              ИНН{' '}
              <Text as='span' color='text.primary' fontSize={2}>
                {legalAccount?.inn}
              </Text>
            </Text>
          </div>
          {isPartner && (
            <StyledStatus ml={2} size='14' title='Мой партнер' type='success' />
          )}
        </Container>
        <Container display='block' mb={2}>
          <InfoItemTitle>Сферы деятельности</InfoItemTitle>
          <Tags
            arrow
            expandable
            fontSize={3}
            itemToString={itemTo('name')}
            limit={2}
            tags={activityAreas}
          />
        </Container>
        <Container display='block'>
          <InfoItemTitle>Местоположение</InfoItemTitle>
          <Text mt='0px'>{mainDepartment?.location?.address}</Text>
        </Container>
      </BodyContainer>
      <Container flexWrap='wrap' margin='0 auto' width='85%'>
        {!isEmpty(stats) &&
          TAGS.map(({ statName, text, bg }) => (
            <StatTag
              key={statName}
              bg={bg}
              count={stats[statName]}
              text={text}
            />
          ))}
      </Container>
    </CardWrapper>
  ),
);

export { CompanyCard };
