import { Fragment, useMemo, memo, FC } from 'react';

import styled, { css } from 'styled-components';

import {
  PRODUCTION_STATE_NAMES,
  KIND_PRODUCT_OPTIONS_BY_TEXT,
} from 'common/constants/products.const';
import { IProduct } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { useReform } from 'reform';
import { Avatar } from 'UI';

import {
  Tags,
  Classifiers,
  Collections,
  VendorCodes,
  ProductionDate,
  SimpleParameters,
  GeneralParametersFooter,
} from './components';
import { PARAMETER_ICON, PARAMETER_TEXT } from './constants';
import { Parameters } from './types';

const ParamsViewWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space[2]}px;
    height: 100%;
    overflow-y: auto;
    padding: ${space[2]}px ${space[4]}px ${space[4]}px;
  `,
);

interface IGeneralParametersProps {
  isPartialEditing: boolean;
  selectedProduct: IProduct;
}

const GeneralParameters: FC<IGeneralParametersProps> = memo(
  ({ isPartialEditing, selectedProduct }) => {
    const {
      initialValues: { vendorCodes, classifiers },
    } = useReform();

    const initialVendorCodes = useMemo(
      () => new Set<string>(vendorCodes.map(({ code }) => code)),
      [vendorCodes],
    );
    const initialClassifiers = useMemo(
      () => new Set<string>(classifiers.map(({ type }) => type)),
      [classifiers],
    );

    return (
      <Fragment>
        <ParamsViewWrapper>
          <SimpleParameters
            basicValue={null}
            icon={PARAMETER_ICON[Parameters.previewImage]}
            label={PARAMETER_TEXT[Parameters.previewImage]}
            parameter={Parameters.previewImage}
            selectedProduct={selectedProduct}
            value={
              <Avatar
                size='xs'
                url={getImageURL(selectedProduct.previewImage)}
              />
            }
          />
          <SimpleParameters
            basicValue=''
            icon={PARAMETER_ICON[Parameters.name]}
            isPartialEditing={isPartialEditing}
            label={PARAMETER_TEXT[Parameters.name]}
            parameter={Parameters.name}
            selectedProduct={selectedProduct}
          />
          <SimpleParameters
            basicValue=''
            icon={PARAMETER_ICON[Parameters.kind]}
            isPartialEditing={isPartialEditing}
            label={PARAMETER_TEXT[Parameters.kind]}
            parameter={Parameters.kind}
            selectedProduct={selectedProduct}
            value={KIND_PRODUCT_OPTIONS_BY_TEXT[selectedProduct.kind]}
          />
          <SimpleParameters
            basicValue=''
            icon={PARAMETER_ICON[Parameters.type]}
            isPartialEditing={isPartialEditing}
            label={PARAMETER_TEXT[Parameters.type]}
            parameter={Parameters.type}
            selectedProduct={selectedProduct}
          />
          <SimpleParameters
            basicValue=''
            icon={PARAMETER_ICON[Parameters.productionState]}
            label={PARAMETER_TEXT[Parameters.productionState]}
            parameter={Parameters.productionState}
            selectedProduct={selectedProduct}
            value={PRODUCTION_STATE_NAMES[selectedProduct.productionState]}
          />
          <ProductionDate productionDate={selectedProduct.productionDate} />
          <Tags tags={selectedProduct.tags} />
          <VendorCodes
            initialVendorCodes={initialVendorCodes}
            isPartialEditing={isPartialEditing}
            vendorCodes={selectedProduct.vendorCodes}
          />
          <Classifiers
            classifiers={selectedProduct.classifiers}
            initialClassifiers={initialClassifiers}
            isPartialEditing={isPartialEditing}
          />
          <Collections collections={selectedProduct.collections} />
        </ParamsViewWrapper>
        <GeneralParametersFooter
          initialClassifiers={initialClassifiers}
          initialVendorCodes={initialVendorCodes}
          isPartialEditing={isPartialEditing}
          selectedProduct={selectedProduct}
        />
      </Fragment>
    );
  },
);

export { GeneralParameters };
