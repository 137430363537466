import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import {
  SERVICE_PANEL_WIDTH,
  SERVICE_PANEL_ANIMATION_DURATION,
} from 'common/constants/service-panel.const';

import { HEADER_HEIGHT, SUPERUSER_HEADER_HEIGHT } from './constants';

export const PanelWrapper = styled.div<{ isCollapsed: boolean }>(
  ({ isCollapsed }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 ${isCollapsed ? 0 : SERVICE_PANEL_WIDTH}px;
    height: 100%;
    min-width: 0;
    transition: all ${SERVICE_PANEL_ANIMATION_DURATION} ease-in-out;
  `,
);

export const PanelContent = styled.div<{ isImpersonated: boolean }>(
  ({ theme: { colors, zIndex }, isImpersonated }) => css`
    position: absolute;
    top: -${HEADER_HEIGHT};
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: ${SERVICE_PANEL_WIDTH}px;
    height: calc(100vh - ${isImpersonated ? SUPERUSER_HEADER_HEIGHT : 0}px);
    background: ${colors.background};
    border-left: 1px solid ${colors.divider};
    z-index: ${zIndex.sidebar - 10};
  `,
);

export const ScrollButton = styled.div<{ isDisabled: boolean } & SpaceProps>(
  ({ theme: { colors }, isDisabled }) => css`
    display: flex;
    justify-content: center;
    width: 34px;
    height: 13px;
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    border-radius: 12px;
    opacity: ${isDisabled ? 0.5 : 1};
    cursor: ${isDisabled ? 'default' : 'pointer'};

    ${styledSpace}
  `,
);

export const AddButton = styled.div(
  ({ theme: { colors, borderRadius, shadows } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    background: ${colors.white};
    border-radius: ${borderRadius}px;
    box-shadow: ${shadows[3]};
  `,
);
