import { FC, Fragment } from 'react';

import styled, { css } from 'styled-components';

import { Button } from 'UI';

const Placeholder = styled.div<{ overlay: boolean }>(
  ({ theme: { colors, fontSizes }, overlay }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 128px;
    border: 1px dashed ${colors.divider};
    background-color: ${colors.background};
    font-size: ${fontSizes[5]}px;
    white-space: pre-wrap;
    z-index: 1;

    ${overlay &&
    css`
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    `}
  `,
);

const FILE_TYPES_NAMES = {
  document: 'файлы',
  image: 'фото',
};

interface IDropPlaceholderProps {
  overlay?: boolean;
  disabled?: boolean;
  type: 'document' | 'image';
  onOpen?: VoidFunction;
}

const DropPlaceholder: FC<IDropPlaceholderProps> = ({
  overlay,
  type = 'document',
  disabled,
  onOpen,
}) => {
  const fileType = FILE_TYPES_NAMES[type];

  return (
    <Placeholder overlay={!!overlay}>
      {overlay ? (
        `Перетащите ${fileType} сюда. Загрузка начнется автоматически.`
      ) : (
        <Fragment>
          <span>Перетащите {fileType} сюда или </span>
          <Button
            disabled={disabled}
            fontSize='inherit'
            variant='string'
            onClick={onOpen}
          >
            выберите их на своем компьютере
          </Button>
        </Fragment>
      )}
    </Placeholder>
  );
};

export { DropPlaceholder };
