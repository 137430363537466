import { Fragment, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { PROPERTY_ICON } from 'common/constants/properties.const';
import { IProperty, IPropertyGroup } from 'common/types/product.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { ProductPropertyLink } from 'components/products/properties/page';
import { renderProperty } from 'components/products/properties/utils';
import { ArrowDown1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';
import { Checkbox, Text, Tooltip, Modal } from 'UI';

import { PROPERTIES_TYPES_NAMES } from '../../../../../../../constants';

const GroupContainer = styled(Modal.ListItem)<{ level: number }>(
  ({ theme: { space }, level }) => css`
    display: flex;
    align-items: center;
    height: ${space[4]}px;
    padding-right: ${space[1]}px;
    padding-left: ${(level + 1) * space[2] - space[1]}px;
    cursor: pointer;
  `,
);

const PropertyWrapper = styled.div(
  ({ theme: { space } }) => css`
    max-width: 649px;
    max-height: 562px;
    padding: ${space[2]}px;
    overflow-y: auto;
  `,
);

const PropertyContainer = styled(Modal.ListItem)<{ level: number }>(
  ({ theme: { space }, level }) => css`
    padding-left: ${(level + 1) * space[2]}px;
  `,
);

const TextBlockWrapper = styled.div(
  ({ theme: { space } }) => css`
    min-width: 0;
    margin-right: ${space[2]}px;
  `,
);

const ToggleWrapper = styled.div(
  ({ theme: { space } }) => css`
    padding: ${space[1]}px;
  `,
);

const getChildrenIdsByNodeIdMap = (
  properties: IProperty[],
  nodeId = '_root',
  childrenIdsByNodeId = new Map<string, string[]>(),
): Map<string, string[]> => {
  const childrenIds: string[] = [];

  properties.forEach(property => {
    if (property.type === PropertiesTypes.group) {
      getChildrenIdsByNodeIdMap(
        property.value,
        property.id,
        childrenIdsByNodeId,
      );

      const ids = childrenIdsByNodeId.get(property.id);

      if (ids) childrenIds.push(...ids);
    } else childrenIds.push(property.id);
  });

  childrenIdsByNodeId.set(nodeId, childrenIds);
  return childrenIdsByNodeId;
};

interface IGroupProps {
  name: string;
  property: IPropertyGroup;
  level: number;
  children: JSX.Element[];
  childrenIds: Map<string, string[]>;
  visibleProperties: string[];
  setFieldValue: (name: string, properties: string[]) => void;
}

function Group({
  name,
  property: { name: groupName, id },
  level,
  children,
  childrenIds,
  visibleProperties,
  setFieldValue,
}: IGroupProps): JSX.Element {
  const [toggleGroup, setToggleGrout]: [boolean, () => void] = useToggle(true);

  const childProperties = childrenIds.get(id);
  const selectedChildProperties = visibleProperties.filter(e =>
    childProperties!.includes(e),
  );

  const isIndeterminate =
    selectedChildProperties.length > 0 &&
    selectedChildProperties.length < childProperties!.length;

  return (
    <Fragment>
      <GroupContainer
        level={level}
        selected={selectedChildProperties.length === childProperties!.length}
      >
        {toggleGroup ? (
          <ToggleWrapper
            onClick={e => {
              e.preventDefault();
              setToggleGrout();
            }}
          >
            <ArrowDown1pxIcon size={8} />
          </ToggleWrapper>
        ) : (
          <ToggleWrapper
            onClick={e => {
              e.preventDefault();
              setToggleGrout();
            }}
          >
            <ArrowRight1pxIcon size={8} />
          </ToggleWrapper>
        )}
        <Text truncate color='text.secondary'>
          {groupName}
        </Text>
        <Checkbox
          indeterminate={isIndeterminate}
          ml='auto'
          value={selectedChildProperties.length === childProperties!.length}
          onChange={() =>
            setFieldValue(
              name,
              selectedChildProperties.length > 0
                ? visibleProperties.filter(e => !childProperties!.includes(e))
                : visibleProperties.concat(childProperties!),
            )
          }
        />
      </GroupContainer>
      {toggleGroup && children}
    </Fragment>
  );
}

interface ILeafProps {
  name: string;
  property: IProperty;
  level?: number;
  visibleProperties: string[];
  setFieldValue: (name: string, properties: string[]) => void;
}

function Leaf({
  name,
  property,
  level,
  visibleProperties,
  setFieldValue,
}: ILeafProps): JSX.Element {
  const IconByType = PROPERTY_ICON[property.type];
  const isSelected = visibleProperties.includes(property.id);

  const handleSelect = (): void =>
    setFieldValue(
      name,
      isSelected
        ? visibleProperties.filter(id => id !== property.id)
        : [...visibleProperties, property.id],
    );

  return (
    <PropertyContainer level={level} selected={isSelected}>
      <TextBlockWrapper>
        <Text
          truncate
          color='text.secondary'
          fontSize={2}
          fontWeight={600}
          mb='2px'
        >
          {property.name}
        </Text>
        <Text truncate={property.type !== 'multiColor'}>
          {renderProperty({
            property,
            propertyTypeRender: prop =>
              prop.type === PropertiesTypes.product ? (
                // @ts-ignore
                <ProductPropertyLink property={prop} />
              ) : null,
          })}
        </Text>
      </TextBlockWrapper>
      <Tooltip arrow hint title={PROPERTIES_TYPES_NAMES[property.type]}>
        {/* @ts-ignore */}
        <IconByType color='text.disabled' ml='auto' mr={2} size={16} />
      </Tooltip>
      <Checkbox value={isSelected} onChange={handleSelect} />
    </PropertyContainer>
  );
}

interface IPropertyProps {
  name: string;
  property: IProperty;
  level?: number;
  childrenIds: Map<string, string[]>;
  visibleProperties: string[];
  setFieldValue: (name: string, properties: string[]) => void;
}

function Property({
  name,
  property,
  level = 0,
  childrenIds,
  visibleProperties,
  setFieldValue,
}: IPropertyProps): JSX.Element {
  const isGroup = property.type === PropertiesTypes.group;

  return isGroup ? (
    <Group
      childrenIds={childrenIds}
      level={level}
      name={name}
      property={property}
      setFieldValue={setFieldValue}
      visibleProperties={visibleProperties}
    >
      {property.value.map(groupProperty => (
        <Property
          key={groupProperty.id}
          childrenIds={childrenIds}
          level={level + 1}
          name={name}
          property={groupProperty}
          setFieldValue={setFieldValue}
          visibleProperties={visibleProperties}
        />
      ))}
    </Group>
  ) : (
    <Leaf
      level={level}
      name={name}
      property={property}
      setFieldValue={setFieldValue}
      visibleProperties={visibleProperties}
    />
  );
}

interface ILinkedProductPropertyProps {
  name: string;
  properties: IProperty[];
  visibleProperties: string[];
  setFieldValue: (name: string, properties: string[]) => void;
}

function LinkedProductProperty({
  name,
  properties,
  visibleProperties,
  setFieldValue,
}: ILinkedProductPropertyProps): JSX.Element | null {
  const childrenIds = useMemo(
    () => getChildrenIdsByNodeIdMap(properties),
    [properties],
  );

  if (properties.length < 1) return null;

  const allProperties = childrenIds.get('_root');

  const isIndeterminate =
    visibleProperties.length > 0 &&
    visibleProperties.length < allProperties!.length;

  return (
    <PropertyWrapper>
      <Modal.ListItem mb={1}>
        <Text fontWeight={500}>Все</Text>
        <Checkbox
          indeterminate={isIndeterminate}
          ml='auto'
          value={visibleProperties.length === allProperties!.length}
          onChange={() => {
            setFieldValue(
              name,
              visibleProperties.length > 0 ? [] : allProperties!,
            );
          }}
        />
      </Modal.ListItem>
      {properties.map(property => (
        <Property
          key={property.id}
          childrenIds={childrenIds}
          name={name}
          property={property}
          setFieldValue={setFieldValue}
          visibleProperties={visibleProperties}
        />
      ))}
    </PropertyWrapper>
  );
}

export { LinkedProductProperty };
