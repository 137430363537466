import { ChangeEvent, CSSProperties, FC, memo } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { ViewTypesBlock } from 'common/types/common.types';
import { ViewListIcon, ViewMapIcon } from 'resources/icons/18';
import { IconButton } from 'UI';

const ViewControlsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: ${space[0]}px;

    ${styledSpace}
  `,
);

const ViewControlButton = styled(IconButton)(
  ({ theme: { colors }, checked }) => css`
    ${checked &&
    css`
      background: ${colors.highlight[2]};
      border: 0;

      & svg {
        color: ${colors.text.primary};
      }
    `}
  `,
);

interface IViewTypeControlsProps {
  style?: CSSProperties;
  value: ViewTypesBlock;
  onChange: ({
    currentTarget: { dataset },
  }: ChangeEvent<HTMLButtonElement>) => void;
}

const ViewTypeControls: FC<IViewTypeControlsProps & SpaceProps> = memo(
  ({ style, value, onChange, ...other }) => (
    <ViewControlsWrapper style={style} {...other}>
      <ViewControlButton
        frame
        rounded
        checked={value === ViewTypesBlock.list}
        data-view={ViewTypesBlock.list}
        title='Показать в виде списка'
        onClick={onChange}
      >
        <ViewListIcon />
      </ViewControlButton>
      <ViewControlButton
        frame
        rounded
        checked={value === ViewTypesBlock.map}
        data-view={ViewTypesBlock.map}
        title='Показать на карте'
        onClick={onChange}
      >
        <ViewMapIcon />
      </ViewControlButton>
    </ViewControlsWrapper>
  ),
);

export { ViewTypeControls, ViewControlsWrapper, ViewControlButton };
