import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  SpaceProps,
  width,
  WidthProps,
} from 'styled-system';

const BaseInput = styled.input<SpaceProps & WidthProps>(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    flex: 1;
    align-self: stretch;
    min-width: 0;
    line-height: 100%;
    padding: 0 ${space[2]}px;
    color: inherit;
    background: none;
    text-align: left;
    text-overflow: ellipsis;
    outline: none;

    &::placeholder {
      color: ${colors.text.disabled};
    }

    ${styledSpace}
    ${width}
  `,
);

export { BaseInput };
