import { Dispatch, FC, ReactNode, SetStateAction } from 'react';

import styled, { css } from 'styled-components';

import { IDocumentFile, IImage } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { ArrowLeft1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { UploadIcon } from 'resources/other-16';
import { STUB_FUNC } from 'tools/utils';
import { Container, IconButtonWrapper, Text } from 'UI';

const NavButton = styled.button.attrs(() => ({ type: 'button' }))(
  ({ theme: { space, colors } }) => css`
    height: 100%;
    color: ${colors.text.secondary};
    cursor: pointer;
    transition: background 200ms ease-in-out;
    flex: 0 0 ${space[7]}px;
    z-index: 1;

    & svg {
      color: ${colors.white};
      height: 22px;
      width: 22px;
    }

    &:hover {
      background: ${colors.text.secondary};
      opacity: 0.9;
    }

    &:disabled {
      background: ${colors.text.secondary};
      opacity: 0;
      cursor: default;
      &:hover {
        background: none;
      }
    }
  `,
);

const Description = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    margin-top: ${space[4]}px;
    word-break: break-all;
  `,
);

interface IMainPreviewProps {
  children: ReactNode;
  items: (IImage | IDocumentFile)[];
  companyName?: string;
  selectedItemIndex?: number;
  isInitStage?: boolean;
  onSelect?: Dispatch<SetStateAction<number>>;
}

const MainPreview: FC<IMainPreviewProps> = ({
  children,
  items,
  companyName,
  selectedItemIndex = 0,
  isInitStage,
  onSelect = STUB_FUNC.NOOP,
}) => {
  const currentItem = items?.length > 0 ? items[selectedItemIndex] : null;
  const showDescription = !!currentItem?.description;

  const handlePrev = (): void => onSelect(idx => idx - 1);
  const handleNext = (): void => onSelect(idx => idx + 1);

  return (
    <Container height='100%' minHeight={0} position='relative'>
      <NavButton
        disabled={selectedItemIndex === 0 || !isInitStage}
        onClick={handlePrev}
      >
        <ArrowLeft1pxIcon />
      </NavButton>
      <Container column flex='1 1 960px'>
        {children}
        <Description>
          <Container alignItems='center' mb={1}>
            <Text color='white' fontSize={5} fontWeight={600}>
              {currentItem?.filename}
            </Text>
            <IconButtonWrapper
              download
              as='a'
              href={getImageURL(currentItem, { size: 'original' })}
              ml={1}
              title='Скачать оригинал'
              variant='white'
            >
              <UploadIcon />
            </IconButtonWrapper>
          </Container>
          {showDescription && (
            <Text color='white'>{currentItem.description}</Text>
          )}
          <Container
            alignItems='center'
            justifyContent='flex-start'
            mb={2}
            mt={1}
          >
            <Text color='white' fontSize={2} style={{ opacity: '0.7' }}>
              Опубликовано: {companyName || 'istock.info'}
            </Text>
          </Container>
        </Description>
      </Container>
      <NavButton
        disabled={selectedItemIndex === items.length - 1 || !isInitStage}
        onClick={handleNext}
      >
        <ArrowRight1pxIcon />
      </NavButton>
    </Container>
  );
};

export { MainPreview };
