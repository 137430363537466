import { Fragment, memo, FC } from 'react';

import styled, { css } from 'styled-components';

import { PartnershipStatuses } from 'common/types/partnership';
import { RecommendationSmallIcon } from 'resources/icons/12';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { Badge, Text, Tooltip } from 'UI';

const TooltipFooter = styled.div(
  ({ theme: { colors, space } }) => css`
    color: ${colors.text.secondary};
    background: ${colors.background};
    padding: ${space[1]}px;
    margin: ${space[1]}px -${space[1]}px -${space[1]}px;
  `,
);

const COMPANY_TIP = (
  <Fragment>
    <Text fontWeight={600} mb='10px'>
      Страница компании сгенерирована системой
    </Text>
    Производителя пока нет в системе, товары добавлены поставщиками
    <TooltipFooter>
      Если вы обнаружили ошибку или хотите пожаловаться, свяжитесь с нами
      удобным для вас способом:{' '}
      <a href='mailto:support@istock.info'>support@istock.info</a>, тел. +7
      (981) 795-00-35
    </TooltipFooter>
  </Fragment>
);

const COMPANY_STATE_NAMES: Record<string, string> = {
  [PartnershipStatuses.confirmed]: 'Мой партнер',
  [PartnershipStatuses.pending]: 'Ожидает подтверждения',
  [PartnershipStatuses.declined]: 'Заблокирован',
  [PartnershipStatuses.blocked]: 'Заблокирован',
};

const badgesByStatus: Record<string, JSX.Element> = {
  [PartnershipStatuses.pending]: (
    <Badge label={COMPANY_STATE_NAMES.PENDING} size='m' />
  ),
  [PartnershipStatuses.confirmed]: (
    <Badge
      bg='success.main'
      borderColor='transparent'
      color='white'
      fontWeight={700}
      label={COMPANY_STATE_NAMES.CONFIRMED}
      size='m'
    />
  ),
  [PartnershipStatuses.declined]: (
    <Badge label={COMPANY_STATE_NAMES.DECLINED} size='m' type='error' />
  ),
  [PartnershipStatuses.blocked]: (
    <Badge label={COMPANY_STATE_NAMES.BLOCKED} size='m' type='error' />
  ),
};

interface ICompanyBadge {
  isUserCompany?: boolean;
  isRegistered?: boolean;
  partnershipStatus?: string;
}

const CompanyBadge: FC<ICompanyBadge> = memo(
  ({ isUserCompany, isRegistered, partnershipStatus }) => {
    if (isUserCompany) {
      return <Badge label='Моя компания' />;
    }

    if (isRegistered && partnershipStatus) {
      return badgesByStatus[partnershipStatus];
    }

    if (!isRegistered) {
      return (
        <Badge
          bg='primary.main'
          borderColor='transparent'
          color='white'
          label={
            <Fragment>
              <RecommendationSmallIcon color='white' mr={1} />
              Cгенерировано системой
              <Tooltip
                arrow
                maxWidth={334}
                placement='left'
                title={COMPANY_TIP}
              >
                <Help1pxIcon color='white' ml={1} />
              </Tooltip>
            </Fragment>
          }
          style={{ letterSpacing: 0.5 }}
        />
      );
    }

    return null;
  },
);

export { CompanyBadge };
