import {
  CSSProperties,
  ElementType,
  FC,
  MouseEventHandler,
  ReactNode,
  useContext,
} from 'react';

import styled, { css } from 'styled-components';
import {
  color as styledColor,
  typography,
  space as styledSpace,
  ColorProps,
  TypographyProps,
  SpaceProps,
} from 'styled-system';

import { CheckSmallIcon } from 'resources/icons/12';
import { IExternalReference, ILink } from 'tools/libs/navigate/types';

import { ContextMenuContext } from './ContextMenu';

interface IItemProps {
  $warn?: boolean;
  $active?: boolean;
  $selected?: boolean;
  $multiline?: boolean;
  disabled?: boolean;
}

const Item = styled.div<IItemProps & ColorProps & TypographyProps & SpaceProps>(
  ({
    theme: { colors, space, fontSizes },
    $warn,
    $active,
    $selected,
    disabled,
    $multiline,
  }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${space[1]}px ${space[2]}px;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text.secondary};
    text-align: left;
    cursor: pointer;
    transition: 0.2s ease-in-out color, 0.2s ease-in-out background-color;

    ${!$multiline &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    `}

    &:hover {
      background: ${$warn ? colors.highlight[3] : colors.highlight[0]};
      color: ${colors.text.primary};

      & svg {
        color: ${colors.text.primary};
      }
    }

    ${$selected &&
    css`
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};
      cursor: default;
    `}

    &:hover {
      ${$active &&
      css`
        color: ${colors.text.secondary};
        background: transparent;
        cursor: default;
      `}
    }

    ${disabled &&
    css`
      cursor: default;
      pointer-events: none;

      &,
      &:hover {
        background: transparent;
        color: ${colors.text.disabled};
      }
    `}

    ${styledColor}
    ${typography}
    ${styledSpace}
  `,
);

const Text = styled.span<{ $icon: boolean }>(
  ({ theme: { space }, $icon }) => css`
    flex: 1;
    margin-left: ${$icon ? space[1] : 0}px;
    padding-right: ${$icon ? space[1] : 0}px;
    line-height: 1.5;
  `,
);

interface IContextMenuItemProps {
  className?: string;
  style?: CSSProperties;
  as?: ElementType;
  text?: ReactNode;
  icon?: ReactNode;
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  multiline?: boolean;
  /**
   * Необходимо вручную добавлять тип на color из ColorProps т.к. происходит несоответствие типов.
   * Из-за того, что color является еще и атрибутом в теге.
   */
  color?: string;
  onClick?: MouseEventHandler<any>;
}

const ContextMenuItem: FC<
  IContextMenuItemProps &
    Partial<ILink> &
    Partial<IExternalReference> &
    ColorProps &
    TypographyProps &
    SpaceProps
> = ({
  text = '',
  as,
  to,
  href,
  download,
  className,
  style,
  icon,
  active,
  selected,
  disabled,
  multiline,
  color,
  onClick,
  ...other
}) => {
  const onRequestClose = useContext(ContextMenuContext);

  const handleClick: MouseEventHandler<HTMLDivElement> = event => {
    if (onClick) onClick(event);
    if (onRequestClose) onRequestClose();
  };

  return (
    <Item
      $active={active}
      $multiline={multiline}
      $selected={selected}
      as={as}
      className={className}
      color={color}
      disabled={disabled}
      download={download}
      href={href}
      role='menuitem'
      style={style}
      to={to}
      onClick={handleClick}
      {...other}
    >
      {icon}
      <Text $icon={!!icon}>{text}</Text>
      {active && <CheckSmallIcon color='primary.main' ml='auto' size={12} />}
    </Item>
  );
};

export { ContextMenuItem };
