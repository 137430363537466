import { forwardRef, useEffect, useRef } from 'react';

import styledSystemPropTypes from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space as styledSpace, borders as styledBorders } from 'styled-system';

import { width, height } from 'common/constants/customStyledSystems.const';
import { pickOmit } from 'common/utils';

import { InputContainer } from '../Inputs/components';
import { Text } from '../Text';

const Container = styled(InputContainer)(
  ({ autosize }) => css`
    height: ${autosize ? 'auto' : '136px'};

    ${autosize &&
    css`
      min-height: 70px;
    `}

    ${styledSpace};
    ${styledBorders};
    ${width};
    ${height}
  `,
);

const TextAreaInput = styled.textarea(
  ({ theme: { space, colors } }) => css`
    flex: 1;
    align-self: stretch;
    min-width: 0;
    padding: ${space[1]}px ${space[2]}px;
    line-height: inherit;
    color: inherit;
    text-align: left;
    background: none;
    font-family: inherit;
    outline: none;
    resize: none;
    border: none;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

const CONTAINER_PROPS = [
  'data-border-bottom',
  'data-border-right',
  'height',
  ...Object.keys(styledSystemPropTypes.space),
];

// Обновление height, когда value меняется
function useAutosizeTextArea(textAreaRef, value) {
  useEffect(() => {
    if (textAreaRef) {
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = '0px';
      const { scrollHeight } = textAreaRef;
      // eslint-disable-next-line no-param-reassign
      textAreaRef.style.height = `${scrollHeight}px`;
    }
  }, [textAreaRef, value]);
}

const TextArea = forwardRef(
  (
    {
      id,
      inputRef,
      style,
      className,
      name,
      readonly,
      disabled,
      autosize,
      placeholder,
      limit,
      value,
      error,
      rightAddon,
      onChange,
      onBlur,
      ...other
    },
    ref,
  ) => {
    const [containerProps, textAreaProps] = pickOmit(other, CONTAINER_PROPS);
    const textAreaRef = useRef(null);

    useAutosizeTextArea(autosize && textAreaRef.current, value);

    return (
      <Container
        ref={ref}
        readonly={readonly}
        style={style}
        className={className}
        disabled={disabled}
        error={error}
        autosize={autosize}
        {...(limit && { position: 'relative', pb: 3 })}
        {...containerProps}
      >
        <TextAreaInput
          id={id}
          ref={autosize ? textAreaRef : inputRef}
          name={name}
          value={value}
          placeholder={placeholder}
          maxLength={limit}
          disabled={disabled}
          onChange={onChange}
          onBlur={onBlur}
          {...textAreaProps}
        />
        {limit && (
          <Text
            position='absolute'
            bottom={1}
            right={1}
            color='text.disabled'
            fontSize={1}
          >
            {value?.length ?? 0}/{limit}
          </Text>
        )}
        {rightAddon}
      </Container>
    );
  },
);

const CustomTextArea = styled(TextArea)(
  ({ theme: { space }, minHeight, height: areaHeight = '50px' }) => css`
    height: auto;
    min-height: ${space[typeof minHeight === 'number' ? minHeight : 0]}px;
    line-height: ${space[2]}px;
    width: 100%;

    & > textarea {
      height: ${areaHeight};
    }
  `,
);

TextArea.propTypes = {
  /**
   * Initial values
   */
  value: PropTypes.string,
  /**
   * Id for input
   */
  id: PropTypes.node,
  /**
   * Name of input
   */
  name: PropTypes.string,
  /**
   * Disabled status
   */
  disabled: PropTypes.bool,
  /**
   * Text for empty field
   */
  placeholder: PropTypes.string,
  /**
   * Limit of symbols in the field
   */
  limit: PropTypes.number,
  /**
   * Text of error
   */
  error: PropTypes.bool,
  /**
   * Addon after item
   */
  rightAddon: PropTypes.node,
  /**
   * Handler for blur
   */
  onBlur: PropTypes.func,
  /**
   * Handler for change
   */
  onChange: PropTypes.func.isRequired,
};

export { TextArea, CustomTextArea };
