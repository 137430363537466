import { Fragment, useContext } from 'react';

import styled, { css } from 'styled-components';

import { ConditionalOptions } from 'common/types/joint.types';
import { Field, useField, useWatch } from 'reform';
import { AddMediumIcon } from 'resources/icons/18';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  FormLabel,
  Heading,
  IconButton,
  SelectField,
  useContextMenu,
} from 'UI';

import { CONDITIONAL_OPTIONS_NAME } from '../../../constants';
import {
  JOINT_RULE_INITIAL_VALUES,
  JOINT_RULE_OPERATION_NAMES,
} from '../constants';
import { OperationField } from '../operations/Operations';
import { PropertySelect, PropertySelectContext } from '../PropertySelect';

const conditionalOptions = [
  ConditionalOptions.equal,
  ConditionalOptions.greater,
  ConditionalOptions.greaterOrEqual,
  ConditionalOptions.less,
  ConditionalOptions.lessOrEqual,
];

const conditionalToName = (item: string): string => {
  return CONDITIONAL_OPTIONS_NAME[item];
};

const NumericWarning = styled.div(
  ({ theme: { space, colors } }) => css`
    width: 200px;
    margin-top: ${space[0]}px;
    margin-bottom: ${space[3]}px;
    color: ${colors.text.secondary};
  `,
);

interface IExpressionProps {
  name: string;
  dependentPropertyName: string;
}

function Expression({
  name,
  dependentPropertyName,
}: IExpressionProps): JSX.Element {
  const { value, setValue } = useField({ name });
  const affectingPropertyId = useWatch({ fieldName: dependentPropertyName });

  const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
    useContextMenu();

  if (!value) {
    return (
      <Fragment>
        <Button
          color='secondary'
          mb={2}
          variant='text'
          onClick={handleContextMenuOpen}
        >
          <IconButton frame as='div' mr={1} size={20}>
            <AddMediumIcon size={10} />
          </IconButton>
          Добавить вид условия
        </Button>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          onRequestClose={handleContextMenuClose}
        >
          {JOINT_RULE_INITIAL_VALUES.map(rule => (
            <ContextMenuItem
              key={rule.operation}
              // todo remove when will the backend appear
              disabled={rule.disabled}
              text={JOINT_RULE_OPERATION_NAMES[rule.operation]}
              onClick={() => setValue(rule)}
            />
          ))}
        </ContextMenu>
      </Fragment>
    );
  }

  return (
    <OperationField
      disabled
      affectingPropertyId={affectingPropertyId}
      mb={2}
      name={name}
      value={value}
      onRemove={() => setValue(null)}
    />
  );
}

function ComparisonRuleForm({ index }: { index: number }): JSX.Element {
  const propertySelectContext = useContext(PropertySelectContext);
  const { propertyById } = propertySelectContext ?? {};

  const isStringType = useWatch({
    fieldName: `rules.${index}.property.id`,
    selector: (property: string): boolean =>
      propertyById?.get(property)?.type === 'multiString' ||
      propertyById?.get(property)?.type === 'string',
  });

  const isNotEqual = useWatch({
    fieldName: `rules.${index}.comparison`,
    selector: (property: string): boolean => property !== 'equal',
  });

  return (
    <Fragment>
      <Heading as='h5' mb={2}>
        Форма для которой задается правило
      </Heading>
      <PropertySelect name={`rules.${index}.property`} />
      <Heading as='h5' mb={2} mt='12px'>
        Условие
      </Heading>
      <Field
        component={SelectField}
        itemToString={conditionalToName}
        label='Тип сравнения'
        name={`rules.${index}.comparison`}
        options={conditionalOptions}
        placeholder='Выберите тип сравнения'
        width={200}
      />
      {isStringType && isNotEqual && (
        <NumericWarning>
          Для сравнения будет испозьзоваться справочник числовых эквивалентов
          для класса точности
        </NumericWarning>
      )}
      {/* TODO: htmlFor */}
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <FormLabel required>Выражение</FormLabel>
      <Expression
        dependentPropertyName={`rules.${index}.property.id`}
        name={`rules.${index}.expression`}
      />
    </Fragment>
  );
}

export { ComparisonRuleForm };
