import { FC, ReactNode, useState } from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { Slider } from 'UI';

const TabIcon = styled.button<{ active: boolean }>(
  ({ theme: { colors }, active }) => css`
    display: flex;
    align-items: center;
    flex-grow: 1;
    justify-content: center;
    height: 46px;
    border-bottom: 2px solid transparent;
    color: ${colors.text.secondary};

    &:hover {
      border-bottom-color: ${colors.highlight[3]};
      color: ${colors.text.primary};
    }

    ${active &&
    css`
      border-bottom-color: ${colors.primary.main};
      color: ${colors.primary.main};
      &:hover {
        border-bottom-color: ${colors.primary.main};
        color: ${colors.primary.main};
      }
    `}
  `,
);

interface IAdvertisingSlider {
  tabs: any;
  frameWidth: number;
  children: ReactNode;
}

const AdvertisingSlider: FC<IAdvertisingSlider & SpaceProps> = ({
  tabs,
  frameWidth,
  children,
  ...other
}) => {
  const [currentTab, setCurrentTab] = useState(0);
  return (
    <Slider
      currentTab={currentTab}
      frameWidth={frameWidth}
      tabs={
        <Slider.Tabs borderColor='divider' {...other}>
          {tabs.map((title, index) => (
            <TabIcon
              key={index}
              active={currentTab === index}
              onClick={() => setCurrentTab(index)}
            >
              {title}
            </TabIcon>
          ))}
        </Slider.Tabs>
      }
    >
      {children}
    </Slider>
  );
};

export { AdvertisingSlider };
