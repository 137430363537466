import { useEffect, memo, FC, RefObject } from 'react';

import { ICommonSearchProductForm } from 'common/types/common-search';
import { ScrollTabsByArrows } from 'components/ScrollTabsByArrows';
import { CheckMediumIcon } from 'resources/icons/18';
import { AnalogIcon } from 'resources/other-20';
import { ToggleButton } from 'UI';

const HINT =
  'Фильтры по характеристикам привязаны к типам товаров. Выберите один или несколько из подобранных фильтров и заполните значения необходимых характеристик. При изменении запроса фильтры будут сброшены.';

interface IProductFormTabsProps {
  tabListRef: RefObject<HTMLDivElement>;
  indexDeletedForm?: number;
  activeFormIndex: number;
  productForms: ICommonSearchProductForm[];
  filledFormsIdsMap: Set<string>;
  analogId: string | undefined;
  onSelectForm: (index: number) => void;
}

const ProductFormTabs: FC<IProductFormTabsProps> = memo(
  ({
    tabListRef,
    indexDeletedForm,
    activeFormIndex,
    productForms,
    filledFormsIdsMap,
    analogId,
    onSelectForm,
  }) => {
    useEffect(() => {
      const firstTabForScrolling =
        indexDeletedForm ??
        productForms.findIndex(form => filledFormsIdsMap.has(form.id));

      if (firstTabForScrolling >= 0) onSelectForm(firstTabForScrolling);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <ScrollTabsByArrows hint={HINT} tabListRef={tabListRef}>
        {productForms.map(({ id: formId, productType }, index) => {
          const hasIcon = filledFormsIdsMap.has(formId);
          return (
            <ToggleButton.Tab
              key={formId}
              truncate
              checked={activeFormIndex === index}
              hasIcon={hasIcon}
              label={productType}
              maxWidth={350}
              mr={0}
              mt='6px'
              pl={2}
              pr={hasIcon ? '10px' : 2}
              rightAddon={
                hasIcon &&
                (formId === analogId ? (
                  <AnalogIcon color='primary.main' ml={1} />
                ) : (
                  <CheckMediumIcon color='primary.main' ml={1} size={20} />
                ))
              }
              onClick={() => onSelectForm(index)}
            />
          );
        })}
      </ScrollTabsByArrows>
    );
  },
);

export { ProductFormTabs };
