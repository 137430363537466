import { CSSProperties, FC } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';

import { fetchManufacturedNtds } from 'common/api/ntd.api';
import { useRequest } from 'common/hooks';
import { ManufacturingRequestType } from 'common/types/manufacturing-requests.types';
import { IManufacturers } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { CREATE_MANUFACTURING_REQUEST_MODAL } from 'entities/manufacturing-requests';
import { useManufactureButtonInfo } from 'entities/manufacturing-requests/hooks';
import { useModalContext } from 'entities/modals';
import { LinkOutsideIcon } from 'resources/icons/18';
import { getPath, PAGES, COMPANY } from 'routes';
import {
  Avatar,
  Button,
  Container,
  IconButton,
  Spinner,
  Text,
  Tooltip,
} from 'UI';

import { ExternalLinkIcon, HiddenLinkIcon, MapCardContainer } from '../styled';

const linkIconStyle: CSSProperties = {
  position: 'absolute',
  top: '25px',
  right: '5px',
};

interface IManufacturersMapCardProps {
  style: CSSProperties;
  active: boolean;
  location: IManufacturers;
  handleClick: VoidFunction;
}

const ManufacturersMapCard: FC<IManufacturersMapCardProps> = ({
  style,
  active,
  location,
  location: { name, id, logo, location: placement },
  handleClick,
}) => {
  const { id: itemId } = useParams<{ id: string }>();
  const { handleModalOpen } = useModalContext();
  const { pathname } = useLocation();

  const isNtdPage = pathname.includes(getPath(PAGES.NTD, { itemId }));
  const isStandardPage = pathname.includes(getPath(PAGES.STANDARD, { itemId }));
  const isProductVariationPage = pathname.includes(
    getPath(PAGES.GENERIC_PRODUCT_VARIATIONS, { itemId }),
  );

  // Должно показываться только на ВС НТД и ВС Вариации
  const showRequestButton = isNtdPage || isProductVariationPage;

  const { title, disabled } = useManufactureButtonInfo({
    companyId: id,
    type: isNtdPage
      ? ManufacturingRequestType.ntdManufacturersBlock
      : ManufacturingRequestType.genericProductVariationBlock,
  });

  const { isLoading, data: manufacturedNtdId } = useRequest(
    fetchManufacturedNtds,
    [{ companyId: id, ntdId: itemId, itemsPerPage: 1 }],
    { responseToData: response => response.member[0]?.id },
  );

  const handleRequestModalOpen = (): void => {
    return handleModalOpen(CREATE_MANUFACTURING_REQUEST_MODAL, {
      companyId: id,
      type: isNtdPage
        ? ManufacturingRequestType.ntd
        : ManufacturingRequestType.genericProductVariation,
      item: { id: itemId },
      authorshipCompany: location,
    });
  };

  if (isLoading) return <Spinner />;

  return (
    <MapCardContainer active={active} style={style} onClick={handleClick}>
      <Container m='auto'>
        <Avatar letter={name} mr={2} size='s' url={getImageURL(logo)} />
        <Container display='inline-flex' width='300px'>
          <Container alignItems='center' mb='0' minHeight='20px'>
            <Container column>
              <Text
                truncate
                fontSize={4}
                fontWeight={500}
                maxWidth='280px'
                mr='auto'
              >
                {name}
              </Text>
              {placement && <Text lineClamp>{placement.address}</Text>}
              {showRequestButton && (
                <Tooltip placement='top' title={title}>
                  <div>
                    <Button
                      color='primary'
                      disabled={disabled}
                      fontSize={2}
                      fontWeight={500}
                      mt={0}
                      variant='string'
                      onClick={handleRequestModalOpen}
                    >
                      Отправить заявку
                    </Button>
                  </div>
                </Tooltip>
              )}
            </Container>
          </Container>
          {isNtdPage || isStandardPage ? (
            <HiddenLinkIcon>
              <IconButton
                as={Link}
                title='К НТД в производстве'
                to={getPath(PAGES.MANUFACTURED_NTD, {
                  id: manufacturedNtdId,
                })}
              >
                <LinkOutsideIcon color='text.secondary' />
              </IconButton>
            </HiddenLinkIcon>
          ) : (
            <Tooltip arrow hint ml='auto' title='К компании'>
              <Link style={linkIconStyle} to={getPath(COMPANY.CARD, { id })}>
                <ExternalLinkIcon color='primary.main' size={18} />
              </Link>
            </Tooltip>
          )}
        </Container>
      </Container>
    </MapCardContainer>
  );
};

export { ManufacturersMapCard };
