import { FC } from 'react';

import { Link } from 'react-router-dom';

import { hasAccessRole } from 'entities/identity';
import { CONSTRUCTOR, PARTNERS, PRODUCTS } from 'routes';
import { useAppSelector } from 'store';
import { Heading, Button, Container } from 'UI';

const IdentityAuthPlaceholder: FC<{ heading: string }> = ({ heading }) => {
  const isConstructor = useAppSelector(
    state =>
      hasAccessRole(state, 'chiefConstructor') ||
      hasAccessRole(state, 'juniorConstructor'),
  );

  return (
    <>
      <Heading as='h2' mb={3} mt={-1} textAlign='center'>
        {heading}
      </Heading>
      <Container alignItems='center' justifyContent='center' mb={1}>
        <Button
          as={Link}
          mr={2}
          size='l'
          to={isConstructor ? CONSTRUCTOR.PRODUCT : PRODUCTS.COMPANY_PUBLIC}
        >
          К товарам
        </Button>
        <Button as={Link} size='l' to={PARTNERS.LIST} variant='outlined'>
          К партнерам
        </Button>
      </Container>
    </>
  );
};

export { IdentityAuthPlaceholder };
