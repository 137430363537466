import { FC } from 'react';

import styled, { css } from 'styled-components';

import { COMPOSITION_ITEM_TO_TEXT } from 'common/constants/products.const';
import { Nullable } from 'common/types/common.types';
import {
  CompositionType,
  EntitiesTypes,
  IProductEntity,
  ProductKind,
  PropertyPositionType,
} from 'common/types/product.types';
import { ChildrenUnionWrapper, EditTemplates } from 'components/characteristic-elements/EditMode';
import { checkIsSectionDisabled, useComposition } from 'entities/composition';
import { useWatch } from 'reform';
import { CloseMediumIcon } from 'resources/icons/18';
import { ButtonRounded, IconButton, Tooltip } from 'UI';

const StyledChildrenUnionWrapper = styled(ChildrenUnionWrapper)(
  ({ theme: { colors, space, borderRadius } }) => css`
    align-items: center;
    height: ${space[4]}px;
    padding-left: ${space[2]}px;
    border-left: 1px solid ${colors.divider};

    & > *:last-child {
      border-radius: ${borderRadius}px;
      margin-left: auto;
    }
  `,
);

/**
 * Метод для получения списка доступных разделов, в которые сущность может быть добавлена
 * (без учета того, заполнен ли раздел / добавлена ли уже сущность в этот раздел)
 * @param entity - сущность, которую нужно добавить
 */
const getCompositionSectionsByType = (
  entity: Nullable<IProductEntity>,
): Nullable<PropertyPositionType[]> => {
  if (!entity) return null;

  switch (entity['@type']) {
    case EntitiesTypes.LinkedProduct:
    case EntitiesTypes.Product: {
      return [PropertyPositionType.equipment];
    }
    case EntitiesTypes.Assortment: {
      return [PropertyPositionType.material, PropertyPositionType.workPiece];
    }
    case EntitiesTypes.ProductStandard: {
      return [PropertyPositionType.equipment, PropertyPositionType.workPiece];
    }
    default:
      return [];
  }
};

export const COMPOSITION_TEXT_BY_TYPE = {
  [PropertyPositionType.equipment]: 'В комплектацию',
  [PropertyPositionType.workPiece]: 'В заготовку',
  [PropertyPositionType.material]: 'В материалы',
};

interface ICompositionEntityButtonsProps {
  propertyId: string;
  selectedProduct: Nullable<IProductEntity>;
  onAddToComposition: (
    type: PropertyPositionType | CompositionType.materials,
  ) => void;
  onRemoveFromComposition: VoidFunction;
}

const CompositionEntityButtons: FC<ICompositionEntityButtonsProps> = ({
  propertyId,
  selectedProduct,
  onAddToComposition,
  onRemoveFromComposition,
}) => {
  const composition = useWatch({ fieldName: 'composition' });
  const productKind = useWatch({ fieldName: 'kind' });
  const isDetail = productKind === ProductKind.detail;

  const { getPropertyFromPositions } = useComposition();
  const addedProperty = getPropertyFromPositions(propertyId);

  const availableCompositionSections =
    getCompositionSectionsByType(selectedProduct);

  if (!selectedProduct) {
    return null;
  }

  // если уже выбрана сущность состава у товара, то отобразить ее
  if (addedProperty) {
    return (
      <StyledChildrenUnionWrapper>
        {COMPOSITION_ITEM_TO_TEXT[addedProperty.type]}
        <IconButton
          frame
          mr='2px'
          size={28}
          title='Сбросить'
          onClick={onRemoveFromComposition}
        >
          <CloseMediumIcon />
        </IconButton>
      </StyledChildrenUnionWrapper>
    );
  }

  return (
    <EditTemplates.PlaceholderContainer data-border-bottom data-border-right>
      {availableCompositionSections?.map(section => {
        const currentSection =
          section === PropertyPositionType.material
            ? CompositionType.materials
            : section;
        const { disabled, text } = checkIsSectionDisabled({
          composition,
          type: section,
          isDetail,
        });

        return (
          <Tooltip key={section} hint pointer placement='bottom' title={text}>
            <div>
              <ButtonRounded
                disabled={disabled}
                mr={0}
                size='s'
                variant='outlined'
                onClick={() => onAddToComposition(currentSection)}
              >
                + {COMPOSITION_TEXT_BY_TYPE[section]}
              </ButtonRounded>
            </div>
          </Tooltip>
        );
      })}
    </EditTemplates.PlaceholderContainer>
  );
};

export { CompositionEntityButtons };
