import { FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Heading, Text } from 'UI';

const Item = styled(Link)(
  ({ theme: { colors, space, borderRadius } }) => css`
    display: flex;
    padding: ${space[4]}px;
    margin-bottom: ${space[2]}px;
    background: ${colors.white};
    color: inherit;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    width: 860px;
    max-width: 860px;

    &:hover {
      color: inherit;
      background: ${colors.highlight[0]};
    }
  `,
);

const TextWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    padding: 0 ${space[2]}px;
  `,
);

interface IInformationItemProps {
  route: string;
  title: string;
  icon: JSX.Element;
  text: string;
}

const InformationItem: FC<IInformationItemProps> = ({
  route,
  title,
  icon,
  text,
}) => (
  <Item to={route}>
    {icon}
    <TextWrapper>
      <Heading as='h2' mb={2}>
        {title}
      </Heading>
      <Text fontSize={5}>{text}</Text>
    </TextWrapper>
  </Item>
);

export { InformationItem };
