import {
  cloneElement,
  FC,
  isValidElement,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';

import styled, { css } from 'styled-components';
import { fontSize, FontSizeProps, SpaceProps } from 'styled-system';

import { Container } from '../Blocks';
import { ICollapse, useCollapse } from '../CollapseCard';
import { PaginationButton } from '../Pagination';

const Wrapper = styled.div<{
  restrictionHeight: number;
  offsetHeight: number;
  expanded: boolean;
}>(
  ({ restrictionHeight, offsetHeight, expanded }) => css`
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
    max-height: ${restrictionHeight}px;
    transition: max-height 200ms ease-in-out;

    ${expanded &&
    css`
      max-height: ${offsetHeight}px;
    `}
  `,
);

const DangerouslyTextWrapper = styled.div.attrs<{ text: string }>(
  ({ text }) => ({
    dangerouslySetInnerHTML: { __html: text },
  }),
)<{ text: string } & FontSizeProps>`
  max-width: 860px;
  margin: 0;

  & > p {
    margin-top: 0;
  }

  ${fontSize}
`;

interface IExpandableContainerProps {
  maxHeight?: number;
  title?: string;
  children: ReactNode;
}

const ExpandableContainerInner: FC<
  IExpandableContainerProps & ICollapse & SpaceProps
> = ({
  initialOpen,
  open,
  title = 'Показать все',
  maxHeight = 436,
  children,
  onRequestToggle,
  ...other
}) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [offsetHeightChildrenContainer, setOffsetHeightChildrenContainer] =
    useState(0);

  useEffect(() => {
    const wrapperObserver = new ResizeObserver(() => {
      setOffsetHeightChildrenContainer(wrapperRef.current!.offsetHeight);
    });

    wrapperObserver.observe(wrapperRef.current!);

    return () => wrapperObserver.disconnect();
  }, []);

  const { isOpen, handleToggle } = useCollapse({
    open,
    initialOpen,
    onRequestToggle,
  });

  const expandable = offsetHeightChildrenContainer > maxHeight;

  return (
    <Container display='block' {...other}>
      <Wrapper
        expanded={isOpen}
        offsetHeight={offsetHeightChildrenContainer}
        restrictionHeight={maxHeight}
      >
        {isValidElement(children) ? (
          cloneElement(children, { ref: wrapperRef, ...children.props })
        ) : (
          <Container ref={wrapperRef} display='block'>
            {children}
          </Container>
        )}
      </Wrapper>
      {expandable && (
        <PaginationButton mt={2} onClick={handleToggle}>
          {isOpen ? 'Свернуть' : title}
        </PaginationButton>
      )}
    </Container>
  );
};

const ExpandableContainer = Object.assign(ExpandableContainerInner, {
  DangerouslyTextWrapper,
});

export { ExpandableContainer };
