import { FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { LIFE_STATE_COLORS, LIFE_STATE_NAMES, PRODUCT_CATEGORY_TEXT } from 'common/constants/products.const';
import { IProduct, IProductEntity, ProductLifeState } from 'common/types/product.types';
import { getItemData } from 'common/utils/products/getItemData';
import { MainProperties } from 'components/products/properties';
import { LinkOutsideIcon, StandartBoldIcon } from 'resources/icons/18';
import { Badge, Container, IconButton, Text } from 'UI';

import { IAddButton, IAddCompositionProperties, IAddLinkedProperties, IOnAdd, SearchTypes } from '../types';

const Row = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[2]}px;
    min-height: ${space[6]}px;
    border-bottom: 1px solid ${colors.background};
    font-size: ${fontSizes[3]}px;

    & > ${Container} {
      flex-shrink: 0;
    }
  `,
);

interface IEntityItemProps {
  searchType: SearchTypes;
  entity: IProductEntity;
  addButtonComponent: IAddButton;
  onAdd: IOnAdd;
  properties: IAddCompositionProperties | IAddLinkedProperties;
}

const EntityItem: FC<IEntityItemProps> = ({
  searchType,
  entity,
  addButtonComponent: AddButtonComponent,
  ...other
}) => {
  const { designation, lifeState, category } = entity as IProduct;
  const {
    isStandard,
    isAssortment,
    itemName,
    itemCategory,
    itemLink,
    itemCompany,
  } = getItemData({
    item: entity,
    type: entity['@type'].toLowerCase(),
  });

  const isStandardEntity = isStandard || isAssortment;

  return (
    <Row>
      <Container width='252px'>
        <AddButtonComponent entity={entity} {...other} />
        <Container alignItems='center' width='210px'>
          <Container column mr={1} width='148px'>
            <Text
              truncate
              color={itemName ? 'text.primary' : 'text.disabled'}
              mb='2px'
            >
              {itemName || 'Не указано'}
            </Text>
            <Text color='text.secondary' fontSize={11}>
              {isStandardEntity
                ? itemCategory
                : PRODUCT_CATEGORY_TEXT[category]}
            </Text>
          </Container>
          <Container width='28px'>
            {isStandardEntity && <StandartBoldIcon color='primary.main' />}
          </Container>
          <MainProperties
            hasCopyUin
            product={
              {
                ...entity,
                mainProperties: [], // сейчас за common-search не приходят все релейшены, так что пока сделали так
              } as any
            }
          />
        </Container>
      </Container>
      <Container width='108px'>
        <Text truncate color={designation ? 'text.primary' : 'text.disabled'}>
          {designation || 'Не указано'}
        </Text>
      </Container>
      <Container alignItems='center' maxWidth='125px'>
        {searchType === SearchTypes.public ? (
          <Text truncate color={itemCompany ? 'text.primary' : 'text.disabled'}>
            {itemCompany || 'Не указано'}
          </Text>
        ) : (
          <Badge
            dot={LIFE_STATE_COLORS[lifeState]}
            label={
              lifeState === ProductLifeState.internalUsage
                ? 'Внутренний'
                : LIFE_STATE_NAMES[lifeState]
            }
            type={
              lifeState === ProductLifeState.blocked ||
              lifeState === ProductLifeState.rejected
                ? Badge.TYPES.ERROR
                : Badge.TYPES.DEFAULT
            }
          />
        )}
      </Container>
      <Container ml='auto' width='34px'>
        <Link target='_blank' to={itemLink}>
          <IconButton size={34} title='Открыть в новой вкладке'>
            <LinkOutsideIcon />
          </IconButton>
        </Link>
      </Container>
    </Row>
  );
};

export { EntityItem };
