import { Fragment, useState, useEffect, forwardRef } from 'react';

import ReactDOM from 'react-dom';
import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { Nullable } from 'common/types/common.types';
import { IImage, ImagesSections } from 'common/types/media.types';
import { IForbidden } from 'common/types/permissions.types';
import { IAssortment } from 'common/types/product.types';
import { getFileInfo, preparedForbidden } from 'common/utils';
import { IMediaComponentSection } from 'components/media';
import { StyledDescriptionIcon } from 'components/media/styled';
import { ImagesGallery } from 'components/products/ImagesGallery';
import { useNotify } from 'entities/notify';
import { useErrorControllerPanel, useField } from 'reform';
import { MoveIcon } from 'resources/icons/18';
import { StandardIcon } from 'resources/other-16';
import { useForkRef, useToggle } from 'tools/hooks';
import { moveItem, removeItem, replaceItem } from 'tools/utils';
import {
  Text,
  Panel,
  Button,
  Notice,
  Tooltip,
  Relative,
  IconButton,
  CollapseCard,
} from 'UI';

import { ImagesPreview } from './components';
import { DescriptionPopup, DropPlaceholder, ImageTile } from '../../components';

const StyledStandardIcon = styled(StandardIcon)(
  ({ theme: { space } }) => css`
    position: absolute;
    top: ${space[0]}px;
    right: ${space[0]}px;
  `,
);

const ProductPhotoWrapper = styled.div`
  position: relative;
  outline: none;
  height: 100%;
`;

const Grid = styled.div<{ imagesCount: number }>(
  ({ theme: { space }, imagesCount }) => css`
    display: grid;
    grid-template-columns: repeat(${imagesCount}, minmax(auto, 125px));
    grid-auto-rows: minmax(125px, auto);
    grid-gap: ${space[2]}px;
    margin-bottom: ${space[3]}px;
  `,
);

const INITIAL_DESCRIPTION_STATE: {
  descriptionAnchor: Nullable<HTMLElement>;
  descriptionIndex: number | null;
  imageExtension: string;
} = {
  descriptionAnchor: null,
  descriptionIndex: null,
  imageExtension: '',
};

interface IImagesFormTemplateProps {
  fileUploadSection?: ImagesSections;
  isDragMode?: boolean;
  forbidden?: IForbidden['forbidden'];
  disableGallery?: boolean;
  disableDescription?: boolean;
  error?: any;
  limit?: number;
  imagesCount?: number;
  images: IImage[] | IAssortment['measurementVariation']['measurement'];
  checkIsStandardFile?: (file: IImage) => boolean;
  handleChange: (newValue: any) => void;
}

const ImagesFormTemplate = forwardRef<HTMLDivElement, IImagesFormTemplateProps>(
  (
    {
      fileUploadSection,
      isDragMode = false,
      forbidden,
      disableGallery = false,
      disableDescription = false,
      error,
      limit = 100,
      imagesCount = 5,
      images: imgs = [],
      checkIsStandardFile = () => false,
      handleChange,
    },
    ref,
  ) => {
    const notify = useNotify();
    const [imagesErrorsState, setImagesErrorsState] = useState<string[]>([]);

    const images = Array.isArray(imgs) ? imgs : imgs.media?.images ?? [];

    useEffect(() => {
      const formattedErrors = images.map((_, index) =>
        error ? error[index] : undefined,
      );

      setImagesErrorsState(formattedErrors);
      // This is necessary because we only set errors when they change themselves
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    const [
      { descriptionAnchor, descriptionIndex, imageExtension },
      setDescriptionState,
    ] = useState(INITIAL_DESCRIPTION_STATE);

    const [selectedGalleryImage, openGallery] = useState<number | null>(null);

    const currentPhoto =
      descriptionIndex !== null ? images[descriptionIndex] : null;

    const handlePopupClose = (description: string, name: string): void => {
      const filename = `${name}.${imageExtension}`;
      handleChange(
        replaceItem(
          images,
          item => ({ ...item, filename, description }),
          descriptionIndex,
        ),
      );
      setDescriptionState(INITIAL_DESCRIPTION_STATE);
    };

    const { isDragActive, getRootProps, getInputProps, open } = useDropzone({
      noClick: true,
      disabled: forbidden?.insert,
      accept: [
        'image/png',
        'image/jpeg',
        'image/jpg',
        'image/bmp',
        'image/webp',
      ],
      maxSize: 52428800, // 50Mb
      onDrop: files => {
        if (images.length + files.length > limit) {
          notify.error('Достигнут лимит количества файлов');
        }

        handleChange([
          ...images,
          ...files
            .map((file: any) => {
              const tmpFile = file;

              tmpFile.id = uuid();
              tmpFile.filename = file.name;
              tmpFile.description = '';
              tmpFile.objectUrl = URL.createObjectURL(file);

              return tmpFile;
            })
            .slice(0, limit - images.length),
        ]);
      },
      onDropRejected: () => {
        notify.error(
          'Файлы с неверным форматом или размером не были загружены',
        );
      },
    });

    const handleLoadOf =
      (index: number) =>
      (value: IImage): void => {
        URL.revokeObjectURL(images[index].objectUrl);
        handleChange(replaceItem(images, value, index));
      };

    const handleMove = (from: string, to: string): void => {
      const fromIndex = images.findIndex(item => item.id === from);
      if (fromIndex === -1) return;

      const toIndex = images.findIndex(item => item.id === to);

      setImagesErrorsState(prev => moveItem(prev, fromIndex, toIndex));
      handleChange(moveItem(images, fromIndex, toIndex));
    };

    const handleRemove = (index: number): void => {
      setImagesErrorsState(prev => removeItem(prev, index));
      URL.revokeObjectURL(images[index].objectUrl);
      handleChange(removeItem(images, index));
    };

    return (
      <Fragment>
        <ProductPhotoWrapper {...getRootProps()}>
          <input {...getInputProps()} />
          {images.length > 0 ? (
            <Fragment>
              <Grid ref={ref} imagesCount={imagesCount}>
                {images.map((photo, index) => {
                  const isStandardFile = checkIsStandardFile(photo);

                  const handleOpenDescription = (event): void => {
                    const { extension } = getFileInfo(photo.filename);
                    setDescriptionState({
                      descriptionAnchor: event.currentTarget as HTMLElement,
                      descriptionIndex: index,
                      imageExtension: extension,
                    });
                  };

                  return (
                    <Relative key={photo.id}>
                      <ImagesPreview
                        canDrag={isDragMode}
                        error={imagesErrorsState[index]}
                        fileUploadSection={fileUploadSection}
                        photo={photo}
                        showControls={currentPhoto === photo}
                        onDescriptionRequest={
                          disableDescription ||
                          isStandardFile ||
                          forbidden?.edit
                            ? undefined
                            : handleOpenDescription
                        }
                        onGalleryRequest={
                          disableGallery ? undefined : () => openGallery(index)
                        }
                        onLoad={handleLoadOf(index)}
                        onMoveItem={handleMove}
                        onRemoveRequest={
                          isStandardFile || forbidden?.remove
                            ? undefined
                            : () => handleRemove(index)
                        }
                      />
                      {isStandardFile && (
                        <Tooltip
                          arrow
                          hint
                          placement='right'
                          title={
                            <Fragment>
                              <strong>Медиаданные стандарта</strong>
                              <Text>Недоступны для удаления</Text>
                            </Fragment>
                          }
                        >
                          <StyledStandardIcon />
                        </Tooltip>
                      )}
                      {photo.description && (
                        <Tooltip title={photo.description}>
                          <StyledDescriptionIcon />
                        </Tooltip>
                      )}
                    </Relative>
                  );
                })}
                {images.length < limit && !forbidden?.insert && (
                  <ImageTile onOpen={open} />
                )}
              </Grid>
              {isDragActive && <DropPlaceholder overlay type='image' />}
            </Fragment>
          ) : (
            <DropPlaceholder type='image' onOpen={open} />
          )}
        </ProductPhotoWrapper>
        {descriptionAnchor && (
          <DescriptionPopup
            isTitle
            open
            anchorEl={descriptionAnchor}
            descriptionPlaceholder='Описание фото'
            titlePlaceholder='Название фото'
            valueDescription={currentPhoto?.description}
            valueTitle={getFileInfo(currentPhoto?.filename).name}
            onSubmit={handlePopupClose}
          />
        )}
        {selectedGalleryImage !== null &&
          ReactDOM.createPortal(
            <ImagesGallery
              images={images}
              selectedImageIndex={selectedGalleryImage}
              onRequestClose={() => openGallery(null)}
            />,
            document.body,
          )}
      </Fragment>
    );
  },
);

interface IImagesFormProps {
  flat?: boolean;
  fileUploadSection?: ImagesSections;
  limit?: number;
}

const ImagesForm = forwardRef<
  HTMLDivElement,
  IMediaComponentSection & IImagesFormProps
>(
  (
    {
      id,
      flat,
      forbidden = {},
      name,
      fileUploadSection,
      limit,
      title,
      onRemoveSection,
    },
    ref,
  ) => {
    const notify = useNotify();

    const { isOpen, ref: panelRef, toggleOpen } = useErrorControllerPanel();
    const [isDragMode, toggleDragMode] = useToggle(false);

    const unionRef = useForkRef(ref, panelRef);

    const {
      initialValue,
      ref: fieldRef,
      value,
      setValue,
      error,
    } = useField({ name });

    const Card = flat ? CollapseCard : Panel;

    const simpleForbidden = preparedForbidden(forbidden, initialValue);

    return (
      <Card
        ref={unionRef}
        collapse
        inner
        actions={
          !simpleForbidden.edit && (
            <IconButton
              active={isDragMode}
              disabled={value.length < 2}
              ml={1}
              title={
                isDragMode
                  ? 'Выключить режим перемещения'
                  : 'Включить режим перемещения'
              }
              onClick={(event: Event) => {
                event.stopPropagation();
                toggleDragMode();
                notify.info(
                  isDragMode
                    ? 'Выключен режим перемещения изображений'
                    : 'Включен режим перемещения изображений',
                );
              }}
            >
              <MoveIcon />
            </IconButton>
          )
        }
        id={id}
        open={isOpen}
        px={flat ? '0px' : undefined}
        rightActions={
          !simpleForbidden.remove &&
          !!onRemoveSection && (
            <Button
              color='secondary'
              ml={1}
              variant='text'
              onClick={onRemoveSection}
            >
              Удалить
            </Button>
          )
        }
        title={title}
        onRequestToggle={toggleOpen}
      >
        <ImagesFormTemplate
          ref={fieldRef}
          error={error}
          fileUploadSection={fileUploadSection}
          forbidden={simpleForbidden}
          handleChange={setValue}
          images={value}
          isDragMode={isDragMode}
          limit={limit}
        />
        <Notice mb='0px' mt={3} type={Notice.TYPES.DISABLED}>
          Допустимые форматы файла — JPG, PNG, BMP. <br />
          Допустимый размер файла — 1 KB – 50 MB. Минимальное разрешение файла —
          50х50 px.
        </Notice>
      </Card>
    );
  },
);

export { ImagesForm, ImagesFormTemplate };
