import styled, { css } from 'styled-components';
import { height, HeightProps } from 'styled-system';

import { BaseInput } from 'UI';

export const HeaderContainer = styled.div<{ width: number } & HeightProps>(
  ({ theme: { space, colors }, width }) => css`
    width: ${width}px;
    min-width: ${width}px;
    border: 1px solid ${colors.text.disabled};
    padding: ${space[1]}px;
    margin-left: 3px;

    ${height};
  `,
);

export const GroupWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: flex-start;
    min-height: ${space[4]}px;
    margin-bottom: ${space[1]}px;
  `,
);

export const RowWrapper = styled.div`
  display: flex;
`;

export const CellWrapper = styled.div<{ width: number }>(
  ({ width }) => css`
    width: ${width}px;
    min-width: ${width}px;
    margin-left: 3px;
  `,
);

export const RowControlWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: 30px;
    min-width: 30px;
    border: 1px solid ${colors.text.disabled};
    border-top: none;
  `,
);

export const CellControl = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  white-space: nowrap;

  & > button {
    position: absolute;
    opacity: 0;
  }

  &:hover {
    color: transparent;

    & > button {
      opacity: 1;
    }
  }
`;

export const AddControl = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;

    &:hover::before {
      background: ${colors.success.main};
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      background: transparent;
      cursor: pointer;
      z-index: 1;
    }
  `,
);

export const AddRow = styled(AddControl)`
  &::before {
    left: 0;
    right: 0;
    top: -3px;
    height: 6px;
  }
`;

export const containerStyle = css<{ error?: boolean }>(
  ({ theme: { colors }, error }) => css`
    min-height: 31px;
    height: 31px;
    border: 1px solid ${colors.text.disabled};
    border-top: 1px solid transparent;
    border-radius: 0;

    &:focus {
      border-color: ${colors.text.primary};
    }

    ${error &&
    css`
      border-color: ${colors.error.main};
    `}
  `,
);

export const Input = styled(BaseInput)<{ error?: boolean }>(
  ({ theme: { colors }, disabled }) => css`
    width: 100%;

    ${disabled &&
    css`
      color: ${colors.text.disabled};
      background-color: ${colors.highlight[0]};
    `}

    ${containerStyle};
  `,
);
