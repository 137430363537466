import { ReactElement } from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { fetchMeasurementUnits } from 'common/api/dictionaries.api';
import { useUnit } from 'common/hooks';
import { FieldWrapper } from 'components/fields';
import { RecommendationSmallIcon } from 'resources/icons/12';
import { Tooltip, Select, Button } from 'UI';

const DropdownItem = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    height: 77px;
    padding: ${space[2]}px;
    background: ${colors.background};
    font-size: ${fontSizes[1]}px;
    color: ${colors.text.secondary};
  `,
);

const fetchMeasurementUnitsByPhysicalQuantity =
  physicalQuantity =>
  ({ name, page }) => {
    return fetchMeasurementUnits({ name, page, physicalQuantity });
  };

interface PropertyUnitsProps {
  'data-border-bottom'?: boolean;
  'data-border-right'?: boolean;
  disablePortal?: boolean;
  recommendationMode?: boolean;
  showLabel?: boolean;
  required?: boolean;
  readOnly?: boolean;
  physicalQuantity?: string | string[];
  physicalQuantityByUnitKey?: string;
  placeholder?: string;
  width?: string | number;
  value: string | null;
  onChange?: (unit: string) => void;
  onResetRecommendationMode?: () => void;
}

function PropertyUnits({
  recommendationMode,
  showLabel = true,
  required,
  readOnly,
  physicalQuantity,
  physicalQuantityByUnitKey,
  placeholder,
  value = '',
  width = 140,
  onChange,
  onResetRecommendationMode,
  ...other
}: PropertyUnitsProps & SpaceProps): ReactElement {
  const { unitToString, getPhysicalQuantity, getUnitOptions } = useUnit();

  const selectProps = recommendationMode
    ? {
        rightAddon: (
          <Tooltip
            pointer
            arrow={false}
            placement='bottom-start'
            title='Подобраны рекомендации'
          >
            <RecommendationSmallIcon color='primary.main' mr={1} my='auto' />
          </Tooltip>
        ),
        dropdownAddon: (
          <DropdownItem>
            Вернуться к{' '}
            <Button
              fontSize={1}
              variant='string'
              onClick={onResetRecommendationMode}
            >
              полному списку
            </Button>{' '}
            единиц измерения
          </DropdownItem>
        ),
        options:
          Array.isArray(physicalQuantity) && physicalQuantity.length === 1
            ? getUnitOptions({
                physicalQuantity: physicalQuantity[0],
                physicalQuantityByUnitKey,
              })
            : fetchMeasurementUnitsByPhysicalQuantity(physicalQuantity),
        fetchSearchOptions:
          fetchMeasurementUnitsByPhysicalQuantity(physicalQuantity),
      }
    : {
        options: getUnitOptions({
          physicalQuantity,
          physicalQuantityByUnitKey,
        }),
        fetchSearchOptions: fetchMeasurementUnitsByPhysicalQuantity(
          physicalQuantity ?? getPhysicalQuantity(physicalQuantityByUnitKey),
        ),
      };

  const select = (
    <Select
      {...selectProps}
      disabled={readOnly}
      itemToString={unitToString}
      placeholder={placeholder}
      value={value}
      width={width}
      onChange={onChange}
      {...other}
    />
  );

  if (!showLabel) return select;

  return (
    <FieldWrapper
      injectedProps
      disabled={readOnly}
      label='Ед. измерения'
      required={required}
    >
      {select}
    </FieldWrapper>
  );
}

export { PropertyUnits };
