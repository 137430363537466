import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  color,
  fontSize,
  fontWeight,
  ColorProps,
  FontSizeProps,
  FontWeightProps,
} from 'styled-system';

export const ContentWrapper = styled.div(
  ({ theme: { space } }) => css`
    columns: 242px 3;
    column-gap: ${space[4]}px;
    margin-top: ${space[4]}px;
    margin-bottom: ${space[2]}px;

    & > div {
      break-inside: avoid;
    }
  `,
);

export const ContentLink = styled(Link)<
  ColorProps & FontSizeProps & FontWeightProps
>(
  ({ theme: { colors } }) => css`
    width: max-content;
    max-width: 100%;
    color: ${colors.text.secondary};

    &:hover {
      color: ${colors.text.primary};
    }

    ${color}
    ${fontSize}
    ${fontWeight}
  `,
);
