import { Field } from 'reform';

import { Switcher } from './styled';

interface ISwitchFieldProps {
  isChecked?: boolean;
  isDisabled?: boolean;
  name?: string;
  label: string;
  tip?: string;
}

const SwitchField = ({
  isChecked,
  isDisabled,
  name,
  label,
  tip,
}: ISwitchFieldProps): JSX.Element => {
  if (isDisabled) {
    return (
      <Switcher
        disabled
        isDisabled
        checked={isChecked}
        label={label}
        tip={tip}
      />
    );
  }

  return (
    <Field
      name={name}
      render={field => (
        <Switcher
          checked={!field.value}
          field={field}
          label={label}
          onChange={event => {
            field.setValue(!event.target.checked);
          }}
        />
      )}
    />
  );
};

export { SwitchField };
