import styled, { css } from 'styled-components';

export const HTMLTable = styled.table(
  ({ theme: { colors, space } }) => css`
    position: relative;
    width: 100%;
    border: 1px solid ${colors.divider};
    table-layout: auto;
    border-collapse: collapse;

    & th,
    & td {
      width: 25%;
      font-weight: 500;
    }

    & td {
      padding: 14px ${space[2]}px;
      border-right: 1px solid ${colors.divider};

      &:last-child {
        border-right: none;
      }
    }

    & th {
      color: ${colors.text.secondary};
      text-align: center;
      padding: ${space[1]}px ${space[2]}px;
      border: 1px solid ${colors.divider};
    }

    & td:first-child {
      color: ${colors.text.secondary};
    }

    & tr:nth-child(even) td {
      background: ${colors.highlight[0]};
    }
  `,
);
