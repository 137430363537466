import styled, { css } from 'styled-components';
import { typography, TypographyProps } from 'styled-system';

const DrawerContentWrapper = styled.div<TypographyProps>(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${space[3]}px ${space[4]}px;
    overflow: auto;

    ${typography}
  `,
);

export { DrawerContentWrapper };
