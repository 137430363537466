import { FC, memo, useCallback } from 'react';

import { NumericFormat } from 'react-number-format';
import styled, { css } from 'styled-components';

import { Currency, CURRENCY_ISO_TEXT } from 'common/constants/currency.const';
import { ServicePriceTypes } from 'common/types/services.types';
import { EditTemplates } from 'components/characteristic-elements';
import {
  COMMON_NUMERIC_FORMAT_PROPS,
  PriceFieldWrapper,
} from 'components/works-and-services/form';
import { useField } from 'reform';
import { ReplaceIcon } from 'resources/icons/18';
import { Container, IconButton } from 'UI';
import { ItemToStringType } from 'UI/Inputs';

const FIELD_NAME = 'price';

const CURRENCIES = Object.values(Currency);
const itemToCurrency: ItemToStringType = (currency: Currency) =>
  CURRENCY_ISO_TEXT[currency];

const ChangePriceTypeButton = styled(IconButton).attrs(() => ({
  size: 34,
  frame: true,
}))(
  ({ theme: { borderRadius, colors } }) => css`
    margin: -1px -1px 0;
    border-radius: ${borderRadius}px 0 0;
    background-color: ${colors.background};
  `,
);

const getServicePriceError = (error: any, isNumberType: boolean): string => {
  if (typeof error === 'string') return error;

  if (isNumberType) {
    return error?.amount ?? error?.currency ?? '';
  }

  return error?.from ?? error?.to ?? error?.currency ?? '';
};

const Price: FC = memo(() => {
  const {
    value: { value, type },
    error,
    setValue,
  } = useField({ name: FIELD_NAME });
  const isNumberType = type === ServicePriceTypes.number;

  const handleChangePriceValue = useCallback(
    (key: string) =>
      ({ value: priceValue }) => {
        setValue(prev => ({
          ...prev,
          value: { ...prev.value, [key]: priceValue },
        }));
      },
    [setValue],
  );

  const handleChangePriceUnit = useCallback(
    priceUnit => {
      setValue(prev => ({
        ...prev,
        value: { ...prev.value, currency: priceUnit },
      }));
    },
    [setValue],
  );

  const handleChangePriceType = useCallback(() => {
    if (isNumberType) {
      setValue(prev => ({
        type: ServicePriceTypes.range,
        value: {
          currency: prev.value.currency,
          from: prev.value.amount,
          to: '',
        },
      }));
    } else {
      setValue(prev => ({
        type: ServicePriceTypes.number,
        value: { currency: prev.value.currency, amount: prev.value.from },
      }));
    }
  }, [isNumberType, setValue]);

  return (
    <PriceFieldWrapper
      commentPlaceholder='Комментарий'
      error={getServicePriceError(error, isNumberType)}
    >
      <Container>
        <ChangePriceTypeButton
          title={`Сменить на ${isNumberType ? 'диапазон' : 'число'}`}
          onClick={handleChangePriceType}
        >
          <ReplaceIcon />
        </ChangePriceTypeButton>
        {isNumberType ? (
          <NumericFormat
            {...COMMON_NUMERIC_FORMAT_PROPS}
            error={error?.amount}
            id='value.amount'
            name={`${FIELD_NAME}.value.amount`}
            placeholder='Введите цену'
            value={value.amount}
            width='100%'
            onValueChange={handleChangePriceValue('amount')}
          />
        ) : (
          <>
            <NumericFormat
              {...COMMON_NUMERIC_FORMAT_PROPS}
              error={error?.from}
              id='value.from'
              name={`${FIELD_NAME}.value.from`}
              placeholder='Цена от'
              value={value.from}
              width='50%'
              onValueChange={handleChangePriceValue('from')}
            />
            <NumericFormat
              {...COMMON_NUMERIC_FORMAT_PROPS}
              error={error?.to}
              id='value.to'
              name={`${FIELD_NAME}.value.to`}
              placeholder='Цена до'
              value={value.to}
              width='50%'
              onValueChange={handleChangePriceValue('to')}
            />
          </>
        )}
        <EditTemplates.Select
          borderTopLeftRadius='0px'
          error={error?.currency}
          itemToString={itemToCurrency}
          options={CURRENCIES}
          placeholder='Валюта'
          value={value.currency}
          width={160}
          onChange={handleChangePriceUnit}
        />
      </Container>
    </PriceFieldWrapper>
  );
});

export { Price };
