import { memo, FC } from 'react';

import styled from 'styled-components';

import { fetchClassifierTypes } from 'common/api/dictionaries.api';
import { EMPTY_ARRAY } from 'common/constants/emptyDataStructures.const';
import { useRequest } from 'common/hooks';
import { IClassifierType } from 'common/types/dictionaries.types';
import { Field } from 'reform';
import { SelectField, Divider } from 'UI';
import { colors } from 'UI/theme';

import { TableHeader } from '../../components';

const Wrapper = styled(TableHeader)`
  display: flex;
  flex-direction: column;
`;

const DIVIDER_STYLE = { borderColor: colors.text.primary };

const ClassifierHeader: FC = memo(() => {
  const { data: classifierCodes = EMPTY_ARRAY }: { data: string[] } =
    useRequest(fetchClassifierTypes, [], {
      responseToData: ({ member }: { member: IClassifierType[] }) => {
        return member.map(({ name }) => name);
      },
    });

  return (
    <Wrapper>
      <Divider mb='0px' mt='auto' style={DIVIDER_STYLE} />
      <Field
        component={SelectField}
        m={1}
        name='classifierType'
        options={classifierCodes}
        placeholder='Выберите значение'
        width='auto'
      />
    </Wrapper>
  );
});

export { ClassifierHeader };
