import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Tags, Tag, Container } from 'UI';

import { DEVIATION_TYPES } from '../../constants/common.const';
import { DEFAULT_DATE_PATTERN } from '../../constants/datePatterns.const';
import { Nullable } from '../../types/common.types';
import { PropertiesTypes } from '../../types/properties.types';
import { formatDate } from '../formatDate';
import { getFormattedNumber, getRangeText } from '../getFormattedNumber';
import { getItemData } from '../products/getItemData';

const CUSTOM_VALUE = 'Выбор из справочника';
const CUSTOM_VALUE_PRODUCT = 'Выбор из доступных товаров';

const customValueTooltip = (item: string): Nullable<string> =>
  item === CUSTOM_VALUE
    ? 'Эта характеристика может быть исполнена по пользовательским значениям.'
    : null;

const UpperDeviation = styled.sup(
  ({ theme: { fontSizes } }) => css`
    display: block;
    top: -5px;
    font-size: ${fontSizes[1]}px;
  `,
);

const LowerDeviation = styled.sub(
  ({ theme: { space, fontSizes } }) => css`
    display: block;
    top: ${space[0]}px;
    font-size: ${fontSizes[1]}px;
  `,
);
const getFormattedDeviation = (deviation: any): any => {
  switch (deviation?.type) {
    case DEVIATION_TYPES.number:
      return (
        <span style={{ display: 'inline-block' }}>
          {deviation.negative && (
            <LowerDeviation>-{deviation.negative}</LowerDeviation>
          )}
          {deviation.positive && (
            <UpperDeviation>+{deviation.positive}</UpperDeviation>
          )}
        </span>
      );
    case DEVIATION_TYPES.limit:
      return deviation.limit;
    case DEVIATION_TYPES.fit:
      return `${deviation.hole}/${deviation.shaft}`;
    default:
      return null;
  }
};

/**
 * The function to format product property
 *
 * @param {Object} property
 * @param {boolean} shortMode
 * @returns {string | JSX.Element} human readable string
 */
function getProductPropertiesPrimitive(
  property,
  shortMode = false,
): Nullable<string | JSX.Element> {
  const { type, value } = property;

  switch (type) {
    case PropertiesTypes.number: {
      const { deviation } = property;

      if (value) {
        return shortMode ? (
          getFormattedNumber(value)
        ) : (
          <span>
            {getFormattedNumber(value)} {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.twoDimensions: {
      const { deviation } = property;

      if (value.a && value.b) {
        return shortMode ? (
          `${getFormattedNumber(value.a)} x ${getFormattedNumber(value.b)}`
        ) : (
          <span>
            {getFormattedNumber(value.a)} x {getFormattedNumber(value.b)}{' '}
            {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.threeDimensions: {
      const { deviation } = property;

      if (value && value.a && value.b && value.c) {
        return shortMode ? (
          `${getFormattedNumber(value.a)} x ${getFormattedNumber(
            value.b,
          )} x ${getFormattedNumber(value.c)}`
        ) : (
          <span>
            {getFormattedNumber(value.a)} x {getFormattedNumber(value.b)} x{' '}
            {getFormattedNumber(value.c)} {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.range: {
      const propValue = getRangeText({ ...value, infinitely: false });

      return shortMode ? propValue : <span>{propValue}</span>;
    }
    case PropertiesTypes.dateRange:
      return `от ${formatDate(
        value.from,
        false,
        DEFAULT_DATE_PATTERN,
      )} до ${formatDate(value.to, false, DEFAULT_DATE_PATTERN)}`;
    case PropertiesTypes.date:
      return formatDate(value, false, DEFAULT_DATE_PATTERN);
    case PropertiesTypes.string:
      return shortMode ? value : <span>{value}</span>;
    case PropertiesTypes.multiString: {
      if (!value || value.length === 0) return null;

      if (shortMode) {
        return value.join(', ');
      }

      return <Tags arrow limit={value.length} tags={value} />;
    }
    case PropertiesTypes.arbitraryNumber:
    case PropertiesTypes.arbitraryRange:
      return getRangeText(value.options);
    case PropertiesTypes.arbitraryMultiString:
    case PropertiesTypes.arbitraryMultiNumber: {
      const { allowCustomValues, options } = value;
      const optionsWithCustomer = (
        allowCustomValues ? [...options, CUSTOM_VALUE] : options
      ).map(i => (typeof i === 'number' ? getFormattedNumber(i) : i));

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.arbitraryStandardMaterial: {
      const { allowCustomValues, materials } = value;
      const optionsWithCustomer = (
        allowCustomValues ? [...materials, CUSTOM_VALUE] : materials
      ).map(i =>
        i !== CUSTOM_VALUE ? `${i?.mark} (${i?.ntdReference?.numericName})` : i,
      );

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.arbitraryStandardCoating: {
      const { allowCustomValues, coatings } = value;

      const optionsWithCustomer = (
        allowCustomValues ? [...coatings, CUSTOM_VALUE] : coatings
      ).map(i =>
        i !== CUSTOM_VALUE
          ? `${i?.symbol || i?.name} (${i?.ntdReference?.numericName})`
          : i,
      );

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.arbitraryLinkedEntity: {
      const {
        allowCustomValues,
        options: { entity, references },
      } = value;

      const isAssortmentOrStandard =
        entity === 'standard' || entity === 'assortment';

      const optionsWithCustomer = allowCustomValues
        ? [
            ...references,
            isAssortmentOrStandard ? CUSTOM_VALUE : CUSTOM_VALUE_PRODUCT,
          ]
        : references;

      if (!references.length && !allowCustomValues) {
        return null;
      }

      return (
        <Container alignItems='center' flexWrap='wrap' gap='2px' width='100%'>
          {optionsWithCustomer.map((item, index) => {
            const name = getItemData({
              item: item?.id?.id ? item?.id : item,
              type: entity,
            }).itemName;

            const link = getItemData({
              item: item?.id?.id ? item?.id : item,
              type: entity,
            }).itemLink;

            const isCustomValue = !item.id;

            const customValueText = isAssortmentOrStandard
              ? CUSTOM_VALUE
              : CUSTOM_VALUE_PRODUCT;

            return (
              <Tag
                key={index}
                arrow
                as={Link}
                style={{
                  cursor: `${isCustomValue && 'default'}`,
                }}
                to={!isCustomValue ? link : undefined}
                value={isCustomValue ? customValueText : name}
              />
            );
          })}
        </Container>
      );
    }
    case PropertiesTypes.arbitraryRegulatoryReference:
      return property.name;
    case PropertiesTypes.constructionVariation:
      return property.name;
    case PropertiesTypes.multiColor:
      return value.map(color => color.name).join(', ');
    default:
      return null;
  }
}

export { getProductPropertiesPrimitive };
