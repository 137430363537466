import { useState, useEffect, useMemo, memo, useRef, FC } from 'react';

import styled, { css } from 'styled-components';

import { CatalogEntities, ICatalog } from 'common/types/catalogs.types';
import { IMapCategory } from 'common/types/categories.types';
import { Container, TabList, TablistTab } from 'UI';

import { CategoryGenericProducts } from './GenericProducts';
import { CategoryProducts } from './Products';
import { CategoryStandards } from './Standards';
import { ENTITY_PRODUCT, ENTITY_STANDARDS } from '../../constants';
import { EntityBlock } from '../../types';
import { isIncludeEntity } from '../../utils';

const EntitiesTabList = styled(TabList)(
  ({ theme: { space, colors } }) => css`
    position: sticky;
    top: -1px;
    z-index: 1;
    margin-top: ${space[2]}px;
    margin-bottom: ${space[1]}px;
    background: ${colors.white};
  `,
);

const TEXT_BY_TYPE = {
  [EntityBlock.standard]: 'НТД',
  [EntityBlock.genericProduct]: 'Типовые товары',
  [EntityBlock.product]: 'Пользовательские изделия',
};

const Entities: FC<{
  catalog: ICatalog;
  category: IMapCategory;
  viewType: string;
  ntdStatuses: string[];
}> = memo(({ catalog, category, viewType, ntdStatuses }) => {
  const wrapperRef = useRef<HTMLDivElement>(null);

  const entitiesTypeTabs = [
    {
      key: EntityBlock.standard,
      entity: ENTITY_STANDARDS,
      counts: category.stats.ntdsCount,
    },
    {
      key: EntityBlock.genericProduct,
      entity: CatalogEntities.genericProduct,
      counts: category.stats.genericProductsCount,
    },
    {
      key: EntityBlock.product,
      entity: ENTITY_PRODUCT,
      counts: category.stats.productsCount,
    },
  ].filter(({ entity, counts }) => {
    if (counts === 0) return false;

    return typeof entity === 'string'
      ? catalog.entities.includes(entity)
      : isIncludeEntity(catalog.entities, entity);
  });

  const [activeTab, setActiveTab] = useState<EntityBlock>(
    entitiesTypeTabs[0]?.key,
  );

  const handleClickTab = (typeKey: EntityBlock): void => {
    setActiveTab(typeKey);
    wrapperRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  useEffect(() => {
    if (!entitiesTypeTabs.find(({ key }) => key === activeTab)) {
      setActiveTab(entitiesTypeTabs[0]?.key);
    }
  }, [activeTab, entitiesTypeTabs]);

  const entitiesProps = useMemo(() => {
    return { catalogId: catalog.id, categoryId: category.id, viewType };
  }, [catalog, category, viewType]);

  return (
    <Container ref={wrapperRef} column height='100%'>
      <EntitiesTabList>
        {entitiesTypeTabs.map(({ key: typeKey, counts: entityCounts }) => (
          <TablistTab
            key={typeKey}
            aria-selected={activeTab === typeKey}
            data-type={typeKey}
            onClick={() => handleClickTab(typeKey)}
          >
            {TEXT_BY_TYPE[typeKey]} ({entityCounts})
          </TablistTab>
        ))}
      </EntitiesTabList>
      {isIncludeEntity(catalog.entities, ENTITY_STANDARDS) && (
        <CategoryStandards
          {...entitiesProps}
          isActive={activeTab === EntityBlock.standard}
          ntdStatuses={ntdStatuses}
        />
      )}
      {catalog.entities.includes(CatalogEntities.genericProduct) && (
        <CategoryGenericProducts
          {...entitiesProps}
          isActive={activeTab === EntityBlock.genericProduct}
        />
      )}
      {isIncludeEntity(catalog.entities, ENTITY_PRODUCT) && (
        <CategoryProducts
          {...entitiesProps}
          isActive={activeTab === EntityBlock.product}
        />
      )}
    </Container>
  );
});

export { Entities };
