import { FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { Container, Tip } from 'UI';

const BlockWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    align-self: stretch;
    margin: 0 ${space[4]}px;
  `,
);

const Block = styled.div<SpaceProps>(
  ({ theme: { space, colors } }) => css`
    display: flex;
    flex-direction: column;
    margin-bottom: ${space[3]}px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: 4px;
  `,
);

const TitleWrapper = styled.div(
  ({ theme: { fontSizes } }) => css`
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: ${fontSizes[4]}px;
  `,
);

interface MasterPanelProps {
  icon: JSX.Element;
  title: string | JSX.Element;
  tip: string;
  rightAddon?: JSX.Element;
  children: ReactNode;
}

const MasterPanelBlock: FC<MasterPanelProps & SpaceProps> = ({
  icon,
  title,
  tip,
  rightAddon,
  children,
}) => {
  return (
    <BlockWrapper>
      <Block>
        <Container height={34} justifyContent='space-between' mb={2}>
          <TitleWrapper>
            <Container mr={1}>{icon}</Container>
            {title}
            {tip && <Tip ml={0} tip={tip} />}
          </TitleWrapper>
          {rightAddon}
        </Container>
        {children}
      </Block>
    </BlockWrapper>
  );
};
export { MasterPanelBlock };
