import { FC, memo } from 'react';

import { SpaceProps } from 'styled-system';

import { EMPTY_OBJECT } from 'common/constants/emptyDataStructures.const';
import { Nullable } from 'common/types/common.types';
import { ILegalAccount } from 'common/types/company.types';
import { DescriptionList, Text } from 'UI';

interface CompanyLegalInfoProps {
  hideOkved?: boolean;
  legalAccount: Nullable<ILegalAccount>;
  foreignIdentifier?: string;
}

const CompanyLegalInfo: FC<CompanyLegalInfoProps & SpaceProps> = memo(
  ({ hideOkved, legalAccount, foreignIdentifier, mb }) => {
    if (foreignIdentifier) {
      return (
        <DescriptionList dotted primary mb={mb}>
          <DescriptionList.Property label='Идентификатор'>
            {foreignIdentifier}
          </DescriptionList.Property>
        </DescriptionList>
      );
    }

    const {
      legalName,
      address,
      managerPosition,
      managerName,
      inn,
      kpp,
      okpo,
      ogrn,
      okved,
    } = legalAccount ?? (EMPTY_OBJECT as ILegalAccount);

    return (
      <DescriptionList dotted primary mb={mb}>
        <DescriptionList.Property label='Юридическое наименование'>
          {legalName}
        </DescriptionList.Property>
        <DescriptionList.Property label='Юридический адрес'>
          {address}
        </DescriptionList.Property>
        <DescriptionList.Property label='Руководитель'>
          {!managerPosition && !managerName ? (
            <Text color='text.disabled'>Не указан</Text>
          ) : (
            `${managerPosition} ${managerName}`
          )}
        </DescriptionList.Property>
        <DescriptionList.Property label='ИНН'>{inn}</DescriptionList.Property>
        <DescriptionList.Property label='КПП'>{kpp}</DescriptionList.Property>
        <DescriptionList.Property label='ОКПО'>{okpo}</DescriptionList.Property>
        <DescriptionList.Property label='ОГРН'>{ogrn}</DescriptionList.Property>
        {!hideOkved && (
          <DescriptionList.Property label='ОКВЭД'>
            {okved}
          </DescriptionList.Property>
        )}
      </DescriptionList>
    );
  },
);

export { CompanyLegalInfo };
