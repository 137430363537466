import { FC, memo } from 'react';

import { IMapCategory } from 'common/types/categories.types';
import { ViewTemplates } from 'components/characteristic-elements';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { useToggle } from 'tools/hooks';
import { Button, Checkbox, Container, Text } from 'UI';

import { CollapsePanel } from '../../../components';

interface ICategoryProps {
  isSelected: boolean;
  category: IMapCategory;
  categories: Map<string, IMapCategory>;
  level?: number;
  selectedCategories: Set<string>;
  onCategoryClick: (id: string) => void;
}

const Category: FC<ICategoryProps> = memo(
  ({
    isSelected,
    category: { id: categoryId, name: categoryName, children },
    categories,
    level = 0,
    selectedCategories,
    onCategoryClick,
  }) => {
    const [isCollapse, toggleCollapse] = useToggle(true);

    const isSelectedCategory = isSelected || selectedCategories.has(categoryId);

    return (
      <Container column gap={2}>
        <CollapsePanel>
          <Container flexGrow={1}>
            <Checkbox
              disabled={isSelected && !selectedCategories.has(categoryId)}
              ml={1}
              value={isSelectedCategory}
              onChange={() => onCategoryClick(categoryId)}
            />
            <Text truncate color='text.primary' fontWeight={600} mr={1}>
              {categoryName}
            </Text>
            {children.length > 0 && (
              <Button
                color='secondary'
                variant='string'
                onClick={toggleCollapse}
              >
                {isCollapse ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </Button>
            )}
          </Container>
        </CollapsePanel>
        {!isCollapse && children.length > 0 && (
          <ViewTemplates.LevelBody>
            <Container column gap={2}>
              {children.map(item => {
                const childCategory = categories.get(item)!;

                return (
                  <Category
                    key={childCategory.id}
                    categories={categories}
                    category={childCategory}
                    isSelected={isSelectedCategory}
                    level={level + 1}
                    selectedCategories={selectedCategories}
                    onCategoryClick={onCategoryClick}
                  />
                );
              })}
            </Container>
          </ViewTemplates.LevelBody>
        )}
      </Container>
    );
  },
);

export { Category };
