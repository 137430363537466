import {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled, { css, keyframes } from 'styled-components';

import { useOutsideRef } from 'common/hooks';
import { useAppSelector } from 'store';
import { useToggle } from 'tools/hooks';
import { Container, Tooltip } from 'UI';

import { ArchivePopup } from './components/Popup';
import {
  COMMON_STATUS_TEXT,
  IMPORT_PRODUCTS_STATUS_TEXT,
  UPLOAD_PRODUCT_STATUS_TEXT,
} from './constants';
import { ReactComponent as ArrowIcon } from './icons/arrow.svg';
import { ReactComponent as CheckSuccessIcon } from './icons/check-success.svg';
import { ArchiveStatuses, ArchiveTypes, IArchiveItem } from './types';

const bounceArrow = keyframes`
  0% {
    opacity: 1;
    transform: translateY(2px);
  }
  50% {
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(2px);
  }
`;

const showArrow = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(-13px) scaleY(0.6);
  }
  10% {
    opacity: 1;
    transform: translateY(-10px) scaleY(0.8);
  }
  20% {
    transform: translateY(-9px) scaleY(1);
  }
  100% {
    opacity: 1;
    transform: translateY(2px);
  }
`;

const centerArrow = keyframes`
  0% {
    opacity: 0.5;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(2px);
  }
`;

const showCheck = keyframes`
  0% {
    stroke-dasharray: 0, 24;
    stroke-dashoffset: 0;
  }
  70% {
    stroke-dasharray: 24, 24;
    stroke-dashoffset: 0;
  }
  100% {
    stroke-dasharray: 24, 24;
    stroke-dashoffset: -7;
  }
`;

const Arrow = styled(ArrowIcon)(
  ({ status }) => css`
    position: absolute;
    transform: translateY(-13px) scaleY(0.6);
    opacity: 0;
    animation-fill-mode: forwards, none;
    animation-duration: 1000ms, 400ms;
    animation-iteration-count: infinite, 1;

    ${status === ArchiveStatuses.start &&
    css`
      animation-name: ${bounceArrow}, ${showArrow};
      animation-delay: 950ms, 550ms;
    `}

    ${status === ArchiveStatuses.processing &&
    css`
      animation-name: ${bounceArrow}, ${centerArrow};
      animation-delay: 400ms, 0ms;
    `}

    ${status === ArchiveStatuses.error &&
    css`
      opacity: 1;
      transform: translateY(0);
    `}
  `,
);

const CheckSuccess = styled(CheckSuccessIcon)(
  ({ status }) => css`
    position: absolute;
    opacity: 1;
    stroke-dasharray: 0, 24;

    ${status === ArchiveStatuses.success &&
    css`
      animation: ${showCheck} 400ms 1 forwards;
    `}
  `,
);

const BadgeContainer = styled.div<{ status: string }>(
  ({ theme: { colors, space }, status }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${space[4]}px;
    width: ${status === ArchiveStatuses.none ? 0 : space[4]}px;
    border-radius: ${space[0]}px;
    background: ${colors.primary.main};
    transition: background 200ms ease-in, width 100ms ease-in;
    cursor: pointer;

    &:hover {
      background: ${colors.primary.dark};
    }

    ${status === ArchiveStatuses.none &&
    css`
      & svg {
        display: none;
      }
    `}

    ${status === ArchiveStatuses.error &&
    css`
      background: ${colors.error.main};

      &:hover {
        background: ${colors.error.dark};
      }
    `}

    ${status === ArchiveStatuses.success &&
    css`
      &,
      &:hover {
        background: ${colors.success.main};
      }
    `}
  `,
);

const hasErrors = (archive: IArchiveItem[]): boolean => {
  return archive.some(({ status }) => status === ArchiveStatuses.error);
};

const hasAllSuccess = (archive: IArchiveItem[]): boolean => {
  return archive.every(({ status }) => status === ArchiveStatuses.success);
};

function ArchiveBadge(): ReactElement {
  const elementRef = useRef<HTMLDivElement>(null);
  const [status, setStatus] = useState<ArchiveStatuses>(ArchiveStatuses.none);
  const statusRef = useRef(status);
  statusRef.current = status;
  const [isOpen, toggleOpen] = useToggle();
  const archives = useAppSelector(state => state.archiveManager);

  const archivesTypes = new Set(archives.map(({ type }) => type));

  const handleClosePopup = useCallback(() => {
    toggleOpen(false);
  }, [toggleOpen]);

  useOutsideRef({
    elementRef,
    onOutsideClick: handleClosePopup,
  });

  useEffect(() => {
    if (archives.length > 0) {
      if (statusRef.current === ArchiveStatuses.none) {
        setStatus(ArchiveStatuses.start);
      } else if (hasErrors(archives)) {
        setStatus(ArchiveStatuses.error);
      } else if (hasAllSuccess(archives)) {
        setStatus(ArchiveStatuses.success);
      } else if (statusRef.current !== ArchiveStatuses.start) {
        setStatus(ArchiveStatuses.processing);
      }
    } else {
      setStatus(ArchiveStatuses.none);
      handleClosePopup();
    }
  }, [archives, handleClosePopup]);

  const statusTextMap = useMemo(() => {
    if (archivesTypes.size > 1) {
      return COMMON_STATUS_TEXT;
    }

    if (
      archivesTypes.has(ArchiveTypes.uploadProduct) ||
      archivesTypes.has(ArchiveTypes.uploadStatement)
    ) {
      return UPLOAD_PRODUCT_STATUS_TEXT;
    }

    return IMPORT_PRODUCTS_STATUS_TEXT;
  }, [archivesTypes]);

  return (
    <Container ref={elementRef} display='block' position='relative'>
      <Tooltip arrow title={statusTextMap[status]}>
        <BadgeContainer status={status} onClick={toggleOpen}>
          {/* <Circle status={status} /> */}
          <Arrow status={status} />
          <CheckSuccess status={status} />
        </BadgeContainer>
      </Tooltip>
      {archives.length > 0 && (
        <ArchivePopup archives={archives} isOpen={isOpen} />
      )}
    </Container>
  );
}

export { ArchiveBadge };
