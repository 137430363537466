import { Fragment, FC } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import {
  IProperty,
  IProductEntity,
  PropertyPositionType,
} from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { propertyValidation } from 'common/utils/properties';
import { ChildrenUnionWrapper } from 'components/characteristic-elements/EditMode';
import { LINKED_PRODUCT_BY_TYPE } from 'components/products/properties/components';
import { useComposition } from 'entities/composition';
import { STUB_FUNC } from 'tools/utils';
import { Avatar, Text } from 'UI';

import { CompositionEntityButtons, LinkedProductCombobox } from './components';
import { LinkedProductAvatarWrapper } from '../../../../../styled';
import { PropertiesEdit } from '../../../../_shared/Edit';
import { IPropertiesLinkedProductProps } from '../../types';

const ErrorBlock = styled(Text)(
  ({ theme: { space, colors, fontSizes } }) => css`
    color: ${colors.error.main};
    font-size: ${fontSizes[1]}px;
    margin-bottom: ${space[2]}px;
  `,
);

interface ILinkedProductEditProps
  extends Omit<IPropertiesLinkedProductProps, 'onCopy'> {
  selectedProduct: Nullable<IProductEntity>;
}

const LinkedProductEdit: FC<ILinkedProductEditProps> = ({
  isNoName,
  selectedProduct,
  name,
  level,
  field,
  field: {
    value: property,
    value: {
      value: {
        reference: { id: product, entity: selectedProductType },
      },
      name: propertyName,
      id: propertyId,
    },
    error,
  },
  onRemove,
  onChange,
  onToggleConfirmed,
}) => {
  const { onAddToComposition, onRemoveFromComposition } = useComposition();

  const linkedObjectType =
    selectedProductType && LINKED_PRODUCT_BY_TYPE[selectedProductType];

  const isValid = propertyValidation(property);
  const valueError: object = error?.value ?? {};

  const preview = selectedProduct && (
    <LinkedProductAvatarWrapper hasImage>
      <Avatar
        letter={linkedObjectType && linkedObjectType.getName(product)}
        url={getImageURL(
          linkedObjectType && linkedObjectType.getImage(product),
        )}
      />
    </LinkedProductAvatarWrapper>
  );

  const handleTypeChange = (
    currentType: string,
    initialValues: IProperty,
  ): void => {
    onChange(name)({ ...initialValues, id: propertyId, name: propertyName });
  };

  const handleAddToComposition = (type: PropertyPositionType): void => {
    onAddToComposition({ propertyId, product: selectedProduct, type });
  };

  const handleRemoveFromComposition = (): void => {
    onRemoveFromComposition(propertyId);
  };

  return (
    <Fragment>
      <PropertiesEdit
        field={field}
        hasControls={isNoName ? false : undefined}
        header={isNoName ? STUB_FUNC.NOOP : undefined}
        isDisabledBorder={isNoName}
        isValid={isValid}
        leftAddon={isNoName ? undefined : preview}
        level={level}
        menuAddon={isNoName ? STUB_FUNC.NOOP : undefined}
        name={name}
        style={{ border: '0' }}
        unionChildren={false}
        onChangeName={onChange}
        onChangeType={handleTypeChange}
        onRemove={onRemove}
        onSubmit={onToggleConfirmed}
      >
        <ChildrenUnionWrapper unionChildren>
          <LinkedProductCombobox
            error={valueError}
            name={name}
            selectedProduct={selectedProduct}
            value={property.value}
            onChange={onChange}
            onRemoveFromComposition={handleRemoveFromComposition}
          />
        </ChildrenUnionWrapper>
        {!isNoName && (
          <CompositionEntityButtons
            propertyId={propertyId}
            selectedProduct={selectedProduct}
            onAddToComposition={handleAddToComposition}
            onRemoveFromComposition={handleRemoveFromComposition}
          />
        )}
      </PropertiesEdit>
      {!isNoName && error && error.value?.reference && (
        <ErrorBlock>{error.value.reference}</ErrorBlock>
      )}
    </Fragment>
  );
};

export { LinkedProductEdit };
