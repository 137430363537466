import styled, { css } from 'styled-components';

const iconWrapperStyle = {
  width: '34px',
  justifyContent: 'center',
  alignItems: 'center',
};

export const ParamIconWrapper = styled.div<{ isPrimary?: boolean }>(
  ({ theme: { colors }, isPrimary }) => css`
    display: flex;

    & svg {
      color: ${isPrimary ? colors.highlight[3] : colors.divider};
    }

    ${iconWrapperStyle};
  `,
);

export const ArrowIconWrapper = styled.div`
  display: none;

  ${iconWrapperStyle}
`;

export const ParamKey = styled.dt<{ widthKey: string }>(
  ({ theme: { space }, widthKey }) => css`
    position: relative;
    z-index: 0;
    display: flex;
    min-width: ${widthKey};
    margin-right: ${space[2]}px;
    background: inherit;
    word-break: break-word;

    &::after {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      height: 1px;
      margin-top: 1em;
      background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAMAAAABCAAAAAA+i0toAAAAAnRSTlMA/1uRIrUAAAAMSURBVHheY7j1/z8ABY8C2UtBe8oAAAAASUVORK5CYII=)
        0 0 repeat-x;
    }
  `,
);

export const ViewContainer = styled.div<{
  isRounded?: boolean;
  isPrimary?: boolean;
}>(
  ({
    theme: { space, colors, fontSizes, borderRadius },
    isRounded = true,
    isPrimary,
  }) => css`
    display: flex;
    padding: ${space[1]}px ${space[1]}px ${space[1]}px 0;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text.secondary};
    background: ${isPrimary ? colors.highlight[1] : colors.background};
    border-radius: ${isRounded
      ? `${borderRadius}px`
      : `${borderRadius}px ${borderRadius}px 0 0`};
    cursor: pointer;

    &:hover {
      & ${ParamIconWrapper} {
        display: none;
      }

      & ${ArrowIconWrapper} {
        display: flex;
      }
    }
  `,
);

export const LimitedWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    max-width: 460px;
    font-weight: 600;
    padding-right: ${space[2]}px;

    & > div {
      max-width: 100%;
    }

    & svg,
    &:hover svg {
      color: ${colors.primary.main};
    }
  `,
);

export const ArrayChildrenWrapper = styled.div<{
  isCollapse: boolean;
  isFilling: boolean;
}>(
  ({ theme: { space, colors, borderRadius }, isCollapse, isFilling }) => css`
    display: ${isCollapse ? 'none' : 'flex'};
    flex-wrap: wrap;
    gap: ${space[1]}px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

    ${isFilling &&
    css`
      padding: ${space[2]}px ${space[4]}px;
      background: ${colors.background};
      border: none;
    `}
  `,
);

export const GroupChildrenWrapper = styled.div<{ isCollapse: boolean }>(
  ({ theme: { space, colors }, isCollapse }) => css`
    display: ${isCollapse ? 'none' : 'flex'};
    flex-direction: column;
    gap: ${space[2]}px;
    margin-left: 17px;
    padding-left: 17px;
    border-left: 1px solid ${colors.divider};
    background: ${colors.white};
  `,
);

export const Key = styled.span(
  ({ theme: { space } }) => css`
    position: relative;
    z-index: 1;
    padding-right: ${space[1]}px;
    background: inherit;
  `,
);
