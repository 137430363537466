import { FC, useEffect, useLayoutEffect, useRef, useState } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Nullable } from 'common/types/common.types';
import { IImage, MediaFieldsTypes } from 'common/types/media.types';
import { ProductSections } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { checkIsStandardFile } from 'common/utils/standard';
import { SkeletonPreviewGallery } from 'components/products/SkeletonProductPage';
import { Model3dIcon } from 'resources/icons/18';
import { ArrowLeft1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { ZoomInLargeIcon } from 'resources/icons/24';
import { AvatarBlankIcon } from 'resources/icons/scale';
import { DescriptionIcon } from 'resources/other';
import { StandardIcon } from 'resources/other-16';
import { EmptyImageIcon } from 'resources/placeholders';
import { Text, Tooltip, IconButton } from 'UI';

import { get3DModelImage } from '../../../3d-model/utils';
import { ImagesWithTypes } from '../types';

const NavButton = styled.button.attrs(() => ({ type: 'button' }))<{
  direction: string;
}>(
  ({ theme: { borderRadius, space }, direction }) => css`
    position: absolute;
    top: 50%;
    ${direction}: ${space[1]}px;
    display: none;
    justify-content: center;
    align-items: center;
    width: 34px;
    height: 34px;
    border-radius: ${borderRadius}px;
    background-color: rgba(255, 255, 255, 0.7);
    cursor: pointer;
    transform: translateY(-50%);
    transition: background 200ms ease-in-out;
    z-index: 1;

    & svg {
      height: 20px;
      width: 18px;
    }

    &:disabled {
      display: none !important;
    }
  `,
);

const ImageContainer = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    padding-bottom: 100%;
    margin-bottom: ${space[2]}px;

    &:hover > ${NavButton} {
      display: flex;
    }
  `,
);

const MainImageWrapper = styled.img<{
  isLoaded: boolean;
}>(
  ({ isLoaded }) => css`
    position: absolute;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    object-fit: scale-down;
    opacity: ${isLoaded ? 1 : 0};
    cursor: zoom-in;
    transition: opacity 200ms ease-in;
  `,
);

const Placeholder = styled.div<{ isEmpty?: boolean }>(
  ({ isEmpty, theme: { colors } }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border-radius: 8px;

    ${isEmpty &&
    css`
      background: ${colors.highlight[1]};
    `}
  `,
);

const StyledIconWrapper = styled.div<SpaceProps>(
  ({ theme: { colors, borderRadius } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border-radius: ${borderRadius}px;
    background-color: ${colors.blackout};

    & svg {
      color: ${colors.white};
    }

    ${styledSpace}
  `,
);

const IconsWrapper = styled.div(
  ({ theme: { space } }) => css`
    position: absolute;
    top: ${space[0]}px;
    right: ${space[0]}px;
    display: flex;
  `,
);

const StyledIconButton = styled(IconButton).attrs(() => ({
  title: 'Открыть в галерее',
}))(
  ({ theme: { space, colors } }) => css`
    position: absolute;
    bottom: ${space[0]}px;
    right: ${space[0]}px;

    & svg {
      color: ${colors.text.secondary};
    }
  `,
);

interface IPreviewImage {
  image: Nullable<IImage>;
}

/**
 * Отображаем только одну фотографию
 */
const PreviewImage: FC<IPreviewImage> = ({ image }) => {
  const [isLoading, setLoading] = useState(!!image && !!image.uri);

  return (
    <SkeletonPreviewGallery isLoading={isLoading}>
      <ImageContainer>
        {image ? (
          <MainImageWrapper
            alt={image.description ?? image.filename}
            isLoaded={!isLoading}
            src={getImageURL(image, { size: 'l' })}
            onLoad={() => setLoading(false)}
          />
        ) : (
          <Placeholder isEmpty>
            <EmptyImageIcon height={94} width={99} />
          </Placeholder>
        )}
      </ImageContainer>
    </SkeletonPreviewGallery>
  );
};

interface IMainImageProps {
  selectedImageWithType: ImagesWithTypes[number];
  isShowArrows: boolean;
  onOpenGallery: VoidFunction;
  handlePrevImage: VoidFunction;
  handleNextImage: VoidFunction;
}

const MainImage: FC<IMainImageProps> = ({
  selectedImageWithType: { type: selectedImageType, image: selectedImage },
  isShowArrows,
  onOpenGallery,
  handlePrevImage,
  handleNextImage,
}) => {
  const [isSelectedImageLoaded, setSelectedImageLoaded] = useState(false);
  const selectedImageURL = getImageURL(selectedImage, { size: 'l' });
  const [imageSrc, setImageSrc] = useState<string>(selectedImageURL ?? '');
  const isLoadedRef = useRef(isSelectedImageLoaded);
  isLoadedRef.current = isSelectedImageLoaded;

  const is3DModel = selectedImageType === MediaFieldsTypes.model3D;

  useLayoutEffect(() => {
    if (isLoadedRef.current) setSelectedImageLoaded(false);
  }, []);

  useEffect(() => {
    if (is3DModel) {
      setSelectedImageLoaded(false);

      const getImageData = async (): Promise<void> => {
        const { src } = await get3DModelImage(selectedImage, 324);
        setImageSrc(src);
      };

      getImageData().then(() => setSelectedImageLoaded(true));
    } else {
      setImageSrc(selectedImageURL!);
    }
  }, [is3DModel, selectedImage, selectedImageType, selectedImageURL]);

  if (!selectedImage) {
    return (
      <ImageContainer>
        <Placeholder isEmpty>
          <AvatarBlankIcon size={82} />
        </Placeholder>
      </ImageContainer>
    );
  }

  return (
    <SkeletonPreviewGallery isLoading={!isSelectedImageLoaded}>
      <ImageContainer>
        <NavButton
          direction='left'
          disabled={isShowArrows}
          onClick={handlePrevImage}
        >
          <ArrowLeft1pxIcon color='text.secondary' />
        </NavButton>
        <MainImageWrapper
          alt={selectedImage.description ?? selectedImage.filename}
          data-image-id={selectedImage.id}
          id={ProductSections.gallery}
          isLoaded={isSelectedImageLoaded}
          src={imageSrc}
          onClick={is3DModel ? undefined : onOpenGallery}
          onLoad={() => setSelectedImageLoaded(true)}
        />
        <NavButton
          direction='right'
          disabled={isShowArrows}
          onClick={handleNextImage}
        >
          <ArrowRight1pxIcon color='text.secondary' />
        </NavButton>
        <IconsWrapper>
          {checkIsStandardFile(selectedImage) && <StandardIcon mr={1} />}
          {is3DModel && (
            <StyledIconWrapper mr={0}>
              <Model3dIcon />
            </StyledIconWrapper>
          )}
          <Tooltip
            arrow
            maxWidth={240}
            placement='left'
            title={
              <>
                <Text fontWeight={600}>{selectedImage.filename}</Text>
                {selectedImage.description}
              </>
            }
          >
            <StyledIconWrapper>
              <DescriptionIcon />
            </StyledIconWrapper>
          </Tooltip>
        </IconsWrapper>
        {is3DModel && (
          <StyledIconButton onClick={onOpenGallery}>
            <ZoomInLargeIcon />
          </StyledIconButton>
        )}
      </ImageContainer>
    </SkeletonPreviewGallery>
  );
};

export { MainImage, PreviewImage };
