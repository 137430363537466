import {
  AriaRole,
  CSSProperties,
  FC,
  forwardRef,
  MouseEvent,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
} from 'react';

import { createPopper, Placement, Instance } from '@popperjs/core';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';
import {
  space,
  zIndex as styledZIndex,
  width,
  SpaceProps,
  ZIndexProps,
  WidthProps,
} from 'styled-system';

import { useForkRef } from 'tools/hooks';

const Paper = styled.div<
  { open: boolean; height?: number } & SpaceProps & ZIndexProps & WidthProps
>(
  ({ theme: { colors, shadows, borderRadius, zIndex }, open, height }) => css`
    position: absolute;
    z-index: ${zIndex.sideMenu - 1};
    min-width: 130px;
    padding: 8px 0;
    margin: 0;
    top: 0;
    border-radius: ${borderRadius}px;
    background: ${colors.white};
    box-shadow: ${shadows[1]};
    visibility: ${open ? 'visible' : 'hidden'};
    outline: none;

    ${height &&
    css`
      max-height: ${height}px;
      overflow-y: auto;
    `}

    ${space}
    ${width}
    ${styledZIndex}
  `,
);

interface IDropdownProps {
  className?: string;
  style?: CSSProperties;
  open: boolean;
  role?: AriaRole;
  keepMounted?: boolean;
  disablePortal?: boolean;
  height?: number;
  offset?: [number, number];
  placement?: Placement;
  anchorEl: HTMLElement | null;
  children: ReactNode;
  onRequestClose?: (event: MouseEvent) => void;
}

const InnerDropdown: FC<
  IDropdownProps & SpaceProps & WidthProps & ZIndexProps
> = (
  {
    className,
    style,
    open = false,
    keepMounted = false,
    disablePortal,
    height,
    role,
    offset = [0, 4],
    children,
    anchorEl = null,
    placement = 'bottom-start',
    onRequestClose,
    ...other
  },
  ref,
) => {
  const paperRef = useRef<HTMLDivElement | null>(null);
  const popperRef = useRef<Instance | null>(null);
  const unionRef = useForkRef(ref, paperRef);

  const handleOutsideClick = useCallback(
    (event): void => {
      if (!open || !anchorEl || !paperRef.current || !onRequestClose) return;

      if (
        document.documentElement.contains(event.target) &&
        !anchorEl.contains(event.target) &&
        !paperRef.current.contains(event.target)
      ) {
        onRequestClose(event);
      }
    },
    [open, anchorEl, onRequestClose],
  );

  const handleOpen = useCallback((): void => {
    if (!open || !anchorEl || !paperRef.current) return;

    if (popperRef.current) {
      popperRef.current.destroy();
      popperRef.current = null;
    }

    popperRef.current = createPopper(anchorEl, paperRef.current, {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: { offset },
        },
        {
          name: 'preventOverflow',
          options: {
            boundariesElement: 'window',
          },
        },
      ],
    });
  }, [open, anchorEl, placement, offset]);

  const handleClose = useCallback((): void => {
    if (popperRef.current) {
      popperRef.current.destroy();
      popperRef.current = null;
    }
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [handleOutsideClick]);

  useEffect(() => {
    if (open && anchorEl) {
      handleOpen();
    } else if (!open) {
      handleClose();
    }
  }, [open, anchorEl, handleOpen, handleClose]);

  if (!keepMounted && !open) return null;

  const anchorWidth = anchorEl ? anchorEl.offsetWidth : undefined;

  const element = (
    <Paper
      ref={unionRef}
      className={className}
      height={height}
      open={open}
      role={role}
      style={style}
      width={anchorWidth}
      {...other}
    >
      {children}
    </Paper>
  );

  return disablePortal
    ? element
    : ReactDOM.createPortal(element, document.body);
};

export const Dropdown = forwardRef(InnerDropdown);
export { IDropdownProps };
