import { ReactElement, ReactNode } from 'react';

import styled, { css, keyframes } from 'styled-components';

import { useAnimationModal, useModalContext } from 'entities/modals';
import { CloseMediumIcon } from 'resources/icons/18';
import { Modal } from 'UI';

const transitionAnimation = keyframes`
  35% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-13%);
  }
  85% {
    transform: translateX(91%);
  }
  96% {
    transform: translateX(87%);
  }
  100% {
    transform: translateX(91%);
  }
`;

const Overlay = styled(Modal.Overlay)(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: center;
    padding: ${space[4]}px 86px;

    @media (max-width: 1228px) {
      padding-left: ${space[2]}px;
      padding-right: 70px;
      min-width: 1140px;
    }
  `,
);

const CloseButton = styled.button(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    width: 70px;
    height: 70px;
    transition: background 200ms ease-in-out;

    &:hover {
      background: ${colors.text.secondary};
      opacity: 0.9;
    }
  `,
);

const Dialog = styled.div.attrs(() => ({
  role: 'dialog',
}))<{ startAnim?: boolean }>(
  ({ theme: { space, colors }, startAnim }) => css`
    position: relative;
    display: flex;
    min-height: 650px;
    width: 100%;
    max-width: 1388px;
    min-width: 1056px;
    border-radius: ${space[3]}px;
    background: ${colors.white};
    overflow: hidden;

    &:after {
      position: absolute;
      content: '';
      height: 100%;
      width: 110%;
      background: ${colors.istock};
      transform: translateX(-100%);

      ${startAnim &&
      css`
        animation: ${transitionAnimation} 1000ms;
      `}
    }
  `,
);

interface FullModalProps {
  startAnim?: boolean;
  children: ReactNode;
  onAnimationEnd?: () => void;
}

function FullModal({
  startAnim,
  children,
  onAnimationEnd,
}: FullModalProps): ReactElement {
  const { handleModalClose } = useModalContext();
  const { isModalVisible } = useAnimationModal();

  return (
    <Overlay style={{ opacity: isModalVisible ? 1 : 0 }}>
      <CloseButton onClick={handleModalClose}>
        <CloseMediumIcon color='white' size={22} />
      </CloseButton>
      <Dialog startAnim={startAnim} onAnimationEnd={onAnimationEnd}>
        {children}
      </Dialog>
    </Overlay>
  );
}

export { FullModal };
