import { Fragment } from 'react';

import {
  LIFE_STATE_NAMES,
  LIFE_STATE_COLORS,
  PRODUCT_CATEGORY_TEXT,
  PRODUCT_ENTITY_TO_TEXT,
} from 'common/constants/products.const';
import { Nullable } from 'common/types/common.types';
import {
  IProductStandard,
  IProduct,
  ProductEntity,
} from 'common/types/product.types';
import { LINKED_PRODUCT_BY_TYPE } from 'components/products/properties/components';
import { MainProperties } from 'components/products/properties/page';
import { StandardIcon } from 'resources/other-16';
import { Badge, Container, Text } from 'UI';
import { ItemToStringAndOptionsType } from 'UI/Inputs';

import { RightAddon, SelectItem } from './styled';

const typeDefinition = {
  Product: 'product',
  ProductStandard: 'standard',
  Assortment: 'assortment',
};

const BADGE_STYLES = { background: 'transparent', lineHeight: '12px' };

export const itemToString: ItemToStringAndOptionsType = (
  item: Nullable<IProduct | IProductStandard>,
  { isOption }: { isOption?: boolean } = {},
) => {
  if (!item) return undefined;

  const isProduct = 'name' in item;
  const linkedObjectType =
    LINKED_PRODUCT_BY_TYPE[typeDefinition[item['@type']]];

  if (isOption) {
    return (
      <SelectItem>
        <Container width='100%'>
          <Text truncate mb='2px' mr={1}>
            {linkedObjectType.getName(item) || 'Не указано'}
          </Text>
          <RightAddon>
            {isProduct ? (
              <Fragment>
                <Badge
                  dot={LIFE_STATE_COLORS[item.lifeState]}
                  label={LIFE_STATE_NAMES[item.lifeState]}
                  mr={1}
                  style={BADGE_STYLES}
                  type={Badge.TYPES.DEFAULT}
                />
                <MainProperties hasCopyUin={false} product={item} />
              </Fragment>
            ) : (
              <Fragment>
                <StandardIcon mr={1} />
                <MainProperties
                  hasCopyUin={false}
                  product={{ ...item, mainProperties: [] } as any}
                />
              </Fragment>
            )}
          </RightAddon>
        </Container>
        <Text color='text.secondary' fontSize={2}>
          {isProduct
            ? PRODUCT_CATEGORY_TEXT[item.category]
            : PRODUCT_ENTITY_TO_TEXT[ProductEntity.product]}
        </Text>
      </SelectItem>
    );
  }

  return linkedObjectType.getName(item);
};
