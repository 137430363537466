import { FC, memo } from 'react';

import styled from 'styled-components';

import { AddSmallIcon } from 'resources/icons/12';
import { AddMediumIcon } from 'resources/icons/18';
import { CompositionType, ProductKind } from 'common/types/product.types';
import { useModalContext } from 'entities/modals';
import { useReform, useWatch } from 'reform';
import { Button, ButtonRounded, ContextMenu, ContextMenuItem, Divider, Tooltip, useContextMenu } from 'UI';

import { COMPOSITION_MODAL_BY_TYPE, COMPOSITIONS_MODALS } from '../constants';
import { checkIsSectionDisabled, isLimitReached } from '../utils';

const StyledButton = styled(Button)`
  @media (max-width: 1480px) {
    font-size: 0;
    color: transparent;
    width: 34px;
    min-width: inherit;
    padding: 0;

    & * {
      margin: 0;
    }
  }
`;

const TEXT_BUTTON_BY_TYPE = {
  [CompositionType.equipment]: 'Комплектующий элемент',
  [CompositionType.nodes]: 'Узел',
  [CompositionType.materials]: 'Материал',
  [CompositionType.workPiece]: 'Заготовку',
};

interface IAddItemToCompositionProps {
  disabled?: boolean;
  hasComposition?: boolean;
  productKind: ProductKind;
}

const AddItemToComposition: FC<IAddItemToCompositionProps> = memo(
  ({ disabled, hasComposition, productKind }) => {
    const { handleModalOpen } = useModalContext();
    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();
    const composition = useWatch({ fieldName: 'composition' });
    const { setFieldValue } = useReform();

    const isDetail = productKind === ProductKind.detail;
    const disabledComposition =
      hasComposition &&
      Object.entries(CompositionType).every(([type, value]) =>
        isLimitReached(type, value),
      );
    const buttonProps = {
      disabled: disabledComposition || disabled,
      onClick: handleContextMenuOpen,
    };
    const availableSections = [];

    const handleItemClick = (type?: CompositionType): void => {
      const modalKey = type
        ? COMPOSITION_MODAL_BY_TYPE[type]
        : COMPOSITIONS_MODALS.ADD_SLOT;

      handleModalOpen(modalKey, {
        productKind,
        availableSections,
        onPush: value => {
          // поле _compositionType указывается в модалке добавления слота
          // _compositionType - это раздел состава, куда будет добавляться слот
          const currentType = type ?? value._compositionType;
          const itemsLength = composition[currentType]?.items.length || 0;
          const fieldName = `composition.${currentType}.items[${itemsLength}]`;

          setFieldValue(fieldName, value);
        },
      });
    };

    return (
      <>
        <Tooltip
          hint
          pointer
          placement='bottom'
          title={disabledComposition && 'Состав заполнен'}
        >
          {hasComposition ? (
            <StyledButton {...buttonProps} color='info' variant='filled'>
              <AddMediumIcon color='primary.main' mr={1} size={18} /> Добавить
            </StyledButton>
          ) : (
            <ButtonRounded mx='auto' pl={1} {...buttonProps}>
              <AddSmallIcon color='white' mr={1} /> Добавить
            </ButtonRounded>
          )}
        </Tooltip>
        <ContextMenu
          anchorEl={anchorEl}
          disablePortal={false}
          open={open}
          placement={hasComposition ? 'bottom-start' : 'bottom'}
          onRequestClose={handleContextMenuClose}
        >
          {Object.values(CompositionType).map(type => {
            const { disabled: disabledItem, text } = checkIsSectionDisabled({
              composition,
              type,
              isDetail,
            });
            // доступные секции для добавления для модалки Слота
            availableSections[type] = { disabled: disabledItem, text };

            return (
              <Tooltip key={type} hint pointer placement='bottom' title={text}>
                <div>
                  <ContextMenuItem
                    disabled={disabledItem}
                    text={TEXT_BUTTON_BY_TYPE[type]}
                    onClick={() => handleItemClick(type)}
                  />
                </div>
              </Tooltip>
            );
          })}
          <Divider />
          <ContextMenuItem
            text='Слот под товар'
            onClick={() => handleItemClick()}
          />
        </ContextMenu>
      </>
    );
  },
);

export { AddItemToComposition };
