import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { Paper } from 'UI';

export const MainInfoContainer = styled(Paper)(
  ({ theme: { space } }) => css`
    & > * {
      margin-bottom: ${space[2]}px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    ${styledSpace};
  `,
);

export const Placeholder = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    padding: ${space[4]}px 0;
    border: 1px solid ${colors.divider};
    background-color: ${colors.white};
    color: ${colors.text.secondary};
    text-align: center;
    font-size: ${fontSizes[5]}px;

    ${styledSpace};
  `,
);

export const DetailWrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    padding: ${space[2]}px 0 ${space[2]}px ${space[7]}px;
    background: ${colors.highlight[0]};
  `,
);

export const RowCount = styled.div(
  ({ theme: { colors, fontSizes } }) => css`
    width: 30px;
    border-bottom: 1px solid ${colors.divider};
    font-size: ${fontSizes[4]}px;
    text-align: center;
  `,
);

export const FieldWrapper = styled.div(
  ({ theme: { colors } }) => css`
    flex: 0 0 315px;
    border: 1px solid ${colors.text.primary};
    border-top-width: 0;
  `,
);

export const TableHeader = styled.div(
  ({ theme: { colors } }) => css`
    height: calc(100% - 34px);
    border: 1px solid ${colors.text.primary};
  `,
);
