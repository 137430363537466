import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
  forwardRef,
  FC,
} from 'react';

import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  SERVICE_PANEL_WIDTH,
  SERVICE_PANEL_ANIMATION_DURATION,
} from 'common/constants/service-panel.const';
import { useOutsideClick, useRequest } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { DocumentsSections } from 'common/types/media.types';
import { IForbidden } from 'common/types/permissions.types';
import { transformApiErrors } from 'common/utils';
import { DocumentsMultiply } from 'components/media/documents';
import {
  DocumentField,
  ISettingsDropZone,
} from 'components/media/documents/form/components';
import { useNotify } from 'entities/notify';
import { isPanelCollapsedSelector } from 'entities/service-panel';
import { SupportSections } from 'nomenclature/support';
import { ISupportReason, ISupportSection } from 'nomenclature/support/types';
import { Field, IReform, Reform, useReform, useWatch } from 'reform';
import { CommentIcon } from 'resources/icons/18';
import { CloseLargeIcon, SupportIcon } from 'resources/icons/24';
import { IstockSymbolIcon } from 'resources/icons/scale';
import { useActions, useAppSelector } from 'store';
import { itemTo } from 'tools/utils';
import {
  Text,
  Button,
  Notice,
  Tooltip,
  FormLabel,
  SelectField,
  TextAreaField,
  ButtonFloating,
  TextInputField,
  MultiSelectField,
} from 'UI';

import {
  createIssues,
  fetchIssueSections,
} from '../../../../src/features/support/api';
import { getChatLink } from '../../../common/utils';

const SupportButton = styled(ButtonFloating)<{
  isHidden: boolean;
  isMovedLeft: boolean;
  isOpenForm: boolean;
}>(
  ({
    theme: { colors, space, zIndex },
    isHidden,
    isMovedLeft,
    isOpenForm,
  }) => css`
    position: fixed;
    right: ${space[2]}px;
    bottom: ${space[3]}px;
    display: none;
    background: ${isOpenForm ? colors.text.primary : colors.highlight[3]};
    transition: right ${SERVICE_PANEL_ANIMATION_DURATION} ease-in-out;
    z-index: ${zIndex.sideMenu - 1};

    &:hover {
      background: ${isOpenForm ? colors.text.primary : colors.highlight[3]};
    }

    ${!isHidden &&
    css`
      display: flex;
      justify-content: center;
      align-items: center;
    `}

    ${isMovedLeft && `right: ${space[2] + SERVICE_PANEL_WIDTH}px`}
  `,
);

const IconWrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${space[4]}px;
    height: ${space[4]}px;
    margin-right: ${space[2]}px;
    background: ${colors.highlight[1]};
    border-radius: 50%;
  `,
);

const Form = styled.form(
  ({ theme: { zIndex, colors, borderRadius, space, shadows } }) => css`
    position: fixed;
    bottom: 83px;
    right: ${space[2]}px;
    z-index: ${zIndex.modal};
    width: 488px;
    background: ${colors.white};
    box-shadow: ${shadows[2]};
    border-radius: ${borderRadius}px;
  `,
);

const Header = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: flex;
    align-items: center;
    height: 78px;
    padding-left: ${space[4]}px;
    font-weight: 500;
    font-size: ${fontSizes[6]}px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

const Body = styled.div(
  ({ theme: { space } }) => css`
    height: 474px;
    padding: ${space[3]}px ${space[4]}px;
    overflow-y: auto;
  `,
);

const FooterContainer = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: flex;
    align-items: center;
    height: 78px;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.background};
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
  `,
);

const List = styled.ul(
  ({ theme: { space } }) => css`
    margin: 0;
    padding-left: ${space[0]}px;
    list-style: none;
  `,
);

const LIMIT = 2;
const LABEL = 'Прикрепленные файлы';
const INN_REASON_ID = 25; // Я являюсь владельцем компании с указанным ИНН
const OWN_COMPANY_REASON_ID = 78; // Эта компания принадлежит мне
const CHAT_LINK = getChatLink();
const SETTING_DROP_ZONE: ISettingsDropZone = {
  maxSize: 10485760, // 10mb
};
const FORBIDDEN: IForbidden['forbidden'] = {
  /** Запрещаем задавать описание документа */
  edit: true,
};
const INITIAL_VALUE = {
  reason: null,
  clarifications: [],
  title: '',
  description: '',
  files: [],
  email: '',
};

const ReasonFields: FC<{ reasons?: ISupportReason[] }> = memo(({ reasons }) => {
  const { setFieldValue } = useReform();
  const reason = useWatch({ fieldName: 'reason' }) ?? {};
  const hasClarificationField = reason.clarifications?.length > 0;

  useEffect(() => {
    if (!hasClarificationField) {
      setFieldValue('clarifications', []);
    }
  }, [hasClarificationField, setFieldValue]);

  return (
    <Fragment>
      <Field
        required
        component={SelectField}
        itemToKey={itemTo('id')}
        itemToString={itemTo('title')}
        label='Причина обращения'
        mb='12px'
        name='reason'
        options={reasons}
        placeholder='Выберите причину'
      />
      {hasClarificationField && (
        <Field
          component={MultiSelectField}
          label={reason.clarificationTitle}
          limit={5}
          mb='12px'
          name='clarifications'
          options={reason.clarifications}
          placeholder='Добавьте причину'
        />
      )}
    </Fragment>
  );
});

const DocumentsField = forwardRef<HTMLDivElement, any>(
  ({ field: { value, setValue } }, ref) => {
    const currentReason = useWatch({ fieldName: 'reason' }) ?? {};

    const canUploadCount = LIMIT - value.length;

    const isOwnCompany =
      currentReason?.id === INN_REASON_ID ||
      currentReason?.id === OWN_COMPANY_REASON_ID;

    return (
      <Fragment>
        <FormLabel ref={ref}>{LABEL}</FormLabel>
        <DocumentsMultiply
          hasDownloadInput
          canUploadCount={canUploadCount}
          cardComponent={DocumentField}
          documents={value}
          fileSection={DocumentsSections.issueFiles}
          forbidden={FORBIDDEN}
          gap={1}
          name='files'
          setDocuments={setValue}
          settingsDropZone={SETTING_DROP_ZONE}
        />
        {!!canUploadCount && (
          <Fragment>
            {isOwnCompany ? (
              <Text color='text.secondary' fontSize={1} mt={0}>
                Прикрепите файлы, подтверждающее ваше владение компанией:
                <br />
                <List>
                  <li>
                    • Выписка из ЕГРЮЛ/ЕГРИП — не старше 5 календарных дней с
                    момента выписки на день подачи документов.
                  </li>
                  <li>
                    • Скан заверенной копии свидетельства о регистрации или
                    листа записи ЕГРЮЛ/ЕГРИП — с подписью генерального директора
                    или доверенного лица, печатью организации и штампом «Копия
                    заверена».
                  </li>
                </List>
              </Text>
            ) : (
              <Text color='text.secondary' fontSize={1} mt={0}>
                Прикрепите файлы, например, снимок экрана с ошибкой, чтобы
                ускорить решение проблемы.
              </Text>
            )}
          </Fragment>
        )}
      </Fragment>
    );
  },
);

interface IFooterProps {
  isSubmitting: boolean;
  isLoading: boolean;
  handleSubmit: VoidFunction;
}

const Footer: FC<IFooterProps> = memo(
  ({ isSubmitting, isLoading, handleSubmit }) => {
    const disabled = useWatch({
      selector: ({ files }) => files.some(file => file instanceof File),
    });

    return (
      <FooterContainer>
        <Button
          as='a'
          color='secondary'
          href={CHAT_LINK}
          mr={1}
          size='l'
          target='_blank'
          variant='outlined'
          width='100%'
        >
          <CommentIcon mr={1} />
          Перейти в чат
        </Button>
        <Button
          disabled={isSubmitting || isLoading || disabled}
          size='l'
          width='100%'
          onClick={handleSubmit}
        >
          Отправить сообщение
        </Button>
      </FooterContainer>
    );
  },
);

interface ISupportIssuesReformProps {
  isOpenForm?: boolean;
  sectionKey?: string;
  onSubmit: VoidFunction;
}

// может вызываться из модалки
const SupportIssuesReform: FC<ISupportIssuesReformProps> = memo(
  ({ isOpenForm, sectionKey, onSubmit }) => {
    const notify = useNotify();
    const { pathname } = useLocation();
    const reformRef = useRef<IReform>(null);
    const bodyRef = useRef<HTMLDivElement>(null);
    const identity = useAppSelector(state => state.identity);

    const [sections, setSections] = useState<Nullable<ISupportSection[]>>(null);
    const [currentSection, setCurrentSection] = useState<ISupportSection>();
    const [isSubmitting, setSubmitting] = useState(false);

    const userEmail = identity?.email ?? '';

    useEffect(() => {
      if (sections) {
        const currentKey = sectionKey ?? SupportSections.other;
        setCurrentSection(sections.find(section => section.key === currentKey));
      }
    }, [sectionKey, sections]);

    useEffect(() => {
      reformRef.current!.reset();
      reformRef.current!.setFieldValue('email', userEmail);
    }, [pathname, userEmail]);

    useEffect(() => {
      if (isOpenForm) {
        bodyRef.current?.scrollTo({ top: 0 });
      }
    }, [isOpenForm]);

    const { isLoading } = useRequest(fetchIssueSections, [], {
      onSuccess: ({ member }) => {
        setSections(member);
      },
    });

    const handleSubmit = useCallback(() => {
      setSubmitting(true);
      const values = reformRef.current!.getData();

      const transformValues = {
        ...values,
        reason: values.reason?.title ?? '',
        section: currentSection?.['@id'],
      };

      createIssues(transformValues).then(
        () => {
          setSubmitting(false);
          notify.success('Обращение отправлено.');
          reformRef.current!.reset();
          reformRef.current!.setFieldValue('email', userEmail);

          if (onSubmit) onSubmit();
        },
        error => {
          setSubmitting(false);
          reformRef.current!.setErrors(transformApiErrors(error));
        },
      );
    }, [currentSection, userEmail, notify, onSubmit]);

    return (
      <Reform ref={reformRef} initialValues={INITIAL_VALUE}>
        <Header>
          <IconWrapper>
            <SupportIcon color='primary.main' />
          </IconWrapper>
          Служба поддержки
        </Header>
        <Body ref={bodyRef}>
          <Notice mb={3} type={Notice.TYPES.DEFAULT}>
            Ответ на Ваше обращение будет дан в чате техподдержки.
          </Notice>
          {!isLoading && (
            <Fragment>
              <ReasonFields reasons={currentSection?.reasons} />
              <Field
                required
                component={TextInputField}
                hasErrorZIndex={false}
                label='Заголовок'
                mb='12px'
                name='title'
                placeholder='Введите заголовок обращения'
              />
              <Field
                required
                component={TextAreaField}
                label='Текст обращения'
                mb={2}
                name='description'
                placeholder='Введите текст обращения'
              />
              {!!identity && <Field component={DocumentsField} name='files' />}
              <Field
                required
                component={TextInputField}
                hasErrorZIndex={false}
                label='Email для обратной связи'
                mt={3}
                name='email'
                placeholder='email@example.com'
              />
            </Fragment>
          )}
        </Body>
        <Footer
          handleSubmit={handleSubmit}
          isLoading={isLoading}
          isSubmitting={isSubmitting}
        />
      </Reform>
    );
  },
);

// работает с редаксом
const SupportIssues: FC = memo(() => {
  const isServicePanelCollapsed = useAppSelector(isPanelCollapsedSelector);
  const supportButtonRef = useRef<HTMLButtonElement>(null);
  const { toggleSupportForm } = useActions();

  const { hideButton, isOpenForm, sectionKey } = useAppSelector(
    state => state.support,
  );

  const { formRef } = useOutsideClick({
    initialOpen: false,
    isOpenForm,
    isClose: target => !supportButtonRef.current!.contains(target),
    onToggleOpen: toggleSupportForm,
  });

  const handleToggleSupportForm = useCallback(() => {
    toggleSupportForm(false);
  }, [toggleSupportForm]);

  return (
    <Fragment>
      <Tooltip arrow placement='left' title='Служба поддержки'>
        <SupportButton
          ref={supportButtonRef}
          isHidden={hideButton}
          isMovedLeft={!isServicePanelCollapsed}
          isOpenForm={isOpenForm}
          onClick={toggleSupportForm as any}
        >
          {isOpenForm ? (
            <CloseLargeIcon color='white' />
          ) : (
            <IstockSymbolIcon size={46} />
          )}
        </SupportButton>
      </Tooltip>
      <Form ref={formRef} style={{ display: isOpenForm ? 'block' : 'none' }}>
        <SupportIssuesReform
          isOpenForm={isOpenForm}
          sectionKey={sectionKey}
          onSubmit={handleToggleSupportForm}
        />
      </Form>
    </Fragment>
  );
});

export { SupportIssues, SupportIssuesReform };
