import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Container } from 'UI';

interface IStyledContainerProps {
  zIndex: number | string;
  drawBorder?: boolean;
}

export const alwaysStyledForContainer = css<IStyledContainerProps>(
  ({ theme: { colors }, zIndex, drawBorder }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    background: ${colors.white};

    ${drawBorder &&
    css`
      border-left: 1px solid ${colors.divider};
    `}

    ${zIndex &&
    css`
      z-index: ${zIndex};
    `}
  `,
);

export const HintContainer = styled(Container)<IStyledContainerProps>`
  position: relative;
  flex: 0 0 0;
  min-width: 0;
  overflow: hidden;
  transition: flex 200ms ease-in-out;

  ${alwaysStyledForContainer};
`;

export const Content = styled.div<SpaceProps & { width?: number }>(
  ({ theme: { fontSizes }, width = 400 }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
    width: ${width}px;
    font-size: ${fontSizes[5]}px;
    overflow-y: auto;
    overflow-x: hidden;

    ${styledSpace};
  `,
);
