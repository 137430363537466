import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { InfoIcon, RecommendationMediumBoldIcon } from 'resources/icons/18';
import { Switch } from 'UI';

export const MasterPanelItem = styled.label<{ isDisabled?: boolean }>(
  ({ isDisabled, theme: { space, colors, fontSizes, borderRadius } }) => css`
    display: flex;
    align-items: center;
    min-height: 34px;
    padding: 0 ${space[0]}px 0 ${space[1]}px;
    margin-bottom: 0;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text.secondary};
    border-radius: ${borderRadius}px;
    transition: 0.2s ease-in-out all;

    ${!isDisabled &&
    css`
      cursor: pointer;
      &:hover {
        color: ${colors.text.primary};
        background: ${colors.highlight[0]};
      }
    `}

    ${styledSpace};
  `,
);

export const StyledSwitch = styled(Switch)(
  ({ theme: { space, colors } }) => css`
    margin-left: ${space[4]}px;

    & label {
      margin-right: ${space[2]}px;
      color: ${colors.text.primary};
      cursor: pointer;
    }
  `,
);

export const StyledRecommendationIcon = styled(RecommendationMediumBoldIcon)(
  ({ theme: { colors } }) => css`
    & svg {
      color: ${colors.primary.main};
    }
  `,
);

export const TabPanel = styled.div(
  ({ theme: { space, colors, shadows, zIndex } }) => css`
    position: sticky;
    z-index: ${zIndex.header};
    top: 0;
    display: flex;
    justify-content: center;
    height: ${space[4]}px;
    margin-bottom: 12px;
    border-radius: 20px;
    background: ${colors.white};
    box-shadow: ${shadows[1]};
  `,
);

export const StyledInfoIcon = styled(InfoIcon)(
  ({ theme: { space, colors } }) => css`
    position: absolute;
    right: ${space[1]}px;
    top: ${space[1]}px;
    color: ${colors.text.disabled};

    &:hover {
      color: ${colors.text.secondary};
    }
  `,
);
