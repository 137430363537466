import { FC, useRef } from 'react';

import { ErrorCode, useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { HINTS } from 'common/constants/products.const';
import { useHint } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { FieldWrapper } from 'components/fields';
import {
  ArchiveStatuses,
  useArchiveManagerActions,
} from 'entities/archive-manager';
import { ArchiveTypes } from 'entities/archive-manager/types';
import { IModalProps } from 'entities/modals';
import { BUTTONS_ACTION, useNotify } from 'entities/notify';
import { IReform, Reform, useField, useWatch } from 'reform';
import { AddSmallIcon } from 'resources/icons/12';
import { ImportIcon } from 'resources/icons/24';
import {
  Button,
  Container,
  FileLoaded,
  FormLabel,
  Modal,
  Notice,
  ToggleButton,
} from 'UI';
import theme from 'UI/theme';

import { fetchImportProductFromXML } from '../../api';

const IMPORT_PRODUCTS = 'IMPORT_PRODUCTS';

enum DocumentsFields {
  importsFile = 'importsFile',
  propertiesFile = 'propertiesFile',
}

type FilesType = {
  [DocumentsFields.importsFile]: File;
  [DocumentsFields.propertiesFile]: File;
};

const INITIAL_VALUES = {
  [DocumentsFields.importsFile]: null,
  [DocumentsFields.propertiesFile]: null,
};

const DOCUMENT_FIELDS_TITLES = {
  [DocumentsFields.importsFile]: 'Список изделий',
  [DocumentsFields.propertiesFile]: 'Характеристики изделий',
};

const DOCUMENT_TIPS_BY_TIP = {
  [DocumentsFields.importsFile]:
    'Загрузите файл со списком изделий. По умолчанию название начинается с «import».',
  [DocumentsFields.propertiesFile]:
    'Загрузите файл с характеристиками изделий. По умолчанию название начинается с «properties».',
};

const StyledToggleTab = styled(ToggleButton.Tab)(
  ({ theme: { space } }) => css`
    margin-bottom: 0;
    padding: 0 ${space[2]}px;
    justify-content: center;
    flex-basis: 100px;
  `,
);

const ButtonsWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    margin: ${space[3]}px -${space[4]}px 0;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.background};
    border-radius: 0 0 ${space[1]}px ${space[1]}px;
  `,
);

const FormatTypeField: FC = () => {
  return (
    <FieldWrapper injectedProps={false} label='Формат файла' mt={2}>
      <StyledToggleTab checked label='XML' mr={0} />
      <StyledToggleTab disabled label='CSV' />
    </FieldWrapper>
  );
};

const DocumentsField: FC<{ name: string }> = ({ name }) => {
  const notify = useNotify();
  const { value, setValue } = useField<Nullable<File>>({
    name,
  });

  const handleOnDrop = (files: File[]): void => {
    setValue(files[0]);
  };

  const handleRemove = (): void => {
    setValue(null);
  };

  const { getInputProps, open } = useDropzone({
    noClick: true,
    multiple: false,
    maxSize: 52428800, // 50Mb
    accept: '.xml',
    onDropAccepted: handleOnDrop,
    onDropRejected: fileRejections => {
      const { errors } = fileRejections[0];
      const { code: errorCode } = errors[0];
      if (errorCode === ErrorCode.FileInvalidType) {
        notify.error('Допустимые форматы файла — XML');
      } else if (
        errorCode === ErrorCode.FileTooSmall ||
        errorCode === ErrorCode.FileTooLarge
      ) {
        notify.error('Допустимый размер файла — 1 KB – 50 MB');
      }
    },
  });

  return (
    <>
      <FormLabel
        required
        fontSize={4}
        htmlFor={name}
        mb={2}
        mt={3}
        tip={DOCUMENT_TIPS_BY_TIP[name]}
      >
        {DOCUMENT_FIELDS_TITLES[name]}
      </FormLabel>
      {value ? (
        <FileLoaded file={value} onRemove={handleRemove} />
      ) : (
        <>
          <input {...getInputProps()} />
          <Button color='secondary' mr='auto' size='s' onClick={open}>
            <AddSmallIcon mr={0} /> Добавить файл
          </Button>
        </>
      )}
    </>
  );
};

type OnSuccessType = ({
  payload,
  message,
  notifyMessage,
}: {
  payload: { id: string; isSuccessful: boolean; errors?: any }[];
  message: string;
  notifyMessage: string;
}) => void;

type OnErrorType = ({ message }: { message: string }) => void;

interface IStartImportProductsProps {
  data: FilesType;
  onSuccess: OnSuccessType;
  onError: OnErrorType;
}

const startImportProducts = ({
  data,
  onSuccess,
  onError,
}: IStartImportProductsProps): void => {
  const { importsFile, propertiesFile } = data;
  const formData = new FormData();

  formData.append('importsFile', importsFile);
  formData.append('propertiesFile', propertiesFile);

  fetchImportProductFromXML(formData).then(
    ({ items }) => {
      if (items.every(({ isSuccessful }) => isSuccessful)) {
        onSuccess({
          payload: items,
          message:
            'Импорт номенклатуры завершен. Сформированные изделий доступны в разделе Черновики',
          notifyMessage:
            'Импорт номенклатуры завершен. Сформированные изделий доступны в разделе Черновики',
        });
      } else {
        onSuccess({
          payload: items,
          message:
            'Ошибка при импорте номенклатуры, не удалось обработать файлы полностью. Сформированные изделий доступны в разделе Черновики',
          notifyMessage:
            'Не удалось обработать файлы полностью. Сформированные изделий доступны в разделе Черновики',
        });
      }
    },
    () => {
      onError({
        message:
          'Ошибка при импорте номенклатуры. Попробуйте импортировать данные из файлов еще раз.',
      });
    },
  );
};

const ModalBody: FC = () => {
  const { isViewed, handleToggleViewed } = useHint(HINTS.IMPORT_PRODUCTS);

  return (
    <>
      {!isViewed && (
        <Notice mb={1} mt={3} py={1} onClose={handleToggleViewed}>
          Импорт файлов, выгруженных из 1С-систем.
          <br />
          Загрузите файлы со списком номенклатуры и характеристиками
          номенклатуры в соответствующие слоты. Система обработает загруженные
          файлы и сформирует черновики изделий.
        </Notice>
      )}
      <FormatTypeField />
      <DocumentsField name={DocumentsFields.importsFile} />
      <DocumentsField name={DocumentsFields.propertiesFile} />
    </>
  );
};

interface IModalFooterProps {
  onRequestClose: VoidFunction;
  onSubmit: VoidFunction;
}

const ModalFooter: FC<IModalFooterProps> = ({ onRequestClose, onSubmit }) => {
  const disabled = useWatch<FilesType, boolean>({
    selector: ({ importsFile, propertiesFile }) => {
      return !importsFile || !propertiesFile;
    },
  });

  return (
    <ButtonsWrapper>
      <Notice fontSize={2} mb='0px' type={Notice.TYPES.DISABLED}>
        Размер файла: 1 KB – 50 MB.
      </Notice>
      <Button
        color='secondary'
        mr={1}
        size='l'
        variant='outlined'
        onClick={onRequestClose}
      >
        Отменить
      </Button>
      <Button disabled={disabled} minWidth={140} size='l' onClick={onSubmit}>
        Импортировать
      </Button>
    </ButtonsWrapper>
  );
};

interface IImportProductsModal {
  updateData: VoidFunction;
}

const ImportProductsModal: FC<IImportProductsModal & IModalProps> & {
  TYPE: string;
} = ({ handleModalClose, updateData }) => {
  const reformRef = useRef<IReform>(null);
  const notify = useNotify();

  const { pushProcess, updateProcess } = useArchiveManagerActions();

  const handleSubmit = (): void => {
    const data = reformRef.current!.getData();
    const processId = uuid();

    pushProcess({
      id: processId,
      status: ArchiveStatuses.processing,
      type: ArchiveTypes.importProducts,
      message: 'Импорт номенклатуры из файлов',
    });

    startImportProducts({
      data,
      onSuccess: ({ payload, message, notifyMessage }) => {
        updateProcess({
          id: processId,
          status: ArchiveStatuses.success,
          type: ArchiveTypes.importProducts,
          onClose: updateData,
          message,
          payload,
        });

        notify.success(notifyMessage, {
          as: BUTTONS_ACTION.BUTTON,
          title: 'Перейти',
          onClick: updateData,
          variant: 'filled',
        });
      },
      onError: ({ message }) => {
        updateProcess({
          id: processId,
          status: ArchiveStatuses.error,
          type: ArchiveTypes.importProducts,
          message,
        });

        notify.error(message);
      },
    });

    handleModalClose();
  };

  return (
    <Reform ref={reformRef} initialValues={INITIAL_VALUES}>
      <Modal hasSubmitOnEnter={false} width={700}>
        <Modal.Default
          hideButtons
          icon={<ImportIcon color='primary.main' />}
          paddingBottom={22}
          paddingTop={22}
          style={{ borderTop: `1px solid ${theme.colors.divider}` }}
          title='Импорт изделий'
        >
          <Container column>
            <ModalBody />
            <ModalFooter
              onRequestClose={handleModalClose}
              onSubmit={handleSubmit}
            />
          </Container>
        </Modal.Default>
      </Modal>
    </Reform>
  );
};

ImportProductsModal.TYPE = IMPORT_PRODUCTS;

export { ImportProductsModal };
