import styled, { css } from 'styled-components';

import { IconButton } from 'UI';

const Wrapper = styled.div`
  width: 100%;
`;

const ItemContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    height: ${space[4]}px;
  `,
);

const Name = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    flex-basis: 210px;
    border: 1px solid ${colors.divider};
    border-bottom: 1px solid transparent;
    border-right: none;
    font-weight: 600;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    padding-left: ${space[2]}px;
    padding-top: ${space[1]}px;
    overflow: hidden;
    white-space: nowrap;
  `,
);

const Placeholder = styled.div(
  ({ theme: { space, colors, borderRadius, fontSizes } }) => css`
    height: ${space[4]}px;
    padding: ${space[1]}px 0;
    border: 1px solid ${colors.divider};
    border-bottom: none;
    border-top-right-radius: ${borderRadius}px;
    border-top-left-radius: ${borderRadius}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    text-align: center;
  `,
);

const WrapperIcon = styled.div(
  ({ theme: { colors, space, borderRadius } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${space[4]}px;
    height: ${space[4]}px;
    margin-right: ${space[2]}px;
    margin-left: -1px;
    background: ${colors.primary.main};
    border-bottom-left-radius: ${borderRadius}px;
  `,
);

const AddButton = styled.button.attrs(() => ({
  type: 'button',
  role: 'button',
}))(
  ({ theme: { space, colors, borderRadius, fontSizes } }) => css`
    display: flex;
    align-items: center;
    height: ${space[4]}px;
    width: 100%;
    color: ${colors.text.secondary};
    border: 1px solid ${colors.divider};
    border-bottom-right-radius: ${borderRadius}px;
    border-bottom-left-radius: ${borderRadius}px;
    font-size: ${fontSizes[2]}px;
    cursor: pointer;

    &:disabled,
    &[disabled] {
      color: ${colors.text.disabled};
      cursor: default;

      & ${AddButton} {
        background: ${colors.divider};
      }

      & ${WrapperIcon} {
        background: ${colors.highlight[1]};

        & svg {
          color: ${colors.primary.disabled};
        }
      }
    }
  `,
);

AddButton.WrapperIcon = WrapperIcon;

const DeleteButton = styled(IconButton)(
  ({ theme: { colors, borderRadius }, first }) => css`
    height: 100%;
    border: 1px solid ${colors.divider};
    border-left-width: 0;
    border-bottom-width: 0;
    border-radius: 0;

    ${first &&
    css`
      border-top-right-radius: ${borderRadius}px;
    `}
  `,
);

const ItemContainerExtension = Object.assign(ItemContainer, { Name });

export {
  Wrapper,
  ItemContainerExtension as ItemContainer,
  Placeholder,
  AddButton,
  DeleteButton,
};
