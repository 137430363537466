import { memo, FC } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { NTD_STATUSES } from 'common/constants/standartization';
import { useQueryParams } from 'common/hooks';
import { NtdLifeStates } from 'common/types/standartization';
import { SettingsIcon } from 'resources/icons/18';
import {
  Text,
  Button,
  Switch,
  Container,
  ContextMenu,
  SearchInput,
  useContextMenu,
} from 'UI';

const StyledSearchInput = styled(SearchInput)`
  flex: 1;
  margin-bottom: 0;

  & > div {
    padding: 0 0 0 6px;
  }
`;

const STYLE_SWITCH = { justifyContent: 'space-between' };

const NTD_FILTER_LIFE_STATES = [
  NtdLifeStates.active,
  NtdLifeStates.inactive,
  NtdLifeStates.partiallyCancelled,
];

const DictionaryControls: FC<{ placeholder: string }> = memo(
  ({ placeholder }) => {
    const history = useHistory();

    const [{ search, ntdStatuses = [] }, querySet] = useQueryParams();

    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();

    const handleSearch = (value = ''): void => {
      history.replace(
        querySet(params => ({ ...params, page: 1, search: value })),
      );
    };

    const handleChangeStatus = (key: string): void => {
      history.replace(
        querySet(props => ({
          ...props,
          ntdStatuses: ntdStatuses.includes(key)
            ? ntdStatuses.filter(status => status !== key)
            : [...ntdStatuses, key],
          page: 1,
        })),
      );
    };

    return (
      <Container alignItems='center' gap='12px' justifyContent='center'>
        <StyledSearchInput
          isBordered
          delay={400}
          initialValue={search}
          placeholder={placeholder}
          width='300px'
          onChange={handleSearch}
        />
        <Button
          color={ntdStatuses.length === 0 ? 'secondary' : 'primary'}
          mr={0}
          variant='text'
          onClick={handleContextMenuOpen}
        >
          <SettingsIcon />
        </Button>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          width='210px'
          onRequestClose={handleContextMenuClose}
        >
          <Container column gap={1} px={2} py={1}>
            <Text color='text.secondary' fontWeight={600}>
              Статус НТД
            </Text>
            {NTD_FILTER_LIFE_STATES.map(ntdStatus => (
              <Switch
                key={ntdStatus}
                checked={ntdStatuses.includes(ntdStatus)}
                label={NTD_STATUSES[ntdStatus]}
                style={STYLE_SWITCH}
                onChange={() => handleChangeStatus(ntdStatus)}
              />
            ))}
          </Container>
        </ContextMenu>
      </Container>
    );
  },
);

export { DictionaryControls };
