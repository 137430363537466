import { FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { Container, Paper } from 'UI';

const StyledPaper = styled(Paper)(
  ({ theme: { space } }) => css`
    padding: 0;
    width: 100%;
    margin-top: ${space[3]}px;
    border-radius: 8px;
    box-shadow: 0 1px 2px 0 rgba(28, 31, 34, 0.15);
  `,
);

const Footer = styled.footer(
  ({ theme: { space, colors, fontSizes } }) => css`
    padding: ${space[2]}px ${space[4]}px;
    border-top: 1px solid ${colors.divider};
    font-size: ${fontSizes[2]}px;
    text-align: center;
  `,
);

interface IIdentityBlankProps {
  children: ReactNode;
  footer?: ReactNode;
}

const IdentityBlank: FC<IIdentityBlankProps> = ({ children, footer }) => {
  return (
    <StyledPaper>
      <Container column p={5}>
        {children}
      </Container>
      {footer && <Footer>{footer}</Footer>}
    </StyledPaper>
  );
};

export { IdentityBlank };
