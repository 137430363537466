import styled, { css } from 'styled-components';

const NotificationBadge = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    min-width: 20px;
    padding: 0 ${space[0]}px;
    background: ${colors.primary.main};
    color: ${colors.white};
    font-size: ${fontSizes[0]}px;
    font-weight: 600;
    border-radius: 7px;
  `,
);

export { NotificationBadge };
