import { memo, FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { findPublicCatalogs } from 'common/api/catalogy.api';
import { EMPTY_ARRAY } from 'common/constants/emptyDataStructures.const';
import { useRequest } from 'common/hooks';
import { ICatalog } from 'common/types/catalogs.types';
import { getCatalogIcon } from 'common/utils/catalogy';
import { DefaultPage } from 'layouts/Pages';
import { CATALOGS, getPath } from 'routes';
import { Container, Text, Heading, Spinner } from 'UI';

const INITIAL_VALUE = { items: EMPTY_ARRAY };

const Item = styled(Link)(
  ({ theme: { colors, space, borderRadius } }) => css`
    display: flex;
    width: 860px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;

    &:hover {
      background: ${colors.highlight[0]};
    }
  `,
);

const ListCatalog: FC = memo(() => {
  const {
    isLoading,
    data: { items } = INITIAL_VALUE,
  }: { isLoading: boolean; data: { items: ICatalog[] } } = useRequest(
    findPublicCatalogs,
    [{ orderBy: 'orderIndex', orderDirection: 'asc' }],
  );

  return (
    <DefaultPage heading='Каталоги и справочники'>
      <Spinner height='100%' loading={isLoading}>
        <Container column alignItems='center' gap={2} py={2}>
          {items.map(({ id, name, type, description }) => {
            const Icon = getCatalogIcon(type);

            return (
              <Item key={id} to={getPath(CATALOGS.BREEDING, { catalogId: id })}>
                <Container column>
                  <Container alignItems='center'>
                    <Icon color='primary.main' mr={2} />
                    <Heading as='h2'>{name}</Heading>
                  </Container>
                  {description && (
                    <Text color='text.primary' fontSize={5} ml='40px' mt={2}>
                      {description}
                    </Text>
                  )}
                </Container>
              </Item>
            );
          })}
        </Container>
      </Spinner>
    </DefaultPage>
  );
});

export { ListCatalog };
