import { CSSProperties, forwardRef, MouseEventHandler, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  SpaceProps,
  HeightProps,
  height,
  MaxHeightProps,
  maxHeight,
  flexbox,
  FlexboxProps,
  borderRadius as styledBorderRadius,
  BorderRadiusProps,
} from 'styled-system';

import { TrashIcon, EditMediumIcon } from 'resources/icons/18';
import { ContextVIcon } from 'resources/icons/18';
import { IForbidden } from 'common/types/permissions.types';
import { ArrowUp1pxIcon, ArrowDown1pxIcon } from 'resources/icons/1px-12';

import { Container, FrameContainer } from '../Blocks';
import { ICollapse, useCollapse } from '../CollapseCard';
import { useContextMenu, ContextMenu } from '../ContextMenu';
import { IconButtonWrapper, IconButton } from '../IconButton';
import { Tooltip } from '../Tooltip';

interface IViewContainer {
  primary?: boolean;
  /**
   * Скрывает управляющие элементы, но с "затемнением" данных
   */
  disabled?: boolean;
  /**
   * Скрывает управляющие элемены, но без "затемнения" данных
   */
  readonly?: boolean;
  error?: boolean;
}

const ViewContainer = styled.div<
  IViewContainer & SpaceProps & HeightProps & MaxHeightProps & BorderRadiusProps
>(
  ({
    theme: { space, colors, borderRadius },
    error,
    disabled,
    primary,
    readonly,
  }) => css`
    display: flex;
    align-items: center;
    min-height: ${space[4]}px;
    width: 100%;
    min-width: 0;
    border-radius: ${borderRadius}px;
    background: ${colors.background};

    ${primary &&
    css`
      background: ${colors.highlight[1]};
    `}

    ${disabled &&
    css`
      opacity: 50%;
      pointer-events: none;
    `}

    ${readonly &&
    css`
      pointer-events: none;
      cursor: default;
    `}

    ${error &&
    css`
      border: 1px solid ${colors.error.main};
    `}

    ${height};
    ${maxHeight};
    ${styledBorderRadius};
  `,
);

const Content = styled.div<{ disabled?: boolean }>(
  ({ theme: { space }, disabled }) => css`
    display: flex;
    align-items: center;
    flex-grow: 1;
    min-width: 1px;
    padding: ${space[1]}px;
    cursor: pointer;

    ${disabled &&
    css`
      cursor: default;
      pointer-events: none;
    `}
  `,
);

const RightActionsContainer = styled.div<{
  isVisible?: boolean;
}>(
  ({ isVisible, theme: { space } }) => css`
    display: flex;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    align-items: center;
    justify-content: flex-end;
    align-self: stretch;
    min-height: ${space[4]}px;
    height: auto;
    width: 68px;
    min-width: 68px;
    margin-left: auto;

    ${ViewContainer}:hover & {
      visibility: visible;
    }
  `,
);

type ButtonSettingType = {
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
} & Record<string, any>;

const getButtonProps = (
  settings: ButtonSettingType = {},
  handler?: MouseEventHandler<HTMLButtonElement>,
): ButtonSettingType => {
  return {
    ...settings,
    onClick: settings?.onClick ?? handler,
  };
};

interface IViewModeProps {
  style?: CSSProperties;
  title?: false | string;
  visibleRightActions?: boolean;
  /**
   * Отображает справа кнопку контекстного меню и передает в меню значение пропа contextMenu
   */
  contextMenu?: ReactNode;
  children?: ReactNode;
  /**
   * Отображает icon в самом левом крае, не обязательно может быть иконкой.
   */
  icon?: ReactNode;
  leftAction?: ReactNode;
  rightActions?: ReactNode;
  /**
   * Предоставляем возможность более гибгой настройки кнопки удаления.
   */
  removeButtonProps?: ButtonSettingType;
  /**
   * Если нет нужды в настройке кнопки, можно просто передать хендлер
   */
  onRemove?: MouseEventHandler<HTMLButtonElement>;
  onEdit?: MouseEventHandler<HTMLButtonElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
}

/**
 * Реализация универсальной обертки для отображения данных в режиме просмотра.
 * Данная обретка будет постепенно "дополняться" новыми возможностями (если в данный момент нет нужных возможносте, то стоит использовать
 * старый компонент components/characteristic-elements/ViewMode.tsx, который постепенно будет выпиливаться из-за громоздкости и сложной поддержки)
 */
const ViewMode = forwardRef<
  HTMLDivElement,
  IViewModeProps &
    IViewContainer &
    Partial<IForbidden> &
    HeightProps &
    MaxHeightProps &
    BorderRadiusProps
>(
  (
    {
      style,
      forbidden,
      primary,
      readonly,
      disabled,
      visibleRightActions,
      error,
      title,
      children,
      contextMenu,
      icon,
      leftAction,
      rightActions,
      removeButtonProps,
      onClick,
      onRemove,
      onEdit,
      ...other
    },
    ref,
  ) => {
    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();

    const removeButton = getButtonProps(removeButtonProps, onRemove);

    const readonlyViewMode = readonly || (forbidden?.remove && forbidden?.edit);
    const hasRemoveAction = !forbidden?.remove && !!removeButton.onClick;
    const hasEditAction = !forbidden?.edit && !!onEdit;

    return (
      <ViewContainer
        ref={ref}
        disabled={disabled}
        error={error}
        primary={primary}
        readonly={readonly}
        style={style}
        {...other}
      >
        {icon && (
          <Container
            alignItems='center'
            height='100%'
            justifyContent='center'
            size={34}
          >
            {icon}
          </Container>
        )}
        {leftAction}
        {children && (
          <Tooltip hint pointer placement='bottom' title={title}>
            <Content
              disabled={disabled || !onClick || readonlyViewMode}
              onClick={onClick}
            >
              {children}
            </Content>
          </Tooltip>
        )}
        <RightActionsContainer isVisible={open || visibleRightActions}>
          {rightActions}
          {hasRemoveAction && (
            <IconButton
              style={{ borderRadius: 0, height: '100%' }}
              title='Удалить'
              {...removeButton}
            >
              <TrashIcon />
            </IconButton>
          )}
          {hasEditAction && (
            <IconButton
              style={{ borderRadius: 0, height: '100%' }}
              title='Изменить'
              onClick={onEdit}
            >
              <EditMediumIcon />
            </IconButton>
          )}
          {contextMenu && (
            <>
              <IconButton
                focused
                style={{ borderRadius: '0 2px 2px 0', height: '100%' }}
                onClick={handleContextMenuOpen}
              >
                <ContextVIcon />
              </IconButton>
              <ContextMenu
                anchorEl={anchorEl}
                open={open}
                placement='bottom-end'
                onRequestClose={handleContextMenuClose}
              >
                {contextMenu}
              </ContextMenu>
            </>
          )}
        </RightActionsContainer>
      </ViewContainer>
    );
  },
);

const LabelButton = styled.button<FlexboxProps>(
  ({ theme: { colors }, disabled }) => css`
    display: inline-flex;
    justify-content: left;
    text-align: left;
    align-items: center;
    min-width: 1px;
    color: ${colors.text.primary};
    cursor: ${disabled ? 'default' : 'pointer'};

    ${flexbox};
  `,
);

const CollapsingViewModeContainer = styled(Container)<{ error?: boolean }>(
  ({ theme: { colors, borderRadius }, error }) => css`
    /** Аккуратно! При возниконовении проблем - убрать, но возникнут проблемы с border-radius */
    overflow: hidden;
    border: 1px solid transparent;
    border-radius: ${borderRadius}px;

    ${error &&
    css`
      border-color: ${colors.error.main};

      & ${FrameContainer} {
        border-color: transparent;
      }
    `}
  `,
);

interface ICollapsingViewMode {
  className?: string;
  style?: CSSProperties;
  error?: boolean;
  /**
   * Рендерится внутри кнопки, разворачивающей карточку
   */
  collapseLabel?: ReactNode;
  /**
   * Рендерится за пределами LabelButton
   */
  contentLabel?: ReactNode;
  children: ReactNode;
}

const CollapsingViewMode = forwardRef<
  HTMLDivElement,
  ICollapsingViewMode &
    ICollapse &
    Partial<IForbidden> &
    Omit<IViewModeProps, 'children' | 'style' | 'leftAction'> &
    SpaceProps
>(
  (
    {
      style,
      className,
      initialOpen,
      open,
      icon,
      error,
      title,
      forbidden,
      visibleRightActions,
      collapseLabel,
      contentLabel,
      contextMenu,
      rightActions,
      children,
      removeButtonProps,
      onRequestToggle,
      onRemove,
      onEdit,
      onClick,
      ...other
    },
    ref,
  ) => {
    const { isOpen, handleToggle } = useCollapse({
      open,
      initialOpen,
      onRequestToggle,
    });

    return (
      <CollapsingViewModeContainer
        ref={ref}
        column
        className={className}
        error={error}
        flexGrow={1}
        style={style}
        {...other}
      >
        <ViewMode
          borderRadius='0px'
          contextMenu={contextMenu}
          forbidden={forbidden}
          icon={icon}
          leftAction={
            <LabelButton
              flexGrow={collapseLabel ? 1 : undefined}
              onClick={handleToggle}
            >
              <IconButtonWrapper size={34}>
                {isOpen ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </IconButtonWrapper>
              {collapseLabel}
            </LabelButton>
          }
          removeButtonProps={removeButtonProps}
          rightActions={rightActions}
          title={title}
          visibleRightActions={visibleRightActions}
          onClick={onClick}
          onEdit={onEdit}
          onRemove={onRemove}
        >
          {contentLabel}
        </ViewMode>
        <div style={{ display: isOpen ? 'block' : 'none' }}>{children}</div>
      </CollapsingViewModeContainer>
    );
  },
);

export { ViewMode, CollapsingViewMode };
