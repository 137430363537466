import { Fragment, FC } from 'react';

import { Link, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { CURRENCY_ISO_TEXT } from 'common/constants/currency.const';
import { useGlobalLoader, useRequest, useUnit } from 'common/hooks';
import { IParamsId } from 'common/types/common.types';
import { ManufacturingRequestType } from 'common/types/manufacturing-requests.types';
import { IMedia } from 'common/types/product.types';
import { IServiceBackend } from 'common/types/services.types';
import { getImageURL } from 'common/utils';
import {
  ControlActions,
  SectionNavigationsType,
} from 'components/action-panel';
import { ExpandableDescription } from 'components/ExpandableDescription';
import { ImagesPage } from 'components/media';
import { DocumentsList } from 'components/products/media/documents';
import { getServicePrice } from 'components/works-and-services/utils';
import { getCompanyId } from 'entities/identity';
import {
  CREATE_MANUFACTURING_REQUEST_MODAL,
  CreateManufacturingRequestModal,
  ManufacturingRequestButton,
} from 'entities/manufacturing-requests';
import { ModalController } from 'entities/modals';
import { ToolsPanelPage } from 'layouts/Pages';
import { COMPANY, getPath } from 'routes';
import { useAppSelector } from 'store';
import {
  Avatar,
  Container,
  DescriptionList,
  Heading,
  Text,
  InfoBlock,
  Tooltip,
} from 'UI';

import { fetchService } from '../api';
import { SERVICE_SECTION_TEXT } from '../constants';
import { useServiceActions } from '../hooks/useServiceActions';
import { IServiceControls, ServiceSectionEnum } from '../types';

const TextWrapper = styled.div(
  ({ theme: { colors } }) => css`
    height: fit-content;
    color: ${colors.primary.main};
    font-size: 22px;
    font-weight: 600;
  `,
);

const ServiceMainInfo: FC<{ service: IServiceBackend }> = ({ service }) => {
  const { unitToString } = useUnit();
  const { name, laborEffort, company, category, media, previewImage } = service;

  const hasImages = !!media.images?.items.length || !!previewImage;

  return (
    <InfoBlock.LeftColumn>
      <Heading mb={3}>{name}</Heading>
      <Container>
        {hasImages && (
          <ImagesPage
            flexBasis={260}
            media={media as IMedia}
            previewImage={previewImage}
            withFilters={false}
          />
        )}
        <DescriptionList dotted primary flexGrow={1} mb={3}>
          <DescriptionList.Property label='Категория'>
            {category}
          </DescriptionList.Property>
          <DescriptionList.Property label='Компания'>
            <Link to={getPath(COMPANY.CARD, { id: company.id })}>
              {company.name}
            </Link>
            {company.logo && (
              <Avatar
                letter={company.name}
                mt={1}
                size='s'
                url={getImageURL(company.logo)}
              />
            )}
          </DescriptionList.Property>
          {laborEffort && (
            <DescriptionList.Property
              label={`Трудозатраты, ${unitToString(laborEffort.unit)}`}
            >
              {laborEffort.value}
            </DescriptionList.Property>
          )}
        </DescriptionList>
      </Container>
    </InfoBlock.LeftColumn>
  );
};

const ServicePrice: FC<{ service: IServiceBackend }> = ({
  service,
  service: {
    price,
    company: { id: companyId },
  },
}) => {
  const { amount, currency } = getServicePrice({ price }) ?? {};

  const tipTitle = currency
    ? CURRENCY_ISO_TEXT[currency].charAt(0).toUpperCase() +
      CURRENCY_ISO_TEXT[currency].slice(1)
    : '';

  return (
    <InfoBlock.RightColumn column>
      {amount ? (
        <TextWrapper>
          <Container display='inline'>{amount.join(' ')}&nbsp;</Container>
          <Tooltip hint pointer placement='bottom' title={tipTitle}>
            <Text as='span' fontSize={5} wordBreak='break-word'>
              {currency}
            </Text>
          </Tooltip>
        </TextWrapper>
      ) : (
        <Heading color='text.disabled' width='100%'>
          Цена не указана
        </Heading>
      )}
      <ManufacturingRequestButton
        color='info'
        companyId={companyId}
        item={service}
        mt={3}
        type={ManufacturingRequestType.service}
        width={300}
      />
    </InfoBlock.RightColumn>
  );
};

interface IServicePageControls extends IServiceControls {
  isOwnService: boolean;
}

const ServicePageControls: FC<IServicePageControls> = ({
  isOwnService,
  service,
}) => {
  const actions = useServiceActions({ service });

  if (isOwnService) {
    return <ControlActions>{actions}</ControlActions>;
  }

  return null;
};

const ServicePage: FC = () => {
  const { id: serviceId } = useParams<IParamsId>();
  const companyId = useAppSelector(getCompanyId);

  const { isLoading, data: service = {} } = useRequest(fetchService, [
    serviceId,
  ]);

  useGlobalLoader(isLoading);

  if (isLoading) return null;

  const hasDescription = !!service.description;
  const hasDocuments = service.media.documents?.items.length > 0;

  const sections: SectionNavigationsType = [
    {
      title: SERVICE_SECTION_TEXT[ServiceSectionEnum.main],
      dataType: ServiceSectionEnum.main,
    },
    hasDescription && {
      title: SERVICE_SECTION_TEXT[ServiceSectionEnum.description],
      dataType: ServiceSectionEnum.description,
    },
    hasDocuments && {
      title: SERVICE_SECTION_TEXT[ServiceSectionEnum.documents],
      dataType: ServiceSectionEnum.documents,
    },
  ].filter(Boolean);

  return (
    <ToolsPanelPage
      bg='background'
      rightAddon={
        <Fragment>
          <ManufacturingRequestButton
            companyId={service.company.id}
            item={service}
            type={ManufacturingRequestType.service}
          />
          <ServicePageControls
            isOwnService={companyId === service.company.id}
            service={service}
          />
        </Fragment>
      }
      sections={sections}
    >
      <InfoBlock id={ServiceSectionEnum.main} mt={2}>
        <Container>
          <ServiceMainInfo service={service} />
          <InfoBlock.Divider />
          <ServicePrice service={service} />
        </Container>
      </InfoBlock>
      <ExpandableDescription
        description={{ text: service.description }}
        id={ServiceSectionEnum.description}
        title={SERVICE_SECTION_TEXT[ServiceSectionEnum.description]}
      />
      <DocumentsList
        documents={service.media.documents?.items ?? []}
        documentsCount={service.media.documents?.items.length ?? 0}
        id={ServiceSectionEnum.documents}
        title={SERVICE_SECTION_TEXT[ServiceSectionEnum.documents]}
      />
      <ModalController
        component={CreateManufacturingRequestModal}
        type={CREATE_MANUFACTURING_REQUEST_MODAL}
      />
    </ToolsPanelPage>
  );
};

export { ServicePage };
