import { FC, memo } from 'react';

import { Link } from 'react-router-dom';
import styled, { useTheme, css } from 'styled-components';

import { CURRENCY_ISO_SYMBOLS } from 'common/constants/currency.const';
import { ManufacturingRequestType } from 'common/types/manufacturing-requests.types';
import { ProductLifeState } from 'common/types/product.types';
import { IServiceBackend } from 'common/types/services.types';
import { getFileURL, getImageURL } from 'common/utils';
import { CardInfo, CardTitle } from 'components/company';
import { getServicePrice } from 'components/works-and-services/utils';
import { ManufacturingRequestButton } from 'entities/manufacturing-requests';
import { COMPANY, getPath } from 'routes';
import { Avatar, Container, Heading, Text, Tooltip } from 'UI';

const ServiceTitle = styled(CardTitle)(
  ({ theme: { colors, fontSizes } }) => css`
    min-height: 20px;
    max-height: 40px;
    font-size: ${fontSizes[4]}px;
    font-weight: 500;
    color: ${colors.text.secondary};
  `,
);

const ServiceCardWrapper = styled.div<{ isDictionary: boolean }>(
  ({ theme: { colors }, isDictionary }) => css`
    display: flex;
    position: relative;
    border-radius: 8px;
    overflow: hidden;
    border: 1px solid ${colors.divider};
    min-height: 280px;
    max-height: ${isDictionary ? '320px' : '280px'};
  `,
);

const RequestButtonWrapper = styled.div(
  ({ theme: { space } }) => css`
    position: absolute;
    right: ${space[2]}px;
    top: 113px;
    z-index: 1;
  `,
);

interface IServiceCardProps {
  isDictionary?: boolean;
  productId?: string;
  item: IServiceBackend;
  productLifeState?: ProductLifeState;
}

const ServiceCard: FC<IServiceCardProps> = memo(
  ({
    isDictionary = false,
    productId,
    productLifeState,
    item,
    item: {
      id,
      name,
      category,
      price,
      previewImage,
      company: { id: companyId, name: companyName, logo },
    },
  }) => {
    const theme = useTheme();

    const manufacturingRequestButton = (
      <RequestButtonWrapper>
        <ManufacturingRequestButton
          rounded
          companyId={companyId}
          item={item}
          productId={productId}
          type={ManufacturingRequestType.service}
        />
      </RequestButtonWrapper>
    );

    const servicePrice = getServicePrice({
      price,
      currencyConvertMap: CURRENCY_ISO_SYMBOLS,
    });

    return (
      <ServiceCardWrapper isDictionary={isDictionary}>
        <CardInfo>
          <Avatar
            cover
            full
            as={Link}
            borderRadius={`${theme.borderRadius}px ${theme.borderRadius}px 0px 0px`}
            height={130}
            size='xl'
            to={getPath(COMPANY.SERVICES_PAGE, { id })}
            url={getFileURL(previewImage)}
          />
          <Container
            column
            flexGrow={1}
            justifyContent='space-between'
            mt={1}
            p={2}
          >
            {productId
              ? productLifeState === ProductLifeState.public &&
                manufacturingRequestButton
              : manufacturingRequestButton}
            <Text
              color='text.disabled'
              fontSize={0}
              fontWeight={500}
              letterSpacing='0.5px'
              mb={0}
              textTransform='uppercase'
            >
              {category}
            </Text>
            <Tooltip hint placement='bottom' title={name}>
              <ServiceTitle to={getPath(COMPANY.SERVICES_PAGE, { id })}>
                {name}
              </ServiceTitle>
            </Tooltip>
            {isDictionary && (
              <Container
                alignItems='center'
                as={Link}
                mb={1}
                to={getPath(COMPANY.CARD, { id: companyId })}
              >
                <Avatar
                  letter={companyName}
                  mr={1}
                  size='xxs'
                  url={getImageURL(logo)}
                />
                <Text
                  truncate
                  color='text.secondary'
                  letterSpacing='0.5px'
                  ml={0}
                >
                  {companyName}
                </Text>
              </Container>
            )}
            <Heading as='h2' mb={1} mt='auto'>
              {servicePrice ? (
                `${servicePrice.amount.join(' ')} ${servicePrice.currency}`
              ) : (
                <Text color='text.disabled'>Цена не указана</Text>
              )}
            </Heading>
          </Container>
        </CardInfo>
      </ServiceCardWrapper>
    );
  },
);

export { ServiceCard };
