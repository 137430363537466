import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

export const AnchorButton = styled.button<SpaceProps>(
  ({ theme: { colors, fontSizes } }) => css`
    display: block;
    margin-left: auto;
    border-bottom: 1px dashed ${colors.text.secondary};
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;

    ${styledSpace};
  `,
);
