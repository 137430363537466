import { useState, useEffect, useCallback, memo, useRef, FC } from 'react';

import styled, { css } from 'styled-components';

import { findActivityAreas } from 'common/api/company-management';
import { INITIAL_DATA_REQUEST_V2_MAP } from 'common/constants/request.const';
import { useRequest } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import {
  IActivityArea,
  IActivityAreaChildren,
} from 'common/types/company-management';
import { Field, useArrayField, useReform, useWatch } from 'reform';
import { HomeIcon } from 'resources/icons/18';
import { PalletIcon } from 'resources/other-28';
import { useToggle } from 'tools/hooks';
import { itemTo } from 'tools/utils';
import {
  Divider,
  Dropdown,
  Container,
  FormLabel,
  IconButton,
  VerticalDivider,
  MultiSelectField,
} from 'UI';

import { HeaderItem } from './HeaderItem';
import { Item } from './Item';

const StyledIconButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    & svg {
      color: ${colors.text.secondary};
    }
  `,
);

const LIMIT = 5;
const ACTIVITY_AREAS = 'activityAreas';
const TIP = {
  maxWidth: 220,
  title:
    'Выберите, какие услуги предоставляет и на каких работах специализируется компания.',
};

const ActivityAreasLibrary: FC = memo(() => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { errors } = useReform();
  const { push } = useArrayField({ name: ACTIVITY_AREAS });

  const [isOpenLibrary, toggleOpenLibrary] = useToggle(false);
  const [selectedItem, setSelectedItem] =
    useState<Nullable<IActivityArea>>(null);
  const [selectedItemsId, setSelectedItemsId] = useState<Set<string>>(
    new Set(),
  );

  const activityAreasField = useWatch({ fieldName: ACTIVITY_AREAS });

  useEffect(
    () => setSelectedItemsId(new Set(activityAreasField.map(({ id }) => id))),
    [activityAreasField],
  );

  const {
    data: { items: activityAreas } = INITIAL_DATA_REQUEST_V2_MAP,
  }: { data: { items: IActivityArea[] } } = useRequest(findActivityAreas, []);

  const itemsToRender = selectedItem ? selectedItem.children : activityAreas;

  const handleGoHome = (): void => setSelectedItem(null);

  const handleNextLevel = useCallback((item: IActivityArea) => {
    setSelectedItem(item);
  }, []);

  const handleSelect = useCallback(
    (item: IActivityAreaChildren): void => {
      if (selectedItem && activityAreasField.length < LIMIT) push(item);
    },
    [selectedItem, activityAreasField, push],
  );

  return (
    <Container column>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <FormLabel required tip={TIP}>
        Сферы деятельности
      </FormLabel>
      <div ref={containerRef}>
        <Field
          component={MultiSelectField}
          error={errors[ACTIVITY_AREAS]}
          itemToKey={itemTo('id')}
          itemToString={itemTo('name')}
          limit={LIMIT}
          name={ACTIVITY_AREAS}
          options={activityAreas.map(({ children }) => children).flat()}
          rightAddon={
            <StyledIconButton
              active={isOpenLibrary}
              ml={1}
              size={28}
              title='Библиотека'
              variant='grayBackground'
              onClick={toggleOpenLibrary}
            >
              <PalletIcon color='text.secondary' />
            </StyledIconButton>
          }
        />
        {isOpenLibrary && (
          <Dropdown
            disablePortal
            open
            anchorEl={containerRef.current}
            onRequestClose={toggleOpenLibrary}
          >
            <Container column>
              <Container p={1}>
                <IconButton
                  disabled={!selectedItem}
                  size={28}
                  title='К корневому'
                  onClick={handleGoHome}
                >
                  <HomeIcon />
                </IconButton>
                <VerticalDivider height={28} mx={1} />
                <Container alignItems='center' height={28} minWidth={0}>
                  <HeaderItem
                    goBack={selectedItem ? handleGoHome : null}
                    title={selectedItem?.name ?? ''}
                  />
                </Container>
              </Container>
              <Divider m='0px' mb='-2px' />
              <Container column maxHeight={228} overflowY='scroll'>
                {itemsToRender.map(item => {
                  if (selectedItemsId.has(item.id)) return null;

                  return (
                    <Item
                      key={item.id}
                      item={item}
                      selectedItem={selectedItem}
                      onNextLevel={handleNextLevel}
                      onSelect={handleSelect}
                    />
                  );
                })}
              </Container>
            </Container>
          </Dropdown>
        )}
      </div>
    </Container>
  );
});

export { ActivityAreasLibrary };
