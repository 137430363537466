import { FC, ReactNode } from 'react';

import { useTheme } from 'styled-components';

import { ContextMenuHorizontalIcon } from 'resources/other';
import { TitleWrapper } from 'components/action-panel/styled';
import { Button } from 'UI';
import { Tooltip } from 'UI/Tooltip';

interface IControlActionsProps {
  disabled?: boolean;
  tip?: ReactNode;
  description?: string;
  maxWidth?: number | string;
  children: ReactNode[] | ReactNode;
}

// Используется на внешней странице изделия/ол/стандарта/места продаж. Кнопка Действия

export const ControlActions: FC<IControlActionsProps> = ({
  disabled,
  tip,
  description = '',
  children,
  maxWidth,
}) => {
  const { zIndex } = useTheme();
  if ((Array.isArray(children) && children.length === 0) || !children) {
    return null;
  }

  return (
    <Tooltip title={tip}>
      <div>
        <Tooltip
          maxWidth={maxWidth}
          offset={[14, -4]}
          placement='bottom-end'
          title={
            <>
              {description && (
                <TitleWrapper uppercase color='text.primary' fontSize='8px'>
                  {description}
                </TitleWrapper>
              )}
              {children}
            </>
          }
          zIndex={zIndex.modal - 1}
        >
          <Button color='secondary' disabled={disabled} variant='text'>
            <ContextMenuHorizontalIcon mr={1} /> Действия
          </Button>
        </Tooltip>
      </div>
    </Tooltip>
  );
};
