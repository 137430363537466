import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  layout,
  SpaceProps,
  LayoutProps,
} from 'styled-system';

import { TriggerForMasterPanel } from 'entities/right-product-panel';
import { IconButton } from 'UI';
import { InputContainer } from 'UI/Inputs/components';

export const AddSectionButton = styled.button<SpaceProps>(
  ({ theme: { space, colors, fontSizes, borderRadius } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 762px;
    width: 762px;
    min-height: 46px;
    margin: 0 auto ${space[2]}px;
    font-size: ${fontSizes[2]}px;
    font-weight: 600;
    color: ${colors.text.secondary};
    background: ${colors.highlight[0]};
    border: 1px dotted ${colors.divider};
    border-radius: ${borderRadius}px;

    ${styledSpace}
  `,
);

export const LinkedProductInfoWrapper = styled.div<LayoutProps & SpaceProps>(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding-left: ${space[2]}px;

    ${layout};
    ${styledSpace};
  `,
);

export const LinkedProductInputWrapper = styled(InputContainer)<{
  stretch?: boolean;
}>(
  ({ theme: { space }, stretch }) => css`
    align-items: center;
    height: ${stretch ? '100%' : 'auto'};
    min-height: ${space[4]}px;
  `,
);

export const LinkedProductAvatarWrapper = styled.div<{ hasImage?: boolean }>(
  ({ theme: { colors }, hasImage }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${colors.background};

    ${hasImage &&
    css`
      background: transparent;
    `}
  `,
);

export const StyledMasterPanelTrigger = styled(TriggerForMasterPanel)(
  ({ theme: { colors } }) => css`
    color: ${colors.primary.main};
    cursor: pointer;
  `,
);

export const MasterPanelHeaderButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    && svg {
      color: ${colors.text.secondary};
    }

    &&:hover {
      background: ${colors.highlight[2]};

      && svg {
        color: ${colors.text.primary};
      }
    }
  `,
);
