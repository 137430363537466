import { Fragment, FC } from 'react';

import { Link, useLocation, useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { MASTER_PANEL_SCREENS } from 'common/constants/master-panel.const';
import { ProductEntity } from 'common/types/product.types';
import { useMasterPanelEntityFormContext } from 'entities/master-panel';
import { useModalContext } from 'entities/modals';
import { getPath, PRODUCTS, SEARCH } from 'routes';

import { StyledMasterPanelTrigger } from '../../../../../features/products/create/_shared/styled';

const WrapperNotice = styled.div(
  ({ theme: { colors, space, fontSizes, borderRadius } }) => css`
    padding: ${space[1]}px;
    margin: 0 ${space[1]}px ${space[1]}px;
    background: ${colors.background};
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    border-radius: ${borderRadius}px;
  `,
);

type NoticeTypeByProduct =
  | ProductEntity.standard
  | ProductEntity.assortment
  | ProductEntity.product
  | ProductEntity.specification;

const ENTITY_WORD_BY_TYPE: Record<NoticeTypeByProduct, WordCases> = {
  [ProductEntity.standard]: {
    nominative: 'стандартное изделие',
    genitive: 'стандартного изделия',
  },
  [ProductEntity.assortment]: {
    nominative: 'сортамент',
    genitive: 'сортамента',
  },
  [ProductEntity.product]: {
    nominative: 'товар',
    genitive: 'товара',
  },
  [ProductEntity.specification]: {
    nominative: 'техническое задание',
    genitive: 'технического задания',
  },
};

type WordCases = { nominative: string; genitive: string };

type SearchLink = {
  link?: string;
  text?: string;
};

const getSearchLink = (type: NoticeTypeByProduct): SearchLink => {
  if (type === ProductEntity.specification) return {};

  const link = type === ProductEntity.product ? SEARCH.PAGE : SEARCH.STANDARD;
  const text =
    type === ProductEntity.product
      ? 'поиске'
      : 'поиске по стандартным изделиям';

  return { link, text };
};

const getNoticeMessage = (
  type: NoticeTypeByProduct,
  productType: WordCases,
): string => {
  const uinText = type === ProductEntity.specification ? '.' : ' или UIN.';
  const messagePrefix = `Чтобы добавить ${productType.nominative} из системы, введите его название${uinText}`;
  const messageSuffix =
    type === ProductEntity.specification
      ? ''
      : ` Если вы не знаете название или UIN ${productType.genitive}, используйте фильтры по характеристикам в `;

  return messagePrefix + messageSuffix;
};

interface INoticeMessageProps {
  type: NoticeTypeByProduct;
  inputValue: string;
}

const NoticeMessage: FC<INoticeMessageProps> = ({ type, inputValue }) => {
  const { handleModalClose } = useModalContext();
  const { id } = useParams<{ id?: string }>();
  const { pathname } = useLocation();

  const {
    screen: { setScreen },
  } = useMasterPanelEntityFormContext();

  /**
   * Для ТЗ и товара по шаблону формулировки остаются старые, поэтому проверяем что за страница сейчас открыта
   */
  const isSpecificationPage = pathname.includes(
    getPath(PRODUCTS.SPECIFICATION_PAGE, { id }),
  );
  const isByTemplatePage = pathname.includes('by-template');

  const productType = ENTITY_WORD_BY_TYPE[type];
  const message = getNoticeMessage(type, productType);
  const { link, text } = getSearchLink(type);

  const openMasterPanel = (): void => {
    setScreen({
      name: MASTER_PANEL_SCREENS.RECOMMENDATIONS.COMPOSITION_SEARCH,
      data: inputValue,
    });

    handleModalClose();
  };

  if (isSpecificationPage || isByTemplatePage) {
    return (
      <WrapperNotice>
        {message}
        {link && (
          <Fragment>
            <Link target='_blank' to={`${link}?search=${inputValue}`}>
              {text}
            </Link>
          </Fragment>
        )}
      </WrapperNotice>
    );
  }

  return (
    <WrapperNotice>
      {type === ProductEntity.specification ? (
        message
      ) : (
        <Fragment>
          Введите название или UIN {productType.genitive} из системы. Для
          подбора {productType.genitive} по характеристикам используйте{' '}
          <StyledMasterPanelTrigger onClick={openMasterPanel}>
            мастер-панель
          </StyledMasterPanelTrigger>
          .
        </Fragment>
      )}
    </WrapperNotice>
  );
};

export { NoticeMessage };
