import { FC, memo } from 'react';

import styled, { css } from 'styled-components';
import {
  position,
  PositionProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { AddMediumIcon } from 'resources/icons/18';
import { ButtonRounded, Tooltip } from 'UI';

const LevelOffset = styled.div(
  ({ theme: { colors } }) => css`
    flex-shrink: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    background: ${colors.white};
  `,
);

const GroupLabel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  height: 100%;
  pointer-events: none;
`;

const GroupText = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    max-width: 217px;
    height: 14px;
    padding: 0 ${space[0]}px;
    background: ${colors.text.primary};
    color: ${colors.white};
    font-size: ${fontSizes[0]}px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `,
);

const GroupArrow = styled.div(
  ({ theme: { colors } }) => css`
    width: 4px;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 4px solid ${colors.text.primary};
  `,
);

const GroupCircle = styled.div(
  ({ theme: { colors } }) => css`
    box-sizing: border-box;
    width: 8px;
    height: 8px;
    border: 2px solid ${colors.text.primary};
    border-radius: 50%;
  `,
);

const LevelContainer = styled.div<
  { levelOffset: number } & SpaceProps & PositionProps
>(
  ({ theme: { space, colors }, levelOffset }) => css`
    position: relative;
    display: flex;
    height: ${space[2]}px;
    background: ${colors.white};
    margin-left: -${space[3] * levelOffset}px;
    z-index: 10;

    ${styledSpace}
    ${position}
  `,
);

const LevelOpacityWrapper = styled.div<{ show?: boolean; disabled?: boolean }>(
  ({ show }) => css`
    display: flex;
    width: 100%;
    opacity: ${show ? 1 : 0};
    transition: opacity 200ms ease-in;

    &:hover:not([disabled]) {
      opacity: 1;
    }

    &[disabled] {
      visibility: hidden;
    }
  `,
);

const LevelButton = styled.button<{ active: boolean }>(
  ({ theme: { colors }, active }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding: 6px 2px;
    background-image: linear-gradient(
      ${colors.highlight[1]},
      ${colors.highlight[1]}
    );
    background-clip: content-box;

    &:first-of-type {
      padding-left: 0;
    }

    &:last-of-type {
      padding-right: 0;
    }

    ${active &&
    css`
      background-image: linear-gradient(
        ${colors.text.primary},
        ${colors.text.primary}
      );
    `}
  `,
);

export const LevelRender: FC<{ levelName: string }> = memo(({ levelName }) => {
  return (
    <LevelOffset>
      <GroupLabel>
        <GroupText>{levelName}</GroupText>
        <GroupArrow />
        <GroupCircle />
      </GroupLabel>
    </LevelOffset>
  );
});

interface SimpleLevelProps extends SpaceProps, PositionProps {
  title?: string;
  disabled?: boolean;
  onClick: VoidFunction;
}

const SimpleLevel: FC<SimpleLevelProps> = ({
  title,
  disabled,
  onClick,
  ...other
}) => {
  return (
    <LevelContainer levelOffset={0} {...other}>
      <LevelOpacityWrapper disabled={disabled}>
        <LevelButton active onClick={onClick}>
          <Tooltip arrow hint title={title}>
            <ButtonRounded circle as='div'>
              <AddMediumIcon color='white' />
            </ButtonRounded>
          </Tooltip>
        </LevelButton>
      </LevelOpacityWrapper>
    </LevelContainer>
  );
};

export { LevelContainer, LevelOpacityWrapper, LevelButton, SimpleLevel };
