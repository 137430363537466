import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

export const CardWrapper = styled.div(
  ({ theme: { space, shadows } }) => css`
    display: flex;
    position: relative;
    min-height: 388px;
    border-radius: 8px;
    overflow: hidden;
    padding: ${space[2]}px;

    &:hover {
      box-shadow: ${shadows[2]};
    }
  `,
);

export const CardTitle = styled(Link)(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: -webkit-box;
    color: ${colors.text.primary};
    font-size: ${fontSizes[5]}px;
    font-weight: 600;
    margin-bottom: ${space[1]}px;
    word-break: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    &:hover {
      color: ${colors.text.primary};
    }
  `,
);

export const CardInfo = styled.div`
  flex-grow: 1;
  min-width: 0;
`;
