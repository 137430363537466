import { useMemo } from 'react';

import { addDays, isAfter } from 'date-fns';
import styled, { css } from 'styled-components';

import { TIMER_DISPLAY_MODE, HINTS } from 'common/constants/products.const';
import { useHint } from 'common/hooks';
import { ProductLifeState } from 'common/types/product.types';
import { InfoNotice } from 'UI';

import { Timer } from './Timer';

const FULL_EDITING_EXPIRED_REASON = {
  NONE: 'none',
  LINKED_PRODUCT: 'productHasBeenLinked',
  PARTNER_PRODUCT: 'productHasBeenDistributed',
  QUESTIONNAIRE_HAS_BEEN_FILLED: 'questionnaireHasBeenFilled',
};
const FULL_EDITING_EXPIRED_REASON_TEXT = {
  [FULL_EDITING_EXPIRED_REASON.LINKED_PRODUCT]:
    'Время на редактирование товара истекло по причине привязки товара в качестве характеристики к другому товару.',
  [FULL_EDITING_EXPIRED_REASON.PARTNER_PRODUCT]:
    'Время на редактирование товара истекло по причине распространения товара 1 и более партнерам.',
  [FULL_EDITING_EXPIRED_REASON.QUESTIONNAIRE_HAS_BEEN_FILLED]:
    'Время на редактирование товара истекло по причине заполнения опросного листа пользователем системы.',
};

const DashboardContainer = styled.div`
  min-width: 896px;
`;

const TimerContainer = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 51px;
    border-bottom: 1px solid ${colors.divider};
    white-space: nowrap;
  `,
);

function FullEditingCompletedReasonHint({
  isOpen,
  product,
}): JSX.Element | null {
  const { id, fullEditingExpiredReason, fullEditingExpiresAt } = product ?? {};

  const { isViewed, handleToggleViewed } = useHint(
    `${HINTS.REASON_FOR_EARLY_EXPIRATION_OF_THE_TIME}_${id}`,
  );

  const isVisibleReasonHint = useMemo(() => {
    // displayed within 5 days after the completion of full editing the product
    const expiredReasonHintDate = addDays(new Date(fullEditingExpiresAt), 5);

    return (
      !isViewed &&
      fullEditingExpiredReason !== FULL_EDITING_EXPIRED_REASON.NONE &&
      expiredReasonHintDate.getTime() > new Date().getTime()
    );
  }, [fullEditingExpiredReason, fullEditingExpiresAt, isViewed]);

  if (!isOpen) return null;

  if (!fullEditingExpiredReason) return null;

  return (
    <InfoNotice open={isVisibleReasonHint} onRequestClose={handleToggleViewed}>
      {FULL_EDITING_EXPIRED_REASON_TEXT[fullEditingExpiredReason]}
    </InfoNotice>
  );
}

function FullEditingTimer({
  lifeState,
  fullEditingExpiresAt,
}): JSX.Element | null {
  const isVisibleTimer =
    lifeState === ProductLifeState.public &&
    !!fullEditingExpiresAt &&
    isAfter(new Date(fullEditingExpiresAt), new Date());
  if (!isVisibleTimer) {
    return null;
  }

  return (
    <TimerContainer>
      <Timer
        displayMode={TIMER_DISPLAY_MODE.FULL}
        fullEditingExpiresAt={fullEditingExpiresAt}
      />
    </TimerContainer>
  );
}

export { DashboardContainer, FullEditingCompletedReasonHint, FullEditingTimer };
