import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Button } from 'UI';

export const LeftSide = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    width: 100%;
    padding: ${space[4]}px;
    overflow-y: auto;

    ${styledSpace}
  `,
);

export const RightSide = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 287px;
    width: 287px;
    padding: ${space[5]}px ${space[4]}px ${space[4]}px;
    background: ${colors.background};
    border-radius: 0 8px 8px 0;
  `,
);

export const FormFilterWrapper = styled.div(
  ({ theme: { space } }) => css`
    padding-right: 22px;
    margin-bottom: 20px;
    margin-right: -${space[4]}px;
    overflow-y: auto;
  `,
);

export const FormFilterButton = styled(Button)`
  display: block;
  text-align: left;
  white-space: normal;
  word-break: break-word;
`;
