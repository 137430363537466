import styled, { css } from 'styled-components';

export const SelectSearchTypeButton = styled.button(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    min-width: 140px;
    white-space: nowrap;
    height: ${space[5]}px;
    margin-right: ${space[2]}px;
    padding: 0 ${space[2]}px;
    border-right: 1px solid ${colors.divider};

    &:hover {
      & svg {
        color: ${colors.text.primary};
      }
    }
  `,
);

export const Header = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[2]}px;
    min-height: ${space[4]}px;
    padding-right: ${space[2]}px;
    padding-left: 59px;
    border-bottom: 1px solid ${colors.divider};
    color: ${colors.text.secondary};
    background: ${colors.background};
    font-size: ${fontSizes[0]}px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
);

export const SelectorContainer = styled.div(
  ({ theme: { colors, space } }) => css`
    padding-bottom: ${space[1]}px;
    margin-bottom: ${space[2]}px;
    border-bottom: 1px solid ${colors.divider};
  `,
);
