import { FC } from 'react';

import styled, { css } from 'styled-components';

import { POINTS_OF_SALE_ACTIVITY_KINDS_NAME } from 'common/constants/points-of-sale.const';
import { PointsOfSaleActivityKinds } from 'common/types/point-of-sale.types';
import { useField } from 'reform';
import { removeItem } from 'tools/utils';
import { ToggleButton } from 'UI';

export const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    position: relative;
    gap: ${space[0]}px;
    flex-wrap: wrap;
    margin-top: ${space[1]}px;
  `,
);

const activityKindsString = (item: PointsOfSaleActivityKinds): string => {
  return POINTS_OF_SALE_ACTIVITY_KINDS_NAME[item];
};
const ACTIVITY_KINDS_OPTIONS = Object.keys(POINTS_OF_SALE_ACTIVITY_KINDS_NAME);

const ActivityKindsFilter: FC = () => {
  const { value: tags = [], setValue } = useField({ name: 'activityKind' });

  const handleChange = ({ currentTarget }): void => {
    const { checked, value } = currentTarget;

    if (checked) {
      setValue(removeItem(tags, item => item === value));
    } else {
      setValue([...tags, value]);
    }
  };

  return (
    <Wrapper>
      {ACTIVITY_KINDS_OPTIONS.map((item: PointsOfSaleActivityKinds) => (
        <ToggleButton.Tab
          key={item}
          secondary
          checked={tags.includes(item)}
          label={activityKindsString(item)}
          mb='0px'
          mr='0px'
          value={item}
          onClick={handleChange}
        />
      ))}
    </Wrapper>
  );
};

export { ActivityKindsFilter };
