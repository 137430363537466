import { FC, Fragment } from 'react';

import styled, { css } from 'styled-components';

import { IForbidden } from 'common/types/permissions.types';
import { IPackageVariation } from 'common/types/product.types/accompany-information.types';
import {
  AddPhotoBetweenWrapper,
  DashedLine,
  OpenMenuIcon,
} from 'components/media/styled';
import { useModalContext } from 'entities/modals';
import { NextMediumIcon } from 'resources/icons/18';
import { OpenedBoxIcon } from 'resources/other';
import { ConvertIcon } from 'resources/other-22';
import { Container, FormError, IconButtonWrapper, Text, Tooltip } from 'UI';

import { AddButton } from './AddButton';
import { ADD_PACKAGE } from './modal/Modal';
import { PackageCard } from './PackageCard';

const StyledAddPhotoBetweenWrapper = styled(AddPhotoBetweenWrapper)`
  width: 18px;
  opacity: 1;
`;

const StyledDashedLine = styled(DashedLine)`
  display: none;
  border-left: none;
  border-right-width: 1px;

  ${StyledAddPhotoBetweenWrapper}:hover & {
    display: block;
  }
`;

const StyledOpenMenuIcon = styled(OpenMenuIcon)`
  display: none;

  ${StyledAddPhotoBetweenWrapper}:hover & {
    display: flex;
    align-items: center;
  }
`;

const StyledArrowRightIcon = styled(NextMediumIcon)(
  ({ theme: { colors } }) => css`
    align-self: center;
    color: ${colors.divider};
    display: block;

    ${StyledAddPhotoBetweenWrapper}:hover & {
      display: none;
    }
  `,
);

const SwipeButtonWrapper = styled(IconButtonWrapper)`
  align-self: center;
`;

const PACKAGES_COUNT_LIMIT = 5;

interface IBetweenControls {
  canInsertBetween: boolean;
  isSwapMode: boolean;
  isLimitReached: boolean;
  isPrimaryPackage: boolean;
  isReplaceablePackage: boolean;
  onAddPackage: any;
  onSwapPackages: any;
}

const BetweenControls: FC<IBetweenControls> = ({
  canInsertBetween,
  isSwapMode,
  isLimitReached,
  isPrimaryPackage,
  isReplaceablePackage,
  onAddPackage,
  onSwapPackages,
}) => {
  if (isLimitReached && !isSwapMode) return <StyledArrowRightIcon />;

  if (isSwapMode && isReplaceablePackage) {
    return (
      <SwipeButtonWrapper
        size={18}
        title={
          isPrimaryPackage
            ? 'Нельзя переместить первичную упаковку'
            : 'Поменять местами'
        }
        variant={isPrimaryPackage ? 'disabled' : 'outline'}
        onClick={!isPrimaryPackage ? onSwapPackages : undefined}
      >
        <ConvertIcon size={18} />
      </SwipeButtonWrapper>
    );
  }

  if (canInsertBetween && !isSwapMode) {
    return (
      <Tooltip arrow hint title='Добавить упаковку'>
        <StyledAddPhotoBetweenWrapper onClick={onAddPackage}>
          <StyledArrowRightIcon />
          <StyledDashedLine />
          <StyledOpenMenuIcon />
        </StyledAddPhotoBetweenWrapper>
      </Tooltip>
    );
  }

  return <StyledArrowRightIcon />;
};

interface IPackagesList {
  isSwapMode: boolean;
  variation: IPackageVariation;
  initialVariation?: IPackageVariation;
  error: any;
  onAddPackage: any;
  onEditPackage: any;
  onSwapPackages: any;
  onRemovePackage: any;
}

const PackagesList: FC<IPackagesList & IForbidden> = ({
  isSwapMode,
  variation: { packages },
  initialVariation,
  forbidden,
  error,
  onAddPackage,
  onEditPackage,
  onSwapPackages,
  onRemovePackage,
}) => {
  const { handleModalOpen } = useModalContext();

  const isLimitReached = packages.length === PACKAGES_COUNT_LIMIT;

  const handleEditPackage = ({
    isEditable,
    productPackage,
    index,
    depth,
    error: productPackageError,
  }): void => {
    handleModalOpen(ADD_PACKAGE, {
      isEditable,
      isPrimaryPackage: depth === 1,
      productPackage,
      index,
      error: productPackageError,
      onSubmit: onEditPackage,
    });
  };

  return (
    <Container display='block' mt={1}>
      {packages.length === 0 ? (
        <AddButton
          style={{ width: '100%' }}
          onClick={() =>
            handleModalOpen(ADD_PACKAGE, {
              isPrimaryPackage: true,
              index: 0,
              onSubmit: onAddPackage,
            })
          }
        >
          <OpenedBoxIcon color='text.primary' />
          <Text fontSize={2}>
            Добавить <br />
            первичную упаковку
          </Text>
        </AddButton>
      ) : (
        <div style={{ display: 'flex', overflowX: 'auto' }}>
          <Container alignItems='flex-start' pb='10px'>
            {!isLimitReached && !forbidden.insert && (
              <Fragment>
                <AddButton
                  onClick={() =>
                    handleModalOpen(ADD_PACKAGE, {
                      index: 0,
                      onSubmit: onAddPackage,
                    })
                  }
                >
                  <OpenedBoxIcon color='text.primary' />
                  <Text fontSize={2}>Добавить внешнюю упаковку</Text>
                </AddButton>
                <StyledArrowRightIcon />
              </Fragment>
            )}
            {packages.map((productPackage, index) => {
              const isPrimaryPackage = index === packages.length - 1;
              const packageCardError = error?.packages?.[index];
              const prevProductPackage = packages[index - 1];

              const hasInitialProductPackage =
                !!initialVariation?.packages.find(
                  ({ id }) => productPackage.id === id,
                );

              const hasInitialPrevProductPackage =
                prevProductPackage &&
                initialVariation?.packages.find(
                  ({ id }) => prevProductPackage.id === id,
                );

              const forbiddenEditProductPackage =
                (forbidden.editInitialValues && hasInitialProductPackage) ||
                !!forbidden.edit;

              const forbiddenEditPrevProductPackage =
                (forbidden.editInitialValues && hasInitialPrevProductPackage) ||
                !!forbidden.edit;

              const disabled =
                (isSwapMode && isPrimaryPackage) || forbiddenEditProductPackage;

              const canInsertBetween =
                !forbiddenEditProductPackage ||
                !forbiddenEditPrevProductPackage;

              return (
                <Fragment key={productPackage.id}>
                  {index !== 0 && (
                    <BetweenControls
                      canInsertBetween={canInsertBetween}
                      isLimitReached={isLimitReached}
                      isPrimaryPackage={isPrimaryPackage}
                      isReplaceablePackage={!forbiddenEditProductPackage}
                      isSwapMode={isSwapMode}
                      onAddPackage={() =>
                        handleModalOpen(ADD_PACKAGE, {
                          index,
                          onSubmit: onAddPackage,
                        })
                      }
                      onSwapPackages={() => onSwapPackages(index)}
                    />
                  )}
                  <Container flexBasis='min-content' flexDirection='column'>
                    <PackageCard
                      depth={packages.length - index}
                      disabled={disabled}
                      error={packageCardError}
                      index={index}
                      isEditable={!forbiddenEditProductPackage}
                      productPackage={productPackage}
                      showControls={!isSwapMode && !forbidden.edit}
                      onEditPackage={handleEditPackage}
                      onRemovePackage={() => onRemovePackage(index)}
                    />
                    {packageCardError && (
                      <FormError show>Исправьте ошибки в упаковке</FormError>
                    )}
                  </Container>
                </Fragment>
              );
            })}
          </Container>
        </div>
      )}
    </Container>
  );
};

export { PackagesList };
