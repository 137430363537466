import { CSSProperties, FC, Fragment } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { ArrowLeftIcon } from 'resources/icons/12';
import { HomeIcon } from 'resources/icons/18'
import { Nullable } from 'common/types/common.types';
import { Text, VerticalDivider } from 'UI';

import { useMasterPanelContext } from './MasterPanel';
import { MasterPanelHeaderButton } from '../../../features/products/create/_shared/styled';

const TEXT_STYLE: CSSProperties = { whiteSpace: 'nowrap' };

const HeaderWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    min-height: 46px;
    padding-right: ${space[5]}px;
    border-bottom: 1px solid ${colors.divider};

    ${styledSpace}
  `,
);

const SideHeadingButtonsWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[1]}px;
    margin: 0 ${space[1]}px;

    & button {
      border-radius: 0;

      &:hover {
        background-color: ${colors.highlight[1]};

        svg {
          color: ${colors.primary.main};
        }
      }
    }
  `,
);

interface IMasterPanelHeaderProps {
  title: string;
  leftAddon?: JSX.Element;
  rightAddon?: Nullable<JSX.Element>;
}

const MasterPanelHeader: FC<IMasterPanelHeaderProps & SpaceProps> = ({
  title,
  leftAddon,
  rightAddon,
  ...other
}) => {
  const { popPanelState, clearPanelState } = useMasterPanelContext();

  return (
    <HeaderWrapper {...other}>
      <SideHeadingButtonsWrapper>
        {leftAddon ?? (
          <Fragment>
            <MasterPanelHeaderButton
              title='На стартовый экран'
              onClick={clearPanelState}
            >
              <HomeIcon />
            </MasterPanelHeaderButton>
            <VerticalDivider height={46} />
            <MasterPanelHeaderButton title='Назад' onClick={popPanelState}>
              <ArrowLeftIcon />
            </MasterPanelHeaderButton>
          </Fragment>
        )}
      </SideHeadingButtonsWrapper>
      <Text fontWeight={600} style={TEXT_STYLE}>
        {title}
      </Text>
      {rightAddon}
    </HeaderWrapper>
  );
};

export { MasterPanelHeader };
