import { Fragment, useEffect, useImperativeHandle, ReactNode } from 'react';

import { useDrag } from 'react-dnd';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { getPropertyPath } from 'common/utils/properties';
import {
  markPropertyAsAdded,
  removeAddedProperty,
} from 'entities/master-panel/components/properties/store/actions';
import { useNotify } from 'entities/notify';
import { TrashIcon, EditMediumIcon } from 'resources/icons/18';
import { MoveIcon } from 'resources/other';
import { ContextVIcon } from 'resources/icons/18';
import { useAppSelector } from 'store';
import {
  Tooltip,
  IconButton,
  ContextMenu,
  useContextMenu,
  ContextMenuItem,
} from 'UI';

import {
  MainPropertyCheckbox,
  MAIN_PROPERTIES_MAX_COUNT,
} from './MainProperties';
import { IProperty, IPropertyRecursionProps } from './types';
import { usePropertiesContext } from './usePropertiesContext';

const ICON_BUTTON_STYLE = {
  borderRadius: 0,
  height: '100%',
  maxHeight: '34px',
};
const ICON_BUTTON_CONTEXT_STYLE = {
  borderRadius: '0 2px 2px 0',
  height: '100%',
  maxHeight: '34px',
};

const DragWrapper = styled.div`
  display: flex;
  cursor: pointer;
`;

const matchPropertyShape = (property1, property2): boolean => {
  if (!property1 || !property2) return false;

  return (
    property1.name === property2.name &&
    property1.type === property2.type &&
    property1.unit === property2.unit
  );
};

const getGroupDepth = (group, depth = 1): number => {
  let accDepth = depth;

  for (let i = 0; i < group.value.length; i += 1) {
    const property = group.value[i];

    if (property.type === PropertiesTypes.group) {
      const branchDepth = getGroupDepth(property, depth + 1);
      if (branchDepth > accDepth) accDepth = branchDepth;
    }
  }

  return accDepth;
};

interface IUsePropertyProps {
  // сокращённый вид, в котором нет выбора имени характеристики
  isNoName?: boolean;
  index: number;
  name: string;
  field: any;
  recursionProps: IPropertyRecursionProps;
  onCopy: (field: any) => void;
  onEdit: VoidFunction;
  onRemove: (index: number) => void;
}

const useProperty = ({
  isNoName,
  index,
  name: fieldName,
  field: { ref, value: property, setValue },
  recursionProps: { groupPath, parentGroup },
  onCopy,
  onEdit,
  onRemove,
}: IUsePropertyProps): {
  isDragging: boolean;
  isSelectMode: boolean;
  isMainProperty: boolean;
  isOpenContextMenu: boolean;
  leftActions: ReactNode;
  rightActions: ReactNode;
  mainPropertyAction: ReactNode;
  injectAdditionInfo?: (property: IProperty) => Nullable<JSX.Element>;
} => {
  const dispatch = useDispatch();
  const notify = useNotify();

  const {
    isSelectMode,
    dragType,
    mainProperties = [],
    onSelectMainProperty,
    injectAdditionInfo,
  } = usePropertiesContext();

  const {
    id,
    type,
    name,
    unit,
    propertyUniquenessId,
    _readOnly: readOnly,
  } = property;

  const formProperty = useAppSelector(({ productForms }) => {
    return productForms.byGroupPath[
      getPropertyPath({ group: groupPath, name, type, unit })
    ];
  });

  const { open, anchorEl, setAnchorEl, handleContextMenuClose } =
    useContextMenu();

  const isGroup = type === PropertiesTypes.group;
  const dragItem = { index, depth: 0, name: fieldName, parentGroup, isGroup };

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: dragType,
    item: () => {
      return isGroup
        ? { ...dragItem, depth: getGroupDepth(property) }
        : dragItem;
    },
    collect: monitor => ({ isDragging: !!monitor.isDragging() }),
  });

  useImperativeHandle(previewRef, () => ref.current);

  // if property is not group and has match, uniquenessId is enabled
  const uniquenessId =
    matchPropertyShape({ name, type, unit }, formProperty) && !isGroup
      ? formProperty.uniquenessId
      : null;

  // if property was added manually and it matches with product forms property,
  // property is set  product forms uniquenessId for synchronization
  useEffect(() => {
    if (formProperty && !propertyUniquenessId) {
      setValue(prev => ({ ...prev, uniquenessId: formProperty.uniquenessId }));
    }
  }, [formProperty, propertyUniquenessId, setValue]);

  // if product forms property is added to addedProperties by uniquenessId  --> markPropertyAsAdded
  useEffect(() => {
    dispatch(markPropertyAsAdded(uniquenessId));

    return () => {
      dispatch(removeAddedProperty(uniquenessId));
    };
  }, [uniquenessId, dispatch]);

  const isMainProperty = mainProperties.includes(id);
  const isCheckDisabled =
    mainProperties.length === MAIN_PROPERTIES_MAX_COUNT && !isMainProperty;

  const isCompositeProperty =
    type === PropertiesTypes.group ||
    type === PropertiesTypes.standardMaterial ||
    type === PropertiesTypes.standardCoating ||
    type === PropertiesTypes.arbitraryStandardMaterial ||
    type === PropertiesTypes.arbitraryStandardCoating;

  const menuItems = [
    <ContextMenuItem
      key='edit'
      as='button'
      text={isCompositeProperty ? 'Переименовать' : 'Редактировать'}
      onClick={onEdit}
    />,
    <ContextMenuItem
      key='copy'
      as='button'
      text='Дублировать'
      onClick={onCopy}
    />,
    type !== PropertiesTypes.group && onSelectMainProperty && (
      <ContextMenuItem
        key='mainProperties'
        as='button'
        disabled={isCheckDisabled}
        text={
          isMainProperty
            ? 'Убрать из основных характеристик'
            : 'Добавить в основные характеристики'
        }
        onClick={() => {
          onSelectMainProperty(id);
          notify.success(
            'Изменения в выборе основных характеристик сохранены.',
          );
        }}
      />
    ),
  ].filter(Boolean);

  const iconVariant =
    type === PropertiesTypes.group ? 'highlight' : 'secondary';

  const mainPropertyAction = isSelectMode ? (
    <MainPropertyCheckbox
      disabled={isCheckDisabled}
      isMainProperty={isMainProperty}
      isSelectMode={isSelectMode}
      onCheck={() => onSelectMainProperty(id)}
    />
  ) : null;

  const leftActions =
    readOnly || isNoName ? null : (
      <Tooltip placement='bottom-start' title={!isDragging && 'Перетащить'}>
        <DragWrapper ref={dragRef}>
          <MoveIcon />
        </DragWrapper>
      </Tooltip>
    );

  const rightActions = readOnly ? null : (
    <Fragment>
      <IconButton
        disabled={isDragging}
        style={ICON_BUTTON_STYLE}
        variant={iconVariant}
        onClick={onRemove}
      >
        <TrashIcon />
      </IconButton>
      {isNoName && (
        <IconButton
          disabled={isDragging}
          style={ICON_BUTTON_STYLE}
          variant={iconVariant}
          onClick={onEdit}
        >
          <EditMediumIcon />
        </IconButton>
      )}
      {!isNoName && (
        <>
          <IconButton
            focused
            disabled={isDragging}
            style={ICON_BUTTON_CONTEXT_STYLE}
            variant={iconVariant}
            onClick={({ currentTarget }) => {
              setAnchorEl(prev => (prev ? null : currentTarget));
            }}
          >
            <ContextVIcon />
          </IconButton>
          <ContextMenu
            anchorEl={anchorEl}
            open={open}
            placement='bottom-end'
            onRequestClose={handleContextMenuClose}
          >
            {menuItems}
          </ContextMenu>
        </>
      )}
    </Fragment>
  );

  return {
    isDragging,
    isSelectMode,
    isMainProperty,
    isOpenContextMenu: open,
    leftActions,
    rightActions,
    mainPropertyAction,
    injectAdditionInfo,
  };
};

export { useProperty };
