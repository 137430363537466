import { Fragment } from 'react';

import { throttle } from 'lodash';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { FCWithBlankChildren } from 'common/types/common.types';
import { removeLSItem, setLSItem } from 'common/utils';
import { ACCESS_TOKEN, IDENTITY, identityActions } from 'entities/identity';
import { ModalProvider } from 'entities/modals';
import store from 'store/store';
import theme from 'UI/theme';

import { GlobalStyles } from './GlobalStyles';

store.subscribe(
  throttle(() => {
    const { identity } = store.getState();
    setLSItem(IDENTITY, identity);
  }, 1000),
);

window.addEventListener('storage', ({ key, newValue }) => {
  if (key === IDENTITY) {
    store.dispatch(identityActions.setIdentity(JSON.parse(newValue ?? '')));
    if (newValue === null) {
      removeLSItem(ACCESS_TOKEN);
    }
  }
});

const Providers: FCWithBlankChildren = ({ children }) => {
  return (
    <ReduxProvider store={store}>
      <ThemeProvider theme={theme}>
        <ModalProvider>
          <BrowserRouter>
            <Fragment>
              {/* @ts-ignore */}
              <DndProvider backend={HTML5Backend}>{children}</DndProvider>
              <GlobalStyles />
            </Fragment>
          </BrowserRouter>
        </ModalProvider>
      </ThemeProvider>
    </ReduxProvider>
  );
};

export { Providers };
