import { useState, useEffect, memo, useRef, FC } from 'react';

import styled, { css } from 'styled-components';

import { MetaHead } from 'components/meta-head';
import { useSupport } from 'nomenclature/support';
import { useToggle } from 'tools/hooks';

import {
  Footer,
  Header,
  Search,
  Project,
  Partners,
  Products,
  Categories,
  Statistics,
} from './components';
import { ServicePanel } from '../../entities/service-panel';

const StartContainer = styled.div<{ openCatalogsMenu: boolean }>(
  ({ theme: { colors }, openCatalogsMenu }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    font-family: Commissioner, sans-serif;
    line-height: 1.5;
    font-weight: 500;
    text-rendering: optimizeSpeed;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    background: ${colors.white};
    overflow-x: hidden;
    overflow-y: ${openCatalogsMenu ? 'hidden' : 'scroll'};
  `,
);

const StartingPage: FC = memo(() => {
  const ref = useRef<HTMLDivElement>(null);

  useSupport({ hidden: true });

  const [openCatalogsMenu, toggleCatalogsMenu] = useToggle();
  const [containerElement, setContainerElement] = useState<HTMLDivElement>();

  useEffect(() => {
    if (ref.current) setContainerElement(ref.current);
  }, [ref]);

  return (
    <StartContainer ref={ref} openCatalogsMenu={openCatalogsMenu}>
      <MetaHead />
      <ServicePanel isHidden />
      <Header
        containerElement={containerElement}
        openCatalogsMenu={openCatalogsMenu}
        toggleCatalogsMenu={toggleCatalogsMenu}
      />
      <Statistics />
      <Products />
      <Categories />
      <Search />
      <Partners />
      <Project />
      <Footer />
    </StartContainer>
  );
});

export { StartingPage };
