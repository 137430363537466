import {
  ForwardedRef,
  forwardRef,
  Fragment,
  MouseEvent,
  ReactElement,
  useRef,
  useState,
} from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { DEFAULT_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { formatDate } from 'common/utils';

import { CalendarIconButton } from './DateInput';
import { DatePickerDropdown } from '../DatePicker';
import { TextInput } from '../Inputs/TextInput';

const DateContainer = styled.div(
  ({ theme: { borderRadius } }) => css`
    display: flex;

    & > * {
      &:not(:last-child) {
        border-right-color: transparent;
      }

      &:first-child {
        border-radius: ${borderRadius}px 0 0 ${borderRadius}px;
      }

      &:last-child {
        border-radius: 0 ${borderRadius}px ${borderRadius}px 0;
      }
    }

    ${styledSpace};
  `,
);

const DATE_NAME_LEFT = 'left';
const DATE_NAME_RIGHT = 'right';

interface IDateInputRangeProps {
  leftPlaceholder?: string;
  rightPlaceholder?: string;
  /**
   * Дизейблим даты правого инпута, даты которого меньше переданного Date
   */
  prevRightDisabled?: Date;
  /**
   * Дизейблим даты левого инпута, даты которого больше переданного Date
   */
  nextLeftDisabled?: Date;
  canClear?: boolean;
  leftValue: string;
  rightValue: string;
  onChangeLeft: (date: Date | '') => void;
  onChangeRight: (date: Date | '') => void;
}

function DateInputRangeInner(
  {
    leftPlaceholder = 'c',
    rightPlaceholder = 'по',
    nextLeftDisabled,
    prevRightDisabled,
    canClear,
    leftValue,
    rightValue,
    onChangeLeft,
    onChangeRight,
    ...other
  }: IDateInputRangeProps & SpaceProps,
  ref: ForwardedRef<HTMLDivElement>,
): ReactElement {
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const leftInputRef = useRef<HTMLDivElement>(null);
  const rightInputRef = useRef<HTMLDivElement>(null);

  // сейчас активен правый инпут
  const isRightTextInput = anchorEl?.dataset.name === DATE_NAME_RIGHT;

  const currentValue = isRightTextInput ? rightValue : leftValue;
  const currentDateValue =
    currentValue && !Number.isNaN(Date.parse(currentValue))
      ? new Date(currentValue)
      : '';
  const leftDateText =
    leftValue && !Number.isNaN(leftValue)
      ? formatDate(leftValue, false, DEFAULT_DATE_PATTERN)
      : '';

  const rightDateText =
    rightValue && !Number.isNaN(rightValue)
      ? formatDate(rightValue, false, DEFAULT_DATE_PATTERN)
      : '';

  const isAvailableToClearLeftValue = canClear && !!leftDateText;
  const isAvailableToClearRightValue = canClear && !!rightDateText;

  const handleLeftOpen = (): void => {
    setAnchorEl(leftInputRef.current);
  };

  const handleRightOpen = (): void => {
    setAnchorEl(rightInputRef.current);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  const handleChange = (date: Date): void => {
    if (anchorEl?.dataset.name === DATE_NAME_RIGHT) {
      onChangeRight(date);
    } else {
      onChangeLeft(date);
    }
    handleClose();
  };

  const handleClear = (event: MouseEvent<HTMLButtonElement>): void => {
    if (event?.currentTarget.dataset.name === DATE_NAME_RIGHT) {
      onChangeRight('');
    } else {
      onChangeLeft('');
    }
    handleClose();
  };

  return (
    <Fragment>
      <DateContainer ref={ref} {...other}>
        <TextInput
          ref={leftInputRef}
          data-name={DATE_NAME_LEFT}
          placeholder={leftPlaceholder}
          rightAddon={
            <CalendarIconButton
              data-name={DATE_NAME_LEFT}
              isAvailableToClear={isAvailableToClearLeftValue}
              onClear={handleClear}
              onOpen={handleLeftOpen}
            />
          }
          value={leftDateText}
          onFocus={handleLeftOpen}
        />
        <TextInput
          ref={rightInputRef}
          data-name={DATE_NAME_RIGHT}
          placeholder={rightPlaceholder}
          rightAddon={
            <CalendarIconButton
              data-name={DATE_NAME_RIGHT}
              isAvailableToClear={isAvailableToClearRightValue}
              onClear={handleClear}
              onOpen={handleRightOpen}
            />
          }
          value={rightDateText}
          onFocus={handleRightOpen}
        />
      </DateContainer>
      <DatePickerDropdown
        anchorEl={anchorEl}
        isOpen={Boolean(anchorEl)}
        nextDisabledDate={isRightTextInput ? undefined : nextLeftDisabled}
        prevDisabledDate={isRightTextInput ? prevRightDisabled : undefined}
        value={currentDateValue}
        onChange={handleChange}
        onRequestClose={handleClose}
      />
    </Fragment>
  );
}

const DateInputRange = forwardRef(DateInputRangeInner);

export { DateInputRange };
