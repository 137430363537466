import styled, { css } from 'styled-components';
import {
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  SpaceProps,
} from 'styled-system';

import { Text } from 'UI';

export const CompanyTitle = styled(Text)<
  {
    truncate?: boolean;
  } & FontSizeProps &
    FontWeightProps &
    SpaceProps
>(
  ({ theme: { colors, fontSizes } }) => css`
    display: -webkit-box;
    color: ${colors.text.primary};
    font-size: ${fontSizes[4]}px;
    font-weight: 600;
    word-break: break-word;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    a&:hover {
      color: ${colors.text.primary};
    }

    ${fontWeight};
  `,
);
