import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { IconButton, Text } from 'UI';

export const CreateCardContainer = styled.div<
  SpaceProps & { sticky?: boolean }
>(
  ({ theme: { space, colors, borderRadius, shadows, zIndex }, sticky }) => css`
    width: 100%;
    background: ${colors.white};

    ${sticky &&
    css`
      position: sticky;
      bottom: 16px;
      padding: ${space[4]}px;
      border: 1px solid ${colors.divider};
      border-radius: ${borderRadius}px;
      box-shadow: ${shadows[2]};
      z-index: ${zIndex.sideMenu - 1};
    `}

    ${styledSpace};
  `,
);

export const ReadyCardContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    width: 100%;
    padding: ${space[2]}px ${space[3]}px ${space[3]}px;
    border: 1px solid ${colors.divider};
    border-bottom: none;

    &:last-child {
      border-bottom: 1px solid ${colors.divider};
      margin-bottom: ${space[2]}px;
    }
  `,
);

export const StyledIconButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.highlight[0]};
  `,
);

export const InfoPanel = styled.div<SpaceProps & { split?: boolean }>(
  ({ split }) => css`
    display: flex;
    align-items: center;
    overflow: hidden;

    ${split && 'justify-content: space-between;'}

    ${styledSpace};
  `,
);

export const Quote = styled.div<{ isText: boolean }>(
  ({ theme: { space, colors, borderRadius }, isText }) => css`
    display: flex;
    align-items: center;
    padding: ${space[1]}px;
    min-height: ${space[4]}px;
    background: ${colors.preview};
    border-radius: ${borderRadius}px;

    ${isText &&
    css`
      align-items: initial;
      max-height: 200px;
      width: 100%;
      white-space: pre-line;
      word-break: break-all;
      overflow-y: auto;
    `}
  `,
);

export const ImageWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: ${space[7]}px;
    width: ${space[7]}px;
    background: ${colors.white};
  `,
);

export const ReplyContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    margin-left: 36px;
    padding: ${space[2]}px;
    background: ${colors.background};
  `,
);

export const StyledHiddenIconButton = styled(IconButton)`
  display: none;

  ${ReplyContainer}:hover & {
    display: flex;
  }
`;

export const StyledText = styled(Text)`
  word-break: break-word;
`;

export const Form = styled.form`
  ${styledSpace};
`;
