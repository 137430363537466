import { Dispatch, FC, memo, SetStateAction } from 'react';

import styled, { css } from 'styled-components';

import { fetchCommonSearch } from 'common/api/common-search';
import { fetchProducts } from 'common/api/products.api';
import {
  LIFE_STATE_COLORS,
  LIFE_STATE_NAMES,
  PRODUCT_CATEGORY_TEXT,
} from 'common/constants/products.const';
import { IProduct, ProductLifeState } from 'common/types/product.types';
import { ReferenceType } from 'common/types/product.types/commercial-infromation.types';
import { getImageURL } from 'common/utils';
import { getProductFullName } from 'common/utils/products';
import { FieldWrapper } from 'components/fields';
import { MainProperties } from 'components/products/properties';
import { standardToString } from 'entities/composition/components/modals/utils';
import { useField, useReform, useWatch } from 'reform';
import { itemTo } from 'tools/utils';
import {
  Avatar,
  Badge,
  ButtonRounded,
  Container,
  Select,
  Text,
  TextInput,
} from 'UI';
import { FetchOptionsType, ItemToStringAndOptionsType } from 'UI/Inputs';

import { REFERENCE_TYPES_TO_TEXT } from '../../../../../constants';
import { ReferencesTypes } from '../../../../../types';

const FIELD_NAME = 'reference';

const PlaceholderContainer = styled.div(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: flex;
    gap: ${space[0]}px;
    width: 100%;
    height: ${space[4]}px;
    align-items: center;
    padding-left: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    background: ${colors.highlight[0]};
  `,
);

const productToString: ItemToStringAndOptionsType = (
  product: IProduct,
  { isOption }: { isOption?: boolean } = {},
) => {
  if (!product.category) return null;

  const productName = getProductFullName(product);

  if (isOption) {
    return (
      <Container alignItems='center' width='100%'>
        <Avatar mr={2} size='xxs' url={getImageURL(product.previewImage)} />
        <Container column maxWidth={380} mr={1}>
          <Text
            truncate
            color={productName ? 'text.primary' : 'text.disabled'}
            lineHeight='18px'
          >
            {productName || 'Не указано'}
          </Text>
          <Text color='text.secondary' fontSize={2} lineHeight='16px'>
            {PRODUCT_CATEGORY_TEXT[product.category]}
          </Text>
        </Container>
        <Badge
          dot={LIFE_STATE_COLORS[product.lifeState]}
          label={LIFE_STATE_NAMES[product.lifeState]}
          ml='auto'
          style={{ background: 'transparent' }}
          type={Badge.TYPES.DEFAULT}
        />
        <MainProperties product={product} />
      </Container>
    );
  }

  return productName || 'Не указано';
};

const fetchProductOptions =
  (type: ReferencesTypes, manufacturer?: string): FetchOptionsType =>
  ({ name, page }) => {
    return fetchProducts({
      page,
      search: name,
      category: type,
      lifeState: [ProductLifeState.public],
      company: manufacturer,
    });
  };

const fetchStandardOptions: FetchOptionsType = ({ name, page }) => {
  return fetchCommonSearch({
    search: name,
    entities: [ReferencesTypes.standard],
    page,
  });
};

interface IGetSelectPropsReturnProps {
  placeholder: string;
  options: FetchOptionsType;
  itemToString: ItemToStringAndOptionsType;
}

const getSelectProps = (
  type: ReferencesTypes,
  manufacturer?: string,
): IGetSelectPropsReturnProps => {
  if (type === ReferencesTypes.standard) {
    return {
      placeholder: 'Введите наименование или UIN',
      options: fetchStandardOptions,
      itemToString: standardToString,
    };
  }

  return {
    placeholder: 'Введите название или UIN',
    options: fetchProductOptions(type, manufacturer),
    itemToString: productToString,
  };
};

const ItemUin: FC<{ item: any }> = ({ item }) => {
  if (!item || !item.uin) return null;

  return (
    <Text alignSelf='center' fontSize={1} mr={1}>
      UIN: {item.uin}
    </Text>
  );
};

interface IReferenceBodyProps {
  value: ReferenceType;
  setValue: Dispatch<SetStateAction<ReferenceType>>;
}

const ReferenceBody: FC<IReferenceBodyProps> = memo(({ value, setValue }) => {
  const { slot, manufacturer } = useWatch({});
  const { setFieldValue } = useReform();

  if (value.type === ReferencesTypes.slot) {
    const handleChange = ({ target }): void => {
      setValue(prev => ({ ...prev, id: target.value }));
    };

    return (
      <TextInput
        limit={150}
        placeholder='Введите название изделия'
        value={value.id}
        onChange={handleChange}
      />
    );
  }

  const handleChange = (newValue): void => {
    setValue(prev => ({ ...prev, id: newValue ?? '' }));

    /**
     * Сбрасываем производителя при сбросе выбранного товара
     */
    if (
      (value.type === ReferencesTypes.product ||
        value.type === ReferencesTypes.questionnaire) &&
      !newValue
    ) {
      setFieldValue('manufacturer', null);
    }
  };

  const rightAddon = <ItemUin item={value.id} />;

  /**
   * Если в слоте был указан производитель, то при заполнении слота получаем товары
   * по этому производителю
   */
  const slotManufacturer = slot ? manufacturer?.id : undefined;

  return (
    <Select
      itemToKey={itemTo('id')}
      {...getSelectProps(value.type, slotManufacturer)}
      rightAddon={rightAddon}
      value={value.id}
      onChange={handleChange}
    />
  );
});

const ProductReference: FC = memo(() => {
  const {
    value,
    setValue,
    form: {
      initialValues: { slot },
    },
  } = useField({ name: FIELD_NAME });
  const handleSetReferenceType = (type: ReferencesTypes) => () => {
    setValue({ type, id: '' });
  };

  const handleReset = (): void => {
    setValue(null);
  };

  return (
    <FieldWrapper
      required
      cancelButton={value && { onRemove: handleReset }}
      label='Товар'
    >
      {value ? (
        <ReferenceBody setValue={setValue} value={value} />
      ) : (
        <PlaceholderContainer>
          {Object.values(ReferencesTypes).map(type => {
            if (type === ReferencesTypes.slot && !!slot) return null;

            return (
              <ButtonRounded
                key={type}
                size='s'
                variant='outlined'
                onClick={handleSetReferenceType(type)}
              >
                + {REFERENCE_TYPES_TO_TEXT[type]}
              </ButtonRounded>
            );
          })}
        </PlaceholderContainer>
      )}
    </FieldWrapper>
  );
});

export { ProductReference };
