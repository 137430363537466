import styled, { css } from 'styled-components';

import { PROPERTIES_TYPES } from 'common/constants/products.const';
import { PROPERTY_ICON } from 'common/constants/properties.const';
import { getPropertyLabel } from 'common/utils/properties';
import { ViewTemplates } from 'components/characteristic-elements/ViewMode';
import { renderProperty } from 'components/products/properties/utils';
import { Container, DescriptionList, Text } from 'UI';

const COLLAPSED_PADDING = { padding: 0 };

const LeftActionsWrapper = styled.div`
  display: none;
`;

const IconContainer = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    display: flex;
    width: ${space[4]}px;
    min-width: ${space[4]}px;
    height: 100%;
    align-items: center;
    justify-content: center;
  `,
);

const PropertyIcon = styled.div`
  position: relative;
  display: flex;
`;

const HoverIconContainer = styled(IconContainer)`
  ${ViewTemplates.Container}:hover & {
    & ${PropertyIcon} {
      display: none;
    }

    & > ${LeftActionsWrapper} {
      display: flex;
    }
  }
`;

const MainPropertyBadge = styled.div(
  ({ theme: { colors, space } }) => css`
    position: absolute;
    bottom: -1px;
    right: -1px;
    width: ${space[1]}px;
    height: ${space[1]}px;
    background: ${colors.primary.main};
    border: 1px solid ${colors.white};
    border-radius: 50%;
  `,
);

const getLeftAddon = ({
  type,
  mainProperty,
  mainPropertyAction,
  leftActions,
}) => {
  const IconByType = PROPERTY_ICON[type];
  const iconColor = type === PROPERTIES_TYPES.GROUP ? 'highlight.4' : 'divider';
  const icon = (
    <PropertyIcon>
      <IconByType color={iconColor} />
      {mainProperty && <MainPropertyBadge />}
    </PropertyIcon>
  );

  const leftAddon = leftActions ? (
    <HoverIconContainer>
      {icon}
      <LeftActionsWrapper>{leftActions}</LeftActionsWrapper>
    </HoverIconContainer>
  ) : (
    <IconContainer>{icon}</IconContainer>
  );

  return mainPropertyAction ? (
    <IconContainer>{mainPropertyAction}</IconContainer>
  ) : (
    leftAddon
  );
};

function ViewTemplate({
  fieldRef,
  visibleMenuControl,
  disabled,
  mainProperty,
  type,
  title,
  mainPropertyAction,
  leftActions,
  rightActions,
  children,
  onToggleConfirmed,
  ...other
}) {
  const leftAddon = getLeftAddon({
    type,
    mainProperty,
    mainPropertyAction,
    leftActions,
  });

  return (
    <ViewTemplates.SimpleComponent
      ref={fieldRef}
      disabled={disabled}
      visibleRightActions={visibleMenuControl}
      leftAction={leftAddon}
      rightActions={rightActions}
      onClick={onToggleConfirmed}
      title={title}
      {...other}
    >
      {children}
    </ViewTemplates.SimpleComponent>
  );
}

function PropertiesView({
  visibleMenuControl,
  disabled,
  mainProperty,
  title,
  level = 0,
  translation,
  fieldRef,
  property,
  leftActions,
  rightActions,
  injectAdditionInfo,
  mainPropertyAction,
  onToggleConfirmed,
  ...other
}) {
  const paddingByLevel = level * 17 + level;

  const leftAddon = getLeftAddon({
    type: property.type,
    mainProperty,
    mainPropertyAction,
    leftActions,
  });

  const label = injectAdditionInfo ? (
    <Container display='inline-flex' alignItems='center'>
      {injectAdditionInfo?.(property)}
      {getPropertyLabel(property, { t: translation })}
    </Container>
  ) : (
    getPropertyLabel(property, { t: translation })
  );

  return (
    <ViewTemplates.SimpleComponent
      ref={fieldRef}
      disabled={disabled}
      visibleRightActions={visibleMenuControl}
      leftAction={leftAddon}
      rightActions={rightActions}
      onClick={onToggleConfirmed}
      title={title}
      {...other}
    >
      <DescriptionList.Property
        dotted
        widthKey={`50% - ${paddingByLevel}px`}
        label={label}
        backgroundColor='background'
      >
        {renderProperty({ property })}
      </DescriptionList.Property>
    </ViewTemplates.SimpleComponent>
  );
}

function PropertiesNestedView({
  fieldRef,
  visibleMenuControl,
  type,
  label,
  // рисовать label на всю ширину поля
  withWideLabel,
  title,
  children,
  leftActions,
  mainProperty,
  mainPropertyAction,
  onToggleConfirmed,
  ...other
}) {
  const leftAddon = getLeftAddon({
    type,
    mainProperty,
    mainPropertyAction,
    leftActions,
  });

  return (
    <ViewTemplates.CollapseComponent
      withWideLabel={withWideLabel}
      ref={fieldRef}
      style={COLLAPSED_PADDING}
      visibleRightActions={visibleMenuControl}
      label={
        <Text truncate ml={1} width={withWideLabel ? '100%' : undefined}>
          {label}
        </Text>
      }
      leftAction={leftAddon}
      onClick={onToggleConfirmed}
      title={title}
      {...other}
    >
      {children}
    </ViewTemplates.CollapseComponent>
  );
}

export { ViewTemplate, PropertiesView, PropertiesNestedView };
