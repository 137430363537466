import {
  useState,
  useEffect,
  useRef,
  memo,
  FC,
  ReactNode,
  ChangeEvent,
} from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { useOutsideRef } from 'common/hooks';
import { CloseMediumIcon } from 'resources/icons/18';
import { IconButton } from 'UI';
import { InputContainer } from 'UI/Inputs/components';

import { useMasterPanelEntityFormContext } from '../../../index';
import { SearchWrapper, SearchIconWrapper } from '../../styled';

export const SearchContainer = styled(InputContainer)(
  ({ theme: { fontSizes } }) => css`
    border-width: 0;

    & input {
      padding-left: 0;
      font-size: ${fontSizes[5]}px;
    }
  `,
);

const Input = styled.input(
  ({ theme: { colors } }) => css`
    width: 100%;
    color: ${colors.text.primary};
    background: transparent;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

const StyledIconButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    &&:hover {
      background: ${colors.highlight[1]};
    }
  `,
);

interface ISearchProps extends SpaceProps {
  searchValue: string;
  productsCount?: number;
  onSearch: (value: string) => void;
  leftAddon?: ReactNode;
}

const Search: FC<ISearchProps> = memo(
  ({ searchValue, productsCount, onSearch, leftAddon, ...other }) => {
    const containerRef = useRef<HTMLInputElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const {
      screen: { data, setScreen },
    } = useMasterPanelEntityFormContext();

    const [inputValue, setInputValue] = useState(searchValue);

    useEffect(() => {
      if (typeof data === 'string') {
        setInputValue(data);
        onSearch(data);

        setScreen(null);
      }
    }, [data, setScreen, onSearch]);

    useOutsideRef({
      elementRef: containerRef,
      onOutsideClick: () => {
        inputRef.current!.value = searchValue;
        setInputValue(searchValue);
      },
    });

    const handleSubmit = (): void => {
      onSearch(inputRef.current!.value);
    };

    const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
      setInputValue(target.value);
    };

    const handleReset = (): void => {
      setInputValue('');
      inputRef.current!.value = '';
      inputRef.current!.focus();
      onSearch('');
    };

    const handleEnterPress = ({ key }): void => {
      if (key === 'Enter') handleSubmit();
    };

    return (
      <SearchWrapper {...other}>
        {leftAddon}
        <SearchContainer ref={containerRef}>
          <Input
            ref={inputRef}
            disabled={!inputValue && !searchValue && productsCount === 0}
            placeholder='Название, типа товара, UIN'
            type='text'
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleEnterPress}
          />
          {inputValue && (
            <IconButton mr={1} size={34} title='Удалить' onClick={handleReset}>
              <CloseMediumIcon />
            </IconButton>
          )}
          <StyledIconButton
            disabled={!inputValue}
            size={34}
            title='Найти'
            onClick={handleSubmit}
          >
            <SearchIconWrapper isDisabled={!inputValue} />
          </StyledIconButton>
        </SearchContainer>
      </SearchWrapper>
    );
  },
);

export { Search };
