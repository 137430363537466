import { FC, Fragment, memo, useRef } from 'react';

import styled, { css } from 'styled-components';

import {
  fetchNotifications,
  markAllNotificationsAsViewed,
  markNotificationsAsRead,
} from 'common/api/notifications.api';
import { useGlobalLoader, useQueryParams, useScrollRequest } from 'common/hooks';
import { formatDateRelative } from 'common/utils';
import { isExistNotification, NotificationCard } from 'components/notifications';
import { HeadingControls } from 'layouts/components';
import { CenteredPage } from 'layouts/Pages';
import { ProductsIcon } from 'resources/placeholders';
import { Button, Container, Text } from 'UI';

import { NotificationsSideMenu } from './components';
import { SupportSections, useSupport } from '../support';

const HEADING = 'Лента уведомлений';

const TIP =
  'Актуальные события и уведомления профиля. Для фильтрации используйте Левую панель.';

const DateContainer = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: inline-block;
    margin-bottom: ${space[1]}px;
    padding: 0 ${space[1]}px;
    height: 20px;
    line-height: 20px;
    background: ${colors.highlight[0]};
    border-radius: 8px;
    color: ${colors.text.primary};
    font-size: ${fontSizes[0]}px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: uppercase;
  `,
);

const SUBMENU = <NotificationsSideMenu />;

interface IDateProps {
  date: Date;
}

const NotificationsDate: FC<IDateProps> = memo(({ date }) => {
  return <DateContainer>{formatDateRelative(date)}</DateContainer>;
});

const NotificationsPage: FC = () => {
  const [{ section = null }] = useQueryParams();
  const containerRef = useRef<HTMLDivElement>(null);

  const { isLoading, data: notifications } = useScrollRequest(
    fetchNotifications,
    { section },
    {
      callback: member => {
        const notViewedData = member.filter(({ isViewed }) => !isViewed);
        if (notViewedData.length) {
          markNotificationsAsRead(notViewedData);
        }
      },
    },
  );

  const firstDate = new Date(notifications[0]?.createdAt);

  useSupport({
    sectionKey: SupportSections.notificationFeed,
  });

  useGlobalLoader(isLoading);

  const handleMarkAllAsViewed = (): void => {
    markAllNotificationsAsViewed(notifications[0].id).then(() => {
      window.location.reload();
    });
  };

  return (
    <CenteredPage
      contentRef={containerRef}
      heading={{
        heading: HEADING,
        tip: TIP,
        controls: (
          <HeadingControls
            controls={
              <Button
                color='info'
                ml='auto'
                variant='filled'
                onClick={handleMarkAllAsViewed}
              >
                Отметить все как прочитанные
              </Button>
            }
            isShowPaidTooltip={false}
          />
        ),
      }}
      p='0px'
      subMenu={SUBMENU}
      width={CenteredPage.CONTENT_WIDTH.SMALL}
    >
      {notifications.length > 0 ? (
        <Container display='block' p={2} textAlign='center'>
          <NotificationsDate key={firstDate.getTime()} date={firstDate} />
          {notifications.map((event, index, arr) => {
            const eventDate = new Date(event.createdAt);
            const prevEvent = index > 0 ? arr[index - 1] : event;
            const prevEventDate = new Date(prevEvent.createdAt);

            const isEventDate =
              eventDate.getDate() < prevEventDate.getDate() ||
              eventDate.getMonth() < prevEventDate.getMonth() ||
              eventDate.getFullYear() < prevEventDate.getFullYear();

            if (!isExistNotification(event)) return null;

            if (isEventDate) {
              return (
                <Fragment key={event.id}>
                  <NotificationsDate date={eventDate} />
                  <NotificationCard notification={event} />
                </Fragment>
              );
            }

            return <NotificationCard key={event.id} notification={event} />;
          })}
        </Container>
      ) : (
        <Container column mt='15%' py={4} textAlign='center'>
          <ProductsIcon mb={4} />
          <Text as='div' color='text.secondary' fontSize={5} textAlign='center'>
            Нет уведомлений.
          </Text>
        </Container>
      )}
    </CenteredPage>
  );
};

export { NotificationsPage };
