import { FC, memo, useState } from 'react';

import styled, { css } from 'styled-components';

import { useRequest } from 'common/hooks';
import { useNotify } from 'entities/notify';
import { ExportIcon } from 'resources/modal';
import { STUB_FUNC } from 'tools/utils';
import { Checkbox, Modal, Spinner, Text } from 'UI';

import { fetchExportChannels, updateExportChannelProduct } from '../../../features/export/api';
import { PRODUCTS_STATUSES } from '../../../features/export/constants';

// TODO: Актуализировать статус файла. Модалки не используются, так как выключен экспорт.

const PROMISE_STATUS = {
  REJECTED: 'rejected',
  FULFILLED: 'fulfilled',
};

const ContentWrapper = styled.div`
  height: 255px;
`;

const Item = styled.label<{ disabled: boolean }>(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: flex;
    align-items: center;
    height: ${space[4]}px;
    padding: ${space[0]}px ${space[1]}px;
    color: ${colors.text.secondary};
    margin-bottom: ${space[0]}px;
    border-radius: ${borderRadius}px;
    cursor: pointer;

    &:hover {
      background: ${colors.highlight[0]};
    }

    &[aria-selected='true'] {
      background: ${colors.highlight[2]};
    }

    &[disabled] {
      cursor: default;
      color: ${colors.text.disabled};
      background: none;
    }
  `,
);

interface IAddToExportProps {
  selected: any[];
  exports: any[];
  setSelected: any;
}

const AddToExport: FC<IAddToExportProps> = memo(
  ({ selected, exports, setSelected }) => {
    const availableExports = exports.filter(({ isLocked }) => !isLocked);

    const isLockedAll = availableExports.length === 0;

    const isSelectedAll =
      availableExports.length > 0 &&
      selected.length === availableExports.length;

    const handleSelectAll = (): void => {
      if (isSelectedAll) {
        setSelected([]);
      } else {
        setSelected([...availableExports]);
      }
    };

    if (exports.length < 1) {
      return (
        <Modal.Placeholder height={255}>
          Каналов нет. <br />
          Создайте каналы экспорта.
        </Modal.Placeholder>
      );
    }

    return (
      <>
        <Item disabled={isLockedAll}>
          <Text fontSize={3} fontWeight={500}>
            Выберите каналы
          </Text>
          <Checkbox
            disabled={isLockedAll}
            ml='auto'
            value={isSelectedAll}
            onChange={handleSelectAll}
          />
        </Item>
        {exports.map(item => {
          const { id: channelId, name, isLocked } = item;
          const isSelected = !!selected.find(({ id }) => id === channelId);
          const handleSelect = (): void => {
            setSelected(prev =>
              isSelected
                ? prev.filter(({ id }) => id !== channelId)
                : [...prev, item],
            );
          };

          return (
            <Item
              key={channelId}
              aria-selected={isSelected}
              disabled={isLocked}
            >
              <Text truncate mr={1}>
                {name}
              </Text>
              {isLocked && (
                <Text fontSize={1} ml='auto' mr={2}>
                  Редактируется
                </Text>
              )}
              <Checkbox
                {...(!isLocked && { ml: 'auto' })}
                disabled={isLocked}
                value={isSelected}
                onChange={handleSelect}
              />
            </Item>
          );
        })}
      </>
    );
  },
);

interface IAddToExportModalProps {
  productIds: string[];
  onSubmit: VoidFunction;
  handleModalClose: VoidFunction;
}

interface ISelected {
  id: number;
  name: string;
}

const AddToExportModal: FC<IAddToExportModalProps> = memo(
  ({ productIds, onSubmit = STUB_FUNC.NOOP, handleModalClose }) => {
    const notify = useNotify();
    const [selected, setSelected] = useState<ISelected[]>([]);
    const { isLoading, data = { member: [] } } = useRequest(
      fetchExportChannels,
      [],
    );

    const handleSubmit = async (): Promise<void> => {
      const results = await Promise.allSettled(
        selected.map(selectedChannel =>
          updateExportChannelProduct(selectedChannel.id, {
            productIds,
            status: PRODUCTS_STATUSES.ATTACHED,
          }),
        ),
      );

      const hasError = results.some(
        result => result.status === PROMISE_STATUS.REJECTED,
      );

      if (hasError || results.length === 1) {
        results.forEach((result, index) => {
          if (result.status === PROMISE_STATUS.REJECTED) {
            notify.error([
              'Невозможно изменить канал экспорта ',
              <strong key={PROMISE_STATUS.REJECTED}>
                {selected[index].name}
              </strong>,
              ', так как с ним работает другой сотрудник. Повторите попытку позже.',
            ]);
          } else if (result.status === PROMISE_STATUS.FULFILLED) {
            notify.success([
              `${
                productIds.length > 1 ? 'Товары привязаны' : 'Товар привязан'
              } к каналу `,
              <strong key={PROMISE_STATUS.FULFILLED}>
                {selected[index].name}
              </strong>,
            ]);
          }
        });
      } else {
        notify.success(
          `${
            productIds.length > 1 ? 'Товары привязаны' : 'Товар привязан'
          } к каналам экспорта`,
        );
      }
      onSubmit();
      handleModalClose();
    };

    return (
      <Modal disabled={selected.length === 0} onSubmit={handleSubmit}>
        <Modal.Default
          icon={<ExportIcon />}
          submitButton='Добавить'
          title='Добавить в канал экспорта'
        >
          <ContentWrapper>
            <Spinner delay={0} height='100%' loading={isLoading}>
              <AddToExport
                exports={data.member}
                selected={selected}
                setSelected={setSelected}
              />
            </Spinner>
          </ContentWrapper>
        </Modal.Default>
      </Modal>
    );
  },
);

const ADD_TO_EXPORT = 'ADD_TO_EXPORT';

export { AddToExportModal, ADD_TO_EXPORT };
