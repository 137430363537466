import styled, { css } from 'styled-components';
import { space as styledSpace, color, SpaceProps } from 'styled-system';

import { IconButton } from '../../IconButton';

export const Header = styled.header<{
  paddingTop: number;
  paddingBottom: number;
}>(
  ({ theme: { space }, paddingTop, paddingBottom }) => css`
    display: flex;
    align-items: center;
    padding: ${paddingTop}px ${space[4]}px ${paddingBottom}px;
  `,
);

export const ContentWrapper = styled.div<{ hasSearch: boolean } & SpaceProps>(
  ({ theme: { space }, hasSearch }) => css`
    height: 100%;
    padding: 0 ${space[4]}px;
    overflow-y: auto;
    overflow-x: hidden;

    ${hasSearch &&
    css`
      padding-top: ${space[2]}px;
    `}

    ${styledSpace};
  `,
);

export const ButtonsWrapper = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: flex-end;
    column-gap: ${space[1]}px;
    border-radius: 0 0 ${space[1]}px ${space[1]}px;

    ${styledSpace};
    ${color}
  `,
);

export const IconWrapper = styled.div<{ warn: boolean }>(
  ({ theme: { colors, space }, warn }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: ${space[4]}px;
    height: ${space[4]}px;
    margin-right: ${space[2]}px;
    background: ${warn ? colors.error.light : colors.highlight[1]};
    border-radius: 8px;
  `,
);

export const StyledIconButton = styled(IconButton)<{
  zeroBorderTopRight?: boolean;
}>(
  ({ theme: { colors, space }, zeroBorderTopRight }) => css`
    position: absolute;
    top: 0;
    right: 0;
    border-bottom-right-radius: 0;
    border-top-left-radius: 0;
    border-top-right-radius: ${space[1]}px;
    background: ${colors.highlight[0]};

    ${zeroBorderTopRight &&
    css`
      border-top-right-radius: 0;
    `}
  `,
);
