import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { INFORMATION } from 'routes';
import { Button, Container, Text } from 'UI';

const Footnote = styled(Text)(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    height: 33px;
    margin: ${space[0]}px -${space[1]}px -${space[1]}px;
    padding-left: ${space[1]}px;
    color: ${colors.text.secondary};
    background: ${colors.background};
  `,
);

export const hintText = (
  <Container column maxWidth={190}>
    Выберите характеристики для отображения в описании товара вручную или
    выставленные владельцем по-умолчанию (при наличии).
    <Footnote>
      См.&nbsp;
      <Button
        as={Link}
        target='_blank'
        to={INFORMATION.GUIDES.CREATE_PRODUCT_PROPERTIES}
        variant='string'
      >
        Привязка товара
      </Button>
    </Footnote>
  </Container>
);
