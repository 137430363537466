import styled, { css } from 'styled-components';
import { ColorProps, FontSizeProps, FontWeightProps } from 'styled-system';

import { Button } from 'UI';

export const ContentButton = styled(Button)<
  ColorProps & FontSizeProps & FontWeightProps
>(
  ({ theme: { colors } }) => css`
    width: max-content;

    &:hover {
      color: ${colors.primary.dark};

      svg path {
        fill: ${colors.primary.dark};
      }
    }
  `,
);
