import { useState, useEffect, useMemo, memo, FC } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { ICatalog } from 'common/types/catalogs.types';
import { IMapCategory } from 'common/types/categories.types';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { getPath, CATALOGS } from 'routes';
import { Container, IconButton } from 'UI';
import { SideMenuItem } from 'UI/SideMenu';

const ChildrenWrapper = styled.div<{ level: number }>(
  ({ theme: { space, colors }, level }) => css`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${level * space[2] + space[2]}px;
      width: 1px;
      background: ${colors.divider};
    }
  `,
);

interface IStructureItemProps {
  selectedCatalog: ICatalog;
  selectedCategory: string;
  level?: number;
  category: IMapCategory;
  categories: Map<string, IMapCategory>;
  pathIds: Set<string>;
}

const StructureItem: FC<IStructureItemProps> = memo(
  ({
    selectedCatalog,
    selectedCategory,
    level = 0,
    category,
    categories,
    pathIds,
  }) => {
    const theme = useTheme();

    const categoryIncludeInPath = pathIds.has(category.id);

    const [isOpen, setOpen] = useState(categoryIncludeInPath);

    // Нужно для того, чтобы при клике на карточку категории верхнего или первого уровня
    // открыть в структуре эту категорию
    useEffect(() => {
      if (categoryIncludeInPath) setOpen(true);
    }, [categoryIncludeInPath]);

    const isGroup = category.children.length > 0;
    const isShortMenu = pathIds.size === 0;
    const isUpperLevel = level === 0;

    const SIDE_MENU_ITEM_STYLE = useMemo(
      () => ({
        fontWeight: categoryIncludeInPath && isUpperLevel ? 600 : 500,
      }),
      [isUpperLevel, categoryIncludeInPath],
    );

    return (
      <Container column>
        <SideMenuItem
          primary
          active={selectedCategory === category.id}
          fontSize={isUpperLevel && !categoryIncludeInPath ? 4 : 3}
          pl={`${level * theme.space[2] + 14}px`}
          pr={1}
          rightAction={
            isGroup &&
            !isShortMenu && (
              <IconButton
                ml='auto'
                size={28}
                onClick={() => setOpen(state => !state)}
              >
                {isOpen ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </IconButton>
            )
          }
          style={SIDE_MENU_ITEM_STYLE}
          text={category.name}
          to={getPath(CATALOGS.CATEGORY, {
            catalogId: selectedCatalog.id,
            categoryId: category.id,
          })}
        />
        {isGroup && isOpen && !isShortMenu && (
          <ChildrenWrapper level={level}>
            {category.children.map(childCategory => (
              <StructureItem
                key={childCategory}
                categories={categories}
                category={categories.get(childCategory)!}
                level={level + 1}
                pathIds={pathIds}
                selectedCatalog={selectedCatalog}
                selectedCategory={selectedCategory}
              />
            ))}
          </ChildrenWrapper>
        )}
      </Container>
    );
  },
);

interface ICategoryStructureProps {
  selectedCatalog: ICatalog;
  selectedCategory: string;
  categories: Map<string, IMapCategory>;
  pathIds: Set<string>;
}

const CategoryStructure: FC<ICategoryStructureProps> = memo(
  ({ selectedCatalog, selectedCategory, categories, pathIds }) => (
    <Container column>
      {selectedCatalog.categories?.map(categoryId => {
        const category = categories.get(categoryId);

        if (!category) return null;

        return (
          <StructureItem
            key={category.id}
            categories={categories}
            category={category}
            pathIds={pathIds}
            selectedCatalog={selectedCatalog}
            selectedCategory={selectedCategory}
          />
        );
      })}
    </Container>
  ),
);

export { CategoryStructure };
