import { FC, memo, useState } from 'react';

import styled, { css } from 'styled-components';

import { CloseMediumIcon } from 'resources/icons/18';
import { formatDate } from 'common/utils';
import { MessageMediumIcon } from 'resources/icons/18';
import { Dropdown, Heading, Text } from 'UI';
import theme from 'UI/theme';

type ButtonNoticeProps = {
  isOpen?: boolean;
  isUnread?: boolean;
  error?: boolean;
};

const ButtonNotice = styled.button<ButtonNoticeProps>(
  ({ theme: { borderRadius, colors }, isOpen, isUnread, error }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;
    background: ${colors.highlight[error ? 3 : 1]};
    border-radius: ${borderRadius}px;

    ${isOpen &&
    css`
      background: ${colors.white};
    `}

    ${!isOpen &&
    isUnread &&
    css`
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        top: 10px;
        right: 8px;
        border-radius: 50%;
        background: ${colors.error.main};
      }

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 8px;
        height: 8px;
        top: 8px;
        right: 6px;
        border: 1px rgba(184, 212, 255, 0.4);
        border-radius: 50%;
      }
    `};
  `,
);

interface ILetter {
  isUnread: boolean;
  error?: boolean;
  title: string;
  text: string | undefined;
  date: string | undefined;
}

const Letter: FC<ILetter> = memo(({ isUnread, error, title, text, date }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = ({ currentTarget }): void => {
    setAnchorEl(currentTarget);
  };

  const handleClose = (): void => {
    setAnchorEl(null);
  };

  return (
    <>
      <ButtonNotice
        error={error}
        isOpen={Boolean(anchorEl)}
        isUnread={isUnread}
        onClick={anchorEl ? handleClose : handleClick}
      >
        {anchorEl ? <CloseMediumIcon /> : <MessageMediumIcon color='primary.main' />}
      </ButtonNotice>
      <Dropdown
        anchorEl={anchorEl}
        offset={[0, 0]}
        open={Boolean(anchorEl)}
        p={4}
        style={{ boxShadow: theme.shadows[2], zIndex: 1 }}
        width={488}
        onRequestClose={handleClose}
      >
        <Heading as='h4' mb={0}>
          {title}
        </Heading>
        <Text as='p' color='text.secondary' fontSize={2} mb={3}>
          {formatDate(date, false, 'dd MMMM yyyy, HH:mm')}
        </Text>
        <Text
          as='p'
          color='text.primary'
          fontSize={3}
          style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
          {text}
        </Text>
      </Dropdown>
    </>
  );
});

export { Letter };
