import {
  useState,
  useEffect,
  CSSProperties,
  FC,
  PropsWithChildren,
} from 'react';

import styled, { keyframes } from 'styled-components';
import {
  space,
  color as backgroundColor,
  width as styledWidth,
  height as styledHeight,
  HeightProps,
  SpaceProps,
  WidthProps,
  BackgroundColorProps,
} from 'styled-system';

import { SpinnerSmallIcon } from 'resources/icons/18';
import { SpinnerMediumIcon } from 'resources/icons/24';

const spinning = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(360deg)
  }
`;

const Container = styled.div<
  HeightProps & SpaceProps & WidthProps & BackgroundColorProps
>`
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    animation: ${spinning} 1.1s infinite linear;
  }

  ${space};
  ${styledHeight};
  ${styledWidth};
  ${backgroundColor};
`;

interface ISpinnerProps {
  size?: string;
  delay?: number;
  loading?: boolean;
  color?: string;
  style?: CSSProperties;
}

const Spinner: FC<
  ISpinnerProps &
    PropsWithChildren &
    HeightProps &
    SpaceProps &
    WidthProps &
    BackgroundColorProps
> = ({
  size = 's',
  delay = 1000,
  loading = true,
  children = null,
  color = 'primary.main',
  ...other
}) => {
  const [isShow, setShow] = useState(false);

  useEffect(() => {
    if (delay !== 0) {
      const timeoutId = setTimeout(() => setShow(true), delay);
      return () => clearTimeout(timeoutId);
    }

    return undefined;
  }, [delay, setShow]);

  if (delay !== 0 && !isShow && loading) return null;

  if (loading) {
    return (
      <Container {...other}>
        {size === 'xs' ? (
          <SpinnerSmallIcon color={color} />
        ) : (
          <SpinnerMediumIcon color={color} />
        )}
      </Container>
    );
  }

  return <>{children}</>;
};

export { Spinner };
