import styled, { css } from 'styled-components';

import { Button } from 'UI';

export const ResetFiltersButton = styled(Button)(
  ({ theme: { space, fontSizes } }) => css`
    min-width: auto;
    height: ${space[2]}px;
    padding: 0 ${space[0]}px;
    font-size: ${fontSizes[0]}px;
  `,
);

export const FilterButton = styled.button(
  ({ theme: { fontSizes, colors } }) => css`
    display: inline;
    color: ${colors.primary.main};
    font-size: ${fontSizes[5]}px;

    &:hover {
      cursor: pointer;
    }
  `,
);
