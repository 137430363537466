import { useState, useLayoutEffect, FC, ReactNode } from 'react';

import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { Services } from 'layouts/components/Services';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { ProgressIndicator, Tooltip } from 'UI';

const PageHeader = styled.div(
  ({ theme: { space, colors } }) => css`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    height: 42px;
    align-items: center;
    padding-right: ${space[6]}px;
    background: ${colors.background};
  `,
);

const HeadingWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    margin-left: ${space[2]}px;
    font-size: 22px;
    font-weight: 600;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
);

const StyledProgressIndicator = styled(ProgressIndicator)`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;

interface IFixedHeaderProps {
  isLoading?: boolean;
  title: ReactNode;
  tip?: ReactNode;
  buttons?: ReactNode;
  leftAddon?: ReactNode;
}

/**
 * The component to overlap the main header
 */
const FixedHeader: FC<IFixedHeaderProps> = ({
  isLoading,
  title,
  tip,
  buttons,
  leftAddon,
}) => {
  const [placeRender, setPlaceRender] = useState<Nullable<HTMLElement>>(null);

  useLayoutEffect(() => {
    setPlaceRender(document.querySelector('header'));
  }, []);

  if (!placeRender) return null;

  return ReactDOM.createPortal(
    <PageHeader>
      <Services isCollapsed />
      <HeadingWrapper>
        {title}
        {tip && (
          <Tooltip arrow maxWidth={240} title={tip}>
            <Help1pxIcon ml={1} />
          </Tooltip>
        )}
        {leftAddon}
      </HeadingWrapper>
      {buttons}
      <StyledProgressIndicator isLoading={isLoading} />
    </PageHeader>,
    placeRender,
  );
};

FixedHeader.defaultProps = {
  tip: '',
};

FixedHeader.propTypes = {
  /**
   * Page title
   */
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * State of loading
   */
  isLoading: PropTypes.bool,
  /**
   * Tooltip for title
   */
  tip: PropTypes.string,
  /**
   * One or more buttons
   */
  buttons: PropTypes.element,
};

export { FixedHeader };
