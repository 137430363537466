import { FC } from 'react';

import styled, { css } from 'styled-components';

import { ICollapsed } from 'layouts/types';
import { NomenSpravochnikIcon } from 'resources/icons/services';
import { IconButton, Text } from 'UI';

const STYLE_DEFAULT = { cursor: 'default' };

const LogoWrapper = styled.div<ICollapsed>(
  ({ theme: { space, colors }, isCollapsed }) => css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 244px;
    height: 42px;
    padding: 0 ${space[1]}px 0 11px;
    background: ${colors.highlight[1]};
    border-radius: 0 0 8px 0;
    white-space: nowrap;
    overflow: hidden;
    transition: width 200ms ease-in-out;

    ${isCollapsed &&
    css`
      width: 50px;
    `}
  `,
);

const Services: FC<ICollapsed> = ({ isCollapsed }) => (
  <LogoWrapper isCollapsed={isCollapsed}>
    <IconButton as='div' mr='11px' size={28} style={STYLE_DEFAULT}>
      <NomenSpravochnikIcon size={28} />
    </IconButton>
    {!isCollapsed && (
      <Text truncate color='text.primary' fontSize={4} fontWeight={500}>
        Импульс
      </Text>
    )}
  </LogoWrapper>
);

export { Services };
