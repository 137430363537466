import { ReactElement, ReactNode, MouseEvent } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { STEP_TREE, INDENT_FOR_ITEM } from 'UI/SideMenu/constants';

import { IconButton } from '../IconButton';

// TODO должны быть адекватные отступы, чтоб не высчитывать минусовые level
function getIndent(level: number): number {
  if (level === 0) return INDENT_FOR_ITEM;
  return level * STEP_TREE + INDENT_FOR_ITEM;
}

const VerticalDivider = styled.div<{ level: number }>(
  ({ level, theme: { colors } }) => css`
    position: relative;

    &:before {
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      position: absolute;
      top: 0;
      left: ${getIndent(level) + 2}px;
      border-left: 1px solid ${colors.divider};
    }
  `,
);

const HiddenIcon = styled(IconButton)`
  display: none;
`;

interface IHiddenAction {
  icon: ReactNode;
  onClick: (event: MouseEvent<HTMLElement>) => void;
  highlighted?: boolean;
}

function HiddenAction({
  icon,
  onClick,
  highlighted,
}: IHiddenAction): ReactElement {
  return (
    <HiddenIcon focused highlighted={highlighted} size={28} onClick={onClick}>
      {icon}
    </HiddenIcon>
  );
}

const ButtonsWrapper = styled.div(
  ({ theme: space }) => css`
    display: flex;
    margin-left: ${space[1]}px;
  `,
);

const Item = styled.div<{
  active?: boolean;
  focused?: boolean;
  rightAction?: boolean;
  primary?: boolean;
  secondary?: boolean;
  indent?: boolean;
  highlighted?: boolean;
  level?: number;
  levelMeter?: boolean;
  space?: number;
  disabled?: boolean;
}>(
  ({
    theme: { space: spaceTheme, colors, fontSizes },
    active,
    disabled,
    focused,
    rightAction,
    primary,
    indent = true,
    secondary,
    highlighted,
    level,
    levelMeter,
  }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: ${spaceTheme[4]}px;

    line-height: ${spaceTheme[4]}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[3]}px;
    border-left: 2px solid transparent;
    transition: all ease-in-out 0.1s;

    &:hover ${HiddenIcon} {
      display: flex;
    }

    ${indent &&
    !levelMeter &&
    css`
      padding: 0 ${spaceTheme[2]}px;
    `}

    ${levelMeter &&
    css`
      position: relative;
      padding: 0 ${spaceTheme[4]}px;

      &:before {
        content: ' ';
        position: absolute;
        top: 0;
        left: ${spaceTheme[2]}px;
        width: 1px;
        height: 100%;
        background: ${colors.divider};
      }
    `}

    ${focused &&
    css`
      ${HiddenIcon} {
        display: flex;
      }
    `}

    & a, & a:hover, button {
      color: inherit;
      text-align: left;
      font-weight: inherit;
      line-height: inherit;
    }

    & > * {
      flex-grow: 1;
    }

    & ${ButtonsWrapper} {
      flex-grow: inherit;
    }

    ${rightAction && 'padding-right: 3px;'}

    ${highlighted &&
    css`
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};

      & a,
      & a:hover,
      button {
        color: inherit;
        text-align: left;
        font-weight: inherit;
        line-height: inherit;
      }

      & ${HiddenIcon} {
        display: flex;
      }
    `}

    ${active &&
    css`
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};
      border-color: ${colors.primary.main};
      cursor: default;
    `}

    ${primary &&
    css`
      color: ${colors.text.primary};
      font-weight: 500;
      font-size: ${fontSizes[4]}px;
    `}

    ${secondary &&
    css`
      color: ${colors.text.primary};
      font-weight: 600;
    `}

    ${disabled &&
    css`
      color: ${colors.text.disabled};

      & button {
        cursor: default;
      }
    `}

     ${(typeof level === 'string' || typeof level === 'number') &&
    css`
      padding-left: ${getIndent(level)}px;
    `}

    ${styledSpace};
  `,
);

const ItemControl = styled(Item)(
  ({ theme: { colors } }) => css`
    & a,
    & a:hover {
      color: inherit;
    }

    &:hover {
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};
    }
  `,
);

export { HiddenAction, VerticalDivider, Item, ItemControl, ButtonsWrapper };
