import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { LinkOutsideIcon } from 'resources/icons/18';

export const CardsContainer = styled.div<{
  isPreviewMode?: boolean;
}>(
  ({ theme: { colors, space }, isPreviewMode }) => css`
    border: 1px solid ${colors.divider};
    margin-bottom: ${space[2]}px;

    ${isPreviewMode &&
    css`
      border: none;
      margin-bottom: 0;
    `}
  `,
);

const BaseCardContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    height: 92px;
    padding: ${space[1]}px ${space[2]}px;
  `,
);

export const ListCardContainer = styled(BaseCardContainer)<{
  isPreviewMode?: boolean;
}>(
  ({ theme: { space, borderRadius }, isPreviewMode }) => css`
    height: 96px;
    padding-left: ${isPreviewMode ? 0 : space[2]}px;
    border-radius: ${borderRadius}px;
  `,
);

export const MapCardContainer = styled(BaseCardContainer)<{ active: boolean }>(
  ({ theme: { space, colors }, active }) => css`
    padding: ${space[2]}px ${space[4]}px;
    align-items: start;
    border: 1px solid transparent;
    border-bottom-color: ${colors.divider};
    border-left-color: ${colors.divider};
    cursor: pointer;

    ${active &&
    css`
      border-color: ${colors.primary.main};
    `}
  `,
);

export const ExternalLinkIcon = styled(LinkOutsideIcon)(
  ({ theme: { space, colors, borderRadius } }) => css`
    visibility: hidden;
    font-size: 0;
    padding: ${space[0]}px;
    border-radius: ${borderRadius}px;

    &:hover {
      background: ${colors.highlight[1]};
    }

    ${MapCardContainer}:hover & {
      visibility: visible;
    }
  `,
);

export const HiddenLinkIcon = styled.div`
  visibility: hidden;
  margin-left: auto;

  ${MapCardContainer}:hover & {
    visibility: visible;
  }
`;

export const ntdControlsProps = {
  display: 'flex',
  alignItems: 'center',
  height: '36px',
  mb: 3,
};

export const Table = styled.div(
  ({ theme: { space } }) => css`
    column-count: 2;
    column-gap: ${space[2]}px;
  `,
);

export const TableCard = styled.div(
  ({ theme: { space } }) => css`
    margin-bottom: ${space[2]}px;
    page-break-inside: avoid;
    break-inside: avoid-column;
  `,
);

export const Card = styled.div(
  ({ theme: { colors, space, borderRadius } }) => css`
    display: flex;
    margin-bottom: ${space[2]}px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
  `,
);

export const CardTitle = styled(Link)(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    width: fit-content;
    margin-bottom: ${space[2]}px;
    font-size: ${fontSizes[6]}px;
    font-weight: 500;
    color: ${colors.text.primary};
    word-break: break-word;

    &:hover {
      color: inherit;
    }
  `,
);
