import { ReactElement } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { Heading } from 'UI';

const GroupBlockContainer = styled.div(
  ({ theme: { space } }) => css`
    margin-bottom: ${space[3]}px;

    &:last-of-type {
      margin-bottom: 0;
    }

    ${styledSpace};
  `,
);

const DrawerTextBlock = styled.div`
  word-break: break-all;
  white-space: pre-wrap;
`;

function DrawerGroupBlock({ title, children, ...other }): ReactElement {
  return (
    <GroupBlockContainer {...other}>
      <Heading as='h5' mb={2}>
        {title}
      </Heading>
      {children}
    </GroupBlockContainer>
  );
}

export { DrawerGroupBlock, DrawerTextBlock };
