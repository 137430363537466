import { CSSProperties, useState } from 'react';

import { addMonths, differenceInMonths } from 'date-fns';
import styled, { css } from 'styled-components';

import { ArrowLeftIcon, ArrowRightIcon } from 'resources/icons/12';

import { MONTHS, YEAR_OFFSET } from './constants';
import { DaysView } from './DaysView';
import { MonthsView } from './MonthsView';
import { IViewDate } from './types';
import { YearsView } from './YearsView';
import { IconButtonWrapper } from '../IconButton/IconButtonWrapper';

const Container = styled.div`
  display: inline-block;
  min-width: 226px;
`;

const Header = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${space[1]}px;
    padding: 8px 0;
    border-bottom: 1px solid ${colors.divider};
  `,
);

const HeaderTitle = styled.button(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: inline-block;
    padding: 0 ${space[1]}px;
    border: none;
    background: none;
    color: ${colors.text.primary};
    font-size: ${fontSizes[3]}px;
    font-weight: 500;
    cursor: pointer;
  `,
);

type PickerType = 'YEAR_PICKER' | 'MONTH_PICKER' | 'DAY_PICKER';

const YEAR_PICKER = 'YEAR_PICKER';
const MONTH_PICKER = 'MONTH_PICKER';
const DAY_PICKER = 'DAY_PICKER';

const MIN_DATE = new Date('1600-01-01');
const MAX_DATE = new Date('2199-12-31');

const shouldDisableDate = (minDate, maxDate) => date => {
  return date <= minDate || date >= maxDate;
};

function getDisabledControls(
  prevDisabledDate,
  nextDisabledDate,
  pickerType,
  date,
): [boolean, boolean] {
  const isPrevDisabled = !!prevDisabledDate;
  const isNextDisabled = !!nextDisabledDate;

  const currentPrevMonth = prevDisabledDate?.getMonth();
  const currentPrevYear = prevDisabledDate?.getFullYear();
  const currentNextMonth = nextDisabledDate?.getMonth();
  const currentNextYear = nextDisabledDate?.getFullYear();
  const dateMonth = date.getMonth();
  const dateYear = date.getFullYear();

  const minMonth = MIN_DATE.getMonth();
  const minYear = MIN_DATE.getFullYear();
  const maxMonth = MAX_DATE.getMonth();
  const maxYear = MAX_DATE.getFullYear();

  if (pickerType === DAY_PICKER) {
    return [
      (isPrevDisabled &&
        dateMonth <= currentPrevMonth &&
        dateYear <= currentPrevYear) ||
        (dateMonth <= minMonth && dateYear <= minYear),
      (isNextDisabled &&
        dateMonth >= currentNextMonth &&
        dateYear >= currentNextYear) ||
        (dateMonth >= maxMonth && dateYear >= maxYear),
    ];
  }

  if (pickerType === MONTH_PICKER) {
    return [
      (isPrevDisabled &&
        dateMonth <= currentPrevMonth &&
        dateYear <= currentPrevYear) ||
        (!isPrevDisabled && dateYear <= minYear),
      (isNextDisabled &&
        dateMonth >= currentNextMonth &&
        dateYear >= currentNextYear) ||
        dateYear >= maxYear,
    ];
  }

  if (pickerType === YEAR_PICKER) {
    return [
      (isPrevDisabled && dateYear <= currentPrevYear) ||
        dateYear - YEAR_OFFSET <= minYear,
      (isNextDisabled && dateYear >= currentNextYear) ||
        dateYear + YEAR_OFFSET >= maxYear,
    ];
  }

  return [isPrevDisabled, false];
}

const pickerTypeToComponent = (
  type: PickerType,
): ((props: IViewDate) => JSX.Element) => {
  switch (type) {
    case YEAR_PICKER:
      return YearsView;
    case MONTH_PICKER:
      return MonthsView;
    default:
      return DaysView;
  }
};

const pickerTypeToStep = (type: PickerType): number => {
  switch (type) {
    case DAY_PICKER:
      return 1;
    case MONTH_PICKER:
      return 12;
    case YEAR_PICKER:
      return 120;
    default:
      return 0;
  }
};

const pickerTypeUpReducer = (type: PickerType): PickerType => {
  switch (type) {
    case DAY_PICKER:
      return MONTH_PICKER;
    case MONTH_PICKER:
      return YEAR_PICKER;
    default:
      return DAY_PICKER;
  }
};

const formatDate = (date, type): string => {
  const year = date.getFullYear();
  const month = date.getMonth();

  switch (type) {
    case YEAR_PICKER:
      return `${year - YEAR_OFFSET} - ${year + YEAR_OFFSET + 1}`;
    case MONTH_PICKER:
      return year;
    default:
      return `${MONTHS[month]} ${year}`;
  }
};

interface IDatePickerProps {
  prevDisabledDate?: Date;
  nextDisabledDate?: Date;
  className?: string;
  initialPickerType?: PickerType;
  style?: CSSProperties;
  value: Date | '';
  onChange: (date: Date) => void;
}

function DatePicker({
  prevDisabledDate,
  nextDisabledDate,
  className,
  initialPickerType = DAY_PICKER,
  style,
  value,
  onChange,
}: IDatePickerProps): JSX.Element {
  // todo избавится от этого: prevDisabledDate и nextDisabledDate должны идти с уже обнуленными часами
  if (prevDisabledDate) prevDisabledDate.setHours(0, 0, 0, 0);
  if (nextDisabledDate) nextDisabledDate.setHours(0, 0, 0, 0);

  const [monthOffset, setMonthOffset] = useState(0);
  const [pickerType, setPickerType] = useState<PickerType>(initialPickerType);

  const dateWithOffset = addMonths(value || new Date(), monthOffset);
  const step = pickerTypeToStep(pickerType);
  const PickerComponent = pickerTypeToComponent(pickerType);

  const [prevDisabled, nextDisabled] = getDisabledControls(
    prevDisabledDate,
    nextDisabledDate,
    pickerType,
    dateWithOffset,
  );

  const handlePrev = (): void => {
    setMonthOffset(prev => prev - step);
  };
  const handleNext = (): void => {
    setMonthOffset(prev => prev + step);
  };
  const handleTitleClick = (): void => {
    setPickerType(pickerTypeUpReducer);
  };
  const handleChange = (date): void => {
    if (pickerType === DAY_PICKER) {
      setMonthOffset(0);
      onChange(date);
    } else if (pickerType === MONTH_PICKER) {
      setMonthOffset(differenceInMonths(date, value || new Date()));
      setPickerType(DAY_PICKER);
    } else if (pickerType === YEAR_PICKER) {
      setMonthOffset(differenceInMonths(date, value || new Date()));
      setPickerType(MONTH_PICKER);
    }
  };

  return (
    <Container className={className} style={style}>
      <Header>
        <IconButtonWrapper
          aria-label='previous'
          disabled={prevDisabled}
          onClick={handlePrev}
        >
          <ArrowLeftIcon />
        </IconButtonWrapper>
        <HeaderTitle
          aria-label='title'
          type='button'
          onClick={handleTitleClick}
        >
          {formatDate(dateWithOffset, pickerType)}
        </HeaderTitle>
        <IconButtonWrapper
          aria-label='next'
          disabled={nextDisabled}
          onClick={handleNext}
        >
          <ArrowRightIcon />
        </IconButtonWrapper>
      </Header>
      <PickerComponent
        selectedDate={dateWithOffset}
        shouldDisableDate={shouldDisableDate(
          prevDisabledDate || MIN_DATE,
          nextDisabledDate || MAX_DATE,
        )}
        value={value}
        onClick={handleChange}
      />
    </Container>
  );
}

export { DatePicker, IDatePickerProps, PickerType };
