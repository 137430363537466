import { FC } from 'react';

import styled, { css } from 'styled-components';

import { SectionTitle } from 'components/SectionTitle';
import { InfoBlock, ExpandableContainer } from 'UI';

const MAX_HEIGHT = 400;

const DescriptionWrapper = styled(ExpandableContainer.DangerouslyTextWrapper)(
  ({ theme: { space } }) => css`
    table {
      border-collapse: collapse;
    }

    & > table,
    td,
    th {
      padding: ${space[0]}px;
      text-align: center;
      border: 1px solid #ddd;
    }
  `,
);

interface IExpandableDescriptionProps {
  id: string;
  title: string;
  description: { isPrivate?: boolean; text: string };
}

const ExpandableDescription: FC<IExpandableDescriptionProps> = ({
  id,
  title,
  description,
}) => {
  if (!description?.text) return null;

  return (
    <InfoBlock
      id={id}
      title={<SectionTitle isPrivate={!!description.isPrivate} text={title} />}
    >
      <ExpandableContainer maxHeight={MAX_HEIGHT} mt='24px'>
        <DescriptionWrapper text={description.text} />
      </ExpandableContainer>
    </InfoBlock>
  );
};

export { ExpandableDescription };
