import { FC } from 'react';

import styled, { css } from 'styled-components';

import { Text, Button } from 'UI';

import {
  PACKAGE_CREATE_SECTIONS,
  PACKAGE_CREATE_SECTIONS_NAMES,
} from './constants';

const Sidebar = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 290px;
    width: 290px;
    padding: ${space[5]}px ${space[4]}px ${space[4]}px;
    background: ${colors.background};
  `,
);

interface IProductsCreatePackagesModalSidebar {
  isEdit: boolean;
  isPrimaryPackage: boolean;
  activeSection: string;
  isDisabled: boolean;
  handleSubmit: () => void;
  onSelectSection: any;
}

const ProductsCreatePackagesModalSidebar: FC<
  IProductsCreatePackagesModalSidebar
> = ({
  isEdit,
  isPrimaryPackage,
  activeSection,
  isDisabled,
  handleSubmit,
  onSelectSection,
}) => {
  return (
    <Sidebar>
      <div>
        <Text fontSize={6} fontWeight={600}>
          {isEdit ? 'Редактирование' : 'Добавление'}{' '}
          {isPrimaryPackage ? 'первичной' : 'внешней'} упаковки
        </Text>
        {Object.values(PACKAGE_CREATE_SECTIONS).map(section => (
          <Button
            key={section}
            color={section === activeSection ? 'primary' : 'secondary'}
            data-section={section}
            fontSize={5}
            mt={2}
            style={{ display: 'block' }}
            variant='string'
            onClick={onSelectSection}
          >
            {PACKAGE_CREATE_SECTIONS_NAMES[section]}
          </Button>
        ))}
      </div>
      <Button disabled={isDisabled} size='l' onClick={handleSubmit}>
        {isEdit ? 'Сохранить упаковку' : 'Создать упаковку'}
      </Button>
    </Sidebar>
  );
};

export { ProductsCreatePackagesModalSidebar };
