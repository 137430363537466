import { FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { CloseMediumIcon } from 'resources/icons/18';
import { Container } from 'UI';

const Overlay = styled.div(
  ({ theme: { colors, zIndex } }) => css`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    min-width: 960px;
    min-height: 600px;
    display: flex;
    flex-direction: column;
    z-index: ${zIndex.modal};

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background: ${colors.text.secondary};
      opacity: 0.9;
    }
  `,
);

const Cancel = <CloseMediumIcon color='white' size={22} />;

const CloseButton = styled.button.attrs(() => ({
  children: Cancel,
}))(
  ({ theme: { space, colors } }) => css`
    min-height: ${space[7]}px;
    width: ${space[7]}px;
    margin-left: auto;
    transition: background 200ms ease-in-out;

    &:hover {
      background: ${colors.text.secondary};
      opacity: 0.9;
    }
  `,
);

interface IImagesGalleryProps {
  children: ReactNode;
  topAddon?: ReactNode;
  onRequestClose: VoidFunction;
}

const GalleryOverlay: FC<IImagesGalleryProps> = ({
  children,
  topAddon,
  onRequestClose,
}) => {
  return (
    <Overlay>
      <Container
        alignItems='center'
        justifyContent='space-between'
        position='relative'
      >
        {topAddon}
        <CloseButton onClick={onRequestClose} />
      </Container>
      {children}
    </Overlay>
  );
};

export { GalleryOverlay };
