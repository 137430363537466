import { useState } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Avatar } from '../Avatar';

const SliderWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: none;
    justify-content: space-between;
    position: absolute;
    right: ${space[1]}px;
    left: ${space[1]}px;
    bottom: ${space[1]}px;
    gap: 2px;
  `,
);

const ImageWrapper = styled.div`
  position: relative;
  max-height: 160px;

  &:hover ${SliderWrapper} {
    display: flex;
  }

  ${styledSpace};
`;

const SliderChunk = styled.div<{ active: boolean }>(
  ({ theme: { colors }, active }) => css`
    height: 2px;
    background: ${active ? colors.primary.main : colors.highlight[3]};
    flex-grow: 1;
    pointer-events: none;
  `,
);

const MoreImagesPlaceholder = styled(Link)(
  ({ theme: { colors, borderRadius, fontSizes } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${fontSizes[3]}px;
    font-weight: 500;
    color: ${colors.white};
    background-color: #2b2d3fb3;
    border-radius: ${borderRadius}px;

    :hover {
      color: ${colors.white};
    }
  `,
);

interface IImageSliderProps {
  sourceImages: string[];
  size: string;
  cardUrl: string;
}

function ImageSlider({
  sourceImages,
  size,
  cardUrl,
  ...other
}: IImageSliderProps & SpaceProps): JSX.Element {
  const images = sourceImages.slice(0, 5);
  const [image, setImage] = useState(images[0]);
  const [chunk, setChunk] = useState(0);
  const remainingImages = sourceImages.length - 5;

  const isLastChunk = chunk === images.length - 1 && remainingImages > 0;

  const handleMouseMove = (event): void => {
    const rect = event.target.getBoundingClientRect();
    const sizeOfPart = rect.width / images.length;

    const x = event.clientX - rect.left;

    const calcChunk = Math.trunc(x / sizeOfPart);
    setChunk(calcChunk);

    setImage(images[calcChunk]);
  };

  const handleMouseOut = (): void => {
    setChunk(0);
    setImage(images[0]);
  };

  return (
    <ImageWrapper
      onMouseMove={handleMouseMove}
      onMouseOut={handleMouseOut}
      {...other}
    >
      <Avatar
        as={Link}
        size={size}
        style={{ objectFit: 'cover', objectPosition: 'center' }}
        to={cardUrl}
        url={image}
      />
      {isLastChunk && (
        <MoreImagesPlaceholder to={cardUrl}>
          Еще {remainingImages} фото
        </MoreImagesPlaceholder>
      )}
      <SliderWrapper>
        {images.length > 1 &&
          images.map((img, index) => (
            <SliderChunk key={index} active={index === chunk} />
          ))}
      </SliderWrapper>
    </ImageWrapper>
  );
}

export { ImageSlider };
