import { FC, Fragment, memo } from 'react';

import styled, { css } from 'styled-components';

import { PointsOfSaleGtmType } from 'common/constants/points-of-sale.const';
import { ArticlesGtmTypes } from 'common/types/articles.types';
import { GtmSections } from 'common/types/gtm.types';
import { PaidServiceTooltip } from 'components/PaidServiceTooltip';
import { BackVersionIcon, SubscribeSetupBoldIcon } from 'resources/icons/18';
import { Container, IconButton } from 'UI';

const WrapperControls = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    align-items: center;
    padding: ${space[0]}px ${space[1]}px;
    margin-right: -4px;
    background: ${colors.background};
    border-radius: 8px;
  `,
);

interface IHeadingControlsProps {
  section?: GtmSections;
  controls?: JSX.Element | boolean;
  paidControls?: JSX.Element;
  isShowPaidTooltip?: boolean;
}

const HeadingControls: FC<IHeadingControlsProps> = memo(
  ({ isShowPaidTooltip = true, controls, paidControls, section }) => {
    return (
      <Fragment>
        {controls && (
          <Container alignItems='center' mr={1}>
            {controls}
          </Container>
        )}
        {isShowPaidTooltip && (
          <WrapperControls>
            {paidControls}
            <PaidServiceTooltip
              placement='bottom'
              section={section}
              tag={
                section === 'articles'
                  ? ArticlesGtmTypes.subscriptionInRegulations
                  : PointsOfSaleGtmType.subscriptionInPointOfSale
              }
              title='Управление подписками входит в платную подписку.'
              type='notify'
            >
              <IconButton disabled>
                <SubscribeSetupBoldIcon />
              </IconButton>
            </PaidServiceTooltip>
            <PaidServiceTooltip
              placement='bottom'
              section={section}
              tag={
                section === 'articles'
                  ? ArticlesGtmTypes.historyInRegulations
                  : PointsOfSaleGtmType.historyInPointOfSale
              }
              title='История изменений входит в платную подписку.'
              type='notify'
            >
              <IconButton disabled>
                <BackVersionIcon />
              </IconButton>
            </PaidServiceTooltip>
          </WrapperControls>
        )}
      </Fragment>
    );
  },
);

export { HeadingControls };
