import { FC } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { ISlot } from 'common/types/product.types';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { useToggle } from 'tools/hooks';
import { DescriptionList, Heading } from 'UI';

const Wrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    padding: ${space[2]}px;
    margin-bottom: ${space[3]}px;
    background: ${colors.highlight[0]};
    border-radius: 8px;
  `,
);

const StyledHeading = styled(Heading)`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const SlotInfo: FC<{ slot?: Nullable<ISlot> }> = ({ slot }) => {
  const [open, handleToggle] = useToggle(false);

  if (!slot) return null;

  return (
    <Wrapper>
      <StyledHeading as='h5' onClick={handleToggle}>
        Требования к заполнению слота
        {open ? <ArrowUpIcon ml={1} /> : <ArrowDownIcon ml={1} />}
      </StyledHeading>
      {open && (
        <DescriptionList
          dotted
          bg='#f6f7f9'
          mt={2}
          propertyProps={{ mb: '12px' }}
          widthKey='50%'
        >
          <DescriptionList.Property label='Название товара'>
            {slot?.name}
          </DescriptionList.Property>
          <DescriptionList.Property label='Описание товара'>
            {slot?.description}
          </DescriptionList.Property>
        </DescriptionList>
      )}
    </Wrapper>
  );
};

export { SlotInfo };
