import { FC, memo } from 'react';

import styled, { css } from 'styled-components';

import { useReform } from 'reform';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { useToggle } from 'tools/hooks';
import { DescriptionList, Heading } from 'UI';

const CardBackground = styled.div(
  ({ theme: { colors, space } }) => css`
    padding: ${space[2]}px;
    margin-bottom: ${space[3]}px;
    background: ${colors.highlight[0]};
    border-radius: 8px;
  `,
);

const SlotInfo: FC = memo(() => {
  const [open, handleToggle] = useToggle(true);
  const {
    initialValues: { slot },
  } = useReform();

  if (!slot) return null;

  return (
    <CardBackground>
      <Heading
        alignItems='center'
        as='h5'
        display='flex'
        style={{ cursor: 'pointer' }}
        onClick={handleToggle}
      >
        Требования к заполнению слота
        {open ? <ArrowUpIcon ml={1} /> : <ArrowDownIcon ml={1} />}
      </Heading>
      {open && (
        <DescriptionList dotted bg='transparent' mt={2} widthKey='50%'>
          <DescriptionList.Property label='Название товара'>
            {slot?.name}
          </DescriptionList.Property>
        </DescriptionList>
      )}
    </CardBackground>
  );
});

export { SlotInfo };
