import { FC, memo } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ARTICLES_TYPES_NAME } from 'common/constants/articles.const';
import { IArticle } from 'common/types/company-management';
import { formatDate, getFileURL, getImageURL } from 'common/utils';
import { CardInfo, CardTitle, CardWrapper } from 'components/company';
import { COMPANY, getPath } from 'routes';
import { Avatar, Badge, Tags, Text, Tooltip } from 'UI';

const TextPreview = styled(Text)(
  ({ theme: { colors } }) => css`
    display: -webkit-box;
    -webkit-line-clamp: 2;
    word-break: break-word;
    -webkit-box-orient: vertical;
    overflow: hidden;
    color: ${colors.text.primary};
  `,
);

const CompanyInfo = styled.div(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    align-items: center;
    color: ${colors.text.primary};
  `,
);

const TypeBadge = styled(Badge)(
  ({ theme: { space } }) => css`
    position: absolute;
    right: ${space[3]}px;
    top: ${space[3]}px;
  `,
);

const STYLE_AVATAR = { width: '100%', borderRadius: '5px' };

const ArticleCard: FC<{ article: IArticle }> = memo(
  ({
    article: {
      id,
      image,
      type,
      title,
      textPreview,
      tags,
      publishedAt,
      company,
    },
   }) => (
    <CardWrapper>
      <CardInfo>
        <div>
          <Avatar
            cover
            as={Link}
            mb={2}
            size='xl'
            style={STYLE_AVATAR}
            to={getPath(COMPANY.ARTICLE_PAGE, { id })}
            url={getFileURL(image)}
          />
          <TypeBadge label={ARTICLES_TYPES_NAME[type]} />
        </div>
        <CompanyInfo>
          <Avatar
            as={Link}
            letter={company?.name}
            size='xxs'
            to={getPath(COMPANY.CARD, { id: company?.id })}
            url={getImageURL(company?.logo)}
          />
          <Text
            truncate
            as={Link}
            color='text.primary'
            pl={1}
            pr={2}
            to={getPath(COMPANY.CARD, { id: company?.id })}
            width='300px'
          >
            {company?.name}
          </Text>
        </CompanyInfo>
        <Tooltip hint placement='bottom' title={title}>
          <CardTitle as={Link} to={getPath(COMPANY.ARTICLE_PAGE, { id })}>
            {title}
          </CardTitle>
        </Tooltip>
        <Tooltip hint placement='bottom' title={textPreview}>
          <TextPreview mb={2} mt={1}>
            {textPreview}
          </TextPreview>
        </Tooltip>
        <Tags
          arrow
          expandable
          fontSize={1}
          itemToIcon='#'
          limit={4}
          tags={tags}
        />
        <Text
          color='text.primary'
          fontSize={0}
          fontWeight={500}
          letterSpacing='0.5px'
          mt={tags?.length ? 2 : undefined}
          textTransform='uppercase'
        >
          {formatDate(publishedAt, false, 'd MMMM yyyy')}
        </Text>
      </CardInfo>
    </CardWrapper>
  ),
);

export { ArticleCard };
