import {
  CSSProperties,
  ForwardedRef,
  useCallback,
  useMemo,
  useRef,
  Ref,
  ReactElement,
  forwardRef as reactForwardRef,
  FormEvent,
  RefObject,
  useImperativeHandle,
  ReactNode,
} from 'react';

import {
  useCombobox,
  UseComboboxReturnValue,
  UseComboboxState,
  UseComboboxStateChangeOptions,
} from 'downshift';
import styled from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { STUB_FUNC } from 'tools/utils';

import { BaseInput } from './BaseInput';
import {
  Options,
  useAsyncOptions,
  InputContainer,
  filterByInputValue,
} from './components';
import {
  ItemToKeyType,
  DisableItemType,
  ItemToStringAndOptionsType,
  OnStateChange,
  FetchOptionsType,
  ItemToStringType,
} from './types';

const AddonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  padding: 2px;
`;

/**
 * Редъюсер для управления поведением и состоянием `Combobox`.
 * Поведение:
 *  - если мышь пропадает с `Dropdown`, то подсветка `Option` остается;
 *  - если в `Input` введено значение, то `Dropdown` появляется, если `inputValue !== ''`;
 *  - если `Combobox` находится в фокусе и происходит нажатие стрелочек вверх или вниз с клавиатуры, то это не
 *  приводит к появлению `Dropdown` (в поведении по умолчанию, происходит раскрытие Dropdown`);
 *
 * @param state  - текущее состояние `Combobox`;
 * @param changes - измененное состояние;
 * @param type - тип события, которое привело к изменению;
 */
function stateReducer<T>(
  state: UseComboboxState<T>,
  { changes, type }: UseComboboxStateChangeOptions<T>,
): Partial<UseComboboxState<T>> {
  switch (type) {
    case useCombobox.stateChangeTypes.MenuMouseLeave:
      return { ...changes, highlightedIndex: state.highlightedIndex };
    case useCombobox.stateChangeTypes.InputChange:
      return { ...changes, isOpen: changes.inputValue !== '' };
    case useCombobox.stateChangeTypes.InputKeyDownArrowDown:
      return { ...changes, isOpen: !!changes.inputValue };
    case useCombobox.stateChangeTypes.InputKeyDownArrowUp:
      return { ...changes, isOpen: !!changes.inputValue };
    default:
      return changes;
  }
}

/**
 * Отдельная декларация forwardRef необходима для корректной работы
 * с TS Generic.
 *
 * @example
 *  Использование:
 *  <Combobox<{name: string}>
 *    ref={ref}
 *    value={value}
 *    ...
 *  </Combobox>
 *
 *  Реализация:
 *  const Combobox = reactForwardRef(ComboboxInner);
 *
 *  function ComboboxInner<T extends any = string>(
 *    props: IComboboxProps<T>,
 *    ref: ForwardedRef<HTMLInputElement>
 *  ) {
 *    ...
 *  }
 */
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

type ItemToStringInner<T> = (item: T) => string;

type OnChange<T> = (item: T | string) => void;

type IComboboxProps<T extends any = string> = {
  className?: string;
  style?: CSSProperties;
  id?: string;
  disabled?: boolean;
  allowContentSearch?: boolean;
  disablePortal?: boolean;
  hasCustomItem?: boolean;
  error?: boolean;
  placeholder?: string;
  maxLength?: number;
  heightItem?: number;
  value: string;
  controlRef?: RefObject<Nullable<UseComboboxReturnValue<T>>>;
  options: T[] | FetchOptionsType;
  itemToKey?: ItemToKeyType<T>;
  itemToString?: ItemToStringAndOptionsType<T>;
  disableItem?: DisableItemType<T | string>;
  onChange?: OnChange<T>;
  onStateChange?: OnStateChange<T>;
  rightAddon?: JSX.Element;
  dropdownAddon?: ReactNode | ((inputValue: string) => ReactNode);
  dropdownPlaceholder?: string;
};

/**
 * `Combobox`
 * Предоставляет свободный пользовательский ввод с возможностью выбора представленных `рекомендации` в `Dropdown`.
 * Имеет Generic `T`, который позволяет затипизировать `Options`, с которыми будет работать `Dropdown`.
 *
 * На что стоит обратить внимание:
 * Если `Options` является объектом (например, {name: 'Болт широкий', unit: 'si-millimetre'}), то в таком случае
 * необходимо внимательно отнестись к функциям `itemToString` и `itemToKey`. Т.к. эти функции вызываются и в `useCombobox`, и в `Options`.
 * В первом случае необходимо возвращать строку, а во втором то, чего требует постановка задачи (хоть строку, хоть компонент).
 *
 * @param props
 * @param props.allowContentSearch - разрешить поиск среди options (только если options - массив)
 * @param props.className - внутренний проп, необходимый для `styled-components`;
 * @param props.id - id для связи с `Input`;
 * @param props.disabled - задизейблить компонент;
 * @param props.disablePortal - регулирует моунт компонента `Dropdown` либо в `body` (`disablePortal = false`), либо по месту использования самого компонента (`Combobox`);
 * @param props.hasCustomItem - отображать самым первым элементом в `Options` `+ Добавить значение {value}`;
 * @param props.value - пользовательский ввод (работает только со строкой);
 * @param props.rightAddon - компонент справа (может быть чем угодно, обычно это `IconButton`);
 * @param props.controlRef - ref для возможности взаимодействия с downshift извне
 * @param props.error - флаг подсветки `Input` контейнера красным цветом;
 * @param props.placeholder - плейсхолдер в инпуте;
 * @param props.options - функция-запрос (или массив элементов) на получение рекомендаций в зависимости от `value` (необходим для `Options`);
 * @param props.maxLength - максимальная длина ввода значения в `Input`;
 * @param props.itemToKey - функция - трансформатор, для получения ключа;
 * @param props.itemToString - функция - трансформатор, для получения человеко-читаемой информации, может возвращать и текст, и компонент;
 * @param props.onChange - функция, вызываемая при изменении состояния `useCombobox`, триггером служит либо пользовательский ввод в `Input`, либо выбор элемента из `Options`;
 * @param props.disableItem - условие дизейбла элемента выпадающего списка (функция, вызываемая при отображении элемента в Dropdown)
 * @param props.onStateChange -функция, вызываемая при изменении состояния `useCombobox`. Нужна для более гибкого управления состоянием `Combobox` из-вне, т.к. триггерится на абсолютно
 * любые изменения (события мыши, клавиатуры, изменения инпута, выбора элемента из `Options` и т.д.);
 * @param props.style - передача инлайн-стиля;
 * @param props.dropdownAddon - компонент в нижней части Dropdown
 * @param props.dropdownPlaceholder - плейсхолдер выпадающего списка при отсутствии options
 * @param ref - получения ссылки к ноде контейнера `Combobox` (обычно используется компонентом `Tooltip`);
 * @function
 */
function InnerCombobox<T extends any = string>(
  {
    className,
    style,
    id,
    allowContentSearch,
    controlRef,
    disabled = false,
    disablePortal,
    dropdownAddon,
    dropdownPlaceholder,
    hasCustomItem = true,
    heightItem,
    value,
    rightAddon,
    error,
    placeholder = 'Введите значение',
    options = [],
    maxLength,
    itemToKey = STUB_FUNC.ITEM as (prop: any) => string,
    itemToString = STUB_FUNC.ITEM as (prop: any) => string,
    disableItem,
    onChange,
    onStateChange,
  }: IComboboxProps<T>,
  ref: ForwardedRef<HTMLInputElement>,
): ReactElement {
  const containerRef = useRef<HTMLDivElement>(null);
  const [asyncState, dispatch] = useAsyncOptions();

  const isAsyncOptions = typeof options === 'function';

  const expandedItemToString = useCallback<ItemToStringAndOptionsType<T>>(
    (item, props) => {
      if (
        hasCustomItem &&
        props?.isOption &&
        props?.index === 0 &&
        value?.length > 0
      ) {
        return `+ Добавить значение "${value}"`;
      }

      if (!item) {
        return '';
      }

      return typeof item === 'string'
        ? item
        : itemToString(
            item,
            props
              ? {
                  isOption: true,
                  index: props.index,
                  disabled: props.disabled,
                }
              : undefined,
          );
    },
    [hasCustomItem, itemToString, value],
  );

  const filteredItemsWithCustomItem = useMemo(() => {
    // eslint-disable-next-line no-nested-ternary
    const mainItems = isAsyncOptions
      ? asyncState.items
      : allowContentSearch
      ? filterByInputValue(options, value, itemToString as ItemToStringType)
      : options;

    return hasCustomItem && value?.length > 0
      ? [value, ...mainItems]
      : mainItems;
  }, [
    allowContentSearch,
    asyncState.items,
    hasCustomItem,
    isAsyncOptions,
    itemToString,
    options,
    value,
  ]);

  const handleInputValueChange = useCallback<OnStateChange<T>>(
    ({ inputValue }) => {
      if (onChange && typeof inputValue === 'string') {
        onChange(inputValue);
      }
    },
    [onChange],
  );

  const handleSelectedItemChange = useCallback<OnStateChange<T>>(
    ({ selectedItem }) => {
      if (onChange && selectedItem) {
        onChange(selectedItem);
      }
    },
    [onChange],
  );

  const downshift = useCombobox<T>({
    inputValue: value,
    items: filteredItemsWithCustomItem,
    stateReducer,
    defaultHighlightedIndex: 0,
    itemToString: expandedItemToString as ItemToStringInner<T>,
    onStateChange,
    onInputValueChange: handleInputValueChange,
    onSelectedItemChange: handleSelectedItemChange,
  });

  useImperativeHandle(controlRef, () => downshift);

  const { getInputProps, getComboboxProps } = downshift;

  return (
    <InputContainer
      {...getComboboxProps({
        ref: containerRef,
        disabled,
        className,
        style,
      })}
      error={error}
    >
      <BaseInput
        {...getInputProps({
          id,
          disabled,
          maxLength,
          placeholder,
          ref,
          onChange: (
            event: FormEvent<HTMLInputElement> & { target: HTMLInputElement },
          ) => onChange && onChange(event?.target?.value),
        })}
        pr={rightAddon ? 0 : 2}
      />
      {rightAddon && <AddonWrapper>{rightAddon}</AddonWrapper>}
      <Options
        anchorEl={containerRef.current}
        disableItem={disableItem}
        disablePortal={disablePortal}
        dispatch={dispatch}
        downshift={downshift}
        dropdownAddon={dropdownAddon}
        filteredItems={filteredItemsWithCustomItem}
        heightItem={heightItem}
        itemToKey={itemToKey}
        itemToString={expandedItemToString}
        options={options}
        placeholder={dropdownPlaceholder}
        state={asyncState}
      />
    </InputContainer>
  );
}

const Combobox = reactForwardRef(InnerCombobox);

export { Combobox, stateReducer, OnStateChange };
