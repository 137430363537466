import { useState, FC } from 'react';

import styled, { css } from 'styled-components';

import { Dropdown, Text, Button, Container, BaseInput } from 'UI';

const TextArea = styled.textarea(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: block;
    width: 100%;
    height: 96px;
    margin: 0;
    padding: ${space[2]}px;
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${colors.divider};
    color: ${colors.text.primary};
    font-size: ${fontSizes[3]}px;
    line-height: 1.8em;
    letter-spacing: 0.05em;
    resize: none;

    &::placeholder {
      line-height: 2em;
      color: ${colors.text.disabled};
      font-size: ${fontSizes[3]}px;
    }
  `,
);

const FillingDescription = styled.div(
  ({ theme: { space, colors } }) => css`
    padding: ${space[2]}px;
    word-break: break-all;
    color: ${colors.text.secondary};
    border-width: 0 0 1px 0;
    border-style: solid;
    border-color: ${colors.divider};
  `,
);

const Input = styled(BaseInput)(
  ({ theme: { space, colors, fontSizes } }) => css`
    width: 100%;
    padding: ${space[2]}px;
    font-size: ${fontSizes[3]}px;
    border-bottom: 1px solid ${colors.divider};

    &::placeholder {
      font-size: ${fontSizes[3]}px;
    }
  `,
);

interface IDescriptionPopupProps {
  open: boolean;
  isTitle?: boolean;
  anchorEl: HTMLElement;
  maxLength?: number;
  titlePlaceholder?: string;
  descriptionPlaceholder?: string;
  filledDescription?: string;
  slotTitle?: string;
  valueTitle?: string;
  valueDescription?: string;
  onSubmit: (description?: string, name?: string) => void;
}

const DescriptionPopup: FC<IDescriptionPopupProps> = ({
  open,
  isTitle,
  anchorEl,
  maxLength = 150,
  titlePlaceholder,
  descriptionPlaceholder,
  filledDescription,
  slotTitle = '',
  valueTitle = '',
  valueDescription = '',
  onSubmit,
}) => {
  const mainTitle = valueTitle.length > 0 ? valueTitle : slotTitle;
  const [description, setDescription] = useState<string>(valueDescription);
  const mainDescription =
    description.length > 0 ? description : filledDescription;
  const [title, setTitle] = useState<string>(mainTitle);

  const handleChangeTitle = ({ target }): void => {
    setTitle(target.value);
  };

  const handleChangeDescription = ({ target }): void => {
    setDescription(target.value.replace(/\n\s+/, '\n'));
  };

  const handleRequestClose = (): void => {
    onSubmit(
      description.trim().length > 0 ? description.trim() : mainDescription,
      title.trim().length > 0 ? title.trim() : mainTitle,
    );
  };

  return (
    <Dropdown
      anchorEl={anchorEl}
      open={open}
      placement='top-end'
      width={380}
      onRequestClose={handleRequestClose}
    >
      {isTitle && (
        <Input
          placeholder={titlePlaceholder}
          type='text'
          value={title}
          onChange={handleChangeTitle}
        />
      )}
      {slotTitle && (
        <FillingDescription>
          Название слота: {slotTitle}
          <br />
          Описание из слота: {filledDescription ?? '-'}
        </FillingDescription>
      )}
      {!slotTitle && (
        <>
          <TextArea
            maxLength={maxLength}
            placeholder={descriptionPlaceholder}
            value={description}
            onChange={handleChangeDescription}
          />
          <Container bg='background' justifyContent='space-between' p={2}>
            <Text color='text.disabled' fontSize={1}>
              {description.length}/{maxLength}
            </Text>
            <Button variant='string' onClick={handleRequestClose}>
              Сохранить
            </Button>
          </Container>
        </>
      )}
    </Dropdown>
  );
};

export { DescriptionPopup };
