import { FC, memo } from 'react';

import styled, { css } from 'styled-components';

import { TrashIcon } from 'resources/icons/18';
import { IForbidden } from 'common/types/permissions.types';
import { Badge, IconButton, Text } from 'UI';

const Wrapper = styled.div(
  ({ theme: { colors, space, borderRadius } }) => css`
    display: flex;
    align-items: center;
    min-height: ${space[6]}px;
    padding: ${space[1]}px ${space[2]}px;
    margin-top: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
  `,
);

interface IItemCard {
  title: string;
  label: string;
  forbidden?: IForbidden['forbidden'];
  onRemove: VoidFunction;
}

/**
 * Отображает карточку добавленного элемента
 */
const ItemCard: FC<IItemCard> = memo(
  ({ title, label, forbidden, onRemove, ...other }) => {
    return (
      <Wrapper {...other}>
        <Text truncate fontSize={3} fontWeight={500} mr={2}>
          {title}
        </Text>
        <Badge fontWeight={600} label={label} ml='auto' mr='0px' />
        {!forbidden?.remove && (
          <IconButton ml={1} title='Удалить' onClick={onRemove}>
            <TrashIcon />
          </IconButton>
        )}
      </Wrapper>
    );
  },
);

export { ItemCard };
