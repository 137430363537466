import { FC, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';

import { ImageItem } from './ImageItem';

const ImageList = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    min-height: 104px;
    max-height: 104px;
    margin: 0 ${space[7]}px ${space[4]}px;
    padding: ${space[2]}px 0 ${space[1]}px;
    border-top: 1px solid ${colors.divider};
    overflow-x: auto;
    overflow-y: hidden;
  `,
);

interface IPreviewItemProps {
  img: IImage;
  active: boolean;
  onSelect: VoidFunction;
}

const PreviewItem: FC<IPreviewItemProps> = ({ img, active, onSelect }) => {
  const scrollRef = useRef<Nullable<HTMLDivElement>>(null);

  useEffect(() => {
    if (active)
      scrollRef.current!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
  }, [active]);

  return (
    <ImageItem
      active={active}
      bg='white'
      img={img}
      innerRef={scrollRef}
      mr={1}
      size={7}
      onSelectedImage={onSelect}
    />
  );
};

interface IListPreviewProps {
  selectedImageIndex: number;
  images: IImage[];
  onSelect: (value: number) => void;
}

const ListPreview: FC<IListPreviewProps> = ({
  selectedImageIndex,
  images,
  onSelect,
}) => {
  return (
    <ImageList>
      {images.map((img, index) => (
        <PreviewItem
          key={img.id}
          active={selectedImageIndex === index}
          img={img}
          onSelect={() => onSelect(index)}
        />
      ))}
    </ImageList>
  );
};

export { ListPreview };
