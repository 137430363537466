import styled, { css } from 'styled-components';

export const CheckboxContainer = styled.label(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 52px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

export const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    gap: ${space[0]}px;
    flex-wrap: wrap;
  `,
);
