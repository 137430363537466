import { ReactElement, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { autoSizeHeight } from 'common/utils';
import { AddButton, DeleteButton } from 'components/multi-input/components';
import { useArrayField, useField } from 'reform';
import { AddSmallIcon } from 'resources/icons/12';
import { TrashIcon } from 'resources/icons/18';
import { FormLabel, TextArea, Tooltip, Container } from 'UI';

const StyledTextArea = styled(TextArea)(
  ({ theme: { space, colors }, error }) => css`
    height: auto;
    min-height: ${space[4]}px;
    line-height: ${space[2]}px;
    border-radius: 0;
    border-bottom: 1px solid transparent;

    & > textarea {
      height: 33px;
    }

    &:first-child {
      border-right: 1px solid transparent;
    }

    ${error &&
    css`
      border-bottom: 1px solid ${colors.error.main};

      &:first-child {
        border-right: 1px solid ${colors.error.main};
      }
    `}
  `,
);

const ItemContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: grid;
    grid-template-columns: 1fr 1fr ${space[4]}px;
    grid-template-rows: minmax(${space[4]}px, auto);

    &:last-child {
      border-bottom: 1px solid ${colors.divider};
    }
  `,
);

function ReasonsAndRecommendationItem({
  index,
  name,
  fieldKye,
  disabled,
  onRemove,
}): ReactElement {
  const { value, error, setValue, setError } = useField({
    name,
    key: fieldKye,
  });
  const reasonContainerRef = useRef<HTMLDivElement>();
  const reasonInputRef = useRef<HTMLTextAreaElement>();
  const recommendationContainerRef = useRef<HTMLDivElement>();
  const recommendationInputRef = useRef<HTMLTextAreaElement>();

  useEffect(() => {
    reasonContainerRef.current?.addEventListener('click', () =>
      reasonInputRef.current?.focus(),
    );

    recommendationContainerRef.current?.addEventListener('click', () =>
      recommendationInputRef.current?.focus(),
    );

    const removeEventHandlerReasonInput = autoSizeHeight(
      reasonInputRef.current,
    );

    const removeEventHandlerRecommendationInput = autoSizeHeight(
      recommendationInputRef.current,
    );

    return () => {
      if (removeEventHandlerReasonInput) {
        removeEventHandlerReasonInput();
      }

      if (removeEventHandlerRecommendationInput) {
        removeEventHandlerRecommendationInput();
      }
    };
  }, []);

  const handleChange = (key: string) => {
    return ({ target }): void => {
      setValue(prev => ({ ...prev, [target.name]: target.value }));

      if (error[key]) {
        setError(({ [key]: _, ...otherErrors }) => otherErrors);
      }
    };
  };

  return (
    <ItemContainer>
      <Tooltip hint title={error?.reason}>
        <StyledTextArea
          ref={reasonContainerRef}
          error={error?.reason}
          inputRef={reasonInputRef}
          maxLength={1000}
          name='reason'
          placeholder='Введите причины'
          value={value.reason}
          onChange={handleChange('reason')}
        />
      </Tooltip>
      <Tooltip hint title={error?.recommendation}>
        <StyledTextArea
          ref={recommendationContainerRef}
          error={error?.recommendation}
          inputRef={recommendationInputRef}
          maxLength={1000}
          name='recommendation'
          placeholder='Введите рекомендации'
          value={value.recommendation}
          onChange={handleChange('recommendation')}
        />
      </Tooltip>
      <DeleteButton
        disabled={disabled}
        first={index === 0}
        title='Удалить причину'
        onClick={onRemove}
      >
        <TrashIcon />
      </DeleteButton>
    </ItemContainer>
  );
}

function ReasonsAndRecommendations(): ReactElement {
  const { fields, push, remove } = useArrayField({
    name: 'reasonsAndRecommendations',
  });

  const handleAddReason = (): void => {
    push({ id: uuid(), reason: '', recommendation: '' });
  };

  return (
    <Container display='block' mb={3}>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <FormLabel required mb={1}>
        Причины неисправностей и рекомендации по их устранению
      </FormLabel>
      {fields.map(({ id }, index) => (
        <ReasonsAndRecommendationItem
          key={id}
          disabled={fields.length === 1}
          fieldKye={id}
          index={index}
          name={`reasonsAndRecommendations.${index}`}
          onRemove={() => remove(index)}
        />
      ))}
      {fields.length < 10 && (
        <AddButton onClick={handleAddReason}>
          <AddButton.WrapperIcon>
            <AddSmallIcon color='white' />
          </AddButton.WrapperIcon>
          Добавить причину
        </AddButton>
      )}
    </Container>
  );
}

export { ReasonsAndRecommendations };
