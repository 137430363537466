import { ProductSections } from 'common/types/product.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { FiledType } from 'reform';

function filterPropertiesError({ fieldValue, fieldError }): boolean {
  const propertyType =
    !!fieldValue && typeof fieldValue === 'object' && fieldValue.type;

  /**
   * Значит ошибка у родительского компонента Properties
   */
  if (!propertyType) {
    return typeof fieldError === 'string';
  }

  if (propertyType === PropertiesTypes.group) {
    return (
      typeof fieldError === 'string' ||
      typeof fieldError?.value === 'string' ||
      !!fieldError?.name
    );
  }

  if (
    propertyType === PropertiesTypes.standardMaterial ||
    propertyType === PropertiesTypes.standardCoating ||
    propertyType === PropertiesTypes.regulatoryReference ||
    propertyType === PropertiesTypes.arbitraryRegulatoryReference
  ) {
    return (
      typeof fieldError === 'string' || !!fieldError.name || !!fieldError.value
    );
  }

  return true;
}

function filterProductError(
  field: FiledType,
  fieldValue: any,
  fieldError: any,
): boolean {
  const { section } = field.meta ?? {};

  switch (section) {
    case ProductSections.properties: {
      return filterPropertiesError({ fieldValue, fieldError });
    }
    case ProductSections.price: {
      return typeof fieldError === 'string';
    }
    default:
      return true;
  }
}

export { filterProductError };
