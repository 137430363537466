import { FC, Fragment, useEffect, useReducer } from 'react';

import { useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { layout, LayoutProps } from 'styled-system';

import {
  addToFavorite as fetchAddToFavorite,
  removeFromFavorite as fetchRemoveFromFavorite,
  updateSubscribeStatus,
} from 'common/api/favorite.api';
import { Nullable } from 'common/types/common.types';
import {
  IFavoriteProduct,
  ProductCategory,
  ProductLifeState,
  Statistics,
} from 'common/types/product.types';
import { RemoveFromFavoriteModal } from 'components/products/modals';
import { getCompanyId, hasIdentity } from 'entities/identity';
import { ModalController } from 'entities/modals';
import { useNotify, BUTTONS_ACTION } from 'entities/notify';
import {
  UnsubscribeIcon,
  SubscribeIcon,
  FaveBoldIcon,
  EyeVisibleIcon,
} from 'resources/icons/18';
import { FAVORITE, IDENTITY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import { Tooltip, Text, Button, IconButton, Container } from 'UI';

import { createProductView } from '../../../../../api';

const ButtonWrapper = styled.div<LayoutProps>(
  ({ theme: { colors, space } }) => css`
    display: inline-flex;
    align-items: center;
    margin-right: ${space[2]}px;
    color: ${colors.text.secondary};
    font-weight: 600;

    ${layout};
  `,
);

const Counter = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const enum ActionTypes {
  startFavorite = 'startFavorite',
  finishFavorite = 'finishFavorite',
  addToFavorite = 'addToFavorite',
  removeFromFavorite = 'removeFromFavorite',
  startUpdateSubscribe = 'startUpdateSubscribe',
  finishUpdateSubscribe = 'finishUpdateSubscribe',
  updateSubscribe = 'updateSubscribe',
}

interface IFavoriteState {
  favoriteId: Nullable<string>;
  isSubscribed: boolean;
  disabledFavorite: boolean;
  disabledSubscribe: boolean;
  favoritesCount: number;
}

interface IFavoriteAction {
  type: ActionTypes;
  payload?: any;
}

const INITIAL_STATE: IFavoriteState = {
  favoriteId: null,
  isSubscribed: false,
  disabledFavorite: false,
  disabledSubscribe: false,
  favoritesCount: 0,
};

function favoriteReducer(
  state: IFavoriteState,
  { type, payload }: IFavoriteAction,
): IFavoriteState {
  switch (type) {
    case ActionTypes.startFavorite:
      return { ...state, disabledFavorite: true };
    case ActionTypes.finishFavorite:
      return { ...state, disabledFavorite: false };
    case ActionTypes.addToFavorite:
      return {
        ...state,
        disabledFavorite: false,
        favoriteId: payload ?? null,
        favoritesCount: state.favoritesCount + 1,
      };
    case ActionTypes.removeFromFavorite:
      return {
        ...INITIAL_STATE,
        favoritesCount: state.favoritesCount - 1,
      };
    case ActionTypes.startUpdateSubscribe:
      return { ...state, disabledSubscribe: true };
    case ActionTypes.finishUpdateSubscribe:
      return { ...state, disabledSubscribe: false };
    case ActionTypes.updateSubscribe:
      return {
        ...state,
        disabledSubscribe: false,
        isSubscribed: payload ?? false,
      };
    default:
      return state;
  }
}

interface IProductStatisticsProps {
  productId: string;
  companyId: string;
  lifeState: ProductLifeState;
  favorite: IFavoriteProduct;
  category: ProductCategory;
  statistics: Statistics;
}

const ProductStatistics: FC<IProductStatisticsProps> = ({
  productId,
  companyId,
  lifeState,
  favorite,
  category,
  statistics,
}) => {
  const notify = useNotify();
  const location = useLocation();
  const { isAuthed, companyId: currentCompanyId } = useAppSelector(state => ({
    companyId: getCompanyId(state),
    isAuthed: hasIdentity(state),
  }));
  const isOwnProduct = currentCompanyId === companyId;
  const isQuestionnaire = category === ProductCategory.questionnaire;

  const [
    {
      favoriteId,
      isSubscribed,
      disabledFavorite,
      disabledSubscribe,
      favoritesCount,
    },
    dispatch,
  ] = useReducer(favoriteReducer, {
    ...INITIAL_STATE,
    favoriteId: favorite?.id,
    isSubscribed: favorite?.isSubscribed,
    favoritesCount: statistics?.favoritesCount ?? 0,
  });

  const handleAddToFavorite = (): void => {
    dispatch({ type: ActionTypes.startFavorite });
    fetchAddToFavorite(productId).then(
      data => {
        dispatch({ type: ActionTypes.addToFavorite, payload: data[0].id });
      },
      error => {
        dispatch({ type: ActionTypes.finishFavorite });
        notify.error(error.response.data.violations[0].message);
      },
    );
  };

  const handleRemoveFromFavorite = (): void => {
    dispatch({ type: ActionTypes.startFavorite });
    fetchRemoveFromFavorite([favoriteId!]).then(
      () => {
        dispatch({ type: ActionTypes.removeFromFavorite });
        notify.success(
          'Товар удален из избранного. Подписка на выбранный товар отменена.',
          {
            as: BUTTONS_ACTION.BUTTON,
            title: 'Восстановить',
            onClick: handleAddToFavorite,
          },
        );
      },
      () => {
        dispatch({ type: ActionTypes.finishFavorite });
        notify.error('Произошла ошибка. Обновите страницу.');
      },
    );
  };

  const handleUpdateSubscribeStatus = (): void => {
    dispatch({ type: ActionTypes.startUpdateSubscribe });

    updateSubscribeStatus([favoriteId!], { isSubscribed: !isSubscribed }).then(
      () => {
        const message = isSubscribed
          ? 'Подписка на обновления отменена.'
          : 'Подписка на обновления подтверждена.';

        notify.success(
          message,
          !isSubscribed && {
            as: BUTTONS_ACTION.LINK,
            to: FAVORITE.LIST,
            title: 'Перейти в избранное',
            target: '_blank',
          },
        );

        dispatch({ type: ActionTypes.updateSubscribe, payload: !isSubscribed });
      },
      () => {
        dispatch({ type: ActionTypes.finishUpdateSubscribe });
        notify.error('Произошла ошибка. Обновите страницу.');
      },
    );
  };

  useEffect(() => {
    if (lifeState === ProductLifeState.public && !isOwnProduct) {
      createProductView(productId);
    }
    // One request for update views count
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (lifeState !== ProductLifeState.public) return null;

  return (
    <Container
      alignItems='center'
      display='inline-flex'
      height={16}
      maxWidth='100%'
    >
      <Tooltip
        pointer
        placement='bottom-start'
        title='Количество пользователей, просмотревших страницу'
      >
        <ButtonWrapper maxWidth='50%'>
          <EyeVisibleIcon mr={0} />
          <Counter>{statistics?.viewsCount ?? 0}</Counter>
        </ButtonWrapper>
      </Tooltip>
      <ButtonWrapper>
        <Tooltip
          pointer
          placement='bottom-start'
          title='Количество пользователей, добавивших товар в избранное'
        >
          <div style={{ display: 'flex' }}>
            <FaveBoldIcon color={favoriteId ? '#F0C84B' : 'divider'} mr={0} />
            <Counter>{favoritesCount}</Counter>
          </div>
        </Tooltip>
        {isAuthed ? (
          <Tooltip
            pointer
            placement='bottom-start'
            title={
              favoriteId ? 'Удалить из избранного' : 'Добавить в избранное'
            }
          >
            <Button
              as='button'
              color={favoriteId ? 'secondary' : 'primary'}
              disabled={disabledFavorite}
              mx={1}
              variant='string'
              onClick={
                favoriteId ? handleRemoveFromFavorite : handleAddToFavorite
              }
            >
              {favoriteId ? 'Из избранного' : 'В избранное'}
            </Button>
          </Tooltip>
        ) : (
          <Tooltip
            arrow
            maxWidth={290}
            title={
              <Fragment>
                <Link
                  to={{
                    pathname: IDENTITY.REGISTRATION,
                    state: { from: location.pathname },
                  }}
                >
                  Зарегистрируйтесь
                </Link>{' '}
                или{' '}
                <Link
                  to={{
                    pathname: IDENTITY.AUTHENTICATION,
                    state: { from: location.pathname },
                  }}
                >
                  войдите в систему
                </Link>
                , чтобы добавить {isQuestionnaire ? 'опросной лист' : 'товар'} в
                избранное.
              </Fragment>
            }
          >
            <Text color='primary.disabled' ml={1}>
              В избранное
            </Text>
          </Tooltip>
        )}
        {favoriteId && (
          <Tooltip
            arrow
            pointer
            placement='top'
            title={isSubscribed ? 'Отписаться' : 'Подписаться'}
          >
            <IconButton
              disabled={disabledSubscribe}
              size={28}
              variant={isSubscribed ? 'secondary' : 'outline'}
              onClick={handleUpdateSubscribeStatus}
            >
              {isSubscribed ? <UnsubscribeIcon /> : <SubscribeIcon />}
            </IconButton>
          </Tooltip>
        )}
      </ButtonWrapper>
      <ModalController
        component={RemoveFromFavoriteModal}
        type={RemoveFromFavoriteModal.TYPE}
      />
    </Container>
  );
};

export { ProductStatistics };
