import {
  cloneElement,
  CSSProperties,
  forwardRef,
  isValidElement,
  MouseEvent,
  MouseEventHandler,
  ReactElement,
  ReactNode,
  useRef,
} from 'react';

import styled, { css } from 'styled-components';
import { FlexboxProps, SpaceProps, WidthProps } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { TrashIcon } from 'resources/icons/18';
import { CloseMediumIcon } from 'resources/other-28';
import { DoneIcon } from 'resources/other';
import { Container, FormError, FormLabel, IconButton, Text, Tooltip } from 'UI';
import { TipType } from 'UI/Tip';

const CancelIconButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    background: ${colors.background};

    &:disabled {
      background: ${colors.background};
    }
  `,
);

interface IFieldWrapperProps {
  className?: string;
  style?: CSSProperties;
  showErrorDescription?: boolean;
  disabled?: boolean;
  required?: boolean;
  injectedProps?: boolean;
  submitOnEnter?: boolean;
  label?: ReactNode;
  tip?: TipType;
  title?: ReactNode;
  hint?: ReactNode;
  error?: ReactNode;
  children: ReactNode;
  rightAddon?: ReactNode;
  submitButton?: {
    icon?: ReactNode;
    disabled?: boolean;
    title?: string | object;
    onSubmit: (event: MouseEvent<HTMLElement>) => void;
  };
  cancelButton?: {
    icon?: ReactNode;
    disabled?: boolean;
    title?: string | object;
    onRemove?: MouseEventHandler<HTMLButtonElement>;
    onCancel?: MouseEventHandler<HTMLButtonElement>;
  };
}

/**
 *  Компонент-коструктор
 *  Оборачивает children и добавляет в зависимости от конфигурации:
 *   - заголовок, в котором могут отрендериться лейбл и rightAddon;
 *   - отображает ошибку, под children;
 *   - кнопку-крестик справа;
 *
 *  @param props
 *  @param props.showErrorDescription - резервирует место для ошибки под children (необходимо для того, чтобы когда ошибка появится интерефейс не дергался)
 *  @param props.injectedProps - производит клонирование children и вставляет дополнительные свойства в ребенка (disabled, error, id)
 *  @param props.submitOnEnter - активирует возможность вызывать onSubmit по нажатию на Enter
 *  @param props.disabled - дизейблит все управляющие элементы
 *  @param props.required - добавляет к лейбл красную звезду
 *  @param props.label - рендерит лейбл
 *  @param props.hint - подсказка под полем
 *  @param props.rightAddon - добавляет значения rightAddon в заголовок справа
 *  @param props.title - оборачивает children в <Tooltip> и передает значение пропа в <Tooltip title={title} ...>
 *  @param props.tip - добавляет к лейбл подсказку в виде знака вопроса
 *  @param props.error - рендерит текст ошибки с красным цветом
 *  @param props.cancelButton - рендерит IconButton  с иконкой крестик справа от children
 */
const FieldWrapper = forwardRef<
  HTMLDivElement,
  IFieldWrapperProps & SpaceProps & WidthProps & FlexboxProps
>(
  (
    {
      className,
      style,
      showErrorDescription,
      submitOnEnter,
      injectedProps,
      disabled,
      required,
      label,
      tip,
      title,
      hint,
      error,
      rightAddon,
      children,
      submitButton,
      cancelButton,
      ...other
    },
    ref,
  ): ReactElement => {
    const idRef = useRef(uuid());

    let controls: null | ReactElement = null;

    if (!!submitButton && !!cancelButton) {
      controls = (
        <Container column ml={1}>
          <CancelIconButton
            borderBottomLeftRadius={0}
            borderBottomRightRadius={0}
            disabled={cancelButton.disabled || disabled}
            title={cancelButton.title}
            onClick={cancelButton.onRemove ?? cancelButton.onCancel}
          >
            {cancelButton.onRemove ? <TrashIcon /> : <CloseMediumIcon />}
          </CancelIconButton>
          <IconButton
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            disabled={submitButton.disabled || disabled}
            title={submitButton.title}
            variant='darkHighlight'
            {...(submitOnEnter
              ? { type: 'submit' }
              : { onClick: submitButton.onSubmit })}
          >
            <DoneIcon />
          </IconButton>
        </Container>
      );
    } else if (submitButton) {
      controls = (
        <IconButton
          disabled={submitButton.disabled || disabled}
          ml={1}
          title={submitButton.title}
          variant='darkHighlight'
          {...(submitOnEnter
            ? { type: 'submit' }
            : { onClick: submitButton.onSubmit })}
        >
          {submitButton.icon ?? <DoneIcon />}
        </IconButton>
      );
    } else if (cancelButton) {
      controls = (
        <IconButton
          disabled={cancelButton.disabled || disabled}
          ml={1}
          size={34}
          title={cancelButton.title}
          onClick={cancelButton.onRemove ?? cancelButton.onCancel}
        >
          {cancelButton.icon ?? cancelButton.onRemove ? (
            <TrashIcon />
          ) : (
            <CloseMediumIcon />
          )}
        </IconButton>
      );
    }

    const handleSubmitOnForm = (event: MouseEvent<HTMLFormElement>): void => {
      event.preventDefault();
      submitButton!.onSubmit(event);
    };

    return (
      <Container
        ref={ref}
        column
        className={className}
        style={style}
        {...(submitOnEnter &&
          ({ as: 'form', onSubmit: handleSubmitOnForm } as any))}
        {...other}
      >
        {(rightAddon || label) && (
          <Container justifyContent='space-between' mb={1}>
            {label && (
              <FormLabel
                disabled={disabled}
                htmlFor={idRef.current}
                mb='0px'
                required={required}
                tip={tip}
              >
                {label}
              </FormLabel>
            )}
            {rightAddon}
          </Container>
        )}
        <Container>
          {/* Данная ветка предназначена прежде всего для input'ов (TextInput, Select, Combobox и т.д.) */}
          {isValidElement(children) && injectedProps ? (
            <Tooltip arrow placement='top' title={title}>
              {cloneElement(children, {
                id: idRef.current,
                disabled,
                error,
                ...children.props,
              })}
            </Tooltip>
          ) : (
            children
          )}
          {controls}
        </Container>
        {!error && hint && (
          <Text color='text.secondary' fontSize={1} mt={1}>
            {hint}
          </Text>
        )}
        {(showErrorDescription || error) && (
          <FormError show={!!error}>{error}</FormError>
        )}
      </Container>
    );
  },
);

export { FieldWrapper };
