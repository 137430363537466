import styled, { css } from 'styled-components';

import { IconButton } from 'UI';

import { CollapsePanel } from '../../../components';

export const RightActionsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    margin-left: auto;
    padding-left: ${space[1]}px;
    visibility: hidden;

    ${CollapsePanel}:hover & {
      visibility: visible;
    }
  `,
);

export const FolderIconWrapper = styled.div`
  display: flex;
`;

export const MoveIconWrapper = styled(IconButton)`
  display: none;

  &:hover {
    background: transparent;
  }
`;

export const StyledCollapsePanel = styled(CollapsePanel)`
  &:hover {
    & ${FolderIconWrapper} {
      display: none;
    }

    & ${MoveIconWrapper} {
      display: flex;
    }
  }
`;
