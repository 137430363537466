import { CSSProperties } from 'react';

import styled, { css } from 'styled-components';

import { PropertiesFormIcon } from 'resources/placeholders';
import { Text } from 'UI';

const PlaceholderContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 ${space[5]}px;
  `,
);

interface IProductFormsPlaceholderProps {
  style?: CSSProperties;
  text: JSX.Element | string | undefined;
}

function ProductFormsPlaceholder({
  style,
  text,
}: IProductFormsPlaceholderProps): JSX.Element {
  return (
    <PlaceholderContainer style={style}>
      <PropertiesFormIcon mb={3} />
      <Text color='text.secondary' fontSize={5} textAlign='center'>
        {text}
      </Text>
    </PlaceholderContainer>
  );
}

export { ProductFormsPlaceholder };
