import { FC, Fragment, memo, useCallback, useRef } from 'react';

import styled, { css } from 'styled-components';

import { SettingsIcon } from 'resources/icons/18';
import { CloseMediumIcon } from 'resources/other-28';
import { SideFilterButtons, SideFilterWrapper } from 'components/side-filter';
import { ProductionStateFilter } from 'components/side-filter/components';
import { IReform, Reform } from 'reform';
import { Delete1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';
import { STUB_FUNC } from 'tools/utils';
import { IconButton, SideMenuGroup, StyledTag, Text, Tooltip } from 'UI';

import { ProductCategoryFilter } from '../../../../../features/products/_shared/filters/Filters';
import { PRODUCTION_STATES_FILTERS } from '../../../../../features/products/constants';

const Container = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    right: -320px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 320px;
    padding: 0;
    box-shadow: none;
    background: ${colors.white};
    border-right: 1px solid ${colors.divider};
    z-index: 12;
  `,
);

interface IRouseProductsFilter {
  categories: string[];
  productionStates: string[];
}

const FILTER_INITIAL_VALUES: IRouseProductsFilter = {
  categories: [],
  productionStates: [],
};

const getCountOfFilters = (data: IRouseProductsFilter | undefined): number => {
  if (!data) return 0;

  const { categories, productionStates } = data;

  return (
    categories.length +
    productionStates.filter(
      item => item !== PRODUCTION_STATES_FILTERS.CANCELLED,
    ).length
  );
};

interface IAvailableProductsSideFilterProps {
  onReset: VoidFunction;
  onSubmit: VoidFunction;
}

const AvailableProductsSideFilter: FC<IAvailableProductsSideFilterProps> = memo(
  ({ onReset, onSubmit }) => {
    return (
      <Fragment>
        <SideFilterWrapper>
          <SideMenuGroup
            isFixedGroup
            primary
            indent={false}
            secondary={false}
            title={<Text fontSize={4}>Основная информация</Text>}
          >
            <ProductCategoryFilter name='categories' />
            <ProductionStateFilter name='productionStates' />
          </SideMenuGroup>
        </SideFilterWrapper>
        <SideFilterButtons isDirty onReset={onReset} onSubmit={onSubmit} />
      </Fragment>
    );
  },
);

const ProductsFilterForm: FC = memo(() => {
  const reformRef = useRef<IReform<IRouseProductsFilter>>(null);

  const [isOpen, toggleOpen] = useToggle(false);

  const initialValues = {
    categories: [],
    productionStates: [],
  };

  const countOfFilters = getCountOfFilters(reformRef.current?.getData());

  const handleReset = useCallback((): void => {
    reformRef.current?.setData(FILTER_INITIAL_VALUES);
  }, []);

  return (
    <Tooltip arrow hint placement='right' title='Скоро в системе'>
      <div>
        <IconButton
          disabled
          ml={1}
          variant={countOfFilters ? 'outline' : 'secondary'}
          onClick={toggleOpen}
        >
          {isOpen ? <CloseMediumIcon /> : <SettingsIcon />}
        </IconButton>
        {!!countOfFilters && (
          <Tooltip arrow placement='top' title='Сбросить'>
            <StyledTag
              bg='primary.main'
              color='white'
              fontSize={0}
              ml={1}
              px={0}
            >
              {countOfFilters}
              <Delete1pxIcon
                as='button'
                color='white'
                ml='2px'
                onClick={handleReset}
              />
            </StyledTag>
          </Tooltip>
        )}
        {isOpen && (
          <Container>
            <Reform ref={reformRef} initialValues={initialValues}>
              <AvailableProductsSideFilter
                onReset={handleReset}
                onSubmit={STUB_FUNC.NOOP}
              />
            </Reform>
          </Container>
        )}
      </div>
    </Tooltip>
  );
});

export { ProductsFilterForm };
