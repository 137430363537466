import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { ButtonProps, variantStyle } from 'UI/Buttons/Button';

export const Limit = styled.div<SpaceProps>(
  ({ theme: { fontSizes, colors } }) => css`
    margin-top: 6px;
    margin-right: 10px;
    width: 20px;
    align-self: flex-start;
    font-size: ${fontSizes[1]}px;

    color: ${colors.text.disabled};

    ${styledSpace}
  `,
);

export const AddCommentButton = styled.button<
  SpaceProps & Partial<Pick<ButtonProps, 'variant' | 'color'>>
>(
  ({ theme: { colors, space, fontSizes }, variant, color }) => css`
    border: 1px solid ${colors.divider};
    border-radius: 14px;
    color: ${colors.text.primary};

    ${styledSpace};
    ${variant && color && variantStyle({ variant, color })};

    padding: 0 ${space[0]}px;
    font-size: ${fontSizes[2]}px;
  `,
);
