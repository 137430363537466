import {
  createContext,
  FC,
  Fragment,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { useReform, useWatch } from 'reform';
import { AddMediumIcon } from 'resources/icons/18';
import { RemoveIcon } from 'resources/other';
import { insertItem, removeItem, replaceItem } from 'tools/utils';
import { Button, IconButton, Tooltip } from 'UI';

import { AddControl } from './styled';

const ControlWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${space[2]}px;
  `,
);

const RowCount = styled.div(
  ({ theme: { colors, fontSizes } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    border-bottom: 1px solid ${colors.divider};
    font-size: ${fontSizes[5]}px;
  `,
);

const TableContainer = styled.div(
  ({ theme: { space } }) => css`
    padding-bottom: ${space[2]}px;
    overflow-x: auto;
  `,
);

const HeaderWrapper = styled.div`
  display: flex;
`;

const EmptyCell = styled.div(
  ({ theme: { colors } }) => css`
    width: 30px;
    min-width: 30px;
    border: 1px solid ${colors.text.disabled};
  `,
);

const AddProperties = styled(AddControl)`
  &::before {
    left: -3px;
    top: 0;
    bottom: 0;
    width: 6px;
  }
`;

const TablePlaceholder = styled.div<SpaceProps>(
  ({ theme: { space, colors, fontSizes, borderRadius } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 523px;
    padding: ${space[4]}px 0;
    border: 1px dashed ${colors.divider};
    border-radius: ${borderRadius}px;
    background: ${colors.highlight[0]};
    color: ${colors.text.secondary};
    text-align: center;
    font-size: ${fontSizes[5]}px;

    ${styledSpace};
  `,
);

const SCROLL_WIDTH = 45;
const MARGIN_LEFT_COLUMN = 3;
const ADD_PROPERTY_TEXT = 'Добавить характеристику';

// @ts-ignore
const RowContext = createContext<any>();

const initialDisabled = {
  all: false,
  headerControl: false,
  rowControl: false,
  addColumn: false,
  addLastColumn: false,
  addColumnInHeader: false,
  removeColumn: false,
  addRow: false,
  removeRow: false,
};

const PropertiesTable: FC<any> = ({
  disabled = initialDisabled,
  hasMain,
  initialHeader,
  initialValidRows = [false],
  placeholder = '',
  table: { rowKeys },
  fieldName,
  row: Row,
  renderHeaderItem,
  options,
  setValue,
  setTableValid,
}) => {
  const { headers } = useWatch({ fieldName });

  const [validRows, setValidRows] = useState(initialValidRows);

  const { setFieldValue } = useReform();

  useEffect(() => {
    const isTableValid =
      validRows.length > 0 &&
      validRows.every(Boolean) &&
      headers.length > 0 &&
      headers.every(({ name }) => !!name);

    if (setTableValid) setTableValid(isTableValid);
  }, [headers, validRows, setTableValid]);

  const pushColumn = (index): void => {
    rowKeys.forEach((_, rowIndex) => {
      setFieldValue(`${fieldName}.rows.${rowIndex}`, prev => {
        return insertItem(prev, '', index);
      });
    });

    setValue(prev => ({
      ...prev,
      headers: insertItem(
        prev.headers,
        { id: uuid(), ...initialHeader },
        index,
      ),
    }));
  };

  const removeColumn = (index): void => {
    rowKeys.forEach((_, rowIndex) => {
      setFieldValue(`${fieldName}.rows.${rowIndex}`, prev => {
        return removeItem(prev, index);
      });
    });

    setValue(prev => ({ ...prev, headers: removeItem(prev.headers, index) }));
  };

  const pushRow = (index): void => {
    setValue(prev => ({
      ...prev,
      rows: insertItem(prev.rows, new Array(headers.length).fill(''), index),
      rowKeys: insertItem(rowKeys, uuid(), index),
    }));

    setValidRows(prev => insertItem(prev, false, index));
  };

  const removeRow = (index): void => {
    setValue(prev => ({
      ...prev,
      rows: removeItem(prev.rows, index),
      rowKeys: removeItem(rowKeys, index),
    }));

    setValidRows(prev => removeItem(prev, index));
  };

  const handleValidRows = useCallback((isValid, rowIndex) => {
    setValidRows(prev => replaceItem(prev, isValid, rowIndex));
  }, []);

  const rowsCount = rowKeys.length;
  const disabledRemoveRow =
    rowsCount <= 1 || disabled.removeRow || disabled.all;
  const disabledAddRow = disabled.addRow || disabled.all;
  const disabledAddColumn = disabled.addColumn || disabled.all;

  const isViewedTable = headers.length > (disabled.addLastColumn ? 1 : 0);

  const tableWidth =
    SCROLL_WIDTH +
    headers.reduce((acc, { width }) => acc + width + MARGIN_LEFT_COLUMN, 0);

  return (
    <Fragment>
      {!disabled.headerControl && (
        <ControlWrapper>
          <Button
            disabled={disabledAddColumn}
            mr={2}
            size='s'
            variant='outlined'
            onClick={() => {
              pushColumn(
                disabled.addLastColumn ? headers.length - 1 : headers.length,
              );
            }}
          >
            {ADD_PROPERTY_TEXT}
          </Button>
          {!disabled.rowControl && (
            <Fragment>
              Кол-во строк:
              <IconButton
                disabled={disabledRemoveRow}
                onClick={() => removeRow(rowsCount - 1)}
              >
                <RemoveIcon />
              </IconButton>
              <RowCount>{rowsCount}</RowCount>
              <IconButton
                disabled={disabledAddRow}
                onClick={() => pushRow(rowsCount)}
              >
                <AddMediumIcon />
              </IconButton>
            </Fragment>
          )}
        </ControlWrapper>
      )}
      {isViewedTable ? (
        <TableContainer>
          <HeaderWrapper>
            <EmptyCell />
            {headers.map((header, columnIndex) => (
              <Fragment key={header.id}>
                {!disabledAddColumn && !disabled.addColumnInHeader && (
                  <Tooltip arrow hint title={ADD_PROPERTY_TEXT}>
                    <AddProperties onClick={() => pushColumn(columnIndex)} />
                  </Tooltip>
                )}
                {renderHeaderItem({
                  disabled: disabled.all,
                  hasMain,
                  countRows: rowsCount,
                  columnIndex,
                  fieldName,
                  options,
                  onRemove: () => removeColumn(columnIndex),
                })}
              </Fragment>
            ))}
            {!disabledAddColumn &&
              !disabled.addLastColumn &&
              !disabled.addColumnInHeader && (
                <Tooltip arrow hint title={ADD_PROPERTY_TEXT}>
                  <AddProperties onClick={() => pushColumn(headers.length)} />
                </Tooltip>
              )}
          </HeaderWrapper>
          <RowContext.Provider
            value={{
              fieldName,
              disabled,
              rowsCount,
              headers,
              pushRow,
              removeRow,
              handleValidRows,
            }}
          >
            <FixedSizeList
              height={300}
              itemCount={rowsCount}
              itemKey={index => rowKeys[index]}
              itemSize={31}
              width={tableWidth}
            >
              {Row}
            </FixedSizeList>
          </RowContext.Provider>
        </TableContainer>
      ) : (
        <TablePlaceholder mb={2}>{placeholder}</TablePlaceholder>
      )}
    </Fragment>
  );
};

export { PropertiesTable, RowContext };
