import { FC, memo } from 'react';

import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { LetterTemplate } from 'common/types/notifications.types';
import { MessageSmallIcon } from 'resources/icons/12';
import { Container, Text } from 'UI';

const LetterWrapper = styled.div`
  word-wrap: break-word;
  word-break: break-all;
  ${space};
`;

interface ILetterProps extends SpaceProps {
  title: string;
  message: string;
  template: LetterTemplate;
}

const Letter: FC<ILetterProps> = memo(
  ({ title, message, template = LetterTemplate.full }) => {
    return (
      <LetterWrapper>
        {template === LetterTemplate.compact ? (
          <>
            <Container alignItems='center'>
              <MessageSmallIcon mr={1} />
              <Text as='span' color='text.secondary' fontSize={1}>
                {title}
              </Text>
            </Container>
            <Text fontSize={1}>{message}</Text>
          </>
        ) : (
          <>
            <Container alignItems='center'>
              <MessageSmallIcon mr={1} />
              <Text
                as='span'
                color='text.primary'
                fontSize={5}
                fontWeight={500}
              >
                {title}
              </Text>
            </Container>
            <Text mt={2}>{message}</Text>
          </>
        )}
      </LetterWrapper>
    );
  },
);

export { Letter };
