import { FC, Fragment, useMemo, useRef } from 'react';

import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { getFileURL } from 'common/utils';
import { mixinId } from 'components/FileUpload';
import { useNotify } from 'entities/notify';
import { IReform, Reform, useField, useReform } from 'reform';
import { InfoRoundIcon } from 'resources/other-28';
import {
  ChooseDragPhotosIcon,
  PanoramaErrorIcon,
} from 'resources/placeholders';
import { Img } from 'tools/libs';
import { insertArray, moveItem, removeItem, replaceItem } from 'tools/utils';
import { Button, Modal, Spinner, Text, Tooltip } from 'UI';

import { PanoramasModalPreview } from './Preview';
import {
  AddPhotoBetweenWrapper,
  DashedLine,
  OpenMenuIcon,
} from '../../../../styled';
import { useRotate } from '../../hooks';

const Container = styled.div(
  ({ theme: { space } }) => css`
    padding: ${space[4]}px;
    height: calc(100% - 78px);
  `,
);

const ContentWrapper = styled.div<{ isEmpty: boolean }>(
  ({ theme: { borderRadius, colors }, isEmpty }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;

    ${isEmpty &&
    css`
      border: 1px solid ${colors.divider};
      border-radius: ${borderRadius};
      background-color: ${colors.background};
    `}
  `,
);

const PlaceholderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const MainBoardWrapper = styled.div<{ isLoaded: boolean }>(
  ({ theme: { colors }, isLoaded }) => css`
    position: relative;
    align-self: stretch;
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 150px);
    border: 1px solid ${colors.divider};
    user-select: none;

    ${isLoaded &&
    css`
      cursor: ew-resize;
    `}
  `,
);

const StyledInfoRoundIcon = styled(InfoRoundIcon)(
  ({ theme: { space } }) =>
    css`
      position: absolute;
      top: ${space[2]}px;
      left: ${space[2]}px;
      cursor: pointer;
    `,
);

const ImageView = styled(Img)`
  max-height: 100%;
`;

const FooterWrapper = styled.div(
  ({ theme: { space } }) => css`
    align-self: stretch;
    flex-basis: 116px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: ${space[4]}px;
  `,
);

const ImageListWrapper = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    display: flex;
    flex-grow: 1;
    align-self: stretch;
    border: 1px solid ${colors.background};
    border-radius: ${borderRadius}px;
    overflow-x: auto;
    overflow-y: hidden;
  `,
);

const ImageList = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: ${space[2]}px;
    height: 100%;
  `,
);

const ButtonWrapper = styled.div(
  ({ theme: { space } }) => css`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-width: 222px;
    margin-left: ${space[3]}px;
    padding: 0 0 36px 0;
  `,
);

const AddPhotoTextWrapper = styled.div`
  align-self: stretch;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AddPhotoText = styled(Text)`
  align-self: flex-start;
`;

const LIMIT = 16;

interface IModalBodyProps {
  onSubmit: (value: any) => void;
  onCancel: VoidFunction;
}

const ModalBody: FC<IModalBodyProps> = ({ onSubmit, onCancel }) => {
  const notify = useNotify();
  const { getData } = useReform();
  const { value: images, setValue: setImages } = useField({
    name: 'images',
  });

  const hasImages = images.length > 0;
  const isLimitReached = images.length >= LIMIT;

  const imagesURLs = useMemo(
    () => images.map(img => getFileURL(img)),
    [images],
  );

  const {
    ref: mainBoardRef,
    isLoaded,
    selectedImageIndex,
    setSelectedImageIndex,
  } = useRotate(imagesURLs);

  const indexToInsertRef = useRef<Nullable<number>>(null);
  const { getRootProps, getInputProps, open } = useDropzone({
    noClick: true,
    multiple: true,
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp', 'image/webp'],
    maxSize: 52428800, // 50Mb
    onDrop: files => {
      if (files.length + images.length > LIMIT) {
        notify.error('Достигнут лимит изображений в панораме: 16 штук');
      }

      if (indexToInsertRef.current !== null) {
        setImages(prev => {
          const index = indexToInsertRef.current! + 1;
          indexToInsertRef.current = null;

          return insertArray(
            prev,
            files.slice(0, LIMIT - images.length),
            index,
          );
        });
      } else {
        setImages(prev => [
          ...prev,
          ...files.map(mixinId).slice(0, LIMIT - images.length),
        ]);
      }
    },
    onDropRejected: () =>
      notify.error('Файлы с неверным форматом или размером не были загружены'),
  });

  const isDisabledSubmitButton =
    !isLoaded || images.length === 0 || images.some(image => !image.uri);
  const formattedSelectedImageIndex =
    selectedImageIndex + 1 > images.length
      ? images.length - 1
      : selectedImageIndex;
  const selectedImageUrl = getFileURL(images[formattedSelectedImageIndex]);
  const containerProps = {
    isEmpty: !hasImages,
    ...getRootProps(),
    ...(!hasImages && { onClick: open }),
  };

  const handleLoad = (nextImage, index): void => {
    setImages(prev => replaceItem(prev, nextImage, index));
  };

  const handleRemove = ({ currentTarget: { dataset } }): void => {
    setImages(prev => removeItem(prev, Number(dataset.index)));
  };

  const handleMove = (fromId, toId): void => {
    const from = images.findIndex(({ id }) => id === fromId);
    const to = images.findIndex(({ id }) => id === toId);
    setImages(prev => moveItem(prev, from, to));
  };

  const handleAddPhotoBetween = ({ currentTarget: { dataset } }): void => {
    indexToInsertRef.current = Number(dataset.index);
    open();
  };

  const getMainBoardContent = (): JSX.Element => {
    if (!isLoaded) {
      return (
        <Modal.Placeholder icon={<Spinner delay={0} mb={2} />}>
          Панорама загружается
        </Modal.Placeholder>
      );
    }

    if (!selectedImageUrl) {
      return (
        <Modal.Placeholder icon={<PanoramaErrorIcon mb={4} />}>
          Ошибка при загрузке
        </Modal.Placeholder>
      );
    }

    return <ImageView alt='Selected' src={selectedImageUrl} />;
  };

  return (
    <Modal
      fullscreen
      disabled={isDisabledSubmitButton}
      hasSubmitOnEnter={false}
      onSubmit={() => onSubmit(getData())}
    >
      <Modal.Default
        buttonsPosition='top'
        cancelButton={{ title: 'Отменить', onClick: onCancel }}
        submitButton='Сохранить'
        title='Создание 3D панорамы'
      >
        <Container>
          <input {...getInputProps()} />
          <ContentWrapper {...containerProps}>
            {hasImages ? (
              <Fragment>
                <MainBoardWrapper ref={mainBoardRef} isLoaded={isLoaded}>
                  <Tooltip
                    arrow
                    title='Чтобы вращать панораму зажмите левую кнопку мыши и перемещайте курсор в сторону'
                  >
                    <StyledInfoRoundIcon />
                  </Tooltip>
                  {getMainBoardContent()}
                </MainBoardWrapper>
                <FooterWrapper>
                  <ImageListWrapper>
                    <ImageList>
                      {images.map((image, index) => (
                        <Fragment key={image.id}>
                          <PanoramasModalPreview
                            active={index === formattedSelectedImageIndex}
                            image={image}
                            index={index}
                            onLoad={handleLoad}
                            onMoveItem={handleMove}
                            onRemove={handleRemove}
                            onSelect={setSelectedImageIndex}
                          />
                          {index < images.length - 1 && !isLimitReached && (
                            <Tooltip arrow hint title='Добавить фото'>
                              <AddPhotoBetweenWrapper
                                data-index={index}
                                onClick={handleAddPhotoBetween}
                              >
                                <DashedLine />
                                <OpenMenuIcon />
                              </AddPhotoBetweenWrapper>
                            </Tooltip>
                          )}
                        </Fragment>
                      ))}
                    </ImageList>
                  </ImageListWrapper>
                  {!isLimitReached && (
                    <ButtonWrapper>
                      <Button
                        size='l'
                        variant='outlined'
                        width='100%'
                        onClick={open}
                      >
                        Добавить фото
                      </Button>
                      <AddPhotoTextWrapper>
                        <AddPhotoText color='text.secondary' fontSize={1}>
                          Форматы: JPG, PNG, WEBP, BMP.
                        </AddPhotoText>
                        <AddPhotoText color='text.secondary' fontSize={1}>
                          Размер файла: 1 KB – 50 MB.
                        </AddPhotoText>
                      </AddPhotoTextWrapper>
                    </ButtonWrapper>
                  )}
                </FooterWrapper>
              </Fragment>
            ) : (
              <PlaceholderWrapper>
                <ChooseDragPhotosIcon mb={3} />
                <Text fontSize={5} mb={3}>
                  Перетащите фото сюда или{' '}
                  <Button
                    fontSize={5}
                    variant='string'
                    onClick={event => {
                      event.stopPropagation();
                      open();
                    }}
                  >
                    выберите их
                  </Button>
                </Text>
                <Text>Загрузите фотографии в формате JPG, PNG, WEBP, BMP.</Text>
                <Text>Максимальный размер фото — 50MB.</Text>
              </PlaceholderWrapper>
            )}
          </ContentWrapper>
        </Container>
      </Modal.Default>
    </Modal>
  );
};

const INITIAL_VALUES = {
  name: 'Panorama01',
  images: [],
};

interface IPanoramasModalFormProps extends IModalBodyProps {
  initialValues: { name: string; images: IImage[] };
}

const PanoramasModalForm: FC<IPanoramasModalFormProps> = ({
  initialValues,
  onCancel,
  onSubmit,
}) => {
  const reformRef = useRef<IReform>(null);

  return (
    <Reform ref={reformRef} initialValues={initialValues || INITIAL_VALUES}>
      <ModalBody onCancel={onCancel} onSubmit={onSubmit} />
    </Reform>
  );
};

const ADD_PANORAMA = 'ADD_PANORAMA';

export { PanoramasModalForm, ADD_PANORAMA };
