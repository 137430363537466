import styled, { css } from 'styled-components';

export const SquareGrid = styled.div<{ columns: number }>(
  ({ columns }) => css`
    min-width: 224px;
    display: grid;
    grid-template-columns: repeat(${columns}, minmax(16px, 1fr));
    grid-auto-rows: minmax(32px, 1fr);
  `,
);

export const Tile = styled.button<{
  active?: boolean;
  secondary?: boolean;
  today?: boolean;
}>(
  ({
    theme: { borderRadius, colors, fontSizes },
    disabled,
    active,
    secondary,
    today,
  }) =>
    css`
      display: flex;
      justify-content: center;
      align-items: center;
      background: none;
      border: 1px solid transparent;
      border-radius: ${borderRadius}px;
      color: ${colors.text.secondary};
      font-size: ${fontSizes[2]}px;
      text-align: center;
      cursor: pointer;

      &:hover {
        border: 1px solid ${colors.divider};
      }

      ${today &&
      css`
        color: ${active ? colors.white : colors.text.primary};
        font-weight: 500;
      `}

      ${active &&
      css`
        color: ${colors.white};
        background-color: ${colors.text.primary};
      `}
      
      ${secondary &&
      css`
        color: ${colors.text.disabled};
      `}  

      ${disabled &&
      css`
        border-radius: 0;
        background-color: ${colors.background};
        color: ${colors.text.disabled};
        cursor: default;

        &:hover {
          border: none;
        }
      `}
    `,
);
