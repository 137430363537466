import { FC, FocusEvent, ReactNode, useMemo, useState } from 'react';

import styled, { css, useTheme } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { FieldWrapper } from 'components/fields';
import { useField } from 'reform';
import { CheckSmallIcon } from 'resources/icons/12';
import { EyeVisibleIcon, EyeInvisibleIcon } from 'resources/icons/18';
import { useToggle } from 'tools/hooks';
import { Dropdown, Heading, IconButton, TextInput } from 'UI';

const PasswordHintItem = styled.div<{ active?: boolean }>(
  ({ theme: { space, colors }, active }) => css`
    display: flex;
    align-items: start;
    margin-bottom: ${space[1]}px;
    overflow-wrap: break-word;
    color: ${colors.text.primary};

    ${active &&
    css`
      svg {
        color: ${colors.success.main};
      }
      color: ${colors.success.main};
    `}
  `,
);

interface ValidateMethodProps {
  message: string | ReactNode;
  value?: number;
}

enum ValidateMethods {
  isCapitalSymbol = 'isCapitalSymbol',
  isContainDigitOrSymbol = 'isContainDigitOrSymbol',
  min = 'min',
}

type Config = Record<string, ValidateMethodProps>;

const validatorConfig: Config = {
  [ValidateMethods.min]: {
    message: 'Содержит не менее 8 символов',
    value: 8,
  },
  [ValidateMethods.isCapitalSymbol]: {
    message: (
      <>
        Содержит как строчные (a–z), так и заглавные
        <br />
        (A–Z) буквы латинского алфавита
      </>
    ),
  },
  [ValidateMethods.isContainDigitOrSymbol]: {
    message:
      'Содержит хотя бы одну цифру (0–9) или символ из списка: .,-!?@#$%^&*()/\\»',
  },
};

function validate(
  value: string,
  validateMethod: string,
  config: ValidateMethodProps,
): boolean {
  switch (validateMethod) {
    case ValidateMethods.isCapitalSymbol: {
      const capitalRegExp = /[a-z].*[A-Z]|[A-Z].*[a-z]/;
      return capitalRegExp.test(value);
    }
    case ValidateMethods.isContainDigitOrSymbol: {
      const digitRegExp = /[\d.,\-!?@#$%^&*()/\\»]+/;
      return digitRegExp.test(value);
    }
    case ValidateMethods.min: {
      return config.value !== undefined && value.length >= config.value;
    }
    default:
      return false;
  }
}

function validator(value: string, config: Config): Record<string, string> {
  const requirements = {};

  Object.entries(config).forEach(([validateMethod, props]) => {
    requirements[validateMethod] = validate(value, validateMethod, props);
  });

  return requirements;
}

interface IPasswordInputProps {
  checkValid?: boolean;
  required?: boolean;
  name: string;
  placeholder: string;
  label: string;
}

const PasswordInput: FC<IPasswordInputProps & SpaceProps> = ({
  checkValid,
  name,
  placeholder,
  label,
  ...other
}) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const handleOpen = ({
    currentTarget,
  }: FocusEvent<HTMLInputElement>): void => {
    if (checkValid) {
      setAnchorEl(currentTarget as HTMLElement);
    }
  };

  const [isShow, handleShow] = useToggle(false);
  const { value, setValue, error } = useField({ name });

  const passwordRequirements = useMemo(() => {
    return validator(value, validatorConfig);
  }, [value]);

  const handleClose = (): void => setAnchorEl(null);

  const handleChange = ({ target }): void => {
    setValue(target.value);
  };

  return (
    <>
      <FieldWrapper
        injectedProps
        showErrorDescription
        error={error}
        label={label}
        mb='12px'
        {...other}
      >
        <TextInput
          placeholder={placeholder}
          position='relative'
          rightAddon={
            <IconButton
              mr='2px'
              size={28}
              style={{ position: 'absolute', right: 0 }}
              title={isShow ? 'Скрыть' : 'Показать'}
              onClick={handleShow}
            >
              {isShow ? <EyeInvisibleIcon /> : <EyeVisibleIcon />}
            </IconButton>
          }
          type={isShow ? 'name' : 'password'}
          value={value}
          onBlur={handleClose}
          onChange={handleChange}
          onFocus={handleOpen}
        />
      </FieldWrapper>
      <Dropdown
        anchorEl={anchorEl}
        offset={[-10, 40]}
        open={Boolean(anchorEl)}
        p={3}
        placement='right-start'
        width={392}
        zIndex={theme.zIndex.modal}
        onRequestClose={handleClose}
      >
        <Heading as='h4' mb={2}>
          Создайте пароль, который:
        </Heading>
        {Object.entries(validatorConfig).map(
          ([validateMethod, { message }], index) => (
            <PasswordHintItem
              key={index}
              active={!!passwordRequirements[validateMethod]}
            >
              <CheckSmallIcon mr={1} mt='1px' />
              {message}
            </PasswordHintItem>
          ),
        )}
      </Dropdown>
    </>
  );
};

export { PasswordInput };
