import { FC, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';

import { MediaFieldsTypes } from 'common/types/media.types';
import { ImageItem } from 'components/gallery/components';
import { ImagesWithTypes } from 'components/media/images/page/types';
import { ArrowLeft1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { Container, IconButtonWrapper } from 'UI';

import { useGallery } from '../hooks';

const ImageList = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    height: ${space[6]}px;
    overflow: hidden;
  `,
);

const Stage = styled(Container)`
  position: absolute;
  transition: transform 200ms ease-in;
`;

const ButtonStyle = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
`;

const PrevButton = styled(IconButtonWrapper)`
  left: -13px;
  ${ButtonStyle};
`;

const NextButton = styled(IconButtonWrapper)`
  right: -13px;
  ${ButtonStyle};
`;

interface IListImagesProps {
  selectedImageIndex: number;
  filteredImagesWithTypes: ImagesWithTypes;
  handleSelectedImage: (index: number) => void;
}

const ListImages: FC<IListImagesProps> = ({
  selectedImageIndex,
  filteredImagesWithTypes,
  handleSelectedImage,
}) => {
  const [showChevron, setShowChevron] = useState<boolean>(false);
  const {
    leftPosition,
    imageListRef,
    stageRef,
    handleScrollListRight,
    handleScrollListLeft,
  } = useGallery({
    activeIndex: selectedImageIndex,
    forceUpdatePosition: filteredImagesWithTypes,
  });

  useEffect(() => {
    const updateShowChevron = (): void => {
      const sliderWidth = imageListRef.current!.clientWidth;
      const stageWidth = stageRef.current!.clientWidth;
      setShowChevron(stageWidth > sliderWidth);
    };

    updateShowChevron();

    window.addEventListener('resize', updateShowChevron);

    return () => {
      window.removeEventListener('resize', updateShowChevron);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredImagesWithTypes]);

  return (
    <Container display='block' position='relative'>
      <ImageList ref={imageListRef}>
        <Stage
          ref={stageRef}
          style={{ transform: `translate3d(${leftPosition}px, 0, 0)` }}
        >
          {filteredImagesWithTypes.map(({ type, image }, index) => (
            <ImageItem
              key={image.id}
              active={selectedImageIndex === index}
              bg='preview'
              img={image}
              is3DModel={type === MediaFieldsTypes.model3D}
              mr={0}
              size={6}
              onSelectedImage={() => handleSelectedImage(index)}
            />
          ))}
        </Stage>
      </ImageList>
      {showChevron && (
        <>
          <PrevButton wrapper onClick={handleScrollListRight}>
            <ArrowLeft1pxIcon />
          </PrevButton>
          <NextButton wrapper onClick={handleScrollListLeft}>
            <ArrowRight1pxIcon />
          </NextButton>
        </>
      )}
    </Container>
  );
};

export { ListImages };
