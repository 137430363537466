import { ReactElement } from 'react';

import styled from 'styled-components';

import { Text } from 'UI';

const WrapperText = styled.div`
  max-width: 860px;
  white-space: break-spaces;
  word-break: break-word;
`;

function InstructionsAndInformation({ value }): ReactElement {
  const { text } = value;

  return text ? (
    <WrapperText>{text}</WrapperText>
  ) : (
    <Text color='text.secondary' fontSize={5}>
      Подраздел не заполнен
    </Text>
  );
}

export { InstructionsAndInformation };
