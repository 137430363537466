import {
  CSSProperties,
  MouseEvent,
  ReactElement,
  ReactNode,
  ElementType,
  forwardRef,
} from 'react';

import { Link, useLocation, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  color,
  border,
  SpaceProps,
  BorderProps,
  ColorProps,
  LayoutProps,
} from 'styled-system';

import { ButtonsWrapper, Item, ItemControl } from './styled';
import { Text } from '../Text';

const SideMenuMainItemComponent = styled.div<{ collapseVisible?: boolean }>(
  ({ theme: { colors, space, fontSizes }, collapseVisible }) => css`
    display: flex;
    align-items: center;
    height: ${space[5]}px;
    min-height: ${space[5]}px;
    padding: 0 ${space[2]}px;
    font-weight: 500;
    font-size: ${fontSizes[4]}px;
    color: ${colors.text.primary};
    white-space: nowrap;
    background: ${colors.highlight[1]};

    &:hover {
      color: ${colors.text.primary};
    }

    ${collapseVisible &&
    css`
      visibility: visible;
      padding: 0;
      justify-content: center;
    `}

    ${styledSpace};
    ${color};
    ${border};
  `,
);

const RightAction = styled.div<{ collapseVisible?: boolean }>(
  ({ collapseVisible }) => css`
    display: flex;
    margin-left: auto;

    ${collapseVisible &&
    css`
      margin-left: 0;
    `}
  `,
);

interface ISideMenu {
  className?: string;
  style?: CSSProperties;
  as?: ElementType;
}

interface ISideMenuMainItem extends ISideMenu {
  collapseVisible?: boolean;
  children: ReactNode;
  rightAction?: ReactNode;
}

function SideMenuMainItem({
  style,
  className,
  as = Link,
  collapseVisible,
  children,
  rightAction,
  ...other
}: ISideMenuMainItem & SpaceProps & BorderProps & ColorProps): ReactElement {
  return (
    <SideMenuMainItemComponent
      as={as}
      className={className}
      collapseVisible={collapseVisible}
      style={style}
      {...other}
    >
      {!collapseVisible && children}
      {rightAction && (
        <RightAction collapseVisible={collapseVisible}>
          {rightAction}
        </RightAction>
      )}
    </SideMenuMainItemComponent>
  );
}

interface ISideMenuItem extends ISideMenu {
  active?: boolean;
  disabled?: boolean;
  focused?: boolean;
  highlighted?: boolean;
  primary?: boolean;
  secondary?: boolean;
  text: ReactNode;
  rightAction?: ReactNode;
  to?: string;
  id?: string;
  exact?: boolean;
  level?: number;
  fontSize?: number;
  fontWeight?: number;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
}

const SideMenuItem = forwardRef<
  HTMLDivElement,
  ISideMenuItem & SpaceProps & LayoutProps
>(
  (
    {
      id,
      style,
      className,
      active,
      focused,
      highlighted,
      primary,
      secondary,
      disabled,
      text,
      rightAction,
      to,
      as = Link,
      exact,
      level,
      fontSize,
      fontWeight,
      onClick,
      ...other
    },
    ref,
  ): ReactElement => {
    const queryString = useLocation().search;
    const match = useRouteMatch({ path: to, exact });
    const routeMatch = !queryString ? match : null;
    const isActive = typeof active === 'undefined' ? !!routeMatch : active;
    const isControl = as !== 'div';
    const Component = (isControl ? ItemControl : Item) as typeof Item;

    return (
      <Component
        active={isActive}
        aria-selected={isActive}
        className={className}
        disabled={disabled}
        focused={focused}
        highlighted={highlighted}
        id={id}
        level={level}
        primary={primary}
        rightAction={!!rightAction}
        secondary={secondary}
        style={style}
        {...other}
      >
        <Text
          replace
          truncate
          aria-label='title'
          as={as}
          disabled={disabled}
          fontSize={fontSize}
          fontWeight={fontWeight}
          to={to}
          onClick={onClick}
        >
          {text}
        </Text>
        {rightAction && (
          <ButtonsWrapper ref={ref}>{rightAction}</ButtonsWrapper>
        )}
      </Component>
    );
  },
);

const ActionSideMenuItem = styled(SideMenuItem)(
  ({ theme: { colors } }) => css`
    color: ${colors.primary.main};

    &:hover {
      color: ${colors.primary.main};
    }
  `,
);

export { SideMenuItem, ActionSideMenuItem, SideMenuMainItem };
