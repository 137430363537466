import { FC, Fragment, useState } from 'react';

import styled, { css } from 'styled-components';

import { IProduct, ProductLifeState } from 'common/types/product.types';
import { formatDate } from 'common/utils';
import { MessageMediumIcon, CloseMediumIcon } from 'resources/icons/18';
import { Dropdown, Heading, Text } from 'UI';

type ButtonNoticeProps = {
  isOpen?: boolean;
  error?: boolean;
};

const ButtonNotice = styled.button<ButtonNoticeProps>(
  ({ theme: { colors, space }, isOpen, error }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${space[4]}px;
    height: ${space[4]}px;
    background: ${colors.highlight[error ? 3 : 1]};
    border-radius: 2px;

    ${isOpen &&
    css`
      background: ${colors.white};
    `}

    ${!isOpen &&
    css`
      &::after {
        position: absolute;
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        top: 7px;
        left: 19px;
        border-radius: 50%;
        background: ${colors.error.main};
      }

      &::before {
        position: absolute;
        content: '';
        display: block;
        width: 9px;
        height: 9px;
        top: 5px;
        left: 17px;
        border: 2px solid ${colors.highlight[error ? 3 : 4]};
        border-radius: 50%;
      }
    `}
  `,
);

const DropdownMessage = styled(Dropdown)(
  ({ theme: { shadows, space } }) => css`
    width: 488px;
    box-shadow: ${shadows[2]};
    z-index: 10;
    max-height: 516px;
    overflow: scroll;
    padding: ${space[4]}px;
  `,
);

const ProductModerationMessage: FC<{ product: IProduct }> = ({ product }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpen = ({ currentTarget }): void => setAnchorEl(currentTarget);
  const handleClose = (): void => setAnchorEl(null);

  const { moderation, lifeState, blockedAt } = product;

  if (
    (lifeState !== ProductLifeState.moderationCompleted &&
      lifeState !== ProductLifeState.rejected &&
      lifeState !== ProductLifeState.blocked) ||
    !moderation?.comment
  ) {
    return null;
  }

  return (
    <Fragment>
      <ButtonNotice
        error={lifeState === ProductLifeState.rejected}
        isOpen={Boolean(anchorEl)}
        onClick={anchorEl ? handleClose : handleOpen}
      >
        {anchorEl ? <CloseMediumIcon /> : <MessageMediumIcon />}
      </ButtonNotice>
      <DropdownMessage
        anchorEl={anchorEl}
        offset={[0, 0]}
        open={Boolean(anchorEl)}
        onRequestClose={handleClose}
      >
        <Heading as='h4' mb={0}>
          Сообщение модератора
        </Heading>
        <Text as='p' color='text.secondary' fontSize={2} mb={3}>
          {formatDate(
            lifeState === ProductLifeState.blocked
              ? blockedAt
              : moderation?.completedAt,
            false,
            'dd MMMM yyyy, HH:mm',
          )}
        </Text>
        <Text
          as='p'
          color='text.primary'
          style={{ wordBreak: 'break-word', whiteSpace: 'pre-line' }}
        >
          {moderation?.comment}
        </Text>
      </DropdownMessage>
    </Fragment>
  );
};

export { ProductModerationMessage };
