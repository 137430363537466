import styled, { css } from 'styled-components';
import {
  flexGrow,
  FlexGrowProps,
  minWidth,
  MinWidthProps,
  space,
  SpaceProps,
  WidthProps,
} from 'styled-system';

/**
 * Подсвечиваем границы верхней и нижней строки
 */
const highlightedTopAndBottomBordersRowsError = css(
  ({ theme: { colors } }) => css`
    /**
     * Обращаемся к первой строке (Row) и подсвечиваем верхний бордер каждого элемента внутри строки
     */
    &:first-child > div:not(:focus-within) {
      border-top-color: ${colors.error.main};
    }

    /**
     * Обращаемся к последней строке (Row) и подсвечиваем нижний бордер каждого элемента внутри строки
     */
    &:last-child > div:not(:focus-within) {
      border-bottom-color: ${colors.error.main};
    }
  `,
);

/**
 * Подсвечиваем левую границу первого элемента, если этот элемент не в фокусе
 */
const highlightedLeftBordersError = css(
  ({ theme: { colors } }) => css`
    & > div:first-child:not(:focus-within):not([data-error='true']) {
      border-left-color: ${colors.error.main};
    }
  `,
);

/**
 * Подсвечиваем правую границу последнего элемента, если этот элемент не в фокусе
 */
const highlightedRightBordersError = css(
  ({ theme: { colors } }) => css`
    & > div:last-child:not(:focus-within):not([data-error='true']) {
      border-right-color: ${colors.error.main};
    }
  `,
);

const Row = styled.div<FlexGrowProps & { error?: boolean }>(
  ({ theme: { colors }, error }) => css`
    display: flex;
    flex-grow: 1;
    min-width: 0;

    /**
     * Объединяем бордеры между инпутами внутри одной строки
     */
    & > div {
      border-radius: 0;

      &:not(:focus-within):not([data-error='true']) {
        border-right-color: transparent;
        border-bottom-color: transparent;
      }

      &:last-child:not(:focus-within):not([data-error='true']) {
        border-right-color: ${colors.divider};
      }
    }

    &:last-child > div:not(:focus-within):not([data-error='true']) {
      border-bottom-color: ${colors.divider};
    }

    /**
     * Формируем подсветку границ у строки
     **/
    ${error &&
    css`
      & > div:not(:focus-within):not([data-error='true']) {
        border-bottom-color: ${colors.error.main};
        border-top-color: ${colors.error.main};
      }

      ${highlightedLeftBordersError};
      ${highlightedRightBordersError}
    `}

    ${flexGrow};
  `,
);

const Column = styled.div<{ error?: boolean } & FlexGrowProps & MinWidthProps>(
  ({ theme: { colors, borderRadius }, error }) => css`
    display: flex;
    flex-direction: column;
    min-width: 0;

    /**
     * Обращаемся к каждой строке, а в строке к последнему элементу, который находится не в фокусе
     * или у которого отсутствует атрибут data-error и делаем правый бордер прозрачным
     **/
    & > ${Row} > div:last-child:not(:focus-within):not([data-error='true']) {
      border-right-color: transparent;
    }

    /**
     * Обращаемся к первой колонке
     **/
    &:first-child {
      /**
       * Обращаемся к первой строке и первому элементу этой строки
       **/
      & > ${Row}:first-child > div:first-child {
        border-top-left-radius: ${borderRadius}px;
      }

      /**
       * Обращаемся к последней строке и первому элементу этой строки
       **/
      & > ${Row}:last-child > div:first-child {
        border-bottom-left-radius: ${borderRadius}px;
      }
    }

    /**
     * Обращаемся к последней колонке
     **/
    &:last-child {
      /**
       * Обращаемся к первой строке и последнему элементу этой строки
       **/
      & > ${Row}:first-child > div:last-child {
        border-top-right-radius: ${borderRadius}px;
      }

      /**
       * Обращаемся к последней строке и последнему элементу этой строки
       **/
      & > ${Row}:last-child > div:last-child {
        border-bottom-right-radius: ${borderRadius}px;
      }

      /**
       * Обращаемся к каждой строке, а в строке к последнему элементу, который находится не в фокусе
       * или у которого отсутствует атрибут data-error и окрашиваем правый бордер
       **/
      & > ${Row} > div:last-child:not(:focus-within):not([data-error='true']) {
        border-right-color: ${colors.divider};
      }
    }

    /**
     * Формируем подсветку границ у колонки
     **/
    ${error &&
    css`
      & > ${Row} {
        ${highlightedTopAndBottomBordersRowsError};
        ${highlightedLeftBordersError};
        ${highlightedRightBordersError}
      }
    `}

    ${flexGrow};
    ${minWidth};
  `,
);

const Wrapper = styled.div<{ error?: boolean; column?: boolean } & SpaceProps>(
  ({ theme: { borderRadius }, error, column }) => css`
    display: flex;
    min-width: 0;
    flex-grow: 1;

    ${column &&
    css`
      flex-direction: column;
    `}

    /**
     * Обращаемся ко всем строкам и формируем радиусы границ
     */
    & > ${Row} {
      /**
       * Первая строка (Row)
       */
      &:first-child {
        & > div {
          /**
         * Первый инпут первой строки (верхний левый угол)
         * Под инпутами подразумевается TextInput, Select, Combobox, MultiSelect и др, все компоненты имеющие бордеры и выделения
         */
          &:first-child {
            border-top-left-radius: ${borderRadius}px;
          }

          /**
         * Последний инпут первой строки (верхний правый угол)
         * Под инпутами подразумевается TextInput, Select, Combobox, MultiSelect и др, все компоненты имеющие бордеры и выделения
         */
          &:last-child {
            border-top-right-radius: ${borderRadius}px;
          }
        }
      }

      /**
       * Последняя строка (Row)
       */
      &:last-child {
        & > div {
          /**
           * Первый инпут последней строки (нижний левый угол)
           * Под инпутами подразумевается TextInput, Select, Combobox, MultiSelect и др, все компоненты имеющие бордеры и выделения
           */
          &:first-child {
            border-bottom-left-radius: ${borderRadius}px;
          }

          /**
           * Последний инпут последней строки (нижний правый угол)
           * Под инпутами подразумевается TextInput, Select, Combobox, MultiSelect и др, все компоненты имеющие бордеры и выделения
           */
          &:last-child {
            border-bottom-right-radius: ${borderRadius}px;
          }
        }
      }
    }

    /**
     * Формируем красную обводку
     */
    ${error &&
    css`
      & > ${Row}, & > ${Column} > ${Row} {
        ${highlightedTopAndBottomBordersRowsError};
      }

      /**
       * Обращаемся к каждой строке (Row) или к первому столбцу (Column), а в нем к каждой строке и:
       * подсвечиваем левый бордер каждого элемента внутри строки
       */
      & > ${Row}, & > ${Column}:first-child > ${Row} {
        ${highlightedLeftBordersError};
      }

      /**
       * Обращаемся к каждой строке (Row) или к последнему столбцу (Column), а в нем к каждой строке и:
       * подсвечиваем правый бордер каждого элемента внутри строки
       */
      & > ${Row}, & > ${Column}:last-child > ${Row} {
        ${highlightedRightBordersError};
      }
    `}

    ${space};
  `,
);

/**
 * Компоновщик инпутов (TextInput, Select, MultiSelect, Combobox и т.д.).
 * Данная обертка объединяет бордеры переданных внутрь нее компонентов и расставляет borderRadius только у угловых компонентов.
 *
 * !Важные пояснения:
 *   - инпуты, которые предполоагается объединить, необходимо вкладывать в <UnionContainer.Row>{inputs}</UnionContainer.Row>
 *   - строки (Row) должны быть обернуты в <UnionContainer>{row}</UnionContainer>, если строк несколько, то должен быть передан проп `column` в <UnionContainer...
 *   - если предполагаются колонки (Column), то строки (Row) должны быть вложены в колонки (Column), а колонки в <UnionContainer><UnionContainer.Column><UnionContainer.Row>{row}...
 *   - про сами инпуты: работает это только, например, с TextInput, но не будет работать с TextInputField, т.к. эти инпуты должны иметь контейнер, в котором реализуется стилистика бордеров.
 *   - инпуты должны выставлять дата-атрибут `data-error` со строковыми значениями "true" или "false", если у инпута присутсвует ошибка для подсветки бордера.
 *   Это необходимо, чтобы стили конкретного инпута были в приоритете, чем те, которые навешивает UnionContainer. У TextInput, Select, MultiSelect, Combobox и т.д. в качестве родительского
 *   контейнера используют InputContainer, которые проставляет `data-error` и стили с бордерами.
 *
 * @param error  - подсвечивает бордер красным цветом
 * @param column  - добавляет свойство flex-direction: column;
 *
 * @example
 *   // 1 строка с несколькими инпутами с подсветкой бордеров
 *  <UnionContainer mt={3} error>
 *    <UnionContainer.Row flexGrow={1}>
 *    <TextInput />
 *    <Select
 *      canClear
 *      options={options}
 *      value={value}
 *      onChange={setValue}
 *    />
 *    <TextInput />
 *   </UnionContainer.Row>
 *  </UnionContainer>
 *
 *  // 2 строки инпутов
 *  <UnionContainer column error mt={2}>
 *    <UnionContainer.Row>
 *      <Select
 *        canClear
 *        options={options}
 *        style={{ height: 'auto' }}
 *        value={value}
 *        onChange={setValue}
 *      />
 *    </UnionContainer.Row>
 *    <UnionContainer.Row>
 *      <TextInput />
 *      <Select
 *        canClear
 *        options={options}
 *        value={value}
 *        onChange={setValue}
 *      />
 *      <TextInput error />
 *    </UnionContainer.Row>
 *  </UnionContainer>
 *
 *  // в случае, когда верстка не стандартная и необходимо реализовать несколько колонок с разным кол-вом строк
 *  <UnionContainer mt={2}>
 *    <UnionContainer.Column>
 *      <UnionContainer.Row flexGrow={1}>
 *        <Select
 *          canClear
 *          options={options}
 *          style={{ height: 'auto' }}
 *          value={value}
 *          onChange={setValue}
 *        />
 *      </UnionContainer.Row>
 *    </UnionContainer.Column>
 *    <UnionContainer.Column error>
 *      <UnionContainer.Row flexGrow={1}>
 *        <Select
 *          canClear
 *          error
 *          options={options}
 *          style={{ height: 'auto' }}
 *          value={value}
 *          onChange={setValue}
 *        />
 *      </UnionContainer.Row>
 *      <UnionContainer.Row error>
 *          <TextInput />
 *          <TextInput />
 *          <TextInput />
 *      </UnionContainer.Row>
 *      <UnionContainer.Row>
 *          <TextInput />
 *      </UnionContainer.Row>
 *    </UnionContainer.Column>
 *  </UnionContainer>
 */
const UnionContainer = Object.assign(Wrapper, { Row, Column });

export { UnionContainer };
