import { Children, cloneElement, CSSProperties, FC } from 'react';

import styled, { css } from 'styled-components';
import { color, fontWeight } from 'styled-system';

import { pickOmit } from 'common/utils';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { useToggle } from 'tools/hooks';

import { VerticalLineDivider } from '../Divider';
import { IconButtonWrapper } from '../IconButton';

const CHEVRON_PROPS = {
  color: 'text.secondary',
  size: 12,
};

const GroupContainer = styled.div(
  ({ theme: { space } }) => css`
    background: inherit;
    margin-bottom: ${space[2]}px;
    margin-top: ${space[1]}px;

    & > dl {
      background: inherit;
    }

    &:last-child {
      margin-bottom: 0;
    }
  `,
);

const GroupTitle = styled.div<{ isDisabled: boolean; level: number }>(
  ({ theme: { space, colors }, isDisabled, level }) => css`
    display: flex;
    font-weight: 600;
    min-width: 0;
    flex-grow: 1;
    margin-bottom: ${space[1]}px;
    margin-left: ${space[2] * level}px;
    word-break: break-word;

    ${isDisabled &&
    css`
      color: ${colors.text.disabled};

      & svg {
        color: ${colors.text.disabled};
      }
    `}

    ${fontWeight};
    ${color};
  `,
);

const GroupTitleContainer = styled.div<{ level?: number }>(
  ({ theme: { space }, level }) => css`
    display: flex;
    margin-left: ${level
      ? space[2] * level // имитируем поведение GroupTitle
      : 0}px;
  `,
);

const CONTAINER_PROPS = ['fontWeight', 'color'];

interface IGroupProps {
  isCollapsible?: boolean;
  style?: CSSProperties;
  disabled?: boolean;
  level?: number;
  flat?: boolean;
  title: JSX.Element | string;
  leftAddon?: JSX.Element;
  children: JSX.Element | JSX.Element[];
}

const Group: FC<IGroupProps> = ({
  isCollapsible,
  style,
  disabled,
  level = 0,
  flat,
  title,
  leftAddon,
  children,
  ...other
}) => {
  const [isCollapsed, setIsCollapsed] = useToggle(false);
  const [titleProps, childrenProps] = pickOmit(other, CONTAINER_PROPS);

  let Chevron;
  if (isCollapsible) {
    Chevron = isCollapsed ? ArrowDownIcon : ArrowUpIcon;
  }

  const content = Children.map(children, child =>
    typeof child === 'object' && !!child
      ? cloneElement(child, {
          level: flat ? level : level + 1,
          ...childrenProps,
          ...child.props,
        })
      : child,
  );

  return (
    <GroupContainer style={style}>
      <GroupTitleContainer level={isCollapsible ? level : undefined}>
        {isCollapsible && (
          <IconButtonWrapper
            height={18}
            p='0px'
            size={34}
            onClick={setIsCollapsed}
          >
            <Chevron {...CHEVRON_PROPS} />
          </IconButtonWrapper>
        )}
        {leftAddon}
        <GroupTitle
          isDisabled={disabled}
          level={isCollapsible ? 0 : level}
          {...titleProps}
        >
          {title}
        </GroupTitle>
      </GroupTitleContainer>
      {!isCollapsed && (
        <dl role='presentation'>
          {isCollapsible ? (
            <VerticalLineDivider
              // level отвечает за left, так что передаем значение, кратное 16пх
              level={2 * (level + 1)}
              // для хар-ки с level = 0 базово pl = 34px, далее +16px с каждым level++
              pl={`${34 + 16 * level}px`}
            >
              {content}
            </VerticalLineDivider>
          ) : (
            content
          )}
        </dl>
      )}
    </GroupContainer>
  );
};

export { Group };
