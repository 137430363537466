import { FC, MouseEventHandler, ReactNode, useState } from 'react';

import styled, { css } from 'styled-components';

import { IDocumentFile, IImage } from 'common/types/media.types';
import { DescriptionAddIcon, DescriptionIcon } from 'resources/icons/18';
import { BaseInput, Button, Container, Dropdown, IconButton, Text } from 'UI';

const LIMIT_TEXT = 150;

const TextArea = styled.textarea(
  ({ theme: { space, colors } }) => css`
    display: block;
    width: 100%;
    height: 105px;
    margin: 0;
    border: none;
    padding: ${space[2]}px;
    color: ${colors.text.primary};
    resize: none;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

const TopAddonContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    padding: ${space[1]}px ${space[2]}px;
    font-weight: 500;
    color: ${colors.text.secondary};
    border-bottom: 1px solid ${colors.divider};
  `,
);

const Input = styled(BaseInput)(
  ({ theme: { space, colors, fontSizes } }) => css`
    width: 100%;
    padding: ${space[2]}px;
    font-size: ${fontSizes[3]}px;
    border-bottom: 1px solid ${colors.divider};

    &::placeholder {
      font-size: ${fontSizes[3]}px;
    }
  `,
);

interface IDescriptionPopupIconProps {
  hasTitle?: boolean;
  title?: { add?: string; edit?: string };
  hasDescription: boolean;
  focused: boolean;
  onOpenPopup: MouseEventHandler<HTMLButtonElement>;
}

const DescriptionPopupButton: FC<IDescriptionPopupIconProps> = ({
  hasTitle,
  focused,
  title: { add, edit } = {
    add: 'Добавить описание',
    edit: 'Редактировать описание',
  },
  hasDescription,
  onOpenPopup,
}) => {
  const title = hasDescription ? edit : add;

  return (
    <IconButton
      focused={focused}
      title={hasTitle && title}
      variant={hasDescription ? 'outline' : 'secondary'}
      onClick={onOpenPopup}
    >
      {hasDescription ? <DescriptionIcon /> : <DescriptionAddIcon />}
    </IconButton>
  );
};

interface IDescriptionPopupViewProps {
  open: boolean;
  anchorEl: HTMLElement;
  title: string;
  description?: string;
  onRequestClose: VoidFunction;
}

const DescriptionPopupView: FC<IDescriptionPopupViewProps> = ({
  open,
  anchorEl,
  title,
  description,
  onRequestClose,
}) => {
  return (
    <Dropdown
      anchorEl={anchorEl}
      open={open}
      p={2}
      placement='left-start'
      width={240}
      onRequestClose={onRequestClose}
    >
      {title}
      <br />
      {description}
    </Dropdown>
  );
};

interface IDescriptionPopupProps {
  open: boolean;
  hasTitle?: boolean;
  anchorEl: HTMLElement;
  topAddon?: ReactNode;
  placeholders: { title?: string; description: string };
  value: IDocumentFile | IImage;
  onSubmit: (props: { title: string; description: string }) => void;
  onRequestClose: VoidFunction;
}

const DescriptionPopup: FC<IDescriptionPopupProps> = ({
  open,
  hasTitle,
  anchorEl,
  topAddon,
  placeholders,
  value,
  onSubmit,
  onRequestClose,
}) => {
  const [{ title, description }, setData] = useState<{
    title: string;
    description: string;
  }>({
    title: value.filename ?? '',
    description: value.description ?? '',
  });

  const handleChange = ({ target }): void => {
    const { name, value: newValue } = target;

    setData(prev => ({ ...prev, [name]: newValue }));
  };

  const handleRequestClose = (): void => {
    onSubmit({ title: title.trim(), description: description.trim() });
    onRequestClose();
  };

  return (
    <Dropdown
      anchorEl={anchorEl}
      open={open}
      placement='bottom-end'
      width={380}
      onRequestClose={handleRequestClose}
    >
      {topAddon && <TopAddonContainer>{topAddon}</TopAddonContainer>}
      {hasTitle && (
        <Input
          name='title'
          placeholder={placeholders.title}
          type='text'
          value={title}
          onChange={handleChange}
        />
      )}
      <TextArea
        maxLength={LIMIT_TEXT}
        name='description'
        placeholder={placeholders.description}
        value={description}
        onChange={handleChange}
      />
      <Container
        bg='background'
        height={46}
        justifyContent='space-between'
        p={2}
      >
        <Text color='text.disabled' fontSize={1}>
          {description.length}/{LIMIT_TEXT}
        </Text>
        <Button fontSize={2} variant='string' onClick={handleRequestClose}>
          Сохранить
        </Button>
      </Container>
    </Dropdown>
  );
};

export { DescriptionPopup, DescriptionPopupView, DescriptionPopupButton };
