import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

const UnorderedListWrapper = styled.ul<SpaceProps>(
  ({ theme: { space } }) => css`
    margin-top: 0;
    margin-bottom: ${space[3]}px;
    padding-left: ${space[2]}px;

    ${styledSpace}
  `,
);

const UnorderedListItem = styled.li(
  ({ theme: { space } }) => css`
    margin-bottom: ${space[0]}px;
  `,
);

const UnorderedList = Object.assign(UnorderedListWrapper, {
  Li: UnorderedListItem,
});

export { UnorderedList };
