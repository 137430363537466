import styled, { css } from 'styled-components';

import { HeaderButton } from './components';

const TableHeader = styled.div<{ isTableInner?: boolean }>(
  ({ theme: { space, colors, borderRadius }, isTableInner }) => css`
    position: sticky;
    top: -1px;
    min-height: 42px;
    display: flex;
    align-items: center;
    padding: 0 ${space[1]}px;
    background: ${colors.white};
    z-index: 1;

    ${!isTableInner &&
    css`
      border-radius: ${borderRadius}px ${borderRadius}px 0 0;

      & ~ div {
        border-radius: 0 0 ${borderRadius}px ${borderRadius}px;
      }
    `}

    ${isTableInner && 'border-width: 0 0 1px 0;'}
  `,
);

const TableHeaderExtension = Object.assign(TableHeader, {
  Button: HeaderButton,
});

export { TableHeaderExtension as TableHeader };
