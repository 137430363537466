import {
  CSSProperties,
  ElementType,
  forwardRef,
  MouseEventHandler,
  ReactNode,
} from 'react';

import { Placement } from '@popperjs/core';
import styled, { css } from 'styled-components';
import {
  HeightProps,
  space as styledSpace,
  SpaceProps,
  WidthProps,
} from 'styled-system';

import { height, width } from 'common/constants/customStyledSystems.const';

import { Tooltip } from '../Tooltip';

// TODO убрать после типизации Tooltip
type TooltipProps = {
  title: string;
  hint?: boolean;
  arrow?: boolean;
  placement?: Placement;
};

type StyledProps = SpaceProps &
  WidthProps &
  HeightProps & {
    isActive?: boolean;
    isDisabled?: boolean;
    primary?: boolean;
    size?: number;
  };

const MenuButtonStyled = styled.button<StyledProps>(
  ({ theme: { space, colors }, primary, isActive, size, isDisabled }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    width: ${size || space[4]}px;
    min-width: ${size || space[4]}px;
    height: ${size || space[4]}px;
    min-height: ${size || space[4]}px;
    color: ${primary ? colors.primary.main : colors.text.secondary};
    transition: 0.2s background-color ease-in-out;
    cursor: pointer;

    & svg {
      color: ${primary ? colors.primary.main : colors.text.secondary};
    }

    &:hover {
      color: ${primary ? colors.primary.main : colors.text.primary};
      background: ${colors.highlight[primary ? 1 : 2]};

      & svg {
        color: ${primary ? colors.primary.main : colors.text.secondary};
      }
    }

    ${isDisabled &&
    css`
      color: ${colors.text.disabled};
      cursor: default;
      pointer-events: none;

      &:hover {
        background: none;
      }

      & svg,
      &:hover svg {
        color: ${colors.text.disabled};
      }
    `}

    ${isActive &&
    css`
      color: ${colors.primary.main};

      & svg {
        color: ${colors.primary.main};
      }

      &:hover {
        color: ${colors.primary.dark};
        background: ${colors.highlight[1]};

        & svg {
          color: ${colors.primary.dark};
        }
      }
    `}

    ${styledSpace}
    ${height}
    ${width}
  `,
);

interface IMenuButtonProps extends StyledProps {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  to?: string;
  as?: ElementType;
  title?: string | TooltipProps;
  onClick?: MouseEventHandler<HTMLButtonElement>;
}

const MenuButton = forwardRef<HTMLButtonElement, IMenuButtonProps>(
  (
    {
      style,
      className,
      children,
      title,
      onClick,
      ...other // only for styled-system, data attrs, aria props
    },
    ref,
  ) => {
    const tooltipProps =
      typeof title === 'string'
        ? { title, hint: true }
        : { hint: true, ...title };

    return (
      <Tooltip {...tooltipProps}>
        <MenuButtonStyled
          ref={ref}
          className={className}
          style={style}
          onClick={onClick}
          {...other}
        >
          {children}
        </MenuButtonStyled>
      </Tooltip>
    );
  },
);

export { MenuButton };
