import {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import styled, { css, useTheme } from 'styled-components';

import { fetchAssortmentMaterial } from 'common/api/standard-assortments';
import { findMaterials } from 'common/api/standartization';
import { EMPTY_OBJECT } from 'common/constants/emptyDataStructures.const';
import { useRequest } from 'common/hooks';
import { IAssortmentMaterial } from 'common/types/assortments.types';
import { Nullable } from 'common/types/common.types';
import { IOption } from 'common/types/form.types';
import { IStandardMaterial } from 'common/types/standards.types';
import { itemToMarkMaterial } from 'common/utils/materials';
import {
  RequestReturnType,
  RequestSimpleCollection,
} from 'common/utils/request/types';
import { AssortmentSelect } from 'components/AssortmentSelect';
import { Field, useArrayField } from 'reform';
import { RecommendationSmallIcon } from 'resources/icons/12';
import { AddMediumIcon } from 'resources/icons/18';
import { useToggle } from 'tools/hooks';
import {
  Button,
  Container,
  Divider,
  LevelContainer,
  SelectField,
  Spinner,
  ToggleButton,
  Tooltip,
} from 'UI';
import { CollapseHeader } from 'UI/Blocks/Paper';
import { colors as themeColors } from 'UI/theme';

import { PropertyField } from './PropertyField';
import { IAssortmentPropertiesSchema } from '../../../../../types';
import { INITIAL_FIELD_PROPS } from '../../../constants';

const DropdownItem = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    padding: ${space[1]}px ${space[2]}px;
    background: ${colors.background};
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
  `,
);

const ASSORTMENT_MATERIAL_FIELD =
  'reference.assortmentParameters.assortmentMaterial';

const DIVIDER_STYLE = { borderColor: themeColors.white };

const itemToString = (item: IStandardMaterial): string => {
  return itemToMarkMaterial(item, true);
};

const fetchMaterialOptions = (
  data: IOption,
  assortmentMaterialId?: string,
): Promise<RequestReturnType<RequestSimpleCollection<IStandardMaterial>>> => {
  return findMaterials({
    search: data.name,
    page: data.page,
    assortmentMaterial: assortmentMaterialId,
  }) as Promise<RequestReturnType<RequestSimpleCollection<IStandardMaterial>>>;
};

interface IAssortmentMaterialFieldsProps {
  isDisabled: boolean;
  assortmentMaterial: Omit<IAssortmentMaterial, 'propertiesSchema'> & {
    propertiesSchema: IAssortmentPropertiesSchema[];
  };
}

const AssortmentMaterialFields: FC<IAssortmentMaterialFieldsProps> = memo(
  ({ isDisabled, assortmentMaterial }) => {
    const theme = useTheme();

    const [propertiesSchemaById, setPropertiesSchemaById] =
      useState<Record<string, IAssortmentPropertiesSchema>>();

    const { id: assortmentMaterialId, propertiesSchema } =
      assortmentMaterial ?? EMPTY_OBJECT;

    const currentMaterialIdRef = useRef<Nullable<string>>(
      assortmentMaterialId ?? null,
    );

    const {
      isLoading: isLoadingMarkRecommendation,
      data: hasMarkRecommendation,
    }: { isLoading: boolean; data: boolean } = useRequest(
      assortmentMaterialId && findMaterials,
      [{ assortmentMaterial: assortmentMaterialId }],
      {
        responseToData: (data: { totalItems: number }) => data.totalItems > 0,
      },
    );

    const { isLoading: isLoadingAssortmentMaterial } = useRequest(
      assortmentMaterialId && fetchAssortmentMaterial,
      [assortmentMaterialId],
      {
        onSuccess: (data: IAssortmentMaterial) => {
          const schemaById = data.propertiesSchema?.reduce(
            (acc, item) => ({ ...acc, [item.id]: item }),
            {},
          );
          setPropertiesSchemaById(schemaById);
        },
      },
    );

    const {
      fields: materialProperties,
      push,
      remove,
      setFields,
    } = useArrayField({
      name: `${ASSORTMENT_MATERIAL_FIELD}.properties`,
    });

    const [isRecommendationMode, toggleRecommendationMode] = useToggle(true);
    const [isOpenMaterial, toggleOpenMaterial] = useToggle(true);

    useEffect(() => {
      // Для полного сброса поля характеристик
      if (currentMaterialIdRef.current !== assortmentMaterialId) {
        setFields([]);
        currentMaterialIdRef.current = assortmentMaterialId ?? null;
      }

      if (propertiesSchema && materialProperties.length === 0) {
        const mainProperty = propertiesSchema.find(({ isMain }) => isMain);

        if (mainProperty) push(mainProperty);
      }
    }, [
      propertiesSchema,
      materialProperties,
      assortmentMaterialId,
      push,
      setFields,
    ]);

    const notAddedProperties = useMemo(() => {
      if (!propertiesSchema) return [];

      return propertiesSchema.filter(({ id: schemaId }) => {
        return !materialProperties.some(item => item?.id === schemaId);
      });
    }, [propertiesSchema, materialProperties]);

    const handleRemoveProperty = useCallback(
      (index: number) => remove(index),
      [remove],
    );

    const selectProps = useMemo(() => {
      return isRecommendationMode && hasMarkRecommendation
        ? {
            rightAddon: (
              <Tooltip
                pointer
                arrow={false}
                placement='bottom-start'
                title='Подобраны рекомендации'
              >
                <RecommendationSmallIcon
                  color='primary.main'
                  mr={1}
                  my='auto'
                />
              </Tooltip>
            ),
            dropdownAddon: (
              <DropdownItem>
                Вернуться к{' '}
                <Button variant='string' onClick={toggleRecommendationMode}>
                  полному списку
                </Button>{' '}
                марок материала по выбранному ГОСТ
              </DropdownItem>
            ),
            options: (data: IOption) => {
              return fetchMaterialOptions(data, assortmentMaterialId);
            },
          }
        : {
            dropdownAddon: hasMarkRecommendation && (
              <DropdownItem>
                Вернуться к{' '}
                <Button variant='string' onClick={toggleRecommendationMode}>
                  рекомендуемому списку
                </Button>{' '}
                марок материала по выбранному ГОСТ
              </DropdownItem>
            ),
            options: fetchMaterialOptions,
          };
    }, [
      isRecommendationMode,
      hasMarkRecommendation,
      assortmentMaterialId,
      toggleRecommendationMode,
    ]);

    return (
      <Fragment>
        <Field
          disabled={isDisabled}
          {...INITIAL_FIELD_PROPS}
          isMaterial
          component={AssortmentSelect}
          label='НТД на материал'
          name={`${ASSORTMENT_MATERIAL_FIELD}.id`}
        />
        {isLoadingAssortmentMaterial || isLoadingMarkRecommendation ? (
          <Spinner delay={300} />
        ) : (
          assortmentMaterial && (
            <LevelContainer mb={3}>
              <Container
                bg={theme.colors.background}
                borderRadius={theme.borderRadius}
                display='block'
                p={2}
              >
                <CollapseHeader
                  collapse
                  collapseHeight={34}
                  color={theme.colors.text.secondary}
                  fontSize={3}
                  isOpen={isOpenMaterial}
                  mb={0}
                  size='s'
                  title='Характеристики материала'
                  onToggleCollapse={toggleOpenMaterial}
                />
                {isOpenMaterial && (
                  <Fragment>
                    <Field
                      {...selectProps}
                      required
                      component={SelectField}
                      disabled={isDisabled}
                      itemToString={itemToString}
                      label='Марка материала'
                      name={`${ASSORTMENT_MATERIAL_FIELD}.standardMaterial`}
                    />
                    <Container column gap='14px'>
                      {materialProperties.map((property, index) => (
                        <PropertyField
                          key={property?.id}
                          isDisabled={isDisabled}
                          path={`${ASSORTMENT_MATERIAL_FIELD}.properties.${index}`}
                          property={propertiesSchemaById![property?.id]}
                          onRemove={() => handleRemoveProperty(index)}
                        />
                      ))}
                    </Container>
                    {notAddedProperties.length > 0 && (
                      <Fragment>
                        <Divider
                          mb={2}
                          mt='0px'
                          mx={-2}
                          style={DIVIDER_STYLE}
                        />
                        <Container flexWrap='wrap' gap={0}>
                          {notAddedProperties.map(property => (
                            <ToggleButton.Tab
                              key={property?.id}
                              disabled={isDisabled}
                              icon={<AddMediumIcon />}
                              label={property?.name}
                              mb='0px'
                              mr='0px'
                              onClick={() => {
                                push(propertiesSchemaById![property?.id]);
                              }}
                            />
                          ))}
                        </Container>
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Container>
            </LevelContainer>
          )
        )}
      </Fragment>
    );
  },
);

export { AssortmentMaterialFields };
