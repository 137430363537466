import { Fragment, ReactElement } from 'react';

import { useParams } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { TrashIcon } from 'resources/icons/18';
import {
  ArrayBackendType,
  IParamsId,
  Nullable,
} from 'common/types/common.types';
import { PhysicalQuantities } from 'common/types/dictionaries.types';
import { ObjectType } from 'common/types/product.types';
import { ISparePartsInfo } from 'common/types/product.types/accompany-information.types';
import {
  isValidObjectValue,
  checkIsDropdownObjectDisabled,
} from 'common/utils/products/linked-object';
import { SimpleLinkedObject } from 'components/products/linked-object';
import { useField, useWatch } from 'reform';
import { AddSmallIcon } from 'resources/icons/12';
import { removeItem } from 'tools/utils';
import {
  FormLabel,
  TextInput,
  IconButton,
  Tooltip,
  Button,
  UnionContainer,
} from 'UI';

import { PropertyUnits } from '../../../../../../features/products/_shared/Units';

const TIP =
  'Запасные части, материалы и инструменты, необходимые для проведения работы.';
const LIMIT = 10;

const HeaderGrid = styled.div(
  ({ theme: { space, fontSizes, colors } }) => css`
    display: grid;
    grid-template-columns: 273px ${space[2]}px auto;
    font-weight: 600;
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
    margin-top: ${space[2]}px;
    margin-bottom: ${space[1]}px;
  `,
);

const Container = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: 273px ${space[2]}px auto ${space[1]}px ${space[4]}px;
    grid-template-rows: auto;
    grid-row-gap: ${space[0]}px;
  `,
);

function SpareItem({
  name,
  parentName,
  fieldKey,
  onRemove,
}): ReactElement | null {
  const allSpareParts = useWatch({ fieldName: parentName });
  const { id: productId } = useParams<IParamsId>();
  const { value, error, setError, setValue } = useField<ISparePartsInfo>({
    name,
    key: fieldKey,
  });

  const handleChangeQuantity = ({ target }): void => {
    setValue(prev => ({ ...prev, quantity: target.value }));

    if (error?.quantity) {
      setError(({ quantity: _, ...otherErrors }) => otherErrors);
    }
  };

  const handleChangeUnit = (unit): void => {
    setValue(prev => ({ ...prev, unit }));
  };

  return (
    <Fragment>
      <SimpleLinkedObject
        canError
        disableItem={item =>
          checkIsDropdownObjectDisabled({
            productId,
            item,
            items: allSpareParts.items,
          })
        }
        name={`${name}.object`}
      />
      <div />
      <UnionContainer>
        <UnionContainer.Row>
          <Tooltip hint title={error?.quantity}>
            <TextInput
              error={error?.quantity}
              placeholder='Введите кол-во'
              type='number'
              value={value.quantity}
              onChange={handleChangeQuantity}
            />
          </Tooltip>
          <PropertyUnits
            physicalQuantity={[
              PhysicalQuantities.quantity,
              PhysicalQuantities.volume,
              PhysicalQuantities.weight,
              PhysicalQuantities.length,
            ]}
            placeholder='Ед. изм.'
            showLabel={false}
            value={value.unit}
            width={140}
            onChange={handleChangeUnit}
          />
        </UnionContainer.Row>
      </UnionContainer>
      <div />
      <IconButton onClick={onRemove}>
        <TrashIcon />
      </IconButton>
    </Fragment>
  );
}

function SpareParts(): ReactElement {
  const fieldName = 'spareParts';
  const { value, setValue } = useField<
    Nullable<ArrayBackendType<ISparePartsInfo>>
  >({
    name: fieldName,
  });

  const disabledAddButton = useWatch({
    fieldName,
    selector: spareParts => {
      const isEmptyOrFillSpare =
        !spareParts ||
        spareParts.items.every(
          ({ object, quantity, unit }) =>
            isValidObjectValue({ object }) && !!quantity && !!unit,
        );

      return !isEmptyOrFillSpare;
    },
  });

  const handleAddSpare = (): void => {
    const initialValue: ISparePartsInfo = {
      id: uuid(),
      object: { type: ObjectType.text, value: '' },
      quantity: '',
      unit: '',
    };
    if (!value) {
      setValue({ items: [initialValue] });
    } else {
      setValue(prev => ({ items: [...prev!.items, initialValue] }));
    }
  };

  const handleRemoveSpare = index => () => {
    setValue(prev =>
      prev!.items.length === 1
        ? null
        : { items: removeItem(prev!.items, index) },
    );
  };

  return (
    <Fragment>
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <FormLabel
        color='text.primary'
        fontSize={4}
        fontWeight={500}
        mb='0px'
        mt={3}
        tip={TIP}
      >
        Запасные части, инструменты, расходные материалы
      </FormLabel>
      {!!value && (
        <Fragment>
          <HeaderGrid>
            <span>Название</span>
            <div />
            <span>Количество</span>
          </HeaderGrid>
          <Container>
            {value.items.map((item, index) => {
              const key =
                item.id ?? `${item.object.value}-${item.quantity}-${item.unit}`;

              return (
                <SpareItem
                  key={key}
                  fieldKey={key}
                  name={`${fieldName}.items.${index}`}
                  parentName={fieldName}
                  onRemove={handleRemoveSpare(index)}
                />
              );
            })}
          </Container>
        </Fragment>
      )}
      {(!value || value.items.length < LIMIT) && (
        <div>
          <Button
            color='secondary'
            disabled={disabledAddButton}
            mt={0}
            size='s'
            variant='text'
            onClick={handleAddSpare}
          >
            <AddSmallIcon mr={0} /> Добавить товар
          </Button>
        </div>
      )}
    </Fragment>
  );
}

export { SpareParts };
