import { FC, memo, useMemo, useState, useEffect, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import {
  addToFavorite,
  removeFromFavorite,
  updateSubscribeStatus,
} from 'common/api/favorite.api';
import { fetchProducts } from 'common/api/products.api';
import { DEFAULT_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { INITIAL_DATA_REQUEST_V1 } from 'common/constants/request.const';
import { useRequest } from 'common/hooks';
import { getTablesDate } from 'common/utils';
import { PRODUCT_COLUMNS } from 'components/products/columns';
import { TABLE_COLUMNS } from 'components/tableColumns';
import { useNotify, BUTTONS_ACTION } from 'entities/notify';
import { UnsubscribeIcon, TrashIcon, SubscribeIcon } from 'resources/icons/18';
import {
  DataTable,
  Pagination,
  HiddenTableAction,
  Checkbox,
  Status,
  IDataTableColumn,
  Container,
} from 'UI';

import { FavoriteTableHeader } from './TableHeader';
import { IFavoriteProductsTableProps } from '../../type';

const ControlsWrapper = styled.div`
  display: inline-flex;
`;

const renderStatus = (isSubscribed: boolean): JSX.Element => {
  if (isSubscribed) {
    return (
      <Status ml='20px' title='Подписан' type={Status.TYPES.SUCCESS_STATUS} />
    );
  }

  return <Status ml='20px' title='Не подписан' />;
};

const FavoriteProductsTable: FC<IFavoriteProductsTableProps> = memo(
  ({
    page,
    searchValue,
    itemsPerPage,
    orderBy,
    orderDirection,
    querySet,
    onSort,
    isFavorite = 1,
  }) => {
    const history = useHistory();
    const notify = useNotify();

    const {
      data = INITIAL_DATA_REQUEST_V1,
      isLoading,
      updateData,
    } = useRequest(fetchProducts, [
      {
        search: searchValue,
        page,
        itemsPerPage,
        orderBy,
        orderDirection,
        isFavorite,
      },
    ]);

    const [selectedProducts, setSelectedProducts] = useState<string[]>([]);

    useEffect(() => setSelectedProducts([]), [page]);

    const isSelectedAll =
      !isLoading &&
      data.member.length > 0 &&
      selectedProducts.length === data.member.length;

    const handleSelectAll = useCallback(() => {
      if (isSelectedAll) {
        setSelectedProducts([]);
      } else {
        setSelectedProducts([...data.member.map(product => product.id)]);
      }
    }, [data.member, isSelectedAll]);

    const handleUpdateData = useCallback(
      (productIds: string[], isSubscribeAction: boolean = false): void => {
        const totalPages = Math.ceil(data.totalItems / itemsPerPage);
        if (
          !isSubscribeAction &&
          page > 1 &&
          totalPages === page &&
          data.member.length === productIds.length
        ) {
          history.push(
            querySet(params => ({
              ...params,
              page: page > 0 ? page - 1 : 0,
            })),
          );
        } else {
          updateData();
        }
      },
      [
        data.member.length,
        data.totalItems,
        history,
        itemsPerPage,
        page,
        querySet,
        updateData,
      ],
    );

    const handleRemoveFromFavorite = useCallback(
      async (favoriteIds: string[]): Promise<void> => {
        try {
          await removeFromFavorite(favoriteIds);
          setSelectedProducts([]);
          handleUpdateData(favoriteIds);
          const message =
            favoriteIds.length === 1
              ? 'Товар удален из раздела Избранное.'
              : 'Товары удалены из раздела Избранное.';

          notify.success(message, {
            as: BUTTONS_ACTION.BUTTON,
            title: 'Восстановить',
            onClick: async () => {
              try {
                await Promise.all(favoriteIds.map(id => addToFavorite(id)));
                updateData();
              } catch (error) {
                notify.error('При восстановлении возникли ошибки.');
              }
            },
          });
        } catch (error) {
          notify.error('Произошла ошибка. Обновите страницу.');
        }
      },
      [handleUpdateData, notify, updateData],
    );

    const columns: IDataTableColumn[] = useMemo(
      () => [
        {
          ...TABLE_COLUMNS.checkbox({
            disabled: isLoading || data.member.length === 0,
            isSelectedAll,
            handleSelectAll,
          }),
          render: (_, product) => {
            const isSelected = selectedProducts.includes(product.id);

            return (
              <Checkbox
                checked={isSelected}
                value={isSelected}
                onChange={() => {
                  setSelectedProducts(prev =>
                    isSelected
                      ? prev.filter(id => id !== product.id)
                      : [...prev, product.id],
                  );
                }}
              />
            );
          },
        },
        PRODUCT_COLUMNS.previewImage(),
        PRODUCT_COLUMNS.mainProperties,
        PRODUCT_COLUMNS.name(),
        PRODUCT_COLUMNS.category,
        PRODUCT_COLUMNS.type(),
        PRODUCT_COLUMNS.companyName(),
        {
          key: 'favoredAt',
          truncate: false,
          header: { text: 'Добавлен', fixedWidth: 110 },
          render: date => getTablesDate(date, false, DEFAULT_DATE_PATTERN),
        },
        {
          key: 'isSubscribed',
          header: { text: 'Подписка', fixedWidth: 100 },
          truncate: false,
          render: isSubscribed => renderStatus(isSubscribed),
        },
        {
          key: 'controls',
          sortable: false,
          truncate: false,
          align: 'right',
          header: { fixedWidth: 100 },
          render: (_, { id, isSubscribed, name }) => (
            <ControlsWrapper>
              <HiddenTableAction
                mr={1}
                title={isSubscribed ? 'Отписаться' : 'Подписаться'}
                onClick={() => {
                  updateSubscribeStatus([id], {
                    isSubscribed: !isSubscribed,
                  }).then(
                    () => {
                      const message = !isSubscribed
                        ? `Подписка на обновления ${name} оформлена.`
                        : 'Вы отписались от обновлений.';
                      notify.success(message);
                      handleUpdateData([id], true);
                    },
                    () => notify.error('Произошла ошибка. Обновите страницу.'),
                  );
                }}
              >
                {isSubscribed ? <UnsubscribeIcon /> : <SubscribeIcon />}
              </HiddenTableAction>
              <HiddenTableAction
                mr={1}
                title='Удалить'
                onClick={() => handleRemoveFromFavorite([id])}
              >
                <TrashIcon />
              </HiddenTableAction>
            </ControlsWrapper>
          ),
        },
      ],
      [
        data.member.length,
        handleRemoveFromFavorite,
        handleSelectAll,
        handleUpdateData,
        isLoading,
        isSelectedAll,
        notify,
        selectedProducts,
      ],
    );

    return (
      <>
        <Container column flexGrow={1} height='100%' width='100%'>
          <FavoriteTableHeader
            selectedProducts={selectedProducts}
            setSelectedProducts={setSelectedProducts}
            onRemoveFromFavorite={handleRemoveFromFavorite}
            onUpdateData={handleUpdateData}
          />
          <DataTable
            isInnerTable
            columns={columns}
            data={data.member}
            loading={isLoading}
            orderBy={orderBy}
            orderDirection={orderDirection}
            placeholder={{ type: searchValue ? 'matches' : 'favorite' }}
            rowStyle={item => {
              const isSelected = selectedProducts.includes(item.id);
              return isSelected
                ? { backgroundColor: 'highlight.0' }
                : undefined;
            }}
            onSort={onSort}
          />
          <Pagination mt={2} totalItems={data.totalItems} />
        </Container>
      </>
    );
  },
);

export { FavoriteProductsTable };
