import { useState } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { DEFAULT_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { ITask, ProductSections } from 'common/types/product.types';
import { getImageURL, formatDate, getFullName } from 'common/utils';
import { TaskStatusBadge } from 'components/products/TaskStatusBadge';
import { InfoBlock, Heading, Avatar, Text, ExpandableSection } from 'UI';

const MAX_HEIGHT = 400;

const Info = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${space[3]}px;
  `,
);

const BlockInfo = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    height: 70px;
    padding: 12px ${space[3]}px;
    border: 1px solid ${colors.divider};
    border-right-width: 0;

    &:first-of-type {
      padding-left: 0;
      border-left-width: 0;
    }
  `,
);

const TaskInfo = styled(ExpandableSection.Wrapper)<{ hasInfo: boolean }>(
  ({ theme: { fontSizes, colors }, hasInfo }) => css`
    max-width: 860px;
    font-size: ${fontSizes[5]};
    color: ${hasInfo ? colors.text.primary : colors.text.secondary};
    word-break: break-word;
    white-space: pre-line;
  `,
);

function ProductTask({ task }: { task: ITask }): JSX.Element | null {
  const theme = useTheme();

  const [showExpandButton, setShowExpandButton] = useState(false);

  const handleDescriptionRef = (element: HTMLDivElement): void => {
    if (element && element.offsetHeight > MAX_HEIGHT) {
      setShowExpandButton(true);
    }
  };

  if (!task) return null;

  return (
    <InfoBlock
      collapse
      initialOpen
      bg={theme.colors.highlight[0]}
      id={ProductSections.task}
      mt={2}
      title='Задача'
    >
      <ExpandableSection expandable={showExpandButton} maxHeight={MAX_HEIGHT}>
        <ExpandableSection.Wrapper ref={handleDescriptionRef}>
          <Info>
            <BlockInfo style={{ flexGrow: 1 }}>
              <Avatar
                rounded
                letter={getFullName(task.executor)}
                mr={2}
                size='s'
                url={getImageURL(task.executor.avatarImage)}
              />
              <div>
                <Text fontSize={4} fontWeight={500} mb='2px'>
                  {getFullName(task.executor)}
                </Text>
                <Text color='text.secondary'>Исполнитель</Text>
              </div>
            </BlockInfo>
            <BlockInfo style={{ flexGrow: 1 }}>
              <Avatar
                rounded
                letter={getFullName(task.executor)}
                mr={2}
                size='s'
                url={getImageURL(task.verifier.avatarImage)}
              />
              <div>
                <Text fontSize={4} fontWeight={500} mb='2px'>
                  {getFullName(task.executor)}
                </Text>
                <Text color='text.secondary'>Проверяющий</Text>
              </div>
            </BlockInfo>
            {task.dueDate && (
              <BlockInfo>
                <div>
                  <Text fontSize={4} fontWeight={500} mb='2px'>
                    {formatDate(task.dueDate, false, DEFAULT_DATE_PATTERN)}
                  </Text>
                  <Text color='text.secondary'>Срок исполнения</Text>
                </div>
              </BlockInfo>
            )}
            <BlockInfo>
              <TaskStatusBadge status={task.status} />
            </BlockInfo>
          </Info>
          <Heading as='h4' mb={2}>
            Описание
          </Heading>
          <TaskInfo
            dangerouslySetInnerHTML={{
              __html: task.description || 'Описание отсутствует',
            }}
            hasInfo={!!task.description}
          />
        </ExpandableSection.Wrapper>
      </ExpandableSection>
    </InfoBlock>
  );
}

export { ProductTask };
