import { FC, Fragment, useEffect, useRef, useState } from 'react';

import { throttle } from 'lodash';
import styled, { css } from 'styled-components';

import { getLSItem } from 'common/utils';
import { EducationPoint } from 'entities/education';
import { TriggerForMasterPanel, useRightProductPanelContext } from 'entities/right-product-panel';
import { ProductFormIcon } from 'resources/other-20';
import { Tooltip } from 'UI';

import { AccessPanel } from './access-panel/AccessPanel';
import { RecommendationPanelButton } from './components';
import { HINT_POINT_KEYS } from '../../../constants';

const Wrapper = styled.div<{ isSidePanelVisible: boolean }>(
  ({ theme: { zIndex }, isSidePanelVisible }) => css`
    position: sticky;
    top: 0;
    margin-right: -12px;
    z-index: ${zIndex.header};
    height: 0;

    ${isSidePanelVisible &&
    css`
      display: none;
    `}
  `,
);

const ButtonsWrapper = styled.div(
  ({ theme: { space, colors, shadows } }) => css`
    display: flex;
    flex-direction: column;
    gap: ${space[0]}px;
    width: min-content;
    margin-left: auto;
    padding: ${space[1]}px;
    border-radius: 8px;
    background: ${colors.white};
    box-shadow: ${shadows[1]};
  `,
);

const getPointOfWidthCollapse = (): number => {
  // TODO нельзя завязываться на storage, т.к. при свёртывании панелей ничего не происходит. Нужен стейт или контекст у панелей
  const isSidebarCollapse = getLSItem('sidebarCollapse');
  const isMenuCollapse = getLSItem('menuCollapse');

  if (isSidebarCollapse && isMenuCollapse) return 1310;
  if (!isSidebarCollapse && !isMenuCollapse) return 1659;

  return 1520;
};

const RecommendationPanel: FC<{ isShowRecommendationPanel?: boolean }> = ({
  isShowRecommendationPanel = true,
}) => {
  const actionPanelRef = useRef<HTMLDivElement>(null);

  const { isOpenPanel: isSidePanelVisible } = useRightProductPanelContext();

  const [isHiddenText, setIsHiddenText] = useState(
    window.innerWidth < getPointOfWidthCollapse(),
  );

  useEffect(() => {
    const handleResize = throttle(() => {
      setIsHiddenText(window.innerWidth < getPointOfWidthCollapse());
    }, 1000);

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper isSidePanelVisible={isSidePanelVisible}>
      <ButtonsWrapper ref={actionPanelRef}>
        {isShowRecommendationPanel && (
          <EducationPoint section={HINT_POINT_KEYS.SYSTEM_FORMS}>
            <TriggerForMasterPanel>
              <Tooltip
                arrow
                hint
                placement='left'
                title={isHiddenText && 'Мастер-панель'}
              >
                <RecommendationPanelButton
                  isHiddenText={isHiddenText}
                  size='m'
                  variant='outlined'
                >
                  {isHiddenText ? (
                    <ProductFormIcon size={13} />
                  ) : (
                    <Fragment>
                      <ProductFormIcon mr={1} size={13} />
                      Мастер-панель
                    </Fragment>
                  )}
                </RecommendationPanelButton>
              </Tooltip>
            </TriggerForMasterPanel>
          </EducationPoint>
        )}
        <AccessPanel
          actionPanelRef={actionPanelRef}
          isHiddenText={isHiddenText}
        />
      </ButtonsWrapper>
    </Wrapper>
  );
};

export { RecommendationPanel };
