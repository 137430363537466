import { IPropertyGroup } from 'common/types/form.types';
import { FormTypesByNumber } from 'common/types/product-form-myr.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { ISearchParametersProperty } from 'common/types/search.types';
import { transformProperty } from 'common/utils/search';

export const checkFilled = (
  data: ISearchParametersProperty[] | IPropertyGroup[],
  selectedFormType: string,
): boolean => {
  return data.some(prop => {
    if (prop.type === PropertiesTypes.group) {
      return checkFilled(prop.value, selectedFormType);
    }

    return (
      transformProperty(prop).value &&
      FormTypesByNumber[prop.formType] <= FormTypesByNumber[selectedFormType]
    );
  });
};
