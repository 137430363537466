import { Fragment, FC } from 'react';

import ReactMarkdown from 'react-markdown';
import { Link } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { useRequest } from 'common/hooks';
import { GUIDES_BASE_URL } from 'common/utils/request';
import { CenteredPage } from 'layouts/Pages';
import { getPath, GUIDES, INFORMATION } from 'routes';
import { Divider, Heading, Button, Spinner, Text, Container } from 'UI';

import { GuidesBlock } from './GuidesBlock';
import { SearchGuideElement } from './SearchElement';
import { fetchCategories, fetchGuideHomePage } from '../api';

const CategoryIcon = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 24px;
  height: 24px;
`;

const GuideTitle = styled(Link)(
  ({ theme: { colors } }) => css`
    width: max-content;
    font-size: 18px;
    font-weight: 600;
    color: ${colors.text.primary};
  `,
);

const Footer = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    font-size: ${fontSizes[5]}px;
    color: ${colors.text.secondary};

    & * {
      margin: 0;
      padding: 0;
    }

    &::before {
      content: '';
      display: block;
      width: 100%;
      height: 1px;
      margin: ${space[6]}px 0;
      background: ${colors.divider};
    }
  `,
);

const GuidesList: FC = () => {
  const theme = useTheme();

  const { isLoading: isLoadingPage, data: page } = useRequest(
    fetchGuideHomePage,
    [],
  );

  const { isLoading: isLoadingCategories, data: categories = [] } = useRequest(
    fetchCategories,
    [],
  );

  if (isLoadingPage || isLoadingCategories) {
    return <Spinner bg={theme.colors.white} delay={100} width='100%' />;
  }

  return (
    <CenteredPage
      bg={theme.colors.white}
      heading={{
        heading: page.title,
        backLink: INFORMATION.PAGE,
        controls: <SearchGuideElement />,
      }}
    >
      <Container
        column
        alignItems='stretch'
        id='MainContent'
        m='0 auto'
        p={2}
        width={CenteredPage.CONTENT_WIDTH.MEDIUM}
      >
        <Heading as='h2'>{page.heading}</Heading>
        <Text fontSize={5} mb={4} mt={2}>
          {page.description}
        </Text>
        {categories.map(({ id, title, guides, image }, index) => (
          <Fragment key={id}>
            <Container display='block' pl='40px' position='relative'>
              <Container>
                <CategoryIcon
                  alt={image?.alternativeText}
                  src={`${GUIDES_BASE_URL}${image?.url}`}
                />
                <GuideTitle to={getPath(GUIDES.PAGE, { categoryId: id })}>
                  {title}
                </GuideTitle>
                {guides.length > 7 && (
                  <Button
                    as={Link}
                    color='primary'
                    ml='auto'
                    to={getPath(GUIDES.PAGE, { categoryId: id })}
                    variant='text'
                  >
                    Все статьи
                  </Button>
                )}
              </Container>
              <GuidesBlock guides={guides.slice(0, 8)} />
            </Container>
            {index < categories.length - 1 && <Divider my={4} />}
          </Fragment>
        ))}
        {page.footer && (
          <Footer>
            <ReactMarkdown>{page.footer}</ReactMarkdown>
          </Footer>
        )}
      </Container>
    </CenteredPage>
  );
};

export { GuidesList };
