import { memo, FC } from 'react';

import styled, { css } from 'styled-components';

import { useModalContext } from 'entities/modals';
import { Container, Text } from 'UI';

import {
  COMMON_ITEM_PROPS,
  COMPANY_COLUMN_DATA,
  SUPPORT_COLUMN_DATA,
  IColumnData,
} from './constants';
import impulseTheme from '../../../../theme/theme';
import { CONTAINER_PROPS, GAP, TEXT_PROPS } from '../constants';
import { MODAL_FORM } from '../products/constants';

const StyledContainer = styled(Container)(
  ({ theme: { colors } }) => css`
    color: ${colors.text.primary};

    &:hover {
      color: ${impulseTheme.colors.primary.dark};
    }
  `,
);

const TelephoneLink = styled.a(
  ({ theme: { space, colors } }) => css`
    margin-bottom: ${space[4]}px;
    font-size: 36px;
    line-height: 1.2;
    color: ${colors.text.primary};

    &:hover {
      color: ${impulseTheme.colors.primary.dark};
    }
  `,
);

const Column: FC<{ columnData: IColumnData }> = ({
  columnData: { header, items },
}) => {
  const { handleModalOpen } = useModalContext();

  return (
    <Container column alignItems='flex-start'>
      <Text mb='46px' {...TEXT_PROPS['24']} color='text.primary'>
        {header}
      </Text>
      {items.map(({ hasModal, title, props }, index) => (
        <StyledContainer
          key={index}
          {...props}
          {...COMMON_ITEM_PROPS}
          {...(hasModal && {
            onClick: () => handleModalOpen(MODAL_FORM, { title }),
          })}
        >
          {title}
        </StyledContainer>
      ))}
    </Container>
  );
};

const Footer: FC = memo(() => (
  <Container {...CONTAINER_PROPS} mb='100px'>
    <Container column alignItems='flex-start' mr={2} width='500px'>
      <Text {...TEXT_PROPS['20']} mb='72px'>
        Россия, Республика Дагестан, г. Махачкала
        <br />
        улица Батырая 11, 425
      </Text>
      <TelephoneLink href='tel:+78722670461'>+7 (8722) 67-04-61</TelephoneLink>
      <Text {...TEXT_PROPS['16']}>
        © ООО «Импульс», {new Date().getFullYear()}
      </Text>
    </Container>
    <Container flexGrow={1} gap={GAP} justifyContent='space-around'>
      <Column columnData={SUPPORT_COLUMN_DATA} />
      <Column columnData={COMPANY_COLUMN_DATA} />
    </Container>
  </Container>
));

export { Footer };
