import { useCallback, memo, useRef, FC } from 'react';

import styled, { css } from 'styled-components';

import { findWorkCategories } from 'common/api/dictionaries.api';
import { Nullable } from 'common/types/common.types';
import { IWorkNameCategory } from 'common/types/dictionaries.types';
import { FieldWrapper } from 'components/fields/FieldWrapper';
import { useField } from 'reform';
import { PalletIcon } from 'resources/other-28';
import { useToggle } from 'tools/hooks';
import { Container, IconButton } from 'UI';
import {
  Select,
  ItemToStringAndOptionsType,
  OnStateChange,
  FetchOptionsType,
} from 'UI/Inputs';

import { CategoryLibrary } from './CategoryLibrary';

const StyledIconButton = styled(IconButton)<{ isActive: boolean }>(
  ({ theme: { colors }, isActive }) => css`
    ${isActive &&
    css`
      color: ${colors.text.primary};
      background: ${colors.highlight[2]};

      & svg {
        color: ${colors.text.primary};
      }
    `}
  `,
);

const itemToString: ItemToStringAndOptionsType = (item, options) => {
  return options ? item.category : item;
};

const fetchCategoriesOptions: FetchOptionsType = ({
  name,
  page,
  itemsPerPage,
}) => {
  return findWorkCategories({
    page,
    itemsPerPage,
    search: name,
    isLeaf: true,
  });
};

const CategoryComponent: FC<{ type: 'service' | 'work' }> = memo(({ type }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [isOpenLibrary, toggleOpenLibrary] = useToggle(false);

  const { value, setValue, error } = useField({ name: 'category' });

  const handleStateChange = useCallback<OnStateChange>(
    ({ isOpen }) => {
      if (isOpen) toggleOpenLibrary(false);
    },
    [toggleOpenLibrary],
  );

  const handleChange = (newValue: Nullable<IWorkNameCategory>): void => {
    setValue(newValue?.category ?? '');
  };

  return (
    <FieldWrapper
      required
      showErrorDescription
      error={error}
      label='Категория'
      mb={2}
    >
      <Container ref={containerRef} width='100%'>
        <Select
          canClear
          disablePortal={false}
          error={error}
          itemToString={itemToString}
          options={fetchCategoriesOptions}
          value={value}
          onChange={handleChange}
          onStateChange={handleStateChange}
        />
        <StyledIconButton
          frame
          isActive={isOpenLibrary}
          ml={1}
          title='Библиотека'
          onClick={toggleOpenLibrary}
        >
          <PalletIcon />
        </StyledIconButton>
        {isOpenLibrary && (
          <CategoryLibrary
            containerRef={containerRef}
            selectedCategory={value}
            setCategory={setValue}
            type={type}
            onRequestClose={() => toggleOpenLibrary(false)}
          />
        )}
      </Container>
    </FieldWrapper>
  );
});

export { CategoryComponent };
