import { FC, useCallback } from 'react';

import { WarningIcon } from 'resources/modal';
import { HINTS } from 'common/constants/products.const';
import { useHint } from 'common/hooks';
import { useToggle } from 'tools/hooks';
import { Checkbox, Modal, Text } from 'UI';

const REPLACED_PARAMETER_MODAL = 'REPLACED_PARAMETER_MODAL';
const REPLACED_PARAMETERS_MODAL = 'REPLACED_PARAMETERS_MODAL';
const SUBMIT_BUTTON_PROP = { title: 'Заменить' };

interface IReplacedParameterModalProps {
  onConfirm: VoidFunction;
  handleModalClose: VoidFunction;
}

const ReplacedParameterModal: FC<IReplacedParameterModalProps> = ({
  onConfirm,
  handleModalClose,
}) => {
  const { handleToggleViewed } = useHint(HINTS.MASTER_PANEL_REUSE_REPLACED);

  const [checked, toggleChecked] = useToggle();

  const handleSubmit = useCallback(() => {
    if (checked) handleToggleViewed();
    onConfirm();
    handleModalClose();
  }, [checked, onConfirm, handleModalClose, handleToggleViewed]);

  return (
    <Modal onSubmit={handleSubmit}>
      <Modal.Confirm
        icon={<WarningIcon />}
        submitButton={SUBMIT_BUTTON_PROP}
        title='Поле уже заполнено'
      >
        <Text mb={2}>
          Это поле уже заполнено. Хотите заменить ваше значение на новое?
        </Text>
        <Checkbox
          label='Всегда заменять и больше не спрашивать'
          labelColor='text.primary'
          mb={3}
          onChange={toggleChecked}
        />
      </Modal.Confirm>
    </Modal>
  );
};

interface IReplacedParametersModalProps {
  onConfirm: (isSkipConflicts?: boolean) => void;
  handleModalClose: VoidFunction;
}

const ReplacedParametersModal: FC<IReplacedParametersModalProps> = ({
  onConfirm,
  handleModalClose,
}) => {
  const handleSkip = useCallback(() => {
    onConfirm(true);
    handleModalClose();
  }, [onConfirm, handleModalClose]);

  const handleSubmit = useCallback(() => {
    onConfirm();
    handleModalClose();
  }, [onConfirm, handleModalClose]);

  return (
    <Modal hasSubmitOnEnter={false} onSubmit={handleSubmit}>
      <Modal.Confirm
        additionalButton={{ title: 'Пропустить', onClick: handleSkip }}
        icon={<WarningIcon />}
        submitButton={SUBMIT_BUTTON_PROP}
        title='Поля уже заполнены'
      >
        <Text mb={2}>
          Часть полей и разделов, которые вы хотите добавить, уже заполнена.
          Хотите заменить ваши значения на новые?
        </Text>
        <Text mb={3}>
          Нажмите «Пропустить», чтобы добавить значения только в пустые поля и
          разделы.
        </Text>
      </Modal.Confirm>
    </Modal>
  );
};

export {
  ReplacedParameterModal,
  ReplacedParametersModal,
  REPLACED_PARAMETER_MODAL,
  REPLACED_PARAMETERS_MODAL,
};
