import { FC, memo } from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { ICompany } from 'common/types/company.types';
import { useModalContext } from 'entities/modals';
import { SendIcon } from 'resources/icons/18';
import { Button, IconButton, Tooltip } from 'UI';
import { ButtonProps } from 'UI/Buttons/Button';

import { CREATE_MANUFACTURING_REQUEST_MODAL } from './CreateRequestModal';
import { useManufactureButtonInfo } from './hooks';
import { IRequestManufacturersBase } from './type';

const StyledIconButton = styled(IconButton)(
  ({ theme: { shadows } }) => css`
    box-shadow: ${shadows[1]};

    &[disabled] {
      box-shadow: initial;
    }
  `,
);

const StyledButton = styled(Button)(
  ({ theme: { colors } }) => css`
    &[disabled] svg,
    &[disabled]:hover svg {
      color: ${colors.primary.disabled};
    }
  `,
);

const BUTTON_TEXT = 'Отправить заявку';

interface IManufacturingRequestButtonProps extends IRequestManufacturersBase {
  rounded?: boolean;
  companyId: string | undefined;
  authorshipCompany?: ICompany;
  productId?: string;
}

/**
 * Кнопка 'Отправить заявку', которая используется на внешних страницах стандарта,
 * сортамента, опросного листа, изделия, мест продаж, услуги и компании
 *
 * Заявки, отправление через модалку, попадают в раздел Товары / Заявки
 */
const ManufacturingRequestButton: FC<
  IManufacturingRequestButtonProps &
    SpaceProps &
    Pick<ButtonProps, 'color' | 'width'>
> = memo(
  ({
    rounded,
    companyId,
    item,
    type,
    authorshipCompany,
    productId,
    ...others
  }) => {
    const { handleModalOpen } = useModalContext();
    const { title, disabled } = useManufactureButtonInfo({
      authorshipCompany,
      companyId,
      type,
    });

    const handleRequestModalOpen = (): void => {
      return handleModalOpen(CREATE_MANUFACTURING_REQUEST_MODAL, {
        type,
        item,
        authorshipCompany,
        productId,
      });
    };

    const buttonProps = { disabled, onClick: handleRequestModalOpen };

    if (rounded) {
      return (
        <StyledIconButton
          rounded
          title={title ?? BUTTON_TEXT}
          variant='darkHighlight'
          {...buttonProps}
        >
          <SendIcon />
        </StyledIconButton>
      );
    }

    return (
      <Tooltip pointer placement='bottom' title={title}>
        <div>
          <StyledButton {...buttonProps} {...others}>
            <SendIcon mr={1} />
            {BUTTON_TEXT}
          </StyledButton>
        </div>
      </Tooltip>
    );
  },
);

export { ManufacturingRequestButton };
