import { Component, CSSProperties, MouseEvent, ReactNode, RefObject } from 'react';

import { get } from 'lodash';
import { Timeout } from 'react-number-format/types/types';
import styled, { css } from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { CameraIcon, DoneIcon } from 'resources/other';
import { CloseMediumIcon } from 'resources/icons/18';
import { CircleProgress, Text, Tooltip } from 'UI';

import { BaseImageBackground } from '../styled';
import { parseHex } from '../utils';

const rgbaBg = ({
  theme: { colors },
  color: colorProp,
  opacity = 0.2,
}): string => {
  if (!colorProp) return 'background-color: transparent';
  const hexColor = get(colors, colorProp, colorProp);
  return `background-color: rgba(${parseHex(hexColor).join(', ')}, ${opacity})`;
};

const Overlay = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    padding: ${space[2]}px 0;

    ${rgbaBg};
  `,
);

const IconBackground = styled.button.attrs(() => ({
  type: 'button',
}))<ColorProps>(
  ({ theme: { space } }) => css`
    width: ${space[5]}px;
    height: ${space[5]}px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    border-radius: 50%;

    ${color}
  `,
);

const OverlayButton = styled.button.attrs(() => ({ type: 'button' }))(
  ({ theme: { space, colors } }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${space[3]}px;
    height: ${space[3]}px;
    border: none;
    background: ${colors.white};
    cursor: pointer;
    outline: none;
  `,
);

const OverlayControls = styled(Overlay)<{ hide: boolean; show: boolean }>`
  align-items: flex-end;
  visibility: ${({ hide }) => (hide ? 'hidden' : 'visible')};
  opacity: ${({ show }) => (show ? 1 : 0)};
  transition: opacity 0.27s;
  & > ${OverlayButton} {
    border-right: 1px solid #f5f5fa;
    &:last-child {
      border-right: none;
    }
  }
`;

const Placeholder = styled.button.attrs(() => ({ type: 'button' }))(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed ${colors.divider};
    color: ${colors.text.secondary};
    background: none;
  `,
);

const ImageBackground = styled(BaseImageBackground)<{
  error: boolean;
  isDragging: boolean;
}>(
  ({ theme: { colors }, error, isDragging }) => css`
    background-size: cover;
    border: 1px solid ${colors.divider};

    &:hover > ${OverlayControls} {
      opacity: 1;
    }

    ${error &&
    css`
      border-color: ${colors.error.main};
    `}

    ${isDragging &&
    css`
      border: none;
    `}
  `,
);

interface IImageTileProps {
  buttonText?: string;
  icon?: ReactNode;
  controls?: ReactNode;
  formError?: boolean;
  hideControls?: boolean;
  image?: string;
  innerRef?: RefObject<any>;
  isDragging?: boolean;
  loading?: boolean;
  showControls?: boolean;
  style?: CSSProperties;
  uploadError?: string;
  onClick?: (event: MouseEvent<HTMLElement>) => void;
  onReset?: VoidFunction;
  onOpen?: VoidFunction;
}

class ImageTile extends Component<IImageTileProps, { isLoadingOver: boolean }> {
  state = { isLoadingOver: false };

  timeout: Timeout;

  componentDidUpdate(prevProps): void {
    if (
      typeof prevProps.loading === 'number' &&
      typeof this.props.loading !== 'number'
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isLoadingOver: true });
      this.timeout = setTimeout(() => {
        this.setState({ isLoadingOver: false });
      }, 700);
    }
  }

  componentWillUnmount(): void {
    clearTimeout(this.timeout);
  }

  get overlay(): JSX.Element {
    const { isLoadingOver } = this.state;
    const {
      loading,
      uploadError,
      hideControls,
      showControls,
      controls,
      onReset,
      onClick,
    } = this.props;

    if (uploadError) {
      return (
        <Overlay color='error.main'>
          <Tooltip arrow hint title={uploadError}>
            <IconBackground as='button' bg='error.main' onClick={onReset}>
              <CloseMediumIcon color='white' />
            </IconBackground>
          </Tooltip>
        </Overlay>
      );
    }

    if (typeof loading === 'number') {
      return (
        <Overlay color='text.secondary'>
          <CircleProgress
            loading={loading}
            style={{ position: 'absolute', pointerEvents: 'none' }}
          />
          <IconBackground as='button' bg='none' onClick={onReset}>
            <CloseMediumIcon color='white' size={12} />
          </IconBackground>
        </Overlay>
      );
    }

    if (isLoadingOver) {
      return (
        <Overlay color='text.secondary'>
          <IconBackground bg='primary.main'>
            <DoneIcon color='white' size={16} />
          </IconBackground>
        </Overlay>
      );
    }

    return (
      <OverlayControls
        color='divider'
        hide={!!hideControls}
        show={!!showControls}
        onClick={onClick}
      >
        {controls}
      </OverlayControls>
    );
  }

  render(): JSX.Element {
    const {
      style,
      innerRef,
      isDragging = false,
      formError,
      image,
      buttonText = 'Добавить',
      icon = <CameraIcon />,
      onOpen,
    } = this.props;

    if (!image) {
      return (
        <Placeholder ref={innerRef} style={style} onClick={onOpen}>
          {icon}
          <Text color='inherit' fontSize={2} mt={1}>
            {buttonText}
          </Text>
        </Placeholder>
      );
    }

    return (
      <ImageBackground
        {...{ style, isDragging }}
        ref={innerRef}
        error={!!formError}
        url={image}
      >
        {this.overlay}
      </ImageBackground>
    );
  }
}

const ImageTileExtension = Object.assign(ImageTile, {
  Action: OverlayButton,
});

export { ImageTileExtension as ImageTile };
