import { FC } from 'react';

import styled, { css } from 'styled-components';

import { InviteIcon } from 'resources/modal';
import { IDENTITY } from 'routes';
import { useAppSelector } from 'store';
import { Avatar, Button, Container, Modal, Text, UnorderedList } from 'UI';

import { useAuthorization } from '../../../hooks';
import { getIdentity, getUser } from '../../../store';

const DIFFERENT_EMAIL = 'DIFFERENT_EMAIL';

const TextContainer = styled.div(
  ({ theme: { space } }) => css`
    margin-bottom: ${space[5]}px;

    & > strong {
      font-weight: 500;
    }
  `,
);

interface ICompanyMemberModalProps {
  companyName: string;
  inviteEmail: string;
}

const DifferentEmailsModal: FC<ICompanyMemberModalProps> & { TYPE: string } = ({
  companyName,
  inviteEmail,
}) => {
  const identity = useAppSelector(getIdentity);
  const { fullName, avatarUrl, email } = getUser(identity);

  const { logOut } = useAuthorization();

  return (
    <Modal>
      <Modal.Default
        hideButtons
        icon={<InviteIcon />}
        title='Переход по пригласительной ссылке'
      >
        <Container mb={3}>
          <Avatar
            cover
            rounded
            letter={fullName}
            mr={1}
            size='xs'
            url={avatarUrl}
          />
          <div>
            <Text fontWeight={500}>{fullName}</Text>
            <Text fontSize={2}>{email}</Text>
          </div>
        </Container>
        <TextContainer>
          Вы перешли по личной пригласительной ссылке от компании{' '}
          <strong>{companyName}</strong>, отправленной на email{' '}
          <strong>{inviteEmail}</strong>.
          <Text fontWeight={600} mb={1} mt={3}>
            Что вы можете сделать:
          </Text>
          <UnorderedList>
            <UnorderedList.Li>
              Обратиться к компании за личным приглашением на email{' '}
              <strong>{email}</strong>
            </UnorderedList.Li>
            <UnorderedList.Li>
              <Button
                data-location={IDENTITY.REGISTRATION}
                fontSize={3}
                variant='string'
                onClick={logOut}
              >
                Зарегистрироваться
              </Button>{' '}
              под новой учетной записью <strong>{inviteEmail}</strong>.
            </UnorderedList.Li>
          </UnorderedList>
        </TextContainer>
      </Modal.Default>
    </Modal>
  );
};

DifferentEmailsModal.TYPE = DIFFERENT_EMAIL;

export { DifferentEmailsModal };
