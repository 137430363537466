import { Fragment, memo, FC } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchProducts, fetchRetailerProducts } from 'common/api/products.api';
import { useRequest } from 'common/hooks';
import { ICompany } from 'common/types/company.types';
import { ProductLifeState } from 'common/types/product.types';
import { getFormattedNumber } from 'common/utils';
import { getCompanyId } from 'entities/identity';
import { EyeIcon } from 'resources/icons/24';
import { COMPANY, getPath } from 'routes';
import { Spinner, Container, Text, IconButton, InfoBlock } from 'UI';

const MY_COMPANY_PLACEHOLDER =
  'Опубликуйте товары компании в системе или станьте поставщиком';
const DEFAULT_PLACEHOLDER =
  'Компания пока не добавила информацию о производстве или поставках товаров';

const IconsWrapper = styled.div`
  opacity: 0;
  margin-left: auto;
  transition: 0.3s ease-in-out opacity;
`;

const ProductsCountWrapper = styled.div(
  ({ theme: { colors, borderRadius, space } }) => css`
    display: inline-flex;
    align-items: center;
    padding: ${space[1]}px ${space[2]}px;
    border-radius: ${borderRadius}px;
    background: ${colors.background};
    margin-bottom: ${space[2]}px;

    &:hover ${IconsWrapper} {
      opacity: 1;
    }
  `,
);

interface IProductCountCardProps {
  count: number;
  to: string;
  title: string;
}

const ProductCountCard: FC<IProductCountCardProps> = memo(
  ({ count, to, title }) => {
    if (!count) return null;
    return (
      <ProductsCountWrapper>
        <Container column>
          <Text fontSize='22px' fontWeight={600} mb={0}>
            {getFormattedNumber(count)}
          </Text>
          <Text
            color='text.disabled'
            fontSize={0}
            fontWeight={700}
            letterSpacing='0.5px'
            textTransform='uppercase'
          >
            {title}
          </Text>
        </Container>
        <IconsWrapper>
          <IconButton as={Link} title='Перейти к товарам' to={to}>
            <EyeIcon />
          </IconButton>
        </IconsWrapper>
      </ProductsCountWrapper>
    );
  },
);

interface ICompanyCardProps {
  company: ICompany;
}
const ProductColumn: FC<ICompanyCardProps> = memo(({ company }) => {
  const REQUEST_PROPS = {
    lifeState: ProductLifeState.public,
    itemPerPage: 1,
  };

  const { isLoading, data = { totalItems: 0 } } = useRequest(fetchProducts, [
    { company: company.id, ...REQUEST_PROPS },
  ]);

  const {
    isLoading: isRetailerLoading,
    data: retailerData = { totalItems: 0 },
  } = useRequest(fetchRetailerProducts, [
    { company: company.id, ...REQUEST_PROPS },
  ]);

  const hasProductsCount = data.totalItems > 0 || retailerData.totalItems > 0;

  const userCompanyId = useSelector(getCompanyId);
  const isMyCompany = userCompanyId === company.id;

  return (
    <InfoBlock.RightColumn column pb={4} pt={9}>
      <Spinner delay={0} loading={isLoading || isRetailerLoading}>
        {hasProductsCount ? (
          <Fragment>
            <ProductCountCard
              count={data.totalItems}
              title='производство товаров'
              to={getPath(COMPANY.PRODUCTS, { id: company.id })}
            />
            <ProductCountCard
              count={retailerData.totalItems}
              title='поставка товаров'
              to={getPath(COMPANY.RETAILER_PRODUCTS, { id: company.id })}
            />
          </Fragment>
        ) : (
          <Text
            color='text.secondary'
            fontSize={5}
            fontWeight={500}
            lineHeight='150%'
            width='300px'
          >
            {isMyCompany ? MY_COMPANY_PLACEHOLDER : DEFAULT_PLACEHOLDER}
          </Text>
        )}
      </Spinner>
    </InfoBlock.RightColumn>
  );
});

export { ProductColumn };
