import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import {
  SearchMediumIcon,
  PrevMediumIcon,
  NextMediumIcon,
} from 'resources/icons/18';
import { InputContainer } from 'UI/Inputs';

export const NextIconStyled = styled(NextMediumIcon)(
  ({ theme: { colors } }) => css`
    & > svg {
      color: ${colors.primary.main} !important;
    }
  `,
);

export const PrevIconStyled = styled(PrevMediumIcon)(
  ({ theme: { colors } }) => css`
    & > svg {
      color: ${colors.primary.main} !important;
    }
  `,
);

export const SearchWrapper = styled(InputContainer)(
  ({ theme: { space } }) => css`
    min-height: 45px;
    height: 45px;
    padding: 0 ${space[4]}px;
    border-width: 0 0 1px 0;
    border-radius: 0;

    ${styledSpace}
  `,
);

export const SearchIconWrapper = styled(SearchMediumIcon)<{
  isDisabled: boolean;
}>(
  ({ theme: { colors }, isDisabled }) => css`
    svg {
      color: ${isDisabled
        ? colors.primary.disabled
        : colors.primary.main} !important;
    }
  `,
);
