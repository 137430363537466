import styled, { css } from 'styled-components';

export const MainSectionWrapper = styled.div(
  ({ theme: { colors } }) => css`
    background: ${colors.white};

    & > * {
      margin-bottom: 12px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  `,
);
