import { CSSProperties, ElementType, FC, forwardRef, ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { CustomFileProps, IDocumentFile } from 'common/types/media.types';
import { formatFileSize, getFileInfo, getFileURL } from 'common/utils';
import {
  AddMediumIcon,
  AttentionIcon,
  BrowseIcon,
  CloseMediumIcon,
  DownloadIcon,
  ReuploadIcon,
  TrashIcon,
} from 'resources/icons/18';
import { LockIcon } from 'resources/icons/24';
import { EyeIcon } from 'resources/other';

import { IconButton } from '../IconButton';
import { Spinner } from '../Indicators';
import { InputContainer } from '../Inputs';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

const FileCardContainer = styled.div<{ hovered?: boolean } & SpaceProps>(
  ({ theme: { space, colors, borderRadius }, hovered }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;

    ${hovered &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        left: -${space[0]}px;
        right: -${space[0]}px;
        top: -${space[0]}px;
        bottom: -${space[0]}px;
        border-radius: ${borderRadius}px;
        transition: background 200ms ease-in;
      }

      &:hover {
        &::before {
          background: ${colors.highlight[0]};
        }
      }
    `}

    ${styledSpace};
  `,
);

const FileDownloadContainer = styled(InputContainer)(
  ({ theme: { colors } }) => css`
    background: ${colors.highlight[0]};

    &:focus-within {
      border-color: ${colors.divider};

      &:active {
        border-color: ${colors.text.primary};
      }
    }

    ${styledSpace};
  `,
);

const ProgressBarWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    width: 100%;
    height: 6px;
    min-width: 0;
    border-radius: 6px;
    overflow: hidden;
    color: ${colors.primary.main};
    background: ${colors.highlight[1]};
  `,
);

const StyledEyeIcon = styled(EyeIcon)`
  display: none;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

const ExtensionWrapper = styled.div<{
  error?: boolean;
  primary?: boolean;
  preview?: boolean;
  disabled?: boolean;
}>(
  ({
    theme: { colors, space, borderRadius },
    preview,
    error,
    primary,
    disabled,
  }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${space[6]}px;
    min-width: ${space[6]}px;
    height: ${space[6]}px;
    margin-right: ${space[2]}px;
    background-color: ${colors.background};
    border-radius: ${borderRadius}px;
    border: 1px solid ${colors.divider};
    transition: background-color 200ms ease-in;

    ${primary &&
    css`
      background: ${colors.primary.main};
      border: none;
      cursor: pointer;

      &:hover {
        background: ${colors.primary.dark};
      }
    `}

    ${error &&
    css`
      background: ${colors.error.main};
      border: none;
    `}

    ${preview &&
    css`
      &:hover > ${StyledEyeIcon} {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1;
        cursor: pointer;
      }

      &:hover::after {
        content: '';
        position: absolute;
        display: block;
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0.8);
      }
    `}

    ${disabled &&
    css`
      background: ${colors.highlight[1]};
      cursor: default;

      &:hover {
        background: ${colors.highlight[1]};
      }
    `}
  `,
);

const MarkWrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 0;
`;

const ProgressBackground = styled.div<{ loading?: number }>(
  ({ theme: { colors }, loading }) => css`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: ${loading ? `${loading}%` : 0};
    background: ${colors.primary.main};
    transition: width 100ms ease-in;
    border-radius: 6px;
  `,
);

const ActionsWrapper = styled.div<{ hovered?: boolean }>(
  ({ theme: { space }, hovered }) => css`
    display: flex;
    gap: ${space[0]}px;
    margin-left: ${space[2]}px;

    ${hovered &&
    css`
      visibility: hidden;

      ${FileCardContainer}:hover & {
        visibility: visible;
      }
    `}
  `,
);

const EmptyLine = styled.div<{ width: string }>(
  ({ theme: { colors, borderRadius }, width }) => css`
    width: ${width};
    height: 17px;
    margin-top: 4px;
    background-color: ${colors.background};
    border-radius: ${borderRadius}px;
  `,
);

const FlexGrowWrapper = styled.div`
  position: relative;
  flex-grow: 1;
  min-width: 0;
`;

const PreviewImage = styled.img`
  object-fit: cover;
`;

const imageExtensionsRegExp = /(gif|jpe?g|tiff?|png|webp|bmp)$/i;

const getFileName = (file: IDocumentFile | File): string => {
  if ('filename' in file) {
    return file.filename;
  }

  return file.name;
};

interface IFileCardProps {
  className?: string;
  style?: CSSProperties;
  primary?: boolean;
  error?: boolean;
  hovered?: boolean;
  disabled?: boolean;
  preview?: boolean;
  title?: string;
  linkProps?: { as: ElementType; href: string; target: string };
  fileInfo: ReactElement;
  mark?: ReactNode;
  children: ReactNode;
  onClick?: VoidFunction;
}

/**
 * Отображает общую обертку информации о файле
 */
const FileCard = forwardRef<HTMLDivElement, IFileCardProps & SpaceProps>(
  (
    {
      style,
      className,
      error,
      primary,
      hovered,
      preview,
      disabled,
      title,
      linkProps,
      fileInfo,
      mark,
      children,
      onClick,
      ...other
    },
    ref,
  ) => {
    return (
      <FileCardContainer
        ref={ref}
        className={className}
        hovered={hovered}
        style={style}
        {...other}
      >
        <Tooltip hint title={title}>
          <ExtensionWrapper
            disabled={disabled}
            error={error}
            preview={preview}
            primary={primary}
            {...(linkProps ?? {})}
            onClick={onClick}
          >
            {fileInfo}
            {mark && <MarkWrapper>{mark}</MarkWrapper>}
            {preview && <StyledEyeIcon color='primary.main' />}
          </ExtensionWrapper>
        </Tooltip>
        {children}
      </FileCardContainer>
    );
  },
);

interface IFileSlotProps {
  className?: string;
  style?: CSSProperties;
  actions?: ReactNode;
  name: ReactNode;
  disabled?: boolean;
  description?: string;
  error?: string;
  onAddFile?: VoidFunction;
  onRemove?: VoidFunction | false;
}

const FileSlot = forwardRef<HTMLDivElement, IFileSlotProps & SpaceProps>(
  (
    {
      className,
      style,
      actions,
      name,
      error,
      disabled,
      description,
      onAddFile,
      onRemove,
      ...other
    },
    ref,
  ) => {
    return (
      <FileCard
        ref={ref}
        primary
        className={className}
        disabled={disabled}
        fileInfo={
          <AddMediumIcon color={disabled ? 'primary.disabled' : 'white'} />
        }
        hovered={!disabled}
        style={style}
        title='Загрузить документ'
        onClick={onAddFile}
        {...other}
      >
        <FlexGrowWrapper>
          <Text truncate fontWeight={500} mb={0}>
            {name}
          </Text>
          {error ? (
            <Text truncate color='error.main' fontSize={2}>
              {error}
            </Text>
          ) : (
            <Text color='text.secondary' fontSize={2}>
              {description}
            </Text>
          )}
        </FlexGrowWrapper>
        <ActionsWrapper>
          {actions}
          {onRemove && (
            <IconButton size={34} title='Удалить' onClick={onRemove}>
              <TrashIcon />
            </IconButton>
          )}
        </ActionsWrapper>
      </FileCard>
    );
  },
);

interface IFileLoadingProps {
  className?: string;
  style?: CSSProperties;
  file: IDocumentFile | File;
  error: any;
  loading?: number;
  actions?: ReactNode;
  mark?: ReactNode;
  onCancel: VoidFunction;
  onReupload?: VoidFunction;
}

/**
 * Отображает прогресс загрузки файла
 */
const FileLoading = forwardRef<HTMLDivElement, IFileLoadingProps & SpaceProps>(
  (
    {
      style,
      className,
      error,
      file,
      loading,
      actions,
      mark,
      onCancel,
      onReupload,
      ...other
    },
    ref,
  ) => {
    const isError = !!error;
    const fileName = getFileName(file);
    const { name, extension } = getFileInfo(fileName);

    const fileInfo = isError ? (
      <AttentionIcon color='white' />
    ) : (
      <Text truncate color='text.secondary' fontSize={2} fontWeight={600}>
        .{extension}
      </Text>
    );

    return (
      <FileCard
        ref={ref}
        className={className}
        error={isError}
        fileInfo={fileInfo}
        mark={mark}
        style={style}
        {...other}
      >
        <FlexGrowWrapper>
          <Text truncate fontWeight={500} mb='3px'>
            {name}
          </Text>
          {isError ? (
            <Text truncate color='error.main' fontSize={2}>
              {error}
            </Text>
          ) : (
            <ProgressBarWrapper>
              <ProgressBackground loading={loading} />
            </ProgressBarWrapper>
          )}
        </FlexGrowWrapper>
        <ActionsWrapper>
          {actions}
          {isError && onReupload && (
            <IconButton
              size={34}
              title='Повторить загрузку'
              onClick={onReupload}
            >
              <ReuploadIcon />
            </IconButton>
          )}
          <IconButton size={34} title='Отменить загрузку' onClick={onCancel}>
            <CloseMediumIcon />
          </IconButton>
        </ActionsWrapper>
      </FileCard>
    );
  },
);

interface IFileLoadedProps {
  className?: string;
  style?: CSSProperties;
  file: IDocumentFile | File;
  error?: any;
  initialName?: string | false;
  download?: boolean;
  loading?: boolean;
  disabled?: boolean;
  preview?: boolean;
  hovered?: boolean;
  description?: string | false;
  customFileProps?: CustomFileProps;
  actions?: ReactNode;
  mark?: ReactNode;
  onRemove?: VoidFunction;
  onClick?: VoidFunction;
}

/**
 * Отображает информацию о загруженном файле
 */
const FileLoaded = forwardRef<HTMLDivElement, IFileLoadedProps & SpaceProps>(
  (
    {
      style,
      className,
      file,
      error,
      initialName,
      download,
      disabled,
      loading,
      customFileProps = {},
      preview,
      description,
      actions,
      hovered,
      mark,
      onRemove,
      onClick,
      ...other
    },
    ref,
  ) => {
    const fileName = getFileName(file);
    const { name, extension } = getFileInfo(fileName);

    const isError = !!error;
    const href = customFileProps.href || getFileURL(file);
    const hasAccessExtension =
      customFileProps.hasAccessExtension ||
      extension.match(imageExtensionsRegExp);
    const linkProps =
      href && preview
        ? { as: 'a' as ElementType, href, target: '_blank', ...customFileProps }
        : undefined;

    let fileInfo = (
      <Text truncate color='text.secondary' fontSize={2} fontWeight={600}>
        .{extension}
      </Text>
    );

    if (loading) {
      fileInfo = <Spinner delay={0} size='xs' />;
    } else if (preview && hasAccessExtension) {
      fileInfo = <PreviewImage alt={fileName} src={href} />;
    }

    return (
      <FileCard
        ref={ref}
        className={className}
        fileInfo={fileInfo}
        hovered={hovered}
        linkProps={linkProps}
        mark={mark}
        preview={preview}
        style={style}
        onClick={onClick}
        {...other}
      >
        <FlexGrowWrapper>
          <Text
            truncate
            color='text.primary'
            fontWeight={500}
            mb='3px'
            {...(preview && linkProps)}
          >
            {initialName || name}
          </Text>
          <Text
            truncate
            color={isError ? 'error.main' : 'text.secondary'}
            fontSize={2}
          >
            {isError
              ? error
              : `${formatFileSize(file.size)}${
                  description ? ` • ${description}` : ''
                }`}
          </Text>
        </FlexGrowWrapper>
        <ActionsWrapper hovered={hovered}>
          {actions}
          {download && (
            <IconButton
              download
              as='a'
              href={getFileURL(file)}
              size={34}
              title='Скачать'
            >
              <DownloadIcon />
            </IconButton>
          )}
          {!disabled && !!onRemove && (
            <IconButton size={34} title='Удалить' onClick={onRemove}>
              <TrashIcon />
            </IconButton>
          )}
        </ActionsWrapper>
      </FileCard>
    );
  },
);

/**
 * Отображает файл без доступа к нему
 */
const FileNotAccess: FC<SpaceProps> = ({ ...other }) => {
  return (
    <FileCard fileInfo={<LockIcon color='text.disabled' />} {...other}>
      <FlexGrowWrapper>
        <EmptyLine width='67%' />
        <EmptyLine width='34%' />
      </FlexGrowWrapper>
    </FileCard>
  );
};

interface IFileDownloadProps {
  className?: string;
  disabled?: boolean;
  title?: string;
  onOpen: VoidFunction;
}

/**
 * Отображает поле инпут для добавления файла
 */
const FileDownload: FC<IFileDownloadProps & SpaceProps> = ({
  className,
  disabled,
  title = 'Загрузить',
  onOpen,
  ...other
}) => {
  return (
    <FileDownloadContainer className={className} disabled={disabled} {...other}>
      <Text color='text.disabled' ml={1}>
        Выбрать файл
      </Text>
      <IconButton
        disabled={disabled}
        ml='auto'
        mr='2px'
        size={28}
        style={{ backgroundColor: 'white' }}
        title={title}
        onClick={onOpen}
      >
        <BrowseIcon />
      </IconButton>
    </FileDownloadContainer>
  );
};

interface IFileInputProps {
  className?: string;
  disabled?: boolean;
  error?: any;
  loading?: number;
  file?: IDocumentFile | File;
  onOpen: VoidFunction;
  onRemove?: VoidFunction;
}

/**
 * Объединяет отображаение FileDownload, FileLoaded, FileLoading
 */
const FileInput: FC<IFileInputProps & SpaceProps> = ({
  className,
  disabled,
  error,
  loading,
  file,
  onOpen,
  onRemove,
  ...other
}) => {
  if (!!file && (loading || error)) {
    return (
      <FileLoading
        className={className}
        error={error}
        file={file}
        loading={loading}
        onCancel={onRemove!}
      />
    );
  }

  if (file) {
    return (
      <FileLoaded
        className={className}
        disabled={disabled}
        file={file}
        onRemove={onRemove!}
      />
    );
  }

  return (
    <FileDownload
      className={className}
      disabled={disabled}
      onOpen={onOpen}
      {...other}
    />
  );
};

export {
  FileSlot,
  FileLoading,
  FileLoaded,
  FileNotAccess,
  FileDownload,
  FileInput,
};
