import { FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getPath, GUIDES } from 'routes';

import { IGuide } from '../../types';

const GuideSectionWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: 50% 50%;
    row-gap: ${space[2]}px;
    column-gap: ${space[3]}px;
    margin-top: ${space[2]}px;
  `,
);

const GuideSection = styled(Link)(
  ({ theme: { colors, fontSizes } }) => css`
    width: max-content;
    font-size: ${fontSizes[5]}px;
    color: ${colors.text.secondary};
    word-break: break-word;
  `,
);

const GuidesBlock: FC<{ guides: IGuide[] }> = ({ guides }) => {
  return (
    <GuideSectionWrapper>
      {guides.map(({ id, category, title }) => (
        <GuideSection
          key={id}
          to={getPath(GUIDES.CARD, { categoryId: category, guideId: id })}
        >
          {title}
        </GuideSection>
      ))}
    </GuideSectionWrapper>
  );
};

export { GuidesBlock };
