import { useState, useCallback, memo, useRef, FC } from 'react';

import { PatternFormat } from 'react-number-format';
import styled, { css } from 'styled-components';

import {
  LINK_SOGLASHENIE,
  LINK_POLITIKA_KONFIDENTSIALNOSTI,
} from 'entities/identity';
import { useModalContext } from 'entities/modals';
import { Field, Reform, IReform } from 'reform';
import { CloseIcon } from 'resources/starting';
import { Text, Modal, Container, TextAreaField, TextInputField } from 'UI';
import { StyledIconButton } from 'UI/Modal/templates/styled';
import theme from 'UI/theme';

import { INITIAL_FORM_VALUE } from './constants';
import { getDescription } from './utils';
import { createIssues } from '../../../../../src/features/support/api';
import { TEXT_PROPS } from '../constants';
import { ImpulseButton } from '../styled';

const NotifyWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: fixed;
    top: 50%;
    left: 50%;
    width: 600px;
    padding: 46px;
    background: ${colors.white};
    border-radius: 8px;
    transform: translate(-50%, -50%);
  `,
);

const EMAIL_LIMIT = 5;
const TELEPHONE_LIMIT = 11;
const MODAL_STYLE = { fontFamily: 'Commissioner, sans-serif' };
const BUTTON_STYLE = { borderRadius: '8px', height: '54px' };
const FIELD_STYLE = {
  fontSize: `${theme.fontSizes[7]}px`,
  fontWeight: 400,
  borderRadius: '8px',
};
const TEXT_FIELD_PROPS = { ...FIELD_STYLE, height: '46px', mb: 0 };
const INITIAL_ERRORS = {
  fullName: false,
  contacts: false,
  telephone: false,
  email: false,
};

const Label: FC<{ text: string }> = ({ text }) => (
  <Container {...TEXT_PROPS['16']} fontWeight={400} mb='20px'>
    <Text>{text}</Text> <Text color='error.main'>*</Text>
  </Container>
);

const Form: FC<{
  title: string;
  onSubmit: (data: typeof INITIAL_FORM_VALUE) => void;
  onRequestClose?: VoidFunction;
}> = ({ title, onSubmit, onRequestClose }) => {
  const reformRef = useRef<IReform>(null);

  const handleClick = (): void => {
    const data: typeof INITIAL_FORM_VALUE = reformRef.current!.getData();
    const errors = { ...INITIAL_ERRORS };
    const numbersInTelephone = data.telephone.match(/\d/g);

    if (data.fullName.length === 0) errors.fullName = true;

    if (!numbersInTelephone && data.email.length === 0) {
      errors.contacts = true;
    } else {
      if (numbersInTelephone && numbersInTelephone.length < TELEPHONE_LIMIT) {
        errors.telephone = true;
      }

      if (data.email && data.email.length < EMAIL_LIMIT) errors.email = true;
    }

    if (Object.values(errors).some(value => value)) {
      const errorText = 'Заполните поле телефон или email';

      reformRef.current!.setErrors({
        ...(errors.fullName && { fullName: 'Заполните поле ФИО' }),
        ...(errors.contacts && { telephone: errorText, email: errorText }),
        ...(errors.telephone && { telephone: 'Некорректный телефон' }),
        ...(errors.email && { email: 'Некорректный email' }),
      });

      return;
    }

    onSubmit(data);
  };

  return (
    <Container column p={5}>
      <Text {...TEXT_PROPS['24']} mb={2}>
        {title}
      </Text>
      <Text {...TEXT_PROPS['20b']} fontWeight={400} mb='20px'>
        Оставьте сообщение, и мы расскажем подробнее об{' '}
        <b>impuls.istock.info</b>
      </Text>
      <Reform ref={reformRef} initialValues={INITIAL_FORM_VALUE}>
        <Label text='ФИО' />
        <Field
          {...TEXT_FIELD_PROPS}
          borderRadius='8px'
          component={TextInputField}
          name='fullName'
          placeholder='Ваше имя и фамилия'
        />
        <Field
          {...TEXT_FIELD_PROPS}
          component={TextInputField}
          name='company'
          placeholder='Компания'
        />
        <Label text='Способ связи' />
        <Field
          name='telephone'
          render={field => (
            <PatternFormat
              {...TEXT_FIELD_PROPS}
              customInput={TextInputField}
              // @ts-ignore
              field={field}
              format='+7 (###) ###-##-##'
              mask='_'
              placeholder='Телефон'
              onValueChange={({ formattedValue }) => {
                field.setValue(formattedValue);
              }}
            />
          )}
        />
        <Field
          {...TEXT_FIELD_PROPS}
          component={TextInputField}
          name='email'
          placeholder='Email'
        />
        <Field
          component={TextAreaField}
          height='107px'
          mb={1}
          name='comment'
          placeholder='Комментарий, например, дата и удобное время для демонстрации'
          style={FIELD_STYLE}
        />
        <Text color='text.secondary' fontSize={5} fontWeight={400} mb={3}>
          Нажимая кнопку «Отправить», вы принимаете условия{' '}
          <a href={LINK_SOGLASHENIE} rel='noopener noreferrer' target='_blank'>
            Пользовательского соглашения
          </a>{' '}
          и даете свое согласие на обработку вашей персональной информации на
          условиях, определенных{' '}
          <a
            href={LINK_POLITIKA_KONFIDENTSIALNOSTI}
            rel='noopener noreferrer'
            target='_blank'
          >
            Политикой конфиденциальности
          </a>
        </Text>
        <Container alignItems='center' justifyContent='space-between'>
          <Container fontSize={5} fontWeight={400}>
            <Text color='error.main'>*</Text> Поля, обязательные для заполнения
          </Container>
          <ImpulseButton
            fontSize='18px'
            fontWeight={500}
            style={BUTTON_STYLE}
            width='132px'
            onClick={handleClick}
          >
            Отправить
          </ImpulseButton>
        </Container>
      </Reform>
      <StyledIconButton size={46} onClick={onRequestClose}>
        <CloseIcon />
      </StyledIconButton>
    </Container>
  );
};

const ProductModal: FC<{
  title: string;
}> = memo(({ title }) => {
  const { handleModalClose } = useModalContext();

  const [isShowNotify, setShowNotify] = useState(false);

  const handleSubmit = useCallback(
    (data: typeof INITIAL_FORM_VALUE) => {
      createIssues({
        section: '/api/issue-sections/start-istock-info-page',
        reason: 'Иная',
        title: `Стартовая страница - ${title.toLowerCase()}`,
        email: 'support@istock.info',
        description: getDescription(data),
      }).then(() => setShowNotify(true));
    },
    [title],
  );

  return (
    <Modal style={MODAL_STYLE} width={600}>
      {isShowNotify ? (
        <NotifyWrapper>
          <Text {...TEXT_PROPS['24']} mb={2}>
            Заявка отправлена
          </Text>
          <Text {...TEXT_PROPS['20']} fontWeight={400}>
            Мы обязательно свяжемся с вами и поможем найти решение для вашего
            бизнеса
          </Text>
          <StyledIconButton size={46} onClick={handleModalClose}>
            <CloseIcon />
          </StyledIconButton>
        </NotifyWrapper>
      ) : (
        <Form title={title} onSubmit={handleSubmit} />
      )}
    </Modal>
  );
});

export { ProductModal };
