import styled, { css } from 'styled-components';

import { Button, Container } from 'UI';

import { MAX_WIDTH } from './constants';
import impulseTheme from '../../../theme/theme';

export const ImpulseButton = styled(Button)`
  background: ${impulseTheme.colors.primary.main};

  &:hover {
    background: ${impulseTheme.colors.primary.dark};
  }
`;

export const Banner = styled(Container)`
  position: relative;
  width: 100%;
  max-width: ${MAX_WIDTH};
  border-radius: 28px;
  background: ${impulseTheme.colors.primary.main};
`;

export const TabButton = styled.button.attrs(
  ({ isSearch, role = 'tab' }: { isSearch: boolean; role: string }) => ({
    isSearch,
    role,
  }),
)(
  ({ theme: { colors, space, borderRadius }, isSearch }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    padding: ${space[2]}px;
    font-size: 18px;
    font-weight: 500;
    color: ${isSearch ? colors.white : colors.text.secondary};
    border-radius: ${borderRadius}px;
    transition: all ease-in 200ms;

    &:hover {
      color: ${colors.text.primary};
      background: ${colors.highlight[0]};

      ${isSearch &&
      css`
        color: ${colors.white};
      `}
    }

    &[aria-selected='true'] {
      color: ${colors.primary.main};
      background: ${colors.highlight[1]};

      ${isSearch &&
      css`
        color: ${colors.white};
      `}
    }
  `,
);
