import { FC, Fragment, memo, useRef } from 'react';

import { useTheme } from 'styled-components';

import { fetchLocationOptions } from 'common/api/external';
import { EMPTY_ARRAY } from 'common/constants/emptyDataStructures.const';
import { useOutsideRef } from 'common/hooks';
import { ISearchParams } from 'common/types/search.types';
import { getFormattedNumber } from 'common/utils';
import { getSuggestText } from 'common/utils/external/yandex-api';
import { Field, useReform, useWatch } from 'reform';
import {
  ArrowDown1pxIcon,
  ArrowUp1pxIcon,
  Delete1pxIcon,
} from 'resources/icons/1px-12';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { useToggle } from 'tools/hooks';
import {
  Button,
  Container,
  Divider,
  IconButton,
  MultiSelectField,
  Relative,
  Tooltip,
} from 'UI';

import { FiltersButton, FiltersPanel } from './styled';

const LOCATION_ADDRESSES_FIELD_NAME = 'locationAddresses';

interface ILocationFilterProps {
  locationAddresses: string[];
  onSubmit: (props?: Pick<ISearchParams, 'locationAddresses'>) => void;
}

const LocationFilter: FC<ILocationFilterProps> = memo(
  ({ locationAddresses, onSubmit }) => {
    const theme = useTheme();
    const locationButtonRef = useRef<HTMLDivElement>(null);

    const { setFieldValue } = useReform();
    const locations = useWatch({ fieldName: LOCATION_ADDRESSES_FIELD_NAME });
    const hasFilters = locationAddresses.length > 0;

    const [isLocationOpened, setLocationOpened] = useToggle();

    const handleCloseLocations = (): void => {
      if (isLocationOpened) setLocationOpened();
    };

    useOutsideRef({
      elementRef: locationButtonRef,
      onOutsideClick: handleCloseLocations,
    });

    const handleResetLocation = (): void => {
      setFieldValue(LOCATION_ADDRESSES_FIELD_NAME, EMPTY_ARRAY);
      onSubmit();
    };

    return (
      <Relative ref={locationButtonRef}>
        <FiltersButton hasFilters={hasFilters} onClick={setLocationOpened}>
          Местоположение
          <Container alignItems='center' ml={0}>
            {hasFilters ? (
              <Fragment>
                {getFormattedNumber(locationAddresses.length)}
                <IconButton
                  ml={0}
                  size={18}
                  title='Очистить'
                  variant='white'
                  onClick={handleResetLocation}
                >
                  <Delete1pxIcon color={theme.colors.white} />
                </IconButton>
              </Fragment>
            ) : (
              <Fragment>
                {isLocationOpened ? (
                  <ArrowUp1pxIcon ml={0} />
                ) : (
                  <ArrowDown1pxIcon ml={0} />
                )}
              </Fragment>
            )}
          </Container>
        </FiltersButton>
        <FiltersPanel isShow={isLocationOpened}>
          <Field
            component={MultiSelectField}
            itemToKey={getSuggestText}
            itemToString={getSuggestText}
            label={
              <Container alignItems='center' gap={0}>
                Местоположение
                <Tooltip
                  arrow
                  maxWidth={300}
                  title='Поиск осуществляется с точностью до города'
                >
                  <Help1pxIcon />
                </Tooltip>
              </Container>
            }
            maxHeight={340}
            mt={1}
            name={LOCATION_ADDRESSES_FIELD_NAME}
            options={fetchLocationOptions}
            placeholder='Страна, регион, населенный пункт'
          />
          <Divider mb={2} mt='0px' mx={-2} />
          <Button
            disabled={locations.length === 0}
            onClick={() => {
              onSubmit({ locationAddresses: locations });
            }}
          >
            Применить
          </Button>
        </FiltersPanel>
      </Relative>
    );
  },
);
export { LocationFilter };
