import { Children, cloneElement, CSSProperties, forwardRef, Fragment, ReactElement, useRef } from 'react';

import styled, { css } from 'styled-components';
import { FlexboxProps, SpaceProps } from 'styled-system';

import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { useForkRef } from 'tools/hooks';

import { Button, ButtonProps } from './Button';
import { ContextMenu, useContextMenu } from '../ContextMenu';

const ButtonStyled = styled(Button)(
  ({ theme: { colors, borderRadius, space }, size, active, disabled }) => css`
    background: transparent;

    ${active &&
    css`
      color: ${colors.primary.main};

      &:hover,
      & svg {
        background: transparent;
        color: ${colors.primary.main};
      }
    `}

    &:hover {
      &::before {
        background: ${colors.primary.dark};
      }
    }

    &::before {
      content: '';
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: ${size === 'l' ? space[5] : space[4]}px;
      border-radius: ${borderRadius}px;
      background: ${colors.primary.main};
      transition: 0.3s ease all;

      ${active &&
      css`
        height: 2px;
        border-radius: 0;
      `}

      ${disabled &&
      css`
        background: ${colors.divider};
        cursor: not-allowed;
        pointer-events: none;
      `}
    }
  `,
);

const ButtonStyledText = styled.span`
  display: flex;
  position: relative;
  z-index: 1;
`;

interface IButtonOptions {
  className?: string;
  style?: CSSProperties;
  /**
   * Text for button options
   */
  buttonText: string | JSX.Element;
  children: ReactElement | (ReactElement | false)[];
  /**
   * Outline options style or buttons style
   */
  type?: 'outline' | 'default';
  active?: boolean;
  /**
   * Disabled status
   */
  disabled?: boolean;
  /**
   * Height 46 for 'l' and 34 for other
   */
  size?: 's' | 'm' | 'l';
  /**
   * Width of button
   */
  width?: number | string;
  contextMenuWidth?: number | string;
  showChevron?: boolean;
}

const ButtonOptions = forwardRef<
  HTMLDivElement,
  IButtonOptions &
    SpaceProps &
    Pick<ButtonProps, 'color' | 'variant'> &
    FlexboxProps
>(
  (
    {
      className,
      style,
      buttonText,
      children,
      type,
      active,
      disabled,
      size,
      width,
      contextMenuWidth,
      showChevron = true,
      ...other
    },
    containerRef,
  ): JSX.Element => {
    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();
    const buttonRef = useRef<HTMLDivElement>(null);
    const isOutline = type === 'outline';
    const ButtonType = isOutline ? Button : ButtonStyled;
    const iconColor = isOutline ? 'primary.main' : 'white';
    const disabledIconColor = isOutline ? 'primary.disabled' : 'text.disabled';

    const ref = useForkRef(containerRef, buttonRef);

    return (
      <Fragment>
        <ButtonType
          ref={ref}
          active={Boolean(anchorEl)}
          className={className}
          disabled={disabled}
          size={size}
          style={style}
          variant={isOutline ? 'outlined' : 'filled'}
          width={width}
          onClick={open ? handleContextMenuClose : handleContextMenuOpen}
          {...other}
        >
          <ButtonStyledText>{buttonText}</ButtonStyledText>
          {showChevron && open && (
            <ArrowUp1pxIcon
              color={disabled ? disabledIconColor : iconColor}
              ml={1}
            />
          )}
          {showChevron && !open && (
            <ArrowDown1pxIcon
              color={disabled ? disabledIconColor : iconColor}
              ml={1}
            />
          )}
        </ButtonType>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          width={contextMenuWidth ?? `${buttonRef.current?.clientWidth}px`}
          onRequestClose={handleContextMenuClose}
        >
          {Children.map(children, child => {
            if (!child) return null;

            const { onClick, ...props } = child.props;

            const handleClick = (event): void => {
              if (!onClick) return;
              onClick(event);
            };

            return cloneElement(child, {
              ...props,
              onClick: handleClick,
            });
          })}
        </ContextMenu>
      </Fragment>
    );
  },
);

export { ButtonOptions };
