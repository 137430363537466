import { memo, FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ARTICLES_TYPES_NAME } from 'common/constants/articles.const';
import { IArticle } from 'common/types/company-management';
import { formatDate, getFileURL } from 'common/utils';
import { getPath, COMPANY } from 'routes';
import { Text, Avatar, Tags, Tooltip } from 'UI';

import { CardInfo, CardTitle, CardWrapper } from './CompanySectionCard';

const TagsWrapper = styled(Tags)(
  ({ theme: { space, fontSizes } }) => css`
    font-size: ${fontSizes[1]}px;
    height: ${space[2]}px;
  `,
);

const ArticlesWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(3, minmax(32%, 1fr));
    margin-bottom: ${space[2]}px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, minmax(392px, 1fr));
    }
  `,
);

const Article: FC<IArticle> = memo(
  ({ id, title, type, textPreview, image, publishedAt, tags }) => (
    <CardWrapper>
      <CardInfo>
        <Avatar
          cover
          full
          as={Link}
          borderRadius={4}
          mb={2}
          size='xl'
          target='_blank'
          to={getPath(COMPANY.ARTICLE_PAGE, { id })}
          url={getFileURL(image)}
        />
        <Text
          fontSize={0}
          fontWeight={500}
          letterSpacing='0.5px'
          textTransform='uppercase'
        >
          {ARTICLES_TYPES_NAME[type]} ·
          <Text as='span' color='text.secondary' pl={0}>
            {formatDate(publishedAt, false, 'd MMMM yyyy')}
          </Text>
        </Text>
        <Tooltip hint placement='bottom' title={title}>
          <CardTitle as={Link} to={getPath(COMPANY.ARTICLE_PAGE, { id })}>
            {title}
          </CardTitle>
        </Tooltip>
        <Tooltip hint placement='bottom' title={textPreview}>
          <Text lineClamp color='text.primary' mb={2} mt={1}>
            {textPreview}
          </Text>
        </Tooltip>
        <TagsWrapper
          arrow
          expandable
          fontSize={2}
          itemToIcon='#'
          limit={4}
          tags={tags}
        />
      </CardInfo>
    </CardWrapper>
  ),
);

const ArticlesList: FC<{ articles: IArticle[] }> = ({ articles }) => (
  <ArticlesWrapper>
    {articles.map(item => (
      <Article key={item.id} {...item} />
    ))}
  </ArticlesWrapper>
);

export { ArticlesList };
