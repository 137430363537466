import { CSSProperties, FC, Fragment, memo } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { EditMediumIcon } from 'resources/icons/18';
import { IMapCategory } from 'common/types/categories.types';
import { formatDate } from 'common/utils';
import { ViewTemplates } from 'components/characteristic-elements/ViewMode';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { CATALOGS_AND_GUIDES, getPath } from 'routes';
import { useToggle } from 'tools/hooks';
import { Container, IconButton, Status, Text } from 'UI';

import { CollapsePanel } from '../../components';

const OVERFLOW_HIDDEN_STYLE = { overflowX: 'hidden' } as CSSProperties;

const StyledCollapsePanel = styled(CollapsePanel)(
  ({ theme: { space, colors } }) => css`
    column-gap: ${space[2]}px;
    height: 70px;
    padding: 0 ${space[4]}px 0 ${space[2]}px;
    color: ${colors.text.primary};
    background: ${colors.white};
  `,
);

interface ICategoryProps {
  isSearch: boolean;
  category: IMapCategory;
  categoriesMap: Map<string, IMapCategory>;
}

const Category: FC<ICategoryProps> = memo(
  ({ isSearch, category: { id, name, children, updateAt }, categoriesMap }) => {
    const [isCollapse, toggleCollapse] = useToggle(!isSearch);

    return (
      <Fragment>
        <StyledCollapsePanel>
          {children.length > 0 && (
            <IconButton onClick={toggleCollapse}>
              {isCollapse ? <ArrowDownIcon /> : <ArrowUpIcon />}
            </IconButton>
          )}
          <Container column flexGrow={1} style={OVERFLOW_HIDDEN_STYLE}>
            <Text truncate mb='2px'>
              {name}
            </Text>
          </Container>
          <Container justifyContent='center' minWidth={218}>
            <Status />
          </Container>
          <Container justifyContent='center' minWidth={111}>
            {formatDate(updateAt)}
          </Container>
          <Container justifyContent='center' width={50}>
            <IconButton
              as={Link}
              title='Дополнить'
              to={getPath(CATALOGS_AND_GUIDES.CATEGORIES_EDIT, { id })}
            >
              <EditMediumIcon />
            </IconButton>
          </Container>
        </StyledCollapsePanel>
        {!isCollapse && children.length > 0 && (
          <ViewTemplates.LevelBody backgroundColor='transparent' ml={30}>
            <Container column gap={1}>
              {children.map(child => {
                return (
                  <Category
                    key={child}
                    categoriesMap={categoriesMap}
                    category={categoriesMap.get(child)!}
                    isSearch={isSearch}
                  />
                );
              })}
            </Container>
          </ViewTemplates.LevelBody>
        )}
      </Fragment>
    );
  },
);

export { Category };
