import { css, createGlobalStyle } from 'styled-components';

import theme, { colors } from 'UI/theme';

const globalStyles = css`
  * {
    box-sizing: border-box;
    &:focus {
      outline: none;
    }
  }

  body {
    min-height: 100vh;
    background: ${colors.background};
    font-size: ${theme.fontSizes[3]}px;
    color: ${colors.text.primary};
    line-height: 1.5;
    font-family: Montserrat, Verdana, Helvetica, sans-serif;
    text-rendering: optimizeSpeed;
    font-smooth: always;
    -moz-osx-font-smoothing: grayscale;
    -moz-font-smoothing: antialiased;
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
    font-weight: 500;
  }

  /* Works on Chrome/Edge/Safari */
  *::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }

  *::-webkit-scrollbar-track {
    background: transparent;
  }

  *::-webkit-scrollbar-thumb {
    background: ${colors.text.disabled};
    border-radius: 6px;
    border: 1px solid transparent;
  }

  *::-webkit-scrollbar-corner {
    background: transparent;
  }

  button,
  textarea,
  [type='text'],
  [type='button'],
  [type='reset'],
  [type='password'],
  [type='number'],
  [type='submit'] {
    outline: none;
    -webkit-appearance: none;

    &:focus {
      outline: none;
    }
  }

  button {
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
  }

  input {
    border: 0;
    font-weight: inherit;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  button,
  input,
  optgroup,
  select,
  textarea {
    line-height: 1.5;
    font-weight: inherit;
  }

  img {
    max-width: 100%;
  }

  dt,
  dd,
  dl {
    margin: 0;
    padding: 0;
  }

  a {
    color: ${colors.primary.main};
    text-decoration: none;
    transition: 0.1s ease-in-out color;
  }

  a:hover {
    color: ${colors.primary.dark};
  }

  a:active {
    color: ${colors.text.primary};
  }

  strong,
  b {
    font-weight: 600;
  }

  hr {
    background: #eeeeee;
    border: none;
    height: 2px;
    width: 100%;
    margin-top: -14px;
  }

  hr.separate {
    margin: 40px 0;
  }
`;

const GlobalStyles = createGlobalStyle`${globalStyles}`;

export { GlobalStyles };
