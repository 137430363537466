import { FC, memo, useCallback, useState } from 'react';

import { format as formatDateFns } from 'date-fns';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { changeProcessingStatus } from 'common/api/notifications.api';
import { LINK_INTEGRATION_PATH } from 'common/constants/link-integration.const';
import {
  EXPORT,
  IDENTITY,
  MARKETPLACE,
  PARTNER,
} from 'common/constants/notifications.const';
import { useQueryParams, useTimer } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { InvitationalStatuses } from 'common/types/employees.types';
import {
  INotification,
  NotificationsSections,
  LetterTemplate,
} from 'common/types/notifications.types';
import { formatDateRelative, getImageURL } from 'common/utils';
import {
  acceptInvitation,
  fetchIdentity,
  getAccessRoles,
  getIdentity,
  useIdentityActions,
  USER_TYPES,
} from 'entities/identity';
import { useNotify } from 'entities/notify';
import { useAppSelector } from 'store';
import { Avatar, Button, Container, Text } from 'UI';

import { Letter } from './Letter';
import {
  getEventLink,
  getNotificationProps,
  getNotificationKey,
  getNotificationText,
} from './utils';

const Card = styled.article<{ isPopupViewMode: boolean; isViewed: boolean }>(
  ({ theme: { space, colors }, isPopupViewMode, isViewed }) => css`
    display: flex;
    text-align: left;
    padding: ${space[2]}px;
    background: ${colors.white};

    ${isPopupViewMode
      ? css`
          background: ${isViewed ? colors.background : colors.white};
          border-bottom: 1px solid ${colors.divider};

          &:last-child {
            border-bottom: none;
          }
        `
      : css`
          margin-bottom: ${space[2]}px;
          border-radius: 8px;
          border: 1px solid ${colors.divider};

          &:last-child {
            margin-bottom: 0;
          }
        `}
  `,
);

const NewNotificationDot = styled.div(
  ({ theme: { colors } }) => css`
    display: inline-block;
    width: 6px;
    height: 6px;
    margin-left: 11px;
    background: ${colors.primary.main};
    border-radius: 50%;
  `,
);

const InfoEvent = styled.div`
  width: 100%;
  overflow: hidden;
`;

const letterTitle = {
  [PARTNER.PARTNERSHIP_REQUESTED]: 'Сопроводительное письмо',
  [PARTNER.PARTNERSHIP_REQUEST_AGAIN]: 'Сопроводительное письмо',
  [PARTNER.PARTNERSHIP_DECLINE]: 'Причина отказа',
};

const getLink = (
  notification: INotification,
  selectedSection: NotificationsSections,
  supply: boolean,
): Nullable<JSX.Element> => {
  const keyNotification = getNotificationKey(notification, selectedSection);
  const eventLink = getEventLink(keyNotification, notification, supply);

  if (!eventLink) return null;

  if (
    keyNotification === EXPORT.MANUAL_EXPORT_COMPLETED ||
    keyNotification === EXPORT.MANUAL_EXPORT_COMPLETED_WITH_ERRORS
  ) {
    return (
      <a download href={eventLink}>
        Скачать.
      </a>
    );
  }

  if (keyNotification === MARKETPLACE.NEW_ORDER_PLACED) {
    return (
      <a
        href={`${LINK_INTEGRATION_PATH}${eventLink}`}
        rel='noreferrer'
        target='_blank'
      >
        Перейти&nbsp;➙
      </a>
    );
  }

  return <Link to={eventLink}>Перейти&nbsp;➙</Link>;
};

interface INotificationCardProps {
  isPopupViewMode?: boolean;
  notification: INotification;
}

interface INotificationCardInvitedToCompanyProps {
  notification: INotification;
}

const NotificationCardInvitedToCompany: FC<INotificationCardInvitedToCompanyProps> =
  memo(({ notification }) => {
    const notify = useNotify();
    const [isProcessed, setProcessed] = useState(notification.isProcessed);
    const { invitationToken, companyName, invitationExpiresAt } =
      notification.values;

    const { setIdentity } = useIdentityActions();

    const remainingTime = useTimer({
      finishDate: invitationExpiresAt,
      format: 'remainingTime',
    });

    const handleChangeInvitationStatus = useCallback(
      async status => {
        try {
          await acceptInvitation(invitationToken, status);

          if (status === InvitationalStatuses.accepted) {
            const user = await fetchIdentity();
            setIdentity(user);
            notify.success(`Вы стали сотрудником компании ${companyName}.`);
          } else {
            notify.success('Приглашение отклонено.');
          }

          await changeProcessingStatus(notification.id);
          setProcessed(true);
          window.location.reload();
        } catch (error) {
          notify.error('Невозможно подтвердить действие.');
        }
      },
      [invitationToken, setIdentity, notify, companyName, notification.id],
    );

    if (!invitationToken || remainingTime < 1 || isProcessed) {
      return <Text color='text.secondary'>Cрок приглашения истек.</Text>;
    }

    return (
      <Container>
        <Button
          color='secondary'
          ml='auto'
          mr={2}
          mt={1}
          variant='string'
          onClick={() => {
            return handleChangeInvitationStatus(InvitationalStatuses.declined);
          }}
        >
          Отклонить
        </Button>
        <Button
          mt={1}
          variant='string'
          onClick={() => {
            return handleChangeInvitationStatus(InvitationalStatuses.accepted);
          }}
        >
          Принять
        </Button>
      </Container>
    );
  });

interface INotificationControlsProps {
  notification: INotification;
  section: NotificationsSections;
}

const NotificationControls: FC<INotificationControlsProps> = memo(
  ({ notification, section }) => {
    const keyNotification = getNotificationKey(notification, section);
    const identity = useAppSelector(getIdentity);
    if (
      keyNotification === IDENTITY.INVITED_TO_COMPANY &&
      identity.legalRole === USER_TYPES.INDIVIDUAL
    ) {
      return <NotificationCardInvitedToCompany notification={notification} />;
    }
    return null;
  },
);

const NotificationCard: FC<INotificationCardProps> = memo(
  ({ isPopupViewMode = false, notification }) => {
    const [{ section = null }] = useQueryParams();
    const { supply } = useAppSelector(getAccessRoles);
    const { rounded, logo, icon, letter, hidden } = getNotificationProps(
      notification,
      section,
    );

    const link = getLink(notification, section, supply);

    if (hidden) return null;

    return (
      <Card isPopupViewMode={isPopupViewMode} isViewed={notification.isViewed}>
        <Avatar
          backgroundColor={rounded ? 'highlight.1' : 'white'}
          mr={2}
          rounded={rounded}
          size='s'
          url={icon || getImageURL(logo)}
        />
        <InfoEvent>
          <Text color='text.secondary' fontSize={1} mb={0}>
            {isPopupViewMode
              ? formatDateRelative(notification.createdAt, {
                  withTime: true,
                  withYear: true,
                })
              : formatDateFns(new Date(notification.createdAt), 'HH:mm')}
            {!notification.isViewed && <NewNotificationDot />}
          </Text>
          <Text as='span' style={{ wordBreak: 'break-word' }}>
            {getNotificationText(notification, section)}{' '}
          </Text>
          {link}
          {letter && (
            <Letter
              message={letter}
              mt={0}
              template={LetterTemplate.compact}
              title={
                letterTitle[
                  `${notification.sections[0]}.${notification.template}`
                ]
              }
            />
          )}
          <NotificationControls notification={notification} section={section} />
        </InfoEvent>
      </Card>
    );
  },
);

export { NotificationCard };
