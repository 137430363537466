import {
  CSSProperties,
  ElementType,
  forwardRef,
  ReactNode,
  MouseEventHandler,
} from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import {
  color as styledColor,
  space,
  typography,
  display,
  flexbox,
  WidthProps,
  MinWidthProps,
  MaxWidthProps,
  SpaceProps,
  ColorProps,
  TypographyProps,
  DisplayProps,
  FlexboxProps,
  position,
  PositionProps,
  HeightProps,
} from 'styled-system';

import { width, height } from 'common/constants/customStyledSystems.const';

import { Tooltip } from '../Tooltip';

type TextStyledType = WidthProps &
  MinWidthProps &
  MaxWidthProps &
  HeightProps &
  SpaceProps &
  ColorProps &
  TypographyProps &
  DisplayProps &
  FlexboxProps &
  PositionProps;

type TextCustomType = {
  truncate?: boolean;
  nowrap?: boolean;
  as?: ElementType;
  textTransform?: string;
  lineClamp?: boolean;
  wordBreak?: string;
};

const Container = styled.div<TextCustomType & TextStyledType>(
  ({ truncate, nowrap, as, textTransform, lineClamp, wordBreak }) => css`
    a&:hover {
      color: inherit;
    }

    ${as === 'p' && 'margin: 0'};

    ${textTransform &&
    css`
      text-transform: ${textTransform};
    `}

    ${wordBreak &&
    css`
      word-break: ${wordBreak};
    `}

    ${(nowrap || truncate) && 'white-space: nowrap;'}

    ${truncate &&
    css`
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    // Используй lineClamp для переноса на несколько строк длинных названий
    ${lineClamp &&
    css`
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      word-break: break-word;
      overflow: hidden;
    `}

    ${width};
    ${height};
    ${space};
    ${styledColor};
    ${typography};
    ${display};
    ${flexbox};
    ${position};
  `,
);

interface ITextProps {
  style?: CSSProperties;
  className?: string;
  children?: ReactNode;
  to?: string;
  onClick?: MouseEventHandler<HTMLDivElement>;
  htmlFor?: string;
  // Свойства из Link
  replace?: boolean;
  disabled?: boolean;
  target?: string;
}

const Text = forwardRef<
  HTMLDivElement,
  ITextProps & TextCustomType & TextStyledType
>(
  (
    {
      style,
      className,
      as,
      to,
      children,
      nowrap,
      textTransform,
      wordBreak,
      truncate = false,
      color,
      lineClamp,
      onClick,
      ...other
    },
    ref,
  ) => {
    const template = (
      <Container
        ref={ref}
        as={as}
        className={className}
        color={color}
        lineClamp={lineClamp}
        nowrap={nowrap}
        style={style}
        textTransform={textTransform}
        to={to}
        truncate={truncate}
        wordBreak={wordBreak}
        onClick={onClick}
        {...other}
      >
        {children}
      </Container>
    );

    if (truncate) {
      return (
        <Tooltip
          pointer
          truncate
          maxWidth={320}
          placement='bottom'
          title={children}
        >
          {template}
        </Tooltip>
      );
    }

    return template;
  },
);

// @ts-ignore
Text.propTypes = {
  children: PropTypes.node,
  /**
   * Truncated text with Tooltip
   */
  truncate: PropTypes.bool,
  /**
   * Type of presented element
   */
  as: PropTypes.elementType,
  /**
   *  Path name
   */
  to: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /**
   * Width of text
   */
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * [styled-system](https://styled-system.com/api#color) color of text
   */
  color: PropTypes.string,
  /**
   * [styled-system](https://styled-system.com/api/#typography) align text
   */
  textAlign: PropTypes.oneOf([
    'inherit',
    'center',
    'left',
    'justify',
    'right',
    'start',
    'end',
  ]),
  /**
   * [styled-system](https://styled-system.com/api/#typography) font size from theme
   */
  fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * [styled-system](https://styled-system.com/api/#typography) font weigth
   */
  fontWeight: PropTypes.oneOf([400, 500, 600, 700]),
  /**
   * [styled-system](https://styled-system.com/api/#typography) line height. Default 1.5
   */
  lineHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * [styled-system](https://styled-system.com/api/#typography) letter spacing.
   */
  letterSpacing: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * [styled-system](https://styled-system.com/api#space) space (m, mt, ml, mb, mr, mx, my)
   */
  margin: PropTypes.number,
  /**
   * [styled-system](https://styled-system.com/api#space) space (p, pt, pr, pl, pb, px, py)
   */
  padding: PropTypes.number,
};

export { Text };
