import styled, { css } from 'styled-components';

export const CardWrapper = styled.div(
  ({ theme: { colors, space, borderRadius } }) => css`
    margin-top: ${space[0]}px;
    margin-bottom: ${space[2]}px;
    padding: ${space[2]}px 0 ${space[2]}px ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
  `,
);

export const CardMainInfo = styled.div(
  ({ theme: { space } }) => css`
    padding-left: ${space[2]}px;
    max-width: calc(80% - 200px);
  `,
);

export const MultipleCard = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    padding: ${space[2]}px 0 ${space[2]}px 0;
    border-top: 1px solid ${colors.divider};
    margin-left: 80px;

    &:first-child {
      margin-top: ${space[2]}px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  `,
);
