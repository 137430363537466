import { FC, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import { minWidth, MinWidthProps, space as styledSpace, SpaceProps } from 'styled-system';

import { ArrowLeftIcon, ArrowRightIcon } from 'resources/icons/12';
import { CloseMediumIcon } from 'resources/other-28';
import { Container, IconButton, Status, Text } from 'UI';
import { StatusTypes } from 'UI/Status/Status';

import { useErrorController } from './ErrorController';
import { FiledType } from '../types';

const NavigationPanelContainer = styled.div(
  ({ theme: { colors, space, zIndex } }) => css`
    position: sticky;
    bottom: 0;
    left: 0;
    display: flex;
    width: 100%;
    height: ${space[6]}px;
    min-height: ${space[6]}px;
    align-items: center;
    padding: 0 ${space[1]}px 0 ${space[3]}px;
    background: ${colors.white};
    border-top: 1px solid ${colors.divider};
    z-index: ${zIndex.sideMenu};

    ${styledSpace};
    ${minWidth}
  `,
);

type Type = StatusTypes.ERROR_CANCEL | StatusTypes.SUCCESS | 'nothing';

const STATUS_PANEL = {
  ERROR: StatusTypes.ERROR_CANCEL,
  SUCCESS: StatusTypes.SUCCESS,
  NOTHING: 'nothing',
} as const;

const ErrorNavigationPanel: FC<SpaceProps & MinWidthProps> = ({ ...other }) => {
  const [statusPanel, setStatusPanel] = useState<Type>(STATUS_PANEL.NOTHING);

  const [{ errorFields, currentErrorIndex }, setErrors] = useState<{
    errorFields: FiledType[];
    currentErrorIndex: number;
  }>({ errorFields: [], currentErrorIndex: 0 });

  const errorCount = errorFields.length;

  const { onSetWatcher, onScrollToNextError, onScrollToPrevError, onReset } =
    useErrorController();

  useEffect(() => {
    onSetWatcher(setErrors);
  }, [onSetWatcher]);

  useEffect(() => {
    if (errorCount > 0) {
      setStatusPanel(STATUS_PANEL.ERROR);
    } else if (statusPanel === STATUS_PANEL.ERROR && errorCount === 0) {
      setStatusPanel(STATUS_PANEL.SUCCESS);
    }
  }, [errorCount, statusPanel]);

  const handleClose = (): void => {
    setStatusPanel(STATUS_PANEL.NOTHING);
    onReset();
  };

  if (statusPanel === STATUS_PANEL.NOTHING) {
    return null;
  }

  return (
    <NavigationPanelContainer {...other}>
      <Container alignItems='center' flexGrow={1} justifyContent='center'>
        <Status mr={1} type={statusPanel} />
        {statusPanel === STATUS_PANEL.ERROR ? (
          <>
            Исправьте подсвеченные ошибки. Для быстрого переключения между
            ошибками используйте кнопки навигации.
            <Container alignItems='center' ml={2}>
              <IconButton size={28} onClick={onScrollToPrevError}>
                <ArrowLeftIcon />
              </IconButton>
              <Text fontWeight={500} ml={1}>
                {currentErrorIndex + 1} из {errorCount}
              </Text>
              <IconButton ml={1} size={28} onClick={onScrollToNextError}>
                <ArrowRightIcon />
              </IconButton>
            </Container>
          </>
        ) : (
          'Все ошибки исправлены'
        )}
      </Container>
      <IconButton ml={2} onClick={handleClose}>
        <CloseMediumIcon />
      </IconButton>
    </NavigationPanelContainer>
  );
};

export { ErrorNavigationPanel };
