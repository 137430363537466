import { Fragment, useCallback, useRef, FC, KeyboardEvent } from 'react';

import { useCombobox } from 'downshift';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchProductTypesOptions } from 'common/api/product-management';
import { CloseMediumIcon, SearchMediumIcon } from 'resources/icons/18';
import { SEARCH } from 'routes';
import { defaultItemToString } from 'tools/utils/stubFunctions';
import { Container, IconButton } from 'UI';
import {
  Options,
  useAsyncOptions,
  ItemToStringAndOptionsType,
} from 'UI/Inputs';
import { stateReducer } from 'UI/Inputs/Combobox';

const itemToString: ItemToStringAndOptionsType = (item, options) => {
  if (options) {
    return (
      <Container alignItems='center' height='100%'>
        {item}
      </Container>
    );
  }

  return item;
};

const ComboboxContainer = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
    max-width: 400px;
    height: ${space[4]}px;
    font-size: ${fontSizes[5]}px;
    margin: 0 ${space[2]}px;
    padding-right: 3px;
    padding-left: ${space[2]}px;
    color: ${colors.text.primary};
    background: ${colors.highlight[2]};
    border-radius: 8px;
  `,
);

const HeaderInputContainer = styled.input(
  ({ theme: { fontSizes } }) => css`
    width: 100%;
    background: transparent;

    &::placeholder {
      font-size: ${fontSizes[5]}px;
    }
  `,
);

const HeaderSearch: FC = () => {
  const history = useHistory();
  const containerRef = useRef(null);
  const isSearchPage = !!useRouteMatch(SEARCH.PAGE);
  const [asyncState, dispatch] = useAsyncOptions();

  const downshift = useCombobox<string>({
    items: asyncState.items,
    stateReducer,
    onSelectedItemChange: ({ selectedItem }) => {
      history.push(`${SEARCH.FORM}?search=${selectedItem}`);
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      setInputValue('');
    },
  });

  const { inputValue, getInputProps, getComboboxProps, setInputValue } =
    downshift;

  const handleSubmit = (): void => {
    history.push(`${SEARCH.FORM}?search=${inputValue}`);
    setInputValue('');
  };

  const fetchComboOptions = useCallback(
    async (props): Promise<{ member: string[]; totalItems: number }> => {
      return fetchProductTypesOptions(props);
    },
    [],
  );

  const handleReset = (): void => {
    setInputValue('');
  };

  const handleEnterPress = ({ key }: KeyboardEvent<HTMLInputElement>): void => {
    if (key === 'Enter') handleSubmit();
  };

  if (isSearchPage) return null;

  return (
    <ComboboxContainer {...getComboboxProps({ ref: containerRef })}>
      <HeaderInputContainer
        {...getInputProps({
          placeholder: 'Найти изделие',
          onKeyDown: handleEnterPress,
        })}
      />
      <Container alignItems='center' ml='auto'>
        {inputValue && (
          <Fragment>
            <IconButton mr={0} size={28} title='Очистить' onClick={handleReset}>
              <CloseMediumIcon />
            </IconButton>
            <IconButton
              disabled={!inputValue}
              size={28}
              title='Найти'
              variant='outline'
              onClick={handleSubmit}
            >
              <SearchMediumIcon />
            </IconButton>
          </Fragment>
        )}
      </Container>
      <Options
        anchorEl={containerRef.current}
        dispatch={dispatch}
        downshift={downshift}
        filteredItems={asyncState.items}
        heightItem={46}
        itemToKey={defaultItemToString}
        itemToString={itemToString}
        options={fetchComboOptions}
        state={asyncState}
      />
    </ComboboxContainer>
  );
};

export { HeaderSearch };
