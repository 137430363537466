import {
  useState,
  useRef,
  useCallback,
  useMemo,
  createContext,
  useContext,
  FC,
  ReactElement,
} from 'react';

import styled from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { CloseMediumIcon } from 'resources/icons/18';
import { STUB_FUNC } from 'tools/utils/stubFunctions';
import { StyledIconButton } from 'UI/Modal/templates/styled';
import theme from 'UI/theme';

import { Content, HintContainer } from './styled';

const CONTAINER_STYLE = { flex: '0 0 600px' };

const CloseIconButton = styled(StyledIconButton)`
  top: 0;
  right: 0;

  &,
  &:hover {
    background: none;
  }
`;

const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  min-width: 0;
`;

interface IRightProductPanelContext {
  isOpenPanel: boolean;
  panelRef?: Nullable<HTMLDivElement>;
  openPanel: VoidFunction;
  closePanel: VoidFunction;
}

const INITIAL_CONTEXT: IRightProductPanelContext = {
  isOpenPanel: false,
  panelRef: null,
  openPanel: STUB_FUNC.NOOP,
  closePanel: STUB_FUNC.NOOP,
};

const Context = createContext<IRightProductPanelContext>(INITIAL_CONTEXT);

const useRightProductPanelContext = (): IRightProductPanelContext => {
  return useContext(Context);
};

interface IRightProductPanelContentProps {
  children: ReactElement | ReactElement[];
}

const RightProductPanelContext: FC<IRightProductPanelContentProps> = ({
  children,
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [isOpenPanel, setOpenPanel] = useState(false);

  const openPanel = useCallback(() => {
    setOpenPanel(true);
  }, []);

  const closePanel = useCallback(() => {
    setOpenPanel(false);
  }, []);

  const contextValue = useMemo(() => {
    return {
      isOpenPanel,
      panelRef: contentRef.current,
      openPanel,
      closePanel,
    };
  }, [isOpenPanel, openPanel, closePanel]);

  return (
    <Context.Provider value={contextValue}>
      <ChildrenWrapper>{children}</ChildrenWrapper>
      <HintContainer
        drawBorder
        style={isOpenPanel ? CONTAINER_STYLE : undefined}
        zIndex={theme.zIndex.header}
      >
        <CloseIconButton size={46} onClick={closePanel}>
          <CloseMediumIcon />
        </CloseIconButton>
        <Content ref={contentRef} width={600} />
      </HintContainer>
    </Context.Provider>
  );
};

export { RightProductPanelContext, useRightProductPanelContext };
