import { FC } from 'react';

import styled, { css } from 'styled-components';

import { Field, useWatch, useField } from 'reform';
import { useDidUpdate } from 'tools/hooks';
import { FormLabel, InputGroup, SelectField, TextInputField } from 'UI';

import { PACKAGE_TYPE_SIZES } from './constants';

const Container = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    margin-top: ${space[1]}px;
  `,
);

const persistError = ({
  prevError,
  prevValue: prevValues,
  nextValue: nextValues,
}): any => {
  const newError = Object.keys(prevError?.value)?.reduce((acc, key) => {
    const prevValue = prevValues?.value?.[key];
    const nextValue = nextValues?.value?.[key];

    if (prevValue === nextValue) {
      return { ...acc, [key]: prevError?.value[key] };
    }

    return acc;
  }, {});

  return Object.keys(newError)?.length ? { value: newError } : null;
};

const reformSelector = { fieldName: 'packageType' };
const fieldSize = {
  name: 'size',
  persistError,
};
const INITIAL_AB = { value: { a: '', b: '' }, unit: '' };
const INITIAL_AC = { value: { a: '', b: '', c: '' }, unit: '' };

interface IPackageSizeInput {
  disabled: boolean;
  unitToString: (...any) => void;
}

const PackageSizeInput: FC<IPackageSizeInput> = ({
  disabled,
  unitToString,
}) => {
  const packageType = useWatch(reformSelector);
  const { setValue } = useField(fieldSize);

  useDidUpdate(() => {
    if (packageType) {
      const initialValue =
        packageType.sizeParameters.type === PACKAGE_TYPE_SIZES.THREE_DIMENSIONS
          ? INITIAL_AC
          : INITIAL_AB;

      setValue(initialValue);
    }
  }, [packageType, setValue]);

  if (!packageType) return null;

  const isThreeDimensionalPackage =
    packageType.sizeParameters.type === PACKAGE_TYPE_SIZES.THREE_DIMENSIONS;

  return (
    <Container>
      <div>
        <FormLabel required disabled={disabled}>
          {packageType.sizeParameters.name}
        </FormLabel>
        <InputGroup>
          <Field
            component={TextInputField}
            disabled={disabled}
            name='size.value.a'
            placeholder='Д'
            style={{ borderRadius: '2px 0 0 2px' }}
            type='number'
            width={`${isThreeDimensionalPackage ? 127 : 190}px`}
          />
          <Field
            component={TextInputField}
            disabled={disabled}
            name='size.value.b'
            placeholder='Ш'
            style={{
              borderRadius: isThreeDimensionalPackage ? '0' : '0 2px 2px 0',
            }}
            type='number'
            width={`${isThreeDimensionalPackage ? 127 : 190}px`}
          />
          {isThreeDimensionalPackage && (
            <Field
              component={TextInputField}
              disabled={disabled}
              name='size.value.c'
              placeholder='В'
              style={{ borderRadius: '0 2px 2px 0' }}
              type='number'
              width='127px'
            />
          )}
        </InputGroup>
      </div>
      <Field
        required
        component={SelectField}
        disabled={disabled}
        itemToString={unitToString}
        label='Ед. измерения'
        ml={2}
        name='size.unit'
        options={packageType.sizeParameters.units}
      />
    </Container>
  );
};

export { PackageSizeInput };
