import { FC, useEffect } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { CloseMediumIcon } from 'resources/icons/18';
import { useToggle } from 'tools/hooks';
import { Button, IconButton, Text } from 'UI';

const RuleControllerWrapper = styled.div(
  ({ theme: { shadows } }) => css`
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    justify-content: space-around;
    width: 100%;
    height: 20px;
    box-shadow: ${shadows[2]};
    opacity: 0;
    transition: opacity 200ms ease-in-out;
  `,
);

const RuleControllerContainer = styled.div<{ visible: boolean | undefined }>(
  ({ theme: { space }, visible }) => css`
    position: relative;
    height: ${space[1]}px;

    &:last-child {
      height: 20px;
    }

    ${visible &&
    css`
      height: 20px;
      margin-bottom: ${space[2]}px;
      margin-top: ${space[2]}px;

      & > ${RuleControllerWrapper} {
        top: 0;
        transform: none;
        opacity: 1;
      }
    `},

    &:hover > ${RuleControllerWrapper} {
      opacity: 1;
    }

    ${styledSpace};
  `,
);

const RuleButton = styled(Button)(
  ({ theme: { space } }) => css`
    display: inline-block;
    height: 20px;
    min-width: 90px;
    width: 100%;
    padding: 0 ${space[1]}px;
    margin-right: ${space[0]}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:last-child {
      margin-right: 0;
    }
  `,
);

const Container = styled.div(
  ({ theme: { colors, space, borderRadius } }) => css`
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    padding: 0 ${space[1]}px;

    ${styledSpace};
  `,
);

const Header = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: ${space[0]}px;
    margin-bottom: ${space[0]}px;
  `,
);

const CollapseContainer = styled.div<{ collapse: boolean }>(
  ({ collapse }) => css`
    display: ${collapse ? 'none' : 'block'};
  `,
);

interface IRuleControllerProps {
  visible?: boolean;
  disabled?: boolean;
  keyName?: string;
  rules: object[];
  ruleNames: Record<string, string>;
  onAddRule: (rule: object) => void;
}

const RuleController: FC<IRuleControllerProps & SpaceProps> = ({
  visible,
  disabled,
  keyName = 'type',
  rules,
  ruleNames,
  onAddRule,
  ...other
}) => {
  return (
    <RuleControllerContainer visible={visible} {...other}>
      <RuleControllerWrapper>
        {rules.map(item => (
          <RuleButton
            key={item[keyName]}
            disabled={disabled}
            size='s'
            onClick={() => onAddRule({ ...item, id: uuid() })}
          >
            <Text ml={1}>{ruleNames[item[keyName]]}</Text>
          </RuleButton>
        ))}
      </RuleControllerWrapper>
    </RuleControllerContainer>
  );
};

interface IRuleContainerProps {
  disabled: boolean | undefined;
  fullCollapse: boolean;
  header: string;
  children: JSX.Element;
  onRemove: VoidFunction;
}

const RuleContainer: FC<IRuleContainerProps & SpaceProps> = ({
  disabled,
  fullCollapse,
  header,
  children,
  onRemove,
  ...other
}) => {
  const [collapse, handleToggle] = useToggle(fullCollapse);

  useEffect(() => {
    handleToggle(fullCollapse);
  }, [fullCollapse, handleToggle]);

  return (
    <Container {...other}>
      <Header>
        <Button
          color='secondary'
          fontSize={4}
          variant='text'
          onClick={handleToggle}
        >
          {header}
        </Button>
        <IconButton disabled={disabled} onClick={onRemove}>
          <CloseMediumIcon />
        </IconButton>
      </Header>
      <CollapseContainer collapse={collapse}>{children}</CollapseContainer>
    </Container>
  );
};

export { RuleContainer, RuleController };
