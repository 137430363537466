import { Fragment, useCallback, useMemo, memo, FC } from 'react';

import styled, { css } from 'styled-components';

import { useHint } from 'common/hooks';
import { ICombinedFormWithValues } from 'common/types/form.types';
import {
  CREATE_MANUFACTURING_REQUEST_MODAL,
  CreateManufacturingRequestModal,
} from 'entities/manufacturing-requests';
import { ModalController, useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { Button, Notice, Text, Tooltip } from 'UI';

import { CombinedFormResults } from './CombinedFormResults';
import { JOINT_ACTIONS } from './constants';
import { RulesGraph } from './form-properties/rules-graph';
import { SearchByJointPropertiesModal } from './SearchByJointPropertiesModal';
import { getTransformedCombinedForms } from './utils';
import { IJoint, ISearchParams } from '../types';

const SEARCH_BY_JOINT_PROPERTIES_MODAL = 'SEARCH_BY_JOINT_PROPERTIES_MODAL';

const TOOLTIP_TITLE = (
  <Fragment>
    <Text color='text.primary' fontSize={2} mb={2}>
      Действия с соединениями входят в платную подписку. Оформление платной
      подписки будет доступно совсем скоро.
    </Text>
    <Text color='text.primary' fontSize={2}>
      Чтобы не пропустить ее появление, подпишитесь на уведомления.
    </Text>
  </Fragment>
);

const ButtonPanel = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    column-gap: ${space[2]}px;
    height: 42px;
    margin-bottom: ${space[2]}px;
    padding: 0 ${space[1]}px;
  `,
);

interface IJointResultsProps {
  hasMatches: boolean;
  joint?: IJoint;
  searchId: string | undefined;
  combinedForms: ICombinedFormWithValues[];
  filledForms: ICombinedFormWithValues[];
  onSearch: (params: ISearchParams) => void;
}

const JointResults: FC<IJointResultsProps> = memo(
  ({ hasMatches, joint, searchId, combinedForms, filledForms, onSearch }) => {
    const notify = useNotify();
    const { handleModalOpen } = useModalContext();
    const { isViewed, handleToggleViewed } = useHint('joinFilters');

    const rulesGraph = useMemo(
      () => new RulesGraph(combinedForms),
      [combinedForms],
    );

    const handleSubmit = useCallback(
      (params: ICombinedFormWithValues[]): void => {
        const transformedCombinedForms = getTransformedCombinedForms(params);

        onSearch({
          search: searchId,
          searchParameters: transformedCombinedForms,
        });
      },
      [searchId, onSearch],
    );

    const handleRest = useCallback(
      () => onSearch({ search: searchId }),
      [searchId, onSearch],
    );

    const handleOpenFilters = (index: number): void => {
      handleModalOpen(SEARCH_BY_JOINT_PROPERTIES_MODAL, {
        rulesGraph,
        initialValues: joint?.combinedForms,
        initialFormIndex: index,
        productForms: combinedForms,
        filledForms,
        onSubmit: handleSubmit,
        onReset: handleRest,
      });
    };

    return (
      <Fragment>
        {hasMatches && (
          <Fragment>
            {!isViewed && (
              <Notice mb={3} onClose={handleToggleViewed}>
                <b>
                  Для подбора соединения используйте фильтры по типам товаров.{' '}
                </b>
                В фильтрах встречаются влияющие и зависимые характеристики.
                Заполните влияющую характеристику, чтобы автоматически
                заполнилось значение зависимой. Заполняйте фильтры
                последовательно, начиная с{' '}
                <Button variant='string' onClick={() => handleOpenFilters(0)}>
                  фильтра первого типа товара
                </Button>
                .
              </Notice>
            )}
            <ButtonPanel>
              {JOINT_ACTIONS.map(action => (
                <Tooltip
                  key={action.gtmType}
                  arrow
                  maxWidth={240}
                  placement='bottom'
                  title={
                    <Fragment>
                      {TOOLTIP_TITLE}
                      <Button
                        data-gtm-type={action.gtmType}
                        variant='string'
                        onClick={() => {
                          notify.success('Вы подписались на уведомления!');
                        }}
                      >
                        Подписаться
                      </Button>
                    </Fragment>
                  }
                >
                  <div>
                    <Button disabled color='secondary' variant='string'>
                      {action.icon}
                      {action.label}
                    </Button>
                  </div>
                </Tooltip>
              ))}
            </ButtonPanel>
          </Fragment>
        )}
        {combinedForms.map((form, index) => (
          <CombinedFormResults
            key={form.id}
            combinedForm={form}
            combinedForms={combinedForms}
            defaultForms={joint?.combinedForms}
            handleSubmit={handleSubmit}
            rulesGraph={rulesGraph}
            onFilter={() => handleOpenFilters(index)}
          />
        ))}
        <ModalController
          component={SearchByJointPropertiesModal}
          type={SEARCH_BY_JOINT_PROPERTIES_MODAL}
        />
        <ModalController
          component={CreateManufacturingRequestModal}
          type={CREATE_MANUFACTURING_REQUEST_MODAL}
        />
      </Fragment>
    );
  },
);

export { JointResults };
