import styled, { css } from 'styled-components';

import { DEVIATION_TYPES } from 'common/constants/common.const';
import { Nullable } from 'common/types/common.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { formatDate, getFormattedNumber, getRangeText } from 'common/utils';
import { Tags } from 'UI';

const CUSTOM_VALUE = 'свободный ввод';
const customValueTooltip = (item: string): Nullable<string> => {
  return item === CUSTOM_VALUE
    ? 'Эта характеристика может быть исполнена по пользовательским значениям.'
    : null;
};

const UpperDeviation = styled.sup(
  ({ theme: { fontSizes } }) => css`
    display: block;
    top: -5px;
    font-size: ${fontSizes[1]}px;
  `,
);

const LowerDeviation = styled.sub(
  ({ theme: { space, fontSizes } }) => css`
    display: block;
    top: ${space[0]}px;
    font-size: ${fontSizes[1]}px;
  `,
);

const getFormattedDeviation = (deviation: any): any => {
  switch (deviation?.type) {
    case DEVIATION_TYPES.number:
      return (
        <span style={{ display: 'inline-block' }}>
          {deviation.negative && (
            <LowerDeviation>-{deviation.negative}</LowerDeviation>
          )}
          {deviation.positive && (
            <UpperDeviation>+{deviation.positive}</UpperDeviation>
          )}
        </span>
      );
    case DEVIATION_TYPES.limit:
      return deviation.limit;
    case DEVIATION_TYPES.fit:
      return `${deviation.hole}/${deviation.shaft}`;
    default:
      return null;
  }
};

/**
 * The function to format product property
 *
 * @param {Object} property
 * @param {boolean} shortMode
 * @returns {string | JSX.Element} human readable string
 */
function getProductPropertiesPrimitive(
  property,
  shortMode = false,
): Nullable<string | JSX.Element> | undefined {
  const { type, value } = property;

  switch (type) {
    case PropertiesTypes.number: {
      const { deviation } = property;

      if (value) {
        return shortMode ? (
          getFormattedNumber(value)
        ) : (
          <span>
            {getFormattedNumber(value)} {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.twoDimensions: {
      const { deviation } = property;

      if (value.a && value.b) {
        return shortMode ? (
          `${getFormattedNumber(value.a)} x ${getFormattedNumber(value.b)}`
        ) : (
          <span>
            {getFormattedNumber(value.a)} x {getFormattedNumber(value.b)}{' '}
            {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.threeDimensions: {
      const { deviation } = property;

      if (value.a && value.b && value.c) {
        return shortMode ? (
          `${getFormattedNumber(value.a)} x ${getFormattedNumber(
            value.b,
          )} x ${getFormattedNumber(value.c)}`
        ) : (
          <span>
            {getFormattedNumber(value.a)} x {getFormattedNumber(value.b)} x{' '}
            {getFormattedNumber(value.c)} {getFormattedDeviation(deviation)}
          </span>
        );
      }

      return null;
    }
    case PropertiesTypes.range:
      return getRangeText({ ...value, infinitely: false });
    case PropertiesTypes.dateRange:
      return `от ${formatDate(value.from)} до ${formatDate(value.to)}`;
    case PropertiesTypes.date:
      return formatDate(value);
    case PropertiesTypes.string:
      return value;
    case PropertiesTypes.multiString: {
      if (!value || value.length === 0) return null;

      if (shortMode) {
        return value.join(', ');
      }

      return <Tags arrow limit={value.length} tags={value} />;
    }
    case PropertiesTypes.arbitraryNumber:
    case PropertiesTypes.arbitraryRange:
      return getRangeText(value.options);
    case PropertiesTypes.arbitraryMultiString:
    case PropertiesTypes.arbitraryMultiNumber: {
      const { allowCustomValues, options } = value;
      const optionsWithCustomer = (
        allowCustomValues ? [...options, CUSTOM_VALUE] : options
      ).map(i => (typeof i === 'number' ? getFormattedNumber(i) : i));

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.arbitraryStandardMaterial: {
      const { allowCustomValues, materials } = value;
      const optionsWithCustomer = (
        allowCustomValues ? [...materials, CUSTOM_VALUE] : materials
      ).map(i =>
        i !== CUSTOM_VALUE ? `${i?.mark} (${i?.ntdReference?.numericName})` : i,
      );

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.arbitraryStandardCoating: {
      const { allowCustomValues, coatings } = value;

      const optionsWithCustomer = (
        allowCustomValues ? [...coatings, CUSTOM_VALUE] : coatings
      ).map(i =>
        i !== CUSTOM_VALUE
          ? `${i?.symbol || i?.name} (${i?.ntdReference.numericName})`
          : i,
      );

      if (optionsWithCustomer.length === 0) {
        return null;
      }

      if (shortMode) {
        return optionsWithCustomer.join(', ');
      }

      return (
        <Tags
          arrow
          itemToTooltip={customValueTooltip}
          limit={optionsWithCustomer.length}
          tags={optionsWithCustomer}
        />
      );
    }
    case PropertiesTypes.constructionVariation:
      return property.name;
    case PropertiesTypes.multiColor:
      return value.map(color => color.name).join(', ');
    default:
      return null;
  }
}

export { getProductPropertiesPrimitive };
