import { FC, memo, useRef, useState } from 'react';

import styled from 'styled-components';

import { CompanySections, IDepartment } from 'common/types/company.types';
import { ScrollTabsByArrows } from 'components/ScrollTabsByArrows';
import { InfoBlock, TablistTab } from 'UI';

import {
  CompanyArticles,
  CombinedNtdsSection,
  CompanyQuestionnaire,
  CompanyBrands,
  CompanyServices,
  CombinedProductsTable,
  CombinedGenericProductsSection,
} from './components';

const StyledScrollTabsByArrows = styled(ScrollTabsByArrows)`
  border-top: none;
`;

const MAIN_INFO_KEYS = {
  PRODUCTS: 'PRODUCTS',
  RETAILED_PRODUCTS: 'RETAILED_PRODUCTS',
  QUESTIONNAIRES: 'QUESTIONNAIRES',
  MANUFACTURED_NTDS: 'MANUFACTURED_NTDS',
  RETAILED_NTDS: 'RETAILED_NTDS',
  MANUFACTURED_GENERIC_PRODUCTS: 'MANUFACTURED_GENERIC_PRODUCTS',
  RETAILED_GENERIC_PRODUCTS: 'RETAILED_GENERIC_PRODUCTS',
  ARTICLES: 'ARTICLES',
  BRANDS: 'BRANDS',
  SERVICES: 'SERVICES',
} as const;

interface IAdditionalInfoProps {
  companyId: string;
  mainDepartment?: IDepartment;
  id: CompanySections.mainInfo;
  hasSections: {
    hasProducts: boolean;
    hasRetailedProducts: boolean;
    hasArticles: boolean;
    hasBrands: boolean;
    hasManufacturedNtds: boolean;
    hasRetailedNtds: boolean;
    hasManufacturingGenericProducts: boolean;
    hasRetailedGenericProducts: boolean;
    hasQuestionnaires: boolean;
    hasServices: boolean;
    hasPointsOfSale: boolean;
  };
}
const MainInfo: FC<IAdditionalInfoProps> = memo(
  ({ companyId, id, hasSections }) => {
    const tabListRef = useRef(null);

    const {
      hasProducts,
      hasRetailedProducts,
      hasArticles,
      hasBrands,
      hasManufacturedNtds,
      hasRetailedNtds,
      hasManufacturingGenericProducts,
      hasRetailedGenericProducts,
      hasQuestionnaires,
      hasPointsOfSale,
      hasServices,
    } = hasSections;

    const MAIN_INFO_TABS = [
      hasProducts && {
        key: MAIN_INFO_KEYS.PRODUCTS,
        title: 'Товары производителя',
      },
      hasRetailedProducts && {
        key: MAIN_INFO_KEYS.RETAILED_PRODUCTS,
        title: 'Товары продавца',
      },
      hasQuestionnaires && {
        key: MAIN_INFO_KEYS.QUESTIONNAIRES,
        title: 'Опросные листы',
      },
      hasManufacturedNtds && {
        key: MAIN_INFO_KEYS.MANUFACTURED_NTDS,
        title: 'НТД в производстве',
      },
      hasRetailedNtds && {
        key: MAIN_INFO_KEYS.RETAILED_NTDS,
        title: 'НТД в поставке',
      },
      hasManufacturingGenericProducts && {
        key: MAIN_INFO_KEYS.MANUFACTURED_GENERIC_PRODUCTS,
        title: 'Типовые товары',
      },
      hasRetailedGenericProducts && {
        key: MAIN_INFO_KEYS.RETAILED_GENERIC_PRODUCTS,
        title: 'Типовые товары в поставке',
      },
      hasServices && {
        key: MAIN_INFO_KEYS.SERVICES,
        title: 'Услуги',
      },
      hasArticles && {
        key: MAIN_INFO_KEYS.ARTICLES,
        title: 'Публикации',
      },
      hasBrands && {
        key: MAIN_INFO_KEYS.BRANDS,
        title: 'Бренды',
      },
    ].filter(Boolean) as { key: keyof typeof MAIN_INFO_KEYS; title: string }[];

    const [activeTab, setActiveTab] = useState<keyof typeof MAIN_INFO_KEYS>(
      MAIN_INFO_TABS[0].key,
    );

    return (
      <InfoBlock id={id} title='Основная информация'>
        <StyledScrollTabsByArrows mb={3} tabListRef={tabListRef}>
          {MAIN_INFO_TABS.map(item => (
            <TablistTab
              key={item.key}
              aria-selected={activeTab === item.key}
              fontSize={4}
              fontWeight={500}
              onClick={() => setActiveTab(item.key)}
            >
              {item.title}
            </TablistTab>
          ))}
        </StyledScrollTabsByArrows>
        {activeTab === MAIN_INFO_KEYS.PRODUCTS && (
          <CombinedProductsTable
            companyId={companyId}
            hasPointsOfSale={hasPointsOfSale}
          />
        )}
        {activeTab === MAIN_INFO_KEYS.RETAILED_PRODUCTS && (
          <CombinedProductsTable
            isRetailerProducts
            companyId={companyId}
            hasPointsOfSale={hasPointsOfSale}
          />
        )}
        {activeTab === MAIN_INFO_KEYS.QUESTIONNAIRES && (
          <CompanyQuestionnaire companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.MANUFACTURED_NTDS && (
          <CombinedNtdsSection companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.RETAILED_NTDS && (
          <CombinedNtdsSection isRetailedNtds companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.MANUFACTURED_GENERIC_PRODUCTS && (
          <CombinedGenericProductsSection companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.RETAILED_GENERIC_PRODUCTS && (
          <CombinedGenericProductsSection
            isRetailedProducts
            companyId={companyId}
          />
        )}
        {activeTab === MAIN_INFO_KEYS.SERVICES && (
          <CompanyServices companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.ARTICLES && (
          <CompanyArticles companyId={companyId} />
        )}
        {activeTab === MAIN_INFO_KEYS.BRANDS && (
          <CompanyBrands companyId={companyId} />
        )}
      </InfoBlock>
    );
  },
);
export { MainInfo };
