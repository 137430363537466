import { CSSProperties, ElementType, MouseEvent } from 'react';

import styled, { css } from 'styled-components';
import {
  BackgroundColorProps,
  border,
  BorderProps,
  color,
  HeightProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { AvatarBlankIcon } from 'resources/icons/scale';

const avatarSizes = {
  xxs: 28,
  xs: 34,
  s: 46,
  m: 52,
  l: 64,
  xl: 160,
};

const emptySizes = { ...avatarSizes, xl: 82 };

const textSize = {
  xxs: 11,
  xs: 12,
  s: 14,
  m: 14,
  l: 14,
  xl: 22, // get info from designers
};

const EmptyAvatar = styled.div<{ size: string }>(
  ({ theme: { colors }, size }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${colors.highlight[1]};
    color: ${colors.primary.main};
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    font-size: ${textSize[size]}px;

    & svg {
      width: 100%;
    }
  `,
);

const WrapperAvatar = styled.div<{
  size: string | number;
  rounded?: boolean;
  cover?: boolean;
  hasImage?: boolean;
  full?: boolean;
}>(
  ({
    theme: { colors, borderRadius },
    size,
    rounded,
    cover,
    hasImage,
    full,
  }) => css`
    position: relative;
    width: ${avatarSizes[size]}px;
    min-width: ${avatarSizes[size]}px;
    height: ${avatarSizes[size]}px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: ${rounded ? '50%' : `${borderRadius}px`};
    background: ${colors.white};
    overflow: hidden;

    ${hasImage &&
    css`
      img {
        object-fit: ${cover ? 'cover' : 'contain'};
        height: 100%;
        width: 100%;
      }
      ${rounded &&
      css`
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${colors.preview};
        }
      `}
    `}

    ${full &&
    css`
      width: 100%;
      height: ${avatarSizes.xl}px;
      background: ${colors.preview};
      border-radius: 0;
    `};

    ${rounded &&
    css`
      border: 1px solid ${colors.white};
    `}

    ${styledSpace};
    ${color};
    ${border};
  `,
);

function getPlaceholderText(letter?: string | null): string {
  if (!letter) return '';

  const letterParts = letter.replace(/['"().«»,-]+/g, '').split(' ');

  if (letterParts.length < 2) return letterParts[0].slice(0, 2).toUpperCase();

  return letterParts
    .slice(0, 2)
    .reduce((acc, [firstChar = '']) => `${acc}${firstChar.toUpperCase()}`, '');
}

interface IAvatarProps {
  style?: CSSProperties;
  size?: string;
  target?: '_blank' | '_self' | '_parent' | '_top';
  as?: ElementType;
  to?: string;
  /**
   * Image source address
   */
  url?: string;
  /**
   * String to be transformed into letters (in case of no image but name given)
   */
  letter?: string | null;
  /**
   * Is avatar a circle or a square
   */
  rounded?: boolean;
  /**
   * Если аватар -- это верхушка карточки (например, в каталогах),
   * то выставляем object-fit: cover
   */
  cover?: boolean;
  /**
   * Функция при нажатии.
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
  /**
   * Проп, показывающий, нужна ли
   */
  full?: boolean;
  alt?: string;
  href?: string;
}

function Avatar({
  alt = 'avatar',
  style,
  url = '',
  letter,
  cover = false,
  rounded = false,
  size = 'l',
  as,
  to,
  target,
  full = false,
  ...other
}: IAvatarProps &
  BorderProps &
  SpaceProps &
  BackgroundColorProps &
  HeightProps): JSX.Element {
  const placeholderText = getPlaceholderText(letter);

  return (
    <WrapperAvatar
      as={as}
      cover={cover}
      full={full}
      hasImage={!!url}
      rounded={rounded}
      size={size}
      style={style}
      target={target}
      to={to}
      {...other}
    >
      {url ? (
        <img alt={alt} src={url} />
      ) : (
        <EmptyAvatar aria-label={placeholderText} size={size}>
          {letter ? (
            placeholderText
          ) : (
            <AvatarBlankIcon
              color='highlight.1'
              m={0}
              size={emptySizes[size]}
            />
          )}
        </EmptyAvatar>
      )}
    </WrapperAvatar>
  );
}

export { Avatar };
