import { FC, Fragment, memo } from 'react';

import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';
import { Text } from 'UI';

import { ITEMS_LIMIT } from './constants';
import { ContentButton } from './styled';
import { ContentLink } from '../styled';

interface IContentLinksProps<T> {
  isStartingPage?: boolean;
  data: T[];
  getIteratorData: (item: T) => { name: string; link: string };
}

const ContentLinks: FC<IContentLinksProps<any>> = memo(
  ({ isStartingPage, data, getIteratorData }) => {
    const [isOpen, toggleOpen] = useToggle(false);

    const hasToggle = data.length > ITEMS_LIMIT;

    return (
      <Fragment>
        {data
          .slice(0, hasToggle && !isOpen ? ITEMS_LIMIT : undefined)
          .map(item => {
            const { name, link } = getIteratorData(item);

            return (
              <ContentLink key={name} to={link}>
                <Text truncate fontSize={isStartingPage ? 7 : 3}>
                  {name}
                </Text>
              </ContentLink>
            );
          })}
        {hasToggle && (
          <ContentButton variant='string' onClick={toggleOpen}>
            <Text fontSize={0} mr={0} textTransform='uppercase'>
              {isOpen ? 'свернуть' : 'еще'}
            </Text>
            {isOpen ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
          </ContentButton>
        )}
      </Fragment>
    );
  },
);

export { ContentLinks };
