import { Fragment, FC, ReactNode, RefObject } from 'react';

import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  color as styledColor,
  layout,
  flexbox,
  minWidth as styledMinWidth,
  ColorProps,
  MinWidthProps,
  SpaceProps,
  LayoutProps,
  FlexboxProps,
} from 'styled-system';

import {
  ActionPanel,
  IActionPanelProps,
} from 'components/action-panel/ActionPanel';
import { MetaHead, IMetaHead } from 'components/meta-head';
import { PageHeader, Container } from 'UI';
import { PageHeaderType } from 'UI/PageHeader/PageHeader';

const CONTENT_WIDTH = { LARGE: '1200px', MEDIUM: '860px', SMALL: '762px' };

window.getMainContent = (parentId = 'MainContent') => {
  return document.getElementById(parentId) ?? document.body;
};

const MainContent = styled.section<ColorProps>(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    width: 100%;
    height: 100%;
    overflow: auto;
    border: 1px solid ${colors.divider};
    border-right-width: 0;
    border-radius: 8px 0 0 0;
    background: ${colors.white};

    ${styledColor};
  `,
);

const TableMain = styled.div<{ column: boolean } & MinWidthProps>(
  ({ column }) => css`
    position: relative;
    display: flex;
    flex-direction: ${column ? 'column' : 'row'};
    width: 100%;
    height: 100%;

    ${styledMinWidth};
  `,
);

const PageMain = styled.div<{ column?: boolean } & SpaceProps & LayoutProps>(
  ({ theme: { space }, column }) => css`
    display: flex;
    padding: 0 20px ${space[4]}px ${space[2]}px;
    width: 100%;
    min-width: 800px;

    ${column &&
    css`
      flex-direction: column;
    `}

    ${styledSpace};
    ${layout};
  `,
);

const PageMainWrapper = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    height: max-content;
    margin-left: -${space[3]}px;
    min-width: 1000px;

    ${styledSpace}
  `,
);

const CommonPageWrapper = styled.div<
  { gap?: number } & LayoutProps & FlexboxProps
>(
  ({ width = '100%', gap, theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    width: ${width};
    height: inherit;

    ${typeof gap === 'number' &&
    css`
      gap: ${space[gap]}px;
    `}

    ${layout}
    ${flexbox}
  `,
);

type TitleType = string | undefined;

const getHeading = (
  title: TitleType,
  heading?: string | PageHeaderType,
): { tabHead?: string; pageHead: PageHeaderType } => {
  const head = typeof heading === 'string' ? { heading } : heading;

  return {
    tabHead: title ?? (typeof head?.heading === 'string' ? head?.heading : ''),
    pageHead: head ?? {},
  };
};

interface ITablePageProps {
  /**
   * @deprecated - по завершению перехода на новый дизайн [в админках] убрать isRoundPage
   */
  isRoundPage?: boolean;
  title?: string;
  heading: string | PageHeaderType;
  rightColumn?: ReactNode;
  children: ReactNode;
}

const TablePage: FC<ITablePageProps & MinWidthProps> = ({
  isRoundPage,
  title,
  heading,
  rightColumn,
  children,
  minWidth,
}) => {
  const { pageHead } = getHeading(title, heading);

  return (
    <Fragment>
      <MetaHead />
      <MainContent id='MainContent'>
        {heading && <PageHeader {...pageHead} />}
        <Container
          flexDirection='row'
          height={isRoundPage ? '100%' : 'auto'}
          overflow={isRoundPage ? 'auto' : 'initial'}
          width='100%'
        >
          <TableMain column={!isRoundPage} minWidth={minWidth}>
            {children}
          </TableMain>
          {rightColumn && <Container column>{rightColumn}</Container>}
        </Container>
      </MainContent>
    </Fragment>
  );
};

interface IToolsPanelPageProps {
  skeletonComponent?: ReactNode;
  notice?: ReactNode;
  children: ReactNode;
  rightColumn?: ReactNode;
  bg?: string;
}

const ToolsPanelPage: FC<IToolsPanelPageProps & IActionPanelProps> = ({
  skeletonComponent,
  sections,
  notice,
  rightAddon,
  children,
  onShowTooltip,
  rightColumn,
  bg = 'white',
}) => {
  const isLoading = !!skeletonComponent;

  return (
    <Fragment>
      <MetaHead />
      <MainContent bg={bg} id='MainContent'>
        {!isLoading && (
          <Fragment>
            <ActionPanel
              rightAddon={rightAddon}
              sections={sections}
              onShowTooltip={onShowTooltip}
            />
            {notice && (
              <Container pl={4} pr='20px'>
                {notice}
              </Container>
            )}
          </Fragment>
        )}
        <PageMain pb={6}>
          <PageMainWrapper ml='0px'>
            {skeletonComponent || children}
          </PageMainWrapper>
          {rightColumn && <Container column>{rightColumn}</Container>}
        </PageMain>
      </MainContent>
    </Fragment>
  );
};

interface ICreatePageProps {
  title?: string;
  heading?: string | PageHeaderType;
  subMenu?: ReactNode;
  bottomAddon?: ReactNode;
  children: ReactNode;
}

const CreatePage: FC<ICreatePageProps> = ({
  title,
  heading,
  subMenu,
  bottomAddon,
  children,
}) => {
  const { pageHead } = getHeading(title, heading);
  return (
    <Fragment>
      <MetaHead />
      <MainContent>
        {heading && <PageHeader {...pageHead} />}
        <Container
          flexDirection='row'
          height='100%'
          overflow={subMenu ? 'hidden' : 'auto'}
          width='100%'
        >
          {subMenu ? (
            <Fragment>
              {subMenu}
              <Container
                column
                bg='background'
                flexGrow={1}
                height='100%'
                id='MainContent'
                overflow='auto'
                pt={2}
                width='100%'
              >
                <PageMain column>{children}</PageMain>
              </Container>
            </Fragment>
          ) : (
            <PageMain column pt={2}>
              {children}
            </PageMain>
          )}
        </Container>
        {bottomAddon}
      </MainContent>
    </Fragment>
  );
};

interface ICenteredPageProps {
  title?: string;
  heading?: string | PageHeaderType;
  bg?: string;
  noHeaderBorder?: boolean;
  subMenu?: ReactNode;
  rightColumn?: ReactNode;
  bottomAddon?: ReactNode;
  contentRef?: RefObject<HTMLDivElement>;
  children: ReactNode;
}

const CenteredPageInner: FC<ICenteredPageProps & LayoutProps & SpaceProps> = ({
  title,
  heading,
  height = 'max-content',
  width,
  minWidth,
  maxWidth,
  rightColumn,
  bottomAddon,
  children,
  bg,
  subMenu,
  noHeaderBorder,
  contentRef,
  ...other
}) => {
  const { pageHead } = getHeading(title, heading);

  const commonProps = {
    ref: contentRef,
    column: true,
    mx: 'auto',
    height,
    width,
    maxWidth,
    minWidth,
    ...other,
  };

  return (
    <Fragment>
      <MetaHead />
      <MainContent>
        {heading && <PageHeader {...pageHead} />}
        <Container
          flexDirection='row'
          height='100%'
          overflowX='hidden'
          width='100%'
        >
          {subMenu ? (
            <Fragment>
              {subMenu}
              <Container
                column
                bg={bg}
                flexGrow={1}
                height='100%'
                id='MainContent'
                overflow='auto'
                pt={2}
              >
                <PageMain {...commonProps}>{children}</PageMain>
              </Container>
            </Fragment>
          ) : (
            <Fragment>
              <PageMain {...commonProps}>{children}</PageMain>
              {rightColumn && <Container column>{rightColumn}</Container>}
            </Fragment>
          )}
        </Container>
        {bottomAddon}
      </MainContent>
    </Fragment>
  );
};

const CenteredPage = Object.assign(CenteredPageInner, {
  Wrapper: PageMain,
  CONTENT_WIDTH,
});

interface IDefaultPageProps {
  title?: string;
  heading?: string | PageHeaderType;
  noId?: boolean;
  children: ReactNode;
  color?: string;
  metaInfo?: IMetaHead;
}

const DefaultPage: FC<IDefaultPageProps & ColorProps> = ({
  metaInfo,
  title,
  heading,
  children,
  noId,
  color,
  ...other
}) => {
  const { pageHead } = getHeading(title, heading);

  return (
    <Fragment>
      <MetaHead />
      <MainContent
        color={color}
        {...other}
        id={!noId ? 'MainContent' : undefined}
      >
        {pageHead && <PageHeader {...pageHead} />}
        {children}
      </MainContent>
    </Fragment>
  );
};

export {
  CommonPageWrapper,
  TablePage,
  ToolsPanelPage,
  CreatePage,
  CenteredPage,
  DefaultPage,
};
