import { FC, useEffect, useState } from 'react';

import { Timeout } from 'react-number-format/types/types';
import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { Container, Spinner } from 'UI';

import { useGallery } from '../hooks';

const Stage = styled.div<{ isLoading: boolean }>(
  ({ isLoading }) => css`
    position: absolute;
    display: inline-flex;
    height: 100%;
    width: 50000px;
    opacity: ${isLoading ? 0 : 1};
    transition: opacity 200ms ease-in
      ${!isLoading && ', transform 200ms ease-in'};
  `,
);

const ImageItem = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: ${space[4]}px;
    transition: opacity 200ms ease-in;

    &:last-child {
      margin-right: 0;
    }

    img {
      max-height: 100%;
    }
  `,
);

interface ISlickListProps {
  isInitStage: boolean;
  selectedImageIndex: number;
  images: IImage[];
  onInitStage: (value: boolean) => void;
}

const SlickList: FC<ISlickListProps> = ({
  isInitStage,
  selectedImageIndex,
  images,
  onInitStage,
}) => {
  const [countLoadedImages, setCountLoadedImage] = useState(0);
  const [{ widthSlick, heightSlick }, setSizeSlick] = useState({
    widthSlick: 0,
    heightSlick: 0,
  });
  const [isResizing, setResizing] = useState(false);

  const isLoadedAllImages = countLoadedImages === images.length;

  const { leftOffsetStage, slickRef, stageRef } = useGallery({
    selectedImageIndex,
    isLoadedAllImages,
    isResizing,
  });

  useEffect(() => {
    if (isLoadedAllImages) {
      setSizeSlick({
        widthSlick: slickRef.current!.clientWidth,
        heightSlick: slickRef.current!.clientHeight,
      });
      setResizing(false);
      setTimeout(() => onInitStage(true), 200);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadedAllImages]);

  useEffect(() => {
    let timeout: Nullable<Timeout> = null;
    let timeoutUpdatePosition: Nullable<Timeout> = null;
    const updateResize = (): void => {
      if (!timeout) {
        if (!isResizing) setResizing(true);
        timeout = setTimeout(() => {
          timeout = null;
          setSizeSlick({
            widthSlick: slickRef.current!.clientWidth,
            heightSlick: slickRef.current!.clientHeight,
          });
          clearTimeout(timeoutUpdatePosition!);
          timeoutUpdatePosition = setTimeout(() => {
            setResizing(false);
          }, 300);
        }, 66);
      }
    };

    window.addEventListener('resize', updateResize);

    return () => {
      window.removeEventListener('resize', updateResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container
      ref={slickRef}
      display='block'
      height='100%'
      position='relative'
      width='100%'
    >
      {!isInitStage && <Spinner height='100%' />}
      <Stage
        ref={stageRef}
        isLoading={!isInitStage}
        style={{ transform: `translate3d(${leftOffsetStage}px, 0, 0)` }}
      >
        {images.map((img, index) => (
          <ImageItem
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            style={{
              opacity: selectedImageIndex === index ? 1 : 0.5,
              maxWidth: `${widthSlick}px`,
              height: `${heightSlick}px`,
            }}
          >
            <img
              alt={img.description || img.filename}
              src={getImageURL(img, { size: 'l' })}
              onLoad={() => setCountLoadedImage(prev => prev + 1)}
            />
          </ImageItem>
        ))}
      </Stage>
    </Container>
  );
};

export { SlickList };
