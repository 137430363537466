import {
  CSSProperties,
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import styled from 'styled-components';
import {
  color,
  fontSize,
  space,
  SpaceProps,
  typography,
  TypographyProps,
  WidthProps,
} from 'styled-system';

import { requestProductAccess } from 'common/api/products.api';
import { PartnerProductAccessStatus } from 'common/constants/products.const';
import { useRequest, useSelect } from 'common/hooks';
import { IProduct } from 'common/types/product.types';
import { OrderDirectionType } from 'common/types/sorts.types';
import { PRODUCT_COLUMNS } from 'components/products/columns';
import { TABLE_COLUMNS } from 'components/tableColumns';
import { ModalController, useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { OPERATIONS, Permission, usePermission } from 'entities/permission';
import {
  RequestAllIcon,
  RequestOneIcon,
  RequestPartnerIcon,
} from 'resources/icons/18';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import {
  Button,
  ContextMenu,
  ContextMenuItem,
  DataTable,
  Divider,
  Pagination,
  TableHeader,
  Text,
} from 'UI';

import {
  ProductContextMenu,
  SELECT_PARTNERS,
  SELECT_PRODUCT_PARTNERS,
  SelectPartnersModal,
  SelectProductPartnersModal,
} from './components';
import { fetchPartnersProducts } from '../products/api';
import {
  ACCESS_STATUSES_COLORS,
  ACCESS_STATUSES_NAMES,
} from '../products/constants';

interface AccessStatusProps extends SpaceProps, TypographyProps, WidthProps {
  style?: CSSProperties;
}

export const AccessStatus = styled.span<AccessStatusProps>`
  display: inline-block;
  white-space: nowrap;

  ${fontSize};
  ${space};
  ${typography};
  ${color};
`;

const formatNotification = (count): string => {
  return count > 1 ? 'Запросы успешно высланы' : 'Запрос успешно выслан';
};

const renderStatus = (status): JSX.Element => (
  <AccessStatus color={ACCESS_STATUSES_COLORS[status]} fontSize='14px'>
    {ACCESS_STATUSES_NAMES[status]}
  </AccessStatus>
);

interface IProductsTable {
  page: number;
  itemsPerPage: number;
  orderBy: string;
  orderDirection: OrderDirectionType;
  partnerId: string;
  searchValue: string;
  productionState: string;
  productionDateAfter: string;
  accessStatus: string;
  overallAccessStatus: string;
  onSort: (key: string, direction: string) => void;
  onStatusSelect: (status?: string, value?: string) => void;
  onSearchDisabled: (length: number) => void;
}

const PartnerProductsTable: FC<IProductsTable> = memo(
  ({
    page,
    itemsPerPage,
    orderBy,
    orderDirection,
    partnerId,
    searchValue,
    productionState,
    productionDateAfter,
    accessStatus,
    overallAccessStatus,
    onSort,
    onStatusSelect,
    onSearchDisabled,
  }) => {
    const notify = useNotify();
    const { handleModalOpen, handleModalClose } = useModalContext();

    const hasRequestPartnerProductAccess = usePermission(
      OPERATIONS.PRODUCTS_REQUESTED.REQUEST_PARTNER_PRODUCT,
    );

    const [anchorEl, setAnchorEl] = useState(null);

    const handleDropdownOpen = useCallback((event): void => {
      setAnchorEl(event.target);
    }, []);

    const handleDropdownClose = useCallback((): void => {
      setAnchorEl(null);
    }, []);

    const {
      isLoading,
      data: products = { member: [] },
      updateData,
    } = useRequest(fetchPartnersProducts, [
      {
        page,
        itemsPerPage,
        orderBy,
        orderDirection,
        partnerId,
        searchValue,
        productionState,
        productionDateAfter,
        accessStatus,
        overallAccessStatus,
      },
    ]);

    const { isSelectedAll, selected, bindItem, handleSelectAll } = useSelect({
      options: products.member,
      shouldReset: { page, partnerId },
    });

    useEffect(() => onSearchDisabled(products.member.length));

    const handleRequestAccess = useCallback(
      (partnersIds: string[]): Promise<void> => {
        return requestProductAccess(
          selected.reduce(
            (acc, product) => [
              ...acc,
              ...partnersIds.map(originCompanyId => ({
                productId: product.id,
                originCompanyId,
              })),
            ],
            [],
          ),
        ).then(
          () => {
            handleSelectAll(false);
            updateData();
            handleModalClose();
            notify.success(formatNotification(partnersIds.length));
          },
          error => {
            const data = error?.response?.data;
            handleModalClose();
            notify.error(data['hydra:description']);
          },
        );
      },
      [selected, handleSelectAll, updateData, handleModalClose, notify],
    );

    const handleMultipleRequest = useCallback((): void => {
      const companiesAccesses = Object.values(
        selected.reduce(
          (acc, product) => ({ ...acc, ...product.accessByCompany }),
          {},
        ),
      );

      handleModalOpen(SELECT_PARTNERS, {
        product: companiesAccesses,
        onConfirm: handleRequestAccess,
      });
    }, [selected, handleModalOpen, handleRequestAccess]);

    const handleRequestFromAllPartners = useCallback((): void => {
      const companiesAccesses: any[] = Object.values(
        selected.reduce(
          (acc, product) => ({ ...acc, ...product.accessByCompany }),
          {},
        ),
      );

      handleRequestAccess(companiesAccesses.map(item => item.originCompany.id));
    }, [selected, handleRequestAccess]);

    const handleRequestProductAccess = useCallback(
      (partnersIds: string[], product: IProduct): Promise<void> => {
        return requestProductAccess(
          partnersIds.map(originCompanyId => ({
            productId: product.id,
            originCompanyId,
          })),
        )
          .then(() => {
            updateData();
            handleModalClose();
            notify.success(formatNotification(partnersIds.length));
          })
          .catch(({ response: { data } }) => {
            handleModalClose();
            notify.error(data['hydra:description']);
          });
      },
      [updateData, handleModalClose, notify],
    );

    const handleRequestAccessFromPartner = useCallback((): Promise<void> => {
      return requestProductAccess(
        selected.map(product => ({
          productId: product.id,
          originCompanyId: partnerId,
        })),
      )
        .then(() => {
          handleSelectAll(false);
          updateData();
          notify.success(formatNotification(selected.length));
        })
        .catch(({ response: { data } }) => {
          handleSelectAll(false);
          notify.error(data['hydra:description']);
        });
    }, [selected, partnerId, handleSelectAll, updateData, notify]);

    const handleRequestProductFromPartner = useCallback(
      (productId: string): void => {
        requestProductAccess([{ productId, originCompanyId: partnerId }]).then(
          () => {
            updateData();
            notify.success(formatNotification(1));
          },
        );
      },
      [updateData, notify, partnerId],
    );

    const columns = [
      hasRequestPartnerProductAccess &&
        TABLE_COLUMNS.checkbox({
          disabled: !isLoading && !products.member.length,
          isSelectedAll,
          bindItem,
          handleSelectAll,
        }),
      PRODUCT_COLUMNS.previewImage(),
      PRODUCT_COLUMNS.mainProperties,
      PRODUCT_COLUMNS.name(),
      PRODUCT_COLUMNS.category,
      PRODUCT_COLUMNS.type(),
      PRODUCT_COLUMNS.companyName(),
      PRODUCT_COLUMNS.taskStatus,
      partnerId && {
        key: 'accessStatus',
        header: { fixedWidth: 156, text: 'Статус по партнеру' },
        render: (_, item) =>
          item.accessByCompany[partnerId]
            ? renderStatus(item.accessByCompany[partnerId].accessStatus)
            : undefined,
      },
      {
        key: 'overallAccessStatus',
        header: { fixedWidth: 120, text: 'Статус общий' },
        render: renderStatus,
      },
      {
        key: 'controls',
        sortable: false,
        truncate: false,
        header: { fixedWidth: 60 },
        render: (_, product) => (
          <ProductContextMenu
            product={product}
            onRequestProduct={() =>
              handleModalOpen(SELECT_PRODUCT_PARTNERS, {
                product,
                onConfirm: handleRequestProductAccess,
              })
            }
            onRequestProductFromPartner={
              partnerId && (() => handleRequestProductFromPartner(product.id))
            }
          />
        ),
      },
    ].filter(Boolean);

    return (
      <>
        <TableHeader>
          <Permission
            operation={OPERATIONS.PRODUCTS_REQUESTED.REQUEST_PARTNER_PRODUCT}
          >
            <TableHeader.Button
              disabled={selected.length < 1}
              icon={<RequestOneIcon pr={0} />}
              text='Отправить запрос'
              onClick={handleMultipleRequest}
            />
          </Permission>
          <Permission
            operation={OPERATIONS.PRODUCTS_REQUESTED.REQUEST_PARTNER_PRODUCT}
          >
            {partnerId ? (
              <TableHeader.Button
                disabled={selected.length < 1}
                icon={<RequestPartnerIcon pr={0} />}
                text='Запросить у партнера'
                onClick={handleRequestAccessFromPartner}
              />
            ) : (
              <TableHeader.Button
                disabled={selected.length < 1}
                icon={<RequestAllIcon pr={0} />}
                text='Запросить у всех'
                onClick={handleRequestFromAllPartners}
              />
            )}
          </Permission>
          <Button
            color='secondary'
            ml='auto'
            variant='string'
            onClick={anchorEl ? handleDropdownClose : handleDropdownOpen}
          >
            <Text fontSize={2} mr={1}>
              Отображение
            </Text>
            {anchorEl ? (
              <ArrowUp1pxIcon size={10} style={{ pointerEvents: 'none' }} />
            ) : (
              <ArrowDown1pxIcon size={10} style={{ pointerEvents: 'none' }} />
            )}
          </Button>
          <ContextMenu
            anchorEl={anchorEl}
            open={!!anchorEl}
            width={partnerId ? '220px' : '146px'}
            onRequestClose={handleDropdownClose}
          >
            <ContextMenuItem
              fontSize={2}
              text='Все товары'
              onClick={() => {
                onStatusSelect();
                handleDropdownClose();
              }}
            />
            <Divider />
            {Object.values(PartnerProductAccessStatus).map(status => (
              <ContextMenuItem
                key={status}
                active={status === overallAccessStatus}
                fontSize={2}
                text={ACCESS_STATUSES_NAMES[status]}
                onClick={() => {
                  onStatusSelect('overallAccessStatus', status);
                  handleDropdownClose();
                }}
              />
            ))}
            {partnerId && (
              <Fragment>
                <Divider />
                {Object.values(PartnerProductAccessStatus).map(status => (
                  <ContextMenuItem
                    key={status}
                    active={status === accessStatus}
                    fontSize={2}
                    text={`${ACCESS_STATUSES_NAMES[status]} у партнера`}
                    onClick={() => {
                      onStatusSelect('accessStatus', status);
                      handleDropdownClose();
                    }}
                  />
                ))}
              </Fragment>
            )}
          </ContextMenu>
        </TableHeader>
        <DataTable
          isInnerTable
          columns={columns}
          data={products.member}
          loading={isLoading}
          placeholder={{ type: 'matches' }}
          {...{ orderBy, orderDirection }}
          rowStyle={item => {
            const { isSelected } = bindItem(item);
            return isSelected ? { backgroundColor: 'highlight.0' } : undefined;
          }}
          onSort={onSort}
        />
        <Pagination totalItems={products.totalItems} />
        <ModalController
          component={SelectPartnersModal}
          type={SELECT_PARTNERS}
        />
        <ModalController
          component={SelectProductPartnersModal}
          type={SELECT_PRODUCT_PARTNERS}
        />
      </>
    );
  },
);

export { PartnerProductsTable };
