import { Fragment, useMemo, useReducer, FC } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { getProductPropertiesPrimitive } from 'common/utils/properties';
import {
  EditTemplates,
  ChildrenUnionWrapper,
} from 'components/characteristic-elements/EditMode';
import { Notice, Text } from 'UI';

import { SelectKeyPropertyView } from './KeyProperty';
import { getInitialValueOfProperty } from '../../_shared/utils';

const TableContainer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 0;
    margin-right: ${space[1]}px;
  `,
);

const selectedTableToString = type => value => {
  return getProductPropertiesPrimitive({ type, value }, true);
};

interface IKeyProperty {
  id: string;
  name: string;
  type:
    | PropertiesTypes.number
    | PropertiesTypes.range
    | PropertiesTypes.twoDimensions
    | PropertiesTypes.threeDimensions
    | PropertiesTypes.string
    | PropertiesTypes.multiString;
  unit: string;
  values: Array<{ id: string; value: any }>;
}

type PropertiesType = Array<{
  id: string;
  name: string;
  type:
    | PropertiesTypes.number
    | PropertiesTypes.range
    | PropertiesTypes.twoDimensions
    | PropertiesTypes.threeDimensions
    | PropertiesTypes.string
    | PropertiesTypes.multiString;
  unit: string;
  values: any[];
}>;

interface IRegulatoryReferenceSelectedTableProps {
  translation: object;
  disabled?: boolean;
  readonly: boolean;
  currentKeyPropertyId: { value: string; _confirmed: boolean };
  error: any;
  table: { keyProperty: IKeyProperty; properties: PropertiesType };
  setKeyPropertyId: (string) => void;
}

interface IPropertyState {
  keyProperty: IKeyProperty;
  properties: PropertiesType;
  selectedKeyPropertyId: Nullable<string>;
  selectedProperties: any[];
}

interface IPropertyAction {
  columnIndex?: number;
  selectedValue?: any;
  nextSelectedKeyPropertyId?: string;
}

function propertyReducer(
  state: IPropertyState,
  { nextSelectedKeyPropertyId, columnIndex, selectedValue }: IPropertyAction,
): any {
  const { keyProperty, properties } = state;

  if (nextSelectedKeyPropertyId) {
    const rowIndex = keyProperty.values.findIndex(
      ({ id }) => id === nextSelectedKeyPropertyId,
    );

    return {
      ...state,
      selectedKeyPropertyId: nextSelectedKeyPropertyId,
      selectedProperties: properties.map(({ values }) => values[rowIndex]),
    };
  }

  if ((columnIndex || columnIndex === 0) && selectedValue) {
    const rowIndex = properties[columnIndex].values.indexOf(selectedValue);

    const selectedKeyPropertyId = keyProperty.values[rowIndex].id;

    return {
      ...state,
      selectedKeyPropertyId,
      selectedProperties: properties.map(({ values }) => values[rowIndex]),
    };
  }

  return state;
}

const RegulatoryReferenceSelectedTable: FC<
  IRegulatoryReferenceSelectedTableProps
> = ({
  disabled,
  readonly,
  currentKeyPropertyId,
  error,
  table,
  translation,
  setKeyPropertyId,
}) => {
  const { keyProperty, properties } = table;

  const currentKeyPropertyValue = useMemo(() => {
    return currentKeyPropertyId.value
      ? keyProperty.values.find(({ id }) => currentKeyPropertyId.value === id)!
          .value
      : null;
  }, [currentKeyPropertyId, keyProperty.values]);

  const [{ selectedKeyPropertyId, selectedProperties }, dispatchProperty] =
    useReducer(propertyReducer, {
      keyProperty,
      properties,
      selectedKeyPropertyId: currentKeyPropertyId.value,
      selectedProperties: properties.map(
        ({ type }) => getInitialValueOfProperty(type).value,
      ),
    });

  const handleEditSelectedTable = (): void => {
    setKeyPropertyId({ _confirmed: false });
  };

  if (currentKeyPropertyId._confirmed || readonly) {
    return (
      <SelectKeyPropertyView
        disabled={disabled}
        keyProperty={table.keyProperty}
        readonly={readonly}
        translation={translation}
        value={currentKeyPropertyValue}
        onToggleConfirmed={handleEditSelectedTable}
      />
    );
  }

  const handleSubmit = (): void => {
    setKeyPropertyId({ value: selectedKeyPropertyId, _confirmed: true });
  };

  const handleCancel = (): void => {
    dispatchProperty({ nextSelectedKeyPropertyId: currentKeyPropertyId.value });
    setKeyPropertyId({ _confirmed: true });
  };

  if (!properties.length) return null;

  return (
    <Fragment>
      <Notice hasIcon={false} my={2} size='xxs'>
        Для подбора заполните и сохраните все предложенные характеристики.
      </Notice>
      <EditTemplates.SimpleCharacteristicContainer
        cancelButton={
          currentKeyPropertyId.value
            ? { title: 'Отменить', onCancel: handleCancel }
            : undefined
        }
        errorDescription={error}
        highlightErrorContainer={Boolean(error)}
        submitButton={{
          disabled: !selectedKeyPropertyId,
          onSubmit: handleSubmit,
        }}
      >
        <TableContainer>
          {properties.map(({ id, name, type, values }, index) => (
            <ChildrenUnionWrapper key={id} m='0px'>
              <EditTemplates.InputContainer
                {...(index === 0 && { 'data-border-top': true })}
                {...(index === properties.length - 1 && {
                  'data-border-bottom': true,
                })}
                data-border-left
                disabled
                style={{ width: '50%' }}
              >
                <Text truncate>{name}</Text>
              </EditTemplates.InputContainer>
              <EditTemplates.Select
                {...(index === 0 && { 'data-border-top': true })}
                {...(index === properties.length - 1 && {
                  'data-border-bottom': true,
                })}
                data-border-right
                disablePortal={false}
                itemToString={selectedTableToString(type)}
                options={values}
                placeholder='Выберите значение'
                style={{ width: '50%' }}
                value={selectedProperties[index]}
                onChange={selectedValue =>
                  dispatchProperty({ columnIndex: index, selectedValue })
                }
              />
            </ChildrenUnionWrapper>
          ))}
        </TableContainer>
      </EditTemplates.SimpleCharacteristicContainer>
    </Fragment>
  );
};

export { RegulatoryReferenceSelectedTable };
