import styled, { css } from 'styled-components';

export const FiltersPanel = styled.div<{ isShow: boolean }>(
  ({ theme: { colors, borderRadius, shadows, space }, isShow }) => css`
    display: none;
    flex-direction: column;
    position: absolute;
    top: 36px;
    right: 0;
    left: 0;
    z-index: 2;
    width: 300px;
    max-height: 576px;
    padding: ${space[2]}px;
    background: ${colors.white};
    border-radius: ${borderRadius}px;
    box-shadow: ${shadows[2]};

    ${isShow &&
    css`
      display: flex;
    `}
  `,
);

export const FiltersButton = styled.button<{ hasFilters: boolean }>(
  ({ theme: { colors, space, fontSizes, borderRadius }, hasFilters }) => css`
    display: flex;
    align-items: center;
    height: ${space[4]}px;
    padding: ${space[1]}px 10px;
    font-size: ${fontSizes[3]}px;
    font-weight: 500;
    color: ${colors.text.secondary};
    background: ${colors.highlight[0]};
    border-radius: ${borderRadius}px;

    &:hover {
      background: ${colors.highlight[2]};
      color: ${colors.text.primary};
    }

    ${hasFilters &&
    css`
      background: ${colors.text.secondary};
      color: ${colors.white};

      &:hover {
        background: ${colors.text.secondary};
        color: ${colors.white};
      }
    `}
  `,
);
