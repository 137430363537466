import { FC } from 'react';

import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  borderColor,
  BorderColorProps,
} from 'styled-system';

import { FullModal } from 'components/modals';
import { IModalProps } from 'entities/modals';
import { Help1pxIcon } from 'resources/icons/1px-18';
import {
  ProductsIcon,
  SearchLargeIcon,
  PartnersIcon,
  CatalogIcon,
  FillingIcon,
  FaveIcon,
  ExportIcon,
  EmployeIcon,
} from 'resources/icons/24';
import { ApprovedIcon, EmployeesIcon } from 'resources/other-24';
import { COMPANY, INFORMATION } from 'routes';
import { Heading, Text, Badge, Button, Container } from 'UI';

const SELECT_ROLE = 'SELECT_ROLE';

const StyledBadge = styled(Badge)(
  ({ theme: { space } }) => css`
    position: absolute;
    top: -${space[1]}px;
    left: 50%;
    transform: translateX(-50%);
  `,
);

const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    width: 1220px;
    margin: ${space[4]}px auto;
    padding: 0 ${space[4]}px;
    overflow: auto;
  `,
);

const Card = styled.div<ColorProps & BorderColorProps>(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 480px;
    height: 505px;
    padding: ${space[4]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${space[3]}px;

    ${color};
    ${borderColor};
  `,
);

const ListItem = styled.div(
  ({ theme: { fontSizes, colors, space } }) => css`
    display: flex;
    align-items: flex-start;
    font-size: ${fontSizes[5]}px;

    & > div {
      margin-right: ${space[2]}px;
      & svg {
        color: ${colors.primary.main};
      }
    }
  `,
);

const SelectRoleModal: FC<IModalProps> & {
  TYPE: string;
} = ({ handleModalClose }) => {
  const location = useLocation();

  return (
    <FullModal>
      <Wrapper>
        <Heading mb={1}>
          Ваш{' '}
          <Text color='primary.main' style={{ display: 'inline-block' }}>
            следующий шаг
          </Text>{' '}
          работы с системой
        </Heading>
        <Text fontSize={5} mb={4}>
          Чтобы расширить возможности istock.info, зарегистрируйте в системе
          собственную компанию.
        </Text>
        <Container gap={2} justifyContent='center' mb={5}>
          <Card>
            <StyledBadge
              bg='background'
              label='текущий статус'
              type={Badge.TYPES.SECONDARY}
            />
            <Heading as='h2' mb={1} textAlign='center'>
              Продолжить в статусе физ. лица
            </Heading>
            <Badge
              fontWeight={700}
              label='физическим лицам'
              mb={3}
              style={{ width: 'max-content', alignSelf: 'center' }}
              type={Badge.TYPES.PRIMARY}
            />
            <Container column gap={2}>
              <ListItem>
                <CatalogIcon />
                Справочники стандартизированной информации
              </ListItem>
              <ListItem>
                <SearchLargeIcon />
                Поиск по системе
              </ListItem>
              <ListItem>
                <ProductsIcon />
                Просмотр товаров, мест продаж, компаний
              </ListItem>
              <ListItem>
                <FaveIcon />
                Избранные товары, подписка на обновления
              </ListItem>
            </Container>
            <Button
              mt='auto'
              size='l'
              variant='outlined'
              onClick={handleModalClose}
            >
              Продолжить в статусе физ. лица
            </Button>
          </Card>
          <Card bg='background' borderColor='transparent'>
            <Heading as='h2' mb={1} textAlign='center'>
              Регистрация компании
            </Heading>
            <Badge
              fontWeight={700}
              label='владельцам компаний'
              mb={3}
              style={{ width: 'max-content', alignSelf: 'center' }}
              type={Badge.TYPES.PRIMARY}
            />
            <Container column gap={2}>
              <ListItem>
                <EmployeIcon />
                Все функции физического лица
              </ListItem>
              <ListItem>
                <ApprovedIcon />
                Официальная страница компании в системе
              </ListItem>
              <ListItem>
                <PartnersIcon />
                Поиск партнеров, партнерские сети и обмен информацией
              </ListItem>
              <ListItem>
                <FillingIcon />
                Добавление своих товаров, мест продаж и услуг в систему
              </ListItem>
              <ListItem>
                <ExportIcon />
                Выгрузка информации из системы
              </ListItem>
              <ListItem>
                <EmployeesIcon />
                Добавление сотрудников для совместной работы в системе
              </ListItem>
            </Container>
            <Button
              as={Link}
              mt='auto'
              size='l'
              to={{
                pathname: COMPANY.REGISTRATION,
                state: { from: location.pathname },
              }}
              onClick={handleModalClose}
            >
              Зарегистрировать компанию
            </Button>
          </Card>
        </Container>
        <Container alignItems='center' justifyContent='center' mt='auto'>
          <Help1pxIcon mr={1} />
          <Text color='text.secondary'>
            Подробнее в статье{' '}
            <Link target='_blank' to={INFORMATION.GUIDES.USERS_STATUSES}>
              Статусы и возможности пользователей
            </Link>
          </Text>
        </Container>
      </Wrapper>
    </FullModal>
  );
};

SelectRoleModal.TYPE = SELECT_ROLE;

export { SelectRoleModal };
