import { FC, Fragment, useState } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { IPackage } from 'common/types/product.types/accompany-information.types';
import { getFormattedNumber } from 'common/utils';
import { transformPackages } from 'common/utils/products/accompany-information/package-variations';
import { ModalController, useModalContext } from 'entities/modals';
import { PackageLinkIcon } from 'resources/accompany-information';
import { useToggle } from 'tools/hooks';
import { Heading, Switch, Text, ToggleButton, Tooltip } from 'UI';

import { PackageCard } from './Card';
import { PackagesSidebar } from './Sidebar';
import { PACKAGE_KINDS } from '../../../../features/products/constants';

const PACKAGE_SIDEBAR = 'PACKAGE_SIDEBAR';

const HeaderWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 21px 0 ${space[2]}px;
  `,
);

const VariationsSelectWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-wrap: wrap;

    & > label {
      margin-bottom: ${space[1]}px;
    }

    ${styledSpace};
  `,
);

const PackagesListWrapper = styled.div<SpaceProps>(
  ({ theme: { space, colors, fontSizes } }) => css`
    margin-bottom: ${space[1]}px;
    padding: ${space[3]}px;
    background: ${colors.background};
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
    border-radius: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    ${styledSpace};
  `,
);

const PackageWrapper = styled.div<{ disabled: boolean } & SpaceProps>(
  ({ disabled }) => css`
    display: flex;
    align-items: center;
    width: 100%;

    ${disabled &&
    css`
      opacity: 0.3;
      pointer-events: none;
    `}

    ${styledSpace};
  `,
);

const QuantityLabelWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  min-width: 70px;
  height: 70px;

  & svg {
    display: block;
    margin-left: auto;
  }
`;

const QuantityLabel = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    position: absolute;
    top: 50%;
    left: 55%;
    transform: translate(-50%, -50%);
    display: block;
    min-width: 20px;
    max-width: 46px;
    height: 20px;
    padding: 3px ${space[0]}px;
    background: ${colors.text.primary};
    color: ${colors.white};
    border-radius: 20px;
    font-weight: 600;
    font-size: ${fontSizes[1]}px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    z-index: 1;

    ${styledSpace};
  `,
);

const EmptyPackageVariation = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: ${space[3]}px;
    margin-bottom: ${space[1]}px;
    padding: ${space[3]}px;
    background: ${colors.background};
    border-radius: 8px;
  `,
);

const PACKAGE_CARD_OFFSET = 70;
const PACKAGE_VARIATIONS_MIN_LENGTH = 3;

interface IPackageVariationsList {
  showOnlyConsumer: boolean;
  packages: any;
  packageVariationName: string;
}

const PackageVariationsList: FC<IPackageVariationsList> = ({
  showOnlyConsumer,
  packages,
  packageVariationName,
}) => {
  const { handleModalOpen } = useModalContext();
  if (packages.length === 0) {
    return (
      <Text color='text.secondary' fontSize={5}>
        Вариант упаковки не заполнен
      </Text>
    );
  }

  return (
    <>
      {packages.map((productPackage, index) => {
        const isTransportPackage =
          productPackage.kindOfPackage === PACKAGE_KINDS.TRANSPORT;
        const isPrimaryPackage = index + 1 === packages.length;
        const packageCardOffset =
          index > 1 ? (index - 1) * PACKAGE_CARD_OFFSET : 0;
        const quantityInOuterPackage = packages[index - 1]?.quantity.value;
        const globalPackageUnit = packages[packages.length - 1]?.quantity.unit;
        const quantityInOuterPackageFormatted = getFormattedNumber(
          quantityInOuterPackage,
        );

        return (
          <PackageWrapper
            key={productPackage.id}
            disabled={showOnlyConsumer && isTransportPackage}
            pl={`${packageCardOffset}px`}
          >
            {index > 0 && (
              <QuantityLabelWrapper>
                <Tooltip
                  arrow
                  placement='bottom'
                  title={`Упаковок внутри: ${quantityInOuterPackageFormatted}`}
                >
                  <QuantityLabel>
                    {quantityInOuterPackageFormatted}
                  </QuantityLabel>
                </Tooltip>
                <PackageLinkIcon />
              </QuantityLabelWrapper>
            )}
            <PackageCard
              globalPackageUnit={globalPackageUnit}
              isPrimaryPackage={isPrimaryPackage}
              productPackage={productPackage}
              onSidebarOpen={() => {
                handleModalOpen(PACKAGE_SIDEBAR, {
                  packageIndex: index,
                  packages,
                  packageVariationName,
                });
              }}
            />
          </PackageWrapper>
        );
      })}
    </>
  );
};

interface IProductPackagesList {
  packageVariations: {
    id: string;
    name: string;
    hasConsumerPackages: boolean;
    packages: IPackage[];
  }[];
}

const ProductPackagesList: FC<IProductPackagesList> = ({
  packageVariations,
}) => {
  const [showOnlyConsumer, setShowOnlyConsumer] = useToggle();
  const [selectedVariation, setSelectedVariation] = useState(
    packageVariations[0],
  );
  const allVariationsEmpty = packageVariations.every(
    variation => variation.packages.length === 0,
  );

  return (
    <Fragment>
      <HeaderWrapper>
        <Text fontSize={4} fontWeight={500}>
          Вариантов: {packageVariations.length}
        </Text>
        <Switch
          checked={showOnlyConsumer}
          disabled={allVariationsEmpty}
          fontSize={2}
          label='Только потребительские упаковки'
          onChange={setShowOnlyConsumer}
        />
      </HeaderWrapper>
      {packageVariations.length <= PACKAGE_VARIATIONS_MIN_LENGTH ? (
        packageVariations.map(({ id, name, packages }) => {
          if (packages.length === 0) {
            return (
              <EmptyPackageVariation>
                <Text fontSize={4} fontWeight={500}>
                  {name}
                </Text>
                <Text color='text.secondary' fontSize={5}>
                  Вариант упаковки не заполнен
                </Text>
              </EmptyPackageVariation>
            );
          }

          return (
            <PackagesListWrapper key={id}>
              <Heading as='h5' mb={3}>
                {name}
              </Heading>
              <PackageVariationsList
                packages={transformPackages(packages)}
                packageVariationName={name}
                showOnlyConsumer={showOnlyConsumer}
              />
            </PackagesListWrapper>
          );
        })
      ) : (
        <Fragment>
          <VariationsSelectWrapper>
            {packageVariations.map(variation => {
              return (
                <ToggleButton.Tab
                  key={variation.id}
                  checked={selectedVariation.id === variation.id}
                  disabled={showOnlyConsumer && !variation.hasConsumerPackages}
                  label={variation.name}
                  onClick={() => setSelectedVariation(variation)}
                />
              );
            })}
          </VariationsSelectWrapper>
          <PackagesListWrapper mt='12px'>
            <PackageVariationsList
              packages={transformPackages(selectedVariation.packages)}
              packageVariationName={selectedVariation.name}
              showOnlyConsumer={showOnlyConsumer}
            />
          </PackagesListWrapper>
        </Fragment>
      )}
      <ModalController component={PackagesSidebar} type={PACKAGE_SIDEBAR} />
    </Fragment>
  );
};

export { ProductPackagesList };
