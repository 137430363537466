import { FC, Fragment, memo } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { DOTTED_DATE_PATTERN } from 'common/constants/datePatterns.const';
import { LINK_INTEGRATION_PATH } from 'common/constants/link-integration.const';
import {
  ILinkRequest,
  LinkRequestStatus,
} from 'common/types/link-requests.types';
import { formatDate } from 'common/utils';
import { hasIdentity } from 'entities/identity';
import { TimeIcon } from 'resources/icons/18';
import { COMPANY, getPath, IDENTITY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import { Avatar, Container, Text, Badge, Button, Tooltip } from 'UI';

import {
  REQUEST_STATUS_STATE_COLORS,
  REQUEST_STATUS_STATE_NAMES,
} from './constants';

const CardContainer = styled.div(
  ({ theme: { colors, space, shadows } }) => css`
    display: flex;
    width: 100%;
    background: ${colors.white};
    box-shadow: ${shadows[0]};
    margin-bottom: ${space[2]}px;
    padding: ${space[3]}px;
    border-radius: 8px;
    gap: ${space[3]}px;
  `,
);

const DAY_IN_MS = 86400000; // 1 day
const LINK_URL = `${LINK_INTEGRATION_PATH}/quick-response/`;

const RequestCard: FC<{ request: ILinkRequest }> = memo(
  ({
    request: {
      id,
      title,
      status,
      categories,
      createdAt,
      responseEndDate,
      company,
    },
  }) => {
    const theme = useTheme();
    const isAuthed = useAppSelector(hasIdentity);

    const showClockIcon =
      new Date(responseEndDate).getTime() - new Date().getTime() < DAY_IN_MS;

    const LINK_REDIRECT = `${LINK_URL}${id}`;

    const isRequestsCompleted = status === LinkRequestStatus.receptionCompleted;

    const registeredCompanyLink = getPath(COMPANY.CARD, {
      id: company.id,
    });

    const GOTO_REQUEST_LINK = isAuthed ? LINK_REDIRECT : IDENTITY.REGISTRATION;

    return (
      <CardContainer>
        <Container column gap={2} minWidth='0' width='100%'>
          <Container>
            <Text color='text.disabled' fontSize='18px' fontWeight={600}>
              № {id}
            </Text>
            <Badge
              dot={REQUEST_STATUS_STATE_COLORS[status]}
              fontWeight={600}
              label={REQUEST_STATUS_STATE_NAMES[status]}
              ml='auto'
              type={Badge.TYPES.DEFAULT}
            />
          </Container>
          <Container column>
            <Text fontSize={7} mb={1}>
              {title}
            </Text>
            <Text color='text.disabled' fontSize={2}>
              {categories.join(' / ')}
            </Text>
          </Container>
          <Container alignItems='center'>
            {company.id ? (
              <Fragment>
                <Avatar
                  as={Link}
                  letter={company.name}
                  mr={2}
                  size='s'
                  to={registeredCompanyLink}
                  url={company.logo}
                />
                <Container column overflow='hidden'>
                  <Text
                    truncate
                    as={Link}
                    to={registeredCompanyLink}
                    width='100%'
                  >
                    {company.name}
                  </Text>
                  <Text truncate>{company.address}</Text>
                </Container>
              </Fragment>
            ) : (
              <Fragment>
                <Avatar
                  letter={company.name}
                  mr={2}
                  size='s'
                  url={company.logo}
                />
                <Container column flex={1} overflow='hidden'>
                  <Text truncate fontSize={4} lineHeight='19.5px' width='100%'>
                    {company.name}
                  </Text>
                  <Text truncate color='text.secondary'>
                    {company.address}
                  </Text>
                </Container>
              </Fragment>
            )}
            <Button
              as='a'
              color='info'
              disabled={isRequestsCompleted}
              href={GOTO_REQUEST_LINK}
              minWidth='130px'
              ml='auto'
              size='l'
            >
              {isRequestsCompleted ? 'Завершен' : 'Откликнуться'}
            </Button>
          </Container>
        </Container>
        <Container column minWidth='146px' ml='auto'>
          <Text fontSize={5}>
            {formatDate(createdAt, false, DOTTED_DATE_PATTERN)}
          </Text>
          <Text color='text.disabled'>Дата публикации</Text>
          <Container column alignItems='flex-start' mb='3px' mt='auto'>
            <Container fontSize={5}>
              {formatDate(responseEndDate, false, DOTTED_DATE_PATTERN)}
              {showClockIcon && (
                <Tooltip arrow hint title='Осталось менее 24 часов'>
                  <TimeIcon color={theme.colors.error.main} ml={0} />
                </Tooltip>
              )}
            </Container>
            <Text color='text.disabled'>Срок приема откликов</Text>
          </Container>
        </Container>
      </CardContainer>
    );
  },
);

export { RequestCard };
