import styled, { css } from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

export const Cell = styled.div<{ width?: number } & SpaceProps & FlexboxProps>(
  ({ theme: { colors, space }, width = 214 }) => css`
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: ${width}px;
    height: ${space[7]}px;
    padding: 0 ${space[2]}px;
    background: ${colors.background};

    & + & {
      margin-left: 2px;
    }

    ${styledSpace}
    ${flexbox}
  `,
);
