import { ReactElement, ReactNode } from 'react';

import { Link, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Tooltip, Text } from 'UI';

const Item = styled.div<{ isActive: boolean }>(
  ({ theme: { space, colors, fontSizes, borderRadius }, isActive }) => css`
    position: relative;
    display: block;
    padding: ${space[1]}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[4]}px;
    border-radius: ${borderRadius}px;
    cursor: pointer;
    white-space: nowrap;
    font-weight: 500;
    transition: all 200ms ease-in-out;
    height: ${space[4]}px;

    &:hover {
      color: ${colors.text.primary};
      background: ${colors.highlight[0]};
    }

    ${isActive &&
    css`
      &,&:hover {
      color: ${colors.text.primary};
      background: ${colors.highlight[0]};
    `}
  `,
);

interface IMainMenuItem {
  exact?: boolean;
  disabled?: boolean;
  to?: string;
  pathMatch?: string;
  title?: string;
  children: ReactNode;
}

function MainMenuItem({
  exact,
  to = '',
  pathMatch = to,
  disabled,
  title,
  children,
}: IMainMenuItem): ReactElement {
  const { pathname } = useLocation();

  let content = (
    <Item
      as={Link}
      isActive={exact ? pathname === pathMatch : pathname.startsWith(pathMatch)}
      to={to}
    >
      {children}
    </Item>
  );

  if (disabled) {
    content = (
      <Text color='text.disabled' fontSize={3} fontWeight={500} p={1}>
        {children}
      </Text>
    );
  }

  if (title) {
    return (
      <Tooltip arrow title={title}>
        {content}
      </Tooltip>
    );
  }

  return content;
}

export { MainMenuItem };
