import {
  FC,
  memo,
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import Downshift from 'downshift';
import { SyntheticInputEvent } from 'react-number-format/types/types';
import styled, { css } from 'styled-components';

import {
  IRegulatoryTable,
  IRegulatoryTableValue,
  IRegulatoryTableValueTyped,
  ITechnicalDocumentationBackend,
} from 'common/types/product.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { getProductPropertiesPrimitive } from 'common/utils/properties';
import {
  ChildrenUnionWrapper,
  EditTemplates,
} from 'components/characteristic-elements';
import { useField } from 'reform';
import { SystemSmallIcon } from 'resources/icons/12';
import { CloseMediumIcon } from 'resources/icons/18';
import { AddTagIcon, PalletIcon } from 'resources/other-28';
import { removeItem } from 'tools/utils';
import {
  Container,
  CustomTextArea,
  IconButton,
  IconButtonWrapper,
  Select,
  Text,
} from 'UI';
import { getFilteredItems, Options, useAsyncOptions } from 'UI/Inputs';
import {
  Container as CommonContainer,
  Input,
  SelectedItem,
} from 'UI/Multi/_shared/styled';
import theme from 'UI/theme';

import { VariationsView } from './VariationsView';
import { PropertiesEdit } from '../../../_shared/Edit';
import { AddCommentButton, Limit } from '../../../_shared/styled';
import { fetchTechnicalDocumentationOptions } from '../api';

const TAG_LIMIT = 30;
const CONTAINER_STYLE = { borderRadius: '0px' };
const CONTAINER_DISABLED_STYLE = { ...CONTAINER_STYLE, borderTop: 'none' };
const CONTAINER_ERROR_STYLE = { ...CONTAINER_STYLE, borderRight: 'none' };
const BUTTON_STYLE_DISABLED = {
  alignSelf: 'flex-start',
};
const BUTTON_STYLE = {
  ...BUTTON_STYLE_DISABLED,
  background: theme.colors.highlight[0],
};
const DISABLED_STYLE = { borderWidth: '0 1px 1px 0', alignItems: 'initial' };

const itemToKey = (item): string => item.id;
const itemToKeyPropertyString = (item): string => {
  return `${item.table.keyProperty.name}`;
};
const productItemToString = ({ value }): string => {
  return typeof value === 'object'
    ? Object.values(value).join(' ')
    : String(value);
};

const stateReducer = (_, changes): any => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.changeInput:
    case Downshift.stateChangeTypes.itemMouseEnter:
      return changes;
    default:
      return { ...changes, inputValue: '' };
  }
};

// TODO вынести режим пояснений для ArbitraryMaterialItem.js, здесь и др. компонентов с ним в отдельный компонент

const DisabledContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <EditTemplates.PlaceholderContainer
      height='32px'
      mt='1px'
      pl='0px'
      style={DISABLED_STYLE}
    >
      {children}
    </EditTemplates.PlaceholderContainer>
  );
};

const NormalContainer: FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};

const SelectStyled = styled(Select)<{ hasError: boolean }>(
  ({ theme: { borderRadius, colors }, hasError }) => css`
    margin-top: -1px;
    margin-right: -1px;
    width: auto;
    border-radius: 0 ${borderRadius}px 0 0;

    &:focus-within {
      z-index: 2;
      border-radius: 0;
    }

    ${hasError &&
    css`
      border-top-color: ${colors.error.main};
      border-right-color: ${colors.error.main};
    `}
  `,
);

/**
 * Если ошибка пришла на пустое состояние, то не отображаем ее только в случае
 * изменения ключевой хар-ки или ее значений;
 * в остальных случаях (например, если добавили clarification) - оставляем ошибку
 */
const persistError = ({ prevValue, nextValue, prevError }): any => {
  if (
    !prevValue.allowCustomValues &&
    prevValue.keyPropertyValues?.length === 0 &&
    prevValue.allowCustomValues === nextValue.allowCustomValues &&
    prevValue.keyPropertyValues.length === nextValue.keyPropertyValues.length &&
    prevValue.technicalDocumentation?.id ===
      nextValue.technicalDocumentation?.id
  ) {
    return prevError;
  }

  return null;
};

interface IPropertyEditProps {
  name: string;
  readOnly: boolean;
}

/**
 * Шаг 2.2: Компонент выбора характеристик для одного НТД
 */
const PropertyEdit: FC<IPropertyEditProps> = memo(({ name, readOnly }) => {
  const {
    error,
    value: {
      ntd,
      keyPropertyValues,
      technicalDocumentation,
      _confirmed: isConfirmed,
      allowCustomValues,
      clarification,
    },
    setValue,
  } = useField({ name, persistError });
  const [variations, setVariations] = useState<
    IRegulatoryTable['keyProperty']['values']
  >([]);
  const [clarificationMode, setClarificationMode] = useState(!!clarification);
  const [searchValue, setSearchValue] = useState('');
  const [asyncState, dispatchOptions] = useAsyncOptions();

  const containerRef = useRef<HTMLDivElement>(null);
  const textAreaRef = useRef<HTMLTextAreaElement>();

  const isLimitReached = keyPropertyValues?.length === TAG_LIMIT ?? false;
  const type = (technicalDocumentation as ITechnicalDocumentationBackend)?.table
    ?.keyProperty.type;

  const isBackendProperties =
    keyPropertyValues.length > 0 && typeof keyPropertyValues[0] === 'string';

  const selectedItems = (
    isBackendProperties
      ? technicalDocumentation.table?.keyProperty.values.filter(({ id }) =>
          (keyPropertyValues as string[]).includes(id),
        ) ?? []
      : keyPropertyValues
  ) as IRegulatoryTableValue[];

  const filteredVariations = getFilteredItems({
    isFilteredByInputValue: true,
    items: variations,
    selectedItems,
    itemToKey,
    inputValue: searchValue,
    itemToString: productItemToString,
  });

  const selectKeyField = {
    value: {
      type: PropertiesTypes.arbitraryRegulatoryReference,
    },
    error,
  };

  useEffect(() => {
    if (technicalDocumentation?.table?.keyProperty.values) {
      setVariations(technicalDocumentation.table.keyProperty.values);
    }
  }, [technicalDocumentation]);

  const handleSetKeyProperty = (
    property: ITechnicalDocumentationBackend,
  ): void => {
    setValue(prev => ({ ...prev, technicalDocumentation: property }));
    setValue(prev => ({ ...prev, keyPropertyValues: [] }));
    setValue(prev => ({ ...prev, allowCustomValues: false }));
  };

  const handleVariationsChange = (item: IRegulatoryTableValue): void => {
    setValue(prev => ({
      ...prev,
      keyPropertyValues: [...selectedItems, item],
    }));
  };

  const handleVariationsRemove = (index: number): void => {
    setValue(prev => ({
      ...prev,
      keyPropertyValues: removeItem(selectedItems, index),
    }));
  };

  const handleAllowCustom = (state: boolean): void => {
    setValue(prev => ({ ...prev, allowCustomValues: state }));
  };

  const handleAddClarification = (): void => {
    setClarificationMode(true);
    setTimeout(() => textAreaRef.current?.focus());
  };

  const handleChangeClarification = ({
    target: { value },
  }: SyntheticInputEvent): void => {
    setValue(prev => ({ ...prev, clarification: value }));
  };

  const handleRemoveClarification = (): void => {
    setClarificationMode(false);
    setValue(prev => ({ ...prev, clarification: '' }));
  };

  const handleConfirmed = useCallback(
    (state = false): void => {
      setValue(prev => ({ ...prev, _confirmed: state }));
    },
    [setValue],
  );

  const handleSubmit = (): void => {
    handleConfirmed(true);
  };

  const itemToPropertiesString = useCallback(
    ({
      value,
      type: itemType,
    }: IRegulatoryTableValueTyped): ReturnType<
      typeof getProductPropertiesPrimitive
    > => {
      const usedType = itemType ?? type;
      const item = getProductPropertiesPrimitive({ type: usedType, value });

      if (usedType === PropertiesTypes.multiString) {
        return <Container height='32px'>{item}</Container>;
      }
      return item;
    },
    [type],
  );

  const ContainerType = technicalDocumentation
    ? NormalContainer
    : DisabledContainer;

  if (isConfirmed || readOnly) {
    return (
      <VariationsView
        allowCustomValues={allowCustomValues}
        clarification={clarification}
        itemToPropertiesString={itemToPropertiesString}
        keyPropertyValues={selectedItems}
        readOnly={readOnly}
        technicalDocumentation={technicalDocumentation}
        onToggleConfirmed={handleConfirmed}
      />
    );
  }

  let commonContainerStyle = technicalDocumentation
    ? CONTAINER_STYLE
    : CONTAINER_DISABLED_STYLE;

  if (error) {
    commonContainerStyle = CONTAINER_ERROR_STYLE;
  }

  return (
    <PropertiesEdit
      disabledMenu
      field={selectKeyField}
      header={
        <SelectStyled
          disablePortal={false}
          hasError={!!error}
          itemToKey={itemToKey}
          itemToString={itemToKeyPropertyString}
          mb={technicalDocumentation ? '0px' : '1px'}
          options={fetchTechnicalDocumentationOptions(ntd)}
          placeholder='Выберите ключевую характеристику'
          value={technicalDocumentation}
          onChange={handleSetKeyProperty}
        />
      }
      isValid={
        !!technicalDocumentation &&
        (allowCustomValues || keyPropertyValues.length > 0)
      }
      mb={3}
      mt={2}
      onSubmit={handleSubmit}
    >
      <Container display='block' mr={error ? '1px' : undefined} width='100%'>
        <Downshift
          selectedItem={null}
          stateReducer={stateReducer}
          onChange={handleVariationsChange}
        >
          {downshift => {
            const { inputValue, getRootProps, getInputProps, toggleMenu } =
              downshift;

            if (inputValue !== searchValue) {
              setSearchValue(String(inputValue));
            }

            return (
              <CommonContainer
                {...getRootProps()}
                ref={containerRef}
                style={commonContainerStyle}
              >
                <ContainerType>
                  <Container
                    alignItems='center'
                    flexWrap='wrap'
                    pl={2}
                    pr='0px'
                    py={0}
                    width='100%'
                  >
                    {allowCustomValues && (
                      <SelectedItem>
                        <SystemSmallIcon color='text.secondary' mr={0} />
                        <Text color='text.primary'>Выбор из справочника</Text>
                        <IconButtonWrapper
                          ml={0}
                          onClick={() => handleAllowCustom(false)}
                        >
                          <CloseMediumIcon size={12} />
                        </IconButtonWrapper>
                      </SelectedItem>
                    )}
                    {selectedItems?.map((item, index) => {
                      return (
                        <SelectedItem key={item.id}>
                          <Text truncate maxWidth={200}>
                            {itemToPropertiesString(item)}
                          </Text>
                          <IconButtonWrapper
                            ml={0}
                            onClick={() => handleVariationsRemove(index)}
                          >
                            <CloseMediumIcon size={12} />
                          </IconButtonWrapper>
                        </SelectedItem>
                      );
                    })}
                    {!isLimitReached && (
                      // @ts-ignore
                      <Input
                        {...getInputProps({
                          placeholder: 'Добавить варианты',
                          disabled: !technicalDocumentation,
                          style: { fontSize: `${theme.fontSizes[3]}px` },
                          onKeyDown: event => {
                            if (
                              inputValue === '' &&
                              event.key === 'Backspace'
                            ) {
                              handleVariationsRemove(
                                keyPropertyValues!.length - 1,
                              );
                            }
                          },
                        })}
                      />
                    )}
                  </Container>
                  <Limit mt='7px'>{`${
                    keyPropertyValues?.length ?? 0
                  }/${TAG_LIMIT}`}</Limit>
                  <IconButton
                    disabled={allowCustomValues || !technicalDocumentation}
                    m='2px'
                    size={28}
                    style={
                      allowCustomValues || !technicalDocumentation
                        ? BUTTON_STYLE_DISABLED
                        : BUTTON_STYLE
                    }
                    title={
                      allowCustomValues || !technicalDocumentation
                        ? undefined
                        : 'Добавить выбор из справочника'
                    }
                    onClick={() => handleAllowCustom(true)}
                  >
                    <AddTagIcon />
                  </IconButton>
                  <IconButton
                    disabled={isLimitReached || !technicalDocumentation}
                    m='2px'
                    size={28}
                    style={
                      isLimitReached || !technicalDocumentation
                        ? BUTTON_STYLE_DISABLED
                        : BUTTON_STYLE
                    }
                    title={
                      isLimitReached || !technicalDocumentation
                        ? undefined
                        : 'Библиотека'
                    }
                    onClick={toggleMenu}
                  >
                    <PalletIcon />
                  </IconButton>
                  <Options
                    anchorEl={containerRef.current}
                    disablePortal={false}
                    dispatch={dispatchOptions}
                    // @ts-ignore
                    downshift={downshift}
                    filteredItems={filteredVariations}
                    itemToKey={itemToKey}
                    itemToString={itemToPropertiesString}
                    options={variations}
                    state={asyncState}
                  />
                </ContainerType>
              </CommonContainer>
            );
          }}
        </Downshift>
        {clarificationMode ? (
          <ChildrenUnionWrapper mb='0px'>
            <CustomTextArea
              data-border-bottom
              data-border-right
              inputRef={textAreaRef}
              limit={150}
              name='value.clarification'
              placeholder='Введите пояснение для отображения в виде подсказки у названия характеристики'
              rightAddon={
                <IconButton
                  mr='2px'
                  size={28}
                  title='Удалить пояснение к заполнению'
                  onClick={handleRemoveClarification}
                >
                  <CloseMediumIcon />
                </IconButton>
              }
              value={clarification}
              onChange={handleChangeClarification}
            />
          </ChildrenUnionWrapper>
        ) : (
          <EditTemplates.PlaceholderContainer
            data-border-bottom
            data-border-right
          >
            <AddCommentButton
              key={PropertiesTypes.arbitraryRegulatoryReference}
              mr={0}
              type='button'
              onClick={handleAddClarification}
            >
              + Добавить пояснение к заполнению
            </AddCommentButton>
          </EditTemplates.PlaceholderContainer>
        )}
      </Container>
    </PropertiesEdit>
  );
});

export { PropertyEdit };
