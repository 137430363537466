import { forwardRef, useRef } from 'react';

import styledSystemPropTypes from '@styled-system/prop-types';
import styled from 'styled-components';
import { space } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { pickOmit } from 'common/utils';

import { FormError } from '../FormError';
import { FormLabel } from '../FormLabel';
import { Combobox } from '../Inputs/Combobox';
import { Tooltip } from '../Tooltip';

const FormField = styled.div`
  width: 100%;
  ${space};
`;

const SPACE_PROPS = Object.keys(styledSystemPropTypes.space);

const ComboboxField = forwardRef(
  (
    {
      className,
      style,
      form,
      field: { name, value, error },
      disabled,
      required,
      tip,
      label,
      title,
      onChange,
      ...props
    },
    ref,
  ) => {
    const idRef = useRef(uuid());
    const [spaceProps, comboboxProps] = pickOmit(props, SPACE_PROPS);

    const handleChange = currentValue => form.setFieldValue(name, currentValue);

    return (
      <FormField {...spaceProps} ref={ref} className={className} style={style}>
        <FormLabel
          htmlFor={idRef.current}
          tip={tip}
          required={required}
          disabled={disabled}
        >
          {label}
        </FormLabel>
        <Tooltip title={title} arrow placement='top'>
          <div>
            <Combobox
              {...comboboxProps}
              id={idRef.current}
              disabled={disabled}
              error={error}
              value={value}
              onChange={handleChange}
            />
          </div>
        </Tooltip>
        <FormError show={error} mt={0}>
          {Array.isArray(error) ? error.join('') : error}
        </FormError>
      </FormField>
    );
  },
);

export { ComboboxField };
