import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { ACCESS_ROLES, hasAccessRole } from 'entities/identity';
import {
  EditMediumIcon,
  TrashIcon,
  CopyIcon,
  ContextVIcon,
} from 'resources/icons/18';
import { getPath } from 'routes';
import { useAppSelector } from 'store';
import {
  ContextMenu,
  ContextMenuItem,
  HiddenTableAction,
  useContextMenu,
} from 'UI';

const ControlsWrapper = styled.div`
  display: inline-flex;
`;

interface IAdminPanelControl {
  isHidden?: boolean;
  elementId: string;
  elementPath: string;
  elementCopyPath?: string;
  onDelete: () => void;
}

function AdminPanelControl({
  isHidden = false,
  elementId,
  elementPath,
  elementCopyPath,
  onDelete,
}: IAdminPanelControl): JSX.Element {
  const isStandardContentManager = useAppSelector(state => {
    return hasAccessRole(state, ACCESS_ROLES.STANDARD_CONTENT_MANAGER);
  });
  const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
    useContextMenu();

  if (isHidden) {
    return (
      <ControlsWrapper>
        <HiddenTableAction
          forwardedAs={Link}
          mr={1}
          title='Редактировать'
          to={getPath(elementPath, { id: elementId })}
        >
          <EditMediumIcon />
        </HiddenTableAction>
        <HiddenTableAction
          focused={!!anchorEl}
          mr={1}
          onClick={handleContextMenuOpen}
        >
          <ContextVIcon />
        </HiddenTableAction>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          width='150px'
          onRequestClose={handleContextMenuClose}
        >
          <ContextMenuItem
            as={Link}
            text='Дублировать'
            to={getPath(elementCopyPath, { id: elementId })}
          />
          <ContextMenuItem text='Удалить' onClick={onDelete} />
        </ContextMenu>
      </ControlsWrapper>
    );
  }

  return (
    <ControlsWrapper>
      {elementCopyPath && (
        <HiddenTableAction
          forwardedAs={Link}
          mr={1}
          title='Дублировать'
          to={getPath(elementCopyPath, { id: elementId })}
        >
          <CopyIcon />
        </HiddenTableAction>
      )}
      <HiddenTableAction
        forwardedAs={Link}
        mr={1}
        title='Редактировать'
        to={getPath(elementPath, { id: elementId })}
      >
        <EditMediumIcon />
      </HiddenTableAction>
      {!isStandardContentManager && onDelete && (
        <HiddenTableAction mr={1} title='Удалить' onClick={onDelete}>
          <TrashIcon />
        </HiddenTableAction>
      )}
    </ControlsWrapper>
  );
}

export { AdminPanelControl };
