import { FC } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { HINTS } from 'common/constants/products.const';
import { useHint } from 'common/hooks';
import { IProduct, ProductLifeState } from 'common/types/product.types';
import { ACCESS_ROLES, hasAccessRole } from 'entities/identity';
import { useActions } from 'store';
import { Button, Notice } from 'UI';

const StyledNotice = styled(Notice)(
  ({ theme: { space } }) => css`
    min-height: 66px;
    max-height: 66px;
    min-width: 1000px;
    margin: ${space[2]}px 0 0;
    padding: ${space[2]}px;
    border-radius: 8px;
  `,
);

const ProductModerationNotice: FC<{ product: IProduct }> = ({ product }) => {
  const isModerator = useSelector(state => {
    return hasAccessRole(state, ACCESS_ROLES.MODERATOR);
  });
  const { openSupportForm } = useActions();

  const {
    isViewed: isViewedModeration,
    handleToggleViewed: handleToggleViewedModeration,
  } = useHint(HINTS.MODERATION_NOTICE);
  const {
    isViewed: isViewedModerationCompleted,
    handleToggleViewed: handleToggleViewedModerationCompleted,
  } = useHint(HINTS.MODERATION_COMPLETED_NOTICE);
  const {
    isViewed: isViewedBlocked,
    handleToggleViewed: handleToggleViewedBlocked,
  } = useHint(HINTS.BLOCKED_NOTICE);
  const {
    isViewed: isViewedRejected,
    handleToggleViewed: handleToggleViewedRejected,
  } = useHint(HINTS.REJECTED_NOTICE);

  const { lifeState } = product;

  if (isModerator) {
    return null;
  }

  if (lifeState === ProductLifeState.moderation && !isViewedModeration) {
    return (
      <StyledNotice onClose={handleToggleViewedModeration}>
        Редактирование запрещено. Товар находится на стадии проверки модератором
        и генерации улучшений и корректировок искусственным интеллектом.
      </StyledNotice>
    );
  }

  if (lifeState === ProductLifeState.rejected && !isViewedRejected) {
    return (
      <StyledNotice onClose={handleToggleViewedRejected}>
        Товар отклонен модератором. Если вы не согласны с решением модератора,
        обратитесь в{' '}
        <Button variant='string' onClick={openSupportForm}>
          службу поддержки
        </Button>
        .
      </StyledNotice>
    );
  }

  if (lifeState === ProductLifeState.blocked && !isViewedBlocked) {
    return (
      <StyledNotice onClose={handleToggleViewedBlocked}>
        Товар заблокирован модератором. Если вы не согласны с решением
        модератора, обратитесь в{' '}
        <Button variant='string' onClick={openSupportForm}>
          службу поддержки
        </Button>
        .
      </StyledNotice>
    );
  }

  if (
    lifeState === ProductLifeState.moderationCompleted &&
    !isViewedModerationCompleted
  ) {
    return (
      <StyledNotice onClose={handleToggleViewedModerationCompleted}>
        Товар успешно прошел модерацию и готов к публикации. Во избежании ошибок
        не рекомендуем вносить новые изменения.
      </StyledNotice>
    );
  }

  return null;
};

export { ProductModerationNotice };
