import { FC, Fragment, memo } from 'react';

import styled, { css } from 'styled-components';

import { getCountOfFilters } from 'components/side-filter';
import { useReform, useWatch } from 'reform';
import { Button, Heading } from 'UI';

export const FiltersControlsWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    justify-content: space-between;
    padding: ${space[2]}px ${space[4]}px;
    border-top: 1px solid ${colors.divider};
    border-bottom: 1px solid ${colors.divider};
  `,
);

export const FiltersContainer = styled.div(
  ({ theme: { space } }) => css`
    height: 480px; // sidebar height - sidebar header
    padding: ${space[4]}px;
    padding-top: ${space[2]}px;
    overflow: auto;
  `,
);

interface ISidePanelFilterProps {
  onSubmitFilters: (filters: any) => void;
  onResetFilters?: VoidFunction;
  filters: any;
}

const OverlayFilters: FC<ISidePanelFilterProps> = memo(
  ({ onSubmitFilters, filters, onResetFilters }) => {
    const { getData } = useReform();
    const data = useWatch({});

    const handleSubmit = (): void => {
      onSubmitFilters(getData());
    };

    const count = getCountOfFilters(false, data);

    return (
      <Fragment>
        <Heading as='h3' ml={4} my={2}>
          Фильтры
        </Heading>
        <FiltersControlsWrapper>
          <Button color='secondary' variant='text' onClick={onResetFilters}>
            Сбросить все
          </Button>
          <Button disabled={count === 0} onClick={handleSubmit}>
            Применить
          </Button>
        </FiltersControlsWrapper>
        <FiltersContainer>{filters}</FiltersContainer>
      </Fragment>
    );
  },
);

export { OverlayFilters };
