import { FC, memo, useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { EditMediumIcon, TrashIcon } from 'resources/icons/18';
import { IArbitraryProperty } from 'common/types/product.types';
import { EditTemplates } from 'components/characteristic-elements/EditMode';
import { Container, IconButton, Select, Text } from 'UI';
import { ItemToKeyType, ItemToStringAndOptionsType } from 'UI/Inputs';

import { PROPERTIES_TYPES_NAMES } from '../../../../../../features/products/constants';
import { IDependentQuestionnaireProperty } from '../../../../types';

const ViewContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    min-height: ${space[6]}px;
    padding: ${space[1]}px ${space[1]}px ${space[1]}px ${space[2]}px;
    margin-bottom: ${space[0]}px;
    background: ${colors.background};
    border-radius: 2px;
  `,
);

const itemToKey: ItemToKeyType<IArbitraryProperty> = item => item.id;
const itemToString: ItemToStringAndOptionsType<IArbitraryProperty> = (
  item,
  { isOption }: { isOption?: boolean } = {},
) => {
  if (!isOption) {
    return item.name;
  }

  return (
    <Text lineHeight='initial' mt='6px'>
      <Text truncate fontSize={3}>
        {item.name}
      </Text>
      <Text color='text.secondary' fontSize={2}>
        {PROPERTIES_TYPES_NAMES[item.type]}
      </Text>
    </Text>
  );
};

interface IDependentPropertyProps {
  // название для reform поля
  name: string;
  // текущее значение из reform
  value: IDependentQuestionnaireProperty;
  // массив произвольных хар-к родительского ол
  propertiesOptions: IArbitraryProperty[];
  // те прозвольные хар-ки дочернего ол, которые ранее были добавлены
  addedDependentProperties: string[];
  // массив произвольных хар-к дочернего ол
  dependentPropertiesOptions: IArbitraryProperty[];
  // управление стейтом, чтобы блокировать клик на неактивных табах и сабмит модалки
  setTabDisabled: (value: boolean) => void;
  // хенделер для обнволения значений поля (setFieldValue из reform)
  onChange: (name, item) => void;
  // хендлер для удаления
  onRemove: () => void;
}

/**
 * Шаблон для рендеринга выбранных зависимостей и хар-к дочернего ол
 * Имеется 2 вида: шаблон редактирования и шаблон просмотра
 * Переключение идет по внутреннему полю _confirmed
 */
const DependentProperty: FC<IDependentPropertyProps> = memo(
  ({
    name,
    value: {
      _confirmed,
      propertyId,
      dependentProperty: { id: dependentProperty },
    },
    propertiesOptions,
    dependentPropertiesOptions,
    addedDependentProperties,
    setTabDisabled,
    onChange,
    onRemove,
  }) => {
    const hasPropertyId = propertyId;
    const isDisabled = !(hasPropertyId && dependentProperty);

    const filtredDependentPropertiesOptionsByType = useMemo(() => {
      return dependentPropertiesOptions.filter(
        ({ type }) => type === propertyId?.type,
      );
    }, [dependentPropertiesOptions, propertyId]);

    const handleEdit = useCallback(() => {
      onChange(`${name}._confirmed`, false);
      setTabDisabled(true);
    }, [name, onChange, setTabDisabled]);

    const handleRemove = useCallback(() => {
      onRemove();
      setTabDisabled(false);
    }, [onRemove, setTabDisabled]);

    const handleSubmit = useCallback(() => {
      onChange(`${name}._confirmed`, true);
      setTabDisabled(false);
    }, [name, onChange, setTabDisabled]);

    if (!_confirmed) {
      return (
        <EditTemplates.Container
          cancelButton={{
            title: 'Удалить',
            onRemove: handleRemove,
          }}
          header={
            <EditTemplates.Select
              disablePortal={false}
              heightItem={46}
              itemToKey={itemToKey}
              itemToString={itemToString}
              options={propertiesOptions}
              placeholder='Выберите характеристику исходного ОЛ'
              value={propertyId}
              onChange={currentValue =>
                onChange(`${name}.propertyId`, currentValue)
              }
            />
          }
          mb={0}
          submitButton={{
            disabled: isDisabled,
            title: { title: 'Сохранить', placement: 'bottom' },
            onSubmit: handleSubmit,
          }}
        >
          <Container display='block' width='100%'>
            <EditTemplates.PlaceholderContainer>
              =
            </EditTemplates.PlaceholderContainer>
            <Select
              disabled={!hasPropertyId}
              disableItem={(item: IArbitraryProperty): boolean => {
                return addedDependentProperties?.indexOf(item.id) !== -1;
              }}
              dropdownPlaceholder='Нет произвольных характеристик'
              heightItem={46}
              itemToKey={itemToKey}
              itemToString={itemToString}
              options={filtredDependentPropertiesOptionsByType}
              placeholder='Выберите характеристику зависимого ОЛ'
              value={dependentProperty}
              onChange={currentValue =>
                onChange(`${name}.dependentProperty.id`, currentValue)
              }
            />
          </Container>
        </EditTemplates.Container>
      );
    }

    return (
      <ViewContainer>
        <Text mr={2}>
          {propertyId?.name} ({PROPERTIES_TYPES_NAMES[propertyId?.type ?? '']})
          = {dependentProperty?.name} (
          {PROPERTIES_TYPES_NAMES[dependentProperty?.type ?? '']})
        </Text>
        <Container alignItems='center' ml='auto'>
          <IconButton mr={1} title='Редактировать' onClick={handleEdit}>
            <EditMediumIcon />
          </IconButton>
          <IconButton title='Удалить' onClick={onRemove}>
            <TrashIcon />
          </IconButton>
        </Container>
      </ViewContainer>
    );
  },
);

export { DependentProperty };
