import { Fragment, FC } from 'react';

import styled, { css } from 'styled-components';

import { useSelect } from 'common/hooks';
import { ICategory, IMapCategory } from 'common/types/categories.types';
import { INtd } from 'common/types/standartization';
import { getRootCategories } from 'common/utils/categories';
import { useModalContext } from 'entities/modals';
import { ArrowDown1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { FolderIcon } from 'resources/modal';
import { useToggle } from 'tools/hooks';
import { Container, Text, Modal } from 'UI';

const Item = styled(Modal.ListItem)<{ hasChildren: boolean; level: number }>(
  ({ theme: { fontSizes, space }, hasChildren, level }) => css`
    height: ${space[4]}px;
    font-size: ${fontSizes[4]}px;
    padding-left: ${level * space[3]}px;

    ${hasChildren &&
    css`
      &:hover {
        background: transparent;
      }
    `}
  `,
);

const LINK_NTD = 'LINK_NTD';

interface ICategoryItemProps {
  categories: Map<string, IMapCategory>;
  category: IMapCategory | undefined;
  level?: number;
  bindItem: (category: IMapCategory | undefined) => {
    isSelected: boolean;
    handleSelect: VoidFunction;
  };
}

const CategoryItem: FC<ICategoryItemProps> = ({
  categories,
  category,
  level = 0,
  bindItem,
}) => {
  const [isOpen, toggleOpen] = useToggle();

  if (!category) return null;

  const hasChildren = category.children.length > 0;
  const { isSelected, handleSelect } = bindItem(category);

  const itemProps = hasChildren
    ? { onClick: toggleOpen }
    : { selected: isSelected, onClick: handleSelect };

  return (
    <Fragment>
      <Item {...itemProps} hasChildren={hasChildren} level={level}>
        {hasChildren &&
          (isOpen ? <ArrowDown1pxIcon mx={1} /> : <ArrowRight1pxIcon mx={1} />)}
        <Text truncate>{category.name}</Text>
      </Item>
      {isOpen &&
        category.children.map(children => (
          <CategoryItem
            key={children}
            bindItem={bindItem}
            categories={categories}
            category={categories.get(children)}
            level={level + 1}
          />
        ))}
    </Fragment>
  );
};

interface ILinkNtdModalProps {
  categories: Map<string, IMapCategory>;
  selectedNtd: INtd[];
  onSubmit: (ntds: INtd[], categories: ICategory[]) => void;
}

const LinkNtdModal: FC<ILinkNtdModalProps> = ({
  categories,
  selectedNtd,
  onSubmit,
}) => {
  const { handleModalClose } = useModalContext();

  const { selected, bindItem } = useSelect({
    options: Array.from(categories.values()),
  });

  return (
    <Modal fullscreen hasSubmitOnEnter={false}>
      <Container column height='100%'>
        <Modal.Default
          disabled={selected.length === 0}
          icon={<FolderIcon color='primary.main' />}
          submitButton='Добавить'
          title='Добавить к категориям'
          onRequestClose={handleModalClose}
          onSubmit={() => onSubmit(selectedNtd, selected)}
        >
          {getRootCategories(categories).map(({ id: categoryId }) => (
            <CategoryItem
              key={categoryId}
              bindItem={bindItem}
              categories={categories}
              category={categories.get(categoryId)}
            />
          ))}
        </Modal.Default>
      </Container>
    </Modal>
  );
};

export { LinkNtdModal, LINK_NTD };
