import { useCallback, useEffect, Fragment } from 'react';

import styled, { css } from 'styled-components';

import { useField } from 'reform';
import { TrashIcon } from 'resources/icons/18';
import { YoutubeIcon } from 'resources/other';
import { LoadInfoIcon } from 'resources/other-14';
import {
  Text,
  IconButton,
  TextInput,
  FormError,
  Spinner,
  IconButtonWrapper,
  Container,
} from 'UI';

import { useYoutubeVideo } from '../../hooks';
import { VideoType } from '../../types';

const VideoPreview = styled.div<{ hasVideo: boolean }>(
  ({ theme: { space, colors }, hasVideo }) => css`
    position: relative;
    flex-shrink: 0;
    width: 104px;
    height: 64px;
    line-height: 64px;
    margin-right: ${space[2]}px;
    background-color: ${hasVideo ? colors.black : colors.background};

    img {
      max-width: 100%;
      max-height: 100%;
      vertical-align: middle;
    }
  `,
);

const VideoPlaceholder = styled(YoutubeIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const InputWrapper = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    flex: 1;
    min-width: 0;
    padding-right: ${space[3]}px;
  `,
);
const RemoveVideoButton = styled(IconButtonWrapper)`
  position: absolute;
  right: 0;
  bottom: 0;
`;

interface ICreateVideoItemProps {
  id: string;
  name: string;
  onRemove?: () => void;
}

const getErrorText = (error: any): string => {
  if (typeof error === 'string') {
    return error;
  }

  return error?.url ?? '';
};

function CreateVideoItem({
  id,
  name,
  onRemove,
}: ICreateVideoItemProps): JSX.Element {
  const { value, error, setValue, setError } = useField<VideoType>({
    name,
    key: id,
  });

  const { video, isLoading, updateData } = useYoutubeVideo({
    url: value.url,
    onSuccess: ({ error: videError }) => {
      if (videError) setError(videError);
    },
  });

  const title = (video && video.snippet.title) || 'Название';
  const previewImage = video && video.snippet.thumbnails.medium.url;

  // auto fetch videos after restore section
  useEffect(() => {
    if (value._restored) {
      updateData();
    }
  }, [updateData, value._restored]);

  const handleSubmit = useCallback(
    event => {
      if (
        event.target.value !== '' &&
        (event.keyCode === 13 || event.key === 'Enter')
      ) {
        updateData();
        event.preventDefault();
      }
    },
    [updateData],
  );

  const handleChangeUrl = ({ target: { value: url } }): void => {
    setValue({ ...value, url });
  };

  const videoPreview = video ? (
    <img alt={title} src={previewImage} />
  ) : (
    <VideoPlaceholder />
  );

  return (
    <Fragment>
      <Container>
        <VideoPreview hasVideo={Boolean(video)}>
          <Spinner height='100%' loading={isLoading}>
            {videoPreview}
          </Spinner>
        </VideoPreview>
        <InputWrapper>
          <Text
            truncate
            color={video ? 'text.primary' : 'text.disabled'}
            mb='12px'
          >
            {isLoading ? 'Поиск видео' : title}
          </Text>
          <TextInput
            error={error}
            name={`${name}.url`}
            placeholder='Введите ссылку на видео с youtube'
            rightAddon={
              <IconButton
                frame
                disabled={value.url === ''}
                m='2px'
                size={28}
                title='Загрузить'
                onClick={updateData}
              >
                <LoadInfoIcon />
              </IconButton>
            }
            value={value.url}
            onBlur={value.url !== '' ? updateData : undefined}
            onChange={handleChangeUrl}
            onKeyDown={handleSubmit}
          />
          <RemoveVideoButton
            disabled={!onRemove}
            mb={1}
            mt='auto'
            title='Удалить'
            onClick={onRemove}
          >
            <TrashIcon />
          </RemoveVideoButton>
        </InputWrapper>
      </Container>
      <FormError mb={2} ml='120px' show={Boolean(error)}>
        {getErrorText(error)}
      </FormError>
    </Fragment>
  );
}

export { CreateVideoItem };
