import { Dispatch, FC, Fragment, SetStateAction, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';

import { COMPLETENESS_TEXT_BY_TYPE } from 'common/constants/products.const/accompany-information.const';
import { Nullable } from 'common/types/common.types';
import { IForbidden } from 'common/types/permissions.types';
import {
  CompletenessType,
  ICurrentProductAdditionalInformation,
  ICurrentProductAdditionalInformationItem,
} from 'common/types/product.types/accompany-information.types';
import { preparedForbidden } from 'common/utils';
import { FieldWrapper } from 'components/fields';
import { useField } from 'reform';
import { AddSmallIcon } from 'resources/icons/12';
import {
  Button,
  CollapseCard,
  Container,
  ContextMenu,
  ContextMenuItem,
  DescriptionList,
  Text,
  TextInput,
  Tooltip,
  UnionContainer,
  useContextMenu,
  ViewMode,
} from 'UI';
import { InputContainer } from 'UI/Inputs';

import { CompletenessSubsectionProps } from '../types';

const StyledButton = styled(Button)(
  ({ theme: { space, colors } }) => css`
    color: ${colors.text.disabled};
    margin-left: ${space[1]}px;

    &:hover {
      color: ${colors.text.disabled};
      background: transparent;
    }
  `,
);

const enum AdditionalInformationTypes {
  designation = 'designation',
  note = 'note',
}

const ADDITIONAL_INFORMATION_TO_TEXT = {
  [AdditionalInformationTypes.designation]: 'Обозначение',
  [AdditionalInformationTypes.note]: 'Примечание',
};

const TIP = 'Текущий товар является обязательным подразделом комплектности';

const getErrorMessage = (error): string => {
  if (typeof error === 'string') {
    return error;
  }
  return error?.text ?? '';
};

const persistErrorAdditionalInformation = ({
  prevValue,
  nextValue,
  prevError,
}): any => {
  if (prevValue.text === nextValue.text) {
    return prevError;
  }
  return null;
};

interface IAdditionalInformationSubsectionProps {
  type: string;
  name: string;
  onRemove: VoidFunction;
}

const AdditionalInformationSubsection: FC<
  IAdditionalInformationSubsectionProps & IForbidden
> = ({ type, name, forbidden, onRemove }) => {
  const prevErrorRef = useRef(null);

  const { ref, value, initialValue, setValue, error } =
    useField<ICurrentProductAdditionalInformationItem>({
      name,
      persistError: persistErrorAdditionalInformation,
    });

  const simpleForbidden = preparedForbidden(forbidden, initialValue);

  const onToggleConfirmed = (): void => {
    setValue(prev => ({ ...prev, _confirmed: !prev._confirmed }));
  };

  const handleChange = ({ target }): void => {
    setValue(prev => ({ ...prev, text: target.value }));
  };

  useEffect(() => {
    if (!!error && error !== prevErrorRef.current) {
      prevErrorRef.current = error;

      setValue(prev => ({ ...prev, _confirmed: false }));
    }
  }, [error, setValue]);

  const { _confirmed: confirmed, text } = value;

  if (confirmed) {
    return (
      <ViewMode
        ref={ref}
        contextMenu={
          !simpleForbidden.remove && (
            <ContextMenuItem
              key='edit'
              as='button'
              text='Редактировать'
              onClick={onToggleConfirmed}
            />
          )
        }
        forbidden={simpleForbidden}
        readonly={simpleForbidden.edit}
        title='Редактировать'
        onClick={onToggleConfirmed}
        onRemove={onRemove}
      >
        <DescriptionList.FlexProperty
          dotted
          truncate
          backgroundColor='background'
          label={ADDITIONAL_INFORMATION_TO_TEXT[type]}
        >
          {text}
        </DescriptionList.FlexProperty>
      </ViewMode>
    );
  }

  const errorText = getErrorMessage(error);

  return (
    <FieldWrapper
      ref={ref}
      submitOnEnter
      cancelButton={{
        title: 'Удалить',
        onRemove,
      }}
      error={errorText}
      submitButton={{
        disabled: !text,
        title: { title: 'Сохранить', placement: 'bottom' },
        onSubmit: onToggleConfirmed,
      }}
    >
      <UnionContainer column error={!!errorText}>
        <UnionContainer.Row>
          <InputContainer disabled pl={2}>
            {ADDITIONAL_INFORMATION_TO_TEXT[type]}
          </InputContainer>
        </UnionContainer.Row>
        <UnionContainer.Row>
          <TextInput
            placeholder={
              type === AdditionalInformationTypes.designation
                ? 'Введите обозначение или код изделия'
                : 'Например: Поставляется в разобранном виде, в трех контейнерах'
            }
            value={text}
            onChange={handleChange}
          />
        </UnionContainer.Row>
      </UnionContainer>
    </FieldWrapper>
  );
};

const Controls: FC<{
  value: Nullable<ICurrentProductAdditionalInformation>;
  setValue: Dispatch<
    SetStateAction<Nullable<ICurrentProductAdditionalInformation>>
  >;
}> = ({ value, setValue }) => {
  const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
    useContextMenu();

  if (!value || !value.designation || !value.note) {
    return (
      <Fragment>
        <Button
          color='secondary'
          fontWeight={600}
          mr='auto'
          variant='text'
          onClick={handleContextMenuOpen}
        >
          <AddSmallIcon mr={0} />
          Добавить дополнительную информацию
        </Button>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          width={293}
          onRequestClose={handleContextMenuClose}
        >
          {!value?.designation && (
            <ContextMenuItem
              text='Обозначение'
              onClick={() =>
                setValue(prev =>
                  prev
                    ? { ...prev, designation: { text: '', _confirmed: false } }
                    : { designation: { text: '', _confirmed: false } },
                )
              }
            />
          )}
          {!value?.note && (
            <ContextMenuItem
              text='Примечание'
              onClick={() =>
                setValue(prev =>
                  prev
                    ? { ...prev, note: { text: '', _confirmed: false } }
                    : { note: { text: '', _confirmed: false } },
                )
              }
            />
          )}
        </ContextMenu>
      </Fragment>
    );
  }

  return null;
};

const CurrentProduct: FC<CompletenessSubsectionProps> = ({
  fieldName: name,
  fullObjectName,
  forbidden,
  type,
}) => {
  const fieldName = `${name}.additionalInformation`;
  const { value, setValue } = useField<
    Nullable<ICurrentProductAdditionalInformation>
  >({
    name: fieldName,
  });

  const handleRemove = (
    key: keyof ICurrentProductAdditionalInformation,
  ): void => {
    setValue(prev => {
      if (!prev) return null;

      const { [key]: _, ...other } = prev;

      if (Object.keys(other).length === 0) {
        return null;
      }
      return other;
    });
  };

  return (
    <CollapseCard
      initialOpen
      inner
      id={CompletenessType.currentProduct}
      rightActions={
        !forbidden.remove && (
          <Tooltip arrow hint maxWidth={415} title={TIP}>
            <StyledButton variant='text'>Удалить</StyledButton>
          </Tooltip>
        )
      }
      title={<Text fontSize={4}>{COMPLETENESS_TEXT_BY_TYPE[type]}</Text>}
    >
      <Container column gap={2}>
        <ViewMode>
          <DescriptionList.FlexProperty
            dotted
            backgroundColor='background'
            label='Название'
          >
            {fullObjectName}
          </DescriptionList.FlexProperty>
        </ViewMode>
        {!!value &&
          Object.keys(value).map(
            (key: keyof ICurrentProductAdditionalInformation) => (
              <AdditionalInformationSubsection
                key={key}
                forbidden={forbidden}
                name={`${fieldName}.${key}`}
                type={key}
                onRemove={() => handleRemove(key)}
              />
            ),
          )}
        <Controls setValue={setValue} value={value} />
      </Container>
    </CollapseCard>
  );
};

export { CurrentProduct };
