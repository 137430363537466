import styled, { css } from 'styled-components';

export const SectionWrapper = styled.section(
  ({ theme: { space } }) => css`
    &[data-open='true'] + & {
      margin-top: ${space[4]}px;
    }
  `,
);

export const Header = styled.header<{ disabled: boolean }>(
  ({ theme: { space, colors }, disabled }) => css`
    position: sticky;
    top: 0;
    z-index: 1;
    height: ${space[7]}px;
    display: flex;
    align-items: center;
    margin: 0 -${space[1]}px;
    padding: 0 ${space[1]}px;
    background: ${colors.white};
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: -1;
      border-radius: 8px;
    }

    &:hover::before {
      background: ${colors.highlight[0]};
    }

    ${disabled &&
    css`
      cursor: default;

      &:hover::before {
        background: none;
      }
    `}
  `,
);

export const ResultCount = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    height: 24px;
    padding: 0 ${space[1]}px;
    border: 1px solid ${colors.divider};
    border-radius: 18px;
    font-size: 9.5px;

    &[aria-busy='true'] {
      width: 120px;
      justify-content: center;
    }
  `,
);

export const DependentFilterPanel = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    row-gap: ${space[0]}px;
    margin-bottom: ${space[2]}px;
  `,
);

export const ResetButton = styled.button(
  ({ theme: { space, colors, borderRadius } }) => css`
    height: 18px;
    padding: 0 ${space[0]}px;
    color: ${colors.text.secondary};
    line-height: 1;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
  `,
);

export const DropdownItem = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    margin: ${space[1]}px;
    padding: ${space[1]}px;
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
    background: ${colors.background};
  `,
);
