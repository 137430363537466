import { FC, memo, useCallback, useContext, useRef, useState } from 'react';

import { addDays, addMonths } from 'date-fns';
import styled, { css, ThemeContext } from 'styled-components';

import { setCompanyProductProduction } from 'common/api/products.api';
import { ProductionStates } from 'common/types/product.types';
import { formatISOWithoutTime } from 'common/utils';
import { useNotify } from 'entities/notify';
import { Field, IReform, Reform, useReform } from 'reform';
import { HourglassIcon } from 'resources/modal';
import { Container, Modal, Text, ToggleButton } from 'UI';

import { DateInputField } from '../../fields';

enum TimePeriod {
  oneWeek = 'ONE_WEEK',
  oneMonth = 'ONE_MONTH',
  threeMonths = 'THREE_MONTHS',
}

interface IPeriodsMap {
  [key: string]: {
    label: string;
    fn: (date: Date, num: number) => Date;
    total: number;
  };
}

const PERIODS_MAP: IPeriodsMap = {
  [TimePeriod.oneWeek]: { label: '+ 1 неделя', fn: addDays, total: 7 },
  [TimePeriod.oneMonth]: { label: '+ 1 месяц', fn: addMonths, total: 1 },
  [TimePeriod.threeMonths]: { label: '+ 3 месяца', fn: addMonths, total: 3 },
};

const DateButton = styled(ToggleButton)(
  ({ theme: { space, fontSizes } }) => css`
    height: ${space[3]}px;
    padding: 0 ${space[2]}px;
    border-radius: 14px;
    font-size: ${fontSizes[0]}px;
    text-transform: uppercase;
    cursor: pointer;
    margin-right: ${space[0]}px;
    margin-bottom: 0;

    &:last-child {
      margin-right: 0;
    }
  `,
);

interface IDateControlsProps {
  productionDate;
  selectedDateButton;
  setSelectedDateButton;
}

const DateControls: FC<IDateControlsProps> = memo(
  ({ productionDate, selectedDateButton, setSelectedDateButton }) => {
    const { setFieldValue } = useReform();
    const PERIODS_ENTRIES = Object.entries(PERIODS_MAP);

    const handleBadgeClick = useCallback(
      ({ currentTarget: { dataset } }) => {
        const badgeType = dataset.type;

        setFieldValue('productionDate', () => {
          setSelectedDateButton(badgeType);
          let nextDate: Date = new Date(productionDate);

          const { fn, total } = PERIODS_MAP[badgeType];
          nextDate = fn(nextDate, total);

          return nextDate;
        });
      },
      [setFieldValue, setSelectedDateButton, productionDate],
    );

    return (
      <Container alignItems='center' mb={3} mt={1}>
        {PERIODS_ENTRIES.map(([type, { label }]) => (
          <DateButton
            key={label}
            checked={selectedDateButton === type}
            data-type={type}
            label={label}
            onClick={handleBadgeClick}
          />
        ))}
      </Container>
    );
  },
);

interface IExtendAnnouncementModalProps {
  productId: string;
  productionDate: Date;
  handleModalClose: VoidFunction;
  updateData: VoidFunction;
}

const ExtendAnnouncementModal: FC<IExtendAnnouncementModalProps> = memo(
  ({ productId, productionDate, handleModalClose, updateData }) => {
    const { zIndex } = useContext(ThemeContext);
    const reformRef = useRef<IReform>(null);
    const notify = useNotify();
    const [selectedDateButton, setSelectedDateButton] = useState(null);

    const isExpired = new Date() > productionDate;
    const modifiedProductionDate = isExpired
      ? new Date()
      : new Date(productionDate);
    modifiedProductionDate.setDate(modifiedProductionDate.getDate() + 1);
    modifiedProductionDate.setHours(0);
    modifiedProductionDate.setMinutes(0);
    modifiedProductionDate.setSeconds(0);
    modifiedProductionDate.setMilliseconds(0);

    const currentAnnouncementDate = productionDate
      .toLocaleDateString()
      .replace(/\./g, '-');

    const handleSubmit = (): void => {
      const formData = reformRef?.current?.getData();
      setCompanyProductProduction(productId, formData).then(() => {
        updateData();
      });
      handleModalClose();
      notify.success('Анонс товара продлен');
    };

    return (
      <Modal onSubmit={handleSubmit}>
        <Modal.Default
          icon={<HourglassIcon color='primary.main' />}
          paddingBottom={28}
          submitButton='Продлить'
          title='Продлить анонс'
        >
          <Reform
            ref={reformRef}
            initialValues={{
              productionState: ProductionStates.announced,
              productionDate: modifiedProductionDate,
            }}
          >
            <Field
              component={DateInputField}
              label={
                <Container flexGrow={1} width='100%'>
                  <>Выберите новую дату анонса</>
                  <Text fontSize={2} fontWeight={500} ml='auto'>
                    Текущая дата анонса: {currentAnnouncementDate}
                  </Text>
                </Container>
              }
              name='productionDate'
              prevDisabledDate={
                isExpired ? new Date() : new Date(productionDate)
              }
              transform={formatISOWithoutTime}
              zIndex={zIndex.modal}
              onChange={() => setSelectedDateButton(null)}
            />
            <DateControls
              productionDate={productionDate}
              selectedDateButton={selectedDateButton}
              setSelectedDateButton={setSelectedDateButton}
            />
          </Reform>
        </Modal.Default>
      </Modal>
    );
  },
);

const EXTEND_ANNOUNCEMENT = 'EXTEND_ANNOUNCEMENT';

export { ExtendAnnouncementModal, EXTEND_ANNOUNCEMENT };
