import { Fragment, ReactElement, useEffect, useRef } from 'react';

import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { DocumentsSections } from 'common/types/media.types';
import {
  CompletenessType,
  ObligationTypes,
} from 'common/types/product.types/accompany-information.types';
import { parseToNumber } from 'common/utils';
import { FieldWrapper } from 'components/fields';
import { DocumentSingleForm } from 'components/media/documents';
import { useNotify } from 'entities/notify';
import { Field, IReform, Reform, useField, useWatch } from 'reform';
import { DocumentMediumIcon } from 'resources/icons/18';
import {
  Button,
  Container,
  Divider,
  FormLabel,
  RadioField,
  TextAreaField,
  TextInputField,
  TextInput,
  UnionContainer,
} from 'UI';
import { Modal } from 'UI/Modal';

const INITIAL_VALUES = {
  name: '',
  quantity: '',
  designation: '',
  obligation: 'alwaysInCompleteness',
  note: '',
  electronicVersion: null,
};

const ButtonsWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: inline-flex;
    justify-content: flex-end;
    width: auto;
    margin: 0 -${space[4]}px;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.background};
  `,
);

const OTHER_COMPLETENESS_ITEM = 'otherCompletenessItem';

const DECLINABLE_COMPLETENESS_TEXT_BY_TYPE = {
  [CompletenessType.detailsAndEquipments]: 'детали или оборудования',
  [CompletenessType.documentation]: 'документации',
  [CompletenessType.sparePartKitInformation]: 'информации о комплекте ЗИП',
};

const NOTE_PLACEHOLDER_TEXT_BY_TYPE = {
  [CompletenessType.detailsAndEquipments]:
    'Например: Входит в поставку при монтаже на трубопроводе',
  [CompletenessType.documentation]:
    'Например: Электронная версия на русском языке',
  [CompletenessType.sparePartKitInformation]:
    'Например: Поставляется по требованию заказчика',
};

const DESIGNATION_PLACEHOLDER_TEXT_BY_TYPE = {
  [CompletenessType.detailsAndEquipments]:
    'Введите обозначение или код изделия',
  [CompletenessType.documentation]: 'Введите обозначение или код документации',
  [CompletenessType.sparePartKitInformation]:
    'Введите обозначение или код комплекта',
};

const NOTIFICATION_TEXT_BY_TYPE = {
  [CompletenessType.detailsAndEquipments]:
    'Деталь или оборудование с таким названием уже добавлена',
  [CompletenessType.documentation]:
    'Документация с таким названием уже добавлена',
};

function Documents({ name }): ReactElement {
  return (
    <Fragment>
      <FormLabel htmlFor={name}>Электронная версия</FormLabel>
      <DocumentSingleForm
        fileSection={DocumentsSections.productDocuments}
        forbidden={{ edit: true }}
        id={name}
        mb={3}
        name={name}
      />
    </Fragment>
  );
}

function ModalBody({ completenessItemType }): ReactElement {
  const {
    value: name,
    setValue: setName,
    error: nameError,
  } = useField({ name: 'name' });

  const {
    value: quantity,
    setValue: setQuantity,
    error: quantityError,
  } = useField({ name: 'quantity' });

  return (
    <Fragment>
      <Divider mb={2} mt='0px' mx={-4} />
      <FieldWrapper
        required
        showErrorDescription
        error={nameError || quantityError}
        label={
          completenessItemType === CompletenessType.sparePartKitInformation
            ? 'Название'
            : `Название ${DECLINABLE_COMPLETENESS_TEXT_BY_TYPE[completenessItemType]}`
        }
        mb={3}
      >
        <UnionContainer>
          <UnionContainer.Row>
            <TextInput
              disabled={
                completenessItemType ===
                CompletenessType.sparePartKitInformation
              }
              error={nameError}
              limit={250}
              placeholder={
                completenessItemType ===
                CompletenessType.sparePartKitInformation
                  ? 'Комплект ЗИП'
                  : 'Введите название'
              }
              value={name}
              width='100%'
              onChange={({ target }) => setName(target.value)}
            />
            {completenessItemType !==
              CompletenessType.sparePartKitInformation && (
              <TextInput
                error={quantityError}
                placeholder='Введите количество'
                type='number'
                value={quantity}
                width={225}
                onChange={({ target }) => setQuantity(target.value)}
              />
            )}
          </UnionContainer.Row>
        </UnionContainer>
      </FieldWrapper>
      <Field
        component={TextInputField}
        label='Обозначение'
        maxLength={50}
        mb='12px'
        name='designation'
        placeholder={DESIGNATION_PLACEHOLDER_TEXT_BY_TYPE[completenessItemType]}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
      <FormLabel>Обязательность</FormLabel>
      <Field
        component={RadioField}
        label='Всегда входит в комплектность'
        mb={1}
        name='obligation'
        value={ObligationTypes.alwaysInCompleteness}
      />
      <Field
        component={RadioField}
        label='По заявке заказчика'
        mb='24px'
        name='obligation'
        value={ObligationTypes.byCustomerRequest}
      />
      {completenessItemType === CompletenessType.documentation && (
        <Documents name='electronicVersion' />
      )}
      <Field
        component={TextAreaField}
        height={50}
        label='Примечание'
        maxLength={150}
        mb='22px'
        name='note'
        placeholder={NOTE_PLACEHOLDER_TEXT_BY_TYPE[completenessItemType]}
      />
    </Fragment>
  );
}

function Footer({ isEdit, type, onRequestClose, onSubmit }): ReactElement {
  const disabled = useWatch({
    selector: ({ name, quantity, electronicVersion }) => {
      return (
        !name?.trim() ||
        !parseToNumber(quantity) ||
        electronicVersion instanceof File
      );
    },
  });

  return (
    <ButtonsWrapper>
      <Button
        color='secondary'
        mr={1}
        size='l'
        variant='outlined'
        onClick={onRequestClose}
      >
        Отменить
      </Button>
      <Button
        color='primary'
        disabled={type !== CompletenessType.sparePartKitInformation && disabled}
        size='l'
        type='button'
        variant='filled'
        onClick={onSubmit}
      >
        {isEdit ? 'Сохранить' : 'Добавить'}
      </Button>
    </ButtonsWrapper>
  );
}

const transformItem = ({ designation, name, ...other }): any => {
  return { ...other, designation: designation.trim(), name: name.trim() };
};

function OtherCompletenessItemModal({
  completenessItemType,
  index,
  items,
  initialValue,
  error,
  onSubmit,
  handleModalClose,
}): ReactElement {
  const reformRef = useRef<IReform>(null);
  const notify = useNotify();
  const isEdit = typeof index === 'number';

  const currentItem = isEdit
    ? items[index]
    : {
        ...(initialValue ?? INITIAL_VALUES),
        id: uuid(),
      };

  useEffect(() => {
    if (error) {
      reformRef.current?.setErrors(error);
    }
  }, [error]);

  const handleSubmit = (): void => {
    // получаем глубокую копию объекта данных
    const data = transformItem(
      JSON.parse(JSON.stringify(reformRef.current!.getData())),
    );

    const isUniqueName = items
      .filter(({ id }) => data.id !== id)
      .every(({ name }) => data.name !== name);

    if (isUniqueName) {
      onSubmit(data);
      handleModalClose();
    } else {
      notify.error(NOTIFICATION_TEXT_BY_TYPE[completenessItemType]);
    }
  };

  return (
    <Reform ref={reformRef} initialValues={currentItem}>
      <Modal hasSubmitOnEnter={false} width={700}>
        <Modal.Default
          hideButtons
          icon={<DocumentMediumIcon color='primary.main' />}
          paddingBottom={22}
          paddingTop={22}
          title={` ${isEdit ? 'Редактирование' : 'Добавление'} ${
            DECLINABLE_COMPLETENESS_TEXT_BY_TYPE[completenessItemType]
          }`}
        >
          <Container column>
            <ModalBody completenessItemType={completenessItemType} />
            <Footer
              isEdit={isEdit}
              type={completenessItemType}
              onRequestClose={handleModalClose}
              onSubmit={handleSubmit}
            />
          </Container>
        </Modal.Default>
      </Modal>
    </Reform>
  );
}

export { OtherCompletenessItemModal, OTHER_COMPLETENESS_ITEM };
