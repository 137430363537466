import { FC, useEffect, useState, useCallback } from 'react';

import { useHistory, Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { useQueryParams, useRequest, useGlobalLoader } from 'common/hooks';
import { ModalController, useModalContext } from 'entities/modals';
import { DefaultPage, TablePage } from 'layouts/Pages';
import { SEARCH, INFORMATION } from 'routes';
import { SearchInput, Placeholder, Tooltip, Status, Button } from 'UI';

import { FavoriteSideMenu } from './components/SideMenu';
import { SubscriptionModal } from './components/SubscriptionModal';
import { FavoriteProductsTable } from './components/Table';
import { useSupport, SupportSections } from '../../support';
import { fetchSubscriptionConfig } from '../api';

const SUBSCRIPTION = 'SUBSCRIPTION';

const StatusWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${space[4]}px;
    height: ${space[4]}px;
    margin: -${space[2]}px ${space[2]}px -${space[2]}px -9px;
    border-right: 1px solid ${colors.divider};
  `,
);

const HEADING = 'Избранное';

const FavoriteProducts: FC = () => {
  const history = useHistory();
  const { handleModalOpen } = useModalContext();
  const [
    {
      page = 1,
      searchValue = '',
      itemsPerPage = 20,
      orderBy = 'favoredAt',
      orderDirection = 'DESC',
      isFavorite = 1,
    },
    querySet,
  ] = useQueryParams({
    page: Number,
    itemsPerPage: Number,
    isFavorite: Number,
  });
  const { data = { member: [] }, isLoading } = useRequest(fetchProducts, []);
  const {
    data: subscriptionConfig,
    isLoading: isLoadingConfig,
    updateData,
  } = useRequest(fetchSubscriptionConfig, []);

  const isSearchDisabled = searchValue.length === 0 && data.member.length === 0;

  const [withoutSubscription, setSubscription] = useState(false);
  useEffect(() => {
    if (!isLoadingConfig && subscriptionConfig) {
      setSubscription(
        !subscriptionConfig.sendSystemNotifications &&
          !subscriptionConfig.sendByEmails,
      );
    }
  }, [isLoadingConfig, subscriptionConfig]);

  useSupport({
    sectionKey: SupportSections.favorites,
  });

  const handleSearch = useCallback(
    (value = '') => {
      history.push(
        querySet(params => ({
          ...params,
          page: 1,
          searchValue: value,
        })),
      );
    },
    [history, querySet],
  );

  const handleSort = useCallback(
    (key, direction) => {
      history.push(
        querySet(params => ({
          ...params,
          page: 1,
          orderBy: key,
          orderDirection: direction,
        })),
      );
    },
    [history, querySet],
  );

  useGlobalLoader(isLoading);

  if (isLoading) return null;

  if (data.member.length < 1 && !isLoading) {
    return (
      <DefaultPage heading={HEADING}>
        <Placeholder
          buttons={
            <Button as={Link} size='l' to={SEARCH.FORM}>
              Перейти к поиску товаров
            </Button>
          }
          section={HEADING}
          text={[
            'Вы можете добавить интересующие товары ',
            <br key='1' />,
            'в избранное на внешней странице товара.',
          ]}
          title='Нет избранных товаров'
          to={INFORMATION.GUIDES.FAVORITE}
        />
      </DefaultPage>
    );
  }

  return (
    <>
      <TablePage
        isRoundPage
        heading={{
          heading: HEADING,
          controls: (
            <>
              <SearchInput
                isBordered
                disabled={isSearchDisabled}
                initialValue={searchValue}
                mb='0px'
                mr={1}
                width='300px'
                onChange={handleSearch}
              />
              {!isLoadingConfig && (
                <Tooltip
                  arrow
                  hint
                  maxWidth={273}
                  title={
                    withoutSubscription
                      ? 'Вам не приходят уведомления по подписке. Не выбраны типы уведомлений'
                      : undefined
                  }
                >
                  <div>
                    <Button
                      color='info'
                      onClick={() =>
                        handleModalOpen(SUBSCRIPTION, {
                          data: subscriptionConfig,
                          onUpdateSubscribeData: updateData,
                        })
                      }
                    >
                      {withoutSubscription && (
                        <StatusWrapper>
                          <Status type={Status.TYPES.WARN} />
                        </StatusWrapper>
                      )}
                      Управление подпиской
                    </Button>
                  </div>
                </Tooltip>
              )}
            </>
          ),
        }}
      >
        <FavoriteSideMenu />
        <FavoriteProductsTable
          isFavorite={isFavorite}
          itemsPerPage={itemsPerPage}
          orderBy={orderBy}
          orderDirection={orderDirection}
          page={page}
          querySet={querySet}
          searchValue={searchValue}
          onSort={handleSort}
        />
      </TablePage>
      <ModalController component={SubscriptionModal} type={SUBSCRIPTION} />
    </>
  );
};

export { FavoriteProducts };
