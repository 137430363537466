import { Dispatch, FC, SetStateAction, useEffect } from 'react';

import { range } from 'lodash';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { LayoutProps, SpaceProps, TypographyProps } from 'styled-system';

import { PRIMARY_ITEMS_PER_PAGE } from 'common/constants/pagination.const';
import { getTotalPages, scrollToTop, useQueryParams } from 'common/hooks';
import { getFormattedNumber } from 'common/utils';
import {
  ArrowDown1pxIcon,
  ArrowLeft1pxIcon,
  ArrowRight1pxIcon,
  ArrowUp1pxIcon,
} from 'resources/icons/1px-12';
import { STUB_FUNC } from 'tools/utils';

import { Container } from '../Blocks';
import { ContextMenu, useContextMenu } from '../ContextMenu';
import theme from '../theme';

const MAX_VISIBLE_ITEMS = 7;
const DOTS_STYLE = { width: '28px', justifyContent: 'center' };
const CONTEXT_MENU_STYLE = { minWidth: '48px' };

const PageButton = styled(Link)(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: inline-block;
    min-width: ${space[3]}px;
    height: ${space[3]}px;
    color: ${colors.text.secondary};
    border-radius: ${borderRadius}px;
    line-height: ${space[3]}px;
    font-weight: inherit;
    text-align: center;
    user-select: none;

    &:hover,
    &[aria-current='true'] {
      background-color: ${colors.highlight[0]};
      color: ${colors.text.primary};

      & svg {
        color: ${colors.text.primary};
      }
    }
  `,
);

const ItemsPerPageSelect = styled.div<{ isActive: boolean }>(
  ({ theme: { space, colors, borderRadius }, isActive }) => css`
    display: flex;
    align-items: center;
    padding: 0 10px;
    color: ${colors.text.secondary};
    border-radius: ${borderRadius}px;
    cursor: pointer;
    user-select: none;
    height: 28px;

    & svg {
      margin-left: ${space[1]}px;
    }

    ${isActive &&
    css`
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};

      & svg {
        color: ${colors.text.primary};
      }
    `}

    &:hover {
      background-color: ${colors.highlight[0]};
      color: ${colors.text.primary};

      & svg {
        color: ${colors.text.primary};
      }
    }
  `,
);

const PerPageButton = styled(PageButton)(
  ({ theme: { space } }) => css`
    width: 48px;
    height: ${space[4]}px;
    margin: 0;
    line-height: ${space[4]}px;
    border-radius: 0;
  `,
);

const PagesWrapper = styled.ul`
  display: flex;
  align-items: center;
  column-gap: 2px;
  margin: 0;
  padding: 0 1px;
  list-style: none;
`;

const getVisiblePages = (
  page: number,
  totalPages: number,
  isLimit?: boolean,
): Array<number | string> => {
  if (totalPages <= MAX_VISIBLE_ITEMS) return range(1, totalPages + 1);

  if (totalPages - page <= 3) {
    return [1, '...', ...range(totalPages - 4, totalPages + 1)];
  }

  if (isLimit) {
    if (page <= 4) {
      return [...range(1, 7), '...'];
    }

    if (page > 4 && page < totalPages - 3) {
      return [1, '...', page - 1, ...range(page, page + 3), '...'];
    }
  } else {
    if (page <= 4) {
      return [...range(1, 6), '...', totalPages];
    }

    if (page > 4 && page < totalPages - 3) {
      return [1, '...', page - 1, page, page + 1, '...', totalPages];
    }
  }

  return [];
};

interface IPaginationProps {
  noScroll?: boolean;
  totalItems: number;
  limit?: number;
  itemsPerPageOptions?: number[];
}

const Pagination: FC<IPaginationProps & SpaceProps & LayoutProps> = ({
  noScroll = false,
  totalItems,
  limit,
  itemsPerPageOptions = PRIMARY_ITEMS_PER_PAGE,
  ...other
}) => {
  const [
    { page: currentPage = 1, itemsPerPage = itemsPerPageOptions[0] },
    querySet,
  ] = useQueryParams({ page: Number, itemsPerPage: Number });

  const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
    useContextMenu();

  useEffect(noScroll ? STUB_FUNC.NOOP : scrollToTop, [
    currentPage,
    itemsPerPage,
  ]);

  if (totalItems <= itemsPerPageOptions[0]) return null;

  const totalPages = getTotalPages(totalItems, itemsPerPage, limit);
  const visiblePages = getVisiblePages(currentPage, totalPages, !!limit);

  return (
    <Container
      alignItems='center'
      borderTop={`1px solid ${theme.colors.background}`}
      minHeight='53px'
      px={1}
      {...other}
    >
      <ItemsPerPageSelect
        aria-label='Показать по:'
        isActive={open}
        onClick={handleContextMenuOpen}
      >
        Показать по:&nbsp;&nbsp; {itemsPerPage}
        {open ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
      </ItemsPerPageSelect>
      <ContextMenu
        anchorEl={anchorEl}
        open={open}
        placement='top-end'
        style={CONTEXT_MENU_STYLE}
        onRequestClose={handleContextMenuClose}
      >
        {itemsPerPageOptions.map(option => {
          const isActive = option === itemsPerPage;

          return (
            <div key={option}>
              <PerPageButton
                replace
                aria-label={`Показать по ${option}`}
                to={querySet(params => ({
                  ...params,
                  page: 1,
                  itemsPerPage: option,
                }))}
                {...(isActive && { 'aria-current': true })}
                onClick={handleContextMenuClose}
              >
                {option}
              </PerPageButton>
            </div>
          );
        })}
      </ContextMenu>
      {totalPages > 1 && (
        <Container aria-label='Выбор страницы' as='nav' ml={2}>
          <PagesWrapper>
            {currentPage !== 1 && (
              <li>
                <PageButton
                  replace
                  aria-label='Предыдущая страница'
                  to={querySet(params => ({
                    ...params,
                    page: currentPage - 1,
                  }))}
                >
                  <ArrowLeft1pxIcon />
                </PageButton>
              </li>
            )}
            {visiblePages.map((page, index) => {
              const isPage = typeof page === 'number';
              const isActive = page === currentPage;

              if (!isPage) {
                return (
                  <Container
                    key={`${visiblePages[index - 1]}-dots`}
                    as='li'
                    {...DOTS_STYLE}
                  >
                    {page}
                  </Container>
                );
              }

              return (
                <li key={page}>
                  <PageButton
                    replace
                    aria-label={`Страница ${page}`}
                    to={querySet(params => ({ ...params, page }))}
                    {...(isActive && { 'aria-current': true })}
                  >
                    {getFormattedNumber(page)}
                  </PageButton>
                </li>
              );
            })}
            {currentPage < totalPages && (
              <li>
                <PageButton
                  replace
                  aria-label='Следующая страница'
                  to={querySet(params => ({
                    ...params,
                    page: currentPage + 1,
                  }))}
                >
                  <ArrowRight1pxIcon />
                </PageButton>
              </li>
            )}
          </PagesWrapper>
        </Container>
      )}
    </Container>
  );
};

interface IPaginationControlledProps {
  page: number;
  itemsPerPage: number;
  totalItems: number;
  setPage: Dispatch<SetStateAction<number>>;
}

const PaginationControlled: FC<
  IPaginationControlledProps & SpaceProps & TypographyProps
> = ({
  page,
  itemsPerPage,
  totalItems,
  setPage,
  fontWeight = 500,
  ...other
}) => {
  const totalPages = getTotalPages(totalItems, itemsPerPage);
  const visiblePages = getVisiblePages(page, totalPages);

  if (totalPages < 2) return null;

  return (
    <Container
      {...other}
      aria-label='Выбор страницы'
      as='nav'
      fontWeight={fontWeight}
    >
      <PagesWrapper>
        {page !== 1 && (
          <li>
            <PageButton
              aria-label='Предыдущая страница'
              as='button'
              onClick={() => setPage(prev => prev - 1)}
            >
              <ArrowLeft1pxIcon />
            </PageButton>
          </li>
        )}
        {visiblePages.map((visiblePage, index) => {
          const isPage = typeof visiblePage === 'number';
          const isActive = visiblePage === page;

          if (!isPage) {
            return (
              <Container
                key={`${visiblePages[index - 1]}-dots`}
                as='li'
                {...DOTS_STYLE}
              >
                {visiblePage}
              </Container>
            );
          }

          return (
            <li key={visiblePage}>
              <PageButton
                aria-label={`Страница ${visiblePage}`}
                as='button'
                onClick={() => setPage(visiblePage)}
                {...(isActive && { 'aria-current': true })}
              >
                {getFormattedNumber(visiblePage)}
              </PageButton>
            </li>
          );
        })}
        {page < totalPages && (
          <li>
            <PageButton
              aria-label='Следующая страница'
              as='button'
              onClick={() => setPage(prev => prev + 1)}
            >
              <ArrowRight1pxIcon />
            </PageButton>
          </li>
        )}
      </PagesWrapper>
    </Container>
  );
};

export { Pagination, PaginationControlled };
