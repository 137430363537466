import { FC, memo, useCallback } from 'react';

import styled, { css } from 'styled-components';

import { useReform } from 'reform';
import { CheckMediumIcon, EditMediumIcon, InfoIcon, RestoreIcon } from 'resources/icons/18';
import { useToggle } from 'tools/hooks';
import { Container, IconButton, TextInput } from 'UI';
import { InputContainer } from 'UI/Inputs';
import theme from 'UI/theme';

const HINT = (
  <>
    Название вариации генерируется в зависимости от выбранных значений
    параметров. Вы можете <strong>указать название вручную,</strong> при этом
    все заполненные выше данные будут недоступны.
  </>
);

const NameContainer = styled(Container)<{
  isEditMode: boolean;
  isError: boolean;
}>(
  ({ isEditMode, isError }) => css`
    align-items: center;
    padding-left: 18px;
    background: ${isEditMode ? theme.colors.white : theme.colors.highlight[0]};
    height: ${theme.space[5]}px;
    border-top: 1px solid ${theme.colors.divider};
    border-bottom: 1px solid ${theme.colors.divider};

    > ${InputContainer} {
      color: ${theme.colors.text.primary};
      background: transparent;
      border: none;
    }

    ${isError &&
    css`
      border-top: 1px solid ${theme.colors.error.main};
      border-bottom: 1px solid ${theme.colors.error.main};
    `}
  `,
);

interface IGenerationNameProps {
  fieldName: string;
  isCustomName?: boolean;
  name: string;
  onSetName: (value) => void;
  onSetIsCustomName: (value) => void;
  generatedBackendName: string;
  isError: boolean;
  isDisabled: boolean;
}

/**
 * Компонент, отвечающий за генерацию названия в сортаменте и стандарте заготовки
 */
const WorkPieceGenerationName: FC<IGenerationNameProps> = memo(
  ({
    fieldName,
    isCustomName,
    name,
    onSetName,
    onSetIsCustomName,
    generatedBackendName,
    isError,
    isDisabled,
  }) => {
    const [isEditMode, toggleEditMode] = useToggle();

    const { setFieldValue } = useReform();
    const handleSetEditMode = useCallback(() => {
      toggleEditMode();

      setFieldValue(fieldName, prev => ({
        ...prev,
        _isDisabled: !prev._isDisabled || !!isCustomName,
      }));
    }, [fieldName, isCustomName, setFieldValue, toggleEditMode]);

    const handleRestoreBackendName = (): void => {
      onSetName(generatedBackendName);
      onSetIsCustomName(false);
      toggleEditMode(false);
      setFieldValue(fieldName, prev => ({
        ...prev,
        _isDisabled: false,
      }));
    };

    const handleChangeName = ({ target }): void => {
      /**
       * Если флаг isCustomName false, но текущее значение уже отличается от сгенерированного,
       * то ставим флаг isCustomName в true
       * Иначе если флаг true, но текущее значение уже совпало со сгенерированным, то ставим флаг в false
       */
      if (!isCustomName && target.value !== generatedBackendName) {
        onSetIsCustomName(true);
      } else if (isCustomName && target.value === generatedBackendName) {
        onSetIsCustomName(false);
      }

      onSetName(target.value);
    };

    return (
      <NameContainer isEditMode={isEditMode} isError={isError}>
        <TextInput
          disabled={!isEditMode || isDisabled}
          placeholder={
            isEditMode
              ? 'Введите название'
              : 'Название будет сформировано автоматически при заполнении информации'
          }
          rightAddon={
            <Container alignItems='center'>
              {isCustomName && (
                <IconButton
                  disabled={isDisabled}
                  mr={0}
                  title='Восстановить сгенерированное'
                  onClick={handleRestoreBackendName}
                >
                  <RestoreIcon />
                </IconButton>
              )}
              <IconButton
                disabled={isDisabled}
                mr={1}
                title={isEditMode ? 'Сохранить изменения' : 'Указать вручную'}
                onClick={handleSetEditMode}
              >
                {isEditMode ? <CheckMediumIcon /> : <EditMediumIcon />}
              </IconButton>
              <IconButton
                as='div'
                borderLeft={`1px solid ${theme.colors.divider}`}
                size={46}
                title={{ title: HINT, maxWidth: 240 }}
              >
                <InfoIcon />
              </IconButton>
            </Container>
          }
          value={name}
          onChange={handleChangeName}
        />
      </NameContainer>
    );
  },
);

export { WorkPieceGenerationName };
