import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

export const FormHeading = styled.div<SpaceProps>`
  display: flex;
  align-items: center;
  height: 28px;

  ${space};
`;
