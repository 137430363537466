import { FC, memo } from 'react';

import ReactMarkdown from 'react-markdown';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { useMenuScroll, useRequest } from 'common/hooks';
import { GUIDES_BASE_URL } from 'common/utils/request';
import { SIDE_MENU_TEXT_PROPS } from 'components/products/side-menu/constants';
import { SubMenu } from 'layouts/components';
import { CenteredPage, DefaultPage } from 'layouts/Pages';
import { BzIcon } from 'resources/icons/18';
import { getPath, GUIDES } from 'routes';
import { Link } from 'tools/libs';
import {
  Container,
  SideMenuGroup,
  SideMenuItem,
  SideMenuMainItem,
  Spinner,
  Text,
} from 'UI';

import { SearchGuideElement } from './SearchElement';
import { TextBlock } from '../../components';
import { IGuide } from '../../types';
import { fetchGuide } from '../api';
import { MAIN_ITEM_TITLE, PAGE_HEADING } from '../constants';

const GUIDE_CAR_ID = 'guideCardId';
const getSectionElementId = (id: string): string => `guideSection${id}`;

const Section = styled.div<{ isSimilar: boolean }>(
  ({ theme: { colors, space }, isSimilar }) => css`
    &::after {
      content: '';
      display: block;
      margin: ${space[6]}px -${space[4]}px;
      height: 1px;
      background: ${colors.divider};
    }

    &:first-of-type {
      margin-top: ${space[2]}px;
    }

    &:nth-last-child(${isSimilar ? 2 : 1}) {
      padding-bottom: ${space[6]}px;

      &::after {
        display: none;
      }
    }
  `,
);

const SectionWrapper = styled(TextBlock).attrs(() => ({
  width: CenteredPage.CONTENT_WIDTH.MEDIUM,
}))`
  margin: 0 auto;
`;

const SimilarLink = styled(Link)(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: block;
    width: fit-content;
    margin-bottom: ${space[2]}px;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text.secondary};
    word-break: break-word;

    &:hover {
      color: ${colors.primary.main};
    }
  `,
);

const GuideCard: FC = memo(() => {
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const { guideId } = useParams<{ guideId: string }>();

  const {
    isLoading,
    data: {
      sections = [],
      title,
      category,
      similarGuides = [],
    } = {} as IGuide[],
  } = useRequest(fetchGuide, [guideId]);

  const menuItemIds = sections.map(section => getSectionElementId(section.id));
  const notCurrentSimilarGuides = similarGuides.filter(
    ({ id }) => id !== Number(guideId),
  );

  const [activeMenuItem, handleScrollTo] = useMenuScroll({
    parentId: GUIDE_CAR_ID,
    defaultActiveItem: menuItemIds[0],
    data: menuItemIds,
  });

  return (
    <DefaultPage
      bg='white'
      heading={{
        heading: PAGE_HEADING,
        backLink: location.key
          ? history.goBack
          : getPath(GUIDES.PAGE, { categoryId: category?.id }),
        controls: <SearchGuideElement />,
      }}
      title={title ?? ''}
    >
      <Container height='100%' overflow='hidden' width='100%'>
        <SubMenu>
          <SideMenuMainItem
            borderRadius='6px'
            mr={1}
            my={1}
            rightAction={<BzIcon color='primary.main' />}
            to={GUIDES.LIST}
          >
            {MAIN_ITEM_TITLE}
          </SideMenuMainItem>
          <Spinner delay={0} loading={isLoading}>
            <SideMenuItem
              primary
              active={false}
              mt={1}
              text={category?.title}
              to={getPath(GUIDES.PAGE, { categoryId: category?.id })}
            />
            <SideMenuGroup secondary mt={1} title={title}>
              {sections.length > 1 &&
                sections.map((section, index) => (
                  <SideMenuItem
                    {...SIDE_MENU_TEXT_PROPS}
                    key={menuItemIds[index]}
                    active={activeMenuItem === menuItemIds[index]}
                    as='button'
                    fontSize={3}
                    mb='0px'
                    text={section.name}
                    onClick={() => handleScrollTo(menuItemIds[index])}
                  />
                ))}
            </SideMenuGroup>
          </Spinner>
        </SubMenu>
        <Container
          display='block'
          id={GUIDE_CAR_ID}
          overflow='auto'
          width='100%'
        >
          <Container
            column
            alignItems='stretch'
            height='100%'
            m='0 auto'
            p={2}
            width='100%'
          >
            <Spinner
              backgroundColor='#fff'
              delay={100}
              height='100%'
              loading={isLoading}
              width='100%'
            >
              {sections.map(({ id, content, name }) => (
                <Section
                  key={getSectionElementId(id)}
                  isSimilar={notCurrentSimilarGuides.length > 0}
                >
                  <SectionWrapper>
                    <h2 id={getSectionElementId(id)}>{name}</h2>
                    <ReactMarkdown
                      transformImageUri={uri => `${GUIDES_BASE_URL}${uri}`}
                    >
                      {content}
                    </ReactMarkdown>
                  </SectionWrapper>
                </Section>
              ))}
              {notCurrentSimilarGuides.length > 0 && (
                <Container
                  background={theme.colors.highlight[0]}
                  mt='auto'
                  mx={-2}
                  pb={6}
                  pt={4}
                  px={4}
                >
                  <SectionWrapper>
                    <Text fontSize={16} fontWeight={500} mb={3} mx='auto'>
                      Связанные статьи
                    </Text>
                    <div>
                      {notCurrentSimilarGuides
                        .slice(0, 5)
                        .map(
                          ({
                            id,
                            title: guideTitle,
                            category: guideCategory,
                          }) => (
                            <SimilarLink
                              key={id}
                              to={getPath(GUIDES.CARD, {
                                categoryId: guideCategory,
                                guideId: id,
                              })}
                            >
                              {guideTitle}
                            </SimilarLink>
                          ),
                        )}
                    </div>
                  </SectionWrapper>
                </Container>
              )}
            </Spinner>
          </Container>
        </Container>
      </Container>
    </DefaultPage>
  );
});

export { GuideCard };
