import { FC, memo, useCallback, useEffect, useRef, useState } from 'react';

import styled from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { fetchProductSpecifications } from 'common/api/specifications.api';
import {
  LIFE_STATE_COLORS,
  LIFE_STATE_NAMES,
  MULTIPLE_PRODUCT_ENTITY_TO_TEXT,
  PRODUCT_CATEGORY_TEXT,
} from 'common/constants/products.const';
import { SPECIFICATIONS_LIFE_STATE_NAMES } from 'common/constants/specifications.const';
import { useScrollRequest } from 'common/hooks';
import { ProductEntity } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { MainProperties } from 'components/products/properties';
import { Folder_groupIcon } from 'resources/icons/24';
import { MatchesIcon, UnavailableIcon } from 'resources/placeholders';
import { getPath, PRODUCTS } from 'routes';
import { Link } from 'tools/libs';
import {
  Avatar,
  Badge,
  Container,
  Modal,
  Spinner,
  Text,
  ToggleButton,
} from 'UI';

const CONTAINER_HEIGHT = 400;
const HINT_TEXT = 'Товары вашей компании, в состав которых добавлен текущий.';
const PRODUCT_SEARCH_PLACEHOLDER = 'Название, тип товара, теги и UIN';
const SPECIFICATION_SEARCH_PLACEHOLDER = 'Название, производитель и тип товара';

interface IPlaceholder {
  isProduct: boolean;
  searchValue: boolean;
}

const Placeholder: FC<IPlaceholder> = ({ searchValue, isProduct }) => (
  <Modal.Placeholder
    height={350}
    icon={searchValue ? <MatchesIcon mb={4} /> : <UnavailableIcon mb={4} />}
  >
    {searchValue ? (
      <>
        Совпадений не найдено.
        <br />
        Измените запрос и попробуйте снова.
      </>
    ) : (
      <>
        Текущий товар пока не добавлен в состав
        {isProduct ? ' товаров' : ' технических заданий'} вашей компании
      </>
    )}
  </Modal.Placeholder>
);

const ListContainer = styled.div`
  position: relative;
  height: ${CONTAINER_HEIGHT}px;
  overflow-y: auto;
  padding-right: 34px;
`;

const ListItem: FC<{ item: any }> = ({
  item,
  item: { name, previewImage, lifeState, id, mainProperties, category },
}) => {
  // Проверка  - товар или ТЗ
  const isProduct = !!category;
  const route = isProduct ? PRODUCTS.PRODUCT_PAGE : PRODUCTS.SPECIFICATION_PAGE;
  const url = getPath(route, { id });
  const badgeLabel = isProduct
    ? LIFE_STATE_NAMES
    : SPECIFICATIONS_LIFE_STATE_NAMES;

  return (
    <Modal.ListItem single as={Link} target='_blank' to={url} width='100%'>
      <Avatar mr={2} size='s' url={getImageURL(previewImage)} />
      <Container column width='380px'>
        <Text truncate color='text.primary' mr={1}>
          {name || <Text color='text.disabled'>Не указано</Text>}
        </Text>
        {category && (
          <Text color='text.secondary' fontSize={2}>
            {PRODUCT_CATEGORY_TEXT[category]}
          </Text>
        )}
      </Container>
      <Container ml='auto'>
        {mainProperties && <MainProperties product={item} />}
        <Container alignItems='flex-start' width='137px'>
          <Badge
            bg='transparent'
            dot={isProduct && LIFE_STATE_COLORS[lifeState]}
            label={badgeLabel[lifeState]}
            ml={2}
            type={Badge.TYPES.DEFAULT}
          />
        </Container>
      </Container>
    </Modal.ListItem>
  );
};

const TYPE_OPTIONS = {
  [ProductEntity.product]: {
    label: MULTIPLE_PRODUCT_ENTITY_TO_TEXT[ProductEntity.product],
    placeholder: PRODUCT_SEARCH_PLACEHOLDER,
    fetchOptions: fetchProducts,
  },
  [ProductEntity.specification]: {
    label: MULTIPLE_PRODUCT_ENTITY_TO_TEXT[ProductEntity.specification],
    placeholder: SPECIFICATION_SEARCH_PLACEHOLDER,
    fetchOptions: fetchProductSpecifications,
  },
};

interface IProductInCompositionModalProps {
  productId: string;
}

const ProductInCompositionModal: FC<IProductInCompositionModalProps> = memo(
  ({ productId }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [searchValue, setSearchValue] = useState('');
    const [entityType, setEntityType] = useState(ProductEntity.product);
    const isProduct = entityType === ProductEntity.product;

    const { placeholder, fetchOptions } = TYPE_OPTIONS[entityType];

    const { isLoading, data, onResetPagination } = useScrollRequest(
      fetchOptions,
      {
        productInComposition: productId,
        search: searchValue,
        entityType,
      },
      {
        scrollContainer: containerRef.current,
      },
    );

    const handleEntityTypeChange = useCallback(
      newEntityType => {
        setEntityType(newEntityType);
        setSearchValue('');
        onResetPagination();
      },
      [onResetPagination],
    );

    // Ставит page=1 когда изменяется значение search или entityType
    useEffect(() => {
      if (searchValue) {
        onResetPagination();
      }
    }, [onResetPagination, searchValue]);

    return (
      <Modal size='large'>
        <Modal.Default
          hideButtons
          hint={HINT_TEXT}
          icon={<Folder_groupIcon color='primary.main' />}
          paddingBottom={22}
          paddingTop={22}
          searchComponent={
            <Modal.Search
              placeholder={placeholder}
              value={searchValue}
              onChange={setSearchValue}
            />
          }
          style={{ paddingRight: 0 }}
          title='Добавлен в состав'
        >
          {Object.keys(TYPE_OPTIONS).map(type => {
            const { label } = TYPE_OPTIONS[type];
            return (
              <ToggleButton
                key={type}
                checked={entityType === type}
                label={label}
                mb={2}
                mr={0}
                value={type}
                onClick={() => handleEntityTypeChange(type)}
              />
            );
          })}
          <ListContainer ref={containerRef}>
            <Spinner delay={0} height='100%' loading={isLoading}>
              {data.length > 0 ? (
                data.map(item => <ListItem key={item.id} item={item} />)
              ) : (
                <Placeholder
                  isProduct={isProduct}
                  searchValue={!!searchValue}
                />
              )}
            </Spinner>
          </ListContainer>
        </Modal.Default>
      </Modal>
    );
  },
);

const PRODUCT_IN_COMPOSITION = 'PRODUCT_IN_COMPOSITION';

export { ProductInCompositionModal, PRODUCT_IN_COMPOSITION };
