import { FC, memo, useCallback, useState } from 'react';

import styled, { css } from 'styled-components';

import { fetchBrands } from 'common/api/brand.api';
import { useRequest } from 'common/hooks';
import { BrandVerificationStatus, IBrand } from 'common/types/brands.types';
import { getImageURL } from 'common/utils';
import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { useToggle } from 'tools/hooks';
import { Avatar, Container, Heading, PaginationButton, Spinner } from 'UI';

import { CardWrapper } from './styled';

const DescriptionToggle = styled.p(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: flex;
    align-items: center;
    margin-top: ${space[0]}px;
    margin-bottom: 0;
    font-size: ${fontSizes[2]}px;
    font-weight: 500;
    color: ${colors.text.disabled};
    cursor: pointer;
    line-height: 150%;
  `,
);

const Description = styled.p`
  margin: 4px 0 0;
  word-break: break-word;
`;

const ITEMS_PER_PAGE = 5;
interface IBrandProps {
  brand: IBrand;
}

const Brand: FC<IBrandProps> = memo(({ brand }) => {
  const [open, handleToggle] = useToggle(false);

  return (
    <CardWrapper>
      <Container alignItems='flex-start'>
        <Avatar letter={brand.name} size='s' url={getImageURL(brand.logo)} />
        <Container column alignItems='start' justifyContent='center' ml={2}>
          <Heading as='h5' mt={0}>
            {brand.name}
          </Heading>
          {brand.description && (
            <>
              <div>
                <DescriptionToggle onClick={() => handleToggle(!open)}>
                  Описание бренда
                  {open ? (
                    <ArrowUpIcon ml={1} size={12} />
                  ) : (
                    <ArrowDownIcon ml={1} size={12} />
                  )}
                </DescriptionToggle>
              </div>
              {open && <Description>{brand.description}</Description>}
            </>
          )}
        </Container>
      </Container>
    </CardWrapper>
  );
});

interface ICompanyBrandsProps {
  companyId: string;
}

const CompanyBrands: FC<ICompanyBrandsProps> = memo(({ companyId }) => {
  const [page, setPage] = useState(1);
  const [brands, setBrands] = useState<any>([]);

  const { isLoading, data: totalItems = 0 } = useRequest(
    fetchBrands,
    [
      {
        companyId,
        verificationStatus: BrandVerificationStatus.verified,
        page,
        itemsPerPage: ITEMS_PER_PAGE,
      },
    ],
    {
      responseToData: data => data.totalItems,
      onSuccess: ({ member }) => {
        setBrands([...brands, ...member]);
      },
    },
  );

  if (!isLoading && page === 1 && totalItems === 0) {
    return null;
  }

  const isPaginationVisible = brands.length < totalItems;

  const handleNextPage = useCallback(() => setPage(prev => prev + 1), []);

  return (
    <Spinner delay={0} height='316px' loading={isLoading && page === 1}>
      {brands.map((brand: any) => (
        <Brand key={brand?.id} brand={brand} />
      ))}
      {isPaginationVisible && (
        <PaginationButton onClick={handleNextPage}>
          <Spinner delay={0} loading={isLoading && page !== 1} size='xs'>
            Показано {brands.length} из {totalItems}. Показать еще
          </Spinner>
        </PaginationButton>
      )}
    </Spinner>
  );
});

export { CompanyBrands };
