import { ReactElement } from 'react';

import styled, { css } from 'styled-components';

import {
  ImportProductsCard,
  UploadProductCard,
  UploadStatementCard,
} from './cards';
import { ArchiveTypes, IArchiveItem } from '../types';

const HEIGHT_NOTIFY = 52;

const NotifyContainer = styled.div(
  ({ theme: { zIndex, shadows } }) => css`
    position: absolute;
    right: 0;
    top: 36px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    height: 0;
    width: 487px;
    overflow: hidden;
    box-shadow: ${shadows[2]};
    transition: height 200ms ease-in-out;
    z-index: ${zIndex.header + 1};
  `,
);

interface IArchivePopup {
  isOpen: boolean;
  archives: IArchiveItem[];
}

function ArchivePopup({ isOpen, archives }: IArchivePopup): ReactElement {
  const archiveCount = archives.length;

  return (
    <NotifyContainer
      style={{ height: !isOpen ? 0 : archiveCount * HEIGHT_NOTIFY }}
    >
      {archives.map(({ type, ...item }) => {
        if (type === ArchiveTypes.uploadProduct) {
          return <UploadProductCard key={item.id} archive={item} />;
        }

        if (type === ArchiveTypes.uploadStatement) {
          return <UploadStatementCard key={item.id} process={item} />;
        }

        return <ImportProductsCard key={item.id} process={item} />;
      })}
    </NotifyContainer>
  );
}

export { ArchivePopup };
