import { ElementType, FC } from 'react';

import styled, { css } from 'styled-components';
import { width, WidthProps } from 'styled-system';

import { IVideo } from 'common/types/media.types';
import { YoutubeOutlineIcon } from 'resources/other';
import { Img } from 'tools/libs';
import { Spinner } from 'UI';

import { useYoutubeVideo } from '../../hooks';

const ItemContainer = styled.div<WidthProps>(
  ({ theme: { colors, borderRadius } }) => css`
    height: 189px;
    width: 100%;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    color: inherit;
    background: ${colors.white};

    ${width}
  `,
);

const Preview = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    height: 130px;
    background: ${colors.background};

    img {
      width: 100%;
      max-height: 130px;
      object-fit: cover;
    }
  `,
);

const PlaceholderIcon = styled(YoutubeOutlineIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Name = styled.p<{ disabled: boolean }>(
  ({ theme: { space, colors }, disabled }) => css`
    display: -webkit-box;
    max-height: 36px; /* if line-clamp is not supported */
    margin-left: ${space[2]}px;
    margin-right: ${space[2]}px;
    font-weight: 600;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;

    ${disabled && `color: ${colors.text.disabled}`}
  `,
);

interface IVideoItemProps {
  video: IVideo;
  as?: ElementType;
}

const VideoItem: FC<IVideoItemProps & WidthProps> = ({
  as = 'a',
  video: { id, url },
  ...other
}) => {
  const { video, isLoading } = useYoutubeVideo({ url });
  const title = video?.snippet.title || 'Нет данных';
  const thumbnail = video?.snippet.thumbnails.medium.url;

  const previewImage = thumbnail ? (
    <Img alt={title} src={thumbnail} />
  ) : (
    <PlaceholderIcon />
  );

  const href = url.includes('://') ? url : `//${url}`;

  return (
    <ItemContainer
      as={as}
      href={href}
      id={id}
      rel='noopener noreferrer'
      {...other}
      target='_blank'
    >
      <Preview>
        <Spinner height='100%' loading={isLoading}>
          {previewImage}
        </Spinner>
      </Preview>
      <Name disabled={!video?.snippet.title}>{title}</Name>
    </ItemContainer>
  );
};

export { VideoItem };
