import { Fragment, memo, FC } from 'react';

import styled, { useTheme } from 'styled-components';

import { fetchManufacturedNtds } from 'common/api/ntd.api';
import { useRequest } from 'common/hooks';
import { INtdReference } from 'common/types/form.types';
import { IManufacturer } from 'common/types/manufacturing';
import { ManufacturingRequestType } from 'common/types/manufacturing-requests.types';
import { IAssortment } from 'common/types/product.types';
import { INtd } from 'common/types/standartization';
import { getImageURL } from 'common/utils';
import { CREATE_MANUFACTURING_REQUEST_MODAL } from 'entities/manufacturing-requests';
import { useManufactureButtonInfo } from 'entities/manufacturing-requests/hooks';
import { useModalContext } from 'entities/modals';
import { LinkOutsideIcon, SendIcon } from 'resources/icons/18';
import { getPath, PAGES, COMPANY } from 'routes';
import { Link } from 'tools/libs';
import { Avatar, Button, Container, IconButton, Text } from 'UI';

import { CardsContainer, ListCardContainer } from '../../styled';

const HiddenButtons = styled.div`
  display: flex;
  margin-left: auto;
  visibility: hidden;

  ${ListCardContainer}:hover & {
    visibility: visible;
  }
`;

interface IListCardProps {
  showRequestButton: boolean;
  manufacturer: IManufacturer;
  handleOpenOnMap: (id: string) => void;
  ntd?: INtd | INtdReference | IAssortment;
}

const ListCard: FC<IListCardProps> = memo(
  ({
    showRequestButton = false,
    manufacturer,
    manufacturer: { id, name, logo, location },
    handleOpenOnMap,
    ntd,
  }) => {
    const theme = useTheme();
    const { handleModalOpen } = useModalContext();

    const { title, disabled } = useManufactureButtonInfo({
      companyId: id,
      type: ManufacturingRequestType.ntdManufacturersBlock,
    });

    const { data: manufacturedNtdId } = useRequest(
      ntd && fetchManufacturedNtds,
      [{ companyId: id, ntdId: ntd?.id, itemsPerPage: 1 }],
      { responseToData: response => response.member[0]?.id },
    );

    const handleRequestModalOpen = (): void => {
      return handleModalOpen(CREATE_MANUFACTURING_REQUEST_MODAL, {
        companyId: id,
        type: ManufacturingRequestType.ntd,
        item: ntd,
        authorshipCompany: manufacturer,
      });
    };

    return (
      <CardsContainer>
        <ListCardContainer>
          <Avatar
            as={Link}
            letter={name}
            mr={2}
            to={getPath(COMPANY.CARD, { id })}
            url={getImageURL(logo)}
          />
          <Container display='block' pr={2} width='45%'>
            <Text
              truncate
              as={Link}
              color='text.primary'
              fontSize={4}
              fontWeight={500}
              mb={0}
              to={getPath(COMPANY.CARD, { id })}
            >
              {name}
            </Text>
            {location && (
              <Fragment>
                <Text truncate mb={0}>
                  {location.address}
                </Text>
                <Button
                  color='info'
                  style={{ fontSize: theme.fontSizes[2] }}
                  variant='string'
                  onClick={() => handleOpenOnMap(id)}
                >
                  Смотреть на карте
                </Button>
              </Fragment>
            )}
          </Container>
          <HiddenButtons>
            {showRequestButton && (
              <Container ml='auto'>
                <IconButton
                  disabled={disabled}
                  title={title}
                  variant='outline'
                  onClick={handleRequestModalOpen}
                >
                  <SendIcon />
                </IconButton>
              </Container>
            )}
            <IconButton
              as={Link}
              title='К НТД в производстве'
              to={getPath(PAGES.MANUFACTURED_NTD, {
                id: manufacturedNtdId,
              })}
            >
              <LinkOutsideIcon color='text.secondary' />
            </IconButton>
          </HiddenButtons>
        </ListCardContainer>
      </CardsContainer>
    );
  },
);

export { ListCard };
