import { FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { getImageURL } from 'common/utils';
import { LinkOutsideIcon } from 'resources/icons/18';
import { COMPANY, getPath } from 'routes';
import { Avatar, Text } from 'UI';

const ManufacturerWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: ${space[1]}px;
    color: ${colors.text.secondary};

    &:hover {
      color: ${colors.text.primary};
    }
  `,
);

const HiddenExternalLinkBold = styled(LinkOutsideIcon)`
  display: none;

  ${ManufacturerWrapper}:hover & {
    display: block;
  }
`;

interface IManufactureCardProps {
  id: string;
  name: string;
  logo: object;
}

const ManufactureCard: FC<IManufactureCardProps> = ({ id, name, logo }) => (
  <ManufacturerWrapper as={Link} to={getPath(COMPANY.CARD, { id })}>
    <Avatar
      letter={name}
      mr={1}
      size='xxs'
      target='_blank'
      url={getImageURL(logo, { size: 'l' })}
    />
    <Text truncate mr='auto'>
      {name}
    </Text>
    <HiddenExternalLinkBold ml={1} />
  </ManufacturerWrapper>
);

export { ManufactureCard };
