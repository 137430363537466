import { Fragment, memo, FC, ReactNode } from 'react';

import styled from 'styled-components';

import { fetchPartnerChannel } from 'common/api/partners.api';
import { useRequest } from 'common/hooks';
import { ICompany } from 'common/types/company.types';
import { PARTNERS_MODAL_TYPES } from 'common/types/partners.types';
import { PartnershipStatuses } from 'common/types/partnership';
import { ControlActions } from 'components/action-panel';
import { RegisterAndAuthText } from 'components/RegisterAndAuthText';
import { getCompanyId, hasIdentity, useAuthorization } from 'entities/identity';
import { useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { Permission, usePermission, OPERATIONS } from 'entities/permission';
import {
  AddMediumIcon,
  EditMediumIcon,
  CheckMediumIcon,
  CloseMediumIcon,
  CommentReportBoldIcon,
} from 'resources/icons/18';
import { getPath, COMPANY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import {
  Text,
  Button,
  Tooltip,
  Container,
  UnorderedList,
  ContextMenuItem,
} from 'UI';

import { Letter } from './Letter';

const ActionsItem = styled(ContextMenuItem)`
  width: 186px;
`;

const NoAuthTooltip = (
  <Fragment>
    Чтобы отправить запрос на партнерство
    <RegisterAndAuthText /> как юридическое лицо.
  </Fragment>
);

const NoCompanyTooltip = (): ReactNode => {
  const { logOut } = useAuthorization();

  return (
    <Text width={262}>
      Запросы на партнерство доступны компаниям и сотрудникам компаний:{' '}
      <UnorderedList mb='0px'>
        <UnorderedList.Li>
          <Button as={Link} to={COMPANY.REGISTRATION} variant='string'>
            Зарегистрировать компанию.
          </Button>
        </UnorderedList.Li>
        <UnorderedList.Li>
          <Button variant='string' onClick={logOut}>
            Выйти из системы
          </Button>{' '}
          и авторизоваться как компания или сотрудник.
        </UnorderedList.Li>
      </UnorderedList>
    </Text>
  );
};

const ReportButton: FC = () => {
  const { handleModalOpen } = useModalContext();
  return (
    <Button
      color='secondary'
      mr={0}
      px={1}
      variant='text'
      onClick={() => handleModalOpen(PARTNERS_MODAL_TYPES.REPORT_COMPANY)}
    >
      <CommentReportBoldIcon mr={0} />
      Пожаловаться
    </Button>
  );
};

const enum titleByStatus {
  incoming = 'Входящий запрос на партнерство',
  outgoing = 'Исходящий запрос на партнерство',
  blocked = 'Причина блокировки',
}

interface IConfirmedPartnerActionsProps {
  partnerId?: string;
  company: ICompany;
  updateData: VoidFunction;
  handleModalOpen: (type: string, data?: any) => void;
}

const ConfirmedPartnerActions: FC<IConfirmedPartnerActionsProps> = memo(
  ({ partnerId, company, updateData, handleModalOpen }) => {
    const { data: partnerChannel = {} } = useRequest(
      partnerId && fetchPartnerChannel,
      [partnerId],
    );

    return (
      <ControlActions description='Активный партнер'>
        <Permission operation={OPERATIONS.PARTNERS.UPDATE_COMPANY_PARTNERSHIP}>
          <ActionsItem
            px={1}
            text='Управлять группами'
            onClick={() =>
              handleModalOpen(PARTNERS_MODAL_TYPES.ADD_TO_GROUP, {
                selectedPartner: company.partner,
              })
            }
          />
        </Permission>
        <ActionsItem
          as={Link as any}
          px={1}
          text='Открыть канал'
          to={`/partners/channels/${partnerChannel.partnerChannel?.id}/granted`}
        />
        <Permission
          operation={OPERATIONS.PARTNERS.BLOCK_COMPANY_ACTIVE_PARTNERSHIP}
        >
          <ActionsItem
            px={1}
            text='Заблокировать'
            onClick={() =>
              handleModalOpen(PARTNERS_MODAL_TYPES.BLOCK_PARTNER, {
                partnerId,
                updateData,
              })
            }
          />
        </Permission>
        <ActionsItem
          px={1}
          text='Пожаловаться'
          onClick={() => handleModalOpen(PARTNERS_MODAL_TYPES.REPORT_COMPANY)}
        />
      </ControlActions>
    );
  },
);

interface IUserCompanyActionsProps {
  company: ICompany;
}

const UserCompanyActions: FC<IUserCompanyActionsProps> = memo(({ company }) => {
  const hasUpdateOwnCompanyAccess = usePermission(
    OPERATIONS.COMPANY.UPDATE_OWN_COMPANY,
  );

  if (!hasUpdateOwnCompanyAccess) {
    return null;
  }

  return (
    <Button
      as={Link}
      color='secondary'
      px={1}
      to={getPath(COMPANY.EDIT, { id: company.id })}
      variant='text'
    >
      <EditMediumIcon mr={0} />
      Редактировать
    </Button>
  );
});

interface IPendingPartnerActionsProps {
  company: ICompany;
  isCompanyInitiator: boolean;
  partnerId?: string;
  updateData: VoidFunction;
  handleModalOpen: (type: string, data?: any) => void;
  handleChangeStatus: ({
    status,
    letter,
  }: {
    status: string;
    letter: string;
  }) => void;
}

const PendingPartnerActions: FC<IPendingPartnerActionsProps> = memo(
  ({
    isCompanyInitiator,
    partnerId,
    updateData,
    handleModalOpen,
    handleChangeStatus,
    company,
  }) => {
    if (isCompanyInitiator) {
      return (
        <Container alignItems='center' display='inline-flex'>
          <Permission
            operation={OPERATIONS.PARTNERS.UPDATE_COMPANY_PARTNERSHIP}
          >
            <Button
              mr={0}
              px={1}
              variant='text'
              onClick={() =>
                handleChangeStatus({
                  status: PartnershipStatuses.declined,
                  letter: 'Запрос на партнерство был отменен',
                })
              }
            >
              <CloseMediumIcon mr={0} />
              Отменить запрос
            </Button>
          </Permission>
          <ReportButton />
          <Letter
            isUnread
            date={company?.partner?.partnership.updatedAt}
            text={company?.partner?.partnership.letter}
            title={titleByStatus.outgoing}
          />
        </Container>
      );
    }

    return (
      <Container alignItems='center' display='inline-flex'>
        <Permission operation={OPERATIONS.PARTNERS.UPDATE_COMPANY_PARTNERSHIP}>
          <Button
            mr={0}
            px={1}
            variant='text'
            onClick={() =>
              handleChangeStatus({
                status: PartnershipStatuses.confirmed,
                letter: '',
              })
            }
          >
            <CheckMediumIcon mr={0} />
            Принять запрос
          </Button>
          <Button
            color='secondary'
            mr={0}
            px={1}
            variant='text'
            onClick={() =>
              handleModalOpen(PARTNERS_MODAL_TYPES.REJECT_PARTNER, {
                partnerId,
                updateData,
              })
            }
          >
            <CloseMediumIcon mr={0} />
            Отклонить запрос
          </Button>
        </Permission>
        <ReportButton />
        <Letter
          isUnread
          date={company?.partner?.partnership.updatedAt}
          text={company?.partner?.partnership.letter}
          title={titleByStatus.incoming}
        />
      </Container>
    );
  },
);

interface IBlockedPartnerActionsProps {
  company: ICompany;
  isCompanyInitiator: boolean;
  partnerId?: string;
  updateData: VoidFunction;
  handleModalOpen: (type: string, data?: any) => void;
  handleChangeStatus: ({
    status,
    letter,
  }: {
    status: string;
    letter: string;
  }) => void;
}

const BlockedPartnerActions: FC<IBlockedPartnerActionsProps> = memo(
  ({ company, partnerId, updateData, handleModalOpen }) => {
    return (
      <Container alignItems='center' display='inline-flex'>
        <Permission operation={OPERATIONS.PARTNERS.CREATE_COMPANY_PARTNER}>
          <Button
            mr={0}
            px={1}
            variant='text'
            onClick={() => {
              const data = partnerId
                ? { partnerId }
                : { partnerCompanyId: company.id };
              handleModalOpen(PARTNERS_MODAL_TYPES.INVITE_EXIST_PARTNER, {
                ...data,
                updateData,
              });
            }}
          >
            <AddMediumIcon mr={0} />
            Добавить в партнеры
          </Button>
        </Permission>
        <ReportButton />
        <Letter
          isUnread
          date={company?.partner?.partnership.updatedAt}
          text={company?.partner?.partnership.letter}
          title={titleByStatus.blocked}
        />
      </Container>
    );
  },
);

interface ICompanyActionsProps {
  isCompanyInitiator: boolean;
  isUserCompany: boolean;
  company: ICompany;
  updateData: VoidFunction;
  handleChangeStatus: ({
    status,
    letter,
  }: {
    status: string;
    letter: string;
  }) => void;
}

const CompanyActions: FC<ICompanyActionsProps> = memo(
  ({
    isCompanyInitiator,
    isUserCompany,
    company,
    updateData,
    handleChangeStatus,
  }) => {
    const { handleModalOpen } = useModalContext();
    const notify = useNotify();
    const isAuthed = useAppSelector(hasIdentity);
    const hasCompany = !!useAppSelector(getCompanyId);
    const partnerId = company.partner?.id;
    const status = company.partner?.partnership.status;
    const hasReadCompanyPartnerAccess = usePermission(
      OPERATIONS.PARTNERS.READ_COMPANY_PARTNER,
    );

    if (!isAuthed) {
      return (
        <Tooltip hint maxWidth='262px' title={NoAuthTooltip}>
          <div>
            <Button disabled mr={0} px={1} variant='text'>
              <AddMediumIcon mr={0} />
              Добавить в партнеры
            </Button>
          </div>
        </Tooltip>
      );
    }

    if (isAuthed && !hasCompany) {
      return (
        <Tooltip hint title={NoCompanyTooltip()}>
          <div>
            <Button disabled mr={0} px={1} variant='text'>
              <AddMediumIcon mr={0} />
              Добавить в партнеры
            </Button>
          </div>
        </Tooltip>
      );
    }

    if (!company.isRegistered) {
      return (
        <>
          <Permission operation={OPERATIONS.PARTNERS.CREATE_COMPANY_PARTNER}>
            <Button
              mr={0}
              px={1}
              variant='text'
              onClick={() => {
                handleModalOpen(PARTNERS_MODAL_TYPES.INVITE_PARTNER, {
                  onSubmit: () => {
                    notify.success('Приглашение в систему отправлено.');
                  },
                });
              }}
            >
              <AddMediumIcon mr={0} />
              Пригласить компанию
            </Button>
          </Permission>
          <ReportButton />
        </>
      );
    }

    if (!hasReadCompanyPartnerAccess) {
      return null;
    }

    if (isUserCompany) {
      return <UserCompanyActions company={company} />;
    }

    if (status === PartnershipStatuses.confirmed) {
      return (
        <ConfirmedPartnerActions
          company={company}
          handleModalOpen={handleModalOpen}
          partnerId={partnerId}
          updateData={updateData}
        />
      );
    }

    if (
      status === PartnershipStatuses.blocked ||
      status === PartnershipStatuses.declined
    ) {
      return (
        <BlockedPartnerActions
          company={company}
          handleChangeStatus={handleChangeStatus}
          handleModalOpen={handleModalOpen}
          isCompanyInitiator={isCompanyInitiator}
          partnerId={partnerId}
          updateData={updateData}
        />
      );
    }

    if (status === PartnershipStatuses.pending) {
      return (
        <PendingPartnerActions
          company={company}
          handleChangeStatus={handleChangeStatus}
          handleModalOpen={handleModalOpen}
          isCompanyInitiator={isCompanyInitiator}
          partnerId={partnerId}
          updateData={updateData}
        />
      );
    }

    return (
      <>
        <Permission operation={OPERATIONS.PARTNERS.CREATE_COMPANY_PARTNER}>
          <Button
            mr={0}
            px={1}
            variant='text'
            onClick={() => {
              const data = partnerId
                ? { partnerId }
                : { partnerCompanyId: company.id };
              handleModalOpen(PARTNERS_MODAL_TYPES.INVITE_EXIST_PARTNER, {
                ...data,
                updateData,
              });
            }}
          >
            <AddMediumIcon mr={0} />
            Добавить в партнеры
          </Button>
        </Permission>
        <ReportButton />
      </>
    );
  },
);

export { CompanyActions };
