import {
  Dispatch,
  FC,
  Fragment,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';

import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { handleScrollTo } from 'common/hooks';
import { NavigationIcon } from 'resources/icons/18';
import {
  BackLink,
  Button,
  Container,
  ContextMenuItem,
  Tooltip,
  VerticalDivider,
} from 'UI';

const Panel = styled.div(
  ({ theme: { space, colors, shadows } }) => css`
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
    position: sticky;
    top: 0;
    z-index: 50;
    min-width: 1000px;
    max-height: 50px;
    min-height: 50px;
    margin: ${space[2]}px 20px 0 ${space[2]}px;
    padding: ${space[0]}px ${space[0]}px ${space[0]}px ${space[1]}px;
    background: ${colors.white};
    border-radius: 8px;
    box-shadow: ${shadows[1]};
  `,
);

const AddonWrapper = styled.div<{ hasShowText: boolean }>(
  ({ theme: { space }, hasShowText }) => css`
    display: flex;
    flex-wrap: nowrap;
    gap: ${space[0]}px;
    margin-left: 100px;

    ${hasShowText &&
    css`
      & button {
        min-width: auto;
        font-size: 0;

        & div {
          margin: 0;
        }
      }
    `}
  `,
);

const OTHER_OCCUPIED_SPACE_PANEL = 270;
const MIN_WIDTH_STYLE = { minWidth: 'auto' };
const OBSERVE_OPTIONS = { box: 'content-box' } as const;

export type SectionNavigationType = {
  title: string;
  dataType: string;
};

export type SectionNavigationsType = SectionNavigationType[];

interface IActionPanelProps {
  sections?: SectionNavigationType[];
  rightAddon?: JSX.Element;
  onShowTooltip?: Dispatch<SetStateAction<boolean>>;
}

const ActionPanel: FC<IActionPanelProps> = ({
  sections = [],
  rightAddon,
  onShowTooltip,
}) => {
  const history = useHistory();
  const location = useLocation();

  const panelRef = useRef<HTMLDivElement>(null);
  const rightAddonRef = useRef<HTMLDivElement>(null);

  const [hasShowText, setShowText] = useState(false);
  const [rightAddonWidth, setRightAddonWidth] = useState(0);

  useEffect(() => {
    if (rightAddonRef.current) {
      setRightAddonWidth(rightAddonRef.current.clientWidth);
    }
  }, []);

  useEffect(() => {
    const panelObserver = new ResizeObserver(() => {
      const occupiedSpace = rightAddonWidth + OTHER_OCCUPIED_SPACE_PANEL;

      const hasCompress = panelRef.current
        ? panelRef.current.clientWidth - occupiedSpace <= 0
        : false;

      setShowText(hasCompress);
      onShowTooltip?.(hasCompress);
    });

    if (panelRef.current) {
      panelObserver.observe(panelRef.current, { ...OBSERVE_OPTIONS });
    }

    return () => panelObserver.disconnect();
  }, [rightAddonWidth, onShowTooltip]);

  return (
    <Panel ref={panelRef}>
      <Container mr='auto'>
        {location.key && history.length > 0 && (
          <Fragment>
            <BackLink backLink={history.goBack} title='Вернуться назад' />
            <VerticalDivider height={34} mx={0} />
          </Fragment>
        )}
        {sections.length > 0 && (
          <Tooltip
            offset={[-8, -4]}
            paperCloseDelay={100}
            placement='bottom-start'
            title={sections.map(({ title, dataType }) => (
              <ContextMenuItem
                key={title}
                data-type={dataType}
                px={1}
                text={title}
                onClick={handleScrollTo}
              />
            ))}
          >
            <Button color='secondary' style={MIN_WIDTH_STYLE} variant='text'>
              <NavigationIcon mr={1} />
              Навигация
            </Button>
          </Tooltip>
        )}
      </Container>
      {rightAddon && (
        <AddonWrapper ref={rightAddonRef} hasShowText={hasShowText}>
          {rightAddon}
        </AddonWrapper>
      )}
    </Panel>
  );
};

export { ActionPanel, IActionPanelProps };
