import {
  Fragment,
  useState,
  useEffect,
  useCallback,
  memo,
  useRef,
  FC,
} from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import {
  findCompaniesByIds,
  findCompanyOptions,
} from 'common/api/company-management';
import { ARTICLES_TYPES_NAME } from 'common/constants/articles.const';
import { useQueryParams, useRequest } from 'common/hooks';
import { ArticleTypes } from 'common/types/company-management';
import { ICompany } from 'common/types/company.types';
import { formatDate } from 'common/utils';
import { RegisterAndAuthText } from 'components/RegisterAndAuthText';
import {
  SideFilter,
  getCountOfFilters,
  SideFilterButtons,
  SideFilterWrapper,
} from 'components/side-filter';
import { hasIdentity } from 'entities/identity';
import { Field, Reform, useField, IReform } from 'reform';
import { Delete1pxIcon } from 'resources/icons/1px-12';
import { useAppSelector } from 'store';
import { useToggle } from 'tools/hooks';
import { itemTo, removeItem } from 'tools/utils';
import {
  Spinner,
  Tooltip,
  IconButton,
  ToggleButton,
  CheckboxField,
  SideMenuGroup,
  DateInputRange,
  MultiSelectField,
  MultiStringField,
} from 'UI';

const TagsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: 100px 100px;
    gap: ${space[0]}px;
    margin-top: ${space[1]}px;
  `,
);

const TitleTooltip = (
  <Fragment>
    Для работы с партнерами <RegisterAndAuthText /> как юридическое лицо.
  </Fragment>
);

interface IArticlesFormProps {
  type?: ArticleTypes[];
  // поле только для формы. Нужно для транформации данных компании при открытии и
  // на бекенд отправляется как companyId[]
  reformCompanyId?: ICompany[];
  reformPublishedAt: { after?: string; before?: string };
  tag?: string[];
  partnersOnly: boolean;
  publishedAt?: { after?: string; before?: string };
}

const INITIAL_VALUE = {
  type: [],
  reformCompanyId: [],
  reformPublishedAt: { after: '', before: '' },
  tag: [],
  partnersOnly: false,
} as IArticlesFormProps;

const TypeTags: FC = () => {
  const { value: type = [], setValue } = useField({ name: 'type' });
  const handleChange = useCallback(
    ({ currentTarget }): void => {
      const { checked, value } = currentTarget;

      if (checked) {
        setValue(removeItem(type, item => item === value));
      } else {
        setValue([...type, value]);
      }
    },
    [type, setValue],
  );

  return (
    <SideMenuGroup
      isFixedGroup
      secondary
      indent={false}
      mt='-4px'
      py={0}
      rightAction={
        <IconButton
          size={28}
          title='Очистить'
          onClick={() => {
            setValue(INITIAL_VALUE.type);
          }}
        >
          <Delete1pxIcon size={12} />
        </IconButton>
      }
      title='Тип записи'
    >
      <TagsWrapper>
        {Object.keys(ARTICLES_TYPES_NAME).map(item => (
          <ToggleButton.Tab
            key={item}
            secondary
            checked={type.includes(item)}
            justifyContent='center'
            label={ARTICLES_TYPES_NAME[item]}
            name='type'
            value={item}
            onClick={handleChange}
          />
        ))}
      </TagsWrapper>
    </SideMenuGroup>
  );
};

const ArticleDateRangeFilter: FC<{ name: string; title: string }> = memo(
  ({ name, title }) => {
    const { value, setValue } = useField({ name });
    const [
      { nextLeftDisabled, prevRightDisabled },
      setDateDisabledFromDataPicker,
    ] = useState<{
      nextLeftDisabled: undefined | Date;
      prevRightDisabled: undefined | Date;
    }>({ nextLeftDisabled: undefined, prevRightDisabled: undefined });

    const handleChange = (fieldName: string) => (date: Date | '') => {
      if (fieldName === 'after') {
        setDateDisabledFromDataPicker(prev => ({
          ...prev,
          prevRightDisabled: date || undefined,
        }));
      } else {
        setDateDisabledFromDataPicker(prev => ({
          ...prev,
          nextLeftDisabled: date || undefined,
        }));
      }

      const formatNewDate = date ? formatDate(date, false, 'yyyy-MM-dd') : '';
      setValue(prev => ({ ...prev, [fieldName]: formatNewDate }));
    };

    return (
      <SideMenuGroup
        isFixedGroup
        indent={false}
        mt={2}
        py={0}
        rightAction={
          <IconButton
            size={28}
            title='Очистить'
            onClick={() => {
              setValue(INITIAL_VALUE.publishedAt);
            }}
          >
            <Delete1pxIcon size={12} />
          </IconButton>
        }
        title={title}
      >
        <DateInputRange
          canClear
          leftValue={value?.after}
          nextLeftDisabled={nextLeftDisabled}
          prevRightDisabled={prevRightDisabled}
          rightValue={value?.before}
          onChangeLeft={handleChange('after')}
          onChangeRight={handleChange('before')}
        />
      </SideMenuGroup>
    );
  },
);

const CompanyFilters: FC<{ name: string; title: string }> = memo(
  ({ name, title }) => {
    const {
      value,
      form: { setFieldValue },
    } = useField({ name });
    const [{ partnersOnly }] = useQueryParams();
    const isAuthed = useAppSelector(hasIdentity);

    const [isCompanyFetched, setCompanyFetched] = useState(false);

    const { isLoading }: { isLoading: boolean } = useRequest(
      !isCompanyFetched &&
        value?.every(id => typeof id === 'string') &&
        findCompaniesByIds,
      [{ minify: true, ids: value }],
      {
        onSuccess: (response: { items: Record<string, ICompany> }) => {
          setCompanyFetched(true);
          setFieldValue('reformCompanyId', Object.values(response.items));
        },
      },
    );

    return (
      <Spinner delay={400} loading={isLoading} mt='10px'>
        <SideMenuGroup
          isFixedGroup
          secondary
          indent={false}
          mt={2}
          py={0}
          rightAction={
            <IconButton
              size={28}
              title='Очистить'
              onClick={() => {
                setFieldValue('reformCompanyId', INITIAL_VALUE.reformCompanyId);
                setFieldValue('partnersOnly', INITIAL_VALUE.partnersOnly);
              }}
            >
              <Delete1pxIcon size={12} />
            </IconButton>
          }
          title={title}
        >
          <Tooltip arrow placement='top' title={!isAuthed && TitleTooltip}>
            <div>
              <Field
                checked={partnersOnly}
                component={CheckboxField}
                disabled={!isAuthed}
                label='Только партнеры'
                mb={2}
                mt={1}
                name='partnersOnly'
              />
            </div>
          </Tooltip>
          <Field
            component={MultiSelectField}
            disablePortal={false}
            itemToKey={itemTo('id')}
            itemToString={itemTo('name')}
            name='reformCompanyId'
            options={findCompanyOptions}
            placeholder='Введите название компании'
          />
        </SideMenuGroup>
      </Spinner>
    );
  },
);

const ArticlesFilters: FC = () => {
  const reformRef = useRef<IReform<IArticlesFormProps>>(null);
  const [isOpen, toggleOpen] = useToggle(false);
  const history = useHistory();
  const isAuthed = useAppSelector(hasIdentity);

  const [
    {
      type = [],
      tag = [],
      companyId = [],
      partnersOnly,
      publishedAtAfter,
      publishedAtBefore,
    },
    querySet,
  ] = useQueryParams();

  const formValues = {
    type,
    tag,
    companyId,
    partnersOnly,
    reformPublishedAt: { after: publishedAtAfter, before: publishedAtBefore },
  };

  const countOfFilters = getCountOfFilters(false, {
    type,
    tag,
    companyId,
    partnersOnly,
    publishedAtAfter,
    publishedAtBefore,
  });

  const handleSubmit = useCallback(() => {
    const { reformCompanyId, reformPublishedAt, ...other } =
      reformRef.current?.getData() ?? ({} as IArticlesFormProps);
    const transformedCompanyId = reformCompanyId?.map(item => item?.id);

    const queryProps = {
      ...other,
      publishedAtAfter: reformPublishedAt?.after,
      publishedAtBefore: reformPublishedAt?.before,
      companyId: transformedCompanyId,
      page: 1,
    };

    const querySetString = querySet(queryProps);

    history.replace(querySetString);
    toggleOpen();
  }, [history, querySet, toggleOpen]);

  const handleReset = useCallback(() => {
    const querySetString = querySet(queryProps => ({
      ...queryProps,
      type: undefined,
      tag: undefined,
      companyId: undefined,
      partnersOnly: undefined,
      publishedAtAfter: undefined,
      publishedAtBefore: undefined,
      page: 1,
    }));

    reformRef.current?.setData(INITIAL_VALUE);
    history.replace(querySetString);
  }, [history, querySet, reformRef]);

  // сбрасывает значение partnerOnly на дефолтное пользователю без логина
  useEffect(() => {
    if (!isAuthed && partnersOnly) {
      reformRef.current?.setFieldValue(
        'partnersOnly',
        INITIAL_VALUE.partnersOnly,
      );
      const querySetString = querySet(queryProps => ({
        ...queryProps,
        partnersOnly: undefined,
        page: 1,
      }));
      history.replace(querySetString);
    }
  }, [isAuthed, history, partnersOnly, querySet, handleReset]);

  return (
    <SideFilter
      countOfFilters={countOfFilters}
      isOpen={isOpen}
      width='210px'
      onReset={handleReset}
      onToggle={toggleOpen}
    >
      <Reform
        ref={reformRef}
        initialValues={{ ...INITIAL_VALUE, ...formValues }}
      >
        <SideFilterWrapper>
          <TypeTags />
          <CompanyFilters name='companyId' title='Компания' />
          <ArticleDateRangeFilter
            name='reformPublishedAt'
            title='Дата публикации'
          />
          <SideMenuGroup
            isFixedGroup
            indent={false}
            mt={2}
            py={0}
            rightAction={
              <IconButton
                size={28}
                title='Очистить'
                onClick={() => {
                  reformRef.current?.setFieldValue('tag', INITIAL_VALUE.tag);
                }}
              >
                <Delete1pxIcon size={12} />
              </IconButton>
            }
            title='Теги'
          >
            <Field
              component={MultiStringField}
              name='tag'
              placeholder='Введите тег'
            />
          </SideMenuGroup>
        </SideFilterWrapper>
        <SideFilterButtons
          isDirty
          onReset={handleReset}
          onSubmit={handleSubmit}
        />
      </Reform>
    </SideFilter>
  );
};

export { ArticlesFilters };
