import { ReactElement, useEffect } from 'react';

import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';
import { border } from 'styled-system';

import { CloseMediumIcon } from 'resources/icons/18';
import { Button } from 'UI';
import { IconButtonWrapper } from 'UI/IconButton/IconButtonWrapper';
import { Status } from 'UI/Status';
import { Text } from 'UI/Text';

import { BUTTONS_ACTION, NOTIFY_TIMINGS, STATUS_NOTIFY } from '../constants';
import { removeNotifyWithAnimation } from '../store/actions';

const show = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const hide = keyframes`
  from {
    opacity: 1;
  }
  to {
    display: none;
    opacity: 0;
  }
`;

const StyledIconButton = styled(IconButtonWrapper)`
  visibility: hidden;
`;

const Container = styled.div<any>(
  ({
    theme: { space, colors, borderRadius, shadows },
    hiding,
    bottomIndent,
  }) => css`
    position: absolute;
    bottom: ${bottomIndent}px;
    display: flex;
    align-items: center;
    height: ${space[5]}px;
    max-width: 1200px;
    border-radius: ${borderRadius}px;
    background: ${colors.text.secondary};
    box-shadow: ${shadows[1]};
    transition: bottom ${NOTIFY_TIMINGS.DOWN_ANIMATION}ms ease-in;
    animation: ${show} ${NOTIFY_TIMINGS.SHOW_ANIMATION}ms ease-in;
    border-left: ${space[1]}px solid;

    &:hover > ${StyledIconButton} {
      visibility: visible;
    }

    ${hiding &&
    css`
      animation: ${hide} ${NOTIFY_TIMINGS.HIDE_ANIMATION}ms ease-out forwards;
    `}

    ${border}
  `,
);

const StyledText = styled(Text)(
  ({ theme: { space, colors } }) => css`
    margin-left: ${space[2]}px;
    color: ${colors.white};
    opacity: 0.7;

    a&:hover {
      text-decoration: underline;
      color: ${colors.white};
      opacity: 1;
    }
  `,
);

const StyledButton = styled(Button).attrs(() => ({
  color: 'secondary',
}))(
  ({ theme: { colors } }) => css`
    color: ${colors.white};

    &:hover {
      background: ${colors.white};
      border: 1px solid ${colors.white};
      color: ${colors.text.primary};
      cursor: pointer;
    }
  `,
);

const mapStatusToColor = {
  [STATUS_NOTIFY.SUCCESS]: 'success.main',
  [STATUS_NOTIFY.ERROR]: 'error.main',
  [STATUS_NOTIFY.WARN]: 'warning.main',
  [STATUS_NOTIFY.NORMAL]: 'text.secondary',
};

const renderAction = (buttonProps, onCloseCard): null | ReactElement => {
  if (!buttonProps) return null;
  const { as, title, onClick, ...other } = buttonProps;

  if (as === BUTTONS_ACTION.LINK) {
    return (
      <StyledText as={Link} onClick={onCloseCard} {...other}>
        {title}
      </StyledText>
    );
  }

  if (as === BUTTONS_ACTION.BUTTON) {
    return (
      <StyledButton
        data-gtm-type={
          title === 'Увеличить лимит' ? 'limitproductinpointofsale' : undefined
        }
        ml={2}
        size='s'
        variant='outlined'
        onClick={() => {
          onClick();
          onCloseCard();
        }}
        {...other}
      >
        {title}
      </StyledButton>
    );
  }

  return null;
};

function NotifyCard({ notify, bottomIndent }): ReactElement {
  const dispatch = useDispatch();
  const {
    status,
    message,
    hiding,
    buttonProps: { onDestroy = () => {}, ...otherButtonProps },
  } = notify;

  const closeCard = (): any => dispatch(removeNotifyWithAnimation(notify));

  useEffect(() => {
    window.addEventListener('beforeunload', onDestroy);

    return () => {
      window.removeEventListener('beforeunload', onDestroy);
      onDestroy();
    };
  }, [onDestroy]);

  return (
    <Container
      borderColor={mapStatusToColor[status]}
      bottomIndent={bottomIndent}
      hiding={hiding}
    >
      {status !== STATUS_NOTIFY.NORMAL && <Status ml={2} type={status} />}
      <Text truncate color='white' ml={1}>
        {message}
      </Text>
      {renderAction(otherButtonProps, closeCard)}
      <StyledIconButton
        ml={2}
        mr='6px'
        size={34}
        variant='white'
        onClick={closeCard}
      >
        <CloseMediumIcon />
      </StyledIconButton>
    </Container>
  );
}

export { NotifyCard };
