import { forwardRef } from 'react';

import styled, { css } from 'styled-components';
import { border, color, space } from 'styled-system';

import { SellerIcon } from 'resources/icons/18';
import { RecommendationSmallIcon } from 'resources/icons/12';
import { BlockIcon, DoneIcon, ErrorIcon, InactiveIcon, LinkIcon, SubtractIcon, WarnIcon } from 'resources/status';

import { Tooltip } from '../Tooltip';

const Container = styled.div<{ size: number }>(
  ({ theme: { colors }, size }) => css`
    min-width: 18px;
    width: 18px;
    height: 18px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    border: 2px solid ${colors.divider};
    background: transparent;

    ${size &&
    css`
      width: ${size}px;
      min-width: ${size}px;
      height: ${size}px;
    `}

    ${space};
    ${color};
    ${border};
  `,
);

const enum StatusTypes {
  SUCCESS_BLANK = 'success-blank',
  SUCCESS_DISABLED = 'success-disabled',
  SUCCESS_STATUS = 'success-status',
  SUCCESS_STATUS_SELECTED = 'success-status-selected',
  SUCCESS = 'success',
  WARN = 'warn',
  ERROR = 'error',
  ERROR_BLOCK = 'error-block',
  ERROR_CANCEL = 'error-cancel',
  INACTIVE = 'inactive',
  LINK = 'link',
  READY_TO_ATTACHED = 'ready-to-attached',
  RETAILER = 'retailer',
  GENERATED_BY_SYSTEM = 'generatedBySystem',
  SUCCESS_APPROVED = 'successApproved',
}

/**
 * @deprecated
 * Оставлен для совместимости js файлов
 */
const STATUS_TYPES = {
  SUCCESS_BLANK: 'success-blank',
  SUCCESS_DISABLED: 'success-disabled',
  SUCCESS_STATUS: 'success-status',
  SUCCESS_STATUS_SELECTED: 'success-status-selected',
  SUCCESS: 'success',
  WARN: 'warn',
  ERROR: 'error',
  ERROR_BLOCK: 'error-block',
  ERROR_CANCEL: 'error-cancel',
  INACTIVE: 'inactive',
  LINK: 'link',
  READY_TO_ATTACHED: 'ready-to-attached',
  RETAILER: 'retailer',
  GENERATED_BY_SYSTEM: 'generatedBySystem',
} as const;

const statusToType = (type: StatusTypes): any => {
  switch (type) {
    case StatusTypes.SUCCESS_BLANK:
      return {
        icon: <DoneIcon color='divider' />,
      };
    case StatusTypes.SUCCESS_DISABLED:
      return {
        icon: <DoneIcon color='white' />,
        background: 'text.disabled',
      };
    case StatusTypes.SUCCESS_STATUS:
      return {
        icon: <DoneIcon color='white' />,
        background: 'primary.main',
      };
    case StatusTypes.SUCCESS_STATUS_SELECTED:
      return {
        icon: <DoneIcon color='primary.main' />,
        background: 'highlight.1',
        borderColor: '#e9f2ff',
      };
    case StatusTypes.SUCCESS:
      return {
        icon: <DoneIcon color='white' />,
        background: 'success.main',
      };
    case StatusTypes.WARN:
      return {
        icon: <WarnIcon />,
        background: 'warning.main',
      };
    case StatusTypes.ERROR:
      return {
        icon: <WarnIcon />,
        background: 'error.main',
      };
    case StatusTypes.ERROR_CANCEL:
      return {
        icon: <ErrorIcon />,
        background: 'error.main',
      };
    case StatusTypes.ERROR_BLOCK:
      return {
        icon: <BlockIcon />,
        background: 'error.main',
      };
    case StatusTypes.INACTIVE:
      return {
        icon: <InactiveIcon />,
        background: 'text.disabled',
      };
    case StatusTypes.LINK:
      return {
        icon: <LinkIcon color='white' />,
        background: 'success.main',
      };
    case StatusTypes.READY_TO_ATTACHED:
      return {
        icon: <LinkIcon color='success.main' />,
        background: 'success.light',
      };
    case StatusTypes.RETAILER:
      return {
        icon: <SellerIcon color='white' />,
        background: 'primary.main',
      };
    case StatusTypes.GENERATED_BY_SYSTEM:
      return {
        icon: <RecommendationSmallIcon color='white' />,
        background: 'primary.main',
        borderRadius: '6px',
      };
    case StatusTypes.SUCCESS_APPROVED:
      return {
        icon: <SubtractIcon />,
        background: 'transparent',
      };
    default:
      return {};
  }
};

const Status = forwardRef<HTMLDivElement, any>(
  ({ style, className, type = '', title, size, ...other }, ref) => {
    const {
      icon,
      background,
      borderColor = background,
      borderRadius = '50%',
    } = statusToType(type);
    return (
      <Tooltip arrow hint maxWidth={240} title={title}>
        <Container
          ref={ref}
          backgroundColor={background}
          borderColor={borderColor}
          borderRadius={borderRadius}
          className={className}
          size={size}
          style={style}
          {...other}
        >
          {icon}
        </Container>
      </Tooltip>
    );
  },
);

const StatusExtension = Object.assign(Status, { TYPES: STATUS_TYPES });

export { StatusExtension as Status, STATUS_TYPES, StatusTypes };
