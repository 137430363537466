import { FC, memo } from 'react';

import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import {
  IArbitraryValue,
  IRegulatoryTableValue,
  IRegulatoryTableValueTyped,
} from 'common/types/product.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { getProductPropertiesPrimitive } from 'common/utils/properties';
import { SystemSmallIcon } from 'resources/icons/12';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { Container, DescriptionList, Tags, Text, Tooltip } from 'UI';

const STYLE_CONTAINER = { alignItems: 'center' };

const TIP_PAGE_TITLE =
  'Производитель может произвести характеристику с одним из указанных заказчиком значений. Чтобы выбрать значение характеристики, перейдите в режим заполнения опросного листа';

const TIP_CREATE_TITLE =
  'Производитель может произвести характеристику с одним из указанных производителем значений';

// враппер для компонента с дивайдером на всю высоту слева, нужен для сохранения отступов по основной линии
const DividerProperty = styled(DescriptionList.Property)<{
  isWithDivider?: boolean;
}>(
  ({ isWithDivider }) => css`
    display: flex;
    flex-grow: 1;
    min-width: 1px;

    ${isWithDivider &&
    css`
      dt {
        flex-basis: calc(50% - 18px);
      }
    `}
  `,
);

type IArbitraryListPropertyProps = Pick<
  IArbitraryValue<unknown>,
  'allowCustomValues' | 'clarification'
> & {
  isWithDivider?: boolean;
  name?: string;
  values?: IRegulatoryTableValue[];
  bg?: string;
  labelMaxWidth?: number;
  itemToString: (
    value: IRegulatoryTableValueTyped,
  ) => ReturnType<typeof getProductPropertiesPrimitive>;
  isCreation?: boolean;
};

/**
 * Внешний вид произвольной нормативной ссылки для DescriptionList.Property
 */
const ArbitraryListProperty: FC<IArbitraryListPropertyProps> = memo(
  ({
    isWithDivider,
    name,
    allowCustomValues,
    clarification,
    values,
    bg,
    labelMaxWidth = 150,
    itemToString,
    isCreation,
  }) => {
    const availableValues = values
      ? ([...values] as IRegulatoryTableValueTyped[])
      : [];

    if (allowCustomValues) {
      availableValues.push({
        id: uuid(),
        type: PropertiesTypes.string,
        value: 'Выбор из справочника',
      });
    }

    return (
      <DividerProperty
        dotted
        inline
        backgroundColor={bg}
        isWithDivider={isWithDivider}
        label={
          <Container alignItems='center' display='inline-flex'>
            <Tooltip
              arrow
              hint
              maxWidth={208}
              title={isCreation ? TIP_CREATE_TITLE : TIP_PAGE_TITLE}
            >
              <SystemSmallIcon color='primary.main' mr={0} />
            </Tooltip>
            <Text truncate maxWidth={labelMaxWidth}>
              {name ?? 'без названия'}
            </Text>
            {clarification && (
              <Tooltip maxWidth='350px' placement='top' title={clarification}>
                <Help1pxIcon ml={0} size={18} />
              </Tooltip>
            )}
          </Container>
        }
        style={STYLE_CONTAINER}
      >
        <Tags arrow itemToString={itemToString} tags={availableValues} />
      </DividerProperty>
    );
  },
);

export { ArbitraryListProperty, DividerProperty };
