import { memo, FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { Button, Tooltip } from 'UI';

const Wrapper = styled.footer<{ isAbsolute?: boolean }>(
  ({ theme: { colors }, isAbsolute }) => css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    width: 100%;
    margin-top: auto;
    border-top: 1px solid ${colors.divider};

    ${isAbsolute &&
    css`
      position: absolute;
      bottom: 0;
      left: 0;
      background: ${colors.white};
    `}
  `,
);

const BUTTON_STYLE = { borderRadius: 0 };

interface IFooter {
  isDisabled?: boolean;
  isAbsolute?: boolean;
  leftActions?: ReactNode;
  removeTooltipText?: string;
  pushTooltipText?: string;
  deleteAllText?: string;
  addAllText?: string;
  onRemoveAll: VoidFunction;
  onPushAll: VoidFunction;
}

const Footer: FC<IFooter> = memo(
  ({
    isDisabled,
    isAbsolute,
    leftActions,
    removeTooltipText,
    pushTooltipText,
    deleteAllText = 'Убрать все',
    addAllText = 'Добавить все',
    onRemoveAll,
    onPushAll,
  }) => {
    return (
      <Wrapper isAbsolute={isAbsolute}>
        {leftActions}
        <Tooltip pointer placement='top' title={removeTooltipText}>
          <Button
            color='secondary'
            disabled={isDisabled}
            fontSize={4}
            fontWeight={600}
            ml='auto'
            px={3}
            size='l'
            variant='string'
            onClick={onRemoveAll}
          >
            {deleteAllText}
          </Button>
        </Tooltip>
        <Tooltip pointer placement='top' title={pushTooltipText}>
          <Button
            disabled={isDisabled}
            size='l'
            style={BUTTON_STYLE}
            variant='filled'
            onClick={onPushAll}
          >
            {addAllText}
          </Button>
        </Tooltip>
      </Wrapper>
    );
  },
);

export { Footer };
