import { FC, Fragment, MouseEvent, useCallback, useRef, useState } from 'react';

import Downshift from 'downshift';
import styled from 'styled-components';

import { PRODUCT_CATEGORY_TEXT } from 'common/constants/products.const';
import {
  LIFE_STATE_COLORS,
  LIFE_STATE_NAMES,
  PRODUCT_ENTITY_TO_TEXT,
} from 'common/constants/products.const/products.const';
import {
  IPropertyArbitraryLinkedEntity,
  ProductEntity,
} from 'common/types/product.types';
import { getItemData } from 'common/utils/products/getItemData';
import { propertyValidation } from 'common/utils/properties';
import {
  ChildrenUnionWrapper,
  EditTemplates,
} from 'components/characteristic-elements/EditMode';
import { LINKED_PRODUCT_BY_TYPE } from 'components/products/properties/components';
import { MainProperties } from 'components/products/properties/page';
import { IReformField } from 'reform';
import { SystemSmallIcon } from 'resources/icons/12';
import { CloseMediumIcon } from 'resources/icons/18';
import { StandardIcon } from 'resources/other-16';
import { AddTagIcon } from 'resources/other-28';
import { removeItem } from 'tools/utils';
import {
  Avatar,
  Badge,
  ButtonRounded,
  Container,
  CustomTextArea,
  IconButton,
  IconButtonWrapper,
  Text,
} from 'UI';
import {
  getFilteredItems,
  ItemToStringAndOptionsType,
  Options,
  useAsyncOptions,
} from 'UI/Inputs';
import {
  Container as CommonContainer,
  Input,
  SelectedItem,
} from 'UI/Multi/_shared/styled';

import { PropertiesEdit } from '../../_shared/Edit';
import { AddCommentButton, Limit } from '../../_shared/styled';
import { PropertiesView } from '../../_shared/View';
import { IPropertyRecursionProps } from '../../types';
import { useProperty } from '../../useProperty';

const TAG_LIMIT = 30;
const CUSTOM_VALUE = 'Выбор из справочника';
const CUSTOM_VALUE_PRODUCT = 'Выбор из доступных товаров';
const CONTAINER_STYLE = { borderRadius: '0px' };

const SelectItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  margin-top: 6px;
  line-height: 1.2;
`;

export const linkedEntityToString: ItemToStringAndOptionsType = (
  item: any,
  { isOption }: { isOption?: boolean } = {},
): JSX.Element | string | undefined => {
  if (!item) return undefined;

  const value = item?.id?.id ? item.id : item;

  const { itemName, previewImage } = getItemData({
    item: value,
    type: value['@type'],
  });

  const isProduct =
    value['@type'] === 'Product' || value['@type'] === 'LinkedProduct';

  if (isOption) {
    return (
      <SelectItem>
        <Container alignItems='center' width='100%'>
          <Avatar mr={2} size='xs' url={previewImage} />
          <Container column maxWidth='40%'>
            <Text truncate mb='2px' mr={1}>
              {itemName || 'Не указано'}
            </Text>
            <Text color='text.secondary' fontSize={2}>
              {isProduct
                ? PRODUCT_CATEGORY_TEXT[value.category]
                : PRODUCT_ENTITY_TO_TEXT[ProductEntity.standard]}
            </Text>
          </Container>
          <Container ml='auto'>
            {isProduct ? (
              <Fragment>
                <Badge
                  dot={LIFE_STATE_COLORS[value.lifeState]}
                  label={LIFE_STATE_NAMES[value.lifeState]}
                  mr={1}
                  style={{ background: 'transparent', lineHeight: '12px' }}
                  type={Badge.TYPES.DEFAULT}
                />
                <MainProperties
                  hasCopyUin={false}
                  product={{ ...value, mainProperties: value.properties }}
                />
              </Fragment>
            ) : (
              <Fragment>
                <StandardIcon mr={1} />
                <MainProperties
                  hasCopyUin={false}
                  product={{ ...value, mainProperties: [] }}
                />
              </Fragment>
            )}
          </Container>
        </Container>
      </SelectItem>
    );
  }

  return itemName || 'Не указано';
};

const stateReducer = (_, changes): any => {
  switch (changes.type) {
    case Downshift.stateChangeTypes.changeInput:
    case Downshift.stateChangeTypes.itemMouseEnter:
      return changes;
    default:
      return { ...changes, inputValue: '' };
  }
};

const itemToKey = ({ id }: { id: string }): string => id;

// TODO: add types
interface ILinkedProductEditProps {
  name: string;
  property: any;
  error: any;
  selectedItems: any;
  onChange: (name: string) => any;
}

const LinkedProductEdit: FC<ILinkedProductEditProps> = ({
  name,
  property,
  error,
  selectedItems,
  onChange,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const { value } = property;
  const { allowCustomValues } = value;
  const [asyncState, dispatchOptions] = useAsyncOptions();

  const productType = value?.options?.entity;

  const handleChange = (selectedItem): void => {
    onChange(`${name}.value.options.references`)(
      [...selectedItems, selectedItem].filter(Boolean),
    );
  };

  const handleRemove = (selectedItem, index): void => {
    onChange(`${name}.value.options.references`)(
      removeItem(selectedItem, index),
    );
  };

  const isLimitReached = selectedItems.length === TAG_LIMIT;

  const filteredValue = getFilteredItems({
    isFilteredByInputValue: false,
    items: asyncState.items,
    selectedItems,
    itemToKey,
  });

  if (!productType) {
    return (
      <EditTemplates.PlaceholderContainer data-border-bottom data-border-right>
        {Object.keys(LINKED_PRODUCT_BY_TYPE).map(type => (
          <ButtonRounded
            key={type}
            mr={0}
            size='s'
            variant='outlined'
            onClick={() => {
              onChange(`${name}.value.options.entity`)(type);
            }}
          >
            + {LINKED_PRODUCT_BY_TYPE[type].text}
          </ButtonRounded>
        ))}
      </EditTemplates.PlaceholderContainer>
    );
  }

  const linkedObjectType = LINKED_PRODUCT_BY_TYPE[productType];
  const isAssortmentOrStandard =
    productType === 'standard' || productType === 'assortment';

  return (
    <Fragment>
      {/* todo refactor to useCombobox */}
      <Downshift stateReducer={stateReducer} onChange={handleChange}>
        {downshift => {
          const { inputValue, clearSelection, getRootProps, getInputProps } =
            downshift;
          return (
            <CommonContainer
              {...getRootProps()}
              ref={containerRef}
              error={error?.options?.references}
              style={CONTAINER_STYLE}
            >
              <Container flexWrap='wrap' px={2} width='100%'>
                {allowCustomValues && (
                  <SelectedItem>
                    <SystemSmallIcon color='text.disabled' mr={0} />
                    <Text color='text.disabled'>
                      {isAssortmentOrStandard
                        ? CUSTOM_VALUE
                        : CUSTOM_VALUE_PRODUCT}
                    </Text>
                    <IconButtonWrapper
                      ml={0}
                      onClick={() => {
                        onChange(`${name}.value.allowCustomValues`)(false);
                      }}
                    >
                      <CloseMediumIcon size={12} />
                    </IconButtonWrapper>
                  </SelectedItem>
                )}
                {selectedItems.map((item, index) => {
                  return (
                    <SelectedItem key={item?.id}>
                      <Text truncate maxWidth={200}>
                        {linkedEntityToString(item)}
                      </Text>
                      <IconButtonWrapper
                        ml={0}
                        onClick={() => handleRemove(selectedItems, index)}
                      >
                        <CloseMediumIcon size={12} />
                      </IconButtonWrapper>
                    </SelectedItem>
                  );
                })}
                {!isLimitReached && (
                  // @ts-ignore
                  <Input
                    {...getInputProps({
                      placeholder: 'Добавить варианты',
                      onBlur: () => {
                        clearSelection();
                      },
                      onKeyDown: event => {
                        if (inputValue === '' && event.key === 'Backspace') {
                          handleRemove(value, value.length - 1);
                        }
                      },
                    })}
                  />
                )}
              </Container>
              <Container mt='auto'>
                <Limit mt='7px'>{`${
                  selectedItems?.length ?? 0
                }/${TAG_LIMIT}`}</Limit>
                <Fragment>
                  <IconButton
                    frame
                    disabled={allowCustomValues}
                    m='2px'
                    size={28}
                    title={`Добавить выбор из ${
                      isAssortmentOrStandard
                        ? 'справочника'
                        : 'доступных товаров'
                    }`}
                    onClick={() => {
                      onChange(`${name}.value.allowCustomValues`)(true);
                    }}
                  >
                    <AddTagIcon />
                  </IconButton>
                </Fragment>
              </Container>
              <Options
                anchorEl={containerRef.current}
                disablePortal={false}
                dispatch={dispatchOptions}
                // @ts-ignore
                downshift={downshift}
                filteredItems={filteredValue}
                heightItem={52}
                itemToKey={itemToKey}
                itemToString={linkedEntityToString}
                options={linkedObjectType.options}
                state={asyncState}
              />
            </CommonContainer>
          );
        }}
      </Downshift>
    </Fragment>
  );
};

interface IProductsCreatePropertiesLinkedProductProps {
  index: number;
  level: number;
  name: string;
  field: IReformField;
  recursionProps: IPropertyRecursionProps;
  onCopy: (e: MouseEvent<HTMLDivElement>) => void;
  onRemove: VoidFunction;
  onChange: (name: string) => any;
  onToggleConfirmed: (event?: MouseEvent<HTMLDivElement>) => void;
}

const ProductsCreatePropertiesArbitraryLinkedEntity: FC<
  IProductsCreatePropertiesLinkedProductProps
> = ({
  index,
  level,
  name,
  field,
  recursionProps,
  onCopy,
  onRemove,
  onChange,
  onToggleConfirmed,
}) => {
  const textAreaRef = useRef();

  const { value: property, error, ref } = field;

  const {
    value,
    type,
    _confirmed: confirmed,
    _readOnly: readOnly,
  }: IPropertyArbitraryLinkedEntity = property;

  const selectedItems: any[] = value.options?.references;

  const handleRemove = (): void => {
    onRemove();
  };

  const {
    isSelectMode,
    isMainProperty,
    isDragging,
    isOpenContextMenu,
    leftActions,
    rightActions,
    mainPropertyAction,
  } = useProperty({
    index,
    name,
    field,
    recursionProps,
    onRemove: handleRemove,
    onCopy,
    onEdit: onToggleConfirmed,
  });

  const isValid = propertyValidation(property);
  const valueError: object = error?.value ?? {};

  const handleTypeChange = useCallback(
    (currentType: string, initialValues: IPropertyArbitraryLinkedEntity) => {
      onChange(name)({
        ...initialValues,
        id: property.id,
        name: property.name,
      });
    },
    [name, onChange, property.id, property.name],
  );

  const [clarificationMode, setClarificationMode] = useState(
    !!property?.value.clarification,
  );

  if ((confirmed || readOnly) && !error) {
    return (
      <PropertiesView
        disabled={isDragging}
        fieldRef={ref}
        level={level}
        mainProperty={isMainProperty}
        mainPropertyAction={mainPropertyAction}
        property={property}
        visibleMenuControl={isOpenContextMenu}
        {...(!(readOnly || isSelectMode) && {
          leftActions,
          rightActions,
          onToggleConfirmed,
        })}
      />
    );
  }

  return (
    <Fragment>
      <PropertiesEdit
        field={field}
        isValid={isValid}
        level={level}
        name={name}
        onChangeName={onChange}
        onChangeType={handleTypeChange}
        onRemove={handleRemove}
        onSubmit={() => {
          onToggleConfirmed();
        }}
      >
        <Container flexDirection='column' width='100%'>
          <LinkedProductEdit
            error={valueError}
            name={name}
            property={property}
            selectedItems={selectedItems}
            onChange={onChange}
          />
          {clarificationMode ? (
            <ChildrenUnionWrapper>
              <CustomTextArea
                data-border-bottom
                data-border-right
                inputRef={textAreaRef}
                limit={150}
                name={`${name}.value.clarification`}
                placeholder='Введите пояснение для отображения в виде подсказки у заголовка характеристики'
                rightAddon={
                  <IconButton
                    frame
                    mr='2px'
                    size={28}
                    title='Удалить пояснение к заполнению'
                    onClick={() => {
                      setClarificationMode(false);
                      onChange(`${name}.value.clarification`)('');
                    }}
                  >
                    <CloseMediumIcon />
                  </IconButton>
                }
                value={value.clarification}
                onChange={onChange}
              />
            </ChildrenUnionWrapper>
          ) : (
            <EditTemplates.PlaceholderContainer
              style={{ border: '1px solid #DFE4EB' }}
            >
              <AddCommentButton
                key={type}
                mr={0}
                type='button'
                onClick={() => {
                  setClarificationMode(true);
                }}
              >
                + Добавить пояснение к заполнению
              </AddCommentButton>
            </EditTemplates.PlaceholderContainer>
          )}
        </Container>
      </PropertiesEdit>
    </Fragment>
  );
};

export { ProductsCreatePropertiesArbitraryLinkedEntity };
