import { FC } from 'react';

import { IMedia } from 'common/types/product.types';
import { SectionTitle } from 'components/SectionTitle';
import { InfoBlock, ExpandableSection } from 'UI';

import { VideoItem, VideosWrapper } from './components';

const DEFAULT_VIDEOS_COUNT = 10;

interface IVideosPageProps {
  id: string;
  title: string;
  videos?: IMedia['videos'];
}

const VideosPage: FC<IVideosPageProps> = ({ id, title, videos }) => {
  if (!videos?.items || videos.items.length === 0) return null;

  return (
    <InfoBlock
      id={id}
      title={<SectionTitle isPrivate={videos.isPrivate} text={title} />}
    >
      <ExpandableSection
        expandable={videos && videos.items.length > DEFAULT_VIDEOS_COUNT}
        maxHeight={400}
      >
        <VideosWrapper>
          {videos.items.map(video => (
            <VideoItem key={video.id} video={video} />
          ))}
        </VideosWrapper>
      </ExpandableSection>
    </InfoBlock>
  );
};

export { VideosPage };
