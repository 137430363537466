import { FC, useMemo } from 'react';

import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { IProduct, IProperty } from 'common/types/product.types';
import { PropertiesTypes } from 'common/types/properties.types';
import { getProductPagePath } from 'common/utils/products/pagePath';
import { getPropertyLabel } from 'common/utils/properties';
import { FolderBoldIcon, OutsideIcon } from 'resources/icons/12';
import { MainPropertiesIcon } from 'resources/other';
import { IconButtonWrapper, Text } from 'UI';

import { ColorProperty } from './ColorProperty';
import { ProductPropertyLink } from './ProductPropertyLink';
import { UinProperty } from './UinProperty';
import { getRegulatoryReferenceValueText, renderProperty } from '../utils';

const BackgroundContainer = styled.div(
  ({ theme: { colors } }) => css`
    & > div:nth-child(odd) {
      background-color: ${colors.background};
    }
  `,
);

const PropertyWrapper = styled.div<{ level?: number }>(
  ({ theme: { colors }, level = 0 }) => css`
    display: flex;
    align-items: center;
    min-height: 38px;
    padding-left: ${level * 16}px;
    color: ${colors.text.secondary};
  `,
);

const PropertyHeader = styled.div<{ disabled: boolean }>(
  ({ theme: { space, colors, fontSizes }, disabled }) => css`
    display: flex;
    align-items: center;
    padding-top: ${space[1]}px;
    padding-left: ${space[2]}px;
    padding-right: ${space[1]}px;
    color: ${colors.text.secondary};
    font-weight: 500;
    font-size: ${fontSizes[1]}px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    ${disabled &&
    css`
      color: ${colors.text.disabled};

      & svg {
        color: ${colors.text.disabled};
      }
    `}
  `,
);

const PropertyCell = styled.div(
  ({ theme: { space, fontSizes } }) => css`
    width: 250px;
    padding: ${space[0]}px ${space[2]}px;
    font-size: ${fontSizes[2]}px;
    overflow: hidden;
  `,
);

const PropertyName = styled(PropertyCell)`
  font-weight: 600;
`;

const PropertyValue = styled(PropertyCell)(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: right;
    flex-shrink: 0;
    color: ${colors.text.primary};
  `,
);

interface ISimpleProperty {
  id: string;
  type: PropertiesTypes;
  name: string;
  value: any;
}

interface IFlattenProps {
  properties: ISimpleProperty[];
  parentGroup?: null | string;
  level?: number;
}

interface IFlattenProperty extends ISimpleProperty {
  parentGroup: string | null;
  level: number;
}

/**
 * Recursive function to flatten properties tree
 *
 * @param {Array} properties - tree structure of main properties
 * @param {string | null} parentGroup - name of the parent group
 * @param {number} level - level of recursion
 * @returns {Array} flattened properties
 */
function flatten({
  properties,
  parentGroup = null,
  level = 0,
}: IFlattenProps): IFlattenProperty[] {
  return properties.reduce((acc, property, index) => {
    if (property.type === PropertiesTypes.group) {
      return [
        ...acc,
        ...flatten({
          parentGroup: property.name,
          properties: property.value,
          level: level + 1,
        }),
      ];
    }

    if (property.type === PropertiesTypes.regulatoryReference) {
      return [
        ...acc,
        {
          ...property,
          parentGroup: property.name ?? 'Без названия',
          level: level + 1,
        },
      ];
    }

    const isSaveParentName =
      index === 0 || properties[index - 1]?.type === PropertiesTypes.group;

    return [
      ...acc,
      {
        ...property,
        parentGroup: isSaveParentName ? parentGroup : null,
        level,
      },
    ];
  }, []);
}

const MaterialValue = ({
  property: { type, value },
}: {
  property: ISimpleProperty;
}): string | JSX.Element => {
  if (!value) return <Text color='text.disabled'>Не указано</Text>;

  const keyProperties =
    type === PropertiesTypes.standardMaterial ? 'mark' : 'symbol';

  return `${value[keyProperties]} (${value.ntdReference.numericName})`;
};

interface IMainProperties {
  disabled?: boolean;
  hasCopyUin?: boolean;
  textLink?: string;
  product: IProduct;
  placement?: string;
}

const MainProperties: FC<IMainProperties & SpaceProps> = ({
  disabled = false,
  hasCopyUin = true,
  product,
  textLink,
  placement = 'right',
  ...other
}) => {
  const { uin, mainProperties, versionCount = 0 } = product;
  const { t } = useTranslation('measurement-units');
  /**
   * versionCount - кол-во версий товара (versionCount = 1 соответствует самому товару);
   * если > 1, значит, минимум одна версия была создана, тогда отображаем соответствующую надпись
   */
  const isAggregatingProduct = versionCount > 1;

  const flattened = useMemo(() => {
    return disabled ? [] : flatten({ properties: mainProperties });
  }, [disabled, mainProperties]);

  // todo разделить бизнес-логику, т.к. MainProperties должен отвечать только за характеристики, для версий необходим отдельный компонент
  if (isAggregatingProduct) {
    return (
      <IconButtonWrapper
        as='div'
        title={{
          placement,
          p: 2,
          title: (
            <BackgroundContainer>
              <PropertyWrapper>
                <PropertyName>Агрегирующая карточка</PropertyName>
              </PropertyWrapper>
            </BackgroundContainer>
          ),
        }}
      >
        <MainPropertiesIcon />
      </IconButtonWrapper>
    );
  }

  return (
    <IconButtonWrapper
      as='div'
      disabled={disabled}
      title={{
        placement,
        title: (
          <BackgroundContainer>
            <PropertyWrapper>
              <PropertyName>UIN</PropertyName>
              <PropertyValue>
                {uin && hasCopyUin ? (
                  <UinProperty uin={uin} />
                ) : (
                  uin ?? 'Будет присвоен после публикации'
                )}
              </PropertyValue>
            </PropertyWrapper>
            {flattened.map(property => {
              const { parentGroup, level, ...otherProperty } = property;

              return (
                <div key={otherProperty.id}>
                  {typeof parentGroup === 'string' && (
                    <PropertyHeader disabled={!parentGroup}>
                      {otherProperty.type === PropertiesTypes.group && (
                        <FolderBoldIcon mr={0} size={12} />
                      )}
                      <Text truncate>
                        {parentGroup || 'Группа без названия'}
                      </Text>
                    </PropertyHeader>
                  )}
                  <PropertyWrapper level={level >= 1 ? 1 : 0}>
                    <PropertyName>
                      <Text truncate>
                        {getPropertyLabel(property as any, {
                          t,
                          truncate: false,
                        })}
                      </Text>
                    </PropertyName>
                    <PropertyValue>
                      <Text truncate>
                        {renderProperty({
                          property: otherProperty as IProperty,
                          propertyTypeRender: prop => {
                            switch (prop.type) {
                              case PropertiesTypes.product:
                                return (
                                  // @ts-ignore
                                  <ProductPropertyLink
                                    hideLink
                                    property={prop}
                                  />
                                );
                              case PropertiesTypes.multiColor:
                                return <ColorProperty property={prop} />;
                              case PropertiesTypes.standardMaterial:
                              case PropertiesTypes.standardCoating:
                                // @ts-ignore
                                return <MaterialValue property={prop} />;
                              case PropertiesTypes.regulatoryReference:
                                return getRegulatoryReferenceValueText({
                                  value: prop.value,
                                });
                              default:
                                return null;
                            }
                          },
                        }) || <Text color='text.disabled'>Не указано</Text>}
                      </Text>
                    </PropertyValue>
                  </PropertyWrapper>
                </div>
              );
            })}
            {textLink && (
              <PropertyWrapper>
                <OutsideIcon ml='auto' mr={0} />
                <Text
                  as={Link}
                  color='text.secondary'
                  fontSize={2}
                  fontWeight={600}
                  target='_blank'
                  to={getProductPagePath(product)}
                  onClick={event => event.stopPropagation()}
                >
                  {textLink}
                </Text>
              </PropertyWrapper>
            )}
          </BackgroundContainer>
        ),
      }}
      {...other}
    >
      <MainPropertiesIcon />
    </IconButtonWrapper>
  );
};

export { MainProperties };
