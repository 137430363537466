import { ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { TrashIcon } from 'resources/icons/18';
import { Container, FormError, IconButton } from 'UI';

const Wrapper = styled.div(
  ({ theme: { space, colors, borderRadius } }) => css`
    margin-bottom: ${space[4]}px;
    padding: ${space[1]}px ${space[1]}px 0;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;

    ${FormError} {
      display: none;
    }
  `,
);

const Section = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    gap: ${space[1]}px;
  `,
);

const RowCounterPanel = styled.div(
  ({ theme: { space, colors } }) => css`
    flex: 0 0 ${space[3]}px;
    align-self: stretch;
    margin-top: ${space[4]}px;
    border: 1px solid ${colors.text.primary};
  `,
);

const RowCountCell = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    flex: 0 0 ${space[3]}px;
    justify-content: center;
    align-items: center;
    border: 1px solid ${colors.text.primary};
    border-top-width: 0;
  `,
);

const RowCountCellControl = styled(IconButton)(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex: 0 0 ${space[3]}px;
    justify-content: center;
    align-items: center;
    height: auto;
    color: ${colors.text.primary};
    border: 1px solid ${colors.text.primary};
    border-top-width: 0;
    border-radius: 0;

    & .icon {
      position: absolute;
      opacity: 0;
    }

    &:hover {
      color: transparent;

      & .icon {
        opacity: 1;
      }
    }
  `,
);

interface IDynamicTableProp<DataType, ColumnType> {
  data: DataType[];
  columns: ColumnType[];
  renderHeader: (column: ColumnType, columnIndex: number) => ReactNode;
  renderRow: (
    row: DataType,
    rowIndex: number,
    columns: ColumnType[],
  ) => ReactNode;
  onRowRemove: (row: DataType, rowIndex: number) => void;
}

/**
 *
 * @param data массив данных, по которому будет происходить рендер строк с помощью renderRow
 * @param columns массив колонок, по которому будет происходить рендер шапки с помощью renderHeader
 * @param renderHeader функция для рендеринга шапки
 * @param renderRow функция для рендеринга строки
 * @param onRowRemove функция, вызываемая во время клика по кнопке удаления строки
 */
function DynamicTable<DataType, ColumnType>({
  data,
  columns,
  renderHeader,
  renderRow,
  onRowRemove,
}: IDynamicTableProp<DataType, ColumnType>): JSX.Element {
  return (
    <Wrapper>
      <Container column overflowX='auto' pb={3}>
        <Section>
          <RowCounterPanel />
          {columns.map((column, index) => renderHeader(column, index))}
        </Section>
        {data.map((row, index) => {
          return (
            <Section key={index}>
              {data.length > 1 ? (
                <RowCountCellControl
                  size={15}
                  variant='error'
                  onClick={() => onRowRemove(row, index)}
                >
                  <TrashIcon className='icon' />
                  {index + 1}
                </RowCountCellControl>
              ) : (
                <RowCountCell>{index + 1}</RowCountCell>
              )}
              {renderRow(row, index, columns)}
            </Section>
          );
        })}
      </Container>
    </Wrapper>
  );
}

export { DynamicTable };
