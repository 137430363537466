import { FC } from 'react';

import styled, { css } from 'styled-components';

import { Modal } from 'UI';

import { SupportIssuesReform } from './IssuesForm';

const FormWrapper = styled.div(
  ({ theme: { space } }) => css`
    margin: 0 -${space[4]}px;
  `,
);

interface IIssuesModalProps {
  sectionKey: string;
  handleModalClose: VoidFunction;
}

const IssuesModal: FC<IIssuesModalProps> = ({
  sectionKey,
  handleModalClose,
}) => (
  <Modal>
    <Modal.Default hideButtons>
      <FormWrapper>
        <SupportIssuesReform
          sectionKey={sectionKey}
          onSubmit={handleModalClose}
        />
      </FormWrapper>
    </Modal.Default>
  </Modal>
);

export { IssuesModal };
