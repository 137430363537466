import { FC, Fragment, memo, useMemo } from 'react';

import styled, { css } from 'styled-components';

import { ProductFormIcon } from 'resources/other-20';
import { MASTER_PANEL_SCREENS } from 'common/constants/master-panel.const';
import { IPdmProductForm } from 'common/types/pdm';
import { IPublicProductForm } from 'common/types/product-management';
import { Button, Spinner } from 'UI';

import { IFormSelection } from './types';
import { MasterPanelBlock, SelectionItem } from '../../index';
import { useMasterPanelContext } from '../../MasterPanel';
import { MasterPanelProductForm } from '../../types';

const MAX_SAME_LIST_LENGTH = 3;

const Content = styled.div<{ isErrorText?: boolean }>(
  ({ theme: { space, colors, fontSizes }, isErrorText }) => css`
    margin-bottom: ${space[1]}px;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text[isErrorText ? 'secondary' : 'primary']};
  `,
);

interface ISameProductForm {
  isInternal: boolean;
  name: string;
  form: MasterPanelProductForm;
}

interface IDefaultBlockProps {
  isLoading: boolean;
  isNoForms: boolean;
  isInternalFormSelected: boolean;
  isPartialEditing?: boolean;
  isSpecifiedTypeForms: boolean;
  selectedType: string | undefined;
  currentProductType: string;
  internalForms: IPdmProductForm[];
  publicForms: IPublicProductForm[];
  specifiedTypeForms: JSX.Element;
  onFormSelection: IFormSelection;
}

const DefaultBlock: FC<IDefaultBlockProps> = memo(
  ({
    isLoading,
     isNoForms,
     isInternalFormSelected,
    isPartialEditing,
     isSpecifiedTypeForms,
    selectedType,
    currentProductType,
     internalForms,
     publicForms,
    specifiedTypeForms,
    onFormSelection,
  }) => {
    const { pushPanelState } = useMasterPanelContext();

    const handlePushOnChoosingForms = (): void => {
      pushPanelState(MASTER_PANEL_SCREENS.PRODUCT_FORMS.CHOOSING_FORMS);
    };

    const sameProductForms = useMemo((): ISameProductForm[] => {
      const internalSameForms = internalForms
        .slice(0, MAX_SAME_LIST_LENGTH)
        .map(form => ({ isInternal: true, name: form.productType, form }));

      const publicSameForms = publicForms
        .slice(0, MAX_SAME_LIST_LENGTH - internalSameForms.length)
        .map(form => ({ isInternal: false, name: form.productType, form }));

      return [...internalSameForms, ...publicSameForms];
    }, [internalForms, publicForms]);

    return (
      <MasterPanelBlock
        icon={<ProductFormIcon />}
        rightAddon={
          currentProductType && !isPartialEditing ? (
            <Button variant='text' onClick={handlePushOnChoosingForms}>
              Больше форм
            </Button>
          ) : undefined
        }
        tip='Готовые наборы характеристик с рекомендованными единицами измерения, но без указанных значений. Товарные формы подбираются на основе типа товара.'
        title='Товарные формы'
      >
        {isPartialEditing ? (
          <Content isErrorText>
            Истек срок полного редактирования товара, работа с формами и
            характеристиками более недоступна.
          </Content>
        ) : (
          <Spinner delay={0} height='100%' loading={isLoading}>
            {currentProductType ? (
              <Fragment>
                {isNoForms ? (
                  <Content isErrorText>
                    Подходящих форм не найдено. Попробуйте изменить тип товара
                    или воспользуйтесь{' '}
                    <Button
                      variant='string'
                      onClick={handlePushOnChoosingForms}
                    >
                      поиском по формам
                    </Button>
                    .
                  </Content>
                ) : (
                  <Fragment>
                    {isSpecifiedTypeForms ? (
                      <Fragment>
                        <Content>Формы указанного типа</Content>
                        {specifiedTypeForms}
                      </Fragment>
                    ) : (
                      <Fragment>
                        <Content>Формы товаров похожего типа</Content>
                        {sameProductForms.map(({ isInternal, name, form }) => {
                          return (
                            <SelectionItem
                              key={name}
                              form={form}
                              isInternal={isInternal}
                              isSelected={
                                selectedType === name &&
                                isInternal === isInternalFormSelected
                              }
                              name={name}
                              onClick={() => {
                                onFormSelection(isInternal, form);
                              }}
                            />
                          );
                        })}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Fragment>
            ) : (
              <Content isErrorText>
                Для работы с товарными формами заполните поле «Тип товара» в
                блоке <strong>Общие сведения</strong>.
              </Content>
            )}
          </Spinner>
        )}
      </MasterPanelBlock>
    );
  },
);

export { DefaultBlock };
