import { ReactNode, useCallback, useRef, useState } from 'react';

import { useCombobox, useMultipleSelection } from 'downshift';
import { v4 as uuid } from 'uuid';

import { CloseMediumIcon } from 'resources/icons/18';
import { useToggle } from 'tools/hooks';
import { removeItem, STUB_FUNC } from 'tools/utils';

import { getFilteredItems, Options, useAsyncOptions } from './components';
import {
  DisableItemType,
  FetchOptionsType,
  IBaseInputFCProps,
  ItemToKeyType,
  ItemToStringAndOptionsType,
  ItemToStringType,
} from './types';
import { IconButtonWrapper } from '../IconButton';
import {
  AddonWrapper,
  Container,
  Input,
  SelectedItem,
  ValuesWrapper,
} from '../Multi/_shared/styled';
import { Text } from '../Text';

interface IMultiSelectProps<T = any> extends IBaseInputFCProps {
  // todo разобраться, возможно можно избавиться
  itemsSize?: 's' | 'm';
  itemsCount?: number;
  limit?: number;
  maxHeight?: number;
  value: T[];
  options: T[] | FetchOptionsType;
  rightAddon?:
    | ReactNode
    | ((props: { toggleInlineMode: () => void }) => ReactNode);
  leftAddon?: ReactNode;
  disableItem?: DisableItemType<T>;
  itemToKey?: ItemToKeyType<T>;
  itemToString?: ItemToStringAndOptionsType<T>;
  onChange: (selectedItems: T[]) => void;
  onBlur?: VoidFunction;
  onFocus?: VoidFunction;
}

const MultiSelect = <T extends any = any>({
  className,
  style,
  disablePortal,
  disabled = false,
  error = false,
  inputId,
  itemsSize = 's',
  itemsCount,
  limit = Infinity,
  value = [],
  options = [],
  placeholder = 'Выберите значение',
  leftAddon,
  rightAddon,
  itemToKey = STUB_FUNC.ITEM as (prop: T) => string,
  itemToString = STUB_FUNC.ITEM as (prop: T) => string,
  disableItem = STUB_FUNC.FALSE,
  maxHeight,
  onChange,
  onBlur = STUB_FUNC.NOOP,
  onFocus = STUB_FUNC.NOOP,
}: IMultiSelectProps<T>): JSX.Element => {
  const containerRef = useRef<HTMLDivElement>(null);

  const [asyncState, dispatch] = useAsyncOptions();
  const [isInlineMode, toggleInlineMode] = useToggle();
  const [inputValue, setInputValue] = useState('');

  const isAsyncOptions = typeof options === 'function';
  const items = isAsyncOptions ? asyncState.items : options;

  const stateReducer = useCallback(
    (state, { changes, type }) => {
      switch (type) {
        case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
        case useMultipleSelection.stateChangeTypes
          .SelectedItemKeyDownBackspace: {
          const { activeIndex, selectedItems: currentSelectedItems } = state;
          const removableItem =
            currentSelectedItems[
              activeIndex >= 0 ? activeIndex : currentSelectedItems.length - 1
            ];
          return disableItem(removableItem) ? state : changes;
        }
        default:
          return changes;
      }
    },
    [disableItem],
  );

  const { getSelectedItemProps, getDropdownProps, addSelectedItem } =
    useMultipleSelection<T>({
      selectedItems: value,
      itemToString: item => (item ? (itemToString(item) as string) : ''),
      onSelectedItemsChange: ({ selectedItems: currentSelectedItems }) => {
        onChange(currentSelectedItems ?? []);
      },
      stateReducer,
    });

  const filteredItems = getFilteredItems<T>({
    isFilteredByInputValue: !isAsyncOptions,
    items,
    selectedItems: value,
    inputValue,
    itemToKey,
    itemToString: itemToString as ItemToStringType<T>,
  });

  const stateReducerCombobox = useCallback(
    (_, { changes, type }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.ItemClick: {
          if (isInlineMode) {
            return {
              ...changes,
              isOpen: true, // keep menu open after selection.
            };
          }

          return changes;
        }
        default:
          return changes;
      }
    },
    [isInlineMode],
  );

  const stateChangeCombobox = useCallback(
    ({ inputValue: currentInputValue, type, selectedItem }) => {
      switch (type) {
        case useCombobox.stateChangeTypes.InputChange:
          if (isInlineMode) toggleInlineMode();
          setInputValue(currentInputValue ?? '');
          break;
        case useCombobox.stateChangeTypes.InputBlur: {
          if (isInlineMode) toggleInlineMode();
          setInputValue('');
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        }
        case useCombobox.stateChangeTypes.InputKeyDownEscape:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          setInputValue('');
          if (selectedItem) {
            addSelectedItem(selectedItem);
          }
          break;
        default:
          break;
      }
    },
    [addSelectedItem, isInlineMode, toggleInlineMode],
  );

  const downshift = useCombobox<T>({
    inputId,
    inputValue,
    items: filteredItems,
    defaultHighlightedIndex: 0,
    selectedItem: null,
    stateReducer: stateReducerCombobox,
    onStateChange: stateChangeCombobox,
  });

  const { isOpen, getInputProps, getComboboxProps, toggleMenu } = downshift;

  const handleToggleInlineMode = (): void => {
    if (!isOpen || isInlineMode) {
      toggleMenu();
    } else {
      setInputValue('');
    }
    toggleInlineMode();
  };

  return (
    <Container
      className={className}
      error={!!error}
      style={style}
      {...getComboboxProps({ ref: containerRef, disabled })}
    >
      {leftAddon && <AddonWrapper style={style}>{leftAddon}</AddonWrapper>}
      <ValuesWrapper maxHeight={maxHeight}>
        {value.map((item, index) => (
          <SelectedItem
            key={itemToKey ? itemToKey(item) : uuid()}
            size={itemsSize}
            {...getSelectedItemProps({
              disabled: disabled || disableItem(item),
              selectedItem: item,
              index,
            })}
          >
            <Text truncate>{itemToString(item)}</Text>
            {disableItem(item) ? null : (
              <IconButtonWrapper
                disabled={disabled}
                ml={0}
                onClick={() => onChange(removeItem(value, index))}
              >
                <CloseMediumIcon size={12} />
              </IconButtonWrapper>
            )}
          </SelectedItem>
        ))}
        {(!disabled || value.length === 0) && value.length < limit && (
          <Input
            {...getInputProps(
              getDropdownProps({
                disabled,
                onClick: () => !isInlineMode && toggleMenu(),
                onFocus,
                onBlur,
              }),
            )}
            placeholder={placeholder}
            size={itemsSize}
          />
        )}
      </ValuesWrapper>
      {rightAddon && (
        <AddonWrapper>
          {typeof rightAddon === 'function'
            ? rightAddon({ toggleInlineMode: handleToggleInlineMode })
            : rightAddon}
        </AddonWrapper>
      )}
      <Options
        anchorEl={containerRef.current}
        disablePortal={disablePortal}
        dispatch={dispatch}
        downshift={downshift}
        filteredItems={filteredItems}
        inline={isInlineMode}
        itemToKey={itemToKey}
        itemToString={itemToString}
        options={options}
        state={asyncState}
        visibleItemsCount={itemsCount}
      />
    </Container>
  );
};

export { MultiSelect };
