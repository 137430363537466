import { FC } from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { EditMediumIcon } from 'resources/icons/18';
import { useUnit } from 'common/hooks';
import { IPackage } from 'common/types/product.types/accompany-information.types';
import { getFormattedNumber } from 'common/utils';
import { CloseMediumIcon } from 'resources/icons/18';
import { IconButtonWrapper, Text } from 'UI';

const CardWrapper = styled.div<{ disabled: boolean; error: any } & SpaceProps>(
  ({ theme: { colors, space }, disabled, error }) => css`
    position: relative;
    width: 154px;
    height: 118px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};

    ${disabled &&
    css`
      background: rgba(223, 228, 235, 0.3);
    `}

    ${error &&
    css`
      border-color: ${colors.error.main};
    `}
  `,
);

const PackageProperties = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    flex-direction: column;
    margin-top: ${space[1]}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[1]}px;
  `,
);

const Controls = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    right: -1px;
    top: -1px;
    display: flex;
    border: 1px solid ${colors.divider};
    opacity: 0;
    transition: 0.2s ease-in-out opacity;

    ${CardWrapper}:hover & {
      opacity: 1;
    }
  `,
);

const Control = styled(IconButtonWrapper).attrs(() => ({
  size: 34,
  bg: 'white',
}))(
  ({ theme: { colors } }) => css`
    ${Controls} > & {
      border-right: 1px solid ${colors.divider};

      &:last-child {
        border-right: none;
      }
    }
  `,
);

const getPackageDepthLabel = (depth: number): string => {
  return depth === 1 ? 'Первичная упаковка' : `Упаковка ${depth} уровня`;
};

interface IPackageCard {
  index: number;
  showControls: any;
  disabled: boolean;
  isEditable: boolean;
  depth: number;
  productPackage: IPackage;
  error: any;
  onEditPackage: any;
  onRemovePackage: any;
}

const PackageCard: FC<IPackageCard> = ({
  index,
  showControls,
  disabled,
  isEditable,
  depth,
  productPackage,
  error,
  onEditPackage,
  onRemovePackage,
}) => {
  const { unitToString } = useUnit();

  const packageDimensions = Object.values(productPackage.size.value).map(i =>
    getFormattedNumber(i),
  );

  return (
    <CardWrapper disabled={disabled} error={!!error}>
      <Text truncate color='text.secondary' fontSize={2} fontWeight={500}>
        {productPackage.packageType.name}
      </Text>
      <Text color='text.disabled' fontSize={1}>
        {getPackageDepthLabel(depth)}
      </Text>
      <PackageProperties>
        {packageDimensions.length > 0 && (
          <Text truncate>{`${packageDimensions.join(' x ')} ${unitToString(
            productPackage.size.unit,
            {
              isSkipDash: true,
            },
          )}`}</Text>
        )}
        <Text truncate>
          Масса брутто: {getFormattedNumber(productPackage.grossWeight.value)}{' '}
          {unitToString(productPackage.grossWeight.unit, { isSkipDash: true })}
        </Text>
        <Text truncate>
          {depth === 1 ? 'Кол-во товара:' : 'Кол-во упаковок:'}{' '}
          {productPackage.quantity.isApproximate && '~'}
          {getFormattedNumber(productPackage.quantity.value)}{' '}
          {unitToString(productPackage.quantity.unit, { isSkipDash: true })}
        </Text>
      </PackageProperties>
      {showControls && (
        <Controls>
          {isEditable && (
            <Control
              title='Редактировать'
              onClick={() =>
                onEditPackage({
                  isEditable,
                  productPackage,
                  index,
                  depth,
                  error,
                })
              }
            >
              <EditMediumIcon />
            </Control>
          )}
          {disabled ||
            (depth !== 1 && (
              <Control title='Удалить' onClick={onRemovePackage}>
                <CloseMediumIcon />
              </Control>
            ))}
        </Controls>
      )}
    </CardWrapper>
  );
};

export { PackageCard };
