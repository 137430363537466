import { FC, Fragment, memo, useState } from 'react';

import { Placement } from '@popperjs/core';
import { useHistory, useLocation } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { AvatarMenu } from 'components/avatar-menu';
import {
  NotificationBadge,
  NotificationsPopup,
} from 'components/notifications';
import { ArchiveBadge } from 'entities/archive-manager';
import { getIdentity, getUser, useStopImpersonate } from 'entities/identity';
import { MegaMenuButton } from 'entities/mega-menu';
import { getLayoutStates } from 'layouts/store';
import { FaveIcon } from 'resources/icons/18';
import { EventsIcon } from 'resources/other';
import { FAVORITE, NOTIFICATIONS } from 'routes';
import { useAppSelector } from 'store';
import { Button, Container, MenuButton } from 'UI';

import { CommonHeader } from './components';

const TIP_PLACEMENT = { placement: 'bottom' as Placement };
const TITLE_NOTIFICATION = { title: 'Уведомления', ...TIP_PLACEMENT };
const TITLE_FAVORITES = { title: 'Избранное', ...TIP_PLACEMENT };

const NotificationIconWrapper = styled.div`
  display: inline-flex;
  position: relative;
`;

const StyledNotificationBadge = styled(NotificationBadge)(
  ({ theme: { colors, space, borderRadius } }) => css`
    position: absolute;
    left: 20px;
    top: ${space[0]}px;
    width: 17px;
    height: 17px;
    min-width: 17px;
    padding: 0;
    border: 1px solid ${colors.background};
    border-radius: ${borderRadius}px;
  `,
);

interface IUserHeaderProps {
  notificationsCount: number;
  onUpdateNotificationsBadgeCount: (count: number) => void;
}

const UserHeader: FC<IUserHeaderProps> = memo(
  ({ notificationsCount, onUpdateNotificationsBadgeCount }) => {
    const history = useHistory();
    const location = useLocation();

    const { isSubmitting, stopImpersonate } = useStopImpersonate();

    const identity = useAppSelector(getIdentity);
    const { collapseMainMenu } = useAppSelector(getLayoutStates);

    const { impersonateUserId, fullName } = getUser(identity);

    const [notificationAnchorEl, setNotificationAnchorEl] =
      useState<Nullable<HTMLButtonElement>>(null);

    const isNotificationPage = location.pathname === NOTIFICATIONS;
    const isImpersonation = !!impersonateUserId;

    return (
      <Fragment>
        {isImpersonation && (
          <Container
            alignItems='center'
            bg='white'
            height='27px'
            justifyContent='center'
            width='100%'
          >
            Вы видите систему как&nbsp;<strong>{fullName || 'Профиль'}</strong>
          </Container>
        )}
        <CommonHeader isCollapsed={collapseMainMenu}>
          <ArchiveBadge />
          <Container alignItems='center'>
            <MenuButton
              size={42}
              title={TITLE_FAVORITES}
              onClick={() => history.push(FAVORITE.PRODUCTS)}
            >
              <FaveIcon size={18} />
            </MenuButton>
            <NotificationIconWrapper>
              <MenuButton
                isActive={!!notificationAnchorEl}
                isDisabled={isNotificationPage}
                size={42}
                title={TITLE_NOTIFICATION}
                onClick={event => {
                  setNotificationAnchorEl(
                    notificationAnchorEl ? null : event.currentTarget,
                  );
                }}
              >
                <EventsIcon />
                {!!notificationsCount && (
                  <StyledNotificationBadge>
                    {notificationsCount}
                  </StyledNotificationBadge>
                )}
              </MenuButton>
            </NotificationIconWrapper>
            <MegaMenuButton />
            {isImpersonation && (
              <Button
                disabled={isSubmitting}
                mx={0}
                size='s'
                width={135}
                onClick={stopImpersonate}
              >
                Завершить сеанс
              </Button>
            )}
            <AvatarMenu size={42} />
          </Container>
          {notificationAnchorEl && (
            <NotificationsPopup
              anchorEl={notificationAnchorEl}
              newNotificationsCount={notificationsCount}
              onRequestClose={() => setNotificationAnchorEl(null)}
              onUpdateNotificationsBadgeCount={onUpdateNotificationsBadgeCount}
            />
          )}
        </CommonHeader>
      </Fragment>
    );
  },
);

export { UserHeader };
