import { FC, useMemo, useState } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { DOCUMENT_SECTION_NAMES } from 'common/constants/products.const';
import { IDocumentFile } from 'common/types/media.types';
import {
  DocumentSectionTypes,
  IDocuments,
  ProductCategory,
} from 'common/types/product.types';
import { getSlotDescription } from 'common/utils/products/media';
import { SlotCardView } from 'components/media/documents/components';
import { getAccessRoles, hasIdentity } from 'entities/identity';
import { OPERATIONS, usePermission } from 'entities/permission';
import { Lock1pxIcon } from 'resources/icons/1px-18';
import { useAppSelector } from 'store';
import {
  Badge,
  Container,
  ExpandableSection,
  FileNotAccess,
  InfoBlock,
  Notice,
  Text,
  ToggleButton,
  Tooltip,
} from 'UI';
import { TabsWrapper } from 'UI/ToggleButtons';

import { DocumentItem } from './components';

const DocumentsWrapper = styled(ExpandableSection.Wrapper)(
  ({ theme: { space } }) => css`
    display: grid;
    padding: ${space[0]}px;
    grid-template-columns: repeat(2, minmax(300px, 1fr));
    grid-column-gap: ${space[3]}px;
    grid-row-gap: ${space[2]}px;
  `,
);

const DOCUMENTS_KEYS_ORDER = [
  DocumentSectionTypes.constructionDocumentations,
  DocumentSectionTypes.graphicDocuments,
  DocumentSectionTypes.operationalDocuments,
  DocumentSectionTypes.certificates,
  DocumentSectionTypes.ntdDocuments,
  DocumentSectionTypes.additionalReferenceMaterials,
];

const DEFAULT_DOCUMENTS_COUNT = 7;

interface IDocumentsListProps {
  id: string;
  title: string;
  category?: ProductCategory;
  documentsCount?: number;
  documents: IDocumentFile[];
}

const DocumentsList: FC<IDocumentsListProps> = ({
  id,
  title,
  category,
  documentsCount,
  documents,
}) => {
  const isAuthed = useSelector(hasIdentity);

  if (documents.length === 0 && documentsCount === 0) return null;

  if (!isAuthed && category === ProductCategory.questionnaire) {
    if (documents.length === 0 && documentsCount !== 0) {
      return (
        <InfoBlock id={id} title={title}>
          <Notice mb={3} size='xs' type={Notice.TYPES.LOCK}>
            Из-за ограничений прав доступа документы недоступны для просмотра.
          </Notice>
          <DocumentsWrapper>
            <FileNotAccess mb='0px' />
            <FileNotAccess mb='0px' />
          </DocumentsWrapper>
        </InfoBlock>
      );
    }
  }

  if (!isAuthed && category === ProductCategory.product) {
    return null;
  }

  if (documents.length > 0) {
    return (
      <InfoBlock id={id} title={<Container mb={3}>{title}</Container>}>
        <ExpandableSection
          expandable={documents.length > DEFAULT_DOCUMENTS_COUNT}
          maxHeight={252}
          p='4px'
        >
          <DocumentsWrapper>
            {documents.map(file => (
              <DocumentItem key={file.id} file={file} />
            ))}
          </DocumentsWrapper>
        </ExpandableSection>
      </InfoBlock>
    );
  }

  return null;
};

interface IDocumentsWithSubsectionsListProps {
  id: string;
  title: string;
  documents: IDocuments;
}

const DocumentsWithSubsectionsList: FC<IDocumentsWithSubsectionsListProps> = ({
  id,
  title,
  documents,
}) => {
  const isAuthed = useSelector(hasIdentity);
  const hasUpdateCompanyProductAccess = usePermission(
    OPERATIONS.PRODUCTS_OWN.UPDATE_COMPANY_PRODUCT,
  );

  const { chiefConstructor, juniorConstructor } =
    useAppSelector(getAccessRoles);
  const isConstructor = chiefConstructor || juniorConstructor;

  const [documentToSubsectionMap, allDocuments] = useMemo(() => {
    const docToSubsectionMap = new Map();

    return [
      docToSubsectionMap,
      Object.entries(documents).reduce((acc, [subsection, docs]) => {
        if (!docs?.items) return acc;

        docs.items.forEach(doc => {
          return docToSubsectionMap.set(
            doc?.file ? doc?.file.id : doc?.slot?.id,
            subsection,
          );
        });

        return [...acc, ...docs.items];
      }, []),
    ];
  }, [documents]);

  const documentsCount = allDocuments.length;

  const allSubsectionsFilter = {
    key: 'all',
    title: 'Все',
    count: documentsCount,
  };

  const subsectionsFilters = useMemo(() => {
    return [
      allSubsectionsFilter,
      ...DOCUMENTS_KEYS_ORDER.map(subsection => {
        const items = documents[subsection]?.items || [];
        if (items.length > 0) {
          return {
            key: subsection,
            title: DOCUMENT_SECTION_NAMES[subsection],
            count: items.length,
          };
        }
        return null;
      }),
    ].filter(Boolean);
  }, [allSubsectionsFilter, documents]);

  const [subsectionFilter, setSubsectionFilter] = useState(
    allSubsectionsFilter.key,
  );
  const handleSelectSubsection = ({ currentTarget: { dataset } }): void => {
    setSubsectionFilter(dataset.subsection);
  };

  const filteredDocuments = useMemo(
    () => documents[subsectionFilter]?.items ?? allDocuments,
    [allDocuments, documents, subsectionFilter],
  );

  const slotDocuments = filteredDocuments.filter(
    doc => doc?.slot !== undefined && doc?.file === undefined,
  );

  const fileDocuments = filteredDocuments.filter(
    doc => doc?.file !== undefined,
  );

  if (documentsCount === 0) return null;

  if (!isAuthed) {
    return (
      <InfoBlock id={id} title={<Container mb={3}>{title}</Container>}>
        <Notice mb={3} size='xs' type={Notice.TYPES.LOCK}>
          Незарегистрированным пользователям ограничены права доступа.
        </Notice>
        <DocumentsWrapper>
          <FileNotAccess mb='0px' />
          <FileNotAccess mb='0px' />
        </DocumentsWrapper>
      </InfoBlock>
    );
  }

  if (documentsCount > 0) {
    return (
      <InfoBlock id={id} title={title}>
        {subsectionsFilters.length === 2 ? (
          <Container alignItems='center'>
            {documents[subsectionsFilters[1].key].isPrivate && (
              <Lock1pxIcon mr={1} />
            )}
            <Text fontSize={7} fontWeight={500}>
              {subsectionsFilters[1].title}{' '}
            </Text>
            <Badge
              fontWeight={700}
              label={subsectionsFilters[1].count}
              ml={1}
              size='m'
            />
          </Container>
        ) : (
          <TabsWrapper>
            {subsectionsFilters.map(filter => (
              <ToggleButton
                key={filter.key}
                checked={filter.key === subsectionFilter}
                data-subsection={filter.key}
                label={`${filter.title} (${filter.count})`}
                leftAddon={
                  documents[filter.key]?.isPrivate && (
                    <Tooltip
                      arrow
                      hint
                      maxWidth={236}
                      title={
                        hasUpdateCompanyProductAccess
                          ? 'Это приватный раздел — он доступен только сотрудникам вашей компании. Изменить настройки приватности можно в режиме редактирования.'
                          : 'Это приватный раздел — он доступен только сотрудникам вашей компании.'
                      }
                    >
                      <Lock1pxIcon mr={1} />
                    </Tooltip>
                  )
                }
                mr={0}
                onClick={handleSelectSubsection}
              />
            ))}
          </TabsWrapper>
        )}
        <ExpandableSection
          expandable={filteredDocuments.length > DEFAULT_DOCUMENTS_COUNT}
          maxHeight={252}
          mt={3}
        >
          <DocumentsWrapper>
            {fileDocuments.map(document => {
              const subsectionKey = documentToSubsectionMap.get(
                document.file?.id,
              );

              if (document?.slot !== undefined) {
                return (
                  <DocumentItem
                    key={document.file.id}
                    additionalInformation={document?.additionalInformation}
                    file={document.file}
                    subsectionKey={subsectionKey}
                  />
                );
              }
              return (
                <DocumentItem
                  key={document.file.id}
                  additionalInformation={document?.additionalInformation}
                  file={document.file}
                  subsectionKey={subsectionKey}
                />
              );
            })}
            {slotDocuments.map((document, index) => {
              const { additionalInformation, slot } = document || {};
              const { title: slotTitle, isRequired } = slot;
              const { specificationInformation } = additionalInformation ?? {};
              const { designation } = specificationInformation ?? {};

              const isShowForSpecificationFields =
                isConstructor && !!specificationInformation;

              const slotTitleExtended = isShowForSpecificationFields
                ? `${slotTitle}${designation ? ` ${designation}` : ''}`
                : slotTitle;

              return (
                <SlotCardView
                  key={`${slotTitle}.${index}`}
                  disabled
                  description={getSlotDescription({
                    isRequired,
                    isUploadToSpecification: isShowForSpecificationFields,
                  })}
                  title={slotTitleExtended}
                />
              );
            })}
          </DocumentsWrapper>
        </ExpandableSection>
      </InfoBlock>
    );
  }

  return null;
};

export { DocumentsList, DocumentsWithSubsectionsList };
