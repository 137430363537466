import { FC, memo, useCallback, useState } from 'react';

import styled, { css } from 'styled-components';

import { UnlockIcon } from 'resources/modal';
import { Checkbox, Heading, Modal, Text } from 'UI';

const Container = styled.div(
  ({ theme: { space, colors } }) => css`
    min-height: 260px;
    margin-bottom: ${space[3]}px;
    padding: ${space[1]}px 0;
    border: 1px solid ${colors.divider};
    border-radius: ${space[0]}px;
  `,
);

const Divider = styled.div(
  ({ theme: { space, colors } }) => css`
    height: 1px;
    margin-left: ${space[2]}px;
    margin-right: ${space[2]}px;
    margin-bottom: ${space[1]}px;
    background-color: ${colors.divider};
  `,
);

const Item = styled.li<{ selected: boolean }>(
  ({ theme: { space, colors }, selected }) => css`
    display: flex;
    margin: 0 ${space[2]}px 12px;
    background-color: ${selected ? colors.background : 'none'};
  `,
);

const PartnersListWrapper = styled.ul`
  padding: 0;
`;

const HeaderWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${space[1]}px;
    padding-left: ${space[2]}px;
    padding-right: ${space[2]}px;
  `,
);

interface ISelectPartnersModalProps {
  companiesAccesses;
  options;
  onConfirm;
  handleModalClose;
}

const SelectPartnersModal: FC<ISelectPartnersModalProps> = memo(
  ({ companiesAccesses, options, onConfirm, handleModalClose, ...other }) => {
    const [selected, setSelected] = useState<any>([]);

    const isSelectedAll = selected.length === companiesAccesses.length;

    const handleSelectAll = useCallback(() => {
      if (isSelectedAll) {
        setSelected([]);
      } else {
        setSelected(companiesAccesses);
      }
    }, [companiesAccesses, isSelectedAll]);

    const isIndeterminate = !!selected.length && !isSelectedAll;

    return (
      <Modal
        // @ts-ignore
        onSubmit={() => onConfirm(selected.map(item => item?.originCompany.id))}
        {...other}
      >
        <Modal.Default
          icon={<UnlockIcon />}
          submitButton='Запросить'
          title='Отправить запрос'
        >
          <Text fontSize={2} fontWeight={600} mb={1}>
            Список партнеров, у которых есть хотя бы один выбранный товар
          </Text>
          <Container>
            <HeaderWrapper>
              <Heading as='h4' color='text.secondary'>
                Партнеры
              </Heading>
              <Checkbox
                indeterminate={isIndeterminate}
                ml='auto'
                value={isSelectedAll}
                onChange={handleSelectAll}
              />
            </HeaderWrapper>
            <Divider />
            <PartnersListWrapper>
              {companiesAccesses.map(item => {
                const isSelected = selected.includes(item);

                const handleClick = useCallback(() => {
                  return isSelected
                    ? setSelected((prev: any[]) =>
                        prev.filter(
                          ({ originCompany: { id } }) =>
                            item.originCompany.id !== id,
                        ),
                      )
                    : setSelected(prev => [...prev, item]);
                }, [isSelected, item]);

                return (
                  <Item key={item.originCompany.id} selected={isSelected}>
                    <Text truncate pr={1}>
                      {item.originCompany.name}
                    </Text>
                    <Checkbox
                      style={{ marginLeft: 'auto' }}
                      value={isSelected}
                      onChange={handleClick}
                    />
                  </Item>
                );
              })}
            </PartnersListWrapper>
          </Container>
        </Modal.Default>
      </Modal>
    );
  },
);

SelectPartnersModal.defaultProps = {
  companiesAccesses: [],
};

const SELECT_PARTNERS = 'SELECT_PRODUCT_PARTNERS';

export { SelectPartnersModal, SELECT_PARTNERS };
