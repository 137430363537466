import {
  forwardRef,
  ReactNode,
  CSSProperties,
  useCallback,
  Fragment,
  MouseEvent,
} from 'react';

import styled, { css } from 'styled-components';
import {
  border,
  color,
  fontWeight as styledFontWeight,
  space as styledSpace,
  height,
  typography,
  BorderProps,
  SpaceProps,
  ColorProps,
  TypographyProps,
  width,
  WidthProps,
} from 'styled-system';

import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { Tooltip, DescriptionList, IconButtonWrapper } from 'UI';
import { gapStyle } from 'UI/Blocks/Paper';

const ViewContainer = styled.div.attrs(() => ({
  id: 'property-container',
}))<{ disabled?: boolean; error?: boolean } & SpaceProps>(
  ({ theme: { space, colors, borderRadius }, error, disabled }) => css`
    display: flex;
    align-items: center;
    min-height: ${space[4]}px;
    width: 100%;
    min-width: 0;
    border-radius: ${borderRadius}px;
    color: ${colors.text.secondary};
    background: ${colors.background};

    ${disabled && 'opacity: 50%;'}

    ${error &&
    css`
      border: 1px solid ${colors.error.main};
    `}

    ${styledFontWeight};
    ${border};
    ${color};
    ${height};
    ${styledSpace}
  `,
);

const LabelButtonText = styled.button<WidthProps>(
  ({ theme: { colors, fontSizes }, disabled }) => css`
    display: inline-flex;
    justify-content: left;
    text-align: left;
    align-items: center;
    min-width: 1px;
    font-size: ${fontSizes[3]}px;
    color: ${colors.text.primary};
    overflow: hidden;

    cursor: ${disabled ? 'default' : 'pointer'};

    > span,
    > div {
      overflow: hidden;
    }

    ${width};
  `,
);

const Content = styled.div<{ isDisabled: boolean; limitWidth?: boolean }>(
  ({ theme: { space }, isDisabled, limitWidth }) => css`
    display: flex;
    flex-grow: ${limitWidth ? 0 : 1};
    min-width: 1px;
    padding: ${space[1]}px ${space[1]}px ${space[1]}px 0;
    cursor: pointer;

    ${isDisabled &&
    css`
      cursor: default;
    `}
  `,
);

const RightActionsContainer = styled.div<{
  isVisible?: boolean;
  isFullWidth?: boolean;
}>(
  ({ isVisible, isFullWidth, theme: { space } }) => css`
    display: flex;
    justify-content: flex-end;
    width: 76px;
    min-width: 76px;
    height: ${isFullWidth ? '34px' : 'auto'};
    align-items: center;
    align-self: stretch;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    margin-left: auto;
    padding-left: ${space[1]}px;

    ${isFullWidth &&
    css`
      flex-grow: 1;
      min-height: ${space[4]}px;
    `}

    ${ViewContainer}:hover & {
      visibility: visible;
    }
  `,
);

const HighlightBody = styled.div<
  SpaceProps & TypographyProps & { center?: boolean }
>(
  ({ theme: { colors, space, borderRadius }, center }) => css`
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.highlight[0]};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

    ${center &&
    css`
      display: flex;
      justify-content: center;
    `}

    ${styledSpace};
    ${typography};
  `,
);

const FrameBody = styled.div<
  SpaceProps & { noBorders?: boolean; gap?: string | number }
>(
  ({ theme: { colors, borderRadius, space }, noBorders }) => css`
    display: flex;
    flex-direction: column;
    padding: ${space[2]}px ${space[4]}px;
    background: ${colors.white};
    border: ${noBorders ? 0 : `1px solid ${colors.divider}`};
    border-radius: 0 0 ${borderRadius}px ${borderRadius}px;

    ${styledSpace};
    ${gapStyle}
  `,
);

const LevelBody = styled.div<SpaceProps & ColorProps & { gap?: number }>(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    margin-left: 17px;
    padding: 0 0 0 17px;
    border-left: 1px solid ${colors.divider};
    background: ${colors.white};

    ${styledSpace};
    ${color};
    ${gapStyle};
  `,
);

const BorderForComponent = styled.div<{
  collapse: boolean;
  dropping?: boolean;
  borders?: boolean;
  error?: boolean;
}>(
  ({ theme: { colors }, collapse, dropping, borders, error }) => css`
    & > ${ViewContainer} {
      border: 1px solid transparent;
      transition: border-color 200ms ease-in;
    }

    & ${HighlightBody} {
      transition: border-color 200ms ease-in;
      border: 1px solid transparent;
      border-top: none;
    }

    ${borders &&
    css`
      & ${HighlightBody} {
        border: 1px solid ${colors.divider};
      }
    `}

    ${dropping &&
    css`
      & > ${ViewContainer} {
        border-color: ${colors.text.primary};
        border-bottom-color: ${collapse ? colors.text.primary : 'transparent'};
      }

      & ${HighlightBody} {
        border-color: ${colors.text.primary};
      }
    `}

    ${error &&
    css`
      & > ${ViewContainer} {
        border-color: ${colors.error.main};
        border-bottom-color: ${collapse ? colors.error.main : 'transparent'};
      }

      & ${HighlightBody} {
        border-color: ${colors.error.main};
      }

      & ${FrameBody} {
        border-color: ${colors.error.main};
        border-top-color: transparent;
      }
    `}
  `,
);

interface SimpleViewTemplateProps {
  style?: CSSProperties;
  disabled?: boolean;
  error?: boolean;
  title?: string;
  visibleRightActions?: boolean;
  hideRightActions?: boolean;
  fullWidthRightActions?: boolean;
  leftAction?: ReactNode;
  rightActions?: ReactNode;
  children: ReactNode;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
}

const SimpleViewTemplate = forwardRef<
  HTMLDivElement,
  SimpleViewTemplateProps & BorderProps & SpaceProps
>(
  (
    {
      style,
      error,
      disabled,
      visibleRightActions,
      hideRightActions,
      fullWidthRightActions,
      leftAction,
      rightActions,
      title,
      children,
      onClick,
      ...other
    },
    ref,
  ) => {
    return (
      <ViewContainer ref={ref} disabled={disabled} error={error} {...other}>
        {leftAction}
        <Tooltip hint pointer placement='bottom' title={title}>
          <Content
            isDisabled={disabled || !onClick}
            limitWidth={fullWidthRightActions}
            style={style}
            {...(!disabled && { onClick })}
          >
            {children}
          </Content>
        </Tooltip>
        {!hideRightActions && (
          <RightActionsContainer
            isFullWidth={fullWidthRightActions}
            isVisible={visibleRightActions}
          >
            {rightActions}
          </RightActionsContainer>
        )}
      </ViewContainer>
    );
  },
);

/**
 * @deprecated
 * Используй DescriptionList.Property
 */
const DescriptionListProperty = styled(DescriptionList.Property)`
  display: flex;
  flex-grow: 1;
  min-width: 1px;
`;

interface CollapseViewTemplateProps extends SimpleViewTemplateProps {
  canCollapse?: boolean;
  collapse: boolean;
  tip?: string;
  title?: string;
  label: ReactNode;
  withWideLabel?: boolean;
  onToggleCollapse?: () => void;
}

const CollapseViewTemplate = forwardRef<
  HTMLDivElement,
  CollapseViewTemplateProps & BorderProps & SpaceProps & ColorProps
>(
  (
    {
      style,
      disabled,
      canCollapse = true,
      collapse,
      label,
      withWideLabel,
      tip,
      title,
      visibleRightActions,
      children,
      onClick,
      onToggleCollapse,
      ...other
    },
    ref,
  ) => {
    const handleLabelClick = useCallback(
      event => {
        if (onToggleCollapse) {
          event.stopPropagation();
          onToggleCollapse();
        }
      },
      [onToggleCollapse],
    );

    return (
      <Fragment>
        <SimpleViewTemplate
          borderBottomLeftRadius={collapse ? 4 : 0}
          borderBottomRightRadius={collapse ? 4 : 0}
          borderTopLeftRadius={4}
          borderTopRightRadius={4}
          disabled={disabled}
          style={style}
          title={title}
          visibleRightActions={visibleRightActions}
          onClick={onClick}
          {...other}
        >
          <LabelButtonText
            disabled={disabled}
            width={withWideLabel ? '100%' : undefined}
            onClick={handleLabelClick}
          >
            {canCollapse && (
              <IconButtonWrapper ml={1}>
                {collapse ? <ArrowDownIcon /> : <ArrowUpIcon />}
              </IconButtonWrapper>
            )}
            {label}
            {tip && (
              <Tooltip arrow maxWidth={240} placement='right' title={tip}>
                <Help1pxIcon ml={1} size={12} />
              </Tooltip>
            )}
          </LabelButtonText>
        </SimpleViewTemplate>
        <div ref={ref} style={{ display: collapse ? 'none' : 'block' }}>
          {children}
        </div>
      </Fragment>
    );
  },
);

const ViewTemplates = {
  Container: ViewContainer,
  SimpleComponent: SimpleViewTemplate,
  CollapseComponent: CollapseViewTemplate,
  BorderForComponent,
  HighlightBody,
  LevelBody,
  FrameBody,
};

export { ViewTemplates, DescriptionListProperty };
