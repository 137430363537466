import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  SpaceProps,
  styleFn,
  layout,
  LayoutProps,
} from 'styled-system';

import theme from '../theme';

type SizeButton = 's' | 'm';
type VariantTag = 'filled' | 'outlined';

const sizeStyle: styleFn = ({
  size = 'm',
  circle,
  theme: { space, fontSizes },
}) => {
  if (size === 's') {
    return css`
      height: 18px;
      width: ${circle ? '18px' : 'auto'};
      padding: 0 ${space[1]}px;
      font-size: ${fontSizes[2]}px;
      border-radius: 9px;
    `;
  }

  return css`
    height: 22px;
    width: ${circle ? '22px' : 'auto'};
    padding: 0 ${space[1]}px;
    font-size: ${fontSizes[2]}px;
    border-radius: 11px;
  `;
};

const themeButton = {
  filled: {
    default: css`
      color: ${theme.colors.white};
      background: ${theme.colors.text.primary};
      box-shadow: ${theme.shadows[1]};
    `,
  },
  outlined: {
    default: css`
      color: ${theme.colors.text.primary};
      border: 1px solid ${theme.colors.divider};
    `,
  },
};

const variantStyle: styleFn = ({ variant = 'filled' }) => {
  const buttonByVariant = themeButton[variant];

  return buttonByVariant.default;
};

interface IButtonRoundedProps extends SpaceProps, LayoutProps {
  circle?: boolean;
  size?: SizeButton;
  variant?: VariantTag;
}

const ButtonRounded = styled.button<IButtonRoundedProps>(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &:disabled,
    &[disabled] {
      color: ${colors.text.disabled};
      cursor: default;
    }

    ${styledSpace};
    ${layout};
    ${variantStyle};
    ${sizeStyle};
  `,
);

export { ButtonRounded };
