import styled, { css } from 'styled-components';

import { Button, IconButtonWrapper } from 'UI';

export const PropertiesButton = styled(Button).attrs(() => ({
  color: 'secondary',
  variant: 'string',
}))(
  ({ theme: { colors }, active }) => css`
    &,
    & svg {
      color: ${colors.text.primary};
    }
    ${active &&
    css`
      &,
      & svg {
        color: ${colors.primary.main};
      }

      &:hover,
      &:hover svg {
        color: ${colors.primary.dark};
      }
    `}
  `,
);

export const TabPanel = styled.div.attrs(() => ({
  role: 'tabPanel',
}))(
  ({ theme: { space } }) => css`
    height: 576px;
    padding: ${space[3]}px ${space[4]}px 0;
    // при сбросе фильтров появляется скролл (баннеры сдвигают панель), пришлось его принудительно спрятать
    overflow-x: hidden;
    overflow-y: auto;
  `,
);

export const Footer = styled.footer(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-shrink: 0;
    align-items: center;
    height: 78px;
    background: ${colors.background};
  `,
);

export const FooterIconButton = styled(IconButtonWrapper)(
  ({ theme: { space, colors } }) => css`
    width: ${space[5]}px;
    height: 100%;
    border-right: 1px solid ${colors.divider};
  `,
);

export const PropertiesBar = styled.div<{ active: boolean }>(
  ({ theme: { space, colors, borderRadius }, active }) => css`
    display: flex;
    align-items: center;
    height: ${space[4]}px;
    min-height: ${space[4]}px;
    padding: 0 ${space[2]}px;
    margin-left: -1px;
    background: ${colors.highlight[0]};
    border-radius: ${borderRadius}px;

    ${active &&
    css`
      background: ${colors.highlight[1]};
    `}
  `,
);

export const ResetButton = styled(Button)(
  ({ theme: { space, colors, fontSizes } }) => css`
    height: 16px;
    margin-left: auto;
    padding: 0 ${space[0]}px;
    font-size: ${fontSizes[0]}px;
    text-transform: uppercase;
    background: ${colors.primary.main};
  `,
);
