import { FC } from 'react';

import styled, { css } from 'styled-components';

import { WithChildren } from 'common/types/common.types';
import { getLayoutStates } from 'layouts/store';
import { ICollapsed } from 'layouts/types';
import { useAppSelector } from 'store';
import { Container, ProgressIndicator } from 'UI';

import { HeaderSearch } from './Search';
import { Services } from './Services';

const HeaderWrapper = styled.header(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    flex: 0 0 42px;
    width: 100%;
    height: 42px;
    background: ${colors.background};
    transition: top ease-in 200ms;
  `,
);

const StyledProgressIndicator = styled(ProgressIndicator)`
  position: absolute;
  bottom: 0;
  background: transparent;
`;

const CommonHeader: FC<
  WithChildren<ICollapsed> & { showServices?: boolean }
> = ({ isCollapsed, showServices = true, children }) => {
  const { isGlobalLoading } = useAppSelector(getLayoutStates);

  return (
    <HeaderWrapper>
      {showServices && <Services isCollapsed={isCollapsed} />}
      <Container alignItems='center' flex='1'>
        <HeaderSearch />
        <Container
          alignItems='center'
          justifyContent='end'
          ml='auto'
          width='400px'
        >
          {children}
        </Container>
      </Container>
      <StyledProgressIndicator isLoading={isGlobalLoading} />
    </HeaderWrapper>
  );
};

export { CommonHeader };
