import styled, { css } from 'styled-components';

import { ExpandableSection } from 'UI';

export const VideosWrapper = styled(ExpandableSection.Wrapper)(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(226px, 1fr));
    grid-gap: ${space[2]}px;
    justify-items: center;
  `,
);
