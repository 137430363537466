import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { ProgressIndicator, IconButton } from 'UI';

export const CardMainBlock = styled.article(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: flex;
    min-width: 800px;
    max-width: 1120px;
    padding: ${space[2]}px;
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
  `,
);

export const StyledProgressIndicator = styled(ProgressIndicator)<SpaceProps>`
  position: absolute;
  bottom: 0;
  z-index: 1;
  margin: 0 0 2px 0;

  ${styledSpace};
`;

export const SearchInput = styled.input(
  ({ theme: { space, colors, fontSizes } }) => css`
    flex-grow: 1;
    padding-right: ${space[1]}px;
    padding-left: ${space[2]}px;
    color: ${colors.text.primary};
    font-size: ${fontSizes[5]}px;
    text-overflow: ellipsis;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

export const SearchContainer = styled.div<SpaceProps>(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: ${space[1]}px;
    background: ${colors.white};
    min-height: 52px;

    ${styledSpace};
  `,
);

export const SearchIconButton = styled(IconButton).attrs(() => ({
  size: 52,
  title: 'Найти',
  variant: 'darkHighlight',
}))`
  border-radius: 0;
`;
