import styled, { css } from 'styled-components';

import { IconButton } from 'UI';

export const ModalWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    height: 562px;
    margin: 0 -${space[4]}px;
    overflow: hidden;
  `,
);

export const Sidebar = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    border-right: 1px solid ${colors.divider};
  `,
);

export const SidebarButton = styled(IconButton)(
  ({ theme: { space, colors } }) => css`
    margin: ${space[1]}px;

    &:last-of-type {
      position: relative;
      margin-top: auto;

      &::before {
        content: '';
        position: absolute;
        top: -10px;
        width: 52px;
        height: 1px;
        background: ${colors.divider};
      }
    }
  `,
);

export const SidebarButtonDisabled = styled.div(
  ({ theme: { space } }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    margin: ${space[1]}px;
    width: ${space[4]}px;
    min-width: ${space[4]}px;
    height: ${space[4]}px;
  }
  `,
);

export const SwitchWrapper = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    margin-top: auto;
    padding: 14px ${space[2]}px;
    background: ${colors.background};
    border-top: 1px solid ${colors.divider};
  `,
);

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
