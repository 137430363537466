import { memo, FC } from 'react';

import styled, { css } from 'styled-components';

import { CATALOGS, DICTIONARIES, getPath } from 'routes';
import { Link } from 'tools/libs';
import { Container, Text, Badge } from 'UI';

const IconWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 34px;
    width: 34px;
    min-width: 34px;
    max-width: 34px;
    background: ${colors.highlight[0]};
    border-radius: 8px;
  `,
);

const StyledText = styled(Text)(
  ({ theme: { colors } }) => css`
    color: ${colors.primary.main};
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  `,
);

const CardContainer = styled(Link)(
  ({ theme: { colors, space } }) => css`
    display: flex;
    width: 100%;
    position: relative;
    padding: ${space[2]}px;
    color: ${colors.text.primary};
    border: 1px solid transparent;
    background: ${colors.background};
    border-radius: 16px;
    word-break: break-word;

    &:hover {
      background: ${colors.white};
      border: 1px solid ${colors.divider};
      color: ${colors.text.primary};

      ${IconWrapper} {
        background: ${colors.highlight[1]};

        & svg {
          color: ${colors.primary.main};
        }
      }

      ${StyledText} {
        opacity: 1;
      }
    }
  `,
);

export interface ICatalogCardProps {
  icon: JSX.Element;
  title: string;
  text: string;
  count: string;
  catalogId: string;
}

const CatalogCard: FC<{ item: ICatalogCardProps }> = memo(
  ({ item: { catalogId, icon, title, text, count } }) => (
    <CardContainer
      to={
        catalogId
          ? getPath(CATALOGS.BREEDING, { catalogId })
          : getPath(DICTIONARIES.COMPANIES)
      }
    >
      <IconWrapper>{icon}</IconWrapper>
      <Container column gap='6px' ml={2} width='100%'>
        <Text fontSize={7}>{title}</Text>
        <Text mb={1}>{text}</Text>
        <Container mt='auto'>
          <StyledText>Перейти к каталогу</StyledText>
          <Badge
            fontSize={11}
            fontWeight={600}
            label={count}
            ml='auto'
            size='m'
            textTransform='none'
            type={Badge.TYPES.SECONDARY}
          />
        </Container>
      </Container>
    </CardContainer>
  ),
);

export { CatalogCard };
