import { FC, Fragment, useState } from 'react';

import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { IPackage } from 'common/types/product.types/accompany-information.types';
import { ImageSideBarAddon } from 'components/ImageSideBarAddon';
import { useAnimationModal, MODAL_DURATION } from 'entities/modals';
import { CloseMediumIcon } from 'resources/icons/18';
import { IconButton } from 'UI';

import { ProductPackageDetailInfo } from './DetailInfo';

const SIDEBAR_WIDTH = 528;

const Overlay = styled.div<{ onMouseDown: (any) => void }>(
  ({ theme: { colors, zIndex } }) => css`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    z-index: ${zIndex.sidebar};
    background: ${colors.blackout};
    opacity: 0;
    transition: opacity ${MODAL_DURATION}ms ease-out;
  `,
);

const Content = styled.div(
  ({ theme: { colors } }) => css`
    height: 100%;
    width: ${SIDEBAR_WIDTH}px;
    min-width: ${SIDEBAR_WIDTH}px;
    background: ${colors.white};
    opacity: 1;
    transform: translateX(${SIDEBAR_WIDTH}px);
    transition: transform ${MODAL_DURATION}ms ease-out;
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme: { colors, zIndex } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.highlight[0]};
    z-index: ${zIndex.sideMenu};
  `,
);

interface IPackagesSidebar {
  packageIndex: number;
  packages: IPackage[];
  packageVariationName: string;
  handleModalClose: any;
}

const PackagesSidebar: FC<IPackagesSidebar> = ({
  packageIndex,
  packages,
  packageVariationName,
  handleModalClose,
}) => {
  const [selectedPackageIndex, setSelectedPackageIndex] =
    useState(packageIndex);
  const [selectedImage, setSelectedImage] = useState(null);

  const { isModalVisible } = useAnimationModal();

  const prevProductPackage = packages[selectedPackageIndex - 1];
  const nextProductPackage = packages[selectedPackageIndex + 1];

  const handleChangePrevProductPackage = (): void => {
    setSelectedImage(null);
    setSelectedPackageIndex(prev => prev - 1);
  };

  const handleChangeNextProductPackage = (): void => {
    setSelectedImage(null);
    setSelectedPackageIndex(prev => prev + 1);
  };

  const handleOutsideClick = (event): void => {
    if (!handleModalClose) return;

    if (event.currentTarget === event.target) {
      handleModalClose(event);
    }
  };

  const primaryPackageUnit = packages[packages.length - 1].quantity.unit;

  return ReactDOM.createPortal(
    <Fragment>
      <Overlay
        style={{ opacity: isModalVisible ? 1 : 0 }}
        onMouseDown={handleOutsideClick}
      >
        <CloseButton onClick={handleModalClose}>
          <CloseMediumIcon />
        </CloseButton>
        {selectedImage && (
          <ImageSideBarAddon
            selectedImage={selectedImage}
            setSelectedImage={setSelectedImage}
            sidebarWidth={SIDEBAR_WIDTH}
          />
        )}
        <Content
          style={{
            transform: `translateX(${isModalVisible ? 0 : SIDEBAR_WIDTH}px)`,
          }}
        >
          <ProductPackageDetailInfo
            nextProductPackage={nextProductPackage}
            packageVariationName={packageVariationName}
            prevProductPackage={prevProductPackage}
            productPackage={packages[selectedPackageIndex]}
            productPackageUnit={primaryPackageUnit}
            setSelectedImage={setSelectedImage}
            onChangeNextProductPackage={handleChangeNextProductPackage}
            onChangePrevProductPackage={handleChangePrevProductPackage}
          />
        </Content>
      </Overlay>
    </Fragment>,
    document.body,
  );
};

export { PackagesSidebar };
