import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { IParamsId } from 'common/types/common.types';

import { IArchiveItem } from '../types';

const INITIAL_VALUE_STATE: Array<IArchiveItem> = [];

const archiveManagerSlice = createSlice({
  name: 'archiveManager',
  initialState: INITIAL_VALUE_STATE,
  reducers: {
    pushProcess: (state, { payload }: PayloadAction<IArchiveItem>) => {
      return [payload, ...state];
    },
    updateProcess: (
      state,
      { payload }: PayloadAction<Partial<IArchiveItem> & IParamsId>,
    ) => {
      return state.map(item =>
        item.id === payload.id ? { ...item, ...payload } : item,
      );
    },
    removeProcess: (state, { payload }: PayloadAction<string>) => {
      return state.filter(item => item.id !== payload);
    },
    removeAllProcess: () => [],
  },
});

export const archiveManagerActions = archiveManagerSlice.actions;
export const archiveManagerReducer = archiveManagerSlice.reducer;
