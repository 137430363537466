import { memo, FC, Dispatch, SetStateAction } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { LS_SERVICE_PANEL_STATE } from 'common/constants/LS.const';
import { ICatalog } from 'common/types/catalogs.types';
import { setLSItem } from 'common/utils';
import { getCatalogIcon } from 'common/utils/catalogy';
import { CloseMediumIcon } from 'resources/other-28';
import { Container, Heading, IconButton, Notice, Switch, Text } from 'UI';

import { DICTIONARIES_BUTTONS, HEADER_HEIGHT, MIN_BUTTONS } from './constants';

const ButtonSettingsPanel = styled.div<{ isVisible: boolean }>(
  ({ theme: { space, colors, zIndex }, isVisible }) => css`
    position: absolute;
    top: -${HEADER_HEIGHT};
    left: 0;
    width: 376px;
    height: 100vh;
    padding: ${space[2]}px;
    background: ${colors.white};
    border-left: 1px solid ${colors.divider};
    transform: translateX(${isVisible ? -100 : 0}%);
    transition: transform 0.6s ease-in-out;
    overflow-x: hidden;
    overflow-y: scroll;
    z-index: ${zIndex.sidebar - 20};
  `,
);

const CURSOR_STYLE = { cursor: 'pointer' };

interface IButtonSettingsPanelProps {
  isSettingsVisible: boolean;
  catalogs: ICatalog[];
  buttons: string[];
  setButtons: Dispatch<SetStateAction<string[]>>;
  setSettingsVisible: Dispatch<SetStateAction<boolean>>;
  onUpdateScrollState: VoidFunction;
}

const ButtonSettings: FC<IButtonSettingsPanelProps> = memo(
  ({
    isSettingsVisible,
    catalogs,
    buttons,
    setButtons,
    setSettingsVisible,
    onUpdateScrollState,
  }) => {
    const theme = useTheme();

    const handleSwitch = (buttonId: string): void => {
      setButtons(prev => {
        const newButtons = buttons.includes(buttonId)
          ? prev.filter(id => id !== buttonId)
          : [...prev, buttonId];

        setLSItem(LS_SERVICE_PANEL_STATE, newButtons);
        return newButtons;
      });

      onUpdateScrollState();
    };

    return (
      <ButtonSettingsPanel isVisible={isSettingsVisible}>
        <Container
          alignItems='center'
          justifyContent='space-between'
          width='100%'
        >
          <Heading as='h3'>Отображение каталогов</Heading>
          <IconButton size={28} onClick={setSettingsVisible}>
            <CloseMediumIcon />
          </IconButton>
        </Container>
        <Notice
          borderRadius={theme.borderRadius}
          hasIcon={false}
          minHeight='auto'
          my={2}
          px={1}
          py={0}
        >
          <Text fontSize={2}>
            Минимальное число выбранных справочников — {MIN_BUTTONS}
          </Text>
        </Notice>
        <Container flexDirection='column' gap={2}>
          {[...catalogs, ...DICTIONARIES_BUTTONS].map(({ id, name, type }) => {
            const isChecked = buttons.includes(id);
            const isDisabled = buttons.length <= MIN_BUTTONS && isChecked;
            const Icon = getCatalogIcon(type);

            return (
              <Container
                key={id}
                justifyContent='space-between'
                style={CURSOR_STYLE}
                onClick={() => !isDisabled && handleSwitch(id)}
              >
                <Container alignItems='center'>
                  <Icon />
                  <Text truncate color='text.secondary' fontSize={4} mx={2}>
                    {name}
                  </Text>
                </Container>
                <Switch checked={isChecked} disabled={isDisabled} />
              </Container>
            );
          })}
        </Container>
      </ButtonSettingsPanel>
    );
  },
);

export { ButtonSettings };
