import { FC, Fragment } from 'react';

import { useHistory } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { SECONDARY_ITEMS_PER_PAGE } from 'common/constants/pagination.const';
import { INITIAL_DATA_REQUEST_V1 } from 'common/constants/request.const';
import { useQueryParams, useRequest } from 'common/hooks';
import { IProduct } from 'common/types/product.types';
import { IQueryParamsCollection } from 'common/types/request.types';
import { getImageURL } from 'common/utils';
import { CardInfo } from 'components/company';
import { DictionaryCardsWrapper } from 'components/dictionaries/styled';
import { CenteredPage } from 'layouts/Pages';
import { MatchesIcon } from 'resources/placeholders';
import { COMPANY, getPath, PRODUCTS } from 'routes';
import { Link } from 'tools/libs';
import {
  Avatar,
  Container,
  Pagination,
  Placeholder,
  SearchInput,
  Spinner,
  Text,
} from 'UI';

const NO_RESULTS_TEXT = (
  <Fragment>
    Совпадений не найдено.
    <br />
    Измените запрос и попробуйте снова.
  </Fragment>
);

const CardsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(300px, 1fr));
`;

const Card: FC<{ questionnaire: IProduct }> = ({
  questionnaire: { id, previewImage, name, company, type },
}) => {
  return (
    <DictionaryCardsWrapper
      as={Link}
      to={getPath(PRODUCTS.QUESTIONNAIRE_PAGE, { id })}
    >
      <CardInfo>
        <div>
          <Avatar cover full url={getImageURL(previewImage, { size: 'xl' })} />
          <Text
            color='text.primary'
            fontSize={5}
            fontWeight={600}
            mb={0}
            mt={2}
          >
            {name}
          </Text>
          <Text color='text.primary' fontSize={5} mb={2}>
            {type}
          </Text>
        </div>
        <Container
          alignItems='center'
          color='text.primary'
          display='inline-flex'
        >
          <Avatar
            as={Link}
            letter={company?.name}
            size='xxs'
            to={getPath(COMPANY.CARD, { id: company?.id })}
            url={getImageURL(company?.logo)}
          />
          <Text
            truncate
            as={Link}
            color='text.primary'
            pl={1}
            pr={2}
            to={getPath(COMPANY.CARD, { id: company?.id })}
            width='300px'
          >
            {company?.name}
          </Text>
        </Container>
      </CardInfo>
    </DictionaryCardsWrapper>
  );
};

const DictionariesCustomDevelopment: FC = () => {
  const theme = useTheme();
  const history = useHistory();

  const [queryParams, querySet] = useQueryParams({ page: Number });

  const {
    search = '',
    page = 1,
    itemsPerPage = 20,
    orderBy = 'name',
    orderDirection = 'ASC',
  }: IQueryParamsCollection = queryParams;

  const { data: { member, totalItems } = INITIAL_DATA_REQUEST_V1, isLoading } =
    useRequest(fetchProducts, [
      {
        search,
        lifeState: 'public',
        category: 'questionnaire',
        page,
        itemsPerPage,
        orderBy,
        orderDirection,
      },
    ]);

  const handleSearch = (value = ''): void => {
    history.push(
      querySet(params => ({
        ...params,
        page: 1,
        search: value,
        registeredOnly: true,
      })),
    );
  };

  return (
    <CenteredPage
      bg={theme.colors.white}
      heading={{
        heading: 'Каталог заказного производства',
        backLink: history.goBack,
        controls: (
          <SearchInput
            isBordered
            delay={700}
            initialValue={search}
            mb={0}
            placeholder='Название, тип оборудования, UIN'
            width='300px'
            onChange={handleSearch}
          />
        ),
      }}
      height={!isLoading && member.length > 0 ? 'max-content' : 'inherit'}
      maxWidth='1122px'
      minWidth='800px'
    >
      <Spinner delay={0} height='100%' loading={isLoading}>
        {member.length === 0 ? (
          <Placeholder hideInfo icon={MatchesIcon} text={NO_RESULTS_TEXT} />
        ) : (
          <CardsWrapper>
            {member.map(questionnaire => (
              <Card key={questionnaire.id} questionnaire={questionnaire} />
            ))}
          </CardsWrapper>
        )}
      </Spinner>
      {member.length < totalItems && member.length !== 0 && (
        <Pagination
          itemsPerPageOptions={SECONDARY_ITEMS_PER_PAGE}
          mt={2}
          totalItems={totalItems}
        />
      )}
    </CenteredPage>
  );
};

export { DictionariesCustomDevelopment };
