import { useEffect, memo, useRef, FC } from 'react';

import { UseComboboxReturnValue } from 'downshift';
import { useTheme } from 'styled-components';

import { fetchProductTypesOptions } from 'common/api/product-management';
import { EMPTY_ARRAY } from 'common/constants/emptyDataStructures.const';
import { useOutsideRef } from 'common/hooks';
import { ICommonSearchProductForm } from 'common/types/common-search';
import {
  IUrlForm,
  ISearchParams,
  SearchTypes,
} from 'common/types/search.types';
import { CloseMediumIcon } from 'resources/icons/18';
import { SearchLargeIcon } from 'resources/icons/24';
import { defaultItemToString } from 'tools/utils/stubFunctions';
import { Container, IconButton, Text } from 'UI';
import { Options } from 'UI/Inputs';

import { ProductParams } from './product-params';
import { SearchIconButton, SearchInput } from './styled';
import { DropdownItem } from '../joint/components';

const MAX_WIDTH = '1088px';

const dropdownAddon = (
  <DropdownItem>
    Укажите тип товара. Система подберет компании, которые производят товары
    такого типа, для дальнейшей фильтрации.
  </DropdownItem>
);

const itemToString = (item: string): JSX.Element => {
  return <Text truncate>{item}</Text>;
};

interface IDownshiftInputProps {
  isLoadingResults: boolean;
  isLoadingProductForms: boolean;
  isOpenFormsPanel: boolean;
  placeholder: string;
  productForms: ICommonSearchProductForm[];
  value: string;
  searchType: string;
  searchParameters: IUrlForm[] | undefined;
  formTypesMap?: string;
  downshift: UseComboboxReturnValue<any>;
  asyncState: any;
  dispatch: any;
  toggleFormsPanel: VoidFunction;
  onSearch: (params: ISearchParams) => void;
}

const DownshiftInput: FC<IDownshiftInputProps> = memo(
  ({
    isLoadingResults,
    isLoadingProductForms,
    isOpenFormsPanel,
    placeholder,
    productForms,
    value,
    searchType,
    searchParameters = EMPTY_ARRAY,
    formTypesMap,
    downshift,
    asyncState,
    dispatch,
    toggleFormsPanel,
    onSearch,
  }) => {
    const theme = useTheme();

    const searchContainerRef = useRef<HTMLDivElement>(null);
    const containerRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const {
      inputValue,
      highlightedIndex,
      getInputProps,
      getComboboxProps,
      setInputValue,
      closeMenu,
    } = downshift;

    const handleCloseFormFilters = (): void => {
      if (isOpenFormsPanel) toggleFormsPanel();
    };

    useOutsideRef({
      elementRef: searchContainerRef,
      onOutsideClick: handleCloseFormFilters,
    });

    useEffect(() => setInputValue(value), [setInputValue, value]);

    const handleEnterPress = ({ key }: { key: string }): void => {
      // run that handler if only there is no highlighted option in dropdown
      // otherwise - onSelectedItemChange
      if (key === 'Enter' && highlightedIndex < 0) {
        onSearch({ search: inputValue });
        closeMenu();
      }
    };

    const handleClearInput = (): void => {
      setInputValue('');
      closeMenu();

      inputRef.current?.focus();
    };

    return (
      <Container
        ref={searchContainerRef}
        column
        flexGrow={1}
        height='52px'
        maxWidth={MAX_WIDTH}
      >
        <Container
          {...getComboboxProps({ ref: containerRef })}
          alignItems='center'
          border={`2px solid ${theme.colors.primary.main}`}
          borderRadius={theme.borderRadius}
          height='52px'
          position='relative'
          width='100%'
        >
          <SearchInput
            {...getInputProps({
              ref: inputRef,
              placeholder,
              onKeyDown: handleEnterPress,
              onChange: handleCloseFormFilters,
              // Добавлена проверка по blur, так как у downshift не вызывается Blur при пустой строке
              onBlur: () => {
                if (!inputValue && value) setInputValue(value);
              },
            })}
          />
          {inputValue && (
            <IconButton mr='9px' title='Очистить' onClick={handleClearInput}>
              <CloseMediumIcon />
            </IconButton>
          )}
          <SearchIconButton onClick={() => onSearch({ search: inputValue })}>
            <SearchLargeIcon />
          </SearchIconButton>
          <Options
            anchorEl={containerRef.current}
            dispatch={dispatch}
            downshift={downshift}
            dropdownAddon={
              searchType === SearchTypes.manufacturer
                ? dropdownAddon
                : undefined
            }
            filteredItems={asyncState.items}
            hasPlaceholder={false}
            itemToKey={defaultItemToString}
            itemToString={itemToString}
            options={fetchProductTypesOptions}
            state={asyncState}
          />
        </Container>
        {productForms.length > 0 &&
          !(isLoadingResults || isLoadingProductForms) && (
            <ProductParams
              formTypesMap={formTypesMap}
              isLoadingProductForms={isLoadingProductForms}
              isOpenFormsPanel={isOpenFormsPanel}
              productForms={productForms}
              searchParameters={searchParameters}
              toggleFormsPanel={toggleFormsPanel}
              value={value}
              onSearch={onSearch}
            />
          )}
      </Container>
    );
  },
);

export { DownshiftInput };
