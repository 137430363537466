import { FC, Fragment, memo, useCallback, useState } from 'react';

import styled from 'styled-components';

import {
  createOrUpdatePointsOfSale,
  fetchPointsOfSale,
} from 'common/api/points-of-sale.api';
import { managePointsOfSale } from 'common/api/sales';
import { POINTS_OF_SALE_TYPES_NAME } from 'common/constants/points-of-sale.const';
import { useHint, useRequest, useSelect } from 'common/hooks';
import { ICompanyBase } from 'common/types/company.types';
import {
  IManufacturedGenericProductModal,
  SupplierEntity,
} from 'common/types/generic-product';
import {
  IPointsOfSale,
  PointOfSaleArchiveStatus,
  PointsOfSaleLifeStates,
  PointsOfSaleType,
} from 'common/types/point-of-sale.types';
import { IProduct } from 'common/types/product.types';
import { getPointsOfSaleByType } from 'common/utils/points-of-sale';
import {
  HINT_BY_ENTITY,
  LINK_PRODUCT_HINT,
  OPERATION_BY_ATTACHMENT,
  OPERATION_BY_ENTITY,
  TEXT_BY_ENTITY,
} from 'components/points-of-sale/constants';
import { getCompanyId, getIdentity } from 'entities/identity/store/selectors';
import { useNotify } from 'entities/notify';
import { SellerIcon } from 'resources/icons/18';
import { Link1pxIcon } from 'resources/icons/1px-18';
import { LinkIcon } from 'resources/modal';
import { MatchesIcon, PointsOfSaleIcon } from 'resources/placeholders';
import { StatusIcon } from 'resources/status';
import { COMPANY, getPath } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import { Checkbox, Container, Modal, Notice, Spinner, Text } from 'UI';

import { GeneratedBySystemStatus } from './PointOfSalesStatuses';
import { IManufacturedNTD } from '../../features/admin/types';

const modalIcon = <LinkIcon />;

const ExternalLinkIcon = styled(Link1pxIcon)`
  visibility: hidden;
`;

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 550px;

  &:hover ${ExternalLinkIcon} {
    visibility: visible;
  }
`;

const style = { height: 346 };

type IPointsOfSaleListData = [PointsOfSaleType | string, IPointsOfSale[]];

interface IPointsOfSaleListProps {
  isIndeterminate: boolean;
  isSelectedAll: boolean;
  data: IPointsOfSaleListData[];
  bindItem: (point: IPointsOfSale) => {
    isSelected: boolean;
    handleSelect: () => void;
  };
  handleSelectAll: (value: boolean) => void;
}

const PointsOfSaleList: FC<IPointsOfSaleListProps> = memo(
  ({ isIndeterminate, isSelectedAll, data, bindItem, handleSelectAll }) => {
    const pointsOfSale = data || [];

    if (pointsOfSale.every(([, points]) => points.length === 0)) {
      return (
        <Modal.Placeholder height={style.height} icon={<MatchesIcon />}>
          Совпадений не найдено.
          <br />
          Измените запрос и попробуйте снова.
        </Modal.Placeholder>
      );
    }

    return (
      <div style={style}>
        <Modal.ListItem pr='0px'>
          <Text fontWeight={600}>Выбрать все места продаж</Text>
          <Checkbox
            indeterminate={isIndeterminate}
            ml='auto'
            value={isSelectedAll}
            onChange={() => handleSelectAll(!isIndeterminate && !isSelectedAll)}
          />
        </Modal.ListItem>
        {pointsOfSale.map(([type, points]) => {
          if (points.length === 0) return null;
          return (
            <Fragment key={type}>
              <Container alignItems='center' display='inline-flex'>
                <StatusIcon color='text.disabled' />
                <Text
                  color='text.disabled'
                  fontSize={0}
                  fontWeight={500}
                  letterSpacing='0.5px'
                  ml={0}
                  textTransform='uppercase'
                >
                  {POINTS_OF_SALE_TYPES_NAME[type]}
                </Text>
              </Container>
              {points.map((point: IPointsOfSale) => {
                const { isSelected, handleSelect } = bindItem(point);
                const {
                  id,
                  name,
                  location,
                  isGeneratedBySystem,
                  ownershipCompany,
                } = point;
                const userCompanyId = useAppSelector(getCompanyId);
                const isMyPointOfSale = userCompanyId === ownershipCompany?.id;
                return (
                  // eslint-disable-next-line jsx-a11y/label-has-associated-control
                  <label key={id}>
                    <Modal.ListItem
                      pr='0px'
                      py={1}
                      selected={isSelected}
                      onClick={() => handleSelect}
                    >
                      <NameContainer>
                        <Container
                          as={Link}
                          display='inline-flex'
                          target='_blank'
                          to={getPath(COMPANY.POINTS_OF_SALE_PAGE, { id })}
                        >
                          <Text truncate color='text.primary' lineHeight='18px'>
                            {name}
                          </Text>
                          <ExternalLinkIcon ml={1} size={18} />
                        </Container>
                        {location && (
                          <Text truncate color='text.secondary' fontSize={2}>
                            {location.address}
                          </Text>
                        )}
                      </NameContainer>
                      <Container ml='auto'>
                        {isGeneratedBySystem && isMyPointOfSale && (
                          <GeneratedBySystemStatus />
                        )}
                        <Checkbox
                          ml='auto'
                          pl={1}
                          value={isSelected}
                          onChange={handleSelect}
                        />
                      </Container>
                    </Modal.ListItem>
                  </label>
                );
              })}
            </Fragment>
          );
        })}
      </div>
    );
  },
);

interface ILinkProductModalProps {
  isRetailer?: boolean;
  entity: SupplierEntity;
  onSubmit: VoidFunction;
  handleModalClose: VoidFunction;
  products?: IProduct[];
  manufacturedNtd?: IManufacturedNTD;
  manufacturedGenericProduct?: IManufacturedGenericProductModal;
  company?: ICompanyBase;
}
// TODO нужно отрефакторить компонент и сделать расширяемым в зависимости от типа entity
// сейчас вводить новые типы для модалки поставщиков очень больно и это порождаем много условий и доп. проверок
// задача затрагивает облать синей команды и красной
const PointsOfSaleModal: FC<ILinkProductModalProps> = memo(
  ({
    isRetailer,
    entity = SupplierEntity.products,
    products,
    manufacturedNtd,
    manufacturedGenericProduct,
    company,
    onSubmit,
    handleModalClose,
  }) => {
    const notify = useNotify();
    const companyId = useAppSelector(getCompanyId);
    const { isViewed, handleToggleViewed } = useHint(LINK_PRODUCT_HINT);
    const identity = useAppSelector(getIdentity);

    const [searchValue, setSearchValue] = useState('');

    const { company: currentCompany } = identity;
    const { mainDepartment } = currentCompany || {};

    const {
      isLoading,
      updateData,
      data: { member: points = [] as IPointsOfSale[] } = {},
    } = useRequest(fetchPointsOfSale, [
      {
        archiveStatus: PointOfSaleArchiveStatus.notArchived,
        searchValue,
        companyId,
      },
    ]);

    const {
      isIndeterminate,
      isSelectedAll,
      selected,
      bindItem,
      handleSelectAll,
    } = useSelect({
      options: points,
    });

    const pointsByType = getPointsOfSaleByType(points);

    const hasNoData =
      points.length === 0 && !isLoading && searchValue.length === 0;

    const isProduct = !!products;
    const isCompany = !!companyId;

    const handleLinkProduct = useCallback(async (): Promise<void> => {
      let manufacturedItems;

      if (entity === SupplierEntity.ntd) {
        manufacturedItems = [{ id: manufacturedNtd?.id }];
      } else if (entity === SupplierEntity.company) {
        manufacturedItems = [{ id: company?.id }];
      } else {
        manufacturedItems = [manufacturedGenericProduct];
      }

      try {
        await managePointsOfSale({
          id: selected.map(point => point.id)[0],
          operation: OPERATION_BY_ENTITY[entity],
          parameters: {
            pointsOfSale: selected.map(point => point.id),
            [OPERATION_BY_ATTACHMENT[OPERATION_BY_ENTITY[entity]]]: isProduct
              ? products?.map(product => product.id)
              : manufacturedItems,
          },
        });

        if (isProduct) {
          notify.success(
            products!.length > 1
              ? 'Товары привязаны к местам продаж'
              : 'Ваша компания стала поставщиком этого товара',
          );
        } else if (isCompany && entity === SupplierEntity.company) {
          notify.success(
            `Ваша компания стала поставщиком товаров компании ${company?.name}`,
          );
        } else {
          notify.success(
            isRetailer
              ? `Настройки поставки товаров по этому ${TEXT_BY_ENTITY[entity][1]} в производстве изменены`
              : `Ваша компания стала поставщиком товаров по этому ${TEXT_BY_ENTITY[entity][1]} в производстве`,
          );
        }

        handleModalClose();
        if (onSubmit) {
          onSubmit();
        }
      } catch (error) {
        notify.error(
          isProduct
            ? error.response?.data?.['hydra:description'] ||
                'Произошла ошибка, повторите попытку или обратитесь в службу поддержки.'
            : 'Произошла ошибка, повторите попытку или обратитесь в службу поддержки.',
        );
        handleModalClose();
      }
    }, [
      entity,
      manufacturedNtd,
      manufacturedGenericProduct,
      company,
      isProduct,
      products,
      selected,
      isCompany,
      handleModalClose,
      onSubmit,
      notify,
      isRetailer,
    ]);

    const handleCreatePointOfSale = useCallback(async (): Promise<void> => {
      const generatedPoint: IPointsOfSale = {
        name: currentCompany?.name || '',
        email: mainDepartment?.email,
        telephones: mainDepartment?.telephones[0]
          ? [
              {
                value: mainDepartment?.telephones[0]?.value || '',
                comment: mainDepartment?.telephones[0]?.comment || '',
                isMain: true,
              },
            ]
          : [],
        type: PointsOfSaleType.onlineStore,
        url: getPath(COMPANY.CARD, { id: companyId }),
        isArchived: false,
        location: null,
        isGeneratedBySystem: true,
        activityKinds: [],
        lifeState: PointsOfSaleLifeStates.public,
      };

      try {
        await createOrUpdatePointsOfSale(generatedPoint, true);
        notify.success('Место продаж сгенерировано');
        updateData();
      } catch (error) {
        notify.error(
          'Произошла ошибка, повторите попытку или обратитесь в службу поддержки',
        );
      }
    }, [companyId, currentCompany, mainDepartment, notify, updateData]);

    const handleSubmit = hasNoData
      ? handleCreatePointOfSale
      : handleLinkProduct;

    return (
      <Modal
        disabled={!hasNoData && selected.length === 0}
        hasSubmitOnEnter={false}
        size='large'
        onSubmit={handleSubmit}
      >
        <Modal.Default
          hint={HINT_BY_ENTITY[entity]}
          icon={modalIcon}
          paddingBottom={22}
          paddingTop={22}
          searchComponent={
            !hasNoData && (
              <Modal.Search
                placeholder='Введите название места продаж'
                value={searchValue}
                onChange={setSearchValue}
              />
            )
          }
          submitButton={hasNoData ? 'Сгенерировать' : 'Сохранить'}
          submitIcon={hasNoData && <SellerIcon color='white' mr={1} />}
          title='Привязать к местам продаж'
        >
          {hasNoData ? (
            <Modal.Placeholder height={406} icon={<PointsOfSaleIcon />}>
              {isCompany ? (
                <>
                  Чтобы стать поставщиком товаров компании, <br />
                  привяжите ее хотя бы к одному месту продаж.
                </>
              ) : (
                `Чтобы стать поставщиком, привяжите ${TEXT_BY_ENTITY[entity][0]}{' '}
                  хотя бы к одному месту продаж.`
              )}
              <br />У вашей компании еще нет мест продаж. Сгенерировать
              автоматически?
            </Modal.Placeholder>
          ) : (
            <Spinner delay={0} height={380} loading={isLoading}>
              {!isViewed && isProduct && (
                <Notice mb='0px' onClose={handleToggleViewed}>
                  Чтобы стать поставщиком, привяжите товар хотя бы к одному
                  месту продаж.
                </Notice>
              )}
              {!isProduct && !isRetailer && (
                <Notice mb='0px' onClose={handleToggleViewed}>
                  {isCompany
                    ? `Чтобы стать поставщиком, привяжите ${TEXT_BY_ENTITY[entity][0]} в производстве хотя бы к одному месту продаж.`
                    : 'Чтобы стать поставщиком всех товаров компании, а также всех будущих товаров, которые компания опубликует в системе от своего имени, привяжите ее хотя бы к одному месту продаж.'}
                </Notice>
              )}
              <PointsOfSaleList
                bindItem={bindItem}
                data={Object.entries(pointsByType)}
                handleSelectAll={handleSelectAll}
                isIndeterminate={isIndeterminate}
                isSelectedAll={isSelectedAll}
              />
            </Spinner>
          )}
        </Modal.Default>
      </Modal>
    );
  },
);

const LINK_TO_POINT_OF_SALES = 'LINK_TO_POINT_OF_SALES';

export { PointsOfSaleModal, LINK_TO_POINT_OF_SALES };
