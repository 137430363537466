import { FC } from 'react';

import styled, { css } from 'styled-components';
import {
  space,
  color as styledColor,
  SpaceProps,
  ColorProps,
} from 'styled-system';

import { IPropertyMultiColor } from 'common/types/product.types';
import { getRgbCode } from 'common/utils';
import { Text, Tooltip, StyledTag, Container } from 'UI';

const PREVIEW_SIZES = {
  s: 14,
  m: 20,
};

const ColorPreview = styled.div<{ size: 's' | 'm' } & SpaceProps & ColorProps>(
  ({ theme: { borderRadius }, size = 's' }) => css`
    display: inline-block;
    width: ${PREVIEW_SIZES[size]}px;
    min-width: ${PREVIEW_SIZES[size]}px;
    height: ${PREVIEW_SIZES[size]}px;
    border-radius: ${borderRadius}px;

    ${styledColor};
    ${space};
  `,
);

const ColorWrapper = styled.div`
  display: inline-flex;
  align-items: center;
`;

const ColorTag = styled(StyledTag)`
  display: inline-flex;
  align-items: center;
`;

interface IColorPropertyProps {
  extended?: boolean;
  property: IPropertyMultiColor;
}

const ColorProperty: FC<IColorPropertyProps> = ({
  extended = false,
  property: { value = [] },
}) => {
  if (value.length === 0) return <Text color='text.disabled'>Не указано</Text>;

  const Wrapper: typeof StyledTag = extended ? ColorTag : ColorWrapper;

  return (
    <Container flexWrap='wrap' style={{ gap: '2px' }}>
      {value.map(color => {
        const rgbCode = getRgbCode(color);

        return (
          <Wrapper key={rgbCode} size='m'>
            <Tooltip arrow hint title={extended ? undefined : color.name}>
              <ColorPreview
                backgroundColor={`rgb(${rgbCode})`}
                mr={extended ? undefined : 0}
                size={extended ? 's' : 'm'}
              />
            </Tooltip>
            {extended && (
              <Text truncate fontSize={3} ml={0} mr={1}>
                {color.name}
              </Text>
            )}
          </Wrapper>
        );
      })}
    </Container>
  );
};

export { ColorProperty };
