import { FC, MouseEvent, useCallback, useEffect, useMemo, useRef } from 'react';

import styled from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { useEducationActions } from 'entities/education/hooks';
import { getEducationStates } from 'entities/education/store';
import { useServicePanelActions } from 'entities/service-panel';
import { useAppSelector } from 'store';

import { EducationContext } from './hooks/useEducationContext';
import {
  EducationHintSidePanel,
  EducationOnboardingSidePanel,
} from './SidePanel';
import { EducationTooltip } from './Tooltip';
import { EducationTypeEnum, IEducation } from './types';
import { useEducation } from './useEducation';

const ChildrenWrapper = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  min-width: 0;
`;

const Education: FC<IEducation> = ({
  isLoading,
  children,
  data: points = [],
  onCompleteEducation,
  onSelectedPoint,
}) => {
  const { educationType, metaDataByPoint } = useAppSelector(getEducationStates);
  const { setEducationType, setPointMetaData, resetEducation } =
    useEducationActions();
  const childrenRef = useRef<HTMLDivElement>(null);

  const { setServicePanelCollapsed } = useServicePanelActions();

  const { getSelectedPoint, transformPointEvent } = useEducation({
    educationType,
    metaDataByPoint,
    points,
  });

  const setTransformPointByEvent = useCallback(
    event => {
      setPointMetaData(transformPointEvent(event));
    },
    [setPointMetaData, transformPointEvent],
  );

  const handleClosePanel = useCallback(() => {
    setPointMetaData(null);
  }, [setPointMetaData]);

  const handleComplete = useCallback(() => {
    setPointMetaData(null);
    if (educationType === EducationTypeEnum.onboarding && onCompleteEducation) {
      onCompleteEducation();
    }
    setEducationType(null);
  }, [educationType, onCompleteEducation, setEducationType, setPointMetaData]);

  const selectedPoint = getSelectedPoint();

  const memoValueProvider = useMemo(() => {
    return {
      points,
      closePanel: handleClosePanel,
      setTransformPointByEvent,
    };
  }, [points, handleClosePanel, setTransformPointByEvent]);

  useEffect(() => {
    return () => {
      resetEducation();
    };
  }, [resetEducation]);

  useEffect(() => {
    if (onSelectedPoint) {
      onSelectedPoint(selectedPoint);
    }
  }, [onSelectedPoint, selectedPoint]);

  useEffect(() => {
    if (educationType === EducationTypeEnum.onboarding) {
      setPointMetaData(0);
      setServicePanelCollapsed(true);
    }
  }, [educationType, points, setPointMetaData, setServicePanelCollapsed]);

  return (
    <EducationContext.Provider value={memoValueProvider}>
      <ChildrenWrapper ref={childrenRef}>{children}</ChildrenWrapper>
      {educationType === EducationTypeEnum.hints && (
        <EducationHintSidePanel
          childrenRef={childrenRef}
          point={selectedPoint}
          setTransformPoint={setPointMetaData}
        />
      )}
      {educationType === EducationTypeEnum.onboarding && (
        <EducationOnboardingSidePanel
          indexPoint={metaDataByPoint! as number}
          isLoading={isLoading}
          point={selectedPoint}
          setTransformPoint={setPointMetaData}
          stepCount={points.length - 2}
          onComplete={handleComplete!}
        />
      )}
      {educationType === EducationTypeEnum.tooltips && (
        <EducationTooltip
          currentEvent={metaDataByPoint as Nullable<MouseEvent<HTMLElement>>}
          point={selectedPoint}
          setTransformPoint={setPointMetaData}
        />
      )}
    </EducationContext.Provider>
  );
};

export { Education };
