import { FC, Fragment, useRef } from 'react';

import { useDropzone } from 'react-dropzone';
import styled, { css } from 'styled-components';

import { ModalController, useModalContext } from 'entities/modals';
import { useNotify } from 'entities/notify';
import { AddMediumIcon, CropIcon, EyeVisibleIcon } from 'resources/icons/18';

import { AvatarInputModalCropper } from './ModalCropper';
import { CircleProgress } from '../CircleProgress';
import { Tooltip } from '../Tooltip';

const MODAL_CROPPER = 'MODAL_CROPPER';

const Tile = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: ${colors.primary.main};
    border-radius: ${borderRadius}px;
    transition: 0.2s ease-in-out background-color;

    & svg {
      color: ${colors.white};
    }
  `,
);

const SettingTile = styled(Tile)(
  ({ theme: { colors } }) => css`
    background: ${colors.blackout};
    opacity: 0;
    transition: opacity 0.27s;
  `,
);

const ImageContainer = styled.div<{ rounded?: boolean; src?: string }>(
  ({ theme: { space, borderRadius }, rounded, src }) => css`
    position: relative;
    width: ${space[5]}px;
    min-width: ${space[5]}px;
    height: ${space[5]}px;
    margin: 2px;
    padding: 1px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: ${borderRadius}px;
    overflow: hidden;
    cursor: pointer;

    ${src
      ? `background-image: url('${src}')`
      : 'box-shadow: 0px 4px 8px rgba(77, 124, 254, 0.5)'};

    ${rounded &&
    css`
      border-radius: 50%;
    `}

    &:hover ${SettingTile} {
      opacity: 1;
    }
  `,
);

const ControlButton = styled.button.attrs(() => ({
  type: 'button',
}))(
  ({ theme: { space, colors, fontSizes } }) => css`
    width: 100%;
    height: ${space[5]}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    cursor: pointer;
    transition: 0.2s ease-in-out color;
  `,
);

const Container = styled.div<{
  rounded?: boolean;
  error?: boolean;
  disabled?: boolean;
  src?: string;
}>(
  ({
    theme: { colors, borderRadius, fontSizes },
    rounded,
    error,
    disabled,
    src,
  }) => css`
    display: inline-flex;
    position: relative;
    min-width: 213px;
    align-items: center;
    padding: 0;
    border: 1px solid ${error ? colors.error.main : colors.divider};
    border-radius: ${rounded ? 32 : borderRadius}px;
    background: none;

    &:hover {
      & ${ControlButton} {
        color: ${colors.text.primary};
      }

      & ${ImageContainer} {
        box-shadow: 0 0 0 transparent;
      }

      & ${Tile} {
        background: ${src ? colors.blackout : colors.primary.dark};
      }
    }

    &[data-requery='true']::after {
      content: '*';
      position: absolute;
      top: -4px;
      left: 217px;
      color: ${colors.error.disabled};
      font-size: ${fontSizes[3]}px;
      font-weight: 600;
    }

    ${error &&
    css`
      & ${ControlButton}, &:hover ${ControlButton} {
        color: ${colors.error.main};
      }
    `}

    ${disabled &&
    css`
      &,
      & > * {
        cursor: default;
      }

      & ${ControlButton}, &:hover ${ControlButton} {
        color: ${colors.text.disabled};
      }

      & ${ImageContainer} {
        box-shadow: 0 0 0 transparent;
      }

      & ${Tile}, &:hover ${Tile} {
        background: ${colors.divider};
      }
    `}
  `,
);

const circleStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
};

type CalculateAspectRatioFitType = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number,
) => { width: number; height: number };

const calculateAspectRatioFit: CalculateAspectRatioFitType = (
  srcWidth,
  srcHeight,
  maxWidth,
  maxHeight,
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

  return { width: srcWidth * ratio, height: srcHeight * ratio };
};

const AvatarInput: FC<any> = ({
  className,
  style,
  rounded,
  loading,
  disabled,
  previewMode,
  required,
  limitSize,
  placeholder = 'фото',
  initialCrop,
  previewSrc,
  src,
  error,
  uploadError,
  onChange,
}) => {
  const notify = useNotify();
  const loadedFileRef = useRef<File | null>(null);
  const { handleModalOpen, handleModalClose } = useModalContext();

  const cropTooltip = previewMode ? 'Предпросмотр' : 'Изменить миниатюру';

  const onCancel = (): void => {
    loadedFileRef.current = null;
    handleModalClose();
  };

  const handleSubmit = (crop): void => {
    onChange(loadedFileRef.current, crop);
    handleModalClose();
  };

  const handleOpenCropperModal = (file: File): void => {
    const objectURL = URL.createObjectURL(file);
    loadedFileRef.current = file;
    handleModalOpen(MODAL_CROPPER, {
      previewMode,
      src: objectURL,
      onCancel,
      handleSubmit,
      onReady: () => {
        URL.revokeObjectURL(objectURL);
      },
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    multiple: false,
    noClick: true,
    disabled,
    accept: ['image/png', 'image/jpeg', 'image/jpg', 'image/bmp'],
    onDrop: ([file]) => {
      if (!file) {
        notify.error('Допустимые форматы файла — JPG, PNG, BMP');
        return;
      }

      if (limitSize) {
        const { maxHeight, maxWidth } = limitSize;
        const img = new Image();
        img.src = URL.createObjectURL(file);

        img.onload = () => {
          const { width, height } = img;

          /**
           * Если есть ограничения по разрешению и разрешение загружаемой картинки больше ограничений, то
           * необходимо сжать пропорционально изображение, чтобы оно принимало допустимые размеры.
           */
          if (width > maxWidth || height > maxHeight) {
            const canvas = document.createElement('canvas');
            const { width: fitWidth, height: fitHeight } =
              calculateAspectRatioFit(width, height, maxWidth, maxHeight);
            canvas.width = fitWidth;
            canvas.height = fitHeight;
            const ctx = canvas.getContext('2d');
            ctx!.drawImage(img, 0, 0, fitWidth, fitHeight);
            canvas.toBlob(blob => {
              handleOpenCropperModal(new File([blob!], file.name));
              img.remove();
              URL.revokeObjectURL(img.src);
            });
          } else {
            img.remove();
            URL.revokeObjectURL(img.src);
            handleOpenCropperModal(file);
          }
        };
      } else {
        handleOpenCropperModal(file);
      }
    },
  });

  const getImage = (): null | JSX.Element => {
    if (disabled) return null;

    if (!src) {
      return (
        <Tile onClick={open}>
          <AddMediumIcon />
        </Tile>
      );
    }

    if (typeof loading === 'number') {
      return (
        <CircleProgress loading={loading} size={34} style={circleStyles} />
      );
    }

    return (
      <SettingTile
        onClick={() => {
          handleModalOpen(MODAL_CROPPER, {
            src,
            initialCrop,
            previewMode,
            onCancel,
            handleSubmit,
          });
        }}
      >
        {previewMode ? (
          <EyeVisibleIcon color='white' />
        ) : (
          <CropIcon color='white' />
        )}
      </SettingTile>
    );
  };

  const getText = (): string => {
    if (uploadError) return 'Ошибка при загрузке';

    if (src) return `Удалить ${placeholder}`;
    return `Загрузить ${placeholder}`;
  };

  return (
    <Fragment>
      <Tooltip arrow hint maxWidth='213' title={uploadError}>
        <Container
          data-requery={required}
          disabled={disabled}
          error={error || uploadError}
          rounded={rounded}
          src={src}
          {...getRootProps({
            className,
            style,
          })}
        >
          <Tooltip
            arrow
            hint
            maxWidth='213'
            title={!uploadError && src && !disabled && cropTooltip}
          >
            <ImageContainer rounded={rounded} src={previewSrc ?? src}>
              {getImage()}
            </ImageContainer>
          </Tooltip>
          <ControlButton
            onClick={() => {
              if (!disabled && src) {
                loadedFileRef.current = null;
                onChange(null, null);
              } else {
                open();
              }
            }}
          >
            <input aria-label='upload-image' {...getInputProps({ disabled })} />
            {getText()}
          </ControlButton>
        </Container>
      </Tooltip>
      <ModalController
        component={AvatarInputModalCropper}
        type={MODAL_CROPPER}
      />
    </Fragment>
  );
};

export { AvatarInput };
