import styled, { css } from 'styled-components';
import { space as styledSpace } from 'styled-system';

import { BaseInput } from '../../Inputs/BaseInput';
import { InputContainer } from '../../Inputs/components';
import themeDefault from '../../theme';

export const Container = styled(InputContainer)`
  position: relative;
  height: auto;
`;

export const FieldContainer = styled.div`
  display: flex;
`;

export const FormField = styled.div`
  width: 100%;

  ${styledSpace};
`;

export const LabelWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  word-break: normal;
`;

const ITEMS_HEIGHT = {
  s: 18,
  m: 34,
};

const SELECTED_ITEM_SIZES = {
  s: css`
    height: ${ITEMS_HEIGHT.s}px;
    line-height: ${ITEMS_HEIGHT.s}px;
    padding: 0 ${themeDefault.space[0]}px;
    font-size: ${themeDefault.fontSizes[3]}px;
  `,
  m: css`
    height: ${ITEMS_HEIGHT.m}px;
    line-height: ${ITEMS_HEIGHT.m}px;
    padding: 0 ${themeDefault.space[1]}px;
    font-size: ${themeDefault.fontSizes[4]}px;
  `,
};

export const Input = styled(BaseInput)<{ size?: 's' | 'm' }>(
  ({ size = 's' }) => css`
    width: initial;
    height: ${ITEMS_HEIGHT[size]}px;
    min-width: 70px;
    flex-grow: 1;
    padding: 0;
    margin: 2px 0;
  `,
);

export const SelectedItem = styled.div.attrs(() => ({
  role: 'menuitem',
}))<{
  disabled?: boolean;
  error?: boolean;
  size?: 's' | 'm';
}>(
  ({ theme: { space, colors, borderRadius }, disabled, error, size = 's' }) =>
    css`
      display: flex;
      align-items: center;
      min-width: 0;
      margin: 2px;
      border-radius: ${borderRadius}px;
      background: ${error ? colors.error.light : colors.background};
      color: ${error ? colors.error.main : colors.text.primary};

      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;

      & > button {
        color: ${error ? colors.error.main : colors.text.primary};
      }

      ${disabled &&
      css`
        color: ${colors.text.disabled};
      `}

      ${!disabled &&
      error &&
      css`
        color: ${colors.error.main};
        background: ${colors.error.light};

        & svg {
          color: ${colors.error.main};
        }
      `}

      & + ${Input} {
        margin-left: ${space[0]}px;
      }

      ${SELECTED_ITEM_SIZES[size]}
    `,
);

export const AddonWrapper = styled.div`
  display: flex;
  align-self: flex-end;
  flex-shrink: 0;
  padding: 2px;
`;

export const ValuesWrapper = styled.div<{ maxHeight?: number }>(
  ({ theme: { space }, maxHeight }) => css`
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    width: 100%;
    min-width: 0;
    padding: ${space[0]}px ${space[0]}px ${space[0]}px ${space[2]}px;

    ${maxHeight &&
    css`
      max-height: ${maxHeight}px;
      overflow: auto;
    `}

    ${styledSpace};
  `,
);
