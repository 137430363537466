import { createContext, FC, memo, ReactNode } from 'react';

import styledSystemPropTypes from '@styled-system/prop-types';
import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { pickOmit } from 'common/utils';
import { useToggle } from 'tools/hooks';

const ChildrenWrapper = styled.div`
  overflow: hidden;
  word-break: break-word;

  & > p {
    margin-top: 0;
  }
`;

const Container = styled.div<{ expanded: boolean; maxHeight: number }>(
  ({ expanded, maxHeight = 436 }) => css`
    margin-bottom: 100px;

    & > ${ChildrenWrapper} {
      max-height: ${maxHeight}px;
    }

    ${expanded &&
    css`
      & > ${ChildrenWrapper} {
        overflow: visible;
        max-height: none;
      }
    `}

    &:last-child {
      margin-bottom: 0;
    }

    ${styledSpace};
  `,
);

const ExpandButton = styled.button(
  ({ theme: { colors, fontSizes, space, borderRadius } }) => css`
    width: 100%;
    display: block;
    height: ${space[3]}px;
    margin-top: ${space[2]}px;
    line-height: ${space[3]}px;
    font-size: ${fontSizes[2]}px;
    background: ${colors.highlight[0]};
    color: ${colors.text.secondary};
    border-radius: ${borderRadius}px;
    text-align: center;
  `,
);

const ExpandableContext = createContext({});

const STYLED_PROPS = [...Object.keys(styledSystemPropTypes.space)];

interface IExpandableSectionProps extends SpaceProps {
  children: ReactNode;
  expandable?: boolean;
  maxHeight?: number;
  title?: string;
}

/**
 * ExpandableSection - раскрываемая секцию, которая может разворачиваться
 * или сворачиваться по клику на кнопку в зависимости от высоты контейнера. Секция может быть развернута или свернута с помощью флага `expandable`.
 * Если `expandable` равен `false`, секция всегда будет развернута.
 * Если секция больше `maxHeight`, появится кнопка разворачивания,
 * чтобы отобразить полный контент.
 *
 * @param {IExpandableSectionProps} props - Свойства для компонента ExpandableSection.
 * @returns {JSX.Element} - Возвращаемый элемент ExpandableSection.
 */
const ExpandableSection: FC<IExpandableSectionProps> = memo(props => {
  const [
    styledProps,
    { expandable, maxHeight, title = 'Показать все', children },
  ] = pickOmit(props, STYLED_PROPS);

  const [expanded, toggleExpand] = useToggle(false);

  return (
    <Container
      expanded={expanded || !expandable}
      maxHeight={maxHeight}
      {...styledProps}
    >
      <ExpandableContext.Provider
        value={{ handleExpandSection: () => toggleExpand(true) }}
      >
        {children}
      </ExpandableContext.Provider>
      {expandable && (
        <ExpandButton onClick={toggleExpand}>
          {expanded ? 'Свернуть' : title}
        </ExpandButton>
      )}
    </Container>
  );
});

const ExpandableSectionWithProps = Object.assign(ExpandableSection, {
  Wrapper: ChildrenWrapper,
  Context: ExpandableContext,
});

export { ExpandableSectionWithProps as ExpandableSection };
