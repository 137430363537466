import {
  FC,
  ReactNode,
  RefObject,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import styled, { css } from 'styled-components';
import {
  border,
  BorderProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { InfoIcon } from 'resources/icons/18';
import { NextMediumIcon, PrevMediumIcon } from 'resources/icons/18';
import { Tooltip } from 'UI';

const ScrollButton = styled.button(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${space[2]}px;
    height: ${space[5]}px;

    &:hover {
      background: ${colors.highlight[2]};
    }
  `,
);

const TabsContainer = styled.div<BorderProps>(
  ({ theme: { colors } }) => css`
    display: flex;
    border-top: 1px solid ${colors.highlight[2]};
    border-bottom: 1px solid ${colors.highlight[2]};

    ${styledSpace}
    ${border}
  `,
);

const TabList = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    justify-content: flex-start;
    flex-shrink: 1;
    flex-grow: 1;
    width: 90%;
    height: ${space[5]}px;
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);

const SCROLL_STEP = 300;

interface IScrollTabsByArrowsProps extends SpaceProps, BorderProps {
  tabListRef: RefObject<HTMLDivElement>;
  children: ReactNode;
  hint?: string;
}

/**
 * Компонент ScrollTabsByArrows - компонент для прокрутки вкладок с помощью стрелок.
 * @param {Object} props - Свойства компонента
 * @param {RefObject<HTMLDivElement>} props.tabListRef - Ссылка на контейнер с вкладками
 * @param {ReactNode} props.children - Дочерние элементы ToggleButton
 * @param {string} [props.hint] - Подсказка для компонента
 */
const ScrollTabsByArrows: FC<IScrollTabsByArrowsProps> = memo(
  ({ children, tabListRef, hint, ...other }) => {
    const [isScrollButtonsVisible, setScrollButtonsVisible] = useState(false);

    // Нужен для определения видимости кнопок прокрутки.
    // Происходит проверка ширины контента и видимой области контейнера.
    // Если контент шире, чем видимая область, кнопки прокрутки становятся видимыми,
    // иначе кнопки прокрутки скрываются.
    useEffect(() => {
      const { current: container } = tabListRef;

      // Проверка ширины блока при первом рендере
      if (container) {
        setScrollButtonsVisible(container.scrollWidth > container.clientWidth);
      }

      // Проверка ширины при ресайзе контейнера
      const handleResize = (): void => {
        if (container) {
          setScrollButtonsVisible(
            container.scrollWidth > container.clientWidth,
          );
        }
      };

      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [tabListRef]);

    const handleScrollLeft = useCallback((): void => {
      const { current: container } = tabListRef;

      if (!container) return;

      if (container.scrollLeft === 0) {
        container.scroll({ left: container.scrollWidth, behavior: 'smooth' });
        return;
      }

      container.scrollBy({ left: -SCROLL_STEP, behavior: 'smooth' });
    }, [tabListRef]);

    const handleScrollRight = useCallback((): void => {
      const { current: container } = tabListRef;

      if (!container) return;

      const scrolledDistance = container.scrollLeft + container.clientWidth;

      if (scrolledDistance === container.scrollWidth) {
        container.scroll({ left: 0, behavior: 'smooth' });
        return;
      }

      container.scrollBy({ left: SCROLL_STEP, behavior: 'smooth' });
    }, [tabListRef]);

    return (
      <TabsContainer {...other}>
        {hint && (
          <Tooltip arrow hint maxWidth={290} placement='right' title={hint}>
            <InfoIcon color='primary.main' p='14px' size={18} />
          </Tooltip>
        )}
        {isScrollButtonsVisible && (
          <ScrollButton onClick={handleScrollLeft}>
            <PrevMediumIcon size={12} />
          </ScrollButton>
        )}
        <TabList ref={tabListRef}>{children}</TabList>
        {isScrollButtonsVisible && (
          <ScrollButton onClick={handleScrollRight}>
            <NextMediumIcon size={12} />
          </ScrollButton>
        )}
      </TabsContainer>
    );
  },
);

export { ScrollTabsByArrows };
