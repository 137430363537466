import {
  cloneElement,
  CSSProperties,
  forwardRef,
  Fragment,
  MouseEvent,
  ReactElement,
  useCallback,
  useMemo,
  useRef,
  useState,
} from 'react';

import { createPopper, Instance } from '@popperjs/core';
import styled, { css } from 'styled-components';

import { useAppSelector } from 'store';
import { useForkRef } from 'tools/hooks';
import { Tooltip } from 'UI';
import { pulsePrimary } from 'UI/Buttons/ButtonFloating';

import { EDUCATION_Z_INDEX } from './styled';
import { ONBOARDING } from '../constants';
import { useEducationContext } from '../hooks';
import { getEducationStates } from '../store';
import { IEducationPoint, EducationTypeEnum } from '../types';
import { useEducation } from '../useEducation';

type ConditionalSection = {
  section: string;
  condition?: boolean;
  style?: CSSProperties;
};
type SectionProps =
  | string
  | ConditionalSection
  | (ConditionalSection | string)[];

const PointContainer = styled.div`
  position: absolute;
`;

const StyledPoint = styled.div<{
  active: boolean;
  fixedSize?: boolean;
  zIndex?: number | string;
}>(
  ({ theme: { colors, space }, active, fixedSize, zIndex }) =>
    css`
      width: ${space[2]}px;
      height: ${space[2]}px;
      background: ${colors.text.disabled};
      color: ${colors.text.primary};
      border-radius: 50%;
      border: 2px solid ${colors.white};

      ${zIndex &&
      css`
        z-index: ${zIndex};
      `}

      ${active &&
      css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
        background: ${colors.primary.main};
        color: ${colors.white};
        font-weight: 600;
        animation: ${pulsePrimary} 2s infinite;

        ${fixedSize &&
        css`
          width: ${space[2]}px;
          height: ${space[2]}px;
        `}
      `}
    `,
);

interface IHintPointProps {
  index?: number;
  title: string;
  isActive: boolean;
  onClick: (event: MouseEvent<HTMLElement>) => void;
}

const HintPoint = forwardRef<HTMLButtonElement, IHintPointProps>(
  ({ index, title, isActive, onClick }, ref) => {
    return (
      <Tooltip arrow hint title={title}>
        <StyledPoint
          ref={ref}
          fixedSize
          active={isActive}
          as='button'
          data-index={index}
          zIndex={EDUCATION_Z_INDEX.POINTER[EducationTypeEnum.hints]}
          onClick={onClick}
        />
      </Tooltip>
    );
  },
);

interface IOnboardingPointProps {
  isActive: boolean;
  index?: number;
  section: string;
}

const OnboardingPoint = forwardRef<HTMLDivElement, IOnboardingPointProps>(
  ({ index, section, isActive }, ref) => {
    return (
      <StyledPoint
        ref={ref}
        active={isActive}
        data-index={index}
        id={`${ONBOARDING}-${section}`}
        zIndex={EDUCATION_Z_INDEX.POINTER[EducationTypeEnum.onboarding]}
      >
        {isActive && index}
      </StyledPoint>
    );
  },
);

const EDUCATION_POINTS = {
  [EducationTypeEnum.onboarding]: OnboardingPoint,
  [EducationTypeEnum.hints]: HintPoint,
  [EducationTypeEnum.tooltips]: HintPoint,
};

interface IEducationPointProps {
  style?: object;
  section: SectionProps;
  children: ReactElement;
}

/*
 * Относится к обучению, к режиму подсказок и тултипам в моих товарах
 * */
function EducationPoint({
  style = {},
  section,
  children,
}: IEducationPointProps): JSX.Element {
  const { educationType, metaDataByPoint } = useAppSelector(getEducationStates);
  const [parent, setParent] = useState();
  const parentRef = useRef(parent);
  parentRef.current = parent;

  const { points, setTransformPointByEvent } = useEducationContext();

  const { getSelectedPoint } = useEducation({
    educationType,
    metaDataByPoint,
    points,
  });

  const handleInitPointPopper = useCallback(
    ({ placement, offsetX, offsetY }) => {
      let popper: Instance | null = null;

      return pointNode => {
        if (pointNode) {
          if (parentRef.current) {
            popper = createPopper(parentRef.current, pointNode, {
              placement: placement ?? 'left-start',
              modifiers: [
                {
                  name: 'offset',
                  options: {
                    offset: [offsetY ?? 0, offsetX ?? 0],
                  },
                },
                {
                  name: 'preventOverflow',
                  options: {
                    mainAxis: false,
                    tether: false,
                  },
                },
                {
                  name: 'flip',
                  options: {
                    mainAxis: false,
                    flipVariations: false,
                  },
                },
              ],
            });
          }
        } else if (popper) {
          popper.destroy();
        }
      };
    },
    [],
  );

  const currentPoints = useMemo(() => {
    if (!section) return [];

    const sections = Array.isArray(section) ? section : [section];

    return sections
      .map(item => {
        const {
          name,
          condition,
          style: stylePoint,
        } = typeof item === 'string'
          ? { name: item, condition: true, style: {} }
          : {
              name: item.section,
              condition: item.condition ?? true,
              style: item.style,
            };

        if (!condition) return null;

        const index = points.findIndex(point => point.section === name);

        return index >= 0 && { ...points[index], index, style: stylePoint };
      })
      .filter(Boolean) as IEducationPoint[];
  }, [points, section]);

  // @ts-ignore
  const unionRef = useForkRef(children?.ref, setParent);

  if (!educationType || currentPoints.length === 0 || !children) {
    return children;
  }

  const Point = EDUCATION_POINTS[educationType];

  const selectedPoint = getSelectedPoint();

  return (
    <Fragment>
      {cloneElement(children, {
        ref: unionRef,
      })}
      {parent &&
        currentPoints.map(
          ({
            section: pointSection,
            title,
            placement,
            offsetX,
            offsetY,
            index,
            style: styleContainerPoint,
          }) => (
            <PointContainer
              key={pointSection}
              style={{ margin: 0, ...style, ...styleContainerPoint }}
            >
              <Point
                ref={handleInitPointPopper({
                  placement,
                  offsetX,
                  offsetY,
                })}
                index={index}
                isActive={selectedPoint?.section === pointSection}
                section={pointSection}
                title={title}
                onClick={setTransformPointByEvent}
              />
            </PointContainer>
          ),
        )}
    </Fragment>
  );
}

export { EducationPoint };
