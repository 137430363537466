import { forwardRef, Fragment, useRef, CSSProperties, MouseEvent } from 'react';

import styledSystemPropTypes from '@styled-system/prop-types';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { flexbox, layout, space as styledSpace } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { pickOmit } from 'common/utils';
import { AddSmallIcon } from 'resources/icons/12';

import { Container } from '../Blocks';
import { StatusTypes, Status } from '../Status/Status';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

const SPACE_PROPS = Object.keys(styledSystemPropTypes.space);

const Input = styled.input`
  display: none;
`;

const SelectIconWrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 18px;
    height: 18px;
    border: 1px solid ${colors.divider};
    border-radius: 50%;
    margin-left: ${space[1]}px;
  `,
);

const DefaultLabelButton = styled.label<{ size: string }>(
  ({ theme: { space, colors, fontSizes }, size }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: ${size === 's' ? space[3] : space[4]}px;
    margin-right: ${space[0]}px;
    padding: 0 ${space[2]}px;
    color: ${colors.text.primary};
    background: transparent;
    border-radius: 18px;
    border: 1px solid ${colors.divider};
    font-size: ${size === 's' ? fontSizes[0] : fontSizes[3]}px;
    transition: all 0.2s ease-in-out;
    overflow: hidden;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background: ${colors.highlight[2]};
      border-color: transparent;
    }

    ${Input}:checked + & {
      background: ${colors.highlight[2]};
      border-color: transparent;
      cursor: default;
    }

    ${Input}:disabled + & {
      color: ${colors.text.disabled};
      cursor: default;
      pointer-events: none;
    }

    &:last-child {
      margin-right: 0;
    }

    ${styledSpace};
  `,
);

const MultipleLabelButton = styled.label<{ size: string }>(
  ({ theme: { space, colors, fontSizes }, size }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: ${size === 's' ? space[3] : space[4]}px;
    padding: 0 ${space[1]}px 0 ${space[2]}px;
    margin: 0 ${space[1]}px ${space[1]}px 0;
    border-radius: 60px;
    background: ${colors.highlight[0]};
    font-size: ${size === 's' ? fontSizes[0] : fontSizes[3]}px;
    color: ${colors.text.primary};
    cursor: pointer;
    transition-property: background-color, color, border-color;
    transition-duration: 0.1s, 0.1s, 0.1s;
    transition-timing-function: ease-in-out, ease-in-out, ease-in-out;

    &:hover {
      background: ${colors.highlight[2]};

      svg {
        color: ${colors.text.primary};
      }
    }

    ${Input}:checked + & {
      background: ${colors.text.secondary};
      border-color: ${colors.text.secondary};
      color: ${colors.white};
      svg {
        color: ${colors.white};
      }
    }

    ${Input}:disabled + & {
      color: ${colors.text.disabled};
      box-shadow: 0 0 0 transparent;
      cursor: default;

      ${SelectIconWrapper} svg {
        color: ${colors.text.disabled};
      }
    }

    ${Input}:disabled:checked + & {
      color: ${colors.text.disabled};
      background: ${colors.divider};
    }
  `,
);

const ToggleTabsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    margin-bottom: ${space[2]}px;

    ${styledSpace}
    ${flexbox}
    ${layout}
  `,
);
interface IStyledToggleTab {
  checked?: boolean;
  className?: string;
  disabled?: boolean;
  role: string;
  secondary?: boolean;
  uncheck?: boolean;
  style?: CSSProperties;
  type?: string;
  onClick(event: MouseEvent<HTMLButtonElement>);
}

const StyledToggleTab = styled.button<IStyledToggleTab>(
  ({
    theme: { colors, space, fontSizes },
    secondary,
    checked,
    disabled,
    uncheck,
  }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    height: ${space[4]}px;
    margin-right: ${space[0]}px;
    margin-bottom: ${space[0]}px;
    padding: ${space[1]}px ${space[2]}px ${space[1]}px 12px;
    color: ${colors.text.primary};
    font-size: ${fontSizes[3]}px;
    border-radius: 18px;
    border: 1px solid ${colors.divider};
    cursor: pointer;
    transition: background-color ease-in-out 200ms,
      border-color ease-in-out 200ms;

    ${secondary &&
    css`
      border-color: transparent;
      background: ${colors.highlight[0]};
    `}

    &:hover {
      background: ${colors.highlight[2]};
      border-color: transparent;

      & svg {
        color: ${colors.text.primary};
      }
    }

    & svg {
      color: ${colors.text.secondary};
    }

    ${disabled &&
    css`
      color: ${colors.text.disabled};
      cursor: default;

      & svg {
        color: ${colors.text.disabled};
      }

      &:hover {
        background: transparent;
        border-color: ${colors.divider};

        & svg {
          color: ${colors.text.disabled};
        }
      }
    `}

    ${checked &&
    css`
      background: ${colors.highlight[2]};
      border-color: transparent;
      cursor: default;

      ${secondary &&
      css`
        background: ${colors.text.secondary};
        color: ${colors.white};

        &:hover {
          background: ${colors.text.secondary};
          color: ${colors.white};
        }
      `}

      ${disabled &&
      css`
        &:hover {
          background: ${colors.highlight[2]};
          border-color: transparent;
        }
      `}

      ${uncheck &&
      css`
        cursor: pointer;
      `}
    `}

    ${styledSpace}
    ${layout}
    ${flexbox}
  `,
);

const ToggleButton = ({
  className,
  style,
  multiple,
  checked,
  disabled,
  size,
  label,
  hint,
  name,
  leftAddon,
  rightAddon,
  icon,
  onClick,
  fontWeight = 500,
  ...other
}): JSX.Element => {
  const id = useRef(uuid());
  const [spaceProps, props] = pickOmit(other, SPACE_PROPS);

  if (multiple) {
    return (
      <Fragment>
        <Input
          checked={checked}
          disabled={disabled}
          id={id.current}
          name={name}
          role='checkbox'
          type='checkbox'
          onClick={onClick}
          {...props}
        />
        <MultipleLabelButton
          className={className}
          htmlFor={id.current}
          size={size}
          style={style}
          {...spaceProps}
        >
          {label}
          {icon}
          {checked ? (
            <Status
              ml={1}
              type={
                disabled
                  ? StatusTypes.SUCCESS_DISABLED
                  : StatusTypes.SUCCESS_STATUS
              }
            />
          ) : (
            <SelectIconWrapper>
              <AddSmallIcon />
            </SelectIconWrapper>
          )}
        </MultipleLabelButton>
      </Fragment>
    );
  }

  return (
    <Tooltip hint pointer placement='bottom' title={hint}>
      <Container display='inline-flex' {...spaceProps}>
        <Input
          checked={checked}
          disabled={disabled}
          id={id.current}
          name={name}
          role='radio'
          type='radio'
          onClick={onClick}
          {...props}
        />
        <DefaultLabelButton
          className={className}
          htmlFor={id.current}
          size={size}
          style={style}
        >
          {leftAddon}
          <Text truncate fontWeight={fontWeight}>
            {label}
          </Text>
          {icon}
          {rightAddon}
        </DefaultLabelButton>
      </Container>
    </Tooltip>
  );
};

interface IToggleTab {
  className?: string;
  style?: CSSProperties;
  secondary?: boolean;
  truncate?: boolean;
  disabled?: boolean;
  checked?: boolean;
  uncheck?: boolean;
  label?: string;
  icon?: JSX.Element;
  rightAddon?: JSX.Element;
  onClick(event: MouseEvent<HTMLButtonElement>);
  tooltip?: string;
}

const ToggleTab = forwardRef<HTMLButtonElement, IToggleTab>(
  (
    {
      className,
      style,
      secondary,
      truncate,
      disabled,
      checked,
      uncheck,
      label,
      icon,
      rightAddon,
      onClick,
      tooltip,
      ...other
    },
    ref,
  ): JSX.Element => {
    const toggleTab = (
      <StyledToggleTab
        ref={ref}
        checked={checked}
        className={className}
        disabled={disabled}
        role='button'
        secondary={secondary}
        style={style}
        type='button'
        uncheck={uncheck}
        onClick={onClick}
        {...other}
      >
        {icon}
        <Text ml={icon && 1} truncate={truncate}>
          {label}
        </Text>
        {rightAddon}
      </StyledToggleTab>
    );

    if (tooltip) {
      return (
        <Tooltip arrow hint title={tooltip}>
          <div>{toggleTab}</div>
        </Tooltip>
      );
    }

    return toggleTab;
  },
);

ToggleButton.Tab = ToggleTab;
ToggleButton.Wrapper = ToggleTabsWrapper;

ToggleButton.defaultProps = {
  multiple: false,
  disabled: false,
};

ToggleButton.propTypes = {
  /**
   * The ability to select multiple buttons
   */
  multiple: PropTypes.bool,
  /**
   * State for checked element
   */
  checked: PropTypes.bool,
  /**
   * Disabled status
   */
  disabled: PropTypes.bool,
  /**
   * Height 34 for 'xs' and 46 for other
   */
  size: PropTypes.oneOf(['s', 'm']),
  /**
   * Input label
   */
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  /**
   * Display arrow
   */
  name: PropTypes.string,
  /**
   * Icon on the left
   */
  icon: PropTypes.elementType,
};

export { ToggleButton };
