import styled, { css } from 'styled-components';

import { DictionaryManufacturerItem } from 'components/dictionaries/styled';
import { Link } from 'tools/libs';
import { IconButton, Text } from 'UI';

export const ManufacturerName = styled(Text).attrs(() => ({
  truncate: true,
  as: Link,
}))(
  ({ theme: { colors } }) => css`
    display: block;
    color: ${colors.text.primary};
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    &:hover {
      color: ${colors.text.primary};
    }
  `,
);

export const ExternalLink = styled(IconButton)(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: none;
    align-items: center;
    margin-left: auto;
    padding: ${space[0]}px;
    border-radius: ${borderRadius}px;

    &:hover {
      background: ${colors.highlight[0]};
    }

    ${DictionaryManufacturerItem}:hover & {
      display: flex;
    }
  `,
);
