import { Fragment, useCallback, useMemo, useRef, FC } from 'react';

import { UseComboboxReturnValue } from 'downshift';
import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { fetchMeasurementUnitsByPhysicalQuantities } from 'common/api/dictionaries.api';
import { useUnit } from 'common/hooks';
import { Nullable } from 'common/types/common.types';
import { PhysicalQuantities } from 'common/types/dictionaries.types';
import { IProduct, ObjectType } from 'common/types/product.types';
import { IApplicationPlaces } from 'common/types/product.types/accompany-information.types';
import { getProductFullName } from 'common/utils/products';
import { isValidObjectValue } from 'common/utils/products/linked-object';
import { useSparePartKitContext } from 'components/accompany-information/spare-part-kit/form/components';
import { SimpleLinkedObject } from 'components/products/linked-object';
import { MainProperties } from 'components/products/properties';
import { useField, useWatch } from 'reform';
import { AddSmallIcon, LinkSmallIcon } from 'resources/icons/12';
import { TrashIcon } from 'resources/icons/18';
import { PalletIcon } from 'resources/other-28';
import { removeItem } from 'tools/utils';
import {
  Text,
  Button,
  Select,
  Tooltip,
  Container,
  FormLabel,
  IconButton,
  NumericInput,
  UnionContainer,
} from 'UI';
import { FetchOptionsType, ItemToStringAndOptionsType } from 'UI/Inputs';

import { generateApplicationPlaceOptionId } from '../../../utils';

const LIMIT = 10;
const LINK_TIP = 'Связано с товаром из состава';
const TO_WHOLE_PRODUCT = 'Ко всему изделию';
const PLACEHOLDER = 'Нет добавленных товаров в блоке «Состав»';
const TIP =
  'Место применения описывает, для обслуживания какого узла предназначен добавляемый элемент. В качестве узла может выступать изделие целиком.';

type CompositionOptionType = {
  reference: IProduct;
  designations: string[];
  id: string;
  isProduct?: boolean;
};

const HeaderGrid = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: auto ${space[2]}px 247px ${space[1]}px 32px;
    margin-bottom: ${space[1]}px;
  `,
);

const GridContainer = styled.div<{ hasQuantity?: boolean }>(
  ({ theme: { space }, hasQuantity }) => css`
    display: grid;
    grid-template-columns: auto ${space[1]}px ${space[4]}px;
    grid-template-rows: minmax(34px, auto);
    grid-row-gap: ${space[0]}px;

    ${hasQuantity &&
    css`
      grid-template-columns: auto ${space[2]}px 247px ${space[1]}px ${space[4]}px;
    `}
  `,
);

const StyledIconButton = styled(IconButton).attrs(() => ({ size: 28 }))(
  ({ theme: { colors } }) => css`
    background: ${colors.highlight[0]};
  `,
);

const resetErrorOnSimpleChangeValue = ({
  prevValue,
  nextValue,
  prevError,
}): Nullable<Record<string, string>> => {
  if (!prevError) return null;

  const nextErrors = Object.entries(prevError).reduce((acc, [key, value]) => {
    if (prevValue[key] !== nextValue[key]) {
      return acc;
    }

    return { ...acc, [key]: value };
  }, {});

  return Object.keys(nextErrors).length === 0 ? null : nextErrors;
};

const fetchUnitOptions: FetchOptionsType =
  fetchMeasurementUnitsByPhysicalQuantities([
    PhysicalQuantities.weight,
    PhysicalQuantities.length,
    PhysicalQuantities.volume,
    PhysicalQuantities.quantity,
  ]);

const compositionOptionToString: ItemToStringAndOptionsType<
  string | CompositionOptionType
> = (item, option) => {
  if (typeof item === 'string') {
    return item;
  }

  const { reference, designations } = item;
  const itemName = getProductFullName(reference);

  if (!option) return itemName;

  return (
    <Container alignItems='center' height='100%'>
      <Container column minWidth={0} mr={1}>
        <Text truncate lineHeight='18px'>
          {itemName}
        </Text>
        <Text
          truncate
          color={
            designations?.length || item.isProduct
              ? 'text.secondary'
              : 'text.disabled'
          }
          fontSize={2}
          lineHeight='16px'
        >
          {item.isProduct
            ? TO_WHOLE_PRODUCT
            : designations?.join(', ') ||
              'Обозначение на схеме отсутствует'}
        </Text>
      </Container>
      <MainProperties
        disabled={option.disabled}
        ml='auto'
        product={{ ...reference, mainProperties: [] }}
      />
    </Container>
  );
};

const checkIsDisabled = (
  item: CompositionOptionType,
  applicationPlaces: IApplicationPlaces[],
): boolean => {
  if (
    item.isProduct &&
    applicationPlaces.some(
      place => place.object.type === ObjectType.entireEntity,
    )
  ) {
    return true;
  }

  return applicationPlaces
    .filter(place => place.object.type === ObjectType.reference)
    .some(
      place =>
        item.id ===
        generateApplicationPlaceOptionId({
          id: (place.object.value as IProduct).id,
          designations: place.designations,
        }),
    );
};

interface IApplicationPlaceProps {
  id: string;
  name: string;
  parentName: string;
  hasQuantity?: boolean;
  removeDisabled: boolean;
  onRemove: VoidFunction;
  onSetUnit: (newUnit: string) => void;
}

const ApplicationPlace: FC<IApplicationPlaceProps> = ({
  id: key,
  name,
  parentName,
  hasQuantity,
  removeDisabled,
  onRemove,
  onSetUnit,
}) => {
  const controlRef = useRef<Nullable<UseComboboxReturnValue<IProduct>>>(null);
  const { unitToString } = useUnit();
  const { value, error, setValue } = useField({
    key,
    name,
    persistError: resetErrorOnSimpleChangeValue,
  });
  const { applicationPlaceOptions } = useSparePartKitContext();
  const allApplicationPlaces = useWatch({ fieldName: parentName });

  const handleChangeQuantity = ({ target }): void => {
    setValue(prev => ({ ...prev, quantity: target.value }));
  };

  const handleChangeUnit = (newValue): void => {
    setValue(prev => ({ ...prev, unit: newValue }));

    if (newValue) onSetUnit(newValue);
  };

  /**
   * Т.к. структура объекта в options отличается от требуемой, производим нужные трансформации
   */
  const handleChangeObject = ({ type }): void => {
    if (type === ObjectType.reference) {
      setValue(prev => ({
        ...prev,
        object: { ...prev.object, value: prev.object.value.reference },
        designations: prev.object.value.designations,
      }));
    } else {
      setValue(prev => ({
        ...prev,
        designations: [],
      }));
    }
  };

  /**
   * Определяем rightAddon в зависимости от текущего value
   */
  const rightAddon = useMemo(() => {
    if (value.object.value) return undefined;

    return (
      <StyledIconButton
        size={28}
        onClick={() => controlRef.current?.toggleMenu()}
      >
        <PalletIcon />
      </StyledIconButton>
    );
  }, [value.object.value]);

  /**
   * Отрабатывает, когда выбран товар из состава (object.type === reference)
   */
  const selectedObjectToString = useCallback(selectedObject => {
    return (
      <>
        <Text truncate mr={1} pl={2}>
          {getProductFullName(selectedObject)}
        </Text>
        <Tooltip hint title={LINK_TIP}>
          <LinkSmallIcon color='primary.main' ml='auto' mr={1} />
        </Tooltip>
      </>
    );
  }, []);

  const handleCheckIsDisabled = useCallback(
    item => {
      return checkIsDisabled(item, allApplicationPlaces);
    },
    [allApplicationPlaces],
  );

  return (
    <Fragment>
      <SimpleLinkedObject
        allowContentSearch
        canError
        controlRef={controlRef}
        disableItem={handleCheckIsDisabled}
        dropdownPlaceholder={
          applicationPlaceOptions.length > 0 ? undefined : PLACEHOLDER
        }
        heightItem={46}
        name={`${name}.object`}
        objectToString={compositionOptionToString}
        options={applicationPlaceOptions}
        rightAddon={rightAddon}
        selectedObjectToString={selectedObjectToString}
        onChange={handleChangeObject}
      />
      {hasQuantity && (
        <Fragment>
          <div />
          <UnionContainer>
            <UnionContainer.Row>
              <NumericInput
                decimalScale={2}
                error={error?.quantity}
                placeholder='Количество'
                value={value.quantity}
                onChange={handleChangeQuantity}
              />
              <Select
                error={error?.unit}
                itemToString={unitToString}
                options={fetchUnitOptions}
                placeholder='Ед. изм.'
                value={value.unit}
                width={140}
                onChange={handleChangeUnit}
              />
            </UnionContainer.Row>
          </UnionContainer>
        </Fragment>
      )}
      <div />
      <IconButton disabled={removeDisabled} onClick={onRemove}>
        <TrashIcon />
      </IconButton>
    </Fragment>
  );
};

interface IApplicationPlacesProps {
  hasQuantity?: boolean;
}

const ApplicationPlaces: FC<IApplicationPlacesProps> = ({ hasQuantity }) => {
  const fieldName = 'applicationPlaces';
  const {
    form: { setFieldValue },
    value,
    setValue,
  } = useField({
    name: fieldName,
  });
  const unitRef = useRef<string>(value[0].unit);

  const disabledAddButton = useWatch({
    fieldName,
    selector: applicationPlaces => {
      const isEmptyOrFillSpare =
        applicationPlaces.length === 0 ||
        applicationPlaces.every(
          ({ object, quantity, unit }) =>
            isValidObjectValue({ object }) &&
            (!hasQuantity || (!!quantity && !!unit)),
        );

      return !isEmptyOrFillSpare;
    },
  });

  const handleAddPlace = (): void => {
    const initialValue = {
      id: uuid(),
      object: { type: 'text', value: '' },
      ...(hasQuantity && { quantity: '', unit: unitRef.current }),
      designations: [],
    };
    setValue(prev => [...prev, initialValue]);
  };

  const handleRemovePlace = index => () => {
    setValue(prev => removeItem(prev, index));
  };

  const handleSetUnitToAllPlaces = (newUnit): void => {
    value.forEach((_, index) => {
      setFieldValue(`${fieldName}.${index}`, prev => ({
        ...prev,
        unit: newUnit,
      }));
    });
    unitRef.current = newUnit;
  };

  return (
    <Fragment>
      <HeaderGrid>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
        <FormLabel required fontSize={2} fontWeight={600} mb='0px' tip={TIP}>
          Место применения
        </FormLabel>
        {hasQuantity && (
          <Fragment>
            <span />
            {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
            <FormLabel required fontSize={2} fontWeight={600} mb='0px'>
              Количество в изделии
            </FormLabel>
          </Fragment>
        )}
      </HeaderGrid>
      <GridContainer hasQuantity={hasQuantity}>
        {value.map((item, index) => (
          <ApplicationPlace
            key={item.id}
            hasQuantity={hasQuantity}
            id={item.id}
            name={`${fieldName}.${index}`}
            parentName={fieldName}
            removeDisabled={value.length === 1}
            onRemove={handleRemovePlace(index)}
            onSetUnit={handleSetUnitToAllPlaces}
          />
        ))}
      </GridContainer>
      {value.length < LIMIT && (
        <div>
          <Button
            color='secondary'
            disabled={disabledAddButton}
            mt={0}
            size='s'
            variant='text'
            onClick={handleAddPlace}
          >
            <AddSmallIcon mr={0} /> Добавить место применения
          </Button>
        </div>
      )}
    </Fragment>
  );
};

export { ApplicationPlaces };
