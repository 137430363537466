import styled, { css } from 'styled-components';

const AddButton = styled.button.attrs(() => ({ type: 'button' }))(
  ({ theme: { colors } }) => css`
    width: 154px;
    height: 118px;
    border: 1px dashed ${colors.divider};
    color: ${colors.text.secondary};

    & > span {
      display: block;
      width: 133px;
      text-align: center;
      margin: 0 auto;
    }

    &:hover {
      background: ${colors.highlight[0]};
      color: ${colors.text.primary};
    }
  `,
);

export { AddButton };
