import { FC, Fragment, memo, useCallback, useState } from 'react';

import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { findCategoriesTrees } from 'common/api/category.api';
import { EMPTY_OBJECT } from 'common/constants/emptyDataStructures.const';
import { useGlobalLoader, useQueryParams, useRequest } from 'common/hooks';
import { ICategory, IMapCategory } from 'common/types/categories.types';
import { getCategoryMap, getRootCategories } from 'common/utils/categories';
import { DefaultPage } from 'layouts/Pages';
import { EditMediumIcon } from 'resources/icons/18';
import { DataIcon, MatchesIcon } from 'resources/placeholders';
import { CATALOGS_AND_GUIDES } from 'routes';
import { itemTo } from 'tools/utils';
import { Button, Container, Placeholder, SearchInput, Spinner, Text } from 'UI';

import { Category, getHierarchyMapBySearch } from './components';
import { ICategoryBackend } from './types';
import { findCategories } from '../../api';

const Panel = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    column-gap: ${space[2]}px;
    height: 34px;
    margin-bottom: ${space[1]}px;
    padding: 0 ${space[4]}px;
    font-weight: 500;
    color: ${colors.text.secondary};
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    border-right-width: 0;
    border-left-width: 0;
  `,
);

const ITEM_TO_ID = itemTo('id');
const HEADING = 'Иерархия категорий';
const INITIAL_SEARCH_CATEGORIES = { items: EMPTY_OBJECT, count: 0 };
const HEADING_CONFIG = {
  heading: HEADING,
  controls: (
    <Button
      as={Link}
      to={CATALOGS_AND_GUIDES.CATEGORIES_HIERARCHY_MANAGEMENT}
      variant='text'
    >
      <EditMediumIcon mr={0} /> Управлять иерархией
    </Button>
  ),
};

const Categories: FC = memo(() => {
  const history = useHistory();
  const [{ searchValue }, querySet] = useQueryParams();

  const [categoriesMap, setCategoriesMap] = useState<Map<string, IMapCategory>>(
    new Map(),
  );

  const { isLoading }: { isLoading: boolean } = useRequest(
    findCategoriesTrees,
    [EMPTY_OBJECT],
    {
      onSuccess: ({ items }: { items: ICategory[] }) => {
        setCategoriesMap(getCategoryMap(items));
      },
    },
  );

  const {
    isLoading: isLoadingSearch,
    data: searchCategories = INITIAL_SEARCH_CATEGORIES,
  }: {
    isLoading: boolean;
    data: { items: Record<string, ICategoryBackend>; count: number };
  } = useRequest(searchValue && findCategories, [{ search: searchValue }]);

  useGlobalLoader(isLoading);

  const searchCategoriesMap =
    searchCategories.count > 0
      ? getHierarchyMapBySearch(
          Object.values(searchCategories.items).map(ITEM_TO_ID),
          categoriesMap,
        )
      : null;

  const handleSearch = useCallback(
    (value: string): void => history.push(querySet({ searchValue: value })),
    [history, querySet],
  );

  if (isLoading) return null;

  if (categoriesMap.size === 0)
    return (
      <DefaultPage heading={HEADING}>
        <Placeholder
          hideInfo
          buttons={
            <Button
              as={Link}
              size='l'
              to={CATALOGS_AND_GUIDES.CATEGORIES_HIERARCHY_MANAGEMENT}
            >
              Создать иерархию
            </Button>
          }
          icon={DataIcon}
          text={
            <Fragment>
              Нет иерархии категорий.
              <br />
              Для добавления иерархии категорий в систему нажмите Создать.
            </Fragment>
          }
        />
      </DefaultPage>
    );

  return (
    <DefaultPage heading={HEADING_CONFIG}>
      <SearchInput
        delay={700}
        initialValue={searchValue}
        mb={3}
        placeholder='Поиск по названию категории'
        onChange={handleSearch}
      />
      {searchValue && !isLoadingSearch && !searchCategoriesMap ? (
        <Placeholder hideInfo icon={MatchesIcon} text='Совпадений не найдено' />
      ) : (
        <Fragment>
          <Panel>
            <Text color='text.disabled' flexGrow={1} ml='26px'>
              Название
            </Text>
            <Text color='text.disabled'>Наличие привязанных сущностей</Text>
            <Text color='text.disabled'>Дата обновления</Text>
            <Container width='50px' />
          </Panel>
          <Spinner loading={isLoadingSearch}>
            <Container column gap={1}>
              {getRootCategories(searchCategoriesMap ?? categoriesMap).map(
                category => (
                  <Category
                    key={category.id}
                    categoriesMap={categoriesMap}
                    category={category}
                    isSearch={!!searchValue}
                  />
                ),
              )}
            </Container>
          </Spinner>
        </Fragment>
      )}
    </DefaultPage>
  );
});

export { Categories };
