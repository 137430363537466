import { Fragment, useEffect, memo, FC, CSSProperties } from 'react';

import { useParams } from 'react-router-dom';
import styled, { css, useTheme } from 'styled-components';

import { findManufacturedNTDByIds } from 'common/api/manufacturing';
import { findNtdRetailers } from 'common/api/manufacturing-requests.api';
import { fetchManufacturerNtd } from 'common/api/ntd.api';
import { fetchPointsOfSale } from 'common/api/points-of-sale.api';
import { findNtdsByIds } from 'common/api/standartization';
import { addPageVisit } from 'common/api/stats';
import { handleScrollTo, useRequest } from 'common/hooks';
import { AssortmentPageSections } from 'common/types/assortments.types';
import { IParamsId } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { IStandardMedia } from 'common/types/product.types';
import { StandardPageSections } from 'common/types/standards.types';
import { INtd, NtdTypes } from 'common/types/standartization';
import { PageIdentifier } from 'common/types/stats';
import { getFormattedNumber, getImageURL } from 'common/utils';
import { NotFound } from 'components/ErrorPages';
import { ImagesPage } from 'components/media';
import {
  PointsOfSaleModal,
  ProductPointsOfSale,
  LINK_TO_POINT_OF_SALES,
} from 'components/points-of-sale';
import { DocumentsList } from 'components/products/media/documents';
import { SkeletonProductPage } from 'components/products/SkeletonProductPage';
import { SupplierButton } from 'components/SupplierButton';
import { getIdentity } from 'entities/identity';
import {
  CreateManufacturingRequestModal,
  CREATE_MANUFACTURING_REQUEST_MODAL,
} from 'entities/manufacturing-requests';
import { ModalController } from 'entities/modals';
import { ToolsPanelPage } from 'layouts/Pages';
import {
  ManufacturedNtdProducts,
  StopManufacturingButton,
  StopManufacturingNtdModal,
  STOP_MANUFACTURING_NTD,
} from 'nomenclature/pages/manufactured-ntd/components';
import { NtdPageSections } from 'nomenclature/pages/types';
import { getPath, PAGES, COMPANY } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import {
  Box,
  Text,
  Badge,
  Avatar,
  Status,
  Heading,
  Spinner,
  InfoBlock,
  AnchorButton,
  DescriptionList,
} from 'UI';
import { StatusTypes } from 'UI/Status/Status';

import { SendRequest } from '../components';

const ImageWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    width: 128px;
    max-height: ${space[5]}px;
    margin-top: ${space[1]}px;

    img {
      max-height: ${space[5]}px;
    }
  `,
);

const TEXT_STYLE: CSSProperties = {
  display: 'inline',
  wordBreak: 'break-word',
};

const INITIAL_MANUFACTURER = {
  ntd: null,
  manufacturerId: '',
  manufacturer: null,
};

const ManufacturedNtdPage: FC = memo(() => {
  const theme = useTheme();

  const { id } = useParams<IParamsId>();
  const { company } = useAppSelector(getIdentity);

  const {
    isLoading,
    data: { ntd, manufacturerId, manufacturer } = INITIAL_MANUFACTURER,
  } = useRequest(fetchManufacturerNtd, [{ id }]);

  const {
    data: media,
  }: { data: { standardMedia: IStandardMedia; previewImage: IImage } } =
    useRequest(ntd?.id && findNtdsByIds, [{ minify: true, ids: [ntd?.id] }], {
      responseToData: (response: { items: Record<string, INtd> }) => {
        const data = response.items[ntd?.id];

        return { standardMedia: data.media, previewImage: data.previewImage };
      },
    });

  const { data: hasPointsOfSale } = useRequest(
    fetchPointsOfSale,
    [{ itemsPerPage: 6, manufacturedNTD: id, archiveStatus: 'notArchived' }],
    {
      responseToData: ({ totalItems }) => totalItems > 0,
    },
  );

  const {
    isLoading: isManufacturedNtdRetailersLoading,
    data: isRetailer,
    updateData,
  } = useRequest(company && id && findNtdRetailers, [{ manufacturedNTD: id }], {
    responseToData: response => {
      return response.items.some(item => item.id === company?.id);
    },
  });

  const { data: manufacturedNtd } = useRequest(
    id && findManufacturedNTDByIds,
    [{ ids: [id] }],
    { responseToData: ntds => ntds.items[id] },
  );

  const isCompanyManufacturer = manufacturerId === company?.id;

  // Сбор статистики посещаемости
  useEffect(() => {
    if (manufacturerId && !isCompanyManufacturer) {
      addPageVisit({
        page: PageIdentifier.manufacturedNTDPage,
        entity: id,
        entityCompany: manufacturerId,
      });
    }
  }, [isCompanyManufacturer, id, manufacturerId]);

  const isAssortment =
    ntd?.types.includes(NtdTypes.assortmentMeasurement) ||
    ntd?.types.includes(NtdTypes.assortmentMaterial);

  const ntdSections = [
    { title: 'Описание НТД', dataType: NtdPageSections.description },
    media?.standardMedia.documents.length > 0 && {
      title: 'Документы',
      dataType: NtdPageSections.documents,
    },
    {
      title: isAssortment ? 'Сортаменты' : 'Вариации товаров',
      dataType: isAssortment
        ? AssortmentPageSections.assortments
        : NtdPageSections.products,
    },
    hasPointsOfSale && {
      title: 'Места продаж',
      dataType: StandardPageSections.pointsOfSale,
    },
  ].filter(Boolean);

  if (!isLoading && !ntd) return <NotFound />;

  return (
    <Fragment>
      <ToolsPanelPage
        bg={theme.colors.background}
        rightAddon={
          <Fragment>
            <SendRequest />
            <Spinner delay={0} loading={isManufacturedNtdRetailersLoading}>
              <SupplierButton
                isRetailer={isRetailer}
                manufacturedNtd={manufacturedNtd}
                onSubmit={updateData}
              />
            </Spinner>
            {isCompanyManufacturer && <StopManufacturingButton id={id} />}
          </Fragment>
        }
        sections={ntdSections}
        skeletonComponent={isLoading && <SkeletonProductPage />}
      >
        {ntd && (
          <Fragment>
            <InfoBlock
              badges={
                <Fragment>
                  {isCompanyManufacturer && (
                    <Status
                      mr={0}
                      size={20}
                      title='Ваша компания поставляет товары по этому НТД в производстве'
                      type={StatusTypes.RETAILER}
                    />
                  )}
                  <Badge
                    fontWeight={700}
                    label='НТД в производстве'
                    size='m'
                    type={Badge.TYPES.SECONDARY}
                  />
                </Fragment>
              }
              id={NtdPageSections.description}
              mt={2}
              pt={0}
            >
              <Box display='flex'>
                {media && (
                  <ImagesPage
                    media={media.standardMedia}
                    previewImage={media.previewImage}
                  />
                )}
                <Box.FillingWrapper>
                  <Heading mb={0}>
                    {ntd.numericName} {ntd.name}
                  </Heading>
                  <DescriptionList dotted primary mb={2} mt={3}>
                    <DescriptionList.Property label='НТД'>
                      <Link
                        to={
                          isAssortment
                            ? getPath(PAGES.ASSORTMENT_NTD, {
                                id: ntd.id,
                              })
                            : getPath(PAGES.NTD, { id: ntd.id })
                        }
                      >
                        {ntd.numericName}
                      </Link>
                    </DescriptionList.Property>
                    {manufacturer && (
                      <DescriptionList.Property label='Производитель'>
                        <Link
                          to={getPath(COMPANY.CARD, { id: manufacturer.id })}
                        >
                          {manufacturer.name}
                        </Link>
                        {manufacturer.logo && (
                          <ImageWrapper>
                            <Avatar
                              alt={manufacturer.name}
                              url={getImageURL(manufacturer.logo, {
                                size: 'm',
                              })}
                            />
                          </ImageWrapper>
                        )}
                      </DescriptionList.Property>
                    )}
                  </DescriptionList>
                  <DescriptionList
                    dotted
                    mb={1}
                    mt={3}
                    title='Вариации товаров'
                  >
                    <DescriptionList.Property
                      label={
                        <Text style={TEXT_STYLE}>
                          Количество {isAssortment ? 'сортаментов' : 'вариаций'}
                        </Text>
                      }
                    >
                      {ntd.standardsCount > 0
                        ? getFormattedNumber(ntd.standardsCount)
                        : 'Скоро в системе'}
                    </DescriptionList.Property>
                  </DescriptionList>
                  <AnchorButton
                    data-type={
                      isAssortment
                        ? AssortmentPageSections.assortments
                        : NtdPageSections.products
                    }
                    onClick={handleScrollTo}
                  >
                    Перейти к{' '}
                    {isAssortment ? 'сортаментам' : 'вариациям товаров'}
                  </AnchorButton>
                </Box.FillingWrapper>
              </Box>
            </InfoBlock>
            {media && (
              <DocumentsList
                documents={media.standardMedia.documents}
                documentsCount={media.standardMedia.documents.length}
                id={NtdPageSections.documents}
                title='Документы'
              />
            )}
            <ManufacturedNtdProducts
              id={
                isAssortment
                  ? AssortmentPageSections.assortments
                  : NtdPageSections.products
              }
              ntd={ntd}
              ntdId={ntd.id}
            />
            <ProductPointsOfSale
              id={StandardPageSections.pointsOfSale}
              manufacturedNtdId={id}
            />
          </Fragment>
        )}
      </ToolsPanelPage>
      <ModalController
        component={CreateManufacturingRequestModal}
        type={CREATE_MANUFACTURING_REQUEST_MODAL}
      />
      <ModalController
        component={PointsOfSaleModal}
        type={LINK_TO_POINT_OF_SALES}
      />
      <ModalController
        component={StopManufacturingNtdModal}
        type={STOP_MANUFACTURING_NTD}
      />
    </Fragment>
  );
});

export { ManufacturedNtdPage };
