import { FC } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { BRAND_STATE } from 'common/constants/brand.const';
import { KIND_PRODUCT_OPTIONS_BY_TEXT } from 'common/constants/products.const';
import { handleScrollTo } from 'common/hooks';
import {
  IProduct,
  ProductCategory,
  ProductLifeState,
  ProductSections,
} from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { getProductFullName, getProductPagePath } from 'common/utils/products';
import { getClassifierDescriptionProperties } from 'common/utils/products/classifiers';
import { AccompanyInformationPreviewInfo } from 'components/products/accompany-information';
import { MainPropertiesTree } from 'components/products/properties';
import { UinProperty } from 'components/products/properties/page';
import { getCompanyId, hasAccessRole } from 'entities/identity';
import { OPERATIONS, Permission } from 'entities/permission';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { DoubleStarsIcon } from 'resources/other-18';
import { getPath, PAGES, COMPANY, PRODUCTS, SEARCH } from 'routes';
import { useAppSelector } from 'store';
import { Link } from 'tools/libs';
import {
  AnchorButton,
  Avatar,
  Box,
  Button,
  DescriptionList,
  Heading,
  Tags,
  Text,
  Tooltip,
} from 'UI';

import {
  ProductStatistics,
  ProductVersionDescriptionProperty,
} from './components';

const ImageWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    width: 128px;
    max-height: ${space[5]}px;
    margin-top: ${space[1]}px;

    img {
      max-height: ${space[5]}px;
    }
  `,
);

const previewInfoProperty = {
  type: type => {
    return (
      type && (
        <DescriptionList.Property label='Тип товара'>
          <Link to={`${SEARCH.PAGE}?search=${type}`}>{type}</Link>
        </DescriptionList.Property>
      )
    );
  },
  classifiers: (classifiers, limit = 3) => {
    return (
      classifiers &&
      Object.values(classifiers).length > 0 && (
        <DescriptionList.Group flat primary={false} title='Классификаторы'>
          {getClassifierDescriptionProperties(classifiers, limit)}
        </DescriptionList.Group>
      )
    );
  },
  company: ({ label = 'Производитель', company }) => (
    <DescriptionList.Property label={label}>
      {company ? (
        <Link to={getPath(COMPANY.CARD, { id: company?.id })}>
          {company?.name}
        </Link>
      ) : (
        <Text color='text.disabled'>Не указан</Text>
      )}
      {company.logo && (
        <Avatar
          alt={company?.name}
          letter={company?.name}
          mt={1}
          size='s'
          url={getImageURL(company?.logo, { size: 'm' })}
        />
      )}
    </DescriptionList.Property>
  ),
  brand: brand => {
    return (
      brand &&
      brand.verificationStatus !== BRAND_STATE.BLOCKED && (
        <DescriptionList.Property label='Бренд'>
          {brand.name}
          <ImageWrapper>
            <img
              alt={brand.name}
              src={getImageURL(brand.logo, { size: 'm' })}
            />
          </ImageWrapper>
        </DescriptionList.Property>
      )
    );
  },
};

const ProductsPreviewInfo: FC<IProduct & { isFillingMode?: boolean }> = ({
  id,
  uin,
  name,
  type,
  lifeState,
  category,
  tags,
  company,
  standard,
  authorshipCompany,
  mainProperties,
  properties,
  favorite,
  statistics,
  accompanyingInformation,
  manufacturer,
  kind,
  designation,
  isTemplate,
  template,
  version,
  isFillingMode,
}) => {
  const userCompanyId = useSelector(getCompanyId);

  const showCreateByTemplateButton =
    ((!manufacturer && authorshipCompany?.id === userCompanyId) ||
      manufacturer?.company?.id === authorshipCompany?.id) &&
    category === ProductCategory.questionnaire &&
    (lifeState === ProductLifeState.internalUsage ||
      lifeState === ProductLifeState.public) &&
    isTemplate;

  const isConstructor = useAppSelector(
    state =>
      hasAccessRole(state, 'chiefConstructor') ||
      hasAccessRole(state, 'juniorConstructor'),
  );

  return (
    <Box.FillingWrapper>
      <div id={`${ProductSections.description}.${ProductSections.main}`}>
        <Heading
          color={name || designation ? 'text.primary' : 'text.disabled'}
          mb={0}
        >
          {getProductFullName({ name, designation, category }) || 'Не указано'}
        </Heading>
        <ProductStatistics
          category={category as ProductCategory}
          companyId={authorshipCompany.id!}
          favorite={favorite}
          lifeState={lifeState}
          productId={id}
          statistics={statistics}
        />
        {showCreateByTemplateButton && (
          <Permission
            operation={OPERATIONS.PRODUCTS_OWN.CREATE_COMPANY_PRODUCT}
          >
            <Tooltip
              pointer
              placement='bottom-start'
              title={
                isFillingMode &&
                'Действия с товаром недоступны в режиме заполнения опросного листа'
              }
            >
              <span>
                <Button
                  as={isFillingMode ? 'div' : Link}
                  disabled={isFillingMode}
                  mb={1}
                  mt={1}
                  size='s'
                  to={{
                    pathname: PRODUCTS.CREATE_BY_TEMPLATE,
                    search: `?templatedId=${id}`,
                  }}
                  variant='text'
                >
                  <DoubleStarsIcon color='primary.main' mr={1} />
                  Создать изделие по шаблону
                </Button>
              </span>
            </Tooltip>
          </Permission>
        )}
        <DescriptionList dotted primary mb={2} mt={3}>
          {standard && (
            <DescriptionList.Property label='Вариация товара'>
              <Link to={`/standard/${standard.id}`}>
                {standard.conventionalName}
              </Link>
            </DescriptionList.Property>
          )}
          {template && (
            <DescriptionList.Property
              label='Создано по опросному листу'
              placeholder='Исходный шаблон недоступен'
            >
              {template?.id ? (
                <Link to={getProductPagePath(template.id)}>
                  {template.id.name}
                </Link>
              ) : null}
            </DescriptionList.Property>
          )}
          <DescriptionList.Property label='Вид продукции'>
            {KIND_PRODUCT_OPTIONS_BY_TEXT[kind]}
          </DescriptionList.Property>
          {previewInfoProperty.type(type)}
          {standard && (
            <DescriptionList.Property label='НТД'>
              <Link
                to={getPath(PAGES.NTD, {
                  id: standard.productForm.ntdReference.id,
                })}
              >
                {standard.productForm.ntdReference.numericName}
              </Link>
            </DescriptionList.Property>
          )}
          <DescriptionList.Property label='UIN'>
            {uin ? (
              <UinProperty uin={uin} />
            ) : (
              <Text color='text.disabled'>Будет присвоен после публикации</Text>
            )}
          </DescriptionList.Property>
          {version && (
            <ProductVersionDescriptionProperty
              company={company}
              productId={id}
              version={version}
            />
          )}
          {!(
            isConstructor &&
            (lifeState === ProductLifeState.internalUsage ||
              lifeState === ProductLifeState.draft)
          ) &&
            previewInfoProperty.company({
              company: authorshipCompany,
              label: manufacturer?.name ? 'Поставщик' : 'Производитель',
            })}
          {manufacturer?.name && (
            <DescriptionList.Property
              label={
                <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                  Производитель{' '}
                  <Tooltip
                    arrow
                    maxWidth={300}
                    title='Компания еще не зарегистрирована в системе. Описание товара добавил представитель'
                  >
                    <Help1pxIcon ml={1} />
                  </Tooltip>
                </div>
              }
            >
              {manufacturer.company ? (
                <Link
                  to={getPath(COMPANY.CARD, { id: manufacturer.company.id })}
                >
                  {manufacturer.company.name}
                </Link>
              ) : (
                manufacturer.name
              )}
            </DescriptionList.Property>
          )}
          {tags.length > 0 && (
            <DescriptionList.Property label='Теги'>
              <Tags
                arrow
                as={Link}
                getProps={tag => ({ to: `/search?search=${tag}` })}
                itemToIcon='#'
                tags={tags}
              />
            </DescriptionList.Property>
          )}
        </DescriptionList>
        <AnchorButton data-type={ProductSections.main} onClick={handleScrollTo}>
          К общим сведениям
        </AnchorButton>
      </div>
      {mainProperties.length > 0 && (
        <div
          id={`${ProductSections.description}.${ProductSections.mainProperties}`}
        >
          <MainPropertiesTree
            isStandard={!!standard}
            mb={2}
            mt={3}
            properties={mainProperties}
          />
          {properties.length > 0 && (
            <AnchorButton
              data-type={ProductSections.properties}
              onClick={handleScrollTo}
            >
              К характеристикам
            </AnchorButton>
          )}
        </div>
      )}
      <AccompanyInformationPreviewInfo
        accompanyInformation={accompanyingInformation}
        id={`${ProductSections.description}.${ProductSections.accompanyingInformation}`}
        scrollToId={ProductSections.accompanyingInformation}
      />
    </Box.FillingWrapper>
  );
};

export { ProductsPreviewInfo, previewInfoProperty };
