import styled, { css } from 'styled-components';

import { Button } from 'UI';

export const RecommendationPanelButton = styled(Button)<{
  isHiddenText: boolean;
}>(
  ({ isHiddenText }) => css`
    justify-content: flex-start;
    margin: 0;

    & svg {
      transition: 0.2s ease-in-out color;
    }

    ${isHiddenText &&
    css`
      justify-content: center;
      min-width: auto;
      width: 34px;
    `}
  `,
);
