import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  SpaceProps,
  borders as styledBorders,
  BorderProps,
  BorderRadiusProps,
  width,
  minWidth,
  WidthProps,
  MinWidthProps,
  position,
  height,
  PositionProps,
  HeightProps,
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  MaxWidthProps,
  maxWidth,
} from 'styled-system';

/**
 * Специально задаем аттрибут data-error, чтобы селекторы могли считывать состояние данного контейнера.
 * В данном случае используется в UnionContainer.
 *
 * @param hasErrorZIndex - временное свойство, отключает z-index для случаев с ошибкой в инпуте.
 */
const InputContainer = styled.div.attrs<{ error?: boolean }>(({ error }) => ({
  'data-error': `${!!error}`,
}))<
  {
    disabled?: boolean;
    error?: boolean;
    readonly?: boolean;
    hasErrorZIndex?: boolean;
  } & SpaceProps &
    BorderProps &
    BorderRadiusProps &
    HeightProps &
    PositionProps &
    WidthProps &
    MinWidthProps &
    MaxWidthProps &
    FontSizeProps &
    FontWeightProps &
    ColorProps
>(
  ({
    theme: { borderRadius, colors, fontSizes },
    disabled = false,
    error = false,
    hasErrorZIndex = true,
    readonly,
  }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 0;
    height: 34px;
    min-height: 34px;
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    border-radius: ${borderRadius}px;
    color: ${colors.text.primary};
    font-size: ${fontSizes[3]}px;

    &:focus-within:not([disabled]) {
      border-color: ${colors.text.primary};
    }

    ${error &&
    css`
      border-color: ${colors.error.main};

      ${hasErrorZIndex &&
      css`
        z-index: 1;
      `}
    `}

    ${readonly &&
    css`
      pointer-events: none;
    `}

    ${disabled &&
    css`
      color: ${colors.text.disabled};
      background: ${colors.highlight[0]};
    `}

    ${styledSpace};
    ${styledBorders};
    ${width};
    ${minWidth};
    ${maxWidth};
    ${position};
    ${height};
    ${fontSize}
    ${fontWeight};
    ${color};
  `,
);

export { InputContainer };
