import { CSSProperties, forwardRef, ReactNode, useId } from 'react';

import styled, { css, useTheme } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { ArrowDownIcon, ArrowUpIcon } from 'resources/icons/12';

import { useCollapse } from './useCollapse';
import { IconButton } from '../IconButton';

interface ICollapseCard {
  id?: string;
  className?: string;
  style?: CSSProperties;
  /**
   * Fixed height header (52px). By default height header in collapse status - 64px.
   */
  inner?: boolean;
  /**
   * Disabled status.
   */
  disabled?: boolean;
  /**
   * Only uncollapse status and without collapse toggle button
   */
  readonly?: boolean;
  /**
   * Always display right actions. By default right actions display when uncollapse card and hover by header card.
   */
  showRightActions?: boolean;
  /**
   * Title display in header card
   */
  title?: ReactNode;
  children: ReactNode;
  /**
   * Display left actions after title when card uncollapse.
   */
  actions?: ReactNode;
  /**
   * Display right actions before toggle icon button. By default, it display when uncollapse card and hover header card, but with "showRightActions" display always/.
   */
  rightActions?: ReactNode;
}

const Container = styled.div<SpaceProps>(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    background: ${colors.white};

    ${styledSpace};
  `,
);

const HeaderWrapper = styled.button<{ readonly?: boolean }>(
  ({ theme: { space, colors }, disabled, readonly }) => css`
    display: flex;
    align-items: center;
    padding: 0 ${space[1]}px;
    margin: 0 -${space[1]}px;
    border-radius: 8px;
    color: ${colors.text.primary};
    transition: background 200ms ease-in-out, height 200ms ease-in-out;

    ${disabled
      ? css`
          color: ${colors.text.secondary};
          cursor: default;
        `
      : css`
          ${readonly
            ? css`
                cursor: default;
                pointer-events: none;
              `
            : css`
                &:hover {
                  background: ${colors.highlight[0]};
                }
              `}
        `}
  `,
);

const LeftHalf = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 1;
  min-width: 0;
  margin-right: 16px;
  font-size: 16px;
  font-weight: 500;
  text-align: left;
`;

const RightHalf = styled.div`
  display: flex;
  align-items: center;
  justify-content: right;
`;

const HoverActionsWrapper = styled.div<{ show?: boolean } & SpaceProps>(
  ({ show }) => css`
    display: flex;
    align-items: center;
    visibility: hidden;

    ${show
      ? css`
          visibility: visible;
        `
      : css`
          ${HeaderWrapper}:hover &[data-open=true] {
            visibility: visible;
          }
        `};

    ${styledSpace};
  `,
);

interface ICollapse {
  /**
   * External state control card (Works with onRequestToggle prop).
   */
  open?: boolean;
  /**
   * Initial value collapse/uncollapse card
   */
  initialOpen?: boolean;
  /**
   * Function external state control card (Works with open prop).
   */
  onRequestToggle?: (boolean) => void;
}

const CollapseCard = forwardRef<
  HTMLDivElement,
  ICollapseCard & ICollapse & SpaceProps
>(
  (
    {
      id,
      className,
      style,
      inner,
      disabled,
      readonly,
      open,
      initialOpen,
      showRightActions,
      title,
      children,
      actions,
      rightActions,
      onRequestToggle,
      ...other
    },
    ref,
  ) => {
    const theme = useTheme();
    const widgetId: string = useId();
    const { isOpen, handleToggle } = useCollapse({
      open,
      initialOpen,
      onRequestToggle,
    });

    return (
      <Container
        ref={ref}
        className={className}
        id={id}
        style={style}
        {...other}
      >
        <HeaderWrapper
          aria-controls={widgetId}
          aria-expanded={isOpen}
          aria-label='show more'
          disabled={disabled}
          readonly={readonly}
          style={{
            height: `${inner ? theme.space[6] : theme.space[7]}px`,
          }}
          onClick={handleToggle}
        >
          <LeftHalf>
            {title}
            {actions && isOpen && (
              <HoverActionsWrapper show ml={0}>
                {actions}
              </HoverActionsWrapper>
            )}
          </LeftHalf>
          <RightHalf>
            {rightActions && (
              <HoverActionsWrapper
                data-open={isOpen}
                show={showRightActions}
                onClick={event => event.stopPropagation()}
              >
                {rightActions}
              </HoverActionsWrapper>
            )}
            {!readonly && (
              <IconButton
                as='div'
                disabled={disabled}
                ml={1}
                title={isOpen ? 'Свернуть' : 'Развернуть'}
              >
                {isOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </IconButton>
            )}
          </RightHalf>
        </HeaderWrapper>
        <div
          id={widgetId}
          style={{
            display: isOpen ? 'block' : 'none',
            marginTop: isOpen ? `${theme.space[1]}px` : 0,
            marginBottom: isOpen ? `${theme.space[3]}px` : 0,
          }}
        >
          {children}
        </div>
      </Container>
    );
  },
);

export { CollapseCard, ICollapse };
