import { ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  border,
  space as styledSpace,
  SpaceProps,
  BorderProps,
} from 'styled-system';

const Container = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  ${styledSpace};
`;

const Tabs = styled.div<SpaceProps & BorderProps>(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: ${space[1]}px;
    border-bottom: 1px solid transparent;

    ${styledSpace};
    ${border};
  `,
);

const Frame = styled.div`
  display: flex;
  flex-direction: column;
`;

const Tape = styled.div<{ frameWidth: number }>(
  ({ frameWidth }) => css`
    display: flex;
    flex-grow: 1;
    min-height: 0;
    transform: translateX(0);
    transition: transform 200ms ease-out;

    & > ${Frame} {
      height: 100%;
      min-width: ${frameWidth}px;
    }
  `,
);

interface SliderProps {
  currentTab: number;
  frameWidth: number;
  tabs: ReactNode;
  children: ReactNode;
}

function Slider({
  currentTab,
  frameWidth,
  tabs,
  children,
}: SliderProps): ReactElement {
  return (
    <Container style={{ maxWidth: frameWidth, minWidth: frameWidth }}>
      {tabs}
      <Tape
        frameWidth={frameWidth}
        style={{ transform: `translateX(${-frameWidth * currentTab}px)` }}
      >
        {children}
      </Tape>
    </Container>
  );
}

Slider.Frame = Frame;
Slider.Tabs = Tabs;

export { Slider };
