import { FC } from 'react';

import styled, { css } from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { IColorType } from 'common/types/common.types';
import { getRgbCode } from 'common/utils';
import { Container, Text } from 'UI';

const ColorPreview = styled.div<ColorProps>(
  ({ theme: { colors } }) => css`
    width: 30px;
    min-width: 30px;
    height: 30px;
    border: 1px solid ${colors.background};

    ${color};
  `,
);

const Rgb = styled(Text)<{ isSelected?: boolean }>(
  ({ theme: { colors, space }, isSelected }) => css`
    display: flex;
    flex-shrink: 0;
    margin-left: auto;
    margin-right: ${space[2]}px;
    color: ${isSelected ? colors.text.disabled : colors.text.secondary};
  `,
);

interface IColorItemProps {
  item: IColorType & {
    isSelected: boolean;
    name: string;
    htmlName: string;
  };
}

const ColorItem: FC<IColorItemProps> = ({ item }) => {
  const { isSelected = false, name, htmlName } = item;

  const textColor = isSelected ? 'text.disabled' : 'text.primary';

  return (
    <Container alignItems='center' display='flex'>
      <ColorPreview backgroundColor={`rgb(${getRgbCode(item)})`} />
      <Text truncate color={textColor} ml={2}>
        {name}
      </Text>
      <Text truncate color={textColor} fontSize={1} ml={1} mr={2}>
        {htmlName}
      </Text>
      <Rgb isSelected={isSelected}>{getRgbCode(item)}</Rgb>
    </Container>
  );
};

export { ColorItem };
