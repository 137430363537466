import { ReactNode, ReactPortal, MouseEvent, Fragment } from 'react';

import ReactDOM from 'react-dom';
import styled, { css } from 'styled-components';

import { useAnimationModal, MODAL_DURATION } from 'entities/modals';
import { CloseMediumIcon } from 'resources/icons/18';

import { IconButton } from '../IconButton';

const Header = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    height: ${space[8]}px;
    min-height: ${space[8]}px;
    padding: 0 50px 0 ${space[4]}px;
    background: ${colors.background};
  `,
);

const Overlay = styled.div(
  ({ theme: { colors, zIndex } }) => css`
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: flex-end;
    z-index: ${zIndex.sidebar};
    background: ${colors.blackout};
    opacity: 0;
    transition: opacity ${MODAL_DURATION}ms ease-out;
  `,
);

const Content = styled.div<{ visible: boolean; width: number }>(
  ({ theme: { colors }, width, visible }) => css`
    display: flex;
    flex-direction: column;
    height: 100%;
    width: ${width}px;
    min-width: ${width}px;
    background: ${colors.white};
    opacity: 1;
    overflow: auto;
    transition: transform ${MODAL_DURATION}ms ease-out;
    transform: translateX(${visible ? 0 : width}px);
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme: { colors, zIndex } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.highlight[0]};
    z-index: ${zIndex.sideMenu};
  `,
);

interface DrawerProps {
  width?: number;
  children: ReactNode;
  leftAddon?: ReactNode;
  handleModalClose: () => void;
}

function Drawer({
  width = 528,
  children,
  leftAddon,
  handleModalClose,
}: DrawerProps): ReactPortal {
  const { isModalVisible } = useAnimationModal();

  const handleOutsideClick = (event: MouseEvent): void => {
    if (!handleModalClose) return;

    if (event.currentTarget === event.target) {
      handleModalClose();
    }
  };

  return ReactDOM.createPortal(
    <Fragment>
      <Overlay
        style={{ opacity: isModalVisible ? 1 : 0 }}
        onMouseDown={handleOutsideClick}
      >
        {leftAddon}
        <CloseButton onClick={handleModalClose}>
          <CloseMediumIcon />
        </CloseButton>
        <Content visible={isModalVisible} width={width}>
          {children}
        </Content>
      </Overlay>
    </Fragment>,
    document.body,
  );
}

Drawer.Header = Header;

export { Drawer };
