import { FC, memo, ReactElement, useCallback } from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { useQueryParams } from 'common/hooks';
import {
  ProductionStates,
  ProductionStatesFilters,
} from 'common/types/product.types';
import { pickOmit } from 'common/utils';
import { getProductionStateText } from 'common/utils/products';
import { useArrayField } from 'reform';
import { Select, SideMenuGroup, ToggleButton } from 'UI';

const TITLE = 'Статус производства';
export const PRODUCTION_STATES_TABS = [
  {
    value: ProductionStatesFilters.all,
    name: { plural: 'Все товары' },
  },
  {
    value: ProductionStatesFilters.newProducts,
    name: { singular: 'Новинка', plural: 'Новинки' },
  },
  {
    value: ProductionStates.inProduction,
    name: { singular: 'Производится', plural: 'Производятся' },
  },
  {
    value: ProductionStates.announced,
    name: { singular: 'Анонс', plural: 'Анонсы' },
  },
  {
    value: ProductionStates.discontinued,
    name: { singular: 'Снят с производства', plural: 'Сняты с производства' },
  },
];

const Wrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    gap: ${space[0]}px;
    flex-wrap: wrap;
    margin-top: ${space[1]}px;
  `,
);

const FILTERED_STATE_TABS = [
  ...PRODUCTION_STATES_TABS.filter(
    tab =>
      tab.value !== ProductionStatesFilters.newProducts &&
      tab.value !== ProductionStatesFilters.all,
  ),
];

/*
 * Статус производства
 * Множественный выбор: Анонс, Производится, Снят с производства и Не указан
 * */
function MultipleProductionFilter({ name }): ReactElement {
  const { fields, setFields } = useArrayField({
    name,
  });

  const handleChangeProduction = ({ currentTarget }): void => {
    const { value: newProductionState } = currentTarget;

    if (fields.includes(newProductionState)) {
      setFields(
        fields.filter(item =>
          newProductionState === ProductionStates.announced
            ? item !== ProductionStates.announced &&
              item !== ProductionStates.cancelled
            : item !== newProductionState,
        ),
      );

      return;
    }

    setFields(
      newProductionState === ProductionStates.announced
        ? [...fields, ProductionStates.announced, ProductionStates.cancelled]
        : [...fields, newProductionState],
    );
  };

  return (
    <SideMenuGroup isFixedGroup indent={false} mt={2} title={TITLE}>
      <Wrapper>
        {FILTERED_STATE_TABS.map(item => (
          <ToggleButton.Tab
            key={item.value}
            secondary
            uncheck
            checked={fields.includes(item.value)}
            label={item.name.singular}
            mb='0px'
            mr='0px'
            value={item.value}
            onClick={handleChangeProduction}
          />
        ))}
      </Wrapper>
    </SideMenuGroup>
  );
}

const SINGLE_PRODUCTION_STATE_OPTIONS = [
  ProductionStatesFilters.all,
  ProductionStates.inProduction,
  ProductionStates.announced,
  ProductionStates.discontinued,
];

/*
 * Статус производства
 * Единичный выбор: Все товары, Анонс, Производится, Снят с производства
 * */
const SingleProductionStateFilter: FC = () => {
  const [{ productionState }, querySet] = useQueryParams();
  const history = useHistory();

  const handleProductionFilter = useCallback(
    (newProductionState): void => {
      history.push(
        querySet(params => {
          const [, filteredParams] = pickOmit(params, ['productionState']);

          if (newProductionState === ProductionStatesFilters.all) {
            return {
              ...filteredParams,
              page: 1,
            };
          }

          return {
            ...filteredParams,
            page: 1,
            productionState: newProductionState,
          };
        }),
      );
    },
    [history, querySet],
  );

  return (
    <SideMenuGroup isFixedGroup secondary title={TITLE}>
      <SideMenuGroup.ChildWrapper>
        <Select
          canClear
          itemToString={getProductionStateText}
          options={SINGLE_PRODUCTION_STATE_OPTIONS}
          value={productionState}
          onChange={handleProductionFilter}
        />
      </SideMenuGroup.ChildWrapper>
    </SideMenuGroup>
  );
};

interface IManufacturerFilter {
  isSingle?: boolean;
  name?: string;
}

// Фильтры по статусам производства в боковых меню.
// SingleProductionStateFilter - Единичный фильтр  используется в боковой панели первого уровня (Товары поставщица в компании).
// MultipleProductionFilter - в панели филтьтров второго уровня (Мои товары).
const ProductionStateFilter: FC<IManufacturerFilter> = memo(
  ({ isSingle = false, name }) => {
    if (isSingle) return <SingleProductionStateFilter />;

    return <MultipleProductionFilter name={name} />;
  },
);

export { ProductionStateFilter };
