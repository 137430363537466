import styled, { css } from 'styled-components';
import { space as styledSpace, typography } from 'styled-system';

import { height } from 'common/constants/customStyledSystems.const';

// TODO: keyboard support
const TablistTab = styled.button.attrs(({ role = 'tab' }) => ({ role }))(
  ({ theme: { colors, space, fontSizes } }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 ${space[1]}px;
    font-size: ${fontSizes[4]}px;
    font-weight: 500;
    border-bottom: 2px solid transparent;
    color: ${colors.text.secondary};
    white-space: nowrap;
    transition: all ease-in 200ms;

    &:hover {
      color: ${colors.text.primary};
      border-color: ${colors.highlight[3]};
    }

    &[aria-selected='true'] {
      color: ${colors.primary.main};
      border-color: ${colors.primary.main};

      svg {
        color: ${colors.primary.main};
      }
    }

    &:disabled,
    &:disabled:hover {
      color: ${colors.text.disabled};
      border-color: transparent;
      cursor: default;
    }

    ${typography}
  `,
);

const tabListSizes = { s: 34, m: 46 };

const TabList = styled.div.attrs(
  ({ role = 'tablist' }): { role: string; size?: 's' | 'm' } => ({
    role,
  }),
)(
  ({ theme: { colors, fontSizes }, size = 'm' }) => css`
    flex-shrink: 0;
    display: flex;
    justify-content: flex-start;
    height: ${tabListSizes[size]}px;
    font-size: ${fontSizes[5]}px;
    border-bottom: 1px solid ${colors.divider};
    overflow: scroll;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    ${height};
    ${styledSpace};
  `,
);

export { TabList, TablistTab };
