import { FC, Fragment, memo } from 'react';

import styled, { css } from 'styled-components';

import { InfoIcon } from 'resources/icons/18';

const EmptyWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: ${colors.text.disabled};
    height: 160px;
  `,
);

const EmptyPlaceholder: FC<{ text?: string }> = memo(({ text }) => {
  return (
    <EmptyWrapper>
      <InfoIcon color='text.disabled' mb={1} />
      {text ?? (
        <Fragment>
          Информации пока
          <br /> нет в системе
        </Fragment>
      )}
    </EmptyWrapper>
  );
});

export { EmptyPlaceholder };
