import { memo, FC } from 'react';

import styled, { css } from 'styled-components';

import { ProductLifeState } from 'common/types/product.types';
import { IServiceBackend } from 'common/types/services.types';
import {
  CreateManufacturingRequestModal,
  CREATE_MANUFACTURING_REQUEST_MODAL,
} from 'entities/manufacturing-requests';
import { ModalController } from 'entities/modals';

import { ServiceCard } from '../services';

export const ServicesCardsWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(4, minmax(300px, 1fr));
    width: 100%;
    margin-bottom: ${space[2]}px;
    gap: ${space[2]}px;

    @media (max-width: 1100px) {
      grid-template-columns: repeat(3, minmax(250px, 1fr));
    }
  `,
);

interface IServicesListProps {
  productId?: string;
  productLifeState?: ProductLifeState;
  services: IServiceBackend[];
}

const ServicesList: FC<IServicesListProps> = memo(
  ({ productId, productLifeState, services }) => (
    <ServicesCardsWrapper>
      {services.map(item => (
        <ServiceCard
          key={item.id}
          item={item}
          productId={productId}
          productLifeState={productLifeState}
        />
      ))}
      <ModalController
        component={CreateManufacturingRequestModal}
        type={CREATE_MANUFACTURING_REQUEST_MODAL}
      />
    </ServicesCardsWrapper>
  ),
);

export { ServicesList };
