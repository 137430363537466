import { FC } from 'react';

import styled, { css } from 'styled-components';
import { color } from 'styled-system';

import { WithChildren } from 'common/types/common.types';
import { istockMainPath } from 'resources/main';
import { useAppSelector } from 'store';
import { Img, Link } from 'tools/libs';
import { Container, ProgressIndicator, Text, VerticalDivider } from 'UI';

import { HeaderSearch } from './components';
import { getLayoutStates } from '../../store';
import { ICollapsed } from '../../types';
import { Services } from '../Services';

const LOGO_STYLE_PROPS = { minWidth: '80px' };

const HeaderWrapper = styled.header(
  ({ theme: { colors } }) => css`
    position: relative;
    display: flex;
    flex: 0 0 42px;
    width: 100%;
    height: 42px;
    background: ${colors.background};
    transition: top ease-in 200ms;

    ${color};
  `,
);

const StyledProgressIndicator = styled(ProgressIndicator)`
  position: absolute;
  bottom: 0;
  background: transparent;
`;

/**
 * Общая шапка
 * @param isCollapsed свёрнуто ли левое меню
 * @param showServices показывать ли выбор сервисов
 * @param children
 * @constructor
 */
const CommonHeader: FC<
  WithChildren<ICollapsed> & { showServices?: boolean }
> = ({ isCollapsed, showServices = true, children }) => {
  const { isGlobalLoading } = useAppSelector(getLayoutStates);

  return (
    <HeaderWrapper>
      {showServices && <Services isCollapsed={isCollapsed} />}
      <Container alignItems='center' flex='1' ml={2}>
        <Container as={Link} to='/'>
          <Img
            alt='istock.info'
            src={istockMainPath}
            style={LOGO_STYLE_PROPS}
          />
        </Container>
        <VerticalDivider height='18px' mx={1} />
        <Text
          truncate
          color='text.primary'
          fontSize={8}
          fontWeight={600}
          letterSpacing={0.5}
          mr={2}
          textTransform='uppercase'
        >
          Электронный номенклатурный справочник
        </Text>
        <HeaderSearch />
        <Container
          alignItems='center'
          justifyContent='end'
          ml='auto'
          width='400px'
        >
          {children}
        </Container>
      </Container>
      <StyledProgressIndicator isLoading={isGlobalLoading} />
    </HeaderWrapper>
  );
};

export { CommonHeader };
