import styled, { css, keyframes } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const progressLinearMovement = keyframes`
  0% {
    left: -100%;
  }
  50%, 100% {
    left: 100%;
  }
`;

const ProgressIndicator = styled.div.attrs(() => ({ role: 'progressbar' }))<
  {
    isLoading?: boolean;
    determinate?: boolean;
    value?: number;
  } & SpaceProps
>(
  ({ theme: { colors }, isLoading, determinate, value = 0 }) => css`
    position: relative;
    background: ${colors.highlight[1]};
    height: 2px;
    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease-in;
    overflow: hidden;

    &:before,
    &:after {
      content: '';
      width: 100%;
      display: none;
      position: absolute;
      left: -100%;
      top: 0;
      bottom: 0;
      background: ${colors.primary.main};
    }

    ${determinate &&
    css`
      opacity: ${value >= 0 && value <= 100 ? 1 : 0};

      &:before {
        display: block;
        transform: translateX(${value}%);
        transition: transform 200ms ease-in;
      }
    `};

    ${isLoading &&
    css`
      opacity: 1;

      &:before,
      &:after {
        display: block;
        animation: ${progressLinearMovement} 2.5s infinite;
      }

      &:before {
        animation-delay: 0.1s;
      }

      &:after {
        animation-delay: 0.8s;
      }
    `};

    ${space};
  `,
);

export { ProgressIndicator };
