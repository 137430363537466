import { Fragment, memo, FC } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { IManufacturers } from 'common/types/product.types';
import { StandardPageSections } from 'common/types/standards.types';
import { Container, Text } from 'UI';

import { EmptyPlaceholder } from './EmptyPlaceholder';
import { ManufactureCard } from './ManufactureCard';

const MAX_VISIBLE_MANUFACTURERS = 3;

const MoreManufacturesLink = styled(Text)(
  ({ theme: { colors, fontSizes } }) => css`
    align-self: flex-end;
    margin-top: auto;
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};

    &:hover {
      color: ${colors.text.primary};
    }
  `,
);

interface IManufacturersBlockProps {
  urlToPage: string;
  manufacturers: IManufacturers[];
}

const ManufacturersBlock: FC<IManufacturersBlockProps> = memo(
  ({ urlToPage, manufacturers }) => {
    if (!manufacturers || manufacturers.length === 0) {
      return (
        <Container height='183px'>
          <EmptyPlaceholder />
        </Container>
      );
    }

    const visibleManufacturers =
      manufacturers.length > MAX_VISIBLE_MANUFACTURERS
        ? manufacturers.slice(0, MAX_VISIBLE_MANUFACTURERS)
        : manufacturers;

    return (
      <Fragment>
        {visibleManufacturers.map(item => (
          <ManufactureCard key={item.id} {...item} />
        ))}
        {manufacturers.length > MAX_VISIBLE_MANUFACTURERS && (
          <MoreManufacturesLink
            as={Link}
            to={`${urlToPage}?scrollTo=${StandardPageSections.manufacturers}`}
          >{`Еще ${
            manufacturers.length - MAX_VISIBLE_MANUFACTURERS
          }`}</MoreManufacturesLink>
        )}
      </Fragment>
    );
  },
);

export { ManufacturersBlock };
