import { FC, memo } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { useHint } from 'common/hooks';
import { SearchTypes } from 'common/types/search.types';
import { CloseMediumIcon } from 'resources/icons/18';
import { searchHintPath } from 'resources/search';
import { Img } from 'tools/libs';
import { Container, Text, IconButton, Heading } from 'UI';

export const searchTypeHintKey = {
  [SearchTypes.productForm]: 'productFormHintKey',
  [SearchTypes.classifier]: 'classifiersHintKey',
  [SearchTypes.standard]: 'standardHintKey',
  [SearchTypes.joint]: 'jointHintKey',
  [SearchTypes.manufacturer]: 'manufacturerHintKey',
};

const StepContainer = styled(Container)(
  ({ theme: { space } }) => css`
    align-items: center;
    gap: ${space[1]}px;
    counter-increment: step;
  `,
);

const StepNumber = styled(Container)(
  ({ theme: { colors, space } }) => css`
    align-items: center;
    justify-content: center;
    height: ${space[3]}px;
    width: ${space[3]}px;
    font-weight: 600;
    background-color: ${colors.highlight[1]};
    border-radius: 8px;

    &::before {
      content: counter(step);
    }
  `,
);

interface IBoxHintProps {
  searchType: SearchTypes;
  entity: string;
  steps: string[];
}

const BoxHint: FC<IBoxHintProps> = memo(({ searchType, entity, steps }) => {
  const { colors } = useTheme();

  const { isViewed, handleToggleViewed } = useHint(
    searchTypeHintKey[searchType],
  );

  if (isViewed) return null;

  return (
    <Container
      bg={colors.highlight[1]}
      borderRadius='16px'
      maxWidth='1120px'
      mb='20px'
      minWidth='800px'
      mt={3}
    >
      <Container p={5} width='100%'>
        <Img alt='Икнока поиска' src={searchHintPath} />
        <Container column gap={2} ml={4}>
          <Heading as='h3'>{`Найдите ${entity}`}</Heading>
          {steps.map(step => (
            <StepContainer key={step}>
              <StepNumber />
              <Text fontSize={4}>{step}</Text>
            </StepContainer>
          ))}
        </Container>
      </Container>
      <IconButton
        mr='12px'
        mt='12px'
        variant='outline'
        onClick={handleToggleViewed}
      >
        <CloseMediumIcon color='primary.main' />
      </IconButton>
    </Container>
  );
});

export { BoxHint };
