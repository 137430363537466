import { memo, FC } from 'react';

import styled, { useTheme } from 'styled-components';

import { Container, Text } from 'UI';

import { ADVANTAGES } from './constants';
import { TEXT_PROPS, CONTAINER_PROPS } from '../constants';

const AdvantagesWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: 20px;
  width: 100%;
  margin-top: 10px;
`;

const Project: FC = memo(() => {
  const theme = useTheme();

  return (
    <Container {...CONTAINER_PROPS} mb='100px' mt='70px'>
      <Container column maxWidth='500px' mr='20px' width='100%'>
        <Text {...TEXT_PROPS['48']} mb={4}>
          О проекте
        </Text>
        <Text {...TEXT_PROPS['20']} fontWeight={400}>
          <b>impuls.istock.info</b> — это облачный сервис для закупки продукции
          пищевой отрасли. Система, в которой вы сможете осуществлять закупки
          продукции быстро, прозрачно и без посредников.
        </Text>
      </Container>
      <AdvantagesWrapper>
        {ADVANTAGES.map(({ icon: Icon, title, text }) => (
          <Container
            key={title}
            column
            borderBottom={`1px solid ${theme.colors.divider}`}
            pb='20px'
          >
            <Container alignItems='center' mb={1}>
              <Icon size={34} />
              <Text {...TEXT_PROPS['24']} ml={2}>
                {title}
              </Text>
            </Container>
            <Container {...TEXT_PROPS['16']} fontWeight={400} ml='50px'>
              {text}
            </Container>
          </Container>
        ))}
      </AdvantagesWrapper>
    </Container>
  );
});

export { Project };
