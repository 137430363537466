import { FC } from 'react';

import styled, { css } from 'styled-components';

import { useUnit } from 'common/hooks';
import { IPackage } from 'common/types/product.types/accompany-information.types';
import { getFormattedNumber } from 'common/utils';
import { ConsumerIcon, TransportIcon } from 'resources/other';
import { Button, Container, Text, Tooltip, VerticalDivider } from 'UI';

import { ProductPackagesCategoryAvatar } from './CategoryAvatar';
import { PACKAGE_KINDS } from '../../../../features/products/constants';

export const PACKAGES_KINDS_INFO = {
  [PACKAGE_KINDS.CONSUMER]: {
    icon: <ConsumerIcon height={12} ml={1} width={10} />,
    title: 'Потребительская упаковка',
  },
  [PACKAGE_KINDS.TRANSPORT]: {
    icon: <TransportIcon ml={1} />,
    title: 'Транспортная упаковка',
  },
};

const CardWrapper = styled.div(
  ({ theme: { space, colors, shadows } }) => css`
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    min-width: 1px;
    height: 70px;
    padding: ${space[2]}px;
    margin-bottom: ${space[0]}px;
    background: ${colors.white};
    box-shadow: ${shadows[1]};

    &:hover {
      box-shadow: ${shadows[2]};
    }
  `,
);

const PackageKind = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: ${space[2]}px;
    margin-bottom: ${space[2]}px;
    white-space: nowrap;
  `,
);

const ControlsWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background: ${colors.white};

    ${CardWrapper}:hover & {
      opacity: 1;
      transition: opacity 100ms ease-in;
    }
  `,
);

const WeightWrapper = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface IProductPackage extends IPackage {
  totalItems: number;
}

interface IPackageCard {
  isPrimaryPackage: boolean;
  globalPackageUnit: any;
  productPackage: IProductPackage;
  onSidebarOpen: any;
}

const PackageCard: FC<IPackageCard> = ({
  isPrimaryPackage,
  globalPackageUnit,
  productPackage: {
    size,
    quantity,
    grossWeight,
    packageType,
    kindOfPackage,
    totalItems,
  },
  onSidebarOpen,
}) => {
  const packageSizes = Object.values(size.value)
    .map(i => getFormattedNumber(i))
    .join(' x ');

  const { unitToString } = useUnit();

  return (
    <CardWrapper>
      <ProductPackagesCategoryAvatar category={packageType.category} />
      <Container
        flexDirection='column'
        minWidth='1px'
        ml={2}
        position='relative'
      >
        <Text truncate color='text.primary' fontWeight={600}>
          {packageType.name}
        </Text>
        <Text truncate>
          {isPrimaryPackage ? 'Товара внутри' : 'Общее количество товара'},{' '}
          {unitToString(globalPackageUnit, { isSkipDash: true })}:{' '}
          {quantity.isApproximate && '~'}
          {getFormattedNumber(totalItems)}
        </Text>
      </Container>
      <PackageKind>
        {isPrimaryPackage && 'Первичная упаковка'}
        <Tooltip arrow hint title={PACKAGES_KINDS_INFO[kindOfPackage].title}>
          {PACKAGES_KINDS_INFO[kindOfPackage].icon}
        </Tooltip>
      </PackageKind>
      <VerticalDivider />
      <Container
        flexDirection='column'
        minWidth='236px'
        ml={2}
        position='relative'
        width={236}
      >
        <ControlsWrapper>
          <Button size='s' variant='outlined' onClick={onSidebarOpen}>
            Подробнее
          </Button>
        </ControlsWrapper>
        <div>
          Габариты, {unitToString(size.unit, { isSkipDash: true })}:{' '}
          {packageSizes}
        </div>
        <WeightWrapper>
          Масса брутто, {unitToString(grossWeight.unit, { isSkipDash: true })}:{' '}
          {getFormattedNumber(grossWeight.value)}
        </WeightWrapper>
      </Container>
    </CardWrapper>
  );
};

export { PackageCard };
