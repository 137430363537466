import { Fragment, forwardRef, FC, RefObject, MouseEvent } from 'react';

import styled, { css } from 'styled-components';

import { HINTS } from 'common/constants/products.const';
import { useHint } from 'common/hooks';
import {
  IProduct,
  TaskStatuses,
  ProductionStates,
  ProductLifeState,
} from 'common/types/product.types';
import { useModalContext } from 'entities/modals';
import { usePermission, OPERATIONS } from 'entities/permission';
import { useReform, useWatch, IReform } from 'reform';
import {
  Button,
  Tooltip,
  ContextMenu,
  useContextMenu,
  ContextMenuItem,
} from 'UI';

import {
  PUBLISH_PRODUCT,
  SEND_TO_INTERNAL,
  SEND_TO_MODERATION,
} from './modals';

const TOOLTIP_PROPS = { arrow: true, hint: true, maxWidth: 240 };

const HeaderControlContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: auto;
`;

const ButtonDisabled = styled.div(
  ({ theme: { space, colors, fontSizes, borderRadius } }) => css`
    height: 34px;
    margin-left: ${space[1]}px;
    padding: ${space[1]}px 10px;
    font-size: ${fontSizes[2]}px;
    font-weight: 600;
    color: ${colors.primary.disabled};
    border-radius: ${borderRadius}px;
    cursor: default;
  `,
);

const SubmitButtonDisabled = styled(ButtonDisabled)(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    background: ${colors.highlight[1]};
    border: 1px solid rgba(0, 0, 0, 0);
  `,
);

const InternalButtonDisabled = styled(ButtonDisabled)(
  ({ theme: { colors } }) => css`
    display: inline-flex;
    border: 1px solid ${colors.primary.disabled};
  `,
);

// TODO не хватило времени, чтоб отрефакторить кнопки.
// есть 3 нюанса:
// - isEmptyStandard и hasTemplate нужно убрать, и использовать вместо этого проп disabled
// - для тултипа использовать проп hint, который приходит сверху или подумать еще вариант
// - использовать новые кнопки для состояния disaled вместо SubmitButtonDisabled и тп
// Нужна отдельная таска, так как затрагивает бизнес логику всех команд
interface IHeaderButtonProps {
  isShow?: boolean;
  onSubmit: (lifeState: string) => (event?: MouseEvent<HTMLElement>) => void;
}

interface IInternalButtonProps extends IHeaderButtonProps {
  isEmptyStandard?: boolean;
  lifeState: string;
  hasTemplate?: boolean;
  hint?: string | false;
  disabled?: boolean;
  isEdit?: boolean;
}

const InternalButton = forwardRef<any, IInternalButtonProps>(
  (
    {
      isShow = true,
      isEmptyStandard,
      hasTemplate = true,
      hint,
      lifeState,
      onSubmit,
      disabled,
      isEdit = false,
    },
    ref,
  ) => {
    const { isViewed: isViewedMakeInternalHint } = useHint(
      HINTS.MAKE_PRODUCT_INTERNAL,
    );
    const { handleModalOpen, handleModalClose } = useModalContext();
    const task = useWatch({ fieldName: 'task' });
    const { isDirty } = useReform();

    if (
      !isShow &&
      lifeState !== ProductLifeState.draft &&
      lifeState !== ProductLifeState.internalUsage
    ) {
      return null;
    }

    const handleSendInternal = (): void => {
      if (!isViewedMakeInternalHint) {
        handleModalOpen(SEND_TO_INTERNAL, {
          onConfirm: () => {
            onSubmit(ProductLifeState.internalUsage)();
            handleModalClose();
          },
        });
      } else {
        onSubmit(ProductLifeState.internalUsage)();
      }
    };

    if (!task && isEmptyStandard) {
      return (
        <Tooltip
          {...TOOLTIP_PROPS}
          title='Для продолжения выберите данные стандарта'
        >
          <InternalButtonDisabled ref={ref}>
            Назначить внутренним
          </InternalButtonDisabled>
        </Tooltip>
      );
    }

    const isInternal = lifeState === ProductLifeState.internalUsage;

    const buttonText =
      isInternal && isEdit ? 'Сохранить изменения' : 'Назначить внутренним';

    if (task && task.status !== TaskStatuses.done)
      return (
        <Tooltip
          {...TOOLTIP_PROPS}
          title='Действие доступно для задач в статусе «Готово»'
        >
          <InternalButtonDisabled ref={ref}>
            {buttonText}
          </InternalButtonDisabled>
        </Tooltip>
      );

    if (isInternal && isEdit && !isDirty) {
      return (
        <InternalButtonDisabled ref={ref}>{buttonText}</InternalButtonDisabled>
      );
    }

    const button = (
      <Button
        ref={ref}
        color='info'
        disabled={!hasTemplate || disabled}
        ml={1}
        variant='filled'
        onClick={handleSendInternal}
      >
        {buttonText}
      </Button>
    );

    if (hint) {
      return (
        <Tooltip title={hint} {...TOOLTIP_PROPS}>
          <div>{button}</div>
        </Tooltip>
      );
    }

    return button;
  },
);

interface IDraftButtonProps extends IHeaderButtonProps {
  hasTemplate?: boolean;
  hint?: string | boolean;
  disabled?: boolean;
  lifeState?: string;
  isEdit?: boolean;
}

const DraftButton = forwardRef<any, IDraftButtonProps>(
  (
    {
      isShow,
      hasTemplate = true,
      hint,
      onSubmit,
      disabled,
      lifeState,
      isEdit = false,
    },
    ref,
  ) => {
    const { isDirty } = useReform();

    if (!isShow) return null;

    const isDraft = lifeState === ProductLifeState.draft;

    const button = (
      <Button
        ref={ref}
        color='secondary'
        disabled={!hasTemplate || disabled || (isDraft && isEdit && !isDirty)}
        ml={1}
        variant='filled'
        onClick={onSubmit(ProductLifeState.draft)}
      >
        Сохранить {isDraft && isEdit ? 'изменения' : 'как черновик'}
      </Button>
    );

    if (hint) {
      return (
        <Tooltip title={hint} {...TOOLTIP_PROPS}>
          <div>{button}</div>
        </Tooltip>
      );
    }

    return button;
  },
);

interface IStayDraftButtonProps extends Omit<IHeaderButtonProps, 'isShow'> {
  disabled: boolean;
  isEdit: boolean;
}

const StayDraftButton = forwardRef<any, IStayDraftButtonProps>(
  ({ isEdit, disabled, onSubmit }, ref) => {
    const { isDirty } = useReform();
    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();

    return (
      <Fragment>
        <Button
          ref={ref}
          color='secondary'
          disabled={disabled || (isEdit && !isDirty)}
          ml={1}
          variant='filled'
          onClick={handleContextMenuOpen}
        >
          Сохранить изменения
        </Button>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          placement='top-end'
          width={186}
          onRequestClose={handleContextMenuClose}
        >
          <ContextMenuItem
            data-stay
            text='Сохранить'
            onClick={onSubmit(ProductLifeState.draft)}
          />
          <ContextMenuItem
            text='Сохранить и закрыть'
            onClick={onSubmit(ProductLifeState.draft)}
          />
        </ContextMenu>
      </Fragment>
    );
  },
);

const ModerationButtons: FC<IHeaderButtonProps & { product: IProduct }> = ({
  isShow,
  product,
  onSubmit,
}) => {
  const { lifeState } = product;

  if (!isShow) return null;

  if (lifeState === ProductLifeState.public) {
    return (
      <Fragment>
        <Button
          color='error'
          ml={1}
          onClick={onSubmit(ProductLifeState.trashed)}
        >
          Удалить
        </Button>
        <Button
          color='error'
          ml={1}
          onClick={onSubmit(ProductLifeState.blocked)}
        >
          Заблокировать
        </Button>
      </Fragment>
    );
  }

  if (lifeState === ProductLifeState.blocked) {
    return (
      <Button ml={1} onClick={onSubmit(ProductLifeState.public)}>
        Опубликовать
      </Button>
    );
  }

  return (
    <Fragment>
      <Button
        color='error'
        ml={1}
        onClick={onSubmit(ProductLifeState.rejected)}
      >
        Отклонить товар
      </Button>
      <Button ml={1} onClick={onSubmit(ProductLifeState.moderationCompleted)}>
        Одобрить товар
      </Button>
    </Fragment>
  );
};

interface ISubmitButtonProps extends IHeaderButtonProps {
  isEmptyStandard?: boolean;
  hasTemplate?: boolean;
  hint?: string | boolean;
  reformRef: RefObject<IReform>;
  lifeState: string;
  fullEditingExpiresAt: string;
  disabled?: boolean;
}

const SubmitButton = forwardRef<any, ISubmitButtonProps>(
  (
    {
      reformRef,
      lifeState,
      fullEditingExpiresAt,
      isEmptyStandard,
      hasTemplate = true,
      hint,
      onSubmit,
      disabled,
    },
    ref,
  ) => {
    const { isViewed: isViewedHint, handleToggleViewed } = useHint(
      HINTS.FULL_PRODUCT_EDIT_EXPIRES,
    );
    const { handleModalOpen, handleModalClose } = useModalContext();
    const hasPublishCompanyRegularLifeStateAccess = usePermission(
      OPERATIONS.PRODUCTS_OWN.PUBLISH_COMPANY_PRODUCT_REGULAR_LIFE_STATE,
    );

    const task = useWatch({ fieldName: 'task' });

    const handleSubmitWithPopupHelper = (): void => {
      const productData = reformRef.current!.getData();
      const { productionState } = productData;

      if (
        !isViewedHint &&
        !fullEditingExpiresAt &&
        productionState !== ProductionStates.announced
      ) {
        handleModalOpen(PUBLISH_PRODUCT, {
          products: productData,
          onConfirm: ({ isViewedHintCheck }) => {
            if (isViewedHintCheck) {
              handleToggleViewed();
            }
            handleModalClose();
            onSubmit(ProductLifeState.public)();
          },
        });
      } else {
        onSubmit(ProductLifeState.public)();
      }
    };

    const handleSendToModeration = (): void => {
      handleModalOpen(SEND_TO_MODERATION, {
        onConfirm: () => {
          handleModalClose();
          onSubmit(ProductLifeState.moderation)();
        },
      });
    };

    if (!hasPublishCompanyRegularLifeStateAccess) return null;

    let submitButtonText = 'На модерацию';
    let submitButtonAction = handleSendToModeration;

    if (lifeState === ProductLifeState.public) {
      submitButtonText = 'Сохранить';
      submitButtonAction = handleSubmitWithPopupHelper;
    } else if (lifeState === ProductLifeState.moderationCompleted) {
      submitButtonText = 'Опубликовать';
      submitButtonAction = handleSubmitWithPopupHelper;
    }

    if (!task && isEmptyStandard) {
      return (
        <Tooltip
          {...TOOLTIP_PROPS}
          title='Для продолжения выберите данные стандарта'
        >
          <SubmitButtonDisabled ref={ref}>
            {submitButtonText}
          </SubmitButtonDisabled>
        </Tooltip>
      );
    }

    if (task && task.status !== TaskStatuses.done) {
      return (
        <SubmitButtonDisabled ref={ref}>
          {submitButtonText}
        </SubmitButtonDisabled>
      );
    }

    const button = (
      <Button
        ref={ref}
        disabled={!hasTemplate || disabled}
        ml={1}
        onClick={submitButtonAction}
      >
        {submitButtonText}
      </Button>
    );

    if (hint) {
      return (
        <Tooltip title={hint} {...TOOLTIP_PROPS}>
          <div>{button}</div>
        </Tooltip>
      );
    }

    return button;
  },
);

export {
  HeaderControlContainer,
  InternalButton,
  DraftButton,
  StayDraftButton,
  ModerationButtons,
  SubmitButton,
};
