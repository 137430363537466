import { Fragment, useMemo, FC } from 'react';

import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { fetchProductRetailersOptions } from 'common/api/manufacturing-requests.api';
import { getImageURL } from 'common/utils';
import { hasIdentity } from 'entities/identity';
import { Field, useWatch } from 'reform';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { itemTo } from 'tools/utils';
import {
  Text,
  Avatar,
  Status,
  Tooltip,
  Container,
  CheckboxField,
  SideMenuGroup,
  TextInputField,
  MultiSelectField,
} from 'UI';
import { ItemToStringAndOptionsType } from 'UI/Inputs';

import { ActivityKindsFilter } from './ActivityKinds';
import { RegisterAndAuthText } from '../../../RegisterAndAuthText';

const GroupWrapper = styled(SideMenuGroup)`
  padding-left: 0;
`;

const disabledCheckText = (
  <Fragment>
    Для работы с партнерами <RegisterAndAuthText /> как юридическое лицо.
  </Fragment>
);

const itemToStringProduct: ItemToStringAndOptionsType = (
  item,
  { isOption }: { isOption?: boolean } = {},
): JSX.Element | string => {
  if (!isOption) {
    return item.name;
  }

  return (
    <Container alignItems='center' width='100%'>
      <Avatar mr={2} size='xxs' url={getImageURL(item.logo)} />
      <Container flexDirection='column' minWidth='280px'>
        <Text truncate lineHeight='15px'>
          {item.name}
        </Text>
        <Text color='text.disabled' fontSize={2} lineHeight='15px'>
          {item && item['@type'] === 'Company' ? 'Производитель' : 'Поставщик'}
        </Text>
      </Container>
      {item.isPartner && (
        <Status ml='auto' size='14' title='Мой партнер' type='success' />
      )}
    </Container>
  );
};

const transform = (value: string): string => value.trim();

const PointsOfSaleFiltersForm: FC<{ productId: string }> = ({ productId }) => {
  const isAuthed = useSelector(hasIdentity);

  const isPartner = useWatch({ fieldName: 'onlyPartners' });

  const retailersOptions = useMemo(() => {
    return async ({ name, page }) => {
      return fetchProductRetailersOptions({
        isPartner,
        id: productId,
        search: name,
        page,
      });
    };
  }, [productId, isPartner]);

  return (
    <Fragment>
      <GroupWrapper title='Местоположение'>
        <Field
          component={TextInputField}
          mt={1}
          name='location'
          placeholder='Введите страну, регион, район или неселенный пункт'
          transform={transform}
        />
      </GroupWrapper>
      <GroupWrapper title='Компания'>
        {isAuthed ? (
          <Field
            component={CheckboxField}
            label='Только партнеры'
            mb={2}
            mt={1}
            name='onlyPartners'
          />
        ) : (
          <Tooltip arrow hint title={disabledCheckText}>
            <div>
              <Field
                disabled
                component={CheckboxField}
                label='Только партнеры'
                mb={2}
                mt={1}
                name='onlyPartners'
              />
            </div>
          </Tooltip>
        )}
        <Field
          component={MultiSelectField}
          itemToKey={itemTo('name')}
          itemToString={itemToStringProduct}
          name='companyId'
          options={retailersOptions}
          placeholder='Введите название компании или ИНН'
        />
      </GroupWrapper>
      <GroupWrapper
        title={
          <Container alignItems='center' display='inline-flex'>
            <Text>Вид деятельности</Text>
            <Tooltip
              arrow
              maxWidth={300}
              title='Вид деятельности, которыми занимается компания в рамках места продаж.'
            >
              <Help1pxIcon ml={0} />
            </Tooltip>
          </Container>
        }
      >
        <ActivityKindsFilter />
      </GroupWrapper>
    </Fragment>
  );
};

export { PointsOfSaleFiltersForm };
