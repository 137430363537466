import { Dispatch, FC, Fragment, SetStateAction } from 'react';

import styled, { css } from 'styled-components';
import { position, PositionProps } from 'styled-system';

import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { getFileURL } from 'common/utils';
import { ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { IconButton } from 'UI';

const CLOSE_ICON_SIZE = 70;

const BackButton = styled(IconButton)<PositionProps>(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    border: 1px solid ${colors.background};
    border-radius: 0;

    &:hover {
      background: ${colors.white};
    }

    ${position}
  `,
);

const Gallery = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    margin-left: 70px;
    padding-top: ${CLOSE_ICON_SIZE}px;
    padding-bottom: ${CLOSE_ICON_SIZE}px;
    background: ${colors.white};
  `,
);

interface IImageSideBarAddonProps {
  sidebarWidth: number;
  selectedImage: IImage;
  setSelectedImage: Dispatch<SetStateAction<Nullable<IImage>>>;
}

const ImageSideBarAddon: FC<IImageSideBarAddonProps> = ({
  selectedImage,
  setSelectedImage,
  sidebarWidth,
}) => {
  return (
    <Fragment>
      <Gallery>
        <img
          alt='img'
          src={getFileURL(selectedImage)}
          style={{ maxHeight: '100%' }}
        />
      </Gallery>
      <BackButton
        right={sidebarWidth}
        size={CLOSE_ICON_SIZE}
        onClick={() => setSelectedImage(null)}
      >
        <ArrowRight1pxIcon size={22} />
      </BackButton>
    </Fragment>
  );
};

export { ImageSideBarAddon };
