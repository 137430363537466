import { FC, Fragment, ReactNode, useMemo } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';

import { fetchVendorCodes } from 'common/api/dictionaries.api';
import { useRequest, useUnit } from 'common/hooks';
import { IPackage } from 'common/types/product.types/accompany-information.types';
import { getFormattedNumber, getImageURL } from 'common/utils';
import { itemToRecyclingCodesString } from 'common/utils/dictionaries';
import {
  BaseImageBackground,
  IBaseImageBackground,
} from 'components/media/styled';
import { hasIdentity } from 'entities/identity';
import { ArrowLeft1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { Help1pxIcon } from 'resources/icons/1px-18';
import {
  Text,
  Divider,
  FileLoaded,
  DescriptionList,
  Heading,
  Tooltip,
  VerticalDivider,
  Container,
} from 'UI';

import { PACKAGES_KINDS_INFO } from './Card';
import { ProductPackagesCategoryAvatar } from './CategoryAvatar';

const HEADER_HEIGHT = 70;
const CONTROLS_HEIGHT = 98;

const PackageInfoHeader = styled(Text)(
  ({ theme: { colors, space, fontSizes } }) => css`
    position: absolute;
    width: 100%;
    height: ${HEADER_HEIGHT}px;
    padding: 23px ${space[4]}px;
    background: ${colors.background};
    font-size: ${fontSizes[6]}px;
  `,
);

const Grid = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    display: grid;
    min-width: 448px;
    grid-template-columns: repeat(auto-fill, 100px);
    grid-auto-rows: minmax(100px, auto);
    grid-gap: ${space[2]}px;
    margin-bottom: ${space[3]}px;
  `,
);

const ImageBackground = styled(BaseImageBackground)<
  IBaseImageBackground & { active?: boolean; onClick?: any }
>(
  ({ theme: { colors }, active = false }) => css`
    width: 100px;
    height: 100px;
    position: relative;
    border: 1px solid ${active ? colors.text.primary : colors.divider};
    background-size: contain;
    cursor: pointer;
  `,
);

const ControlsWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: ${CONTROLS_HEIGHT}px;
    border-top: 1px solid ${colors.background};
    background: ${colors.white};
  `,
);

const ControlWrapper = styled.div<{ isLeft: boolean } & SpaceProps>(
  ({ theme: { borderRadius, colors, space }, isLeft = false }) => css`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 240px;
    height: 66px;
    margin-left: auto;
    border-radius: ${borderRadius}px;
    background: ${colors.background};
    cursor: pointer;

    ${isLeft
      ? css`
          margin-left: ${space[2]}px;
          padding-left: 6px;
          padding-right: ${space[1]}px;
        `
      : css`
          margin-right: ${space[2]}px;
          padding-left: ${space[2]}px;
          padding-right: 6px;
        `}
  `,
);

interface IProperties extends IPackage {
  unit?: any;
  totalItems?: number;
}

const Properties: FC<IProperties> = ({
  canBeReused,
  unit,
  totalItems,
  grossWeight,
  size,
  packingAids,
  vendorCode,
  recyclingCode,
}) => {
  const { data: barCodes = [] } = useRequest(fetchVendorCodes, [], {
    responseToData: ({ member }) => member,
  });

  const { unitToString } = useUnit();

  const barCodeToName = useMemo(
    () =>
      barCodes.reduce(
        (acc, { name, value }) => ({ ...acc, [value]: name }),
        {},
      ),
    [barCodes],
  );

  return (
    <Fragment>
      <Divider my={3} />
      <Heading as='h5' mb={2}>
        Информация об упаковке
      </Heading>
      <DescriptionList dotted mb={3}>
        <DescriptionList.Property
          label={`Общее количество товара, ${unitToString(unit, {
            isSkipDash: true,
          })}`}
        >
          {getFormattedNumber(totalItems)}
        </DescriptionList.Property>
        <DescriptionList.Property
          label={`Масса брутто, ${unitToString(grossWeight.unit, {
            isSkipDash: true,
          })}`}
        >
          {getFormattedNumber(grossWeight.value)}
        </DescriptionList.Property>
        <DescriptionList.Property
          label={`Габариты, ${unitToString(size.unit, { isSkipDash: true })}`}
        >
          {Object.values(size.value)
            .map(i => getFormattedNumber(i))
            .join(' x ')}
        </DescriptionList.Property>
        {packingAids.length > 0 && (
          <DescriptionList.Property label='Вспомогательные упаковочные средства'>
            {packingAids.map(({ name }) => name).join(', ')}
          </DescriptionList.Property>
        )}
        {vendorCode?.code && vendorCode?.value && (
          <DescriptionList.Property
            label={`Код производителя, ${
              barCodeToName[vendorCode.code.code ?? vendorCode.code]
            }`}
          >
            {vendorCode.value}
          </DescriptionList.Property>
        )}
        {recyclingCode && (
          <DescriptionList.Property label='Код переработки'>
            <Container alignItems='center'>
              {itemToRecyclingCodesString(recyclingCode, false)}
              <Tooltip arrow hint title={recyclingCode.name}>
                <Help1pxIcon ml={1} />
              </Tooltip>
            </Container>
          </DescriptionList.Property>
        )}
        <DescriptionList.Property label='Переиспользование упаковки'>
          {canBeReused
            ? 'Подходит для повторного использования'
            : 'Не подходит для повторного использования'}
        </DescriptionList.Property>
      </DescriptionList>
    </Fragment>
  );
};

const MediaBlock: FC<{ title: string; children: ReactNode }> = ({
  title,
  children,
}) => {
  return (
    <Fragment>
      <Heading as='h5' mb={2}>
        {title}
      </Heading>
      {children}
    </Fragment>
  );
};

interface IControlPackage {
  isLeft?: boolean;
  category: string;
  packageDepth: any;
  packageTypeName: any;
  onClick: any;
}

const ControlPackage: FC<IControlPackage> = ({
  isLeft = false,
  category,
  packageDepth,
  packageTypeName,
  onClick,
}) => {
  return (
    <ControlWrapper isLeft={isLeft} onClick={onClick}>
      {isLeft && (
        <Fragment>
          <ArrowLeft1pxIcon size={16} />
          <VerticalDivider height={46} ml='6px' mr={2} />
        </Fragment>
      )}
      <ProductPackagesCategoryAvatar category={category} size={34} />
      <Container column minWidth={0} ml={1}>
        <Text truncate fontSize={2} fontWeight={600}>
          {packageDepth}
        </Text>
        <Text truncate color='text.secondary' fontSize={2}>
          {packageTypeName}
        </Text>
      </Container>
      {!isLeft && (
        <Fragment>
          <VerticalDivider height={46} ml={2} mr='6px' />
          <ArrowRight1pxIcon size={16} />
        </Fragment>
      )}
    </ControlWrapper>
  );
};

interface IProductPackageDetailInfo {
  packageVariationName: any;
  productPackageUnit: any;
  productPackage: IPackage;
  prevProductPackage: IPackage;
  nextProductPackage: IPackage;
  setSelectedImage: any;
  onChangePrevProductPackage: any;
  onChangeNextProductPackage: any;
}

const ProductPackageDetailInfo: FC<IProductPackageDetailInfo> = ({
  packageVariationName,
  productPackageUnit,
  productPackage,
  prevProductPackage,
  nextProductPackage,
  setSelectedImage,
  onChangePrevProductPackage,
  onChangeNextProductPackage,
}) => {
  const {
    packageType,
    kindOfPackage,
    media: { images, documents },
  } = productPackage;
  const isAuthed = useSelector(hasIdentity);

  return (
    <Container display='block' height='100%'>
      <PackageInfoHeader truncate>{packageVariationName}</PackageInfoHeader>
      <div style={{ paddingTop: `${HEADER_HEIGHT}px` }}>
        <div
          style={{
            overflowY: 'auto',
            height: `calc(100vh - ${HEADER_HEIGHT + CONTROLS_HEIGHT}px)`,
          }}
        >
          <Container column padding={[3, 4]}>
            <Container>
              <ProductPackagesCategoryAvatar category={packageType.category} />
              <Container
                column
                height='100%'
                minWidth={0}
                ml={2}
                position='relative'
              >
                <Text truncate color='text.primary' fontWeight={600}>
                  {packageType.name}
                </Text>
                <Text color='text.secondary' fontSize={2}>
                  {PACKAGES_KINDS_INFO[kindOfPackage].title}
                </Text>
              </Container>
            </Container>
            <Properties {...productPackage} unit={productPackageUnit} />
            {images.length > 0 && (
              <MediaBlock title='Фото упаковки'>
                <Grid>
                  {images.map(image => (
                    <ImageBackground
                      key={image.id}
                      url={getImageURL(image, { size: 'l' })}
                      onClick={() => {
                        setSelectedImage(image);
                      }}
                    />
                  ))}
                </Grid>
              </MediaBlock>
            )}
            {documents.length > 0 && (
              <MediaBlock title='Документы'>
                <Container column gap={1}>
                  {documents.map(file => (
                    <FileLoaded
                      key={file.id}
                      hovered
                      download={isAuthed}
                      file={file}
                    />
                  ))}
                </Container>
              </MediaBlock>
            )}
          </Container>
        </div>
      </div>
      <ControlsWrapper>
        {prevProductPackage && (
          <ControlPackage
            isLeft
            category={prevProductPackage.packageType.category}
            packageDepth='Внешняя упаковка'
            packageTypeName={prevProductPackage.packageType.name}
            onClick={onChangePrevProductPackage}
          />
        )}
        {nextProductPackage && (
          <ControlPackage
            category={nextProductPackage.packageType.category}
            packageDepth='Вложенная упаковка'
            packageTypeName={nextProductPackage.packageType.name}
            onClick={onChangeNextProductPackage}
          />
        )}
      </ControlsWrapper>
    </Container>
  );
};

export { ProductPackageDetailInfo };
