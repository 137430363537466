import { Fragment, useCallback, FC, MutableRefObject } from 'react';

import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import styled, { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { useUnit } from 'common/hooks';
import { PropertiesTypes } from 'common/types/properties.types';
import { createConstraintElement } from 'common/utils/properties';
import { ViewTemplates } from 'components/characteristic-elements/ViewMode';
import { useAppSelector } from 'store';
import { useToggle } from 'tools/hooks';
import { IconButton, Tooltip } from 'UI';

import { removeAddedProperty } from './store/actions';
import {
  isGroupAdded,
  renderProperties,
  removePropertyFromGroup,
  getNotAddedPropertiesCopy,
} from './utils';
import { ProductFormsPropertiesView } from '../../../../features/admin/product-info/product-forms/components/View';
import { getInitialValueOfProperty } from '../../../../features/products/create/_shared/properties/_shared/utils';
import { PropertiesNestedView } from '../../../../features/products/create/_shared/properties/_shared/View';
import { TEXT_ADDING, TEXT_REMOVAL } from '../constants';
import { PropertyView } from '../reuse/components/adding-parameters/property-parameters/components';
import { NextIconStyled, PrevIconStyled } from '../styled';

const GroupAdder = styled.div`
  cursor: pointer;
  width: 100%;
`;

interface IPropertyProps {
  isShowValues?: boolean;
  isShowConstraints?: boolean;
  formPropertyHasBeenAddedRef: MutableRefObject<boolean>;
  level: number;
  item: any;
  parentUids?: number[];
  setProperties: (data: any) => void;
}

const Property: FC<IPropertyProps> = ({
  isShowValues,
  isShowConstraints,
  formPropertyHasBeenAddedRef,
  level,
  item,
  parentUids,
  setProperties,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const addedProperties = useAppSelector(
    ({ productForms }) => new Set(productForms.addedProperties),
  );

  const { unitToString } = useUnit();
  const { t } = useTranslation('measurement-units');

  const [collapse, toggleCollapse]: [boolean, () => void] = useToggle(false);

  const isAdded = addedProperties.has(
    item.uniquenessId ?? item.uniquenessIds[0],
  );
  const tooltipText = isAdded ? TEXT_REMOVAL : TEXT_ADDING;

  const { value: defaultItemValue, ...otherItemProps } = item ?? {};
  const itemCopy = {
    ...getInitialValueOfProperty(item.type),
    ...otherItemProps,
    id: uuid(),
    type: item.type,
  };

  const handlePush = useCallback((): void => {
    formPropertyHasBeenAddedRef.current = true;

    if (item.type === PropertiesTypes.group) {
      const value = getNotAddedPropertiesCopy(
        defaultItemValue,
        addedProperties,
        isShowValues,
      );

      setProperties(prev => [...prev, { ...itemCopy, value }]);
    } else {
      setProperties(prev => {
        return [
          ...prev,
          {
            ...itemCopy,
            value: isShowValues ? defaultItemValue : itemCopy.value,
          },
        ];
      });
    }
  }, [
    isShowValues,
    item,
    itemCopy,
    addedProperties,
    defaultItemValue,
    formPropertyHasBeenAddedRef,
    setProperties,
  ]);

  const handleRemove = useCallback((): void => {
    formPropertyHasBeenAddedRef.current = false;

    setProperties(prev => {
      if (parentUids) {
        return removePropertyFromGroup(item.uniquenessId, prev, parentUids);
      }

      return prev.filter(
        ({ uniquenessId }) => uniquenessId !== item.uniquenessId,
      );
    });

    dispatch(removeAddedProperty(item.uniquenessId));
  }, [parentUids, item, formPropertyHasBeenAddedRef, dispatch, setProperties]);

  const leftActions = useCallback(
    (isPropertyAdded: boolean) => (
      <IconButton onClick={isPropertyAdded ? handleRemove : handlePush}>
        {isPropertyAdded ? <NextIconStyled /> : <PrevIconStyled />}
      </IconButton>
    ),
    [handlePush, handleRemove],
  );

  if (item.type === PropertiesTypes.group) {
    const isAddedGroup = isGroupAdded(item, addedProperties);

    return (
      <PropertiesNestedView
        key={item.uniquenessId}
        fullWidthRightActions
        visibleRightActions
        bg='highlight.1'
        collapse={collapse}
        disabled={isAddedGroup}
        height={34}
        label={item.name}
        leftActions={leftActions(isAddedGroup)}
        mt={2}
        rightActions={
          <GroupAdder onClick={isAddedGroup ? handleRemove : handlePush} />
        }
        type={item.type}
        onToggleCollapse={toggleCollapse}
      >
        <ViewTemplates.LevelBody>
          {renderProperties({
            isShowValues,
            isShowConstraints,
            formPropertyHasBeenAddedRef,
            level: level + 1,
            properties: item.value,
            parentUids: parentUids
              ? [...parentUids!, item.uniquenessId]
              : [item.uniquenessId],
            setProperties,
          })}
        </ViewTemplates.LevelBody>
      </PropertiesNestedView>
    );
  }

  if (isShowValues) {
    return (
      <PropertyView
        isAdded={isAdded}
        level={level}
        property={item}
        onClick={isAdded ? handleRemove : handlePush}
      />
    );
  }

  return (
    <Fragment>
      <Tooltip pointer placement='bottom' title={tooltipText}>
        <ProductFormsPropertiesView
          key={item.uniquenessId}
          hideRightActions
          aria-label={`${TEXT_ADDING} ${item.name}`}
          leftActions={leftActions(isAdded)}
          level={level}
          mt={2}
          property={item}
          readOnly={isAdded}
          style={{
            cursor: isAdded ? 'default' : 'pointer',
            fontSize: theme.fontSizes[3],
          }}
          translation={t}
          onClick={isAdded ? handleRemove : handlePush}
        />
      </Tooltip>
      {isShowConstraints &&
        'constraints' in item &&
        item.constraints &&
        createConstraintElement(item, unitToString(item.unit), isAdded)}
    </Fragment>
  );
};

export { Property };
