import { FC } from 'react';

import { get } from 'lodash';
import styled, { css } from 'styled-components';
import { SpaceProps, space as styledSpace } from 'styled-system';

import { IIdentity } from 'common/types/identity.types';
import { IManufacturers } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { COMPANY, getPath } from 'routes';
import { Link } from 'tools/libs';
import { Avatar, Tooltip } from 'UI';

const MAX_PREVIEW = 4;

const AvatarGroupContainer = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    display: flex;

    & > a:not(:first-child) {
      margin-left: ${-space[1]}px;
    }

    & > div {
      margin-left: ${-space[1]}px;
    }

    ${styledSpace}
  `,
);

const AvatarMoreCounter = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    position: relative;
    min-height: ${space[3]}px;
    min-width: ${space[3]}px;
    width: ${space[3]}px;
    height: ${space[3]}px;
    line-height: ${space[3]}px;
    border-radius: 50%;
    border: 1px solid ${colors.white};
    background: ${colors.background};
    color: ${colors.text.primary};
    font-weight: 700;
    font-size: ${fontSizes[0]}px;
    text-align: center;
  `,
);

interface IAvatarGroupProps {
  title?: string;
  data: IManufacturers[] | IIdentity[];
  path: [string, string];
}

const AvatarGroup: FC<IAvatarGroupProps & SpaceProps> = ({
  title,
  data,
  path: [urlPath, letterPath],
  ...other
}) => {
  const visibleLogo =
    data.length > MAX_PREVIEW ? data.slice(0, MAX_PREVIEW - 1) : data;

  const hiddenLogos = data.length - visibleLogo.length;

  return (
    <Tooltip arrow hint title={title}>
      <AvatarGroupContainer {...other}>
        {visibleLogo.map((item: IManufacturers | IIdentity) => {
          return (
            <Avatar
              key={item.id}
              cover
              rounded
              as={Link}
              borderColor='colors.white'
              letter={get(item, letterPath)}
              size='xxs'
              to={getPath(COMPANY.CARD, { id: item.id })}
              url={getImageURL(get(item, urlPath), { size: 'l' })}
            />
          );
        })}
        {hiddenLogos > 0 && (
          <AvatarMoreCounter>{`+${hiddenLogos}`}</AvatarMoreCounter>
        )}
      </AvatarGroupContainer>
    </Tooltip>
  );
};

export { AvatarGroup };
