import { ReactElement } from 'react';

import { get } from 'lodash';
import styled from 'styled-components';
import { space, SpaceProps } from 'styled-system';

import { Checkbox, ICheckboxProps } from './Checkbox';
import { FormError } from '../FormError';

const Container = styled.div<SpaceProps>`
  ${space};
`;

interface ICheckboxFieldProps extends ICheckboxProps, SpaceProps {
  field: any;
  form: any;
}

function CheckboxField({
  className,
  style,
  disabled,
  required,
  indeterminate,
  tip,
  checked,
  label,
  labelColor,
  form,
  fontWeight,
  fontSize,
  field: { name, value },
  ...other
}: ICheckboxFieldProps): ReactElement {
  const error = get(form.errors, name);

  const handleChange = ({ target }): void => {
    form.setFieldValue(name, target.value !== 'true');
  };

  return (
    <Container className={className} style={style} {...other}>
      <Checkbox
        checked={checked}
        disabled={disabled}
        fontSize={fontSize}
        fontWeight={fontWeight}
        indeterminate={indeterminate}
        label={label}
        labelColor={labelColor}
        mb='0px'
        name={name}
        required={required}
        tip={tip}
        value={value}
        onChange={handleChange}
      />
      {error && <FormError show={error}>{error}</FormError>}
    </Container>
  );
}

export { CheckboxField };
