import { ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Divider } from '../Divider';
import { Text } from '../Text';

const Container = styled.div`
  ${styledSpace}
`;

const TabsWrapper = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    display: flex;
    flex-wrap: wrap;
    gap: ${space[0]}px;

    ${styledSpace}
  `,
);

interface ITabButtonsPanel {
  title?: string;
  children: ReactNode;
}

function TabButtonsPanel({
  title,
  children,
  ...other
}: ITabButtonsPanel & SpaceProps): ReactElement {
  return (
    <Container {...other}>
      <Divider mb={2} mt={4} mx={-4} />
      {title && (
        <Text fontSize={4} fontWeight={500} mb={2}>
          {title}
        </Text>
      )}
      <TabsWrapper>{children}</TabsWrapper>
    </Container>
  );
}

export { TabButtonsPanel, TabsWrapper };
