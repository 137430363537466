import { FC, Fragment, memo, useCallback, useEffect, useState } from 'react';

import { fetchManufacturedNtds, fetchRetailedNtds } from 'common/api/ntd.api';
import { NOT_FOUND_PLACEHOLDER } from 'common/constants/notFoundPlaceholder.const';
import { useRequest } from 'common/hooks';
import { INtdReference } from 'common/types/form.types';
import { IManufacturedNtd } from 'common/types/manufacturing-requests.types';
import { getFormattedNumber, getImageURL } from 'common/utils';
import { CompanyTitle } from 'components/company';
import { LinkOutsideIcon } from 'resources/icons/18';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { getPath, PAGES, COMPANY } from 'routes';
import { useToggle } from 'tools/hooks';
import { Link } from 'tools/libs';
import {
  Avatar,
  Badge,
  Button,
  Container,
  Heading,
  IconButton,
  PaginationButton,
  SearchInput,
  Spinner,
  Text,
} from 'UI';

import { CardMainInfo, CardWrapper, MultipleCard } from './styled';

const ITEMS_PER_PAGE = 5;

export interface IManufacturedNtdProps {
  isRetailedNtd?: boolean;
  id?: string;
  ntd: INtdReference;
  manufacturedNtds?: IManufacturedNtd[];
}

const NtdCard: FC<IManufacturedNtdProps> = memo(
  ({
    id,
    ntd: {
      name,
      previewImage,
      stats: { standardsCount, assortmentsCount },
      numericName,
      id: ntdId,
    },
    isRetailedNtd = false,
    manufacturedNtds,
  }) => {
    const [openState, handleStateToggle] = useToggle(false);
    const url = isRetailedNtd
      ? getPath(PAGES.NTD, { id: ntdId })
      : getPath(PAGES.MANUFACTURED_NTD, { id });

    const letterOutput = isRetailedNtd ? `${name}` : `${numericName} ${name}`;

    const ntdsCount = assortmentsCount || standardsCount;

    return (
      <CardWrapper>
        <Container alignItems='center'>
          <Avatar as={Link} size='l' to={url} url={getImageURL(previewImage)} />
          <CardMainInfo>
            <Heading as='h5' fontWeight={500} my={0}>
              <Text truncate as={Link} to={url}>
                {letterOutput}
              </Text>
            </Heading>
            <Text color='text.secondary' ml='auto'>
              Количество вариаций: {getFormattedNumber(ntdsCount)}
            </Text>
          </CardMainInfo>
          {isRetailedNtd && (
            <Button
              color='secondary'
              ml='auto'
              mr={2}
              variant='text'
              onClick={() => handleStateToggle(!openState)}
            >
              <Container alignItems='center' display='inline-flex'>
                <Text mr={1}>{`${
                  openState ? 'Скрыть' : ' Показать'
                } производителей`}</Text>
                {openState ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </Container>
            </Button>
          )}
        </Container>
        {isRetailedNtd && openState && (
          <Fragment>
            {manufacturedNtds?.map(
              ({ manufacturer, id: manufacturedNtdId }) => (
                <MultipleCard key={manufacturer.id}>
                  <Avatar
                    as={Link}
                    letter={manufacturer.name}
                    mr={2}
                    size='s'
                    target='_blank'
                    to={getPath(COMPANY.CARD, { id: manufacturer.id })}
                    url={getImageURL(manufacturer.logo)}
                  />
                  <Container column pr={2}>
                    <Badge
                      fontSize='8px'
                      fontWeight={600}
                      label='Производится'
                      letterSpacing='0.5px'
                      mb={0}
                      textTransform='uppercase'
                    />
                    <CompanyTitle
                      truncate
                      as='a'
                      fontSize={3}
                      fontWeight={500}
                      href={getPath(COMPANY.CARD, { id: manufacturer.id })}
                      target='_blank'
                    >
                      {manufacturer.name}
                    </CompanyTitle>
                  </Container>
                  <IconButton
                    as={Link}
                    ml='auto'
                    mr={2}
                    size={34}
                    title='Перейти к НТД в производстве'
                    to={getPath(PAGES.MANUFACTURED_NTD, {
                      id: manufacturedNtdId,
                    })}
                  >
                    <LinkOutsideIcon />
                  </IconButton>
                </MultipleCard>
              ),
            )}
          </Fragment>
        )}
      </CardWrapper>
    );
  },
);

interface ICompanyManufacturedNtdsProps {
  isRetailedNtds?: boolean;
  companyId: string;
}

const CombinedNtdsSection: FC<ICompanyManufacturedNtdsProps> = ({
  isRetailedNtds = false,
  companyId,
}) => {
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [ntds, setNtds] = useState<any>([]);

  const fetchFunction = isRetailedNtds
    ? fetchRetailedNtds
    : fetchManufacturedNtds;

  const { isLoading, data: { totalItems } = { totalItems: 0 } } = useRequest(
    fetchFunction,
    [
      {
        search: searchValue,
        companyId,
        retailer: companyId,
        page,
        itemsPerPage: ITEMS_PER_PAGE,
      },
    ],
    {
      onSuccess: ({ member }: { member: IManufacturedNtd[] }) => {
        setNtds(prev => [...prev, ...member]);
      },
    },
  );

  useEffect(() => {
    setNtds([]);
    setPage(1);
  }, [searchValue]);

  useEffect(() => {
    setSearchValue('');
  }, [isRetailedNtds]);

  const isPaginationVisible = ntds?.length < totalItems && ntds?.length !== 0;

  const handleSearch = useCallback((value = ''): void => {
    setSearchValue(value);
  }, []);

  const handleNextPage = useCallback((): void => {
    setPage(prev => prev + 1);
  }, []);

  if (!isLoading && !searchValue && totalItems === 0) return null;

  return (
    <Fragment>
      <SearchInput
        isBordered
        iconVariant='gray'
        initialValue={searchValue}
        placeholder='Номер и название НТД'
        onChange={handleSearch}
      />
      {isRetailedNtds ? (
        <Spinner delay={0} height='300px' loading={isLoading && page === 1}>
          {ntds.map(({ id, manufacturedNTDs }) => (
            <NtdCard
              key={id}
              isRetailedNtd
              manufacturedNtds={manufacturedNTDs}
              ntd={id}
            />
          ))}
        </Spinner>
      ) : (
        <Spinner delay={0} height='300px' loading={isLoading && page === 1}>
          {ntds.map(({ id, ntd }) => (
            <NtdCard key={id} id={id} ntd={ntd} />
          ))}
        </Spinner>
      )}
      {!isLoading && ntds.length === 0 ? NOT_FOUND_PLACEHOLDER : null}
      {isPaginationVisible && (
        <PaginationButton onClick={handleNextPage}>
          <Spinner delay={0} loading={isLoading && page !== 1} size='xs'>
            Показано {ntds.length} из {totalItems}. Показать еще
          </Spinner>
        </PaginationButton>
      )}
    </Fragment>
  );
};
export { CombinedNtdsSection };
