import {
  createContext,
  Dispatch,
  FC,
  memo,
  ReactNode,
  useContext,
  useRef,
} from 'react';

import { Placement } from '@popperjs/core';
import {
  UseComboboxGetItemPropsOptions,
  UseComboboxReturnValue,
} from 'downshift';
import { FixedSizeList } from 'react-window';
import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { STUB_FUNC } from 'tools/utils/stubFunctions';

import { InfiniteScroll, ITEMS } from './InfiniteScroll';
import { Dropdown } from '../../Dropdown';
import { Spinner } from '../../Indicators/Spinner';
import { Tag } from '../../Tags';
import { Text } from '../../Text';
import {
  DisableItemType,
  FetchOptionsType,
  IAsyncOptionAction,
  IAsyncOptionState,
  IOption,
  ItemToKeyType,
  ItemToStringAndOptionsType,
  ItemToStringType,
} from '../types';

const INITIAL_HEIGHT_ITEM = 34;

const InlineOptionsWrapper = styled(Dropdown)(
  ({ theme: { space } }) => css`
    display: flex;
    flex-wrap: wrap;
    padding: ${space[1]}px ${space[2]}px 6px;
    max-height: 179px;
    overflow-y: auto;
    overflow-x: hidden;
  `,
);

const OptionWrapper = styled(Text)<{
  disabled?: boolean;
  noPadding?: boolean;
  highlighted?: boolean;
}>(
  ({ theme: { colors, space }, disabled, highlighted, noPadding }) => css`
    height: ${space[4]}px;
    line-height: ${space[4]}px;
    padding: 0 ${noPadding ? 0 : space[2]}px;
    background: ${highlighted ? colors.highlight[0] : 'none'};
    cursor: pointer;
    transition: 0.2s ease-in-out background;

    ${disabled &&
    css`
      color: ${colors.text.disabled};
      cursor: default;

      img[alt='avatar'] {
        opacity: 0.6;
      }
    `}
  `,
);

const OptionIndicator = styled(OptionWrapper)(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: center;
    color: ${colors.text.secondary};
    cursor: default;
    white-space: nowrap;
  `,
);

interface IOptionContext<Item = any> {
  highlightedIndex: number;
  itemToString: ItemToStringAndOptionsType<Item>;
  getItemProps: (options: UseComboboxGetItemPropsOptions<Item>) => any;
  placeholder?: ReactNode;
  disableItem?: DisableItemType<Item>;
}

const OptionContext = createContext<IOptionContext>({
  highlightedIndex: -1,
  itemToString: () => '',
  getItemProps: STUB_FUNC.NOOP,
});

const filterBySelectedItems = <Item extends any = any>(
  items: Item[],
  selectedItems: Item[],
  itemToKey: ItemToKeyType<Item>,
): Item[] => {
  return selectedItems
    ? items.filter(item => {
        return !selectedItems.some(
          valueItem => itemToKey(valueItem) === itemToKey(item),
        );
      })
    : items;
};

const filterByInputValue = <Item extends any = any>(
  items: Item[],
  inputValue: string,
  itemToString: ItemToStringType<Item>,
): Item[] => {
  return items.filter(item => {
    return itemToString(item)
      ?.toLowerCase()
      ?.includes(inputValue.toLowerCase());
  });
};

type FilteredItemsWithoutFilterByInputValueType<Item = any> = {
  isFilteredByInputValue?: false;
  items: Item[];
  selectedItems: Item[];
  itemToKey: ItemToKeyType<Item>;
  inputValue?: string;
  itemToString?: ItemToStringType<Item>;
};

type FilteredItemsFullType<Item = any> = {
  isFilteredByInputValue: true;
  items: Item[];
  selectedItems: Item[];
  inputValue: string;
  itemToKey: ItemToKeyType<Item>;
  itemToString: ItemToStringType<Item>;
};

const getFilteredItems = <Item extends any = any>({
  isFilteredByInputValue,
  items,
  selectedItems,
  inputValue,
  itemToKey,
  itemToString,
}: typeof isFilteredByInputValue extends true
  ? FilteredItemsFullType<Item>
  : FilteredItemsWithoutFilterByInputValueType<Item>): Item[] => {
  const filteredByValue = filterBySelectedItems<Item>(
    items,
    selectedItems,
    itemToKey,
  );

  return isFilteredByInputValue
    ? filterByInputValue<Item>(filteredByValue, inputValue, itemToString)
    : filteredByValue;
};

interface IGetItems<Item = any>
  extends Partial<Pick<IAsyncOptionState, 'isLoading' | 'hasNextPage'>> {
  hasPlaceholder: boolean;
  filteredItems: Item[];
}

const getItems = <Item extends any = any>({
  isLoading,
  hasPlaceholder,
  hasNextPage,
  filteredItems,
}: IGetItems<Item>): Item[] => {
  /**
   * Если у нас еще имеются незагруженные страницы (hasNextPage), то добавляем ITEMS.LOADING
   * он служит флагом для компонента InfiniteLoader
   */
  if (hasNextPage || isLoading) {
    return [...filteredItems, ITEMS.LOADING as Item];
  }

  if (filteredItems.length === 0) {
    return hasPlaceholder ? [ITEMS.PLACEHOLDER as Item] : [];
  }

  return filteredItems;
};

const Option = memo<IOption>(({ style, index, data }) => {
  const {
    highlightedIndex,
    itemToString,
    getItemProps,
    placeholder = 'Ничего не найдено',
    disableItem,
  } = useContext(OptionContext);

  const item = data[index];
  const disabled = disableItem ? disableItem(item) : false;

  if (item?.id === ITEMS.VALIDATE.id) {
    return <OptionIndicator style={style}>{item.error}</OptionIndicator>;
  }

  if (item === ITEMS.PLACEHOLDER) {
    return <OptionIndicator style={style}>{placeholder}</OptionIndicator>;
  }

  if (item === ITEMS.LOADING) {
    return (
      <OptionIndicator style={style}>
        <Spinner delay={0} size='xs' />
      </OptionIndicator>
    );
  }

  return (
    <OptionWrapper
      truncate
      highlighted={highlightedIndex === index}
      style={style}
      {...getItemProps({
        index,
        item,
        disabled,
      })}
    >
      {itemToString(item, { index, isOption: true, disabled })}
    </OptionWrapper>
  );
});

interface IOptions<Item = any> {
  inline?: boolean;
  hasPlaceholder?: boolean;
  disablePortal?: boolean;
  placeholder?: ReactNode;
  visibleItemsCount?: number;
  heightItem?: number;
  dropdownAddon?: ReactNode | ((inputValue: string) => ReactNode);
  dropdownPlacement?: Placement;
  disableItem?: DisableItemType<Item>;
  anchorEl: Nullable<HTMLElement>;
  filteredItems: Item[];
  options: Item[] | FetchOptionsType;
  fetchSearchOptions?: FetchOptionsType;
  downshift: UseComboboxReturnValue<Item>;
  state?: IAsyncOptionState;
  itemToKey?: ItemToKeyType<Item>;
  itemToString: ItemToStringAndOptionsType<Item>;
  dispatch?: Dispatch<IAsyncOptionAction>;
}

const Options: FC<IOptions> = ({
  inline = false,
  hasPlaceholder = true,
  placeholder,
  disablePortal = true,
  visibleItemsCount = 7,
  filteredItems,
  disableItem,
  anchorEl,
  dropdownAddon,
  dropdownPlacement,
  options,
  fetchSearchOptions,
  downshift,
  state,
  heightItem = INITIAL_HEIGHT_ITEM,
  itemToKey,
  itemToString,
  dispatch,
}) => {
  const { isOpen, inputValue, getMenuProps, getItemProps } = downshift;

  const dependencyForInitInfinityScroll = useRef({
    inputValue,
    fetchOptions: (fetchSearchOptions ?? options) as FetchOptionsType,
  });

  const isAsyncOptions =
    typeof options === 'function' || (fetchSearchOptions && inputValue !== '');

  const items = getItems({
    hasPlaceholder,
    ...(isAsyncOptions && state),
    filteredItems,
  });

  const renderDropdownAddon =
    typeof dropdownAddon === 'function'
      ? dropdownAddon(inputValue)
      : dropdownAddon;

  const hasItems = filteredItems.length > 0;

  if (inline) {
    if (!itemToKey) {
      console.error('В Options не передан itemToKey');
      return null;
    }

    return (
      <InlineOptionsWrapper
        anchorEl={anchorEl}
        disablePortal={disablePortal}
        open={isOpen}
        {...getMenuProps()}
      >
        {items.map((item, index) => {
          return item === ITEMS.PLACEHOLDER ? (
            <Text color='text.disabled' mx='auto'>
              Добавлены все значения
            </Text>
          ) : (
            <Tag
              key={itemToKey(item)}
              arrow
              value={item}
              {...getItemProps({ type: 'button', index, item })}
            />
          );
        })}
      </InlineOptionsWrapper>
    );
  }

  const fixedSizeListHeight =
    (hasItems ? heightItem : INITIAL_HEIGHT_ITEM) *
    Math.min(items.length, visibleItemsCount);

  return (
    <Dropdown
      keepMounted
      anchorEl={anchorEl}
      disablePortal={disablePortal}
      open={isOpen}
      placement={dropdownPlacement}
      {...getMenuProps()}
      onMouseLeave={event => {
        // Небольшой хак, для уменьшения кол-ва перерендеров
        event.preventDefault();
      }}
    >
      {isOpen && (
        <OptionContext.Provider
          value={{ ...downshift, itemToString, placeholder, disableItem }}
        >
          {isAsyncOptions ? (
            <InfiniteScroll
              dependencyForInitInfinityScroll={dependencyForInitInfinityScroll}
              dispatch={dispatch!}
              fetchOptions={(fetchSearchOptions ?? options) as FetchOptionsType}
              height={fixedSizeListHeight}
              inputValue={inputValue}
              items={items}
              itemSize={hasItems ? heightItem : INITIAL_HEIGHT_ITEM}
              Option={Option}
              state={state!}
            />
          ) : (
            <FixedSizeList
              height={fixedSizeListHeight}
              itemCount={items.length}
              itemData={items}
              itemSize={hasItems ? heightItem : INITIAL_HEIGHT_ITEM}
              width='100%'
            >
              {Option}
            </FixedSizeList>
          )}
          {renderDropdownAddon}
        </OptionContext.Provider>
      )}
    </Dropdown>
  );
};

export { Options, OptionWrapper, getFilteredItems, filterByInputValue };
