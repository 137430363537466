import {
  forwardRef,
  ReactNode,
  MouseEvent,
  ElementType,
  CSSProperties,
} from 'react';

import { LinkProps } from 'react-router-dom';
import styled, { css, CSSProp } from 'styled-components';
import {
  flexbox,
  FlexboxProps,
  HeightProps,
  MaxWidthProps,
  MinHeightProps,
  MinWidthProps,
  space as styledSpace,
  SpaceProps,
  typography,
  TypographyProps,
  WidthProps,
} from 'styled-system';

import { width, height } from 'common/constants/customStyledSystems.const';

import { themeByVariant } from './themeByVariant';
import { Spinner } from '../Indicators/Spinner';

type VariantButton = 'text' | 'filled' | 'outlined' | 'string';

type ColorButton = 'primary' | 'secondary' | 'error' | 'info';

type SizeButton = 's' | 'm' | 'l';

const widthBySize = { s: 90, m: 100, l: 120 };

const SPINNER_STYLE = { position: 'absolute' } as CSSProperties;

interface ButtonWidthProps extends WidthProps, MinWidthProps, MaxWidthProps {}

interface ButtonProps
  extends SpaceProps,
    TypographyProps,
    ButtonWidthProps,
    HeightProps,
    MinHeightProps,
    FlexboxProps {
  style?: CSSProperties;
  className?: string;
  children: ReactNode;
  /**
   * Выключение/включение функции кнопки.
   */
  disabled?: boolean;
  /**
   * Свойство для отображения активного состояния.
   */
  active?: boolean;
  /**
   * Выключение/включение спиннера.
   */
  loading?: boolean;
  /**
   * Атрибут ссылок для скачивания.
   */
  download?: boolean;
  /**
   * Атрибут для ссылки.
   */
  href?: string;
  /**
   * Ссылка для перехода при as={Link}.
   */
  to?: LinkProps['to'];
  target?: '_blank' | '_self';
  type?: 'submit' | 'reset' | 'button';
  /**
   * Тип html-тега компонента. По дефолту: button.
   */
  as?: ElementType;
  /**
   * Размеры кнопок s, m, l. По дефолту: m;
   */
  size?: SizeButton;
  /**
   * Варианты кнопок: text, filled, outlined, string. По дефолту: filled.
   */
  variant?: VariantButton;
  /**
   * Цвета кнопок: primary, secondary, error, info. По дефолту: primary.
   */
  color?: ColorButton;
  /**
   * Функция при нажатии.
   */
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

const variantStyle = (props): CSSProp => {
  const { variant, color, active, loading, size } = props;
  const buttonByVariant = themeByVariant[variant][color];

  return css`
    ${variant === 'string' &&
    css`
      font-weight: 500;
    `}

    min-width: ${variant === 'text' || variant === 'string'
      ? 'inherit'
      : `${widthBySize[size]}px`};

    ${loading &&
    css`
      & > span {
        visibility: hidden;
      }
    `}

    ${buttonByVariant.default};

    &:hover {
      ${buttonByVariant.hover};
    }

    ${active && buttonByVariant.hover}

    &:disabled,
      &[disabled] {
      ${loading ? buttonByVariant.default : buttonByVariant.disabled}
    }
  `;
};

const sizeStyle = (props): CSSProp => {
  const {
    size,
    variant,
    theme: { space, fontSizes },
  } = props;
  if (variant === 'string') {
    return '';
  }

  if (size === 's') {
    return css`
      height: ${space[3]}px;
      padding: ${space[0]}px 9px;
      font-size: ${fontSizes[2]}px;
    `;
  }

  if (size === 'l') {
    return css`
      height: ${space[5]}px;
      padding: 13px ${space[2]}px;
      font-size: ${fontSizes[4]}px;
    `;
  }

  return css`
    height: ${space[4]}px;
    padding: ${space[1]}px 10px;
    font-size: ${fontSizes[2]}px;
  `;
};

const ButtonWrapper = styled.button<ButtonProps & FlexboxProps>(
  ({ theme: { borderRadius }, loading, disabled }) => css`
    position: relative;
    display: inline-flex;
    cursor: ${loading || disabled ? 'default' : 'pointer'};
    white-space: nowrap;
    word-wrap: normal;
    justify-content: center;
    align-items: center;
    border-radius: ${borderRadius}px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-align: center;
    font-family: inherit;
    transition: 0.2s ease-in-out background, 0.2s ease-in-out color,
      0.2s ease-in-out border;

    ${variantStyle};
    ${sizeStyle};

    ${styledSpace};
    ${width};
    ${height};
    ${typography};
    ${flexbox};
  `,
);

const ChildrenWrapper = styled.span`
  display: flex;
  align-items: center;
  max-width: 100%;
`;

const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      style,
      className,
      children,
      to,
      as,
      target,
      type = 'button',
      disabled,
      download,
      href,
      active,
      loading,
      size = 'm',
      variant = 'filled',
      color = 'primary',
      onClick,
      ...other
    },
    ref,
  ) => {
    const buttonByVariant = themeByVariant[variant][color];

    return (
      <ButtonWrapper
        ref={ref}
        active={active}
        as={as}
        className={className}
        color={color}
        disabled={disabled || loading}
        download={download}
        href={href}
        loading={loading}
        role='button'
        size={size}
        style={style}
        target={target}
        to={to}
        type={type}
        variant={variant}
        onClick={onClick}
        {...other}
      >
        {loading && (
          <Spinner
            color={buttonByVariant.loading}
            delay={0}
            size='xs'
            style={SPINNER_STYLE}
          />
        )}
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </ButtonWrapper>
    );
  },
);

export {
  VariantButton,
  ColorButton,
  Button,
  SizeButton,
  ButtonProps,
  variantStyle,
};
