import { Fragment, memo, FC, Dispatch, SetStateAction } from 'react';

import styled, { css } from 'styled-components';

import { NTD_STATUSES } from 'common/constants/standartization';
import { NtdLifeStates } from 'common/types/standartization';
import { SystemIcon } from 'resources/icons/18';
import {
  Text,
  Switch,
  Container,
  IconButton,
  ContextMenu,
  useContextMenu,
} from 'UI';

const NTD_FILTER_LIFE_STATES = [
  NtdLifeStates.active,
  NtdLifeStates.inactive,
  NtdLifeStates.partiallyCancelled,
];

const StyledSwitch = styled(Switch)(
  ({ theme: { colors } }) => css`
    justify-content: space-between;
    height: 20px;

    & label {
      color: ${colors.text.primary};
      font-weight: 500;
    }
  `,
);

interface INtdFilterProps {
  hasNtdFilters: boolean;
  ntdStatuses: string[];
  setNtdStatuses: Dispatch<SetStateAction<string[]>>;
}

const NtdFilter: FC<INtdFilterProps> = memo(
  ({ hasNtdFilters, ntdStatuses, setNtdStatuses }) => {
    const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
      useContextMenu();

    const handleChangeStatus = (status: string): void => {
      setNtdStatuses(props => {
        return props.includes(status)
          ? props.filter(prop => prop !== status)
          : [...props, status];
      });
    };

    return (
      <Fragment>
        <IconButton disabled={!hasNtdFilters} onClick={handleContextMenuOpen}>
          <SystemIcon />
        </IconButton>
        <ContextMenu
          anchorEl={anchorEl}
          open={open}
          width='210px'
          onRequestClose={handleContextMenuClose}
        >
          <Container column gap={2} px={2} py={1}>
            <Text color='text.disabled' fontSize={0} fontWeight={500}>
              СТАТУС НТД
            </Text>
            {NTD_FILTER_LIFE_STATES.map(ntdStatus => (
              <StyledSwitch
                key={ntdStatus}
                checked={ntdStatuses.includes(ntdStatus)}
                label={NTD_STATUSES[ntdStatus]}
                onChange={() => handleChangeStatus(ntdStatus)}
              />
            ))}
          </Container>
        </ContextMenu>
      </Fragment>
    );
  },
);

export { NtdFilter };
