import { Fragment, ReactNode } from 'react';

import { Link } from 'react-router-dom';

import {
  AVAILABLE_PRODUCT,
  BRAND,
  EMPLOYEE,
  EXPORT,
  IDENTITY,
  PARTNER,
  PARTNER_CHANNEL,
  PARTNER_PRODUCT,
  PRODUCT,
  FAVORITE,
  PRODUCT_SPECIFICATION,
  REQUEST,
  MARKETPLACE,
} from 'common/constants/notifications.const';
import { TASK_STATUSES_NAMES } from 'common/constants/task.const';
import { formatDate, getFullName } from 'common/utils';
import { COMPANY, INFORMATION, PARTNERS, PRODUCTS, SEARCH } from 'routes';
import { UnorderedList } from 'UI';

import { getProductCategory, getPublicationText } from './utils';

const toArray = (
  isStrong: boolean,
): ((
  strings: TemplateStringsArray,
  ...expressions: ReactNode[]
) => ReactNode[]) => {
  return (strings: TemplateStringsArray, ...expressions: ReactNode[]) => {
    const finalString: ReactNode[] = [];

    expressions.forEach((value, i) => {
      finalString.push(
        strings[i],
        isStrong ? <strong key={i}>{value}</strong> : value,
      );
    });

    finalString.push(strings[strings.length - 1]);

    return finalString;
  };
};
function getText(key, props): ReactNode[] | JSX.Element | '' {
  const strong = toArray(true);
  const split = toArray(false);

  switch (key) {
    case PRODUCT.ANNOUNCEMENT_TOMORROW:
      return split`Завтра анонс ${getProductCategory(props, false)} ${(
        <strong>{props.productName}</strong>
      )}. Не забудьте перевести ${getProductCategory(
        props,
      )} в статус “Производится” или продлить анонс.`;
    case PRODUCT.ANNOUNCEMENT_TODAY:
      return split`Сегодня анонс ${getProductCategory(props, false)} ${(
        <strong>{props.productName}</strong>
      )}. Переведите ${getProductCategory(
        props,
      )} в статус “Производится” или продлите анонс. В противном случае, анонс будет считаться просроченным.`;
    case PRODUCT.ANNOUNCEMENT_EXPIRED:
      return split`Анонс ${getProductCategory(props, false)} ${(
        <strong>{props.productName}</strong>
      )} просрочен на ${(
        <strong>{props.expiredDays}</strong>
      )} дней. Рекомендуем перевести ${getProductCategory(
        props,
      )} в статус “Производится” или продлить анонс.`;
    case PRODUCT.MODERATION_COMPLETED:
      return split`${getProductCategory(props, true, true)} ${(
        <strong>{props.productName}</strong>
      )} успешно прошел модерацию.`;
    case PRODUCT.REJECTED:
      return split`${getProductCategory(props, true, true)} ${(
        <strong>{props.productName}</strong>
      )} отклонен модератором.`;
    case PRODUCT.BLOCKED:
      return split`${getProductCategory(props, true, true)} ${(
        <strong>{props.productName}</strong>
      )} заблокирован модератором.`;
    case PRODUCT.FULL_EDITING_COMPLETED:
      return split`Истек срок полного редактирования ${getProductCategory(
        props,
        false,
      )} ${(<strong>{props.productName}</strong>)}.`;
    case PRODUCT.FULL_EDITING_STARTED:
      return split`${getProductCategory(props, true, true)} ${(
        <strong>{props.productName}</strong>
      )} доступен для полного редактирования.`;
    case PRODUCT.FULL_EDITING_EXPIRED_BECAUSE_PRODUCT_HAS_BEEN_LINKED:
      return split`Досрочно истек срок полного редактирования ${getProductCategory(
        props,
        false,
      )} ${(
        <strong>{props.productName}</strong>
      )} по причине привязки к публичному товару.`;
    case PRODUCT.FULL_EDITING_EXPIRED_BECAUSE_PRODUCT_HAS_BEEN_DISTRIBUTED:
      return split`Досрочно истек срок полного редактирования ${getProductCategory(
        props,
        false,
      )} ${(
        <strong>{props.productName}</strong>
      )} по причине распространения одному партнеру.`;
    case PRODUCT.PUBLISHED_PRODUCT_HAS_BEEN_EDITED:
      return split`Опубликованный ${getProductCategory(props)} ${(
        <strong>{props.productName}</strong>
      )} был изменен.`;
    case PRODUCT.DELEGATED_OWNERSHIP_TO_MANUFACTURER:
      return split`${getProductCategory(props, true, true)} ${(
        <strong>{props.productName}</strong>
      )} производителя ${(
        <strong>{props.ownershipCompanyName}</strong>
      )} ${getPublicationText(props.productCategory)} в качестве доступного.`;
    case PRODUCT.PRODUCT_TASK_STATUS_CHANGED:
      return split`Пользователь ${(
        <strong>{props.updatedByName}</strong>
      )} изменил статус задачи ${(<strong>{props.productName}</strong>)} на «${
        TASK_STATUSES_NAMES[props.status]
      }».`;
    case PRODUCT.PRODUCT_TASK_TO_DO_STATUS_EVENT:
      return split`Пользователь ${(
        <strong>{props.updatedByName}</strong>
      )} назначил вас исполнителем задачи ${(
        <strong>{props.productName}</strong>
      )}. ${
        props.dueDate &&
        split`Срок исполнения: до ${(
          <strong>{formatDate(props.dueDate)}</strong>
        )}.`
      }
     `;
    case PARTNER.PARTNERSHIP_REQUESTED:
    case PARTNER.PARTNERSHIP_REQUEST_AGAIN:
      return strong`Поступила заявка на партнерство от ${props.companyName}.`;
    case PARTNER.PARTNERSHIP_DECLINE:
      return strong`${props.companyName} отклонила заявку на партнерство.`;
    case PARTNER.PARTNERSHIP_CONFIRMED:
      return strong`${props.companyName} приняла заявку на партнерство.`;
    case PARTNER.PARTNERSHIP_BLOCKED:
      return strong`${props.companyName} заблокировала партнерскую связь.`;
    case PARTNER.PARTNERSHIP_CANCELLED:
      return strong`${props.companyName} отменила заявку на партнерство.`;
    case IDENTITY.INVITED_TO_COMPANY:
      return strong`Компания ${props.companyName} пригласила Вас в качестве сотрудника.`;
    case IDENTITY.REMOVED_FROM_COMPANY:
      return strong`Вы перестали быть сотрудником ${props.companyName}. Для уточнения информации обратитесь в компанию.`;
    case IDENTITY.REGISTRATION_CONFIRMED:
      return (
        <Fragment>
          <p>
            <strong>Рады приветствовать в istock.info!</strong> Вы успешно
            зарегистрировались и готовы работать с системой. Рекомендуем начать
            со следующих шагов:
          </p>
          <UnorderedList>
            <UnorderedList.Li>
              <Link to={COMPANY.REGISTRATION}>Зарегистрируйте компанию</Link>,
              чтобы добавлять товары и обмениваться информацией с партнерами.
            </UnorderedList.Li>
            <UnorderedList.Li>
              Станьте сотрудником компании, чтобы получить доступ к ее товарам и
              партнерам. Для этого обратитесь к своему руководителю.
            </UnorderedList.Li>
            <UnorderedList.Li>
              Воспользуйтесь поиском: <Link to={SEARCH.FORM}>общим</Link>,{' '}
              <Link to={SEARCH.CLASSIFIER}>по классификаторам</Link> или{' '}
              <Link to={SEARCH.STANDARD}>по стандартам</Link>.
            </UnorderedList.Li>
          </UnorderedList>
          <p>
            Для изучения всех возможностей istock.info перейдите в{' '}
            <Link to={INFORMATION.GUIDES_PAGE}>Базу знаний</Link>.
          </p>
        </Fragment>
      );
    case IDENTITY.COMPANY_CREATED:
      return (
        <Fragment>
          <p>
            <strong>
              Статус пользователя успешно изменен на юридическое лицо!
            </strong>{' '}
            Вам доступны полные возможности системы:
          </p>
          <UnorderedList mb='12px'>
            <UnorderedList.Li>
              <Link to={PRODUCTS.COMPANY}>Добавьте товар</Link>, чтобы вас
              находили через вашу продукцию.
            </UnorderedList.Li>
            <UnorderedList.Li>
              Заполните <Link to={COMPANY.PAGE}>информацию о компании</Link>,
              она будет видна партнерам и клиентам.
            </UnorderedList.Li>
            <UnorderedList.Li>
              <Link to={PARTNERS.ADD}>Найдите партнеров</Link> и начните
              обмениваться товарами.
            </UnorderedList.Li>
            <UnorderedList.Li>
              <Link to={COMPANY.EMPLOYEES_LIST}>Добавьте сотрудников</Link>{' '}
              своей компании, разослав им приглашения.
            </UnorderedList.Li>
          </UnorderedList>
          <p>Полезные статьи из Базы знаний:</p>
          <UnorderedList>
            <UnorderedList.Li>
              <Link to={INFORMATION.GUIDES.CREATE_PRODUCT_BASE}>
                Как добавить товар
              </Link>
              .
            </UnorderedList.Li>
            <UnorderedList.Li>
              <Link to={INFORMATION.GUIDES.PARTNER_CHANNELS}>
                Как делиться товарами с партнерами
              </Link>
              .
            </UnorderedList.Li>
            <UnorderedList.Li>
              <Link to={INFORMATION.GUIDES.REQUESTED_PRODUCTS}>
                Как запрашивать товары партнеров
              </Link>
              .
            </UnorderedList.Li>
          </UnorderedList>
        </Fragment>
      );
    case IDENTITY.INVITED_TO_COMPANY_BY_SHARED_LINK_TO_USER:
      return (
        <>
          <p style={{ marginTop: 0 }}>
            Вы стали сотрудником компании <strong>{props.companyName}</strong>.
          </p>
          <UnorderedList>
            <UnorderedList.Li>
              Дождитесь, когда компания назначит вам необходимый уровень
              доступа, чтобы начать полноценно работать в системе.
            </UnorderedList.Li>
            <UnorderedList.Li>
              Чтобы просматривать информацию компании, используйте навигацию в
              основном меню.
            </UnorderedList.Li>
            <UnorderedList.Li>
              Чтобы находить товары и стандарты, используйте раздел{' '}
              <Link to={SEARCH.FORM}>Поиск по системе</Link>.
            </UnorderedList.Li>
          </UnorderedList>
          <p style={{ marginBottom: 0 }}>
            Больше возможностей istock.info описано в{' '}
            <Link to={INFORMATION.GUIDES_PAGE}>Базе знаний</Link>.
          </p>
        </>
      );
    case IDENTITY.POSITION_CHANGED:
      return (
        <>
          Ваша должность в компании <strong>{props.companyName}</strong> была
          изменена.
          <br />
          Старая должность: {props.oldPosition || '—'}.
          <br />
          Новая должность: {props.newPosition}.
        </>
      );
    case IDENTITY.ACCESS_CHANGED:
      return (
        <>
          Ваш уровень доступа к компании <strong>{props.companyName}</strong>{' '}
          был изменен.
          <br />
          Старый уровень доступа: {props.oldRoles.join(', ')}.
          <br />
          Новый уровень доступа: {props.newRoles.join(', ')}.
        </>
      );
    case IDENTITY.INVITED_TO_COMPANY_BY_SHARED_LINK_TO_COMPANY_USERS: {
      const employeeName = getFullName({
        givenName: props.employeeGivenName,
        familyName: props.employeeFamilyName,
      });
      return strong`Сотрудник ${employeeName ?? props.employeeEmail}
          присоединился к компании, назначьте ему Должность и Уровень доступа.`;
    }
    case IDENTITY.ASSORTMENT_MEASUREMENT_VARIATIONS_GENERATION: {
      return props.error ? (
        <Fragment>
          Генерация вариаций размера <strong>{props.name}</strong> прошла с
          ошибками.
          <br />
          Текст ошибки: <strong>{props.error}</strong>.
        </Fragment>
      ) : (
        <Fragment>
          Генерация вариаций размера <strong>{props.name}</strong> завершена
          успешно.
        </Fragment>
      );
    }
    case EMPLOYEE.INVITATION_EXPIRED:
      return strong`Истекло время ожидания приглашения сотрудника на ${props.employeeEmail}. Обновите приглашение.`;
    case EMPLOYEE.INVITATION_DECLINED:
      return strong`Приглашение сотрудника ${props.employeeEmail} было отклонено. Вышлите приглашение повторно.`;
    case EMPLOYEE.INVITATION_ANNULLED:
      return strong`Приглашение сотрудника ${props.employeeEmail} было отклонено. Для выяснения подробностей свяжитесь с сотрудником.`;
    case EMPLOYEE.ACCOUNT_DELETED:
    case EMPLOYEE.INVITATION_ACCEPTED: {
      const employeeName = getFullName({
        givenName: props.employeeGivenName,
        familyName: props.employeeFamilyName,
      });

      if (key === EMPLOYEE.ACCOUNT_DELETED) {
        return strong`Сотрудник${employeeName ? ` ${employeeName}` : ''} (${
          props.employeeEmail
        }) удалил аккаунт. Вы можете пригласить сотрудника обратно.`;
      }

      return strong`Сотрудник${
        employeeName ? ` ${employeeName}` : ''
      }, приглашенный по ${props.employeeEmail}, присоединился к компании.`;
    }
    case EMPLOYEE.EMPLOYEE_RESTORED:
      return strong`Компания ${props.companyName} восстановила вас в качестве сотрудника.`;
    case AVAILABLE_PRODUCT.ACCESS_TO_PRODUCTS_GRANTED:
      return strong`${props.companyName} открыла доступ к ${props.productIds?.length} товарам.`;
    case AVAILABLE_PRODUCT.AVAILABLE_PRODUCT_HAS_BEEN_EDITED:
      return strong`Доступный товар ${props.productName} был изменен.`;
    case AVAILABLE_PRODUCT.ACCESS_TO_PRODUCTS_LOST:
      return strong`${props.companyName} закрыла доступ к ${props.productIds?.length}  товарам.`;
    case AVAILABLE_PRODUCT.PRODUCT_ANNOUNCEMENT_EXPIRED:
      return strong`Производитель ${props.companyName} товара ${props.productName} просрочил дату анонса.`;
    case AVAILABLE_PRODUCT.PRODUCT_ANNOUNCEMENT_POSTPONED:
      return strong`Партнер ${props.companyName} продлил анонс товара ${props.productName} до ${props.postponedDate}.`;
    case AVAILABLE_PRODUCT.PRODUCT_ANNOUNCEMENT_CANCELLED:
      return strong`Производитель ${props.companyName} отменил анонс товара.`;
    case AVAILABLE_PRODUCT.PRODUCT_ANNOUNCEMENT_AFTER_CANCELLATION:
      return strong`Производитель ${props.companyName} восстановил товар после отмены. Перейдите к товару, чтобы посмотреть внесенные изменения.`;
    case AVAILABLE_PRODUCT.PRODUCT_PRODUCTION_STARTED_EARLIER:
      return strong`Производитель ${props.companyName} начал производство товара ${props.productName} раньше планируемого срока.`;
    case PARTNER_CHANNEL.ACCESS_TO_PRODUCTS_REQUESTED:
    case PARTNER_CHANNEL.ACCESS_TO_PRODUCTS_REQUESTED_AGAIN:
      return strong`${props.companyName} запросила доступ к ${props.productIds?.length} товарам.`;
    case PARTNER_PRODUCT.PARTNER_CHANNEL_ACTIVATED:
      return strong`Компания-партнер ${props.companyName} активировала канал распространения информации по товарам к вашей организации. Теперь вы можете запросить доступ к его товарам.`;
    case PARTNER_PRODUCT.PARTNER_CHANNEL_DEACTIVATED:
      return strong`Компания-партнер ${props.companyName} закрыла канал распространения информации по товарам к вашей организации. Для уточнения причины закрытия канала обратитесь к компании-партнеру.`;
    case PARTNER_PRODUCT.ACCESS_TO_PRODUCTS_DECLINE:
      return strong`Компанией ${props.companyName} было отказано в доступе к ${props.productIds?.length} товарам.`;
    case EXPORT.EXPORT_PENDING:
      return strong`Через час канал экспорта ${props.exportChannelName} начнет плановую выгрузку товаров.`;
    case EXPORT.EXPORT_FAILED:
      return strong`Во время выгрузки по каналу экспорт ${props.exportChannelName} произошли неполадки.`;
    case EXPORT.EXPORT_COMPLETED:
      return strong`Канал экспорта ${props.exportChannelName} успешно произвел плановую выгрузку.`;
    case EXPORT.EXPORT_COMPLETED_WITH_ERRORS:
      return strong`Во время выгрузки по каналу экспорт ${props.exportChannelName} произошли неполадки.`;
    case EXPORT.MANUAL_EXPORT_COMPLETED:
      return strong`Архив ${props.exportChannelName} сформирован.`;
    case EXPORT.MANUAL_EXPORT_COMPLETED_WITH_ERRORS:
      return strong`Архив ${props.exportChannelName} сформирован с ошибками.`;
    case EXPORT.START_EXPORT_FAILED:
      return strong`Выгрузка по каналу ${props.exportChannelName} не была запущена, т.к. нет изменений для выгрузки.`;
    case BRAND.VERIFIED:
      return strong`Ваш бренд ${props.brandName} теперь верифицирован.`;
    case BRAND.DECLINED:
      return strong`Ваш бренд ${props.brandName} не прошел верификацию.`;
    case FAVORITE.PUBLISHED_PRODUCT_HAS_BEEN_EDITED:
      return split`Избранный ${getProductCategory(props)} ${(
        <strong>{props.productName}</strong>
      )} был изменен.`;
    case PRODUCT_SPECIFICATION.PRODUCT_SPECIFICATION_CREATED:
      return strong`Новое входящее техническое задание по вашему опросному листу ${(
        <strong>{props.questionnaireName}</strong>
      )}`;
    case REQUEST.REQUEST_RECEIVED:
    case REQUEST.REQUEST_RECEIVED_FOR_MANUFACTURED_NTD:
    case REQUEST.REQUEST_RECEIVED_FOR_SERVICE:
    case REQUEST.REQUEST_RECEIVED_FOR_GENERIC_PRODUCT_VARIATION:
    case REQUEST.REQUEST_RECEIVED_FOR_MANUFACTURED_GENERIC_PRODUCT:
      return strong`Новая входящая заявка на ${(
        <strong>{props.name}</strong>
      )}.`;
    case MARKETPLACE.NEW_ORDER_PLACED:
      return strong`Получен новый заказ через модуль  ${(
        <strong>"Маркетплейс"</strong>
      )}.`;

    default:
      return '';
  }
}

export { getText };
