import { memo, FC } from 'react';

import ReactMarkdown from 'react-markdown';
import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  fontSize,
  fontWeight,
  SpaceProps,
  FontSizeProps,
  FontWeightProps,
} from 'styled-system';

const StyledMarkdown = styled(ReactMarkdown)<{ clamp: number }>(
  ({ clamp }) => css`
    display: -webkit-box;
    -webkit-line-clamp: ${clamp};
    -webkit-box-orient: vertical;
    overflow: hidden;

    p {
      margin: 0;
    }

    ${styledSpace};
    ${fontSize};
    ${fontWeight};
  `,
);

const Markdown: FC<
  { text: string | undefined; clamp: number } & SpaceProps &
    FontSizeProps &
    FontWeightProps
> = memo(({ text, clamp, ...other }) => {
  return (
    <StyledMarkdown
      {...other}
      clamp={clamp}
      components={{
        blockquote: 'p',
        h1: 'strong',
        h2: 'strong',
        h3: 'strong',
        h4: 'strong',
        h5: 'strong',
        h6: 'strong',
        ul: 'p',
        ol: 'p',
        li: 'span',
        pre: 'p',
      }}
      disallowedElements={['br', 'code', 'hr', 'img', 'input', 'table']}
    >
      {text ?? ''}
    </StyledMarkdown>
  );
});

export { Markdown };
