import { useState, useRef, FC, ChangeEvent, KeyboardEvent } from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { SearchTypes } from 'common/types/search.types';
import { CloseMediumIcon } from 'resources/icons/18';
import { SEARCH } from 'routes';
import { IconButton } from 'UI';
import { InputContainer } from 'UI/Inputs';

import { BUTTON_BORDER_RADIUS_STYLE } from './constants';
import { ImpulseButton } from './styled';
import impulseTheme from '../../../theme/theme';

const StyledSearchInput = styled(InputContainer)(
  ({ theme: { space } }) => css`
    flex: 1;
    justify-content: center;
    max-height: 54px;
    height: 54px;
    padding: ${space[0]}px 2px ${space[0]}px 20px;
    border: 2px solid ${impulseTheme.colors.primary.main};
    border-radius: 8px;

    input::placeholder {
      font-size: 20px;
      font-weight: 400;
    }

    &:focus-within:not([disabled]) {
      border: 2px solid ${impulseTheme.colors.primary.main};
    }
  `,
);

const SearchInputContainer = styled.input(
  ({ theme: { colors } }) => css`
    width: 100%;
    font-size: 20px;
    color: ${colors.text.primary};
    background: transparent;

    &::placeholder {
      color: ${colors.text.disabled};
    }
  `,
);

const SearchInput: FC<{ searchType?: string; placeholder?: string }> = ({
  searchType,
  placeholder = 'Найти продукцию или производителей',
}) => {
  const history = useHistory();
  const searchInputRef = useRef<HTMLInputElement>(null);

  const [inputValue, setInputValue] = useState('');

  const handleSubmit = (): void => {
    history.push(
      searchType === SearchTypes.manufacturer
        ? `${SEARCH.MANUFACTURER}?search=${inputValue}`
        : `${SEARCH.FORM}?search=${inputValue}`,
    );
    setInputValue('');
  };

  const handleChange = ({ target }: ChangeEvent<HTMLInputElement>): void => {
    setInputValue(target.value);
  };

  const handleReset = (): void => {
    setInputValue('');
    searchInputRef.current!.value = '';
    searchInputRef.current!.focus();
  };

  const handleEnterPress = ({ key }: KeyboardEvent<HTMLInputElement>): void => {
    if (key === 'Enter') handleSubmit();
  };

  return (
    <StyledSearchInput>
      <SearchInputContainer
        ref={searchInputRef}
        placeholder={placeholder}
        type='text'
        onChange={handleChange}
        onKeyDown={handleEnterPress}
      />
      {inputValue && (
        <IconButton mr={0} size={28} title='Очистить' onClick={handleReset}>
          <CloseMediumIcon />
        </IconButton>
      )}
      <ImpulseButton
        fontSize='18px'
        fontWeight={500}
        minWidth='93px'
        size='l'
        style={BUTTON_BORDER_RADIUS_STYLE}
        onClick={handleSubmit}
      >
        Найти
      </ImpulseButton>
    </StyledSearchInput>
  );
};

export { SearchInput };
