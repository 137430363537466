import { Fragment, useState, useMemo, memo, useCallback, FC } from 'react';

import styled, { css } from 'styled-components';

import { EMPTY_OBJECT } from 'common/constants/emptyDataStructures.const';
import { useQueryParams } from 'common/hooks';
import { IMapCategory } from 'common/types/categories.types';
import { getCategoryPath, getRootCategories } from 'common/utils/categories';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';
import { Container, IconButton, SideMenuGroup, Spinner } from 'UI';
import { SideMenuItem } from 'UI/SideMenu';
import { STEP_TREE, INDENT_FOR_ITEM } from 'UI/SideMenu/constants';

const ChildrenWrapper = styled.div<{ level: number }>(
  ({ theme: { colors }, level }) => css`
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: ${level * STEP_TREE + INDENT_FOR_ITEM}px;
      width: 1px;
      background: ${colors.divider};
    }
  `,
);

const RightButton: FC<{ isOpen: boolean; onClick: VoidFunction }> = ({
  isOpen,
  onClick,
}) => (
  <IconButton ml='auto' size={28} onClick={onClick}>
    {isOpen ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
  </IconButton>
);

interface ICategoryItemProps {
  category: IMapCategory | undefined;
  categories: Map<string, IMapCategory>;
  selectedCategory: string;
  level?: number;
  getLink: (props: {
    withoutCategories?: boolean | undefined;
    id?: string | undefined;
  }) => string;
}

const CategoryItem: FC<ICategoryItemProps> = memo(
  ({ category, categories, selectedCategory, level = 1, getLink }) => {
    const [isOpen, setOpen] = useState(() => {
      return getCategoryPath(categories.get(selectedCategory), categories).has(
        category?.id ?? '',
      );
    });

    const isGroup = category && category.children.length > 0;

    const itemProps = useMemo(() => {
      const handleClick = (): void => setOpen(prev => !prev);

      return isGroup
        ? {
            as: 'div',
            style: { cursor: 'pointer' },
            rightAction: <RightButton isOpen={isOpen} onClick={handleClick} />,
            onClick: handleClick,
          }
        : { to: getLink({ id: category?.id }) };
    }, [isGroup, isOpen, category, getLink, setOpen]);

    if (!category) return null;

    return (
      <Fragment>
        <SideMenuItem
          {...itemProps}
          primary
          active={selectedCategory === category.id}
          fontSize={3}
          level={level}
          text={category.name}
        />
        {isGroup && isOpen && (
          <ChildrenWrapper level={level}>
            {category.children.map(childCategory => (
              <CategoryItem
                key={childCategory}
                categories={categories}
                category={categories.get(childCategory)!}
                getLink={getLink}
                level={level + 1}
                selectedCategory={selectedCategory}
              />
            ))}
          </ChildrenWrapper>
        )}
      </Fragment>
    );
  },
);

interface ICategoryFilterProps {
  isLoading: boolean;
  categories: Map<string, IMapCategory>;
}

const CategoryFilter: FC<ICategoryFilterProps> = memo(
  ({ isLoading, categories }) => {
    const [{ withoutCategories, selectedCategory = '' }, querySet] =
      useQueryParams();

    const [isOpen, toggleOpen] = useToggle(true);

    const getLink = useCallback(
      (props: { withoutCategories?: boolean; id?: string }) => {
        return querySet(prev => ({
          ...prev,
          page: 1,
          withoutCategories: props.withoutCategories,
          selectedCategory: props.id,
        }));
      },
      [querySet],
    );

    return (
      <SideMenuGroup isFixedGroup title='Иерархия категорий'>
        <Spinner delay={400} loading={isLoading}>
          <Container column mr={1}>
            <SideMenuItem
              primary
              active={withoutCategories}
              text='Без категорий'
              to={getLink({ withoutCategories: true })}
            />
            <SideMenuItem
              primary
              active={!selectedCategory && !withoutCategories}
              rightAction={<RightButton isOpen={isOpen} onClick={toggleOpen} />}
              text='Все категорий'
              to={getLink(EMPTY_OBJECT)}
            />
            <ChildrenWrapper level={0}>
              {isOpen &&
                getRootCategories(categories).map(({ id: categoryId }) => (
                  <CategoryItem
                    key={categoryId}
                    categories={categories}
                    category={categories.get(categoryId)}
                    getLink={getLink}
                    selectedCategory={selectedCategory}
                  />
                ))}
            </ChildrenWrapper>
          </Container>
        </Spinner>
      </SideMenuGroup>
    );
  },
);

export { CategoryFilter };
