import { Fragment } from 'react';

import { compareAsc } from 'date-fns';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { minWidth, MinWidthProps } from 'styled-system';

import {
  ICompanyTariff,
  StatusBill,
  ITariff,
  IBill,
} from 'common/types/billing.types';
import { formatDate } from 'common/utils';
import { SystemIcon, InfoIcon } from 'resources/icons/18';
import { PROFILE } from 'routes';
import { IconButton, Button, Text, VerticalDivider } from 'UI';

import {
  SearchByJointModalTypes,
  SearchByJointPromoSections,
} from '../constants';

const AnimatedWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 0;

  transition: width 200ms ease-in-out;
`;

const Container = styled.div<{ primary?: boolean }>(
  ({ theme: { colors, space }, primary }) => css`
    display: flex;
    padding: ${space[1]}px;
    background: ${colors.highlight[0]};
    border-radius: 8px;
    overflow: hidden;

    &:hover ${AnimatedWrapper} {
      width: 93px;
    }

    ${primary &&
    css`
      background: ${colors.highlight[1]};
    `}

    ${minWidth};
  `,
);

const TariffInfo = styled.div(
  ({ theme: { fontSizes } }) => css`
    &,
    & button {
      font-size: ${fontSizes[0]}px;
      font-weight: 500;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }
  `,
);

interface ISquareBadge extends MinWidthProps {
  label: string;
  description?: string | JSX.Element;
  primary?: boolean;
  children?: boolean | JSX.Element | JSX.Element[];
}

function SquareBadge({
  label,
  description,
  primary,
  children,
  ...other
}: ISquareBadge): JSX.Element {
  return (
    <Container primary={primary} {...other}>
      <div style={{ flexGrow: 1 }}>
        <Text
          color={primary ? 'primary.main' : 'text.secondary'}
          fontWeight={600}
          mb={0}
        >
          {label}
        </Text>
        <TariffInfo>{description}</TariffInfo>
      </div>
      {children && <AnimatedWrapper>{children}</AnimatedWrapper>}
    </Container>
  );
}

type ModalPromoProps = {
  defaultSection: SearchByJointPromoSections;
};

type ModalPaymentProps = {
  bill?: IBill;
  tariff?: ITariff;
};

type ModalChangeTariffProps = {
  tariff?: ITariff;
};

interface ITariffBadge {
  showControls?: boolean;
  activeCompanyTariff?: ICompanyTariff;
  lastBill?: IBill;
  onAction: (props: {
    modalType: SearchByJointModalTypes;
    modalProps?: ModalPromoProps | ModalPaymentProps | ModalChangeTariffProps;
  }) => void;
}

function TariffBadge({
  showControls,
  activeCompanyTariff,
  lastBill,
  onAction,
}: ITariffBadge): JSX.Element {
  const handleGoToPromoTariffs: () => void = () => {
    onAction({
      modalType: SearchByJointModalTypes.PROMO,
      modalProps: { defaultSection: SearchByJointPromoSections.TARIFFS },
    });
  };

  const handleGoToChangeTariff: () => void = () => {
    onAction({
      modalType: SearchByJointModalTypes.TARIFFS,
      modalProps: { tariff: activeCompanyTariff?.tariff },
    });
  };

  const handleGoToPayment: () => void = () => {
    onAction({
      modalType: SearchByJointModalTypes.PAYMENT,
      modalProps: { bill: lastBill, tariff: lastBill?.tariff },
    });
  };

  const controls = showControls && (
    <Fragment>
      <IconButton
        as={Link}
        mx={1}
        title='Управлять подпиской'
        to={PROFILE.PAGE}
        variant='highlight'
      >
        <SystemIcon />
      </IconButton>
      <VerticalDivider bg='white' height={52} my={-1} />
      <IconButton
        ml={1}
        title='Подробнее о поиске по соединениям'
        variant='highlight'
        onClick={(): void =>
          onAction({ modalType: SearchByJointModalTypes.PROMO })
        }
      >
        <InfoIcon />
      </IconButton>
    </Fragment>
  );

  // тариф активен - expiresAt > сегодняшней даты
  if (
    activeCompanyTariff &&
    compareAsc(new Date(activeCompanyTariff.expiresAt), new Date()) > 0
  ) {
    const formatExpiresAt = formatDate(
      activeCompanyTariff.expiresAt,
      false,
      'dd.MM.yyyy',
    );

    return (
      <SquareBadge
        primary
        description={`Активен до ${formatExpiresAt}`}
        label={`Тариф «${activeCompanyTariff.tariff.name}»`}
      >
        {controls}
      </SquareBadge>
    );
  }

  // тариф истек - expiresAt <= сегодняшней даты и нет сформированного или истекшего счета
  if (
    activeCompanyTariff &&
    compareAsc(new Date(activeCompanyTariff.expiresAt), new Date()) <= 0 &&
    (!lastBill || lastBill.status === StatusBill.PAID)
  ) {
    return (
      <SquareBadge
        primary
        description={
          <Fragment>
            <Text as='span' color='error.main'>
              Истек
            </Text>
            <Button ml={0} variant='string' onClick={handleGoToChangeTariff}>
              Выбрать план
            </Button>
          </Fragment>
        }
        label={`Тариф «${activeCompanyTariff.tariff.name}»`}
      >
        {controls}
      </SquareBadge>
    );
  }

  // нет тарифа, но есть ожидающий или истекший счет
  if (
    lastBill &&
    (lastBill.status === StatusBill.CANCELLED ||
      lastBill.status === StatusBill.PENDING)
  ) {
    return (
      <SquareBadge
        description={
          <Button variant='string' onClick={handleGoToPayment}>
            {lastBill.status === StatusBill.PENDING
              ? 'Перейти к счету'
              : 'Сформировать счет'}
          </Button>
        }
        label={`Тариф «${lastBill.tariff.name}»`}
      >
        {controls}
      </SquareBadge>
    );
  }

  return (
    <SquareBadge
      description={
        <Button variant='string' onClick={handleGoToPromoTariffs}>
          Выбрать план
        </Button>
      }
      label='Тариф не выбран'
      minWidth={167}
    >
      {controls}
    </SquareBadge>
  );
}

export { TariffBadge };
