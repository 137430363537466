import styled, { css } from 'styled-components';

export const CardsContainer = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(3, minmax(32%, 1fr));
    gap: ${space[2]}px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, minmax(392px, 1fr));
    }
  `,
);
