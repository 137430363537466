import { Dispatch, FC, memo, RefObject, SetStateAction, useRef } from 'react';

import { ICommonSearchProductForm } from 'common/types/common-search';
import { ICombinedFormWithValues } from 'common/types/form.types';
import {
  FormFilterButton,
  FormFilterWrapper,
  LeftSide,
  RightSide,
} from 'components/product-params-form/styled';
import { useModalContext } from 'entities/modals';
import { IReform } from 'reform';
import { CheckMediumIcon, CloseMediumIcon } from 'resources/icons/18';
import { Button, Container, Heading } from 'UI';
import { StyledIconButton } from 'UI/Modal/templates/styled';

type ProductFormOrCombinedForm =
  | ICommonSearchProductForm
  | ICombinedFormWithValues;

interface IProductParamsFormProps {
  reformRef: RefObject<IReform<ProductFormOrCombinedForm[]>>;
  activeFormIndex: number;
  productForms: ProductFormOrCombinedForm[];
  filledFormsIdsMap: Set<string>;
  initialValues: ProductFormOrCombinedForm[];
  LeftSideComponent: JSX.Element;
  setActiveFormIndex: Dispatch<SetStateAction<number>>;
  onReset: VoidFunction;
  onSubmit: (data: ProductFormOrCombinedForm[]) => void;
}

const ProductParamsForm: FC<IProductParamsFormProps> = memo(
  ({
    reformRef,
    activeFormIndex,
    productForms,
    initialValues,
    filledFormsIdsMap,
    LeftSideComponent,
    setActiveFormIndex,
    onReset,
    onSubmit,
  }) => {
    const leftSideRef = useRef<HTMLDivElement>(null);

    const { handleModalClose } = useModalContext();

    const isCombinedForms =
      productForms.length > 0 && 'name' in productForms[0];

    const handleSubmit = (): void => {
      onSubmit(reformRef.current!.getData());
      handleModalClose();
    };

    const handleReset = (): void => {
      reformRef.current!.setData(initialValues);
      filledFormsIdsMap.clear();
      onReset();
    };

    return (
      <Container height='100%'>
        <LeftSide ref={leftSideRef} pt={isCombinedForms ? 4 : 2}>
          {LeftSideComponent}
        </LeftSide>
        <RightSide>
          <Heading as='h3' mb={2}>
            Фильтры по типам товаров
          </Heading>
          <FormFilterWrapper>
            {productForms.map((form, index) => (
              <Container key={form.id} alignItems='center' mb={2}>
                <FormFilterButton
                  active={activeFormIndex === index}
                  color={activeFormIndex === index ? 'primary' : 'secondary'}
                  fontSize={5}
                  variant='string'
                  onClick={() => {
                    setActiveFormIndex(index);
                    leftSideRef.current?.scrollTo(0, 0);
                  }}
                >
                  {'name' in form ? form.name : form.productType}
                </FormFilterButton>
                {filledFormsIdsMap.has(form.id) && (
                  <CheckMediumIcon color='primary.main' ml={1} />
                )}
              </Container>
            ))}
          </FormFilterWrapper>
          <Button
            color='secondary'
            mb={1}
            mt='auto'
            size='l'
            onClick={handleReset}
          >
            Сбросить все
          </Button>
          <Button size='l' onClick={handleSubmit}>
            Применить
          </Button>
          <StyledIconButton onClick={handleModalClose}>
            <CloseMediumIcon />
          </StyledIconButton>
        </RightSide>
      </Container>
    );
  },
);

export { ProductParamsForm };
