import { forwardRef, MouseEvent, useCallback, useState } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { ModalController, useModalContext } from 'entities/modals';
import { useArrayField } from 'reform';
import { CreateIcon } from 'resources/other';
import { Button, Notice, Panel, Relative, Tooltip } from 'UI';

import { ADD_PANORAMA, PanoramasModalForm, PanoramasPreview } from './components';
import { DescriptionPopup, ImageTile } from '../../components';
import { StyledDescriptionIcon } from '../../styled';
import { IMediaComponentSection } from '../../types';

const Grid = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, 125.5px);
    grid-auto-rows: minmax(125.5px, auto);
    grid-gap: ${space[2]}px;
    margin-top: ${space[1]}px;
    margin-bottom: ${space[3]}px;
  `,
);

const INITIAL_DESCRIPTION_STATE: {
  descriptionAnchor: Nullable<HTMLElement>;
  descriptionIndex: Nullable<number>;
} = {
  descriptionAnchor: null,
  descriptionIndex: null,
};

const PanoramasForm = forwardRef<HTMLDivElement, IMediaComponentSection>(
  ({ id, name: fieldName, forbidden, title, onRemoveSection }, ref) => {
    const { handleModalOpen, handleModalClose } = useModalContext();
    const [{ descriptionAnchor, descriptionIndex }, setDescriptionState] =
      useState(INITIAL_DESCRIPTION_STATE);

    const {
      ref: fieldRef,
      fields: panoramas,
      error,
      remove,
      replace,
      insert,
    } = useArrayField({ name: fieldName });

    const handleSubmit = useCallback(
      (nextValue, index) => {
        if (panoramas.length > 0) {
          replace(nextValue, index);
        } else {
          insert(nextValue, index);
        }
        handleModalClose();
      },
      [handleModalClose, replace, insert, panoramas],
    );

    const handleDescriptionOf =
      (index: number) =>
      (event: MouseEvent<HTMLElement>): void => {
        setDescriptionState({
          descriptionAnchor: event.currentTarget,
          descriptionIndex: index,
        });
      };

    const handlePopupClose = (description: string, name: string): void => {
      replace(item => ({ ...item, name, description }), descriptionIndex!);
      setDescriptionState(INITIAL_DESCRIPTION_STATE);
    };

    return (
      <Panel
        ref={ref}
        collapse
        id={id}
        rightActions={
          !forbidden?.remove &&
          !!onRemoveSection && (
            <Button
              color='secondary'
              ml={1}
              variant='text'
              onClick={onRemoveSection}
            >
              Удалить
            </Button>
          )
        }
        title={title}
      >
        <Grid ref={fieldRef}>
          {panoramas.map((panorama, index) => (
            <Relative key={panorama.id}>
              <PanoramasPreview
                key={panorama.id}
                error={Array.isArray(error) ? error[index].name : undefined}
                panorama={panorama}
                showControls={descriptionAnchor !== null}
                onDescription={handleDescriptionOf(index)}
                onEdit={() => {
                  handleModalOpen(ADD_PANORAMA, {
                    initialValues: panoramas[index],
                    onCancel: handleModalClose,
                    onSubmit: nextValue => handleSubmit(nextValue, index),
                  });
                }}
                onRemove={() => remove(index)}
              />
              {panorama.description && (
                <Tooltip title={panorama.description}>
                  <StyledDescriptionIcon />
                </Tooltip>
              )}
            </Relative>
          ))}
          {panoramas.length === 0 && ( // remove this condition to unlock adding multiple panoramas
            <ImageTile
              buttonText='Создать'
              icon={<CreateIcon />}
              onOpen={() => {
                handleModalOpen(ADD_PANORAMA, {
                  onCancel: handleModalClose,
                  onSubmit: nextValue => {
                    handleSubmit(nextValue, panoramas.length ?? 0);
                  },
                });
              }}
            />
          )}
        </Grid>
        <Notice mb='0px' type={Notice.TYPES.DISABLED}>
          Допустимые форматы файла — JPG, PNG, WEBP, BMP. Допустимый размер
          файла — 1 KB – 50 MB.
          <br />
          Минимальное разрешение файла — 50х50 px.
        </Notice>
        <ModalController component={PanoramasModalForm} type={ADD_PANORAMA} />
        {descriptionAnchor && (
          <DescriptionPopup
            isTitle
            open
            anchorEl={descriptionAnchor}
            descriptionPlaceholder='Описание панорамы'
            titlePlaceholder='Название панорамы'
            valueDescription={panoramas[descriptionIndex!].description}
            valueTitle={panoramas[descriptionIndex!].name}
            onSubmit={handlePopupClose}
          />
        )}
      </Panel>
    );
  },
);

export { PanoramasForm };
