import { FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';

import { useUnit } from 'common/hooks';
import {
  IServiceLifeItem,
  IWarranty,
  ObjectOfWarrantyEnum,
} from 'common/types/product.types/accompany-information.types';
import { DownloadSectionButton } from 'components/DownloadSectionButton';
import { SectionTitle } from 'components/SectionTitle';
import {
  CollapseCard,
  DataTable,
  DescriptionList,
  ExpandableContainer,
  Heading,
  InfoBlock,
  Text,
} from 'UI';

import { getOperatingTime, getWarrantyPeriod } from './utils';
import { OBJECT_OF_WARRANTY_TEXT, WARRANTY_TIME_TEXT } from '../constants';
import {
  getPeriodProperty,
  getTextByObjectOfWarranty,
  hasWarranty,
  insertFullObjectNameToWarrantyPeriod,
} from '../utils';

const Badge = styled.div(
  ({ theme: { space, colors } }) => css`
    position: static;
    padding: 2px ${space[0]}px;
    background: ${colors.divider};
    color: ${colors.text.secondary};
    border-radius: 8px;
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
  `,
);

const EmptySection = (
  <Text color='text.secondary' fontSize={5}>
    Подраздел не заполнен
  </Text>
);

const ServiceLifeTitle: FC<{
  item: IServiceLifeItem;
  fullObjectName: string;
}> = ({ item, fullObjectName }) => {
  if (
    item.objectOfWarranty === ObjectOfWarrantyEnum.partial &&
    !item.productPartialName
  ) {
    return (
      <>
        <Text>
          {OBJECT_OF_WARRANTY_TEXT[ObjectOfWarrantyEnum.partial]}:&nbsp;
        </Text>
        <Text color='text.disabled'>не указано</Text>
      </>
    );
  }

  return (
    <Text truncate>
      {getTextByObjectOfWarranty({
        fullObjectOfWarranty: fullObjectName,
        value: item,
      })}
    </Text>
  );
};

const MAX_HEIGHT = 400;

interface IWarrantyPageProps {
  warranty?: IWarranty;
  title?: ReactNode;
  id?: string;
  /**
   * Наименование объекта, в том случае, когда сущность создается не к узлу/части товара и т.д., а ко всему объекту
   */
  fullObjectName: string;
  startGenerateDocxFile?: VoidFunction;
}

const WarrantyPage: FC<IWarrantyPageProps> = ({
  id,
  warranty,
  startGenerateDocxFile,
  fullObjectName,
  title,
}) => {
  const { unitToString } = useUnit();

  if (!hasWarranty(warranty)) return null;

  const { warrantyPeriods, termsOfWarrantyService, serviceLives } = warranty;

  const warrantyPeriodsWithName = insertFullObjectNameToWarrantyPeriod(
    warrantyPeriods?.items ?? [],
    fullObjectName,
  );

  const getPeriod = (period): string => {
    return period ? getWarrantyPeriod({ period, unitToString }) : '—';
  };

  const columns = [
    {
      key: 'name',
      header: { text: 'Объект гарантии' },
      sortable: false,
      render: (name, { objectOfWarranty }) => {
        return (
          <>
            {name}
            <br />
            <Text color='text.secondary' fontSize={2} mt='2px'>
              {OBJECT_OF_WARRANTY_TEXT[objectOfWarranty]}
            </Text>
          </>
        );
      },
    },
    {
      key: 'exploitationPeriod',
      header: {
        text: 'Эксплуатация',
      },
      sortable: false,
      render: (exploitationPeriod, period) => {
        return (
          <>
            {getPeriod(exploitationPeriod)}
            <br />
            {period.time && (
              <Text truncate color='text.secondary' fontSize={2} mt='2px'>
                {WARRANTY_TIME_TEXT[period.time]}
              </Text>
            )}
          </>
        );
      },
    },
    {
      key: '',
      header: {
        text: <Badge>или</Badge>,
        width: 90,
      },
      sortable: false,
    },
    {
      key: 'operatingTime',
      header: { text: 'Наработка' },
      sortable: false,
      render: operatingTime =>
        operatingTime ? getOperatingTime({ operatingTime, unitToString }) : '—',
    },
    {
      key: 'storagePeriod',
      header: { text: 'Хранение' },
      sortable: false,
      render: storagePeriod => getPeriod(storagePeriod),
    },
    {
      key: 'conservationPeriod',
      header: { text: 'Консервация' },
      sortable: false,
      render: conservationPeriod => getPeriod(conservationPeriod),
    },
  ];

  return (
    <InfoBlock
      collapse
      id={id}
      initialOpen={false}
      rightActions={
        startGenerateDocxFile && (
          <DownloadSectionButton onClick={startGenerateDocxFile} />
        )
      }
      title={
        title ?? (
          <SectionTitle
            isPrivate={warranty.isPrivate}
            text='Сопроводительная информация: Гарантия'
          />
        )
      }
    >
      {warrantyPeriods && (
        <CollapseCard title={<Heading as='h4'>Гарантийный срок</Heading>}>
          {warrantyPeriodsWithName.length > 0 ? (
            <DataTable
              isInnerTable
              columns={columns}
              data={warrantyPeriodsWithName}
              minHeight='auto'
            />
          ) : (
            EmptySection
          )}
        </CollapseCard>
      )}
      {serviceLives && (
        <CollapseCard title={<Heading as='h4'>Срок службы</Heading>}>
          {serviceLives.items?.length > 0 ? (
            <DescriptionList dotted bg='white'>
              {serviceLives.items.map(item => (
                <DescriptionList.Group
                  key={item.id}
                  style={{ marginTop: 0 }}
                  title={
                    <ServiceLifeTitle
                      fullObjectName={fullObjectName}
                      item={item}
                    />
                  }
                >
                  {getPeriodProperty({
                    period: item.serviceLife,
                    label: 'Срок службы',
                    unitToString,
                    tip:
                      item.isComponentReplacementIncluded &&
                      'С учетом замены комплектующих',
                  })}
                </DescriptionList.Group>
              ))}
            </DescriptionList>
          ) : (
            EmptySection
          )}
        </CollapseCard>
      )}
      {!!termsOfWarrantyService && (
        <CollapseCard
          title={<Heading as='h4'>Условия гарантийного обслуживания</Heading>}
        >
          {termsOfWarrantyService.text ? (
            <ExpandableContainer maxHeight={MAX_HEIGHT}>
              <ExpandableContainer.DangerouslyTextWrapper
                fontSize={5}
                style={{ whiteSpace: 'break-spaces' }}
                text={termsOfWarrantyService.text}
              />
            </ExpandableContainer>
          ) : (
            EmptySection
          )}
        </CollapseCard>
      )}
    </InfoBlock>
  );
};

export { WarrantyPage };
