import styled, { css } from 'styled-components';

export const SelectItem = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 38px;
  margin-top: 7px;
  line-height: 1.2;
`;

export const RightAddon = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    top: ${space[1]}px;
    display: flex;
    margin-left: auto;
  `,
);
