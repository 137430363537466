import { forwardRef, Fragment, useState } from 'react';

import styled from 'styled-components';

import { useRequest } from 'common/hooks';
import { getImageURL, timestampsOfCroppedImages } from 'common/utils';
import { request } from 'common/utils/request';
import { FileUpload } from 'components/FileUpload';
import { TrashIcon } from 'resources/icons/18';

import { AvatarInput } from './AvatarInput';
import { FormError } from '../FormError';
import { IconButton } from '../IconButton';
import { Text } from '../Text/Text';

const FieldContainer = styled.div`
  display: flex;
  align-items: center;
`;

// Handle if error is not a string
// It could be an object with { id, uri, filename } errors
const getAvatarError = error => {
  if (!error) return undefined;
  if (typeof error === 'string') return error;
  return 'Ошибка!';
};

const DEFAULT_TEXT_CONTENT = (
  <Fragment>
    Вы можете загрузить фотографию в формате JPG или PNG.
    <br />
    Максимальный размер фото — 200KB.
    <br />
    Максимальное разрешение файла — 300x300.
  </Fragment>
);

const fetchMetadata = ({ id, section }) =>
  request.get(`/files/metadata/${section}/${id}`).then(({ data }) => data);

const AvatarInputField = forwardRef(
  (
    {
      disabled,
      previewMode,
      rounded,
      required,
      placeholder,
      limitSize,
      textContent = DEFAULT_TEXT_CONTENT,
      form,
      field,
      section = 'public-images',
      remove,
    },
    ref,
  ) => {
    const hasRemove = Boolean(remove);
    const [avatarValue, setAvatarValue] = useState({ file: null, crop: null });
    const error = field.error ? getAvatarError(field.error) : undefined;

    const handleChange = value => {
      if (value === null) {
        const currentUri = field.value.uri;
        timestampsOfCroppedImages.delete(currentUri);
      } else if (value.uri) {
        timestampsOfCroppedImages.set(value.uri, Date.now());
      }

      form.setFieldValue(field.name, value);
    };

    const handleRemove = () => {
      form.setFieldValue(field.name, null);

      if (typeof remove === 'function') {
        remove(field.name);
      }
    };

    const { data: metadata, isLoading: isCropLoading } = useRequest(
      field.value?.id && !previewMode && fetchMetadata,
      [{ id: field.value?.id, section }],
    );

    return (
      <FileUpload
        section={section}
        file={avatarValue.file || field.value}
        crop={avatarValue.crop}
        onLoad={handleChange}
      >
        {({ error: uploadError, loading }) => (
          <div ref={ref}>
            <FieldContainer>
              <AvatarInput
                limitSize={limitSize}
                loading={isCropLoading ? 50 : loading}
                disabled={disabled}
                previewMode={previewMode}
                rounded={rounded}
                required={required}
                placeholder={placeholder}
                initialCrop={avatarValue.crop || metadata}
                previewSrc={getImageURL(field.value)}
                src={getImageURL(field.value, { size: 'original' })}
                error={error}
                uploadError={uploadError}
                onChange={(file, crop) => {
                  setAvatarValue({ file, crop });

                  if (file === null && crop === null) {
                    handleChange(null);
                  }
                }}
              />
              {hasRemove && (
                <IconButton size={34} ml={0} onClick={handleRemove}>
                  <TrashIcon />
                </IconButton>
              )}
            </FieldContainer>
            <Text as='p' mt={1} mb={0} fontSize={1} color='text.secondary'>
              {textContent}
            </Text>
            <FormError show={!uploadError && error}>{error}</FormError>
          </div>
        )}
      </FileUpload>
    );
  },
);

export { AvatarInputField };
