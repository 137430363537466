import { memo, FC, ReactNode, Dispatch, SetStateAction } from 'react';

import styled, { css } from 'styled-components';

import { MASTER_PANEL_SCREENS } from 'common/constants/master-panel.const';
import { IProduct } from 'common/types/product.types';
import { getProductFullName } from 'common/utils/products';
import { Container, Text, Tooltip } from 'UI';

import { PARTITIONS_TEXT } from './constants';
import { Partitions } from './types';
import { getSubsections } from '../../../../../features/products/create/_shared/recommendation-panel/access-panel/components';
import { useMasterPanelEntityFormContext } from '../../../index';
import { MasterPanelHeader } from '../../index';
import { useMasterPanelContext } from '../../MasterPanel';

const Item = styled.div<{ isDisabled?: boolean }>(
  ({ theme: { space, colors, fontSizes }, isDisabled }) => css`
    min-height: 34px;
    padding: ${space[1]}px ${space[0]}px;
    font-size: ${fontSizes[3]}px;
    color: ${isDisabled ? colors.text.disabled : colors.text.primary};
    border-radius: 4px;
    cursor: pointer;
    transition: 0.2s ease-in-out all;

    ${isDisabled
      ? css`
          width: fit-content;
        `
      : css`
          &:hover {
            background: ${colors.highlight[0]};
          }
        `}
  `,
);

const DisabledItem: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Tooltip arrow hint title='Скоро в системе'>
      <Item isDisabled>{children}</Item>
    </Tooltip>
  );
};

interface IChoosingSectionProps {
  isPartialEditing: boolean;
  selectedProduct: IProduct;
  setSelectedPartition: Dispatch<SetStateAction<string>>;
}

const ChoosingSection: FC<IChoosingSectionProps> = memo(
  ({ isPartialEditing, selectedProduct, setSelectedPartition }) => {
    const { pushPanelState } = useMasterPanelContext();

    const {
      productForms: {
        props: { formProductType },
      },
    } = useMasterPanelEntityFormContext();

    const hasProperties = selectedProduct.properties.length > 0;
    const hasMedia = getSubsections(selectedProduct.media).length > 0;
    const hasAccompanyingInformation =
      getSubsections(selectedProduct.accompanyingInformation).length > 0;
    const hasCommercialInformation =
      getSubsections(selectedProduct.commercialInformation).length > 0;

    const handlePushSection = (partition: string): void => {
      setSelectedPartition(partition);
      pushPanelState(MASTER_PANEL_SCREENS.REUSE.ADDING_PARAMETERS);
    };

    return (
      <Container column width='100%'>
        <MasterPanelHeader
          rightAddon={
            <Text truncate color='text.secondary' fontSize={5} ml={1}>
              {getProductFullName(selectedProduct)}
            </Text>
          }
          title='Разделы товара'
        />
        <Container column height='100%' overflowY='auto' pb={3} pt={2} px={4}>
          <Item onClick={() => handlePushSection(Partitions.general)}>
            {PARTITIONS_TEXT[Partitions.general]}
          </Item>
          {hasProperties && (
            <Tooltip
              arrow
              hint
              title={
                (isPartialEditing &&
                  'Истек срок полного редактирования товара') ||
                (!formProductType && 'Заполните тип текущего товара')
              }
            >
              <Item
                isDisabled={isPartialEditing || !formProductType}
                onClick={() => {
                  if (formProductType && !isPartialEditing) {
                    handlePushSection(Partitions.properties);
                  }
                }}
              >
                {PARTITIONS_TEXT[Partitions.properties]}
              </Item>
            </Tooltip>
          )}
          {hasMedia && (
            <Item onClick={() => handlePushSection(Partitions.media)}>
              {PARTITIONS_TEXT[Partitions.media]}
            </Item>
          )}
          {hasAccompanyingInformation && (
            <DisabledItem>
              {PARTITIONS_TEXT[Partitions.accompanyingInformation]}
            </DisabledItem>
          )}
          {hasCommercialInformation && (
            <DisabledItem>
              {PARTITIONS_TEXT[Partitions.commercialInformation]}
            </DisabledItem>
          )}
        </Container>
      </Container>
    );
  },
);

export { ChoosingSection };
