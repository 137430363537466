import { FC, memo } from 'react';

import { fetchManufacturedNtds } from 'common/api/ntd.api';
import { useRequest } from 'common/hooks';
import { IManufacturers } from 'common/types/product.types';
import { getImageURL } from 'common/utils';
import { DictionaryManufacturerItem } from 'components/dictionaries/styled';
import { LinkOutside1pxIcon } from 'resources/icons/1px-18';
import { getPath, PAGES, COMPANY } from 'routes';
import { Link } from 'tools/libs';
import { Avatar, Tooltip } from 'UI';

import { ExternalLink, ManufacturerName } from '../styled';

const ManufacturersCard: FC<{ manufacturer: IManufacturers; ntdId: string }> =
  memo(({ manufacturer, ntdId }) => {
    const { data: manufacturedNtdId } = useRequest(
      fetchManufacturedNtds,
      [{ companyId: manufacturer.id, ntdId, itemsPerPage: 1 }],
      { responseToData: response => response.member[0]?.id },
    );

    return (
      <DictionaryManufacturerItem key={manufacturer.id}>
        <Avatar
          as={Link}
          letter={manufacturer.name}
          mr={2}
          size='xs'
          to={getPath(COMPANY.CARD, { id: manufacturer.id })}
          url={getImageURL(manufacturer.logo)}
        />
        <Tooltip placement='top' title={manufacturer.name}>
          <ManufacturerName to={getPath(COMPANY.CARD, { id: manufacturer.id })}>
            {manufacturer.name}
          </ManufacturerName>
        </Tooltip>
        <ExternalLink
          as='a'
          href={getPath(PAGES.MANUFACTURED_NTD, { id: manufacturedNtdId })}
          size={34}
          target='_blank'
        >
          <Tooltip placement='top' title='К НТД в производстве'>
            <LinkOutside1pxIcon />
          </Tooltip>
        </ExternalLink>
      </DictionaryManufacturerItem>
    );
  });

export { ManufacturersCard };
