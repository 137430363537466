import { FC } from 'react';

import styled, { css } from 'styled-components';

import { Nullable } from 'common/types/common.types';
import { IImage } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { EmptyImageIcon } from 'resources/placeholders';

const Preview = styled.img`
  width: 324px;
  height: 324px;
  margin-right: 28px;
  border-radius: 8px;
`;

const PreviewEmptyImage = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 324px;
    height: 324px;
    margin-right: 28px;
    border-radius: 8px;
    background: ${colors.highlight[1]};
)`,
);

const PreviewImage: FC<{ image: Nullable<IImage | undefined> }> = ({
  image,
}) => {
  return image ? (
    <Preview alt={image.filename} src={getImageURL(image, { size: 'l' })} />
  ) : (
    <PreviewEmptyImage>
      <EmptyImageIcon color='highlight.1' />
    </PreviewEmptyImage>
  );
};

export { PreviewImage };
