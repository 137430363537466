import { FC, memo } from 'react';

import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { POINTS_OF_SALE_TYPES_NAME } from 'common/constants/points-of-sale.const';
import { handleScrollTo } from 'common/hooks';
import {
  IPointsOfSale,
  PointsOfSaleType,
} from 'common/types/point-of-sale.types';
import { ProductSections } from 'common/types/product.types';
import { getValidUrl } from 'common/utils';
import { getCompanyId } from 'entities/identity';
import { ExternalLinkIcon } from 'resources/other-12';
import { COMPANY, getPath } from 'routes';
import {
  AnchorButton,
  Badge,
  Box,
  Container,
  Divider,
  Heading,
  Text,
} from 'UI';

import { ContactItem } from '../../Map/_shared/styled';
import { GeneratedBySystemStatus } from '../PointOfSalesStatuses';
import { PointOfSaleLink } from '../styled';

const PreviewPointOfSaleSection = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    grid-column-gap: ${space[3]}px;
  `,
);

const PreviewPointOfSaleCard: FC<{ point: IPointsOfSale }> = memo(
  ({ point }) => {
    const {
      isGeneratedBySystem,
      id,
      name,
      location,
      url,
      ownershipCompany,
      type,
    } = point;
    const userCompanyId = useSelector(getCompanyId);
    const isMyPointOfSale = userCompanyId === ownershipCompany?.id;

    return (
      <Container display='block' mb={3}>
        <Badge
          fontSize='8px'
          fontWeight={500}
          label={type ? POINTS_OF_SALE_TYPES_NAME[type] : null}
          letterSpacing='0.5px'
          mb={0}
          textTransform='uppercase'
        />
        <Container column>
          <Container alignItems='center' display='inline-flex' mb={0}>
            <Text
              truncate
              as={Link}
              color='text.primary'
              fontSize={4}
              fontWeight={500}
              mr={1}
              to={getPath(COMPANY.POINTS_OF_SALE_PAGE, { id })}
            >
              {name}
            </Text>
            {isGeneratedBySystem && isMyPointOfSale && (
              <GeneratedBySystemStatus />
            )}
          </Container>
          {point.type !== PointsOfSaleType.onlineStore && location && (
            <ContactItem
              mb='0px'
              primaryColor={false}
              text={location.address}
              truncate={false}
            />
          )}
          {point.type === PointsOfSaleType.onlineStore && (
            <PointOfSaleLink
              color='text.secondary'
              href={getValidUrl(url)}
              rel='noreferrer noopener'
              target='_blank'
            >
              Перейти на сайт
              <ExternalLinkIcon ml={1} />
            </PointOfSaleLink>
          )}
        </Container>
      </Container>
    );
  },
);

const PreviewPointsOfSale: FC<{ points: IPointsOfSale[] }> = memo(
  ({ points }) => {
    if (points?.length === 0) return null;

    return (
      <Box
        id={`${ProductSections.description}.${ProductSections.pointOfSale}`}
        mt={3}
      >
        <Divider my='0px' />
        <Heading as='h4' mb='20px' pt={3}>
          Места продаж
        </Heading>
        <PreviewPointOfSaleSection>
          {points?.map((point: IPointsOfSale) => (
            <PreviewPointOfSaleCard key={point.id} point={point} />
          ))}
        </PreviewPointOfSaleSection>
        <AnchorButton
          data-type={ProductSections.pointOfSale}
          mt={2}
          onClick={event => {
            handleScrollTo(event);
          }}
        >
          Все места продаж
        </AnchorButton>
      </Box>
    );
  },
);

export { PreviewPointsOfSale };
