import styled, { css } from 'styled-components';
import { space, SpaceProps } from 'styled-system';

const FormError = styled.div<{ show?: boolean } & SpaceProps>(
  ({ theme: { colors, fontSizes }, show }) => css`
    min-height: 1.2em;
    color: ${colors.error.main};
    font-size: ${fontSizes[1]}px;
    -webkit-font-smoothing: antialiased; // возможное решение для проблемы лишней жирности ошибки
    -moz-osx-font-smoothing: grayscale;
    word-wrap: break-word;
    visibility: ${show ? 'visible' : 'hidden'};

    ${space};
  `,
);

export { FormError };
