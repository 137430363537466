import { CSSProperties, FC, forwardRef, ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  color,
  ColorProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { CloseMediumIcon } from 'resources/icons/18';

import { Box, CollapseHeader, Container } from './Paper';
import { ICollapse, useCollapse } from '../CollapseCard';
import { Divider, VerticalDivider } from '../Divider';
import { IconButton } from '../IconButton';

const SIDE_PANEL_MIN_HEIGHT = 600;

const BoxWrapper = styled.div<
  { badges?: boolean; bottomAddon?: boolean } & SpaceProps & ColorProps
>(
  ({ theme: { colors, space }, badges, bottomAddon }) => css`
    position: relative;
    padding: ${space[6]}px;
    margin-top: ${space[4]}px;
    min-width: 1000px;
    background: ${colors.white};
    border-radius: ${space[3]}px;

    ${badges &&
    css`
      padding-top: 0;
    `}

    ${bottomAddon &&
    css`
      padding-bottom: ${space[3]}px;
    `}

    ${styledSpace}
    ${color}
  `,
);

const SidePanelContainer = styled.div<{ isOpen?: boolean }>(
  ({ isOpen }) => css`
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 100%;
    overflow: hidden;

    ${isOpen &&
    css`
      right: 0;
    `}
  `,
);

const SidePanelWrapper = styled.div<{ isOpen?: boolean }>(
  ({ theme: { space, colors }, isOpen }) => css`
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 486px;
    height: 100%;
    transform: translateX(-100%);
    min-height: ${SIDE_PANEL_MIN_HEIGHT}px;
    border-radius: ${space[3]}px 0 0 ${space[3]}px;
    background: ${colors.white};
    transition: transform 200ms ease-in;

    ${isOpen &&
    css`
      transform: translateX(0);
    `}
  `,
);

const BadgeWrapper = styled(Box)(
  ({ theme: { space } }) => css`
    display: flex;
    height: 24px;
    align-items: center;
    justify-content: flex-end;
    margin-right: -22px;
    margin-top: ${space[3]}px;
    margin-bottom: ${space[3]}px;
  `,
);

const Overlay = styled.div(
  ({ theme: { colors, space } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: ${colors.blackout};
    border-radius: 30px ${space[3]}px ${space[3]}px 30px;
  `,
);

const CloseButton = styled(IconButton)(
  ({ theme: { colors, borderRadius } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.highlight[2]};
    border-radius: 0 0 0 ${borderRadius}px;
  `,
);

const StyledVerticalDivider = styled(VerticalDivider)<SpaceProps>(
  ({ theme: { space } }) => css`
    height: auto;
    margin: -${space[6]}px ${space[4]}px;

    ${styledSpace}
  `,
);

interface ISidePanel {
  id?: string;
  isOpen: boolean;
  children: ReactNode;
  onRequestClose: () => void;
}

const SidePanel: FC<ISidePanel> = ({
  isOpen,
  id = 'filtersPanel',
  children,
  onRequestClose,
}) => {
  return (
    <SidePanelContainer isOpen={isOpen}>
      {isOpen && <Overlay onClick={onRequestClose} />}
      <SidePanelWrapper id={id} isOpen={isOpen}>
        <CloseButton onClick={onRequestClose}>
          <CloseMediumIcon />
        </CloseButton>
        {children}
      </SidePanelWrapper>
    </SidePanelContainer>
  );
};

const LeftColumn: FC<{ children: ReactNode }> = ({ children }) => {
  return (
    <Container display='block' flexGrow={1} minWidth={1}>
      {children}
    </Container>
  );
};

interface IRightColumnProps extends SpaceProps {
  children: ReactNode;
  column?: boolean;
}

const RightColumn: FC<IRightColumnProps> = ({ children, column, ...other }) => {
  return (
    <Container column={column} maxWidth={300} minWidth={300} {...other}>
      {children}
    </Container>
  );
};

interface IInfoBlock {
  style?: CSSProperties;
  id?: string;
  color?: string;
  collapse?: boolean;
  title?: ReactNode;
  badges?: ReactNode;
  sidePanel?: {
    id?: string;
    isOpen: boolean;
    component: () => ReactElement;
    onRequestClose: () => void;
  };
  children: ReactNode;
  rightActions?: ReactNode;
  bottomAddon?: ReactNode;
}

const INITIAL_SIDE_PANEL = {
  id: undefined,
  isOpen: false,
  component: null,
  onRequestClose: () => {},
};

const InfoBlock = forwardRef<
  HTMLDivElement,
  SpaceProps & ColorProps & IInfoBlock & ICollapse
>(
  (
    {
      style,
      id,
      title,
      collapse,
      badges,
      initialOpen,
      open,
      sidePanel: {
        id: filterId,
        isOpen: isOpenSidePanel,
        component: Component,
        onRequestClose,
      } = INITIAL_SIDE_PANEL,
      children,
      rightActions,
      onRequestToggle,
      bottomAddon,
      color: styledColor,
      ...other
    },
    ref,
  ) => {
    const { isOpen, handleToggle } = useCollapse({
      open,
      initialOpen,
      onRequestToggle,
    });

    return (
      <BoxWrapper
        ref={ref}
        badges={!!badges}
        bottomAddon={!!bottomAddon}
        color={styledColor}
        id={id}
        style={{
          paddingTop: collapse && !isOpen ? 0 : undefined,
          paddingBottom: collapse && !isOpen ? 0 : undefined,
          minHeight: isOpenSidePanel ? SIDE_PANEL_MIN_HEIGHT : 'inherit',
          ...style,
        }}
        {...other}
      >
        {badges && <BadgeWrapper>{badges}</BadgeWrapper>}
        <Box>
          {title && (
            <CollapseHeader
              collapse={collapse}
              isOpen={isOpen}
              mb={1}
              rightActions={rightActions}
              size='s'
              title={title}
              onToggleCollapse={handleToggle}
            />
          )}
          {collapse && !isOpen ? null : children}
        </Box>
        {Component && (
          <SidePanel
            id={filterId}
            isOpen={isOpenSidePanel}
            onRequestClose={onRequestClose}
          >
            <Component />
          </SidePanel>
        )}
        {bottomAddon && (
          <div>
            <Divider mx={-6} my={3} />
            {bottomAddon}
          </div>
        )}
      </BoxWrapper>
    );
  },
);

const InfoBlockExtension = Object.assign(InfoBlock, {
  LeftColumn,
  Divider: StyledVerticalDivider,
  RightColumn,
});

export { InfoBlockExtension as InfoBlock };
