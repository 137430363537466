import { memo, FC } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { CompanyIcon } from 'resources/icons/24';
import { DICTIONARIES } from 'routes';
import { Img, Link } from 'tools/libs';
import { Container, Text } from 'UI';

import { PARTNERS, PARTNER_IMG_STYLE } from './constants';
import { ReactComponent as SearchIcon } from './images/serach.svg';
import impulseTheme from '../../../../theme/theme';
import { COLOR, CONTAINER_PROPS, TEXT_PROPS } from '../constants';

const CompanyListLink = styled(Link)(
  ({ theme: { colors } }) => css`
    display: flex;
    align-items: center;
    font-size: 18px;
    line-height: 1;
    color: ${colors.text.primary};

    &:hover {
      color: ${impulseTheme.colors.primary.dark};
    }
  `,
);

const PartnersWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(207px, 1fr));
    grid-gap: ${space[2]}px;
    width: 100%;
  `,
);

const SearchLink = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 140px;
  color: ${impulseTheme.colors.primary.main};
  background: ${impulseTheme.colors.highlight[1]};

  &:hover {
    color: ${impulseTheme.colors.primary.dark};
  }
`;

const Partners: FC = memo(() => {
  const theme = useTheme();

  return (
    <Container background={COLOR}>
      <Container {...CONTAINER_PROPS} column py='76px'>
        <Container alignItems='flex-end' justifyContent='space-between' mb={5}>
          <Text {...TEXT_PROPS['48']}>С нами работают</Text>
          <CompanyListLink to={DICTIONARIES.COMPANIES}>
            <CompanyIcon color='inherit' mr={1} /> Список компаний
          </CompanyListLink>
        </Container>
        <PartnersWrapper>
          {PARTNERS.map(({ image, alt, link }, index) => (
            <Container
              key={index}
              alignItems='center'
              as={Link}
              background={theme.colors.white}
              height='140px'
              justifyContent='center'
              to={link}
            >
              <Img alt={alt} src={image} style={PARTNER_IMG_STYLE} />
            </Container>
          ))}
          <SearchLink to={DICTIONARIES.COMPANIES}>
            <SearchIcon />
            <Text mt='12px' {...TEXT_PROPS['18']}>
              Найти поставщиков
            </Text>
          </SearchLink>
        </PartnersWrapper>
      </Container>
    </Container>
  );
});

export { Partners };
