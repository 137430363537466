import { FC, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import { width, WidthProps } from 'styled-system';

const TextBlockWrapper = styled.div(
  ({ theme: { colors, space, fontSizes } }) => css`
    ${width};
    margin: 0 auto;

    h3,
    h4,
    h5,
    h6,
    p,
    ol,
    ul,
    table {
      margin: 0 0 ${space[2]}px;
    }

    h2 {
      margin: 0 0 ${space[3]}px;
    }

    img {
      display: block;
      margin: 0 auto ${space[2]}px;
    }

    & > div > *:last-child,
    & > div > *:last-child > *:last-child {
      margin-bottom: 0;
    }

    ul ul {
      margin: 0;
    }

    li > *:first-of-type,
    li > *:first-of-type {
      margin-top: ${space[2]}px;
    }

    h3,
    h4,
    h5,
    h6 {
      margin-top: ${space[4]}px;
    }

    &,
    p {
      font-size: ${fontSizes[5]}px;

      & > img {
        margin-top: ${space[2]}px;
      }
    }

    h2 {
      font-size: 18px;
      font-weight: 500;
    }

    h3 {
      font-size: ${fontSizes[6]}px;
      font-weight: 500;
    }

    h4 {
      font-size: ${fontSizes[5]}px;
      font-weight: 600;
    }

    h5 {
      font-size: ${fontSizes[4]}px;
      font-weight: 500;
    }

    table {
      position: relative;
      width: 100%;
      border: 1px solid ${colors.divider};
      table-layout: auto;
      border-collapse: collapse;

      p {
        margin: 0;
      }

      & th,
      & td {
        width: 25%;
      }
      & td {
        padding: 14px ${space[2]}px;
        border-right: 1px solid ${colors.divider};

        &:last-child {
          border-right: none;
        }
      }

      & th {
        color: ${colors.text.secondary};
        text-align: center;
        padding: ${space[1]}px ${space[2]}px;
        border: 1px solid ${colors.divider};
      }

      & tr:nth-child(even) td {
        background: ${colors.highlight[0]};
      }
    }

    div[id] {
      &:after {
        content: '';
        display: block;
        margin: ${space[6]}px auto;
        height: 1px;
        background: ${colors.divider};
      }

      &:last-child {
        &:after {
          display: none;
        }
      }
    }
  `,
);

interface ITextBlock {
  children: ReactNode;
  width: WidthProps;
}

const TextBlock: FC<ITextBlock & WidthProps> = ({ children, ...other }) => {
  return <TextBlockWrapper {...other}>{children}</TextBlockWrapper>;
};

export { TextBlock };
