import {
  useState,
  useCallback,
  memo,
  FC,
  CSSProperties,
  Dispatch,
  SetStateAction,
} from 'react';

import styled, { css } from 'styled-components';
import { SpaceProps } from 'styled-system';
import { v4 as uuid } from 'uuid';

import { IMapCategory } from 'common/types/categories.types';
import { Nullable } from 'common/types/common.types';
import {
  LevelRender,
  LevelContainer,
  LevelOpacityWrapper,
} from 'components/characteristic-elements/Level';

import { LevelSlice } from './LevelSlice';
import { INITIAL_CATEGORY } from '../../components';
import { Tracer } from '../types';

const StyledLevelContainer = styled(LevelContainer)(
  ({ theme: { space } }) => css`
    position: absolute;
    right: 0;
    left: 0;
    bottom: -${space[2]}px;
    margin-bottom: 0;
    background: transparent;
  `,
);

interface ICategoryLevelProps {
  hasStats?: boolean;
  currentLevel: number;
  tracers: Tracer[];
  categoriesMap: Map<string, IMapCategory>;
  setCategoriesMap: Dispatch<SetStateAction<Map<string, IMapCategory>>>;
  style?: CSSProperties;
}

const CategoryLevel: FC<ICategoryLevelProps & SpaceProps> = memo(
  ({
    hasStats,
    currentLevel = 0,
    tracers,
    categoriesMap,
    setCategoriesMap,
    ...other
  }) => {
    const [hoverLevel, setHoverLevel] = useState<Nullable<number>>(null);

    const handleAdd = useCallback(() => {
      const tempId = `tmp_${uuid()}`;
      const newMap = new Map(categoriesMap);

      const parentId = tracers[hoverLevel!]?.id;
      const parentCategory = parentId && categoriesMap.get(parentId);

      newMap.set(tempId, {
        ...INITIAL_CATEGORY,
        id: tempId,
        level: Number(hoverLevel),
        parentId: parentId ?? null,
      });

      if (parentCategory) {
        newMap.set(parentId, {
          ...parentCategory,
          children: [...parentCategory.children, tempId],
        });
      }

      setCategoriesMap(newMap);
    }, [categoriesMap, tracers, hoverLevel, setCategoriesMap]);

    const handleLevelChange = useCallback(
      (nextHoverLevel: Nullable<number>) => setHoverLevel(nextHoverLevel),
      [],
    );

    const handleLeave = useCallback(
      () => handleLevelChange(null),
      [handleLevelChange],
    );

    return (
      <StyledLevelContainer
        levelOffset={currentLevel}
        onDragLeave={handleLeave}
        onMouseLeave={handleLeave}
        {...other}
      >
        <LevelOpacityWrapper show={hoverLevel !== null}>
          {tracers.map((tracer, index) => {
            const isActive = index === hoverLevel;

            return (
              <LevelSlice
                key={tracer?.id ?? index}
                categoriesMap={categoriesMap}
                id={tracer?.id}
                isActive={isActive}
                level={index}
                setCategoriesMap={setCategoriesMap}
                onAdd={handleAdd}
                onLeave={handleLeave}
                onLevelChange={handleLevelChange}
              />
            );
          })}
          <LevelRender
            levelName={tracers[hoverLevel ?? 0]?.name ?? 'В корень'}
          />
        </LevelOpacityWrapper>
      </StyledLevelContainer>
    );
  },
);

export { CategoryLevel };
