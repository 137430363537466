import { Fragment, ReactElement, useState } from 'react';

import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Text, Drawer, Heading, TabList, TablistTab, Slider } from 'UI';

import { ReactComponent as AddGasButton } from './icons/add_gas_button.svg';
import { ReactComponent as AddInformationButton } from './icons/add_information_button.svg';
import AirExchangeFilled1 from './icons/air_exchange_filled_1.png';
import AirExchangeFilled2 from './icons/air_exchange_filled_2.png';
import { ReactComponent as AirExchangeInputsIcon } from './icons/air_exchange_inputs.svg';
import DustinessFilledIcon from './icons/dustiness_filled.png';
import { ReactComponent as DustinessInputsIcon } from './icons/dustiness_inputs.svg';
import ExampleAirExchangeIcon from './icons/example_air_exchange.png';
import ExampleHumidityIcon from './icons/example_humidity.png';
import ExampleRecommendationsIcon from './icons/example_recommendations.png';
import ExampleTemperatureIcon from './icons/example_temperature.png';
import GasCompositionFilled from './icons/gas_composition_filled.png';
import { ReactComponent as GasCompositionInputs } from './icons/gas_composition_inputs.svg';
import HumidityFilledIcon from './icons/humidity_filled.png';
import { ReactComponent as HumidityInputsIcon } from './icons/humidity_inputs.svg';
import IlluminationFilledIcon from './icons/illumination_filled.png';
import { ReactComponent as IlluminationInputsIcon } from './icons/illumination_inputs.svg';
import NeighborhoodFilledIcon from './icons/neighborhood_filled.png';
import { ReactComponent as NeighborhoodInputsIcon } from './icons/neighborhood_inputs.svg';
import { ReactComponent as RangeInputIcon } from './icons/range.svg';
import RangeFilledIcon from './icons/range_filled.png';
import { ReactComponent as RecommendationsInputsIcon } from './icons/recomendations_inputs.svg';
import RecommendationsFilledIcon from './icons/recommendations_filled.png';

const TextContainer = styled.div(
  ({ theme: { fontSizes } }) => css`
    font-size: ${fontSizes[2]}px;
  `,
);

const Header = styled.div(
  ({ theme: { space, colors } }) => css`
    height: ${space[8]}px;
    padding: 0 ${space[4]}px;
    line-height: ${space[8]}px;
    background: ${colors.background};
  `,
);

const StyledTabList = styled(TabList)`
  justify-content: center;
`;

const ContentWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${space[3]}px ${space[4]}px;
    overflow: auto;
  `,
);

const Image = styled.img<SpaceProps>`
  max-width: 460px;

  ${styledSpace};
`;

const TABS_KEYS = {
  PARAMETER_TYPES: 'parameterTypes',
  EXAMPLE: 'example',
};

const TABS_CONFIG = [
  { key: TABS_KEYS.PARAMETER_TYPES, title: 'Виды параметров' },
  { key: TABS_KEYS.EXAMPLE, title: 'Пример заполнения' },
];

// todo replace to strapi
const TABS_CONTENT = {
  [TABS_KEYS.PARAMETER_TYPES]: (
    <Fragment>
      <Text fontWeight={600} mb={1}>
        Температура хранения
      </Text>
      <Text>
        Укажите допустимую температуру окружающей среды при хранении.
        Обязательной является хотя бы одна граница диапазона: нижняя «от» или
        верхняя «до».
      </Text>
      <RangeInputIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра</Text>
      <Image alt='' mt={1} src={RangeFilledIcon} />
      <Text fontWeight={600} mb={1} mt={5}>
        Относительная влажность воздуха
      </Text>
      <Text>
        Укажите допустимое содержание влаги в воздухе, измеряется в процентах.
        Обязательной является хотя бы одна граница диапазона: нижняя «от» или
        верхняя «до».
      </Text>
      <HumidityInputsIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={HumidityFilledIcon} />
      <Text fontWeight={600} mb={1} mt={5}>
        Воздухообмен
      </Text>
      <Text>
        Из заданного списка выберите рекомендуемый тип воздухообмена при
        хранении. Можно добавить дополнительную информацию в отдельное поле.
      </Text>
      <AirExchangeInputsIcon my={1} />
      <AddInformationButton mb={2} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image my={1} src={AirExchangeFilled1} />
      <Image alt='' src={AirExchangeFilled2} />
      <Text fontWeight={600} mb={1} mt={5}>
        Газовый состав воздуха
      </Text>
      <Text>
        Из заданного списка выберите газ, входящий в состав воздуха в помещении
        для хранения. Укажите концентрацию данного газа в процентах.
        Обязательной является хотя бы одна граница диапазона: нижняя «от» или
        верхняя «до». Можно добавить до 5 видов газа.
      </Text>
      <GasCompositionInputs my={1} />
      <AddGasButton mb={2} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={GasCompositionFilled} />
      <Text fontWeight={600} mb={1} mt={5}>
        Освещенность
      </Text>
      <Text>
        Заполните текстовое поле с описанием рекомендуемых условий освещенности.
        Например: «Хранить в темноте».
      </Text>
      <IlluminationInputsIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={IlluminationFilledIcon} />
      <Text fontWeight={600} mb={1} mt={5}>
        Запыленность воздуха
      </Text>
      <Text>
        Укажите уровень допустимой запыленности воздуха. Обязательной является
        хотя бы одна граница диапазона: нижняя «от» или верхняя «до».
      </Text>
      <DustinessInputsIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={DustinessFilledIcon} />
      <Text fontWeight={600} mb={1} mt={5}>
        Нерекомендованное товарное соседство
      </Text>
      <Text>
        Перечислите товары, которые не рекомендуется хранить совместно с текущим
        товаром. Например: «сырые продукты, полуфабрикаты, готовая продукция»
      </Text>
      <NeighborhoodInputsIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={NeighborhoodFilledIcon} />
      <Text fontWeight={600} mb={1} mt={5}>
        Дополнительные рекомендации
      </Text>
      <Text>
        В текстовом поле укажите дополнительные условия хранения или комментарии
        производителя. Например: «Хранить в недоступном для детей месте».
      </Text>
      <RecommendationsInputsIcon mb={2} mt={1} />
      <Text>Параметр в режиме просмотра:</Text>
      <Image alt='' mt={1} src={RecommendationsFilledIcon} />
    </Fragment>
  ),
  [TABS_KEYS.EXAMPLE]: (
    <Fragment>
      <Text fontSize={4} fontWeight={500} mb={2}>
        Условия хранения товара, описанные в документации:
      </Text>
      <Text mb={1}>
        Рекомендации по хранению крепежных изделий:
        <ul>
          <li>Температура в здании: не меньше +12°C;</li>
          <li>Относительная влажность в воздухе: не больше 60%;</li>
          <li>Наличие равномерной принудительной вентиляции.</li>
        </ul>
      </Text>
      <Text>
        Примечание:
        <ul>
          <li>
            Держать крепежные элементы на открытых площадках можно лишь в
            течение небольшого промежутка времени (2-4 недели).
          </li>
        </ul>
      </Text>
      <Text fontSize={4} fontWeight={500} mb={2} mt={3}>
        Пример заполнения параметров в системе:
      </Text>
      <Image alt='' src={ExampleTemperatureIcon} />
      <Image alt='' mt={2} src={ExampleHumidityIcon} />
      <Image alt='' my={2} src={ExampleAirExchangeIcon} />
      <Image alt='' mb={2} src={ExampleRecommendationsIcon} />
    </Fragment>
  ),
};

interface StorageConditionsHelperModalProps {
  handleModalClose: () => void;
}

function StorageConditionsHelperModal({
  handleModalClose,
}: StorageConditionsHelperModalProps): ReactElement {
  const [currentTab, setCurrentTab] = useState(0);

  return (
    <Drawer handleModalClose={handleModalClose}>
      <Header>
        <Heading as='h3'>Подсказка</Heading>
      </Header>
      <Slider
        currentTab={currentTab}
        frameWidth={528}
        tabs={
          <StyledTabList mt={1} size='s'>
            {TABS_CONFIG.map(({ key, title }, index) => (
              <TablistTab
                key={key}
                aria-selected={currentTab === index}
                fontSize={3}
                onClick={() => setCurrentTab(index)}
              >
                {title}
              </TablistTab>
            ))}
          </StyledTabList>
        }
      >
        <Slider.Frame>
          <ContentWrapper>
            <TextContainer>
              {TABS_CONTENT[TABS_KEYS.PARAMETER_TYPES]}
            </TextContainer>
          </ContentWrapper>
        </Slider.Frame>
        <Slider.Frame>
          <ContentWrapper>
            <TextContainer>{TABS_CONTENT[TABS_KEYS.EXAMPLE]}</TextContainer>
          </ContentWrapper>
        </Slider.Frame>
      </Slider>
    </Drawer>
  );
}

const CONDITIONS_HELPER = 'CONDITIONS_HELPER';

export { StorageConditionsHelperModal, CONDITIONS_HELPER };
