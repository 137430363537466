import { FC, Fragment, memo, useCallback, useEffect, useMemo, useRef } from 'react';

import { useCombobox } from 'downshift';
import styled, { css, useTheme } from 'styled-components';

import { fetchProductTypesOptions } from 'common/api/product-management';
import { DeleteIcon } from 'resources/other-6';
import { defaultItemToString } from 'tools/utils/stubFunctions';
import { Container, IconButton } from 'UI';
import { BaseInput, InputContainer, Options, useAsyncOptions } from 'UI/Inputs';
import { stateReducer } from 'UI/Inputs/Combobox';

import { SearchIconWrapper, SearchWrapper } from '../../styled';

const COMBOBOX_STYLE = { borderWidth: 0 };

const OptionsWrapper = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    & > div {
      padding: ${space[1]}px 0;
    }

    div[role='option']:first-child {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      line-height: 1.2;

      &:after {
        content: 'Тип товара из формы';
        color: ${colors.text.secondary};
        font-size: ${fontSizes[2]}px;
      }
    }
  `,
);

interface ISearchProps {
  currentProductType: string;
  formProductType: string;
  onSearch: (value: string) => void;
}

const Search: FC<ISearchProps> = memo(
  ({ currentProductType, formProductType, onSearch }) => {
    const theme = useTheme();
    const [asyncState, dispatch] = useAsyncOptions();

    const containerRef = useRef(null);
    const searchInputRef = useRef<HTMLInputElement>(null);

    const filteredItems = useMemo(() => {
      return asyncState.items;
    }, [asyncState.items]);

    const downshift = useCombobox<string>({
      initialInputValue: currentProductType,
      items: asyncState.items,
      stateReducer,
      itemToString: defaultItemToString,
      onSelectedItemChange: ({ selectedItem }) => {
        onSearch(selectedItem as string);
      },
      onStateChange: ({ type }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputBlur:
            downshift.setInputValue(currentProductType);
            break;
          default:
            break;
        }
      },
    });

    const {
      inputValue,
      highlightedIndex,
      getInputProps,
      getComboboxProps,
      setInputValue,
      closeMenu,
    } = downshift;

    useEffect(() => {
      setInputValue(currentProductType);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentProductType]);

    const fetchComboOptions = useCallback(
      async (props): Promise<{ member: string[]; totalItems: number }> => {
        const searchProductTypes = await fetchProductTypesOptions(props);
        const indexInTypes = searchProductTypes.member.indexOf(formProductType);

        if (indexInTypes > 0) searchProductTypes.member.slice(indexInTypes, 1);

        searchProductTypes.member.unshift(formProductType);

        return searchProductTypes;
      },
      [formProductType],
    );

    const handleSubmit = (): void => {
      onSearch(inputValue);
    };

    const handleEnterPress = ({ key }: { key: string }): void => {
      if (key === 'Enter' && highlightedIndex < 0) {
        onSearch(inputValue as string);
        closeMenu();
      }
    };

    const handleBlankInputBlur = (): void => {
      if (!inputValue) setInputValue(currentProductType);
    };

    const handleClear = (): void => {
      setInputValue('');
      searchInputRef.current!.focus();
    };

    return (
      <Fragment>
        <SearchWrapper>
          <InputContainer {...getComboboxProps({ style: COMBOBOX_STYLE })}>
            <BaseInput
              {...getInputProps({
                style: { fontSize: theme.fontSizes[5] },
                placeholder: 'Введите тип товара',
                ref: searchInputRef,
                onKeyDown: handleEnterPress,
                // Добавлена проверка по blur, так как у downshift не вызывается Blur при пустой строке
                onBlur: handleBlankInputBlur,
              })}
              pl='0px'
              pr={0}
              value={inputValue}
            />
            <Container alignItems='center'>
              {inputValue && (
                <IconButton mr={1} title='Удалить' onClick={handleClear}>
                  <DeleteIcon size={12} />
                </IconButton>
              )}
              <IconButton
                disabled={!inputValue}
                size={34}
                title='Поиск'
                onClick={handleSubmit}
              >
                <SearchIconWrapper isDisabled={!inputValue} />
              </IconButton>
            </Container>
            <OptionsWrapper>
              <Options
                anchorEl={containerRef.current}
                dispatch={dispatch}
                downshift={downshift}
                filteredItems={filteredItems}
                heightItem={46}
                itemToKey={defaultItemToString}
                itemToString={defaultItemToString}
                options={fetchComboOptions}
                state={asyncState}
              />
            </OptionsWrapper>
          </InputContainer>
        </SearchWrapper>
        <Container ref={containerRef} mx={4} />
      </Fragment>
    );
  },
);

export { Search };
