import styled, { css } from 'styled-components';
import {
  color,
  space as styledSpace,
  SpaceProps,
  BackgroundColorProps,
} from 'styled-system';

interface ContainerProps {
  width?: number;
  collapseWidth?: number;
  collapse: boolean;
}

export const MenuContainer = styled.div<
  {
    width?: number;
    collapseWidth?: number;
    collapse?: boolean;
    isBordered?: boolean;
  } & ContainerProps &
    BackgroundColorProps
>(
  ({
    theme: { colors },
    width = 246,
    collapseWidth = 53,
    collapse,
    isBordered = true,
  }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 0 0 ${width}px;
    min-width: 0;
    height: 100vh;
    max-height: 100%;
    background: ${colors.background};
    color: ${colors.text.secondary};
    transition: flex 200ms ease-in-out;

    ${isBordered && `border-right: 1px solid ${colors.divider}`};

    *::-webkit-scrollbar-track {
      background: inherit;
    }

    ${collapse &&
    css`
      flex: 0 0 ${collapseWidth}px;
    `}

    ${color}
  `,
);

export const MenuWrapper = styled.div<
  { collapse: boolean } & SpaceProps & BackgroundColorProps
>(
  ({ collapse }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;

    ${collapse &&
    css`
      overflow-y: visible;
    `}

    ${styledSpace};
    ${color}
  `,
);

export const ButtonCollapse = styled.button<
  SpaceProps & { isActive?: boolean }
>(
  ({ theme: { colors, space }, isActive }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 34px;
    height: 34px;
    margin-left: ${space[1]}px;
    color: ${colors.text.secondary};
    line-height: 1.15;
    text-align: left;
    cursor: pointer;

    ${isActive &&
    css`
      background: ${colors.highlight[1]};

      & svg {
        color: ${colors.primary.main};
      }
    `}

    ${styledSpace};
  `,
);
