import { FC } from 'react';

import styled from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Skeleton, InfoBlock, Box } from 'UI';

const SkeletonPreviewGallery = styled(Skeleton)<SpaceProps>`
  flex-grow: 1;
  flex-basis: 50%;
  min-width: 0;

  ${styledSpace}
`;

const Filler = styled.div`
  position: relative;
  padding-bottom: 100%;

  &::before {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
  }
`;

const SkeletonProductPage: FC = () => (
  <InfoBlock badges={<Skeleton.Badge />} mt={2}>
    <Box display='flex'>
      <SkeletonPreviewGallery mr={3}>
        <Filler />
      </SkeletonPreviewGallery>
      <Box.FillingWrapper>
        <Skeleton.Text height={34} mb={3} />
        <Skeleton.Text />
        <Skeleton.Text />
        <Skeleton.Text width='50%' />
      </Box.FillingWrapper>
    </Box>
  </InfoBlock>
);

export { SkeletonProductPage, SkeletonPreviewGallery };
