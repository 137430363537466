import { ReactElement, ReactNode } from 'react';

import { addDays } from 'date-fns';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { ContextVIcon } from 'resources/icons/18';
import { useTimer } from 'common/hooks';
import { IEmployee, InvitationalStatuses } from 'common/types/employees.types';
import { getFullName, getImageURL } from 'common/utils';
import { PermissionsTable } from 'components/PermissionsTable';
import { ACCESS_ROLES } from 'entities/identity';
import { OPERATIONS, Permission, usePermission } from 'entities/permission';
import { Lock1pxIcon } from 'resources/icons/1px-18';
import { COMPANY, getPath } from 'routes';
import {
  Avatar,
  Button,
  ContextMenu,
  ContextMenuItem,
  DescriptionList,
  Divider,
  IconButton,
  Status,
  Text,
  Tooltip,
  useContextMenu,
} from 'UI';

import { IEmployeeInvitation } from '../../types';
import { getRemainingTimeText } from '../utils';

const CardContainer = styled.div(
  ({ theme: { space, shadows } }) => css`
    margin-bottom: ${space[2]}px;
    border-radius: 8px;
    box-shadow: ${shadows[0]};
    width: 762px;

    &:last-child {
      margin-bottom: 0;
    }
  `,
);

const MenuWrapper = styled.div`
  margin-left: auto;
`;

const Info = styled.div(
  ({ theme: { space } }) => css`
    flex-grow: 1;
    min-width: 0;
    margin-left: ${space[3]}px;
  `,
);

const FooterContainer = styled.footer(
  ({ theme: { space, colors } }) => css`
    height: ${space[7]}px;
    display: flex;
    align-items: center;
    padding: 0 ${space[4]}px;
    background: ${colors.background};
  `,
);

const ContentWrapper = styled.div(
  ({ theme: { colors, space } }) => css`
    display: flex;
    padding: ${space[4]}px ${space[4]}px ${space[2]}px;
    background: ${colors.white};
    border-radius: 8px;
  `,
);

const HeaderWrapper = styled.div(
  ({ theme: { space, fontSizes } }) => css`
    display: flex;
    align-items: center;
    margin-bottom: ${space[2]}px;
    padding: 0;
    font-weight: 600;
    font-size: ${fontSizes[5]}px;
    word-break: break-all;
  `,
);

interface CardProps {
  isEmployee?: boolean;
  isAdministrator?: boolean;
  employee: IEmployee;
  footer?: ReactNode;
  actions?: ReactNode[];
}

const TIP =
  'Сотрудник недоступен для редактирования или удаления, так как является администратором компании в системе.';

function Card({
  isEmployee,
  isAdministrator,
  employee,
  actions,
  footer,
}: CardProps): ReactElement {
  const fullName = getFullName(employee);
  const { open, anchorEl, handleContextMenuOpen, handleContextMenuClose } =
    useContextMenu();

  const position = employee.position?.localName;

  const accessRoles = employee.accessRoles
    .map(role => role.localName)
    .join(', ');

  const avatarProps = {
    cover: true,
    rounded: true,
    url: getImageURL(employee.avatarImage),
    letter: fullName,
  };

  return (
    <CardContainer>
      <ContentWrapper>
        <Avatar {...avatarProps} />
        <Info>
          <HeaderWrapper>
            {fullName || 'Имя не указано'}
            {isEmployee && <Status ml={1} size='14' type='success' />}
            {isAdministrator && (
              <Tooltip arrow maxWidth={272} title={TIP}>
                <Lock1pxIcon ml={1} />
              </Tooltip>
            )}
            {!!actions?.length && (
              <MenuWrapper>
                <IconButton focused={open} onClick={handleContextMenuOpen}>
                  <ContextVIcon />
                </IconButton>
                <ContextMenu
                  anchorEl={anchorEl}
                  open={open}
                  placement='bottom-end'
                  width='156px'
                  onRequestClose={handleContextMenuClose}
                >
                  {actions}
                </ContextMenu>
              </MenuWrapper>
            )}
          </HeaderWrapper>
          <DescriptionList
            propertyProps={{ fontWeight: 500, color: 'text.primary' }}
            widthKey='20%'
          >
            <DescriptionList.Property truncate label='Email:'>
              {employee.email}
            </DescriptionList.Property>
            <DescriptionList.Property label='Телефон:'>
              {employee.telephone}
            </DescriptionList.Property>
            <DescriptionList.Property label='Должность:'>
              {position}
            </DescriptionList.Property>
            <DescriptionList.Property label='Уровень доступа:'>
              <div style={{ wordBreak: 'break-word' }}>{accessRoles}</div>
            </DescriptionList.Property>
          </DescriptionList>
          <Divider mb={-1} mt={2} />
          <PermissionsTable
            accessRoles={employee.accessRoles}
            initialOpen={false}
            mt={2}
          />
        </Info>
      </ContentWrapper>
      {footer}
    </CardContainer>
  );
}

interface EmployeeFooterProps {
  updatedAt: string;
  onRecovery: () => void;
  updateData: () => void;
}

function EmployeeFooter({
  updatedAt,
  onRecovery,
  updateData,
}: EmployeeFooterProps): ReactElement | null {
  const remainingInviteTime = useTimer({
    finishDate: addDays(new Date(updatedAt), 2),
    format: 'remainingTime',
    onRequestTimerExpired: updateData,
  });

  return (
    <FooterContainer>
      <div>
        <Text>Сотрудник удален.</Text>
        {remainingInviteTime > 1 && (
          <Text color='text.secondary'>
            Он пропадет из списка сотрудников{' '}
            {getRemainingTimeText(remainingInviteTime)}.
          </Text>
        )}
      </div>
      {remainingInviteTime > 1 && (
        <Permission operation={OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE}>
          <Button ml='auto' variant='outlined' onClick={onRecovery}>
            Восстановить
          </Button>
        </Permission>
      )}
    </FooterContainer>
  );
}

interface EmployeeCardProps {
  employee: IEmployee;
  onDelete: (id: string) => void;
  onRecovery: (id: string) => void;
  updateData: () => void;
}

function EmployeeCard({
  employee,
  onDelete,
  onRecovery,
  updateData,
}: EmployeeCardProps): ReactElement {
  const isAdministrator = employee?.accessRoles.some(
    roles => roles.name === ACCESS_ROLES.COMPANY_ADMINISTRATOR,
  );

  const handleDelete = (): void => {
    onDelete(employee.id);
  };

  const handleRecovery = (): void => {
    onRecovery(employee.id);
  };

  const canRemove = usePermission(OPERATIONS.EMPLOYEES.DELETE_COMPANY_EMPLOYEE);
  const canEdit = usePermission(
    OPERATIONS.EMPLOYEES.UPDATE_COMPANY_ACCEPTED_EMPLOYEE,
  );

  const actions =
    !employee.isTrashed && !isAdministrator
      ? [
          canEdit && (
            <ContextMenuItem
              key='edit'
              as={Link}
              text='Редактировать'
              to={getPath(COMPANY.EMPLOYEES_EDIT, { id: employee.id })}
            />
          ),
          canRemove && (
            <ContextMenuItem
              key='remove'
              as='button'
              text='Удалить'
              onClick={handleDelete}
            />
          ),
        ].filter(Boolean)
      : undefined;

  const footer = employee.isTrashed && (
    <EmployeeFooter
      updatedAt={employee.updatedAt}
      updateData={updateData}
      onRecovery={handleRecovery}
    />
  );

  return (
    <Card
      actions={actions}
      employee={employee}
      footer={footer}
      isAdministrator={isAdministrator}
      isEmployee={!employee.isTrashed}
    />
  );
}

interface PendingInvitationFooterProps {
  invitationExpiresAt: string;
  onInvite: () => void;
  updateData: () => void;
}

function PendingInvitationFooter({
  invitationExpiresAt,
  onInvite,
  updateData,
}: PendingInvitationFooterProps): ReactElement {
  const hasInviteEmployeeAccess = usePermission(
    OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE,
  );
  const remainingTime = useTimer({
    finishDate: invitationExpiresAt,
    format: 'remainingTime',
    onRequestTimerExpired: updateData,
  });

  if (remainingTime < 1) {
    const remainingText = hasInviteEmployeeAccess
      ? 'Время запроса истекло. Пригласить сотрудника повторно?'
      : 'Время запроса истекло.';

    return (
      <FooterContainer>
        <Text>{remainingText}</Text>
        <Permission operation={OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE}>
          <Button ml='auto' variant='filled' onClick={onInvite}>
            Пригласить
          </Button>
        </Permission>
      </FooterContainer>
    );
  }

  return (
    <FooterContainer>
      <div>
        <Text mr={2}>На указанный email отправлено приглашение.</Text>
        <Text color='text.secondary'>
          Истекает {getRemainingTimeText(remainingTime)}.
        </Text>
      </div>
    </FooterContainer>
  );
}

interface InvitationFooterProps {
  invitationExpiresAt: string;
  invitationStatus: InvitationalStatuses;
  onInvite: () => void;
  updateData: () => void;
}

function InvitationFooter({
  invitationStatus,
  invitationExpiresAt,
  onInvite,
  updateData,
}: InvitationFooterProps): ReactElement | null {
  if (invitationStatus === InvitationalStatuses.declined) {
    return (
      <FooterContainer>
        <Text>
          Сотрудник отклонил приглашение. Пригласить сотрудника повторно?
        </Text>
        <Permission operation={OPERATIONS.EMPLOYEES.INVITE_EMPLOYEE}>
          <Button ml='auto' variant='outlined' onClick={onInvite}>
            Пригласить
          </Button>
        </Permission>
      </FooterContainer>
    );
  }

  if (invitationStatus === InvitationalStatuses.annulled) {
    return (
      <FooterContainer>
        <Text>
          Сотрудник отклонил приглашение, повторная отправка недоступна.
        </Text>
      </FooterContainer>
    );
  }

  if (invitationStatus === InvitationalStatuses.pending) {
    return (
      <PendingInvitationFooter
        invitationExpiresAt={invitationExpiresAt}
        updateData={updateData}
        onInvite={onInvite}
      />
    );
  }

  return null;
}

interface InvitationCardProps {
  invitation: IEmployeeInvitation;
  onDelete: (id: string) => void;
  onInvite: (id: string) => void;
  updateData: () => void;
}

function InvitationCard({
  invitation,
  onDelete,
  onInvite,
  updateData,
}: InvitationCardProps): ReactElement {
  const handleDelete = (): void => {
    onDelete(invitation.id);
  };

  const handleInvite = (): void => {
    onInvite(invitation.id);
  };

  const canRemove = usePermission(OPERATIONS.EMPLOYEES.DELETE_COMPANY_EMPLOYEE);

  const actions = canRemove
    ? [
        <ContextMenuItem
          key='remove'
          as='button'
          text='Удалить'
          onClick={handleDelete}
        />,
      ]
    : undefined;

  const footer = (
    <InvitationFooter
      invitationExpiresAt={invitation.invitationExpiresAt}
      invitationStatus={invitation.invitationStatus}
      updateData={updateData}
      onInvite={handleInvite}
    />
  );

  return <Card actions={actions} employee={invitation} footer={footer} />;
}

export { EmployeeCard, InvitationCard };
