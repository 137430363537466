import { forwardRef, MouseEvent, ReactElement, ReactNode } from 'react';

import styled, { css, CSSProp } from 'styled-components';
import {
  border,
  BorderProps,
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  height,
  HeightProps,
  space as styledSpace,
  SpaceProps,
  styleFn,
  WidthProps,
} from 'styled-system';

import { width as styledWidth } from 'common/constants/customStyledSystems.const';
import { TrashIcon } from 'resources/icons/18';
import { DoneIcon } from 'resources/other';
import { CloseMediumIcon } from 'resources/other-28';
import { Combobox, FormError, IconButton, Select, TextInput } from 'UI';
import { InputContainer } from 'UI/Inputs/components';
import theme from 'UI/theme';

const STYLE_CANCEL_NO_RADIUS = {
  borderRadius: `0 0 ${theme.borderRadius}px ${theme.borderRadius}px `,
};

const Container = styled.div.attrs(() => ({
  id: 'property-container',
}))<SpaceProps>`
  width: 100%;
  min-width: 0;

  ${styledSpace}
`;

const StyledInputContainer = styled(InputContainer)<BorderProps>(
  ({ theme: { borderRadius, space } }) => css`
    margin-top: -1px;
    margin-right: -1px;
    margin-left: -1px;
    padding-left: ${space[2]}px;
    width: auto;
    border-radius: ${borderRadius}px ${borderRadius}px 0 0;

    ${border}
  `,
);

const mainStyleForInputs: styleFn = ({
  theme: { borderRadius },
  width = 'auto',
}) => css`
  margin: -1px -1px 0;
  width: ${width};
  border-radius: ${borderRadius}px ${borderRadius}px 0 0;

  &:focus-within {
    z-index: 2;
    border-radius: 0;
  }

  ${border}
`;

const StyledTextInput = styled(TextInput)<BorderProps>`
  ${mainStyleForInputs}
`;

const StyledSelect = styled(Select)<BorderProps>`
  ${mainStyleForInputs}
`;

const StyledCombobox = styled(Combobox)(
  ({ theme: { borderRadius } }) => css`
    margin-top: -1px;
    margin-right: -1px;
    width: auto;
    border-radius: 0 ${borderRadius}px 0 0;

    &:focus-within {
      z-index: 2;
      border-radius: 0;
    }
  `,
);

const PlaceholderContainer = styled.div<
  FontSizeProps &
    ColorProps &
    SpaceProps &
    WidthProps &
    BorderProps &
    HeightProps
>(
  ({ theme: { space, fontSizes, colors } }) => css`
    display: flex;
    width: 100%;
    height: ${space[4]}px;
    align-items: center;
    padding-left: ${space[2]}px;
    font-size: ${fontSizes[2]}px;
    border-left: 1px solid ${colors.divider};
    color: ${colors.text.secondary};
    background: ${colors.highlight[0]};

    ${fontSize};
    ${color};
    ${styledSpace};
    ${styledWidth};
    ${border};
    ${height};
  `,
);

const ChildrenUnionWrapper = styled.div<
  { unionChildren?: boolean } & SpaceProps & HeightProps
>(
  ({ theme: { borderRadius }, unionChildren = true }) => css`
    display: block;
    width: 100%;

    ${unionChildren &&
    css`
      display: flex;
      width: initial;
      margin: -1px -1px -1px 0;

      & > * {
        margin-left: -1px;

        &:focus-within {
          z-index: 2;
          border-radius: 0;
        }

        &:first-child {
          margin-left: 0;
          border-radius: 0;
        }

        &:last-child {
          border-radius: 0 0 ${borderRadius}px 0;
        }
      }
    `}

    ${styledSpace};
    ${height};
  `,
);

const errorContainerHighlight = ({ theme: { colors }, error }): CSSProp => css`
  ${error &&
  css`
    border-color: ${colors.error.main};

    & ${StyledCombobox}:not(:focus-within) {
      border-right-color: ${colors.error.main};
      border-top-color: ${colors.error.main};
    }

    & [data-border-top]:not(:focus-within) {
      border-top-color: ${colors.error.main};
    }

    & [data-border-left]:not(:focus-within) {
      border-left-color: ${colors.error.main};
    }

    & [data-border-bottom]:not(:focus-within) {
      border-bottom-color: ${colors.error.main};
    }

    & [data-border-right]:not(:focus-within) {
      border-right-color: ${colors.error.main};
    }
  `};
`;

const SimpleEditContainer = styled.div`
  display: flex;

  ${errorContainerHighlight};
`;

const PropertyWrapper = styled.div<{
  error?: boolean;
  isDisabledBorder?: boolean;
}>(
  ({ theme: { colors, borderRadius }, isDisabledBorder }) => css`
    position: relative;
    flex-grow: 1;
    display: flex;
    min-width: 1px;
    margin-bottom: 1px;
    border: ${isDisabledBorder ? '0' : `1px solid ${colors.divider}`};
    border-radius: ${borderRadius}px;

    ${errorContainerHighlight};
  `,
);

const LeftAddon = styled.div<{ visible: boolean }>(
  ({ theme: { colors }, visible }) => css`
    height: 100%;
    width: 0;
    transition: width 200ms ease-in;

    ${visible &&
    css`
      width: 68px;
      border-left: 1px solid ${colors.divider};
    `}
  `,
);

const FieldContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  min-width: 1px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-width: 1px;
`;

const CancelIconButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    background: ${colors.background};
    border: 0;

    &:disabled {
      background: ${colors.background};
    }
  `,
);

const Controls = styled.div(
  ({ theme: { space, borderRadius } }) => css`
    display: flex;
    flex-direction: column;
    min-width: ${space[4]}px;
    width: ${space[4]}px;
    margin-left: ${space[1]}px;

    & button:first-child {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    & button:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    }

    & button:only-child {
      border-radius: ${borderRadius}px;
    }
  `,
);

interface ISimpleEditCharacteristic extends SpaceProps {
  hasControls?: boolean;
  highlightErrorContainer?: boolean;
  submitButton?: {
    disabled: boolean;
    title?: string | object;
    onSubmit: (event: MouseEvent<HTMLElement>) => void;
  };
  cancelButton?: {
    disabled?: boolean;
    title: string;
    onRemove?: () => void;
    onCancel?: () => void;
  };
  errorDescription?: string;
  children: ReactNode;
}

interface EditTemplateProps extends ISimpleEditCharacteristic {
  isDisabledBorder?: boolean;
  hasControls?: boolean;
  unionChildren?: boolean;
  header?: ReactNode;
  children: ReactNode;
  leftAddon?: ReactNode;
  middleAddon?: ReactNode;
}

const SimpleCharacteristicContainer = forwardRef<
  HTMLDivElement,
  ISimpleEditCharacteristic
>(
  (
    {
      hasControls = true,
      errorDescription,
      cancelButton,
      highlightErrorContainer,
      submitButton,
      children,
      ...other
    },
    ref,
  ): ReactElement => {
    return (
      <Container ref={ref} {...other}>
        <SimpleEditContainer error={highlightErrorContainer}>
          {children}
          {hasControls && (
            <Controls>
              {(cancelButton?.onCancel || cancelButton?.onRemove) && (
                <CancelIconButton
                  disabled={cancelButton.disabled}
                  title={cancelButton.title}
                  onClick={cancelButton.onRemove ?? cancelButton.onCancel}
                >
                  {cancelButton.onRemove ? <TrashIcon /> : <CloseMediumIcon />}
                </CancelIconButton>
              )}
              {submitButton?.onSubmit && (
                <IconButton
                  disabled={submitButton.disabled}
                  style={
                    cancelButton?.onCancel ? STYLE_CANCEL_NO_RADIUS : undefined
                  }
                  title={submitButton.title}
                  variant='darkHighlight'
                  onClick={submitButton.onSubmit}
                >
                  <DoneIcon />
                </IconButton>
              )}
            </Controls>
          )}
        </SimpleEditContainer>
        {errorDescription && <FormError show>{errorDescription}</FormError>}
      </Container>
    );
  },
);

const EditCharacteristicElement = forwardRef<HTMLDivElement, EditTemplateProps>(
  (
    {
      isDisabledBorder,
      hasControls,
      unionChildren = true,
      highlightErrorContainer,
      errorDescription,
      submitButton,
      cancelButton,
      header,
      children,
      leftAddon,
      middleAddon,
      ...other
    },
    ref,
  ): ReactElement => {
    return (
      <SimpleCharacteristicContainer
        ref={ref}
        cancelButton={cancelButton}
        errorDescription={errorDescription}
        hasControls={hasControls}
        highlightErrorContainer={highlightErrorContainer}
        submitButton={submitButton}
        {...other}
      >
        <PropertyWrapper
          error={highlightErrorContainer}
          isDisabledBorder={isDisabledBorder}
        >
          {leftAddon}
          <FieldContainer>
            <LeftAddon visible={!!middleAddon}>{middleAddon}</LeftAddon>
            <FieldWrapper>
              {header}
              <ChildrenUnionWrapper
                {...(!leftAddon && unionChildren && { ml: '-1px' })}
                unionChildren={unionChildren}
              >
                {children}
              </ChildrenUnionWrapper>
            </FieldWrapper>
          </FieldContainer>
        </PropertyWrapper>
      </SimpleCharacteristicContainer>
    );
  },
);

const EditTemplates = {
  SimpleCharacteristicContainer,
  Container: EditCharacteristicElement,
  InputContainer: StyledInputContainer,
  PlaceholderContainer,
  Select: StyledSelect,
  Combobox: StyledCombobox,
  TextInput: StyledTextInput,
};

export { EditTemplates, ChildrenUnionWrapper };
