import { Fragment, useCallback, memo, FC } from 'react';

import { Link, useHistory } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { findCompanies } from 'common/api/company-management';
import { SECONDARY_ITEMS_PER_PAGE } from 'common/constants/pagination.const';
import { INITIAL_DATA_REQUEST_V2 } from 'common/constants/request.const';
import { useQueryParams, useRequest } from 'common/hooks';
import { ICompany } from 'common/types/company.types';
import { CenteredPage } from 'layouts/Pages';
import { MatchesIcon } from 'resources/placeholders';
import { SEARCH } from 'routes';
import { Notice, Pagination, Placeholder, SearchInput, Spinner } from 'UI';

import { CompanyCard, SearchFilters } from './components';
import { getActivityAreasIds } from './utils';

const CardsContainer = styled.div(
  ({ theme: { space } }) => css`
    display: grid;
    grid-template-columns: repeat(3, minmax(32%, 1fr));
    gap: ${space[2]}px;

    @media (max-width: 950px) {
      grid-template-columns: repeat(2, minmax(392px, 1fr));
    }
  `,
);

interface ISearchParamsProps {
  search?: string;
  tags?: string[];
  productType?: string[];
  okved?: string[];
  partnersOnly?: string;
  location?: string[];
  activityDirection?: string;
  activityAreas?: string[];
  page?: number;
  itemsPerPage?: number;
}

const DictionariesCompanies: FC = memo(() => {
  const history = useHistory();

  const [queryParams, querySet] = useQueryParams({
    page: Number,
    itemsPerPage: Number,
  });

  const {
    search = '',
    tags,
    productType,
    okved,
    partnersOnly,
    location,
    activityDirection,
    activityAreas,
    page = 1,
    itemsPerPage = 30,
  }: ISearchParamsProps = queryParams;

  const {
    isLoading,
    data: { items, count } = INITIAL_DATA_REQUEST_V2,
  }: { isLoading: boolean; data: { items: ICompany[]; count: number } } =
    useRequest(findCompanies, [
      {
        partnersOnly: partnersOnly === 'true' ? true : undefined,
        registeredOnly: true,
        search,
        tags,
        productType,
        legalAccountOKVED: okved,
        location,
        activityAreas: getActivityAreasIds({
          activityDirection,
          activityAreas,
        }),
        page,
        itemsPerPage,
      },
    ]);

  const handleSearch = (value = ''): void => {
    history.replace(
      querySet(params => ({ ...params, search: value, page: 1 })),
    );
  };

  const handleSubmit = useCallback(
    filters => {
      history.replace(querySet(prev => ({ ...prev, ...filters, page: 1 })));
    },
    [history, querySet],
  );

  return (
    <CenteredPage
      bg='white'
      heading={{
        heading: 'Справочник компаний',
        backLink: history.goBack,
        controls: (
          <SearchInput
            isBordered
            delay={700}
            initialValue={search}
            mb='0px'
            placeholder='Название или ИНН'
            width='300px'
            onChange={handleSearch}
          />
        ),
      }}
      height={!isLoading && count > 0 ? 'max-content' : 'inherit'}
      maxWidth='1122px'
      minWidth='800px'
      subMenu={
        <SearchFilters
          activityAreas={activityAreas}
          activityDirection={activityDirection}
          location={location}
          okved={okved}
          partnersOnly={partnersOnly === 'true'}
          productType={productType}
          tags={tags}
          onSubmit={handleSubmit}
        />
      }
    >
      <Notice my={2} type={Notice.TYPES.DEFAULT}>
        Чтобы найти компанию, которая производит или может произвести товар по
        вашим требованиям, используйте{' '}
        <Link to={SEARCH.MANUFACTURER}>Поиск по производителям.</Link>
      </Notice>
      <Spinner delay={400} height='100%' loading={isLoading}>
        {count === 0 ? (
          <Placeholder
            hideInfo
            icon={MatchesIcon}
            text={
              <Fragment>
                Совпадений не найдено.
                <br />
                Измените запрос и попробуйте снова.
              </Fragment>
            }
          />
        ) : (
          <CardsContainer>
            {items.map(company => (
              <CompanyCard key={company.id} company={company} />
            ))}
          </CardsContainer>
        )}
        <Pagination
          itemsPerPageOptions={SECONDARY_ITEMS_PER_PAGE}
          mt={2}
          totalItems={count}
        />
      </Spinner>
    </CenteredPage>
  );
});

export { DictionariesCompanies };
