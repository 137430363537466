import styled, { css } from 'styled-components';

import { Status, Text } from 'UI';

export const CardWrapper = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
    border: 1px solid ${colors.divider};
    border-radius: 8px;
    background: ${colors.white};
  `,
);

export const BodyContainer = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    padding: ${space[2]}px ${space[3]}px;
  `,
);

export const StyledStatus = styled(Status)(
  ({ theme: { space } }) => css`
    position: absolute;
    top: ${space[1]}px;
    right: ${space[1]}px;
    cursor: pointer;
  `,
);

export const InfoItemTitle = styled(Text)(
  ({ theme: { space, colors } }) => css`
    margin-bottom: ${space[0]}px;
    font-size: 8px;
    font-weight: 500;
    color: ${colors.text.secondary};
    text-transform: uppercase;
  `,
);

export const FooterContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    justify-content: space-between;
    height: ${space[7]}px;
    max-height: ${space[7]}px;
    margin-top: auto;
    padding: 10px ${space[3]}px;
    background: ${colors.background};
    border-radius: 0 0 ${space[1]}px ${space[1]}px;
  `,
);

export const FooterItem = styled.div(
  ({ theme: { space, fontSizes } }) => css`
    align-items: center;
    display: flex;
    margin-bottom: ${space[1]}px;
    margin-right: ${space[1]}px;
    font-size: ${fontSizes[2]}px;
    white-space: nowrap;
  `,
);

export const FiltersForm = styled.form(
  ({ theme: { space } }) => css`
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: ${space[1]}px ${space[1]}px 0 ${space[1]}px;
  `,
);
