import { FC, memo } from 'react';

import { useHistory } from 'react-router-dom';
import styled, { css, keyframes } from 'styled-components';

import { ServicePanelIcon } from 'resources/other-12';
import { SearchGearIcon } from 'resources/other-14';
import { SEARCH } from 'routes';

const BUTTON_SIZE = 28;

const animationFwd = keyframes`
  0% { opacity: 0; right: -${BUTTON_SIZE}px; }
  100% { opacity: 1; right: 0; }
`;

const animationBwd = keyframes`
  0%, 70% { opacity: 1; }
  100% { opacity: 0; display: none; right: -${BUTTON_SIZE}px; }
`;

const Panel = styled.div<{ isVisible: boolean }>(
  ({ theme: { zIndex }, isVisible }) => css`
    position: fixed;
    top: 50%;
    display: none;
    flex-direction: column;
    gap: 1px;
    transform: translateY(-50%);
    animation: ${animationBwd} 1s forwards;
    z-index: ${zIndex.notification - 10};

    ${isVisible &&
    css`
      display: flex;
      animation: ${animationFwd} 1s forwards;
    `}
  `,
);

const PanelButton = styled.div(
  ({ theme: { colors, borderRadius } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${BUTTON_SIZE}px;
    height: ${BUTTON_SIZE}px;
    background: ${colors.black};
    opacity: 0.5;
    cursor: pointer;

    &:first-child {
      border-radius: ${borderRadius}px 0 0 0;
    }

    &:last-child {
      border-radius: 0 0 0 ${borderRadius}px;
    }
  `,
);

const QuickActions: FC<{ isVisible: boolean; onCollapse: VoidFunction }> = memo(
  ({ isVisible, onCollapse }) => {
    const history = useHistory();

    return (
      <Panel isVisible={isVisible}>
        <PanelButton onClick={() => history.push(SEARCH.FORM)}>
          <SearchGearIcon color='white' />
        </PanelButton>
        <PanelButton onClick={onCollapse}>
          <ServicePanelIcon color='white' />
        </PanelButton>
      </Panel>
    );
  },
);

export { QuickActions };
