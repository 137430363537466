import { FC, RefObject, useEffect, useState } from 'react';

import styled, { css } from 'styled-components';
import { color, ColorProps, space as styledSpace, SpaceProps } from 'styled-system';

import { StandardIcon } from 'resources/other-16';
import { IDocumentFile, IImage } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { checkIsStandardFile } from 'common/utils/standard';
import { get3DModelImage } from 'components/media/3d-model/utils';
import { Model3dIcon } from 'resources/icons/18';
import { Skeleton } from 'UI';

const ImageListItem = styled(Skeleton)<SpaceProps | ColorProps>(
  ({ theme: { space: spaceProps, colors, borderRadius }, size, active }) => css`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${spaceProps[size]}px;
    min-width: ${spaceProps[size]}px;
    height: ${spaceProps[size]}px;
    min-height: ${spaceProps[size]}px;
    border-radius: ${borderRadius}px;
    border: 1px solid transparent;
    transition: border-color 200ms ease-in-out;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }

    &:hover {
      border: 1px solid ${colors.text.secondary};
    }

    ${active &&
    css`
      border-color: ${colors.text.primary};
    `}

    ${color}
    ${styledSpace};
  `,
);

const PreviewImage = styled.img<{ isLoaded: boolean }>`
  opacity: ${({ isLoaded }) => (isLoaded ? 1 : 0)};
  transition: opacity 200ms ease-in;
  max-height: 100%;
`;

const StyledStandardIcon = styled(StandardIcon)(
  ({ theme: { space } }) => css`
    position: absolute;
    top: ${space[0]}px;
    right: ${space[0]}px;
  `,
);

const StyledModel3DIcon = styled(Model3dIcon)(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 2px;
    right: 2px;
    background-color: ${colors.primary.main};
    border-radius: 3px;

    & svg {
      color: ${colors.white};
    }
  `,
);

interface IImageItemProps {
  img: IImage | IDocumentFile;
  active: boolean;
  onSelectedImage: VoidFunction;
  innerRef?: RefObject<HTMLDivElement>;
  size?: number;
  is3DModel?: boolean;
}

const ImageItem: FC<IImageItemProps & SpaceProps & ColorProps> = ({
  img,
  active,
  is3DModel,
  size,
  onSelectedImage,
  innerRef,
  ...other
}) => {
  const [isLoaded, setLoaded] = useState<boolean>(false);
  const [imageSrc, setImageSrc] = useState<string>(
    getImageURL(img, { size: 'l' }) ?? '',
  );

  useEffect(() => {
    if (is3DModel) {
      setLoaded(false);

      const getImageData = async (): Promise<void> => {
        const { src } = await get3DModelImage(img);
        setImageSrc(src);
      };

      getImageData().then(() => setLoaded(true));
    }
  }, [img, is3DModel]);

  return (
    <ImageListItem
      ref={innerRef}
      active={active}
      isLoading={!isLoaded}
      size={size}
      onClick={onSelectedImage}
      {...other}
    >
      {checkIsStandardFile(img) && <StyledStandardIcon />}
      {is3DModel && <StyledModel3DIcon />}
      <PreviewImage
        alt={img.description ?? img.filename}
        isLoaded={isLoaded}
        src={imageSrc}
        onLoad={() => setLoaded(true)}
      />
    </ImageListItem>
  );
};

export { ImageItem };
