import { Fragment, memo, ReactElement, ReactNode, RefObject, useEffect, useState } from 'react';

import ReactDOM from 'react-dom';
import ReactMarkdown from 'react-markdown';
import styled, { css, useTheme } from 'styled-components';
import { color, ColorProps } from 'styled-system';

import { handleScrollTo } from 'common/hooks';
import { GUIDES_BASE_URL } from 'common/utils/request';
import { TimeIcon } from 'resources/icons/18';
import { ArrowLeft1pxIcon, ArrowRight1pxIcon } from 'resources/icons/1px-12';
import { EndIcon, StartIcon } from 'resources/onboarding';
import { ZoomThinIcon } from 'resources/other-25';
import { CloseMediumIcon } from 'resources/other-28';
import { useToggle } from 'tools/hooks';
import { Button, Heading, IconButton, Spinner, Text } from 'UI';
import { StyledIconButton } from 'UI/Modal/templates/styled';

import { EDUCATION_Z_INDEX } from './_shared/styled';
import { ONBOARDING, ONBOARDING_END, ONBOARDING_START } from './constants';
import { EducationTypeEnum, IEducationPoint } from './types';
import { alwaysStyledForContainer, Content, HintContainer } from '../right-product-panel';

const MIME_TYPE = {
  MP4: 'video/mp4',
  GIF: 'image/gif',
};

const CONTAINER_STYLE = { flex: '0 0 400px' };
const TRANSLATE_X0 = {
  transform: 'translateX(0)',
};

const Overlay = styled.div(
  ({ theme: { colors, zIndex } }) =>
    css`
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: ${zIndex.modal + 1};
      top: 0;
      left: 0;
      background: ${colors.highlight[0]};
    `,
);

const OnboardingContainer = styled.div<
  { right?: boolean; left?: boolean; zIndex: number | string } & ColorProps
>(
  ({ theme: { colors }, left, right }) => css`
    position: absolute;
    top: 0;
    border-left: 1px solid ${colors.divider};
    border-right: 1px solid ${colors.divider};
    transition: transform 200ms ease-in-out;

    ${left &&
    css`
      left: 0;
      transform: translateX(-400px);
    `}

    ${right &&
    css`
      right: 0;
      transform: translateX(400px);
    `}

    & a {
      color: ${colors.white};
    }

    ${alwaysStyledForContainer};
    ${color};
  `,
);

const TimeAboutWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    margin-top: ${space[2]}px;
    margin-bottom: ${space[3]}px;
  `,
);

const MediaIconWrapper = styled.div`
  position: absolute;
  top: 45%;
  left: 45%;
  display: none;
  transform: translate(-50%, -50%);
`;

const MediaWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: relative;
    height: auto;
    margin-top: auto;
    margin-bottom: 28px;
    cursor: pointer;
    width: 282px;

    &:hover {
      ${MediaIconWrapper} {
        display: block;
      }

      &::before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: ${colors.blackout};
        opacity: 0.8;
      }
    }
  `,
);

const MediaWindow = styled.div<{
  right?: boolean;
  left?: boolean;
  zIndex?: number | string;
}>(
  ({ theme: { colors }, left, right, zIndex }) =>
    css`
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      background: ${colors.white};
      border-left: 1px solid ${colors.divider};
      border-right: 1px solid ${colors.divider};
      transform: translateX(100%);
      transition: transform 200ms ease-in-out;

      ${zIndex &&
      css`
        z-index: ${zIndex};
      `}

      ${left &&
      css`
        left: 400px;
        transform: translateX(calc(-100% - 400px));
      `}

      ${right &&
      css`
        right: 400px;
        transform: translateX(calc(100% + 400px));
      `}
    `,
);

const CloseMediaButton = styled(IconButton)(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    right: 0;
    background: ${colors.white};
    border: 1px solid ${colors.divider};
    border-top: none;
  `,
);

const ButtonContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    height: 82px;
    padding: ${space[2]}px ${space[4]}px;
    border-top: 1px solid ${colors.divider};
  `,
);

interface IEducationOnboardingSidePanelProps {
  point: IEducationPoint | null;
  isLoading?: boolean;
  indexPoint: number;
  stepCount: number;
  onComplete: () => void;
  setTransformPoint: (index) => void;
}

interface IEducationHintSidePanelProps {
  point: IEducationPoint | null;
  childrenRef: RefObject<HTMLDivElement>;
  setTransformPoint: (index) => void;
}

interface ISidePanelProps {
  title?: string;
  step?: ReactNode;
  description?: string;
  media: any;
  mediaOpen: boolean;
  toggleMediaOpen: (boolean) => void;
  onClose?: () => void;
}

interface IMedia {
  media: any;
  controls?: boolean;
  loop?: boolean;
  width?: string;
}

const Media = memo(({ media, controls, loop, width }: IMedia) => {
  if (!media) return null;

  const MediaComponent = media?.mime === MIME_TYPE.MP4 ? 'video' : 'img';

  return (
    <MediaComponent
      autoPlay
      alt={media.name}
      controls={controls}
      height='auto'
      loop={loop}
      src={`${GUIDES_BASE_URL}${media.url}`}
      style={{ maxWidth: '100%' }}
      width={width}
    />
  );
});

function SidePanel({
  title,
  step,
  description,
  media,
  mediaOpen,
  toggleMediaOpen,
  onClose,
}: ISidePanelProps): ReactElement {
  return (
    <Fragment>
      {onClose && (
        <StyledIconButton zeroBorderTopRight onClick={onClose}>
          <CloseMediumIcon />
        </StyledIconButton>
      )}
      <Content pt={7} px={6}>
        {step}
        <Heading as='h2' mb={3}>
          {title}
        </Heading>
        {description && (
          <ReactMarkdown linkTarget='_blank'>{description}</ReactMarkdown>
        )}
        {media && (
          <MediaWrapper
            style={{ ...(mediaOpen && { display: 'none' }) }}
            onClick={toggleMediaOpen}
          >
            <Media media={media} />
            <MediaIconWrapper>
              <ZoomThinIcon color='white' size={24} />
            </MediaIconWrapper>
          </MediaWrapper>
        )}
      </Content>
    </Fragment>
  );
}

function EducationHintSidePanel({
  childrenRef,
  point,
  setTransformPoint,
}: IEducationHintSidePanelProps): ReactElement {
  const theme = useTheme();

  const [mediaOpen, toggleMediaOpen] = useToggle();
  const { title, description, media } = point ?? {};

  const onClosePanel = (): void => {
    toggleMediaOpen(false);
    setTransformPoint(null);
  };

  return (
    <HintContainer
      style={{ ...(point && CONTAINER_STYLE) }}
      zIndex={EDUCATION_Z_INDEX.SIDE_PANEL[EducationTypeEnum.hints]}
    >
      <SidePanel
        description={description}
        media={media}
        mediaOpen={mediaOpen}
        title={title}
        toggleMediaOpen={toggleMediaOpen}
        onClose={onClosePanel}
      />
      {ReactDOM.createPortal(
        <MediaWindow
          style={{ ...(mediaOpen && TRANSLATE_X0) }}
          zIndex={
            mediaOpen
              ? theme.zIndex.sidebar
              : EDUCATION_Z_INDEX.MEDIA_WINDOW[EducationTypeEnum.hints]
          }
        >
          <CloseMediaButton size={70} onClick={toggleMediaOpen}>
            <ArrowRight1pxIcon size={18} />
          </CloseMediaButton>
          <Media controls loop media={media} width='1500px' />
        </MediaWindow>,
        childrenRef.current!,
      )}
    </HintContainer>
  );
}

const PANEL_POSITION_TYPE = {
  RIGHT: 'right',
  LEFT: 'left',
};

function MainMedia({ media, panelPosition, toggleMediaOpen }): ReactElement {
  return (
    <Fragment>
      <CloseMediaButton
        size={70}
        style={{
          ...(panelPosition === PANEL_POSITION_TYPE.RIGHT
            ? { right: '0', borderRight: 'none' }
            : { left: '0', borderLeft: 'none' }),
        }}
        onClick={toggleMediaOpen}
      >
        {panelPosition === PANEL_POSITION_TYPE.RIGHT ? (
          <ArrowRight1pxIcon size={18} />
        ) : (
          <ArrowLeft1pxIcon size={18} />
        )}
      </CloseMediaButton>
      <Media controls loop media={media} width='1500px' />
    </Fragment>
  );
}

function EducationOnboardingSidePanel({
  isLoading,
  indexPoint,
  point,
  stepCount,
  onComplete,
  setTransformPoint,
}: IEducationOnboardingSidePanelProps): ReactElement {
  const [mediaOpen, toggleMediaOpen] = useToggle();
  const [isMount, setMount] = useState(false);
  const {
    title = '',
    description = '',
    media,
    section,
    panelPosition = 'right',
  } = point ?? {};

  useEffect(() => {
    setTransformPoint(0);
  }, [setTransformPoint]);

  useEffect(() => {
    setMount(true);
  }, []);

  useEffect(() => {
    handleScrollTo(`${ONBOARDING}-${section}`, 'center');

    toggleMediaOpen(false);
  }, [section, toggleMediaOpen]);

  const handleNextPoint = (): void => {
    setTransformPoint(prev => prev! + 1);
  };

  const handlePrevPoint = (): void => {
    setTransformPoint(prev => prev! - 1);
  };

  let content = (
    <Fragment>
      <SidePanel
        description={description}
        media={media}
        mediaOpen={mediaOpen}
        step={
          <Text color='text.secondary' fontSize={3} mb={1}>
            Шаг {indexPoint} из {stepCount}
          </Text>
        }
        title={title}
        toggleMediaOpen={toggleMediaOpen}
        onClose={onComplete}
      />
      <ButtonContainer>
        {indexPoint !== 1 && (
          <Button
            color='secondary'
            // Атрибут для гугл аналитики
            data-type={`onboardingBtnBack-step${indexPoint}`}
            size='l'
            variant='text'
            onClick={handlePrevPoint}
          >
            ← Назад
          </Button>
        )}
        <Button
          // Атрибут для гугл аналитики
          data-type={`onboardingBtnNext-step${indexPoint}`}
          ml='auto'
          size='l'
          variant='outlined'
          onClick={handleNextPoint}
        >
          Далее →
        </Button>
      </ButtonContainer>
    </Fragment>
  );

  if (section === ONBOARDING_START || isLoading) {
    content = (
      <Fragment>
        <StyledIconButton zeroBorderTopRight onClick={onComplete}>
          <CloseMediumIcon />
        </StyledIconButton>
        <Content pt={7} px={6}>
          <Spinner color='white' height={400} loading={isLoading} width='100%'>
            <Heading as='h2' color='white' mb={3}>
              {title}
            </Heading>
            <ReactMarkdown linkTarget='_blank'>{description}</ReactMarkdown>
            <TimeAboutWrapper>
              <TimeIcon color='primary.disabled' mr={1} />
              <Text color='primary.disabled' fontSize={3}>
                Время прохождения: ~5 минут
              </Text>
            </TimeAboutWrapper>
            <Button
              color='info'
              // Атрибут для гугл аналитики
              data-type='onboardingBtnStart'
              size='l'
              variant='outlined'
              onClick={handleNextPoint}
            >
              Пройти обучение
            </Button>
          </Spinner>
          <StartIcon mb={82} mt='auto' mx={-8} style={{ maxHeight: 336 }} />
        </Content>
      </Fragment>
    );
  }

  if (section === ONBOARDING_END) {
    content = (
      <Fragment>
        <StyledIconButton zeroBorderTopRight onClick={onComplete}>
          <CloseMediumIcon />
        </StyledIconButton>
        <Content pt={7} px={6}>
          <Heading as='h2' color='white' mb={3}>
            {title}
          </Heading>
          <ReactMarkdown linkTarget='_blank'>{description}</ReactMarkdown>
          <EndIcon mt='70px' style={{ minHeight: 368 }} />
        </Content>
        <ButtonContainer style={{ border: 'none' }}>
          <Button
            color='info'
            // Атрибут для гугл аналитики
            data-type={`onboardingBtnBack-step${indexPoint}`}
            size='l'
            variant='text'
            onClick={handlePrevPoint}
          >
            ← Назад
          </Button>
          <Button
            color='info'
            // Атрибут для гугл аналитики
            data-type='onboardingBtnEnd'
            ml='auto'
            size='l'
            variant='outlined'
            onClick={onComplete}
          >
            Завершить
          </Button>
        </ButtonContainer>
      </Fragment>
    );
  }

  const isFirstOrEndPoint =
    isLoading || section === ONBOARDING_START || section === ONBOARDING_END;

  return (
    <Fragment>
      <Overlay />
      {panelPosition === PANEL_POSITION_TYPE.LEFT && (
        <>
          {mediaOpen && (
            <MediaWindow
              left
              style={{
                ...TRANSLATE_X0,
              }}
              zIndex={
                EDUCATION_Z_INDEX.MEDIA_WINDOW[EducationTypeEnum.onboarding]
              }
            >
              <MainMedia
                media={media}
                panelPosition={panelPosition}
                toggleMediaOpen={toggleMediaOpen}
              />
            </MediaWindow>
          )}
          <OnboardingContainer
            left
            style={{
              ...TRANSLATE_X0,
            }}
            zIndex={EDUCATION_Z_INDEX.SIDE_PANEL[EducationTypeEnum.onboarding]}
          >
            {content}
          </OnboardingContainer>
        </>
      )}
      {panelPosition === PANEL_POSITION_TYPE.RIGHT && (
        <>
          {mediaOpen && (
            <MediaWindow
              right
              style={{
                ...TRANSLATE_X0,
              }}
              zIndex={
                EDUCATION_Z_INDEX.MEDIA_WINDOW[EducationTypeEnum.onboarding]
              }
            >
              <MainMedia
                media={media}
                panelPosition={panelPosition}
                toggleMediaOpen={toggleMediaOpen}
              />
            </MediaWindow>
          )}
          <OnboardingContainer
            right
            style={{
              ...(isMount && TRANSLATE_X0),
            }}
            zIndex={EDUCATION_Z_INDEX.SIDE_PANEL[EducationTypeEnum.onboarding]}
            {...(isFirstOrEndPoint && { bg: 'primary.main', color: 'white' })}
          >
            {content}
          </OnboardingContainer>
        </>
      )}
    </Fragment>
  );
}

export { EducationHintSidePanel, EducationOnboardingSidePanel };
