import styled, { css, keyframes } from 'styled-components';

const pulsePrimary = keyframes`
  0%{
    box-shadow: 0 0 0 0 rgba(184, 212, 255,0.8), 0 0 0 0 rgba(77, 124, 254,0.5)
  }
  40%{
    box-shadow: 0 0 0 25px rgba(184, 212, 255,0.1), 0 0 0 10px rgba(77, 124, 254,0.1)
  }
  60%{
    box-shadow: 0 0 0 0 rgba(184, 212, 255,0), 0 0 0 0 rgba(77, 124, 254,0)
  }
  80%{
      box-shadow: 0 0 0 25px rgba(184, 212, 255,0), 0 0 0 10px rgba(77, 124, 254,0)
  }
  100%{
      box-shadow: 0 0 0 0 rgba(184, 212, 255,0), 0 0 0 15px rgba(77, 124, 254,0)
  }
`;

const ButtonFloating = styled.button<{ pulse?: boolean }>(
  ({ theme: { space, colors }, pulse }) => css`
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: ${space[5]}px;
    height: ${space[5]}px;
    border-radius: 50%;
    background: ${colors.primary.main};
    transition: 0.2s ease-in-out background;

    ${pulse &&
    css`
      animation: ${pulsePrimary} 2s infinite;
    `}

    & svg {
      color: ${colors.white};
    }

    &:hover {
      background: ${colors.primary.dark};
    }

    &:disabled {
      background: ${colors.divider};
      animation: none;
      box-shadow: 0 0 0 transparent;
      cursor: default;

      & svg {
        color: ${colors.text.disabled};
      }

      &:hover {
        background: ${colors.divider};
      }
    }
  `,
);

export { ButtonFloating, pulsePrimary };
