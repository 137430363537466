import { ReactElement, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  height as styledHeight,
  HeightProps,
  space as styledSpace,
  SpaceProps,
} from 'styled-system';

import { createSlot } from 'common/utils';
import { CloseMediumIcon } from 'resources/icons/18';
import { Modal } from 'UI';
import { StyledIconButton } from 'UI/Modal/templates/styled';

const Container = styled.div<HeightProps>`
  display: flex;
  height: 640px;

  ${styledHeight};
`;

const ContentWrapper = styled.div<SpaceProps>`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-y: auto;
  overflow-x: hidden;

  ${styledSpace};
`;

const SidebarContainer = styled.div(
  ({ theme: { space, colors } }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 287px;
    width: 287px;
    padding: ${space[5]}px ${space[4]}px ${space[4]}px;
    background: ${colors.background};
    border-radius: 0 ${space[1]}px ${space[1]}px 0;
  `,
);

const Sidebar = createSlot();

interface ModalWithSidebarProps {
  width?: number;
  children: ReactNode;
  onRequestClose?: () => void;
}

function ModalWithSidebar({
  height,
  width,
  children,
  onRequestClose,
  ...other
}: ModalWithSidebarProps & HeightProps & SpaceProps): ReactElement {
  return (
    <Modal hasSubmitOnEnter={false} width={width}>
      <Container height={height}>
        <ContentWrapper {...other}>{children}</ContentWrapper>
        <SidebarContainer>
          {onRequestClose && (
            <StyledIconButton onClick={onRequestClose}>
              <CloseMediumIcon />
            </StyledIconButton>
          )}
          {/* @ts-ignore */}
          <Sidebar.Renderer childs={children} />
        </SidebarContainer>
      </Container>
    </Modal>
  );
}

ModalWithSidebar.Sidebar = Sidebar;

export { ModalWithSidebar };
