import styled, { css, keyframes } from 'styled-components';
import {
  space as styledSpace,
  layout,
  SpaceProps,
  LayoutProps,
} from 'styled-system';

const shimmer = keyframes`
  100% {
    transform: translateX(100%);
  }
`;

const SkeletonContainer = styled.div<
  { frame?: boolean; isLoading?: boolean } & SpaceProps & LayoutProps
>(
  ({ theme: { colors }, frame, isLoading = true }) => css`
    position: relative;
    overflow: hidden;
    border-radius: 8px;
    background: transparent;
    transition: background-color 200ms ease-in;

    ${frame &&
    css`
      border: 1px solid ${colors.divider};
      box-sizing: content-box;
    `}

    ${isLoading &&
    css`
      background: ${colors.background};
    `}

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background: linear-gradient(
        90deg,
        ${colors.background} 10%,
        ${colors.highlight[2]} 50%,
        ${colors.background} 80%
      );

      ${isLoading &&
      css`
        animation: ${shimmer} 3000ms infinite;
      `}
    }

    &:last-child {
      margin-bottom: 0;
    }

    ${styledSpace}
    ${layout}
  `,
);

const SkeletonText = styled(SkeletonContainer)<SpaceProps & LayoutProps>(
  ({ theme: { space, borderRadius } }) => css`
    height: 18px;
    width: 100%;
    border-radius: ${borderRadius}px;
    margin-bottom: ${space[1]}px;

    ${styledSpace}
    ${layout}
  `,
);

const SkeletonBadge = styled(SkeletonContainer)`
  width: 120px;
  height: 24px;
  border-radius: 12px;
`;

const Skeleton = Object.assign(SkeletonContainer, {
  Text: SkeletonText,
  Badge: SkeletonBadge,
});

export { Skeleton };
