import { useCallback, useMemo } from 'react';

import styled, { css } from 'styled-components';
import { v4 as uuid } from 'uuid';

import { CopyIcon } from 'resources/icons/18';
import { IForbidden } from 'common/types/permissions.types';
import { IPackageVariation } from 'common/types/product.types/accompany-information.types';
import { BUTTONS_ACTION, useNotify } from 'entities/notify';
import { useField } from 'reform';
import { CloseMediumIcon } from 'resources/icons/18';
import { removeItem, replaceItem, insertItem, moveItem } from 'tools/utils';
import { IconButtonWrapper, Text, AutoSizeInput } from 'UI';

import { PackagesList } from './PackagesList';

const Container = styled.div(
  ({ theme: { space } }) => css`
    position: relative;
    margin-bottom: ${space[2]}px;
    padding-top: ${space[0]}px;
  `,
);

const Controls = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    border: 1px solid ${colors.divider};
    opacity: 0;
    transition: 0.2s ease-in-out opacity;

    ${Container}:hover & {
      opacity: 1;
    }
  `,
);

const AutoSizeWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const Control = styled(IconButtonWrapper).attrs(() => ({
  size: 22,
  bg: 'white',
}))(
  ({ theme: { colors } }) => css`
    ${Controls} > & {
      border-right: 1px solid ${colors.divider};

      &:last-child {
        border-right: none;
      }
    }
  `,
);

const PACKAGES_COUNT_LIMIT = 5;

interface IVariation {
  isSwapMode: boolean;
  id: string;
  index: number;
  name: string;
  focus: boolean;
  onCopy: (variation: IPackageVariation, index: number) => void;
  onRemove: (index: number) => void;
  getVariationInitialValue: (id: string) => IPackageVariation | undefined;
}

function Variation({
  isSwapMode,
  id,
  index,
  name,
  focus,
  forbidden,
  onCopy,
  onRemove,
  getVariationInitialValue,
}: IVariation & IForbidden): JSX.Element {
  const notify = useNotify();
  const {
    ref: fieldRef,
    error,
    value: variation,
    setValue: setVariation,
    setError,
  } = useField<IPackageVariation>({
    name,
    key: id,
  });

  const initialValue = useMemo(
    () => getVariationInitialValue(variation.id),
    [getVariationInitialValue, variation.id],
  );

  const forbiddenRemove =
    (forbidden.removeInitialValues && !!initialValue) || forbidden.remove;

  const forbiddenEdit =
    (forbidden.editInitialValues && !!initialValue) || forbidden.edit;

  const insertPackages = useCallback(
    (item, insertIndex) => {
      setVariation(({ packages, ...packageVariation }) => ({
        ...packageVariation,
        packages: insertItem(packages, item, insertIndex),
      }));
    },
    [setVariation],
  );

  const handleAddPackage = useCallback(
    (productPackage, insertIndex) => {
      insertPackages({ ...productPackage, id: uuid() }, insertIndex);
    },
    [insertPackages],
  );

  const handleRemovePackage = useCallback(
    packageIndex => {
      const removedDataPackage = variation.packages[packageIndex];

      setVariation(({ packages, ...packageVariation }) => ({
        ...packageVariation,
        packages: removeItem(packages, packageIndex),
      }));

      setError(prevError => {
        const reverseIndex = variation.packages?.length - 1 - packageIndex;
        const newError = [...prevError?.packages]?.filter((_, errorIndex) => {
          return errorIndex !== reverseIndex;
        });
        return {
          ...prevError,
          packages: newError?.some(Boolean) ? newError : undefined,
        };
      });

      notify.success(
        [
          'Упаковка ',
          <strong key='removed variation'>
            {removedDataPackage.packageType.name}
          </strong>,
          ' удалена.',
        ],
        {
          as: BUTTONS_ACTION.BUTTON,
          title: 'Восстановить',
          onClick: () => {
            insertPackages(removedDataPackage, packageIndex);
          },
        },
      );
    },
    [insertPackages, notify, setError, setVariation, variation.packages],
  );

  const handleEditPackage = useCallback(
    (productPackage, packageIndex) => {
      setVariation(({ packages, ...packageVariation }) => ({
        ...packageVariation,
        packages: replaceItem(packages, productPackage, packageIndex),
      }));

      setError(prevError => {
        const reverseIndex = variation.packages?.length - 1 - packageIndex;
        const newError = prevError?.packages
          ? [...prevError?.packages]
          : undefined;

        if (newError) {
          newError[reverseIndex] = undefined;
        }

        return {
          ...prevError,
          packages: newError?.some(Boolean) ? newError : undefined,
        };
      });
    },
    [setError, setVariation, variation],
  );

  const handleSwapPackages = useCallback(
    packageIndex => {
      setVariation(({ packages, ...packageVariation }) => ({
        ...packageVariation,
        packages: moveItem(packages, packageIndex, packageIndex - 1),
      }));
    },
    [setVariation],
  );

  const handleChange = ({ target }): void => {
    setVariation(prev => ({ ...prev, name: target.value }));
    setError(prevError => ({ ...prevError, name: undefined }));
  };

  return (
    <Container ref={fieldRef}>
      <AutoSizeWrapper>
        <AutoSizeInput
          error={error?.name}
          focus={focus}
          maxWidth={300}
          readOnly={forbiddenEdit}
          title={error?.name}
          value={variation.name}
          onChange={handleChange}
        />
        <Text as='span' color='text.disabled' fontSize={1} ml={1}>
          {`${variation.packages.length}/${PACKAGES_COUNT_LIMIT}`}
        </Text>
      </AutoSizeWrapper>
      <PackagesList
        error={error}
        forbidden={forbidden}
        initialVariation={initialValue}
        isSwapMode={isSwapMode}
        variation={variation}
        onAddPackage={handleAddPackage}
        onEditPackage={handleEditPackage}
        onRemovePackage={handleRemovePackage}
        onSwapPackages={handleSwapPackages}
      />
      <Controls>
        {!forbidden.insert && (
          <Control title='Дублировать' onClick={() => onCopy(variation, index)}>
            <CopyIcon />
          </Control>
        )}
        {!forbiddenRemove && (
          <Control title='Удалить' onClick={() => onRemove(index)}>
            <CloseMediumIcon />
          </Control>
        )}
      </Controls>
    </Container>
  );
}

export { Variation };
