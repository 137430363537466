import { forwardRef } from 'react';

import styled, { css } from 'styled-components';

import { preparedForbidden } from 'common/utils';
import { useField } from 'reform';
import { useForkRef } from 'tools/hooks';
import { Button, Panel, Text } from 'UI';

import { Editor } from './components';
import { IMediaComponentSection } from '../../types';

import './components/editor.css';

const EditorWrapper = styled.div<{ error: boolean }>(
  ({ theme: { space, colors }, error }) => css`
    position: relative;
    margin-top: ${space[1]}px;

    & > .jodit-container {
      ${error && `1px solid ${colors.error.main} !important`}
    }
  `,
);

const DescriptionForm = forwardRef<HTMLDivElement, IMediaComponentSection>(
  ({ id, title, forbidden = {}, name, onRemoveSection }, ref) => {
    const {
      initialValue,
      value,
      setValue,
      ref: fieldRef,
      error,
    } = useField({ name });

    const unionRef = useForkRef(ref, fieldRef);

    const simpleForbidden = preparedForbidden(forbidden, initialValue);

    return (
      <Panel
        ref={unionRef}
        collapse
        id={id}
        rightActions={
          onRemoveSection &&
          !simpleForbidden.remove && (
            <Button
              color='secondary'
              ml={1}
              variant='text'
              onClick={onRemoveSection}
            >
              Удалить
            </Button>
          )
        }
        title={title}
      >
        <EditorWrapper error={error}>
          <Editor
            readonly={simpleForbidden.edit}
            value={value}
            onChange={setValue}
          />
          <Text color='error.main' fontSize={1}>
            {error}
          </Text>
        </EditorWrapper>
      </Panel>
    );
  },
);

export { DescriptionForm };
