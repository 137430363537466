import { Fragment, FC, CSSProperties, ElementType } from 'react';

import styled, { css } from 'styled-components';
import { FontSizeProps, HeightProps } from 'styled-system';

import { Nullable } from 'common/types/common.types';
import { getFormattedNumber } from 'common/utils';
import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';
import { STUB_FUNC } from 'tools/utils';

import { Tag, StyledTag, ITagProps } from './Tag';
import { Tooltip } from '../Tooltip';

const Container = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[0]}px;
    flex-wrap: wrap;
    width: 100%;
    padding-right: ${space[1]}px;
  `,
);

interface ITagsProps {
  style?: CSSProperties;
  as?: ElementType;
  arrow?: boolean;
  expandable?: boolean;
  limit?: number;
  size?: 's' | 'l' | 'm';
  tags?: any[];
  itemToIcon?: JSX.Element | string;
  itemToString?: (props: any) => Nullable<string | JSX.Element>;
  itemToTooltip?: (props?: any) => string | null;
  getProps?: (props: any, index?: number) => any;
  onRemove?: ITagProps['onRemove'];
  fontSize?: FontSizeProps['fontSize'];
  height?: HeightProps['height'];
  onClick?: ITagProps['onClick'];
}

const Tags: FC<ITagsProps> = ({
  style,
  as,
  arrow = false,
  expandable,
  limit = Infinity,
  size,
  height,
  fontSize,
  tags = [],
  itemToIcon,
  itemToString = STUB_FUNC.ITEM,
  itemToTooltip = STUB_FUNC.NULL,
  getProps = STUB_FUNC.NOOP,
  onRemove,
  onClick,
}: ITagsProps) => {
  const visibleTags = limit ? tags.slice(0, limit) : tags;
  const hiddenTags = tags.slice(limit);
  const [isExpanded, toggleExpanded] = useToggle();
  const tagSize = arrow ? 'm' : size ?? 's';

  const tagProps = {
    as,
    arrow,
    size: tagSize,
    fontSize,
    itemToIcon,
    height,
    onRemove,
  };

  return (
    <Container style={style}>
      {visibleTags.map((tag, index) => (
        <Tooltip
          key={index}
          arrow
          hint
          maxWidth={240}
          title={itemToTooltip(tag)}
        >
          <Tag
            value={getFormattedNumber(itemToString(tag))}
            onClick={onClick}
            {...tagProps}
            {...getProps(tag, index)}
          />
        </Tooltip>
      ))}
      {tags.length > limit && !isExpanded && (
        <Fragment>
          {expandable ? (
            <StyledTag
              aria-label='expand'
              colorTheme='info'
              px={0}
              size={tagSize}
              variant='outlined'
              onClick={toggleExpanded}
            >
              +{hiddenTags.length}
              <ArrowDown1pxIcon ml={0} />
            </StyledTag>
          ) : (
            <Tooltip arrow placement='bottom' title={hiddenTags.join(', ')}>
              <StyledTag
                colorTheme='info'
                px={0}
                size={tagSize}
                variant='outlined'
              >
                +{hiddenTags.length}
              </StyledTag>
            </Tooltip>
          )}
        </Fragment>
      )}
      {isExpanded && (
        <Fragment>
          {hiddenTags.map((tag, index) => (
            <Tooltip
              key={index}
              arrow
              hint
              maxWidth={240}
              title={itemToTooltip(tag)}
            >
              <Tag
                value={getFormattedNumber(itemToString(tag))}
                {...tagProps}
                {...getProps(tag)}
              />
            </Tooltip>
          ))}
          <StyledTag
            aria-label='hide'
            colorTheme='info'
            px={0}
            size={tagSize}
            variant='outlined'
            onClick={toggleExpanded}
          >
            <ArrowUp1pxIcon />
          </StyledTag>
        </Fragment>
      )}
    </Container>
  );
};

export { Tags };
