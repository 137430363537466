import styled, { css } from 'styled-components';

import { Container } from 'UI';

export const CardContainer = styled(Container)(
  ({ theme: { colors, space } }) => css`
    flex-direction: column;
    width: 100%;
    background: ${colors.white};
    height: ${space[6]}px;
    border-bottom: 1px solid ${colors.divider};
    transition: opacity 200ms ease-in-out, bottom 200ms ease-in-out;

    &:last-child {
      border-bottom: none;
    }
  `,
);

export const BodyWrapper = styled(Container)(
  ({ theme: { space, fontSizes } }) => css`
    align-items: center;
    height: 100%;
    width: 100%;
    padding: 0 ${space[2]}px;
    font-size: ${fontSizes[3]}px;
  `,
);
