import { useEffect, forwardRef } from 'react';

import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { space as styledSpace, typography } from 'styled-system';

import { ArrowDown1pxIcon, ArrowUp1pxIcon } from 'resources/icons/1px-12';
import { useToggle } from 'tools/hooks';

import { ButtonsWrapper, Item, ItemControl } from './styled';
import { IconButton } from '../IconButton';
import { Text } from '../Text';

const Container = styled.div`
  position: relative;

  ${styledSpace};
  ${typography};
`;

const ChildWrapper = styled.div(
  ({ theme: { space } }) => css`
    padding: ${space[1]}px ${space[2]}px 20px ${space[2]}px;
  `,
);

const SideMenuGroup = forwardRef(
  (
    {
      style,
      className,
      children,
      focused,
      replace,
      initialOpen = true,
      forceOpen = false,
      isFixedGroup = false,
      primary,
      secondary = true,
      active,
      highlighted,
      title,
      rightAction,
      indent,
      as = 'div',
      level,
      to,
      onClick,
      fontSize,
      fontWeight,
      ...other
    },
    ref,
  ) => {
    const [open, handleToggle] = useToggle(initialOpen);
    const isControl = as !== 'div';
    const Component = isControl ? ItemControl : Item;

    useEffect(() => {
      if (forceOpen) handleToggle(true);
    }, [forceOpen, handleToggle]);

    return (
      <Container ref={ref} {...other}>
        <Component
          style={style}
          className={className}
          indent={indent}
          focused={focused}
          active={active}
          highlighted={highlighted}
          primary={primary}
          secondary={secondary}
          level={level}
        >
          <Text
            truncate
            replace={replace}
            aria-label='title'
            as={as}
            fontWeight={fontWeight}
            fontSize={fontSize}
            to={to}
            onClick={() => {
              if (onClick) onClick();
              handleToggle(true);
            }}
          >
            {title}
          </Text>
          <ButtonsWrapper>
            {rightAction}
            {children && isFixedGroup && (
              <IconButton
                size={28}
                aria-label='show more'
                aria-expanded={open}
                onClick={handleToggle}
              >
                {open ? <ArrowUp1pxIcon /> : <ArrowDown1pxIcon />}
              </IconButton>
            )}
          </ButtonsWrapper>
        </Component>
        {open && children}
      </Container>
    );
  },
);

SideMenuGroup.ChildWrapper = ChildWrapper;

SideMenuGroup.propTypes = {
  children: PropTypes.node,
  rightAction: PropTypes.node,
  active: PropTypes.bool,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  initialOpen: PropTypes.bool,
  isFixedGroup: PropTypes.bool,
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  as: PropTypes.elementType,
  level: PropTypes.number,
};

export { SideMenuGroup };
