import { FC } from 'react';

import styled, { css } from 'styled-components';

import { ProductEntity } from 'common/types/product.types';
import { useReform } from 'reform';
import { TabList, TablistTab } from 'UI';

const StyledTabList = styled(TabList)(
  ({ theme: { space, colors } }) => css`
    justify-content: center;
    margin-bottom: ${space[2]}px;
    border-top: 1px solid ${colors.divider};
  `,
);

interface IModalTabListProps {
  options: { label: string; value: ProductEntity; checked?: boolean }[];
  entity: ProductEntity;
  disabled?: boolean;
  onAfterClick?: (value: ProductEntity) => void;
}

const ModalTabList: FC<IModalTabListProps> = ({
  options,
  entity,
  disabled,
  onAfterClick,
}) => {
  const { setFieldValue } = useReform();

  return (
    <StyledTabList>
      {options.map(({ label, value, checked }) => {
        return (
          <TablistTab
            key={value}
            aria-selected={entity === value || checked}
            disabled={disabled}
            onClick={() => {
              setFieldValue('reference.entity', value);
              setFieldValue('reference.id', null);

              if (onAfterClick) onAfterClick(value);
            }}
          >
            {label}
          </TablistTab>
        );
      })}
    </StyledTabList>
  );
};

export { ModalTabList };
