import { FC, Fragment } from 'react';

import { RegisterAndAuthText } from 'components/RegisterAndAuthText';
import { hasIdentity } from 'entities/identity/store/selectors';
import { useField } from 'reform';
import { useAppSelector } from 'store';
import { Checkbox, Text, Tooltip } from 'UI';

import { CheckboxContainer } from './styled';

const FIELD_OPTIONS = {
  name: 'onlyPartners',
  transform: Number,
};

const CheckboxFilterInput: FC = () => {
  const isAuthed = useAppSelector(hasIdentity);

  const { value, setValue } = useField(FIELD_OPTIONS);

  const handleChange = (): void => setValue(prev => !prev);

  return (
    <Tooltip
      arrow
      hint
      maxWidth={290}
      title={
        !isAuthed && (
          <Fragment>
            Для работы с партнерами <RegisterAndAuthText /> как юридическое
            лицо.
          </Fragment>
        )
      }
    >
      <CheckboxContainer>
        <Text color={!isAuthed ? 'text.disabled' : 'text.primary'} fontSize={3}>
          Только партнеры
        </Text>
        <Checkbox
          checked={value}
          disabled={!isAuthed}
          onChange={handleChange}
        />
      </CheckboxContainer>
    </Tooltip>
  );
};

export { CheckboxFilterInput };
