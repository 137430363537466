import { Dispatch, FC, SetStateAction, useEffect, useRef } from 'react';

import { get } from 'lodash';
import { useDrag, useDragLayer, useDrop } from 'react-dnd';
import styled, { css, CSSProp } from 'styled-components';

import { IImage } from 'common/types/media.types';
import { getImageURL } from 'common/utils';
import { FileUpload } from 'components/FileUpload';
import { BaseImageBackground } from 'components/media/styled';
import { CloseMediumIcon } from 'resources/icons/18';
import { CancelThinIcon } from 'resources/other-22';
import { useForkRef } from 'tools/hooks';
import { CircleProgress, Text, Tooltip } from 'UI';

import { parseHex } from '../../../../utils';

const DRAG_TYPE = 'PRODUCTS_CREATE_PANORAMAS_MODAL_PREVIEW';

const rgbaBg = ({ theme: { colors }, color: colorProp }): string | CSSProp => {
  if (!colorProp) return 'background-color: transparent';

  const hexColor = get(colors, colorProp, colorProp);
  const parsedColor = parseHex(hexColor).join(', ');

  return css`
    background-color: rgba(${parsedColor}, 0.2);

    &:hover {
      background-color: rgba(${parsedColor}, 0.4);
    }
  `;
};

const ImageBackground = styled(BaseImageBackground)<{
  active: boolean;
  loading: boolean;
  hideControls: boolean;
}>(
  ({ theme: { colors }, active, loading, isDragging, hideControls }) => css`
    position: relative;
    min-width: 82px;
    min-height: 82px;
    border: 1px solid ${active ? colors.text.primary : colors.divider};
    background-size: contain;
    cursor: ${loading ? 'default' : 'pointer'};

    ${!isDragging &&
    !hideControls &&
    css`
      &:hover * {
        opacity: 1;
      }
    `}
  `,
);

const InnerBackground = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  ${rgbaBg};
`;

const ErrorIconBackground = styled.button.attrs(() => ({ type: 'button' }))(
  ({ theme: { space, colors } }) => css`
    width: ${space[5]}px;
    height: ${space[5]}px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    border: none;
    border-radius: 50%;
    background: ${colors.error.main};
  `,
);

const DeleteIconWrapper = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 3px;
    right: 3px;

    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;

    box-shadow: 0 1px 1px rgba(62, 67, 104, 0.15);
    border-radius: 1px;
    background: ${colors.white};

    opacity: 0;
  `,
);

const FileNameWrapper = styled.div(
  ({ theme: { space } }) => css`
    position: absolute;
    right: 3px;
    bottom: 3px;
    left: 3px;

    height: ${space[2]}px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 2px;

    border-radius: 1px;
    background: rgba(62, 67, 104, 0.3);

    opacity: 0;
  `,
);

const FileNameText = styled(Text)`
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
`;

const circleStyles = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  pointerEvents: 'none',
};

interface IPanoramasModalPreviewProps {
  active: boolean;
  index: number;
  image: IImage;
  onLoad: (nextImage: any, index: number) => void;
  onMoveItem: (fromId: string, toId: string) => void;
  onRemove: (target) => void;
  onSelect: Dispatch<SetStateAction<number>>;
}

const PanoramasModalPreview: FC<IPanoramasModalPreviewProps> = ({
  index,
  active,
  image,
  onLoad,
  onSelect,
  onRemove,
  onMoveItem,
}) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, dragRef] = useDrag({
    type: DRAG_TYPE,
    item: { id: image.id, index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, dropRef] = useDrop({
    accept: DRAG_TYPE,
    hover({ id: draggedId }) {
      if (draggedId !== image.id) {
        onMoveItem(draggedId, image.id);
      }
    },
  });

  const dragMonitor = useDragLayer(monitor => monitor);
  const hasBeenDragged = dragMonitor.getItem()?.index === index;
  const handleRef = useForkRef(dragRef, dropRef, scrollRef);

  useEffect(() => {
    if (active)
      scrollRef.current!.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
  }, [active]);

  const getContent = (error, loading): JSX.Element => {
    if (error) {
      return (
        <Tooltip arrow hint title={error}>
          <ErrorIconBackground data-index={index} onClick={onRemove}>
            <CloseMediumIcon color='white' />
          </ErrorIconBackground>
        </Tooltip>
      );
    }

    if (loading) {
      return (
        <>
          <CircleProgress loading={loading} size={34} style={circleStyles} />
          <CancelThinIcon
            color='white'
            data-index={index}
            size={14}
            style={{ cursor: 'pointer' }}
            onClick={onRemove}
          />
        </>
      );
    }

    return (
      <>
        <DeleteIconWrapper>
          <CloseMediumIcon data-index={index} onClick={onRemove} />
        </DeleteIconWrapper>
        <FileNameWrapper>
          <FileNameText color='white' fontSize={0}>
            {image.filename}
          </FileNameText>
        </FileNameWrapper>
      </>
    );
  };

  return (
    <FileUpload
      file={image}
      section='product-images'
      onLoad={value => onLoad(value, index)}
    >
      {({ error, loading, image: localImage }) => (
        <ImageBackground
          ref={handleRef}
          active={active}
          color={loading ? 'text.primary' : undefined}
          hideControls={hasBeenDragged}
          isDragging={isDragging}
          loading={loading}
          url={localImage ?? getImageURL(image)}
          onClick={() => onSelect(index)}
        >
          <InnerBackground
            color={error || loading ? 'text.primary' : undefined}
          >
            {getContent(error, loading)}
          </InnerBackground>
        </ImageBackground>
      )}
    </FileUpload>
  );
};

export { PanoramasModalPreview };
