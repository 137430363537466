import { FC, MouseEvent, useMemo } from 'react';

import styled, { css } from 'styled-components';

import {
  ALL,
  IMAGE_FILTERS,
  TITLE_FILTERS,
} from 'common/constants/products.const';
import { Nullable } from 'common/types/common.types';
import { IImage, MediaFieldsTypes } from 'common/types/media.types';
import { Lock1pxIcon } from 'resources/icons/1px-18';
import { Text } from 'UI';

import { Media } from '../types';

const FiltersWrapperImage = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    gap: ${space[0]}px;
    margin-bottom: ${space[1]}px;
  `,
);

const FilterButton = styled.button<{ active: boolean }>(
  ({ theme: { space, colors, fontSizes }, active }) => css`
    display: flex;
    align-items: center;
    padding: ${space[0]}px ${space[1]}px;
    font-size: ${fontSizes[2]}px;

    ${active &&
    css`
      border-radius: 12px;
      background: ${colors.highlight[2]};
    `}
  `,
);

interface IImagesFilterButtonsProps {
  selectedFilter: string;
  previewImage?: Nullable<IImage>;
  media: Media;
  handleSelectedFilter: (event: MouseEvent<HTMLButtonElement>) => void;
}

const ImagesFilterButtons: FC<IImagesFilterButtonsProps> = ({
  selectedFilter,
  previewImage,
  media,
  handleSelectedFilter,
}) => {
  const filterButtons = useMemo(() => {
    const resultFilter = IMAGE_FILTERS.filter(filter => {
      if (filter === MediaFieldsTypes.model3D) {
        return !!media[filter]?.file;
      }

      return !!media[filter]?.items?.length;
    });

    return resultFilter.length > 1 || !!previewImage
      ? [ALL, ...resultFilter]
      : resultFilter;
  }, [media, previewImage]);

  return (
    <FiltersWrapperImage>
      {filterButtons.map(filter => (
        <FilterButton
          key={filter}
          active={selectedFilter === filter}
          data-filter={filter}
          onClick={filterButtons.length > 1 ? handleSelectedFilter : undefined}
        >
          {media[filter]?.isPrivate && (
            <Lock1pxIcon color='text.primary' mr={0} />
          )}
          <Text>{TITLE_FILTERS[filter]}</Text>
        </FilterButton>
      ))}
    </FiltersWrapperImage>
  );
};

export { ImagesFilterButtons };
