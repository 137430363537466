import {
  Fragment,
  memo,
  forwardRef as reactForwardRef,
  FC,
  ReactNode,
  ForwardedRef,
  CSSProperties,
} from 'react';

import { get } from 'lodash';
import styled, { css } from 'styled-components';
import {
  color,
  border,
  opacity,
  space as styledSpace,
  BorderProps,
} from 'styled-system';

import { Nullable } from 'common/types/common.types';
import { SortDownIcon, SortUpIcon } from 'resources/other';

import { Placeholder } from './Placeholder';
import { IDataTableColumn } from './types';
import { ProgressIndicator } from '../Indicators';
import { Text } from '../Text';
import { Tooltip } from '../Tooltip';

const Container = styled.div<{
  isInnerTable?: boolean;
  striped?: boolean;
  minHeight?: string;
}>(
  ({
    isInnerTable,
    striped,
    minHeight = '400px',
    theme: { shadows, colors },
  }) => css`
    position: relative;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: ${minHeight};
    background: ${colors.white};
    box-shadow: ${striped || isInnerTable ? 'none' : shadows[0]};

    ${striped &&
    css`
      box-shadow: none;
      border-top: 1px solid ${colors.divider};
    `}

    ${isInnerTable &&
    css`
      overflow: auto;
      border-top-width: 0;
    `}
  `,
);

const Table = styled.table`
  width: 100%;
  table-layout: auto;
  border-collapse: collapse;
`;

const Error = styled.div(
  ({ theme: { space } }) => css`
    width: ${space[0]}px;
    height: 100%;
  `,
);

const column = css<{ align: string; striped?: boolean }>(
  ({ theme: { space, colors }, align, striped }) => css`
    padding: ${space[1]}px;
    text-align: ${align};
    border-bottom: 1px solid ${colors.background};

    &:first-child {
      padding: 0;
      width: ${space[1]}px;
      min-width: ${space[1]}px;
    }

    ${striped &&
    css`
      border-bottom: none;
    `}
  `,
);

const HeaderRow = styled.tr`
  position: sticky;
  top: 0;
  z-index: 2;

  min-height: 34px;
  height: 34px;
`;

const HeaderCell = styled.th<{
  fixedWidth?: number;
  minWidth?: number;
  striped?: boolean;
}>(
  ({
    theme: { colors, fontSizes },
    fixedWidth,
    minWidth = 70,
    striped = false,
  }) => css`
    min-width: ${minWidth}px;
    white-space: nowrap;
    font-size: ${fontSizes[0]}px;
    font-weight: 700;
    letter-spacing: 0.5px;
    text-transform: ${striped ? 'none' : 'uppercase'};
    color: ${colors.text.secondary};
    background: ${striped ? colors.white : colors.background};

    ${column};

    ${fixedWidth &&
    css`
      width: ${fixedWidth}px;
      min-width: ${fixedWidth}px;
      max-width: ${fixedWidth}px;
    `}

    ${color}
  `,
);

const DataCell = styled.td.attrs(() => ({
  role: 'cell',
}))<{
  truncate?: boolean;
  verticalAlign?: string;
  cellHeight?: string;
  textColor?: string;
}>(
  ({
    theme: { colors, fontSizes, space },
    truncate,
    verticalAlign,
    cellHeight = `${space[7]}px`,
    textColor,
  }) => css`
    max-width: 32px;
    height: ${cellHeight};
    font-size: ${fontSizes[3]}px;
    vertical-align: ${verticalAlign};
    transition: background 0.1s ease-in-out;

    ${textColor &&
    css`
      color: ${colors.text[textColor]};
    `}

    ${!truncate &&
    css`
      word-break: break-word;
      white-space: pre-wrap;
    `}

    tr:last-child > & {
      border-bottom: none;
    }

    ${column}
    ${styledSpace}
    ${border}
  `,
);

const DataCellExpanded = styled(DataCell)`
  height: auto;
`;

const TableRow = styled.tr.attrs(() => ({
  role: 'row',
}))<{ striped?: boolean; error?: boolean; expand?: boolean } & BorderProps>(
  ({ theme: { colors, space }, expand, error, striped }) => css`
    ${striped
      ? css`
          &:nth-child(even) {
            background: ${colors.highlight[0]};
          }

          ${
            error &&
            css`
              & ${DataCell}:first-child {
                background: ${colors.error.main};
            `
          };

          & ${DataCell} {
            border-bottom: none;

            &:last-of-type {
              padding-right: ${space[2]}px;
            }
          },
        `
      : css`
          &:hover ${DataCell} {
            background: ${colors.highlight[0]};
          }

          ${error &&
          css`
              & ${DataCell}:first-child {
                background: ${colors.error.main};
            `};
        `}

    ${expand &&
    css`
      & ${DataCell}, &:hover ${DataCell} {
        border-color: transparent;
        background: transparent;
      }
    `}

    ${color}
    ${opacity}
    ${border}
  `,
);

const HeaderCellText = styled.div<{ sortable: boolean }>(
  ({ sortable }) => css`
    display: flex;
    align-items: center;
    cursor: ${sortable ? 'pointer' : 'default'};
  `,
);

const TableLoading = styled.div`
  width: 100%;
  height: calc(100% - 30px); /* table header must show without loader */
  position: absolute;
  top: 30px;
  z-index: 1;
  background: rgba(255, 255, 255, 0.4);
`;

const SortIconWrapper = styled.div<{ active: boolean }>(
  ({ theme: { space }, active }) => css`
    margin-left: ${space[1]}px;
    opacity: ${active ? 1 : 0};
    transition: opacity 200ms ease-in-out;

    ${HeaderCell}:hover & {
      opacity: 1;
    }
  `,
);

const ASCENDING = 'ASC';
const DESCENDING = 'DESC';

const toggleDirection = (direction): 'ASC' | 'DESC' =>
  direction === ASCENDING ? DESCENDING : ASCENDING;

function getHeaderProps(header): any {
  if (typeof header === 'undefined') return { text: '' };
  if (typeof header === 'string') return { text: header };
  return header;
}

const Loading: FC = () => {
  return (
    <TableLoading>
      <ProgressIndicator isLoading />
    </TableLoading>
  );
};

interface IRenderColumnsDataProps {
  item: any;
  index: number;
  columns: IDataTableColumn[];
  rowKey?: string;
  error?: ReactNode | any;
  cellHeight?: string;
  textColor?: string;
}

/**
 * Отдельная декларация forwardRef необходима для корректной работы
 * с TS Generic.
 */
declare module 'react' {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: Ref<T>) => ReactElement | null,
  ): (props: P & RefAttributes<T>) => ReactElement | null;
}

const ColumnsData: FC<IRenderColumnsDataProps> = memo(
  ({
    item,
    index,
    columns,
    rowKey,
    error,
    cellHeight = '70px',
    textColor = 'primary',
  }) => {
    return (
      <Fragment>
        <DataCell cellHeight={cellHeight}>
          <Tooltip
            maxWidth={260}
            placement='bottom'
            title={typeof error === 'string' && error}
          >
            <Error />
          </Tooltip>
        </DataCell>
        {columns.map(
          ({
            key,
            render,
            truncate = true,
            align,
            verticalAlign = 'inherit',
            colSpan,
            cellStyle,
          }) => {
            const value = get(item, key);
            const renderValue =
              typeof render === 'function' ? render(value, item, index) : value;

            return (
              <DataCell
                key={`${rowKey}_${key}`}
                align={align}
                cellHeight={cellHeight}
                colSpan={colSpan}
                textColor={textColor}
                truncate={truncate}
                verticalAlign={verticalAlign}
                {...cellStyle}
              >
                {truncate ? <Text truncate>{renderValue}</Text> : renderValue}
              </DataCell>
            );
          },
        )}
      </Fragment>
    );
  },
);

const defaultRowKey = (item): string => item.id;

interface IDataTableProps<Item = any> {
  /**
   * Таблица является внутренней (для страниц типа списка товаров)
   */
  isInnerTable?: boolean;
  className?: string;
  style?: CSSProperties;
  /**
   * Read state with another options
   */
  striped?: boolean;
  /**
   * Progress bar for header
   */
  loading?: boolean;
  /**
   * Information in table`s columns
   */
  columns: IDataTableColumn<Item>[];
  /**
   * Key from row
   */
  getRowKey?: (prop: Item) => string;
  /**
   * Style for rows
   */
  rowStyle?: (item: Item, index: number) => object | undefined;
  /**
   * Определяет, должен ли элемент скрываться на основе конкретного условия.
   * Подходит для кейсов фильтрации, когда нужно, чтобы элемент существовал в исходном массиве
   * и сохранял свои индексы, но должен быть скрыт визуально
   * @param {object} item - Элемент, который нужно проверить на скрытие.
   * @return {boolean} - Возвращает true, если элемент должен быть скрыт, и false, если не должен.
   */
  shouldHide?: (item: Item) => boolean;
  /**
   * Rows with additional information
   */
  expandedRows?: object;
  /**
   * Types with additional information
   */
  expandedTypes?: object;
  /**
   * Sorting options
   */
  orderBy?: string;
  /**
   * Direction for table`s rows
   */
  orderDirection?: 'ASC' | 'DESC';
  /**
   * Information in table`s columns
   */
  data: any[];
  /**
   * Placeholder for empty table
   */
  placeholder?: { type: string; text?: ReactNode };
  /**
   * Sort handler
   */
  onSort?: (key: string, direction: string) => void;
  /**
   * Color of data table text
   */
  textColor?: string;
  /**
   * Определяет, нужно ли отображать вложенные элементы в одной объединенной ячейке (дефолтное поведение)
   */
  expandedUnionCells?: boolean;
  cellHeight?: string;
  minHeight?: string;
}

const DataTableInner = <T extends unknown = any>(
  {
    isInnerTable,
    className,
    style,
    striped,
    minHeight,
    orderBy,
    orderDirection,
    onSort,
    loading,
    data,
    columns,
    getRowKey = defaultRowKey,
    rowStyle,
    shouldHide,
    placeholder,
    expandedRows = {},
    expandedTypes = {},
    cellHeight,
    textColor,
    expandedUnionCells = true,
  }: IDataTableProps<T>,
  ref: ForwardedRef<HTMLDivElement>,
): JSX.Element => {
  const renderHeaderCell = ({
    key,
    header,
    align = 'left',
    sortable = true,
  }): JSX.Element => {
    const { text, fixedWidth, ...props } = getHeaderProps(header);

    const isSorted = orderBy === key;
    const handleSort = (): void => {
      if (onSort) {
        onSort(key, isSorted ? toggleDirection(orderDirection) : DESCENDING);
      }
    };
    const isDescending = orderDirection === DESCENDING;
    const SortIcon = isDescending ? SortDownIcon : SortUpIcon;

    return (
      <HeaderCell
        key={key}
        align={align}
        fixedWidth={fixedWidth}
        isInnerTable={isInnerTable}
        striped={striped}
        {...props}
      >
        <HeaderCellText
          sortable={sortable}
          onClick={sortable ? handleSort : undefined}
        >
          {fixedWidth ? (
            <Text truncate width={fixedWidth}>
              {text}
            </Text>
          ) : (
            text
          )}
          {sortable && (
            <Tooltip
              arrow
              hint
              title={
                isDescending
                  ? 'Сортировать по возрастанию'
                  : 'Сортировать по убыванию'
              }
            >
              <SortIconWrapper active={isSorted}>
                <SortIcon />
              </SortIconWrapper>
            </Tooltip>
          )}
        </HeaderCellText>
      </HeaderCell>
    );
  };

  const renderRow = (item, index): Nullable<JSX.Element> => {
    const rowKey = getRowKey(item);
    const expandedType = expandedRows[index];
    const renderExpanded =
      typeof expandedType === 'undefined' ? null : expandedTypes[expandedType];

    const isHidden = shouldHide && shouldHide(item);
    const rowStyles: any = rowStyle && rowStyle(item, index);

    if (isHidden) return null;

    return (
      <Fragment key={`${rowKey}-${index}`}>
        <TableRow
          striped={striped}
          {...rowStyles}
          expand={renderExpanded && expandedUnionCells}
        >
          <ColumnsData
            cellHeight={cellHeight}
            columns={columns}
            error={rowStyles?.error}
            index={index}
            item={item}
            rowKey={rowKey}
            textColor={textColor}
          />
        </TableRow>
        {renderExpanded &&
          (expandedUnionCells ? (
            <TableRow expand={renderExpanded}>
              <DataCellExpanded colSpan={columns.length + 1}>
                {renderExpanded(item, index)}
              </DataCellExpanded>
            </TableRow>
          ) : (
            renderExpanded(item, index)
          ))}
      </Fragment>
    );
  };

  const noData = data.length === 0 && !loading;

  return (
    <Container
      ref={ref}
      className={className}
      isInnerTable={isInnerTable}
      minHeight={minHeight}
      striped={striped}
      style={style}
    >
      {loading && <Loading />}
      <Table>
        <thead>
          <HeaderRow>
            <HeaderCell striped={striped} />
            {columns.map(renderHeaderCell)}
          </HeaderRow>
        </thead>
        {!noData && <tbody>{data.map(renderRow)}</tbody>}
      </Table>
      {noData && (
        <Placeholder text={placeholder?.text} type={placeholder?.type} />
      )}
    </Container>
  );
};

const DataTable = reactForwardRef(DataTableInner);

const DataTableExtension = Object.assign(DataTable, {
  Row: TableRow,
  Columns: ColumnsData,
});

export { DataTableExtension as DataTable };
