import { FC, Fragment } from 'react';

import styled, { css } from 'styled-components';

import { CheckMediumIcon, RemoveIcon } from 'resources/icons/18';
import { Help1pxIcon } from 'resources/icons/1px-18';
import { itemTo } from 'tools/utils';
import { CollapseCard, DataTable, Text, Tooltip } from 'UI';

const enum AccessTypes {
  granted = 'granted',
  denied = 'denied',
  notApplicable = 'notApplicable',
}

enum EmployeeAccessLevels {
  availableProducts = 'availableProducts',
  myProducts = 'myProducts',
  // myStandards = 'myStandards',
  // tasks = 'tasks',
  collections = 'collections',
  productSpecifications = 'productSpecifications',
  // requests = 'requests',
  myCompany = 'myCompany',
  article = 'article',
  employees = 'employees',
  brands = 'brands',
  departments = 'departments',
  pointOfSale = 'pointOfSale',
  myPartners = 'myPartners',
  partnerChannels = 'partnerChannels',
  partnerProducts = 'partnerProducts',
}

const ACCESS_LEVEL_TEXT_BY_TYPE = {
  [EmployeeAccessLevels.availableProducts]: 'Доступные товары',
  [EmployeeAccessLevels.myProducts]: 'Мои товары',
  // [EmployeeAccessLevels.myStandards]: 'Мои стандарты',
  // [EmployeeAccessLevels.tasks]: 'Задачи',
  [EmployeeAccessLevels.collections]: 'Коллекции',
  [EmployeeAccessLevels.productSpecifications]: 'Технические задания',
  // [EmployeeAccessLevels.requests]: 'Заявки',
  [EmployeeAccessLevels.myCompany]: 'Моя компания',
  [EmployeeAccessLevels.article]: 'Регламенты',
  [EmployeeAccessLevels.employees]: 'Сотрудники',
  [EmployeeAccessLevels.brands]: 'Бренды',
  [EmployeeAccessLevels.departments]: 'Контакты',
  [EmployeeAccessLevels.pointOfSale]: 'Места продаж',
  [EmployeeAccessLevels.myPartners]: 'Мои партнеры',
  [EmployeeAccessLevels.partnerChannels]: 'Каналы',
  [EmployeeAccessLevels.partnerProducts]: 'Запросы товаров',
};

const ACCESS_LEVEL_ICON_BY_TYPE = {
  [AccessTypes.granted]: <CheckMediumIcon color='success.main' />,
  [AccessTypes.denied]: <CheckMediumIcon color='divider' />,
  [AccessTypes.notApplicable]: <RemoveIcon color='text.secondary' />,
};

const TOOLTIP_TITLE = (
  <Fragment>
    <strong>Просмотр</strong>
    <Text mb={2}>Просмотр раздела.</Text>

    <strong>Добавление</strong>
    <Text mb={2}>Просмотр раздела, добавление новых элементов.</Text>

    <strong>Редактирование</strong>
    <Text mb={2}>
      Просмотр раздела, добавление новых элементов, редактирование созданных
      ранее.
    </Text>

    <strong>Удаление</strong>
    <Text>
      Просмотр раздела, добавление новых элементов, редактирование и удаление
      созданных ранее.
    </Text>
  </Fragment>
);

const StyledDataTable = styled(DataTable)(
  ({ theme: { colors, fontSizes } }) => css`
    td {
      font-size: ${fontSizes[2]}px;
    }

    th {
      border-bottom: 1px solid ${colors.divider};
      font-size: ${fontSizes[2]}px;
      text-align: center;
    }

    th:not(:first-child, :last-child) {
      border-right: 1px solid ${colors.divider};
    }

    tr > td:not(:first-child, :last-child) {
      border-right: 1px solid ${colors.divider};
    }

    tr > th:nth-child(n + 3) {
      & div {
        justify-content: center;
      }
    }

    tr > td:nth-child(n + 3) {
      text-align: center;
    }

    tr > td:first-child {
      width: 0%;
    }
  `,
);

const Legend = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[6]}px;
    margin-top: ${space[2]}px;
    margin-left: ${space[1]}px;
  `,
);

const LegendItem = styled.div(
  ({ theme: { space, colors, fontSizes } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[1]}px;
    color: ${colors.text.secondary};
    font-size: ${fontSizes[2]}px;
  `,
);

const getAccessType = (prevType, currentLevelType): AccessTypes => {
  const isGranted =
    prevType === AccessTypes.granted ||
    currentLevelType === AccessTypes.granted;

  const isDenied = prevType
    ? prevType === AccessTypes.denied && currentLevelType === AccessTypes.denied
    : currentLevelType === AccessTypes.denied;

  if (isGranted) return AccessTypes.granted;

  return isDenied ? AccessTypes.denied : AccessTypes.notApplicable;
};

const transformAccessRoles = (value): any => {
  const permissions = value.map(role => role.permissions);
  return Object.values(EmployeeAccessLevels).map(level => {
    const permissionLevel = permissions.reduce((acc, permission) => {
      const currentAccessLevel = permission[level] ?? {};

      return {
        read: getAccessType(acc.read, currentAccessLevel.read),
        create: getAccessType(acc.create, currentAccessLevel.create),
        update: getAccessType(acc.update, currentAccessLevel.update),
        delete: getAccessType(acc.delete, currentAccessLevel.delete),
      };
    }, {});

    return {
      level,
      ...permissionLevel,
    };
  });
};

const columns = [
  {
    key: 'level',
    header: {
      text: (
        <Fragment>
          <Text color='text.secondary' fontWeight={600}>
            Раздел / Доступы
          </Text>
          <Tooltip
            arrow
            hint
            maxWidth={320}
            placement='right'
            title={TOOLTIP_TITLE}
          >
            <Help1pxIcon ml={1} />
          </Tooltip>
        </Fragment>
      ),
      width: '28%',
    },
    sortable: false,
    render: level => ACCESS_LEVEL_TEXT_BY_TYPE[level],
  },
  {
    key: 'read',
    header: { text: 'Просмотр', width: '18%' },
    sortable: false,
    render: type => ACCESS_LEVEL_ICON_BY_TYPE[type],
  },
  {
    key: 'create',
    header: { text: 'Добавление', width: '18%' },
    sortable: false,
    render: type => ACCESS_LEVEL_ICON_BY_TYPE[type],
  },
  {
    key: 'update',
    header: { text: 'Редактирование', width: '18%' },
    sortable: false,
    render: type => ACCESS_LEVEL_ICON_BY_TYPE[type],
  },
  {
    key: 'delete',
    header: { text: 'Удаление', width: '18%' },
    sortable: false,
    render: type => ACCESS_LEVEL_ICON_BY_TYPE[type],
  },
];

const PermissionsTable: FC<any> = ({ accessRoles, initialOpen, ...other }) => {
  if (accessRoles.length === 0) return null;

  const transformedAccessRoles = transformAccessRoles(accessRoles);

  return (
    <CollapseCard
      inner
      initialOpen={initialOpen}
      title={<Text fontSize={4}>Таблица доступов</Text>}
      {...other}
    >
      <StyledDataTable
        striped
        cellHeight='34px'
        columns={columns}
        data={transformedAccessRoles}
        getRowKey={itemTo('id')}
        textColor='primary'
      />
      <Legend>
        <LegendItem>
          <CheckMediumIcon color='success.main' />
          Доступ открыт
        </LegendItem>
        <LegendItem>
          <CheckMediumIcon color='divider' />
          Доступ закрыт
        </LegendItem>
        <LegendItem>
          <RemoveIcon color='text.secondary' />
          Возможность не предусмотрена
        </LegendItem>
      </Legend>
    </CollapseCard>
  );
};

export { PermissionsTable };
