import { useState, memo, FC, Dispatch, SetStateAction } from 'react';

import styled, { css } from 'styled-components';

import { fetchProductManagementSearchProducts } from 'common/api/products.api';
import { MASTER_PANEL_SCREENS } from 'common/constants/master-panel.const';
import {
  PRODUCT_CATEGORY_TEXT,
  PRODUCT_ACCESS_STATUSES,
  KIND_PRODUCT_OPTIONS_BY_TEXT,
} from 'common/constants/products.const';
import { INITIAL_DATA_REQUEST_V1 } from 'common/constants/request.const';
import { useRequest } from 'common/hooks';
import { IProduct } from 'common/types/product.types';
import { getProductFullName } from 'common/utils/products';
import { MainProperties } from 'components/products/properties';
import {
  Text,
  Spinner,
  Container,
  Placeholder,
  PaginationControlled,
  PLACEHOLDER_TYPES,
} from 'UI';

import { ProductsFilterForm } from './ProductsFilterForm';
import { Search } from './Search';
import { MasterPanelHeader } from '../../index';
import { useMasterPanelContext } from '../../MasterPanel';

const WIDTH = '102px';
const FLEX_GROW = { flexGrow: 1 };
const HEADER_TEXT_PROPS = {
  width: WIDTH,
  fontSize: 3,
  fontWeight: 500,
  color: 'text.secondary',
};

const Header = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    gap: ${space[2]}px;
    min-height: 34px;
    padding: 0 ${space[2]}px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

const Item = styled.div(
  ({ theme: { space, colors } }) => css`
    display: flex;
    align-items: center;
    gap: ${space[2]}px;
    height: 52px;
    padding: 0 ${space[2]}px;
    border-bottom: 1px solid ${colors.background};
    cursor: pointer;
    transition: 0.2s ease-in-out all;

    &:hover {
      background: ${colors.highlight[0]};
    }
  `,
);

interface IChoosingProductProps {
  searchText: string;
  setSearchText: Dispatch<SetStateAction<string>>;
  setSelectedProduct: Dispatch<SetStateAction<IProduct>>;
}

const ChoosingProduct: FC<IChoosingProductProps> = memo(
  ({ searchText, setSearchText, setSelectedProduct }) => {
    const { pushPanelState } = useMasterPanelContext();

    const [page, setPage] = useState(1);

    const {
      isLoading,
      data: { member, totalItems } = INITIAL_DATA_REQUEST_V1,
    }: {
      isLoading: boolean;
      data: { member: IProduct[]; totalItems: number };
    } = useRequest(fetchProductManagementSearchProducts, [
      {
        search: searchText,
        accessStatus: PRODUCT_ACCESS_STATUSES.OWN,
        page,
      },
    ]);

    const handleSearch = (value: string): void => {
      setSearchText(value);
      setPage(1);
    };

    const handleSetPage = (num: number): void => {
      setPage(num);
    };

    const handleSelected = (product: IProduct): void => {
      setSelectedProduct(product);
      pushPanelState(MASTER_PANEL_SCREENS.REUSE.SECTIONS);
    };

    return (
      <Container column height='100%' width='100%'>
        <MasterPanelHeader
          rightAddon={<ProductsFilterForm />}
          title='Выбор товара'
        />
        <Search
          productsCount={totalItems}
          searchValue={searchText}
          onSearch={handleSearch}
        />
        <Container column height='100%' overflowY='auto' pb={3} pt={2} px={4}>
          <Header>
            <Text {...HEADER_TEXT_PROPS} style={FLEX_GROW}>
              Название
            </Text>
            <Text {...HEADER_TEXT_PROPS}>Тип</Text>
            <Text {...HEADER_TEXT_PROPS}>Вид продукции</Text>
          </Header>
          <Container column flexGrow={1}>
            <Spinner delay={0} height='100%' loading={isLoading}>
              {member.map(product => {
                const { id, type, category, kind } = product;
                const productName = getProductFullName(product);

                return (
                  <Item key={id} onClick={() => handleSelected(product)}>
                    <Container width='264px'>
                      <Container mr={1}>
                        <Container column width='222px'>
                          <Text truncate fontSize={3} mb='2px'>
                            {productName}
                          </Text>
                          <Text color='text.secondary' fontSize={2}>
                            {PRODUCT_CATEGORY_TEXT[category]}
                          </Text>
                        </Container>
                      </Container>
                      <MainProperties hasCopyUin={false} product={product} />
                    </Container>
                    <Container width={WIDTH}>
                      <Text
                        truncate
                        color={!type ? 'text.disabled' : undefined}
                        fontSize={3}
                      >
                        {type || 'Не указано'}
                      </Text>
                    </Container>
                    <Container width={WIDTH}>
                      <Text
                        truncate
                        color={!kind ? 'text.disabled' : undefined}
                        fontSize={3}
                      >
                        {KIND_PRODUCT_OPTIONS_BY_TEXT[kind] || 'Не указано'}
                      </Text>
                    </Container>
                  </Item>
                );
              })}
              {totalItems === 0 && (
                <Placeholder
                  type={
                    searchText
                      ? PLACEHOLDER_TYPES.MATCHES
                      : PLACEHOLDER_TYPES.PRODUCTS
                  }
                />
              )}
            </Spinner>
          </Container>
          <PaginationControlled
            itemsPerPage={20}
            mt={2}
            page={page}
            setPage={handleSetPage}
            totalItems={totalItems}
          />
        </Container>
      </Container>
    );
  },
);

export { ChoosingProduct };
