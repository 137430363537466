import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { useSelector } from 'react-redux';
import styled, { css } from 'styled-components';

import { getTotalPages, handleScrollTo, useRequest } from 'common/hooks';
import { ProductSections } from 'common/types/product.types';
import { getIdentity } from 'entities/identity';
import { useModalContext, useModalPropsContext } from 'entities/modals';
import { CommentAddBoldIcon } from 'resources/icons/18';
import { CommentBlankIcon } from 'resources/other-18';
import { Container, InfoBlock, Spinner, Text } from 'UI';

import { ProductCommentsCard, ProductCreateCommentsPopup } from './Cards';
import { IComment } from './types';
import { useCommentsSections } from './useCommentsSections';
import { fetchProductComments } from '../../../../api';
import { PRODUCT_ACCESS_STATUSES } from '../../../../constants';
import { COMMENT_POPUP } from '../../../constants';

const ITEMS_PER_PAGE = 5;

const NextCommentsPage = styled.button(
  ({ theme: { space, colors, fontSizes } }) => css`
    height: ${space[3]}px;
    width: 100%;
    margin-bottom: ${space[3]}px;
    font-size: ${fontSizes[2]}px;
    color: ${colors.text.secondary};
    background: ${colors.highlight[0]};
  `,
);

const Overflow = styled.div(
  ({ theme: { space, zIndex } }) => css`
    display: flex;
    width: ${space[4]}px;
    height: 42px;
    z-index: ${zIndex.sideMenu};
    cursor: pointer;

    &[data-popper-placement^='top'] > [data-popper-arrow] {
      bottom: ${space[0]}px;
      &::before {
        transform: rotate(-36deg) skewX(20deg);
      }
    }

    &[data-popper-placement^='bottom'] {
      align-items: flex-end;

      [data-popper-arrow] {
        top: ${space[0]}px;
        &::before {
          transform: rotate(144deg) skewX(20deg);
        }
      }
    }
  `,
);

const IconContainer = styled.div(
  ({ theme: { space, colors, borderRadius } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${space[4]}px;
    height: ${space[4]}px;
    border-radius: ${borderRadius}px;
    background: ${colors.text.primary};
  `,
);

const Arrow = styled.div.attrs(() => ({
  'data-popper-arrow': '',
}))(
  ({ theme: { space, colors } }) => css`
    position: absolute;
    bottom: ${space[0]}px;
    left: 50%;
    transform: translateX(-50%);

    &::before {
      content: '';
      display: block;
      width: 9px;
      height: 9px;
      background: ${colors.text.primary};
      transform: rotate(-36deg) skewX(20deg);
    }
  `,
);

function ProductComments({
  id,
  isLoadedPage,
  isCommentMode,
  title,
  product,
}): JSX.Element | null {
  const productRef = useRef(product);
  const blockCommentsRef = useRef<HTMLDivElement>(null);
  const { openedModal } = useModalPropsContext();
  const { handleModalOpen, handleModalClose } = useModalContext();

  const [page, setPage] = useState(1);
  const [visibleComments, setVisibleComments] = useState(isCommentMode);
  const [[comments, totalItems], setData] = useState<
    [comments: IComment[], totalItems: number]
  >([[], 0]);
  const identity = useSelector(getIdentity);

  const { isLoading } = useRequest(
    isCommentMode && fetchProductComments,
    [{ productId: productRef.current.id, page, itemsPerPage: ITEMS_PER_PAGE }],
    {
      responseToData: data => data.totalItems,
      onSuccess: data => {
        setData([[...comments, ...data.member], data.totalItems]);
      },
    },
  );

  const handleUpdateComments = useCallback(
    updateComments => {
      return setData(([prevComments, prevTotalItems]) => {
        const newComments = updateComments(prevComments);
        const diffTotalItems = newComments.length - prevComments.length;

        return [
          newComments.slice(0, page * ITEMS_PER_PAGE),
          prevTotalItems + diffTotalItems,
        ];
      });
    },
    [page],
  );

  const setCommentData = useCallback(
    data => {
      const { payload, section, subSection } = data;

      let quote = payload;
      let file = null;

      if (section === ProductSections.gallery) {
        file = [
          ...(productRef.current.media.images?.items ?? []),
          ...(productRef.current.media.componentImages?.items ?? []),
          productRef.current.previewImage,
        ].find(({ id: imageId }) => imageId === payload);
        quote = null;
      } else if (section === ProductSections.videos) {
        const videoFile = productRef.current.media[section].items.find(
          ({ id: fileId }) => fileId === payload,
        );
        quote = videoFile.url;
      } else if (section === ProductSections.documents) {
        file = Object.values(productRef.current.media[section])
          .map(({ items }) => items ?? [])
          .flat()
          .filter(({ file: documentFile }) => !!documentFile)
          .find(({ file: { id: fileId } }) => fileId === payload).file;
        quote = null;
      }

      handleModalOpen(COMMENT_POPUP, {
        sticky: true,
        productId: productRef.current.id,
        section,
        subSection,
        file,
        quote,
        updateData: handleUpdateComments,
      });
    },
    [handleModalOpen, handleUpdateComments],
  );

  const totalPages = getTotalPages(totalItems, ITEMS_PER_PAGE);

  const { getHintContainer } = useCommentsSections({
    isLoadedPage,
    disabled:
      productRef.current.accessStatus !== PRODUCT_ACCESS_STATUSES.AVAILABLE ||
      openedModal === COMMENT_POPUP ||
      !isCommentMode,
    setCommentData,
  });

  useEffect(() => {
    let start;
    let diffScrollBlockComments;
    let startPageYOffset;
    const parentContainer = window.getMainContent();

    const checkScrollEnd = (timestamp): void => {
      if (!start) start = timestamp;
      const time = timestamp - start;
      const percent = Math.min(time / 300, 1);
      parentContainer.scrollTo(
        0,
        startPageYOffset - diffScrollBlockComments * percent,
      );
      if (time < 300) {
        requestAnimationFrame(checkScrollEnd);
      } else {
        setVisibleComments(false);
      }
    };

    if (isCommentMode) {
      setVisibleComments(true);
      if (productRef.current.accessStatus === PRODUCT_ACCESS_STATUSES.OWN) {
        setTimeout(() => {
          handleScrollTo(ProductSections.comments);
        }, 200);
      }
    } else if (visibleComments) {
      const { top = 0 } =
        blockCommentsRef.current?.getBoundingClientRect() ?? {};
      diffScrollBlockComments = document.documentElement.clientHeight - top;
      if (diffScrollBlockComments > 0) {
        startPageYOffset = parentContainer.scrollTop;
        requestAnimationFrame(checkScrollEnd);
      } else {
        setVisibleComments(false);
      }
    }
  }, [isCommentMode, visibleComments]);

  useEffect(() => {
    if (!visibleComments) {
      if (openedModal === COMMENT_POPUP) {
        handleModalClose();
      }

      setData([[], 0]);
      setPage(1);
    }
  }, [handleModalClose, visibleComments, openedModal]);

  if (!visibleComments) {
    return null;
  }

  return (
    <Fragment>
      <InfoBlock
        ref={blockCommentsRef}
        id={id}
        title={
          <Container alignItems='center'>
            {title}
            <CommentBlankIcon color='text.secondary' ml={2} mr={0} />
            <Text color='text.secondary' fontSize={3} fontWeight={600}>
              {totalItems}
            </Text>
          </Container>
        }
      >
        <Spinner loading={isLoading && page === 1}>
          <div>
            {comments.map(comment => (
              <ProductCommentsCard
                key={comment.id}
                identity={identity}
                isOwnProduct={
                  productRef.current.accessStatus ===
                  PRODUCT_ACCESS_STATUSES.OWN
                }
                productId={productRef.current.id}
                {...comment}
                updateData={handleUpdateComments}
              />
            ))}
            {productRef.current.accessStatus === PRODUCT_ACCESS_STATUSES.OWN &&
              comments.length === 0 && <Text>Нет комментариев к товару.</Text>}
          </div>
        </Spinner>
        {(totalPages > page || (isLoading && page !== 1)) && (
          <NextCommentsPage onClick={() => setPage(prev => prev + 1)}>
            {isLoading ? (
              <Spinner size='xxs' />
            ) : (
              `Показано ${comments.length} из ${totalItems}. Показать еще`
            )}
          </NextCommentsPage>
        )}
        {productRef.current.accessStatus !== PRODUCT_ACCESS_STATUSES.OWN && (
          <ProductCreateCommentsPopup
            productId={productRef.current.id}
            section={ProductSections.toTheProduct}
            updateData={handleUpdateComments}
          />
        )}
      </InfoBlock>
      <Overflow {...getHintContainer()}>
        <IconContainer>
          <CommentAddBoldIcon />
        </IconContainer>
        <Arrow />
      </Overflow>
    </Fragment>
  );
}

export { ProductComments };
