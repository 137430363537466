import { CSSProperties, ElementType, forwardRef, ReactNode } from 'react';

import styled, { css } from 'styled-components';
import {
  space as styledSpace,
  typography,
  color as styledColor,
  SpaceProps,
  ColorProps,
  TypographyProps,
} from 'styled-system';

import { Text } from '../Text';
import { Tip, TipType } from '../Tip';

const Container = styled.label<
  { disabled?: boolean } & SpaceProps & ColorProps & TypographyProps
>(
  ({ theme: { space, colors, fontSizes }, disabled }) => css`
    display: flex;
    align-items: center;
    width: 100%;
    margin-bottom: ${space[1]}px;
    color: ${disabled ? colors.text.disabled : colors.text.secondary};
    font-size: ${fontSizes[2]}px;
    font-weight: 600;
    cursor: inherit;

    ${styledSpace};
    ${styledColor};
    ${typography};

    span {
      color: ${colors.text.disabled};
      margin-left: ${space[1]}px;
      margin-top: 1px;
      font-size: ${fontSizes[1]}px;
    }
  `,
);

interface IFormLabelProps {
  style?: CSSProperties;
  /**
   * Mark for required fields.
   */
  required?: boolean;
  /**
   * Disabled status.
   */
  disabled?: boolean;
  htmlFor?: string;
  as?: ElementType;
  /**
   * Атрибут конфликтует с HTMLAttribute из-за того, что он из styled-system
   */
  color?: string;
  /**
   * Text of tooltip.
   */
  tip?: TipType;
  /**
   * Text of label.
   */
  children: ReactNode;
}

const FormLabel = forwardRef<
  HTMLDivElement,
  IFormLabelProps & SpaceProps & ColorProps & TypographyProps
>(
  (
    { style, required, disabled, tip, htmlFor, children, color, ...other },
    ref,
  ) => {
    return (
      <Container
        ref={ref}
        color={color}
        disabled={disabled}
        htmlFor={htmlFor}
        style={style}
        {...other}
      >
        {children}&nbsp;
        {required && (
          <Text color='error.disabled' fontSize={3}>
            *
          </Text>
        )}
        {tip && <Tip ml={0} tip={tip} />}
      </Container>
    );
  },
);

export { FormLabel };
