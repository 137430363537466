import { Dispatch, FC } from 'react';

import { TinyChevronDownIcon, TinyChevronUpIcon } from 'resources/other';

import { GroupHeader } from '../styled';

interface IColorGroupProps {
  isExpanded: boolean;
  item: { name: string };
  dispatchPalette: Dispatch<any>;
}

const ColorGroup: FC<IColorGroupProps> = ({
  isExpanded,
  item: { name },
  dispatchPalette,
}) => {
  return (
    <GroupHeader
      onClick={() => dispatchPalette({ type: 'EXPAND_GROUP', payload: name })}
    >
      {name}
      {isExpanded ? <TinyChevronUpIcon /> : <TinyChevronDownIcon />}
    </GroupHeader>
  );
};

export { ColorGroup };
