import { FC, Fragment, memo, ReactElement, ReactNode } from 'react';

import styled, { css, useTheme } from 'styled-components';

import { POINT_KEYS } from 'common/constants/onboarding.const';
import { EducationPoint } from 'entities/education';
import { getLayoutStates } from 'layouts/store';
import { SettingsIcon } from 'resources/icons/18';
import { CloseMediumIcon } from 'resources/other-28';
import { useAppSelector } from 'store';
import { SideMenuMainItem, IconButton, Button, Tag, Tooltip } from 'UI';

const Container = styled.div(
  ({ theme: { colors } }) => css`
    position: absolute;
    top: 0;
    right: -321px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 320px;
    padding: 0;
    box-shadow: none;
    background: ${colors.white};
    border-right: 1px solid ${colors.divider};
    z-index: 12;
  `,
);

const SideFilterWrapper = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    padding: ${space[1]}px ${space[2]}px ${space[2]}px;
  `,
);

const Footer = styled.div(
  ({ theme: { space } }) => css`
    display: flex;
    align-items: center;
    height: 52px;
    min-height: 52px;
    width: 100%;
    padding: 9px ${space[2]}px;
    gap: ${space[1]}px;
  `,
);

function SideFilterButtons({ isDirty, onReset, onSubmit }): ReactElement {
  return (
    <Footer>
      <Button
        color='secondary'
        style={{ flexGrow: 1 }}
        variant='filled'
        onClick={onReset}
      >
        Сбросить все
      </Button>
      <Button disabled={!isDirty} style={{ flexGrow: 1 }} onClick={onSubmit}>
        Применить
      </Button>
    </Footer>
  );
}

interface ISideFilterProps {
  children: ReactNode;
  countOfFilters: number;
  onReset: VoidFunction;
  onToggle: VoidFunction;
  isOpen: boolean;
  width?: string;
}

const SideFilter: FC<ISideFilterProps> = memo(
  ({ children, countOfFilters, onReset, onToggle, isOpen, width = '100%' }) => {
    const { collapseSubMenu } = useAppSelector(getLayoutStates);
    const { space } = useTheme();

    const buttonBackground = countOfFilters ? 'highlight.1' : 'highlight.2';

    return (
      <Fragment>
        <SideMenuMainItem
          as='div'
          backgroundColor={collapseSubMenu ? 'background' : buttonBackground}
          collapseVisible={collapseSubMenu}
          // todo временное решение со стилями
          {...(!collapseSubMenu && {
            ml: 1,
            mr: 1,
          })}
          mb={2}
          rightAction={
            <EducationPoint section={POINT_KEYS.SUB_MENU_FILTER_PANEL_OPEN}>
              <IconButton
                bg={collapseSubMenu ? buttonBackground : 'transparent'}
                size={collapseSubMenu ? 36 : 28}
                variant={countOfFilters ? 'outline' : 'secondary'}
                onClick={onToggle}
              >
                {isOpen ? <CloseMediumIcon /> : <SettingsIcon />}
              </IconButton>
            </EducationPoint>
          }
          style={{
            height: collapseSubMenu ? '36px' : `${space[5]}px`,
            minHeight: collapseSubMenu ? '36px' : `${space[5]}px`,
            width: collapseSubMenu ? '36px' : `${width}`,
            borderRadius: collapseSubMenu ? '2px' : `${space[0]}px`,
            fontSize: '13px',
          }}
        >
          Фильтры
          {!!countOfFilters && (
            <Tooltip arrow placement='top' title='Сбросить'>
              <Tag
                colorTheme='primary'
                fontSize={0}
                ml={1}
                px={0}
                py={0}
                value={countOfFilters}
                onRemove={onReset}
              />
            </Tooltip>
          )}
        </SideMenuMainItem>
        {isOpen && <Container>{children}</Container>}
      </Fragment>
    );
  },
);

export { SideFilter, SideFilterWrapper, SideFilterButtons };
