import styled, { css } from 'styled-components';
import { space as styledSpace, SpaceProps } from 'styled-system';

import { Switch } from 'UI';

const INDENT_FROM_PANEL = 2;

export const Panel = styled.div<{ topIndent: number | undefined }>(
  ({ theme: { colors, borderRadius, shadows }, topIndent }) => css`
    position: absolute;
    top: ${topIndent && topIndent + INDENT_FROM_PANEL}px;
    right: 0;
    width: 486px;
    margin-top: 2px;
    margin-left: auto;
    background: ${colors.white};
    border-radius: ${borderRadius}px;
    box-shadow: ${shadows[2]};
    text-align: left;
  `,
);

export const Header = styled.div(
  ({ theme: { colors } }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 46px;
    border-bottom: 1px solid ${colors.divider};
  `,
);

export const BodyWrapper = styled.div(
  ({ theme: { space } }) => css`
    height: 68vh;
    padding: ${space[1]}px ${space[2]}px ${space[4]}px;
    overflow: auto;
  `,
);

export const SwitchBlock = styled.div<SpaceProps>(
  ({ theme: { space } }) => css`
    margin-left: 20px;

    & + & {
      margin-top: ${space[2]}px;
    }

    ${styledSpace}
  `,
);

export const Switcher = styled(Switch)<{ isDisabled?: boolean }>(
  ({ theme: { colors }, isDisabled }) => css`
    width: 100%;
    height: 20px;
    justify-content: space-between;

    & + & {
      margin-top: 12px;
    }

    & label {
      cursor: pointer;
    }

    ${isDisabled &&
    css`
      & label {
        color: ${colors.text.disabled};
      }
    `}
  `,
);
