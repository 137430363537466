import { Fragment, useCallback, useMemo, memo, FC, CSSProperties } from 'react';

import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';

import { fetchProducts } from 'common/api/products.api';
import { useRequest } from 'common/hooks';
import { IProduct, IVersion } from 'common/types/product.types';
import { getProductFullName, getProductPagePath } from 'common/utils/products';
import { getCompanyId } from 'entities/identity';
import { LinkOutsideIcon } from 'resources/icons/18';
import { useAppSelector } from 'store';
import {
  Tag,
  Text,
  Spinner,
  Tooltip,
  Container,
  IconButton,
  InfinityList,
  DescriptionList,
  VerticalDivider,
  ProgressIndicator,
} from 'UI';
import { useAsyncOptions } from 'UI/Inputs';

const CONTAINER_HEIGHT = 336;
const ITEM_SIZE = 42;
const PLACEHOLDER = <div>Элементов не найдено</div>;

const Dot = styled.div(
  ({ theme: { colors } }) => css`
    content: '';
    width: 6px;
    height: 6px;
    background-color: ${colors.primary.main};
    border-radius: 50%;
  `,
);

interface IVersionListItemProps {
  item: IProduct;
  index: number;
  root: string;
  style: CSSProperties;
}

const VersionListItem: FC<IVersionListItemProps> = memo(
  ({ item, index, root, style }) => {
    return (
      <Container
        alignItems='center'
        backgroundColor={index % 2 === 0 ? 'background' : 'white'}
        p={0}
        style={style}
      >
        <Container alignItems='center' flexGrow={1} gap={1} minWidth={0}>
          <Text truncate flexBasis='100%' fontSize={3}>
            {getProductFullName(item)}
          </Text>
          <Container alignItems='center' gap={1} minWidth='fit-content'>
            {item.id === root && (
              <Tooltip pointer placement='bottom' title='Родительская версия'>
                <Dot />
              </Tooltip>
            )}
            <Text fontSize={3}>Версия {item.version?.number}</Text>
          </Container>
        </Container>
        <VerticalDivider height={34} mx={1} my='auto' />
        <IconButton as={Link} target='_blank' to={getProductPagePath(item)}>
          <LinkOutsideIcon />
        </IconButton>
      </Container>
    );
  },
);

const VersionsList: FC<{ root: string; currentProductId: string }> = ({
  root,
  currentProductId,
}) => {
  const [asyncState, dispatch] = useAsyncOptions();

  const fetchOptions = useCallback(() => {
    return fetchProducts({
      root,
      orderBy: 'version.number',
      orderDirection: 'ASC',
    });
  }, [root]);

  const renderItem = useCallback(
    ({ item, index, style }) => (
      <VersionListItem index={index} item={item} root={root} style={style} />
    ),
    [root],
  );

  const filteredItems = useMemo(
    () => asyncState.items.filter(({ id }) => id !== currentProductId),
    [asyncState, currentProductId],
  );

  return (
    <Fragment>
      <ProgressIndicator isLoading={asyncState.isLoading} />
      <InfinityList
        dispatch={dispatch}
        fetchOptions={fetchOptions}
        height={
          filteredItems.length < 9
            ? ITEM_SIZE * filteredItems.length
            : CONTAINER_HEIGHT
        }
        itemSize={ITEM_SIZE}
        placeholder={PLACEHOLDER}
        renderItem={renderItem}
        state={{ ...asyncState, items: filteredItems }}
      />
    </Fragment>
  );
};

interface IProductVersionDescriptionPropertyProps {
  version: IVersion;
  productId: IProduct['id'];
  company: IProduct['company'];
}

const ProductVersionDescriptionProperty: FC<
  IProductVersionDescriptionPropertyProps
> = ({
  productId,
  version,
  company,
  ...other // props from DescriptionList
}) => {
  const companyId = useAppSelector(getCompanyId);

  const { root, number } = version;

  const isOwnProduct = company.id === companyId;
  const { isLoading, data = { member: [] } } = useRequest(
    isOwnProduct && fetchProducts,
    [{ root }],
    {
      responseToData: ({ member }) => ({
        member: member.filter(({ id }) => id !== productId),
      }),
    },
  );

  if (!isOwnProduct) return null;

  const content = isLoading ? (
    <Spinner loading delay={0} mr='auto' my='auto' size='xs' />
  ) : (
    data.member.length > 0 && (
      <Container alignItems='center'>
        <Tooltip
          maxWidth={406}
          minWidth={406}
          p={2}
          title={<VersionsList currentProductId={productId} root={root} />}
        >
          <Tag arrow mr={1} value={`Версия ${number}`} />
        </Tooltip>
        {productId === root && (
          <Tooltip pointer placement='bottom' title='Родительская версия'>
            <Dot />
          </Tooltip>
        )}
      </Container>
    )
  );

  return (
    <DescriptionList.Property
      label='Версия'
      {...other}
      placeholder='Нет версий'
    >
      {content}
    </DescriptionList.Property>
  );
};

export { ProductVersionDescriptionProperty };
